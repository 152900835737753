import { FormikErrors } from "formik";
import { IBusinessClientDetailFormValues } from "../../../../../../../../models";

export default (errors: FormikErrors<IBusinessClientDetailFormValues>) => {
    return !errors?.medicalInsurance?.offerMedicalInsurance &&
    !errors?.medicalInsurance?.medicalCarrier &&
    !errors?.medicalInsurance?.previousMedicalCarrier &&
    !errors?.medicalInsurance?.medicalPlanOffering &&
    !errors?.medicalInsurance?.medicalDeductibleType &&
    !errors?.medicalInsurance?.telemedicine &&
    !errors?.medicalInsurance?.medicalCoverageTerminatesOn &&
    !errors?.medicalInsurance?.medicalNotes &&
    !errors?.medicalInsurance?.medicalPlans;
}