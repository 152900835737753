import React, { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
// Components
import { FormLabel, Accordion, AccordionSummary, AccordionDetails, Typography, Divider, IconButton } from '@mui/material';
import { ExpandMore, Edit } from '@mui/icons-material';
// Types
import { IUser } from '../../../models';
// helpers
import { formatInputPhoneNumber } from '../../../helpers';

interface IMobileManageUser {
  original: IUser;
  handleEdit: (val: IUser) => void;
}

export const MobileManageUser: FC<IMobileManageUser> = ({ original, handleEdit }) => {
  const classes = useStyles();
  const { firstName, lastName, email, isActive, role, clientName, phoneNumber } = original;

  return (
    <Accordion className={classes.root}>
      <AccordionSummary className={classes.accordion} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div>
          <div>
            <div className={classes.roleWrapper}>
              <Typography className={classes.activeName}>{isActive ? 'Active' : 'Inactive'}</Typography>
              <Divider orientation='vertical' flexItem className={classes.divider} />
              <Typography>{role}</Typography>
            </div>
            <IconButton
              className={classes.editButton}
              color='primary'
              onClick={e => {
                e.stopPropagation();
                handleEdit(original);
              }}
              size="large">
              <Edit />
            </IconButton>
          </div>
          <div className={classes.panelSummaryWrapper}>
            <Typography color='primary' className={classes.boldName}>
              {firstName} {lastName}
            </Typography>
            <Typography paragraph>{clientName || '--'}</Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelEmail)}>
            Email:
          </FormLabel>
          <Typography component='span'>{email || '--'}</Typography>
        </div>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelPhone)}>
            Phone:
          </FormLabel>
          <Typography component='span'>{phoneNumber ? formatInputPhoneNumber(phoneNumber) : '--'}</Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    overflowX: 'hidden'
  },
  accordion: {
    padding: '0 16px',
    '&& .MuiAccordionSummary-content': {
      marginBottom: 0
    }
  },
  panelSummaryWrapper: {
    marginTop: theme.spacing(0.5),
    '&:first-child': { marginTop: 0 }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px',
    backgroundColor: theme.palette.grey[100]
  },
  roleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  editButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  boldName: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  activeName: {
    color: theme.palette.grey[500]
  },
  divider: {
    margin: '0 5px'
  },
  subLabelEmail: {
    marginRight: 10
  },
  subLabelPhone: {
    marginRight: 5
  }
}));
