import React, { FC, useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { Add, Save, Close, RecentActors } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';
// Components
import { Toast, Modal, Loader, DatePicker, EmployeeWarningToast } from '../../components';
import {
  Card,
  Fade,
  CardActions,
  Button,
  CardContent,
  CardHeader,
  TextField,
  Grid,
  FormControl,
  Paper,
  Typography,
  FormHelperText,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  InputAdornment
} from '@mui/material';
// models
import { IEmployeeDetail, IBusinessClientLocation, IBusinessClientClass } from '../../models';
// fetch
import { updateEmployee, createEmployee, calculateEmployeeEffectiveDate } from '../../fetch';
// helpers
import { formatInputPhoneNumber, phoneRegExp, formatDate } from '../../helpers';

interface IEmployeeModal {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  currentEmployee: IEmployeeDetail | null;
  locations: IBusinessClientLocation[];
  businessClientClasses: IBusinessClientClass[];
  isLoadingBusinessClient: boolean;
}

const EmployeeSchema = Yup.object().shape(
  {
    firstName: Yup.string().max(255, 'Max 255 characters').required('Required'),
    lastName: Yup.string().max(255, 'Max 255 characters').required('Required'),
    salary: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:\.\d{2})?\s*$/, 'Invalid Number'),
    dateOfHire: Yup.string().required('Required'),
    className: Yup.string().required('Required').typeError('Required'),
    phoneNumber: Yup.string().when('email', {
      is: val => !!val,
      then: Yup.string().notRequired().matches(phoneRegExp, { excludeEmptyString: true, message: 'Invalid phone number' }),
      otherwise: Yup.string().required('Required').matches(phoneRegExp, { excludeEmptyString: true, message: 'Invalid phone number' })
    }),
    email: Yup.string().when('phoneNumber', {
      is: val => !!val,
      then: Yup.string().notRequired().max(255, 'Max 255 characters').email('Email address invalid'),
      otherwise: Yup.string().required('Required').max(255, 'Max 255 characters').email('Email address invalid')
    }),
    locationName: Yup.string().required('Required').typeError('Required'),
    supervisorName: Yup.string(),
    preferredLanguage: Yup.string(),
    status: Yup.boolean(),
    notes: Yup.string(),
    effectiveDateConfirmed: Yup.boolean().nullable()
  },
  [['phoneNumber', 'email']]
);

export const EmployeeModal: FC<IEmployeeModal> = ({ open, onClose, onSave, currentEmployee, locations, businessClientClasses, isLoadingBusinessClient }) => {
  const classes = useStyles();
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [showEmployeeWarning, setShowEmployeeWarning] = useState<boolean>(false);
  const [effectiveDate, setEffectiveDate] = useState<string>(null);
  const [dateOfHire, setDateOfHire] = useState<string>(null);
  const [classId, setClassId] = useState<string | number | null>(null);

  const { businessClientId } = useParams();

  const getEffectiveDate = async (classId: string | number, dateOfHire: string) => {
    try {
      const res = await calculateEmployeeEffectiveDate(businessClientId, `${classId}`, dateOfHire);
      if (res && typeof res === 'string') {
        setEffectiveDate(res);
      } else {
        showError(true);
        setErrorMessage(`Error showing effective date, ${res.Detail}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentEmployee) {
      getEffectiveDate(currentEmployee.classId, currentEmployee.dateOfHire);
      setClassId(currentEmployee.classId);
      setDateOfHire(currentEmployee.dateOfHire);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmployee]);

  useEffect(() => {
    if (classId && dateOfHire) {
      getEffectiveDate(classId, dateOfHire);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfHire, classId]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: currentEmployee && currentEmployee.firstName ? currentEmployee.firstName : '',
          lastName: currentEmployee && currentEmployee.lastName ? currentEmployee.lastName : '',
          email: currentEmployee && currentEmployee.email ? currentEmployee.email : '',
          status: currentEmployee ? currentEmployee.isActive : true,
          phoneNumber:
            currentEmployee && currentEmployee.phoneNumber && currentEmployee.phoneNumber[0] && currentEmployee.phoneNumber[0].number
              ? formatInputPhoneNumber(currentEmployee.phoneNumber[0].number)
              : '',
          salary: currentEmployee && currentEmployee.salary ? currentEmployee.salary : '',
          dateOfHire: currentEmployee && currentEmployee.dateOfHire ? currentEmployee.dateOfHire : '',
          classId: currentEmployee && currentEmployee.classId ? currentEmployee.classId : '',
          className: currentEmployee && currentEmployee.className ? currentEmployee.className : '',
          locationId: currentEmployee && currentEmployee.locationId ? currentEmployee.locationId : '',
          locationName: currentEmployee && currentEmployee.locationName ? currentEmployee.locationName : '',
          supervisorName: currentEmployee && currentEmployee.supervisorName ? currentEmployee.supervisorName : '',
          preferredLanguage: currentEmployee && currentEmployee.preferredLanguage ? currentEmployee.preferredLanguage : '',
          notes: currentEmployee && currentEmployee.notes ? currentEmployee.notes : '',
          effectiveDateConfirmed: currentEmployee && currentEmployee.effectiveDateConfirmed ? currentEmployee.effectiveDateConfirmed : false
        }}
        validationSchema={EmployeeSchema}
        onSubmit={async (values, actions) => {
          try {
            
            const employeeWarningError = 'You are attempting to add new hires with an effective date that is less than 15 days away';

            const selectedLocation = locations.find(location => location.name === values.locationName);
            const selectedClass = businessClientClasses.find(c => c.name === values.className);
            const data = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              dateOfHire: values.dateOfHire,
              locationId: selectedLocation.businessClientLocationId,
              classId: selectedClass.businessClientClassId,
              salary: Number(values.salary),
              supervisorName: values.supervisorName,
              preferredLanguage: values.preferredLanguage,
              notes: values.notes,
              phoneNumber: values.phoneNumber
                ? {
                  number: values.phoneNumber,
                  extension: '',
                  type: 'Home'
                }
                : null
            };

            try {
              if (currentEmployee) {
                await updateEmployee(businessClientId, currentEmployee.employeeId, {
                  isActive: values.status,
                  ...data
                });
              } else {
                await createEmployee(businessClientId, { effectiveDateConfirmed: values.effectiveDateConfirmed, ...data });
              }

              showSuccess(true);
              showError(false);
              setSuccessMessage('Employee Updated!');
              onClose();
              onSave();
              setShowEmployeeWarning(false);
              actions.resetForm();

            } catch (res) {

              const errorMessages = res?.results.map(r => r.errorMessage) as string[];

              if (errorMessages.includes(employeeWarningError)) {
                return setShowEmployeeWarning(true);
              }
  
              setErrorMessage(errorMessages?.join() ?? 'Error, please try again');
              showError(true);
            }


          } catch (error) {
            console.log(error);
            if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
              setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
            }
            showError(true);
          }
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, touched, handleSubmit, dirty, isValid, handleBlur }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                    setEffectiveDate(null);
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  setEffectiveDate(null);
                  resetForm();
                }
              }}
              maxWidth='md'
            >
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <Typography variant='h4'>{currentEmployee ? 'EDIT EMPLOYEE' : 'ADD NEW EMPLOYEE'}</Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Card elevation={0} variant='outlined' className={classes.marginBottom}>
                          <CardHeader avatar={<RecentActors />} title='EMPLOYEE INFO' className={classes.primaryHeader} />
                          <CardContent className={classes.cardContent}>
                            <Paper elevation={0} square>
                              <div className={classes.column}>
                                <Grid container spacing={1}>
                                  {currentEmployee && (
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            color='primary'
                                            checked={values.status}
                                            id='active-inactive'
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (currentEmployee.isActive) {
                                                const result = window.confirm(
                                                  'Are you sure you want to set this employee to inactive? Proceeding with this update will remove this employee from the business client.'
                                                );
                                                if (result) {
                                                  setFieldValue('status', e.target.checked);
                                                }
                                              } else {
                                                setFieldValue('status', e.target.checked);
                                              }
                                            }}
                                          />
                                        }
                                        label='Active'
                                      />
                                    </Grid>
                                  )}
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      autoComplete='nope'
                                      label='First Name'
                                      name='firstName'
                                      value={values.firstName}
                                      onBlur={handleBlur}
                                      onChange={e => setFieldValue('firstName', e.target.value)}
                                      error={Boolean(touched.firstName && errors.firstName)}
                                      helperText={touched.firstName && errors.firstName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      label='Last Name'
                                      autoComplete='nope'
                                      name='lastName'
                                      value={values.lastName}
                                      onBlur={handleBlur}
                                      onChange={e => setFieldValue('lastName', e.target.value)}
                                      error={Boolean(touched.lastName && errors.lastName)}
                                      helperText={touched.lastName && errors.lastName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      variant='outlined'
                                      label='Phone Number'
                                      name='phoneNumber'
                                      autoComplete={`off`}
                                      required={!values.email && !values.phoneNumber}
                                      value={values.phoneNumber}
                                      onBlur={handleBlur}
                                      onChange={e => {
                                        setFieldValue('phoneNumber', formatInputPhoneNumber(e.target.value));
                                      }}
                                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                      helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required={!values.email && !values.phoneNumber}
                                      variant='outlined'
                                      label='Email'
                                      name='email'
                                      autoComplete={`off`}
                                      value={values.email}
                                      onBlur={handleBlur}
                                      onChange={e => {
                                        setFieldValue('email', e.target.value);
                                      }}
                                      error={Boolean(touched.email && errors.email)}
                                      helperText={touched.email && errors.email}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      variant='outlined'
                                      label='Salary'
                                      name='salary'
                                      value={values.salary}
                                      onBlur={handleBlur}
                                      onChange={e => {
                                        setFieldValue('salary', e.target.value);
                                      }}
                                      inputProps={{ maxLength: 255, min: '0.01' }}
                                      InputProps={{
                                        startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                      }}
                                      error={Boolean(touched.salary && errors.salary)}
                                      helperText={touched.salary && errors.salary ? errors.salary : ''}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined' required>
                                      <DatePicker
                                        label='Date of Hire'
                                        className={classes.dateOfHire}
                                        value={values.dateOfHire ? new Date(values.dateOfHire) : null}
                                        renderInput={props => <TextField {...props} required label='Date of Hire' value={values.dateOfHire ? new Date(values.dateOfHire) : null} />}
                                        onChange={(date: Date) => {
                                          const val = date?.toISOString?.() ? date?.toISOString() : '';
                                          setDateOfHire(val);
                                          setFieldValue(`dateOfHire`, val);
                                        }}
                                      />
                                      {touched.dateOfHire && errors.dateOfHire && <FormHelperText>{errors.dateOfHire}</FormHelperText>}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined' required error={touched.classId && errors.classId ? true : false}>
                                      <Autocomplete
                                        value={{ name: values.className, 
                                            businessClientClassId: values.classId } as IBusinessClientClass}
                                        classes={{
                                          paper: classes.paperBorder
                                        }}
                                        onChange={(event, newValue: IBusinessClientClass) => {
                                          if (newValue) {
                                            setClassId(`${newValue.businessClientClassId}`);
                                            setFieldValue('classId', newValue.businessClientClassId);
                                            setFieldValue('className', newValue.name);
                                          } else {
                                            setClassId(null);
                                            setFieldValue('classId', '');
                                            setFieldValue('className', '');
                                          }
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        loading={isLoadingBusinessClient}
                                        id='className'
                                        options={businessClientClasses}
                                        getOptionLabel={(businessClass: IBusinessClientClass) => {
                                          // Value selected with enter, right from the input
                                          if (typeof businessClass === 'string') {
                                            return businessClass;
                                          }
                                          return businessClass.name;
                                        }}
                                        renderOption={(props, option: any) => {
                                          return <li {...props}>{option.name}</li>;
                                        }}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            required
                                            autoComplete='off'
                                            error={Boolean(touched.className && errors.className)}
                                            onBlur={handleBlur}
                                            label='Class'
                                            variant='outlined'
                                          />
                                        )}
                                      />

                                      {touched.className && errors.className && <FormHelperText error>{errors.className}</FormHelperText>}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined'>
                                      <DatePicker
                                        label='Effective Date'
                                        className={classes.dateOfHire}
                                        disabled={true}
                                        value={effectiveDate ? formatDate(effectiveDate) : ' '}
                                        onChange={() => { }}
                                        renderInput={props => (
                                          <TextField {...props} error={false} disabled={true} label='Effective Date' value={effectiveDate ? formatDate(effectiveDate) : ' '} />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      variant='outlined'
                                      label='Supervisor / Manager Name'
                                      name='supervisorName'
                                      autoComplete={`off`}
                                      value={values.supervisorName}
                                      onBlur={handleBlur}
                                      onChange={e => {
                                        setFieldValue('supervisorName', e.target.value);
                                      }}
                                      error={Boolean(touched.supervisorName && errors.supervisorName)}
                                      helperText={touched.supervisorName && errors.supervisorName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined' required error={touched.locationId && errors.locationId ? true : false}>
                                      <Autocomplete
                                        value={{ name: values.locationName } as IBusinessClientLocation}
                                        classes={{
                                          paper: classes.paperBorder
                                        }}
                                        onChange={(event, newValue: IBusinessClientLocation) => {
                                          if (newValue) {
                                            setFieldValue('locationId', newValue.businessClientLocationId);
                                            setFieldValue('locationName', newValue.name);
                                          } else {
                                            setFieldValue('locationId', '');
                                            setFieldValue('locationName', '');
                                          }
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        loading={isLoadingBusinessClient}
                                        id='locationName'
                                        options={locations}
                                        getOptionLabel={(location: IBusinessClientLocation) => {
                                          // Value selected with enter, right from the input
                                          if (typeof location === 'string') {
                                            return location;
                                          }
                                          return location.name;
                                        }}
                                        renderOption={(props, option: any) => {
                                          return <li {...props}>{option.name}</li>;
                                        }}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            required
                                            autoComplete='off'
                                            error={Boolean(touched.locationName && errors.locationName)}
                                            onBlur={handleBlur}
                                            label='Location and Scheduling'
                                            variant='outlined'
                                          />
                                        )}
                                      />

                                      {touched.locationName && errors.locationName && <FormHelperText error>{errors.locationName}</FormHelperText>}
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <FormControl component='fieldset'>
                                      <FormLabel component='legend'>Preferred Language</FormLabel>
                                      <RadioGroup
                                        aria-label={`language`}
                                        name={`language`}
                                        value={values.preferredLanguage}
                                        color='primary'
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          setFieldValue(`preferredLanguage`, e.target.value);
                                        }}
                                      >
                                        <FormControlLabel value='English' control={<Radio color='primary' />} label='English' />
                                        <FormControlLabel value='Spanish' control={<Radio color='primary' />} label='Spanish' />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      variant='outlined'
                                      label='Notes'
                                      name='notes'
                                      autoComplete={`off`}
                                      value={values.notes}
                                      onBlur={handleBlur}
                                      multiline
                                      rows={3}
                                      inputProps={{
                                        style: {
                                          resize: 'vertical'
                                        }
                                      }}
                                      onChange={e => {
                                        setFieldValue('notes', e.target.value);
                                      }}
                                      error={Boolean(touched.notes && errors.notes)}
                                      helperText={touched.notes && errors.notes}
                                    />
                                  </Grid>
                                </Grid>
                                <TextField className={classes.inputHidden} variant='outlined' name='effectiveDateConfirmed' value={values.effectiveDateConfirmed} type='hidden' />
                              </div>
                            </Paper>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                  <CardActions>
                    <Button disabled={!dirty || isSubmitting || !isValid} type='submit' startIcon={currentEmployee ? <Save /> : <Add />} variant='contained' color='primary'>
                      {currentEmployee ? 'Save' : 'Add Employee'}
                    </Button>
                    <Button
                      type='button'
                      variant='contained'
                      color='inherit'
                      startIcon={<Close />}
                      onClick={() => {
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                          if (result) {
                            resetForm();
                            onClose();
                            setEffectiveDate(null);
                          } else {
                            return;
                          }
                        } else {
                          setEffectiveDate(null);
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </CardActions>
                  <EmployeeWarningToast
                    open={open && showEmployeeWarning ? true : false}
                    onConfirm={() => {
                      setFieldValue('effectiveDateConfirmed', true); // Set hidden field to true, the user confirmed they know about the dates
                      setShowEmployeeWarning(false); // Hide alert when confirmed
                    }}
                  />
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
      <Toast id='employee-success' message={successMessage || `Employee Added!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='employee-error'
        autoHideDuration={6000}
        message={errorMessage || 'We were unable to create the employee at this time. Please try again later. Please contact Enrollment Alliance support if this issue continues.'}
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  content: {
    marginTop: theme.spacing(1)
  },
  cardContent: {
    paddingTop: 0
  },
  paperBorder: {
    border: `1px solid ${theme.palette.grey[300]}`
  },
  dateOfHire: {
    marginTop: 0
  },
  inputHidden: {
    display: 'none'
  }
}));
