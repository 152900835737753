import { FC } from 'react';
import { HRPreferencesSubMenusTypes, TabOptionType } from '../../../../MenuData/types';
import { TabsBase } from '../../../base/tabs-base';


interface IHRPreferencesTabsTabsProps {
    validateForm: () => void;
}

const tabOptions: TabOptionType<HRPreferencesSubMenusTypes>[] = [
    {
        value: 0,
        label: 'Campaign Strategy',
        subMenuRelatedId: 'campaign_strategy'
    }
];

//'industry'
//'company_preferences'

export const HRPreferencesTabs: FC<IHRPreferencesTabsTabsProps> = ({ validateForm }) => {
    return <TabsBase validateForm={validateForm} tabOptions={tabOptions} />
}
