import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import { CardActions, Button, Alert } from '@mui/material';
import { Add, Close, PictureAsPdf, Save } from '@mui/icons-material';
import { createBusinessClient, downloadBusinessClientPdf, storeBusinessClientAppLogo, storeBusinessClientCompanyLogo, updateBusinessClient } from '../../../../../fetch';
import { businessClientRes } from '../actions';
import { BusinessClientSchema } from '../../validation';
import { createBusinessClientFormValues } from '../../actions';
import { Loader, Toast } from '../../../../../components';
import { isObjectEmpty } from '../../../../../util';
import useErrorMessage from '../../../../../context/ErrorMessageCtx';
import { useCurrentBusinessClient } from '../../contexts/CurrentBusinessClientContext';
import { useEntitiesDropdownsOptionsCollection } from '../../../../../context/EntitiesDropdownsOptionsCollectionCtx';
import { useAppLogo } from '../../contexts/AppLogoContext';
import { useCompanyLogo } from '../../contexts/CompanyLogoContext';
import { SectionDocumentsContext, SectionDocumentsProvider } from '../TabsForms/tabs-forms-contents/EnrollmentPlanner/EssentialDocsList/contexts/SectionDocumentsContext';
import { FileManagementModal } from '../TabsForms/tabs-forms-contents/EnrollmentPlanner/EssentialDocsList/EssentialDoc/FileManagementModal/FileManagementModal';
import NewFileManagementForm from '../TabsForms/tabs-forms-contents/EnrollmentPlanner/EssentialDocsList/EssentialDoc/FileManagementModal/NewFileManagementForm/NewFileManagementForm';
import useSuccessMessage from '../../../../../context/SuccessMessageCtx';
import EditFileManagementForm from '../TabsForms/tabs-forms-contents/EnrollmentPlanner/EssentialDocsList/EssentialDoc/FileManagementModal/EditFileManagementForm/EditFileManagementForm';

const hasErrorsOnResponse = (res: any) => {
    if (res && (typeof res === 'boolean' || typeof res === 'number')) {
        return false;
    }

    return true;
}

interface FormAndActionsProps {
    isNew: boolean
}

export const FormAndActions: FC<PropsWithChildren<FormAndActionsProps>> = ({ isNew, children }: PropsWithChildren<FormAndActionsProps>) => {
    const classes = useStyles();

    const currentBusinessClient = useCurrentBusinessClient();

    const [isSuccess, showSuccess] = useState<boolean>(false);
    

    const { appLogo } = useAppLogo();
    const { companyLogo } = useCompanyLogo();

    const [errorMessage, setErrorMessage] = useErrorMessage();

    const isError = errorMessage != null;


    const [successMessage, setSuccessMessage] = useState<string>('');
    const [generalSuccessMessage, setGeneralSuccessMessage] = useSuccessMessage();

    const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);

    const { systemOfRecords, brokers } = useEntitiesDropdownsOptionsCollection();

    const onSubmitAction = async (values): Promise<any> => {

        try {
            const { brokerName, systemOfRecord } = values;
            const selectedBroker = brokers.find(broker => broker.name === brokerName);
            const selectedSystemOfRecord = systemOfRecords.find(system => system.name === systemOfRecord);

            if (currentBusinessClient) {
                const res = await updateBusinessClient(currentBusinessClient.businessClientId, businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values));
                const hasErrors = hasErrorsOnResponse(res);

                return { businessClientId: currentBusinessClient.businessClientId, res, hasErrors };

            } else {
                const res = await createBusinessClient(businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values));
                const hasErrors = hasErrorsOnResponse(res);

                if (hasErrors) return { res, hasErrors };

                const newBusinessClientId = res;

                if (!companyLogo) return { businessClientId: newBusinessClientId, res, hasErrors };

                // If an image was selected, perform actions for uploading and updating the business client record
                const create = new FormData();
                create.append('image', companyLogo);
                const imageUrl = await storeBusinessClientCompanyLogo(newBusinessClientId, create);
                const logo = imageUrl.toString();
                let appLogoHeader;
                if (appLogo) {
                    const create = new FormData();
                    create.append('image', companyLogo);
                    const imageUrl = await storeBusinessClientAppLogo(newBusinessClientId, create);
                    appLogoHeader = imageUrl.toString();
                }
                // After uploading image to get a URL, we need to update the client record just created with the URL
                const updateRes = await updateBusinessClient(newBusinessClientId, businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values, logo, appLogoHeader));
                const hasErrorsUpdateRes = hasErrorsOnResponse(updateRes);

                return { businessClientId: newBusinessClientId, res, hasErrors: hasErrorsUpdateRes };

            }
        } catch (error) {
            return { res: error, hasErrors: true };
        }
    }

    const onSubmitBeforeDownloadPdf = async (dirty: boolean, setSubmitting: (isSubmitting: boolean) => void, values) => {
        if (dirty) {
            setSubmitting(true);

            const { businessClientId, res, hasErrors } = await onSubmitAction(values);
            setSubmitting(false);

            if (hasErrors) {
                handlerErrorMessageOnResponse(res);
                return null;
            }

            return businessClientId;
        }

        return currentBusinessClient.businessClientId;
    }

    const handlerErrorMessageOnResponse = (res: any) => {

        if (res?.Errors && Object?.values(Object?.values(res?.Errors)?.[0])?.[0]) {
            // validation errors
            setErrorMessage(Object.values(Object.values(res.Errors)[0])[0] as string);
        }
        if (res?.Detail) {
            setErrorMessage(res?.Detail);
        }
        // server errors
        setErrorMessage('Error, please try again');
    };

    const onSuccessActions = (actions, message: string) => {
        showSuccess(true);
        setSuccessMessage(message);
        //actions.resetForm();
        //setSelectedTab(0);
        setTimeout(() => {
            history.push('/manage-business-clients');
        }, 1000);
    }

    const history = useHistory();

    const theme = useTheme();

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current) {
            if (Object.keys(BusinessClientSchema).length != 0) {
                formRef.current.validateForm()
            }
        }
    }, [BusinessClientSchema]);

    return (<>

        <Formik
            innerRef={formRef}
            validationSchema={BusinessClientSchema}
            initialValues={createBusinessClientFormValues(currentBusinessClient, theme)}
            onSubmit={async (values, actions) => {
              if(values.shouldCompanyNameUsedInCommunication)
                values.companyNameUsedInCommunication = null;
                const { res, hasErrors } = await onSubmitAction(values);

                if (hasErrors) {
                    handlerErrorMessageOnResponse(res);
                } else {
                    onSuccessActions(actions, currentBusinessClient ? 'Business Client Updated!' : 'Business Client Created!');

                    setTimeout(() => {
                        history.push('/manage-business-clients');
                    }, 1000);
                }
            }}
        >
            {({ isSubmitting, dirty, isValid, validateForm, setSubmitting, values, resetForm }) => {
                return (<Form style={{
                    display: "flex",
                    flexDirection: 'column',
                    flexGrow: 1
                }}>
                    <SectionDocumentsProvider sectionDocuments={values?.sectionsDocuments ?? []}>
                        {isSubmitting && <Loader type='fullscreen' position='centered' />}
                        {isGeneratingPdf && <Loader type='fullscreen' position='centered' title='Creating the pdf file...' />}
                        {children}



                        <SectionDocumentsContext.Consumer>
                            {(context) => {
                                const { selectedSectionInfo, isAddNewFileModalOpen, isEditFileModalOpen, selectedDocumentFile, closeAddEditFileModal } = context;
                                return (<>

                                    {
                                        currentBusinessClient.businessClientId && <FileManagementModal open={isAddNewFileModalOpen || isEditFileModalOpen}
                                            handleClose={() => closeAddEditFileModal()}
                                            title={isAddNewFileModalOpen ? 'New document/link' : 'Edit document/link'}
                                        >
                                            {isAddNewFileModalOpen && <NewFileManagementForm 
                                                businessClientId={Number(currentBusinessClient.businessClientId)} 
                                                section={selectedSectionInfo?.[0]} 
                                                sectionIndex={selectedSectionInfo?.[1]} 
                                                cancelCallback={() => closeAddEditFileModal()} />}
                                            

                                            {isEditFileModalOpen && <EditFileManagementForm 
                                                documentFile={selectedDocumentFile}
                                                section={selectedSectionInfo?.[0]} 
                                                sectionIndex={selectedSectionInfo?.[1]} 
                                                cancelCallback={() => closeAddEditFileModal()} />}

                                        </FileManagementModal>
                                    }

                                </>)

                            }}

                        </SectionDocumentsContext.Consumer>

                        <CardActions className={classes.buttonActions}>
                            <Button
                                className={classes.saveButton}
                                disabled={!dirty || isSubmitting || !isValid}
                                type='submit'
                                startIcon={currentBusinessClient ? <Save /> : <Add />}
                                variant='contained'
                                color='primary'
                            >
                                {currentBusinessClient ? 'Save' : 'Add Business Client'}
                            </Button>

                            <Button
                                className={classes.pdfButton}
                                disabled={(isNew && !dirty) || isSubmitting || !isValid}
                                type='button'
                                startIcon={<PictureAsPdf />}
                                variant='contained'
                                color='primary'
                                onClick={async () => {

                                    const errors = await validateForm();

                                    if (!isObjectEmpty(errors)) return;

                                    const businessClientId = await onSubmitBeforeDownloadPdf(dirty, setSubmitting, values);

                                    if (businessClientId == null) return;

                                    setIsGeneratingPdf(true);
                                    await downloadBusinessClientPdf(businessClientId);
                                    setIsGeneratingPdf(false);

                                    resetForm();

                                    setTimeout(() => {
                                        history.push('/manage-business-clients');
                                    }, 1000);

                                }}
                            >
                                {'Submit to pdf'}
                            </Button>

                            <Button
                                type='button'
                                variant='contained'
                                color='inherit'
                                startIcon={<Close />}
                                className={classes.cancelButton}
                                onClick={() => history.push('/manage-business-clients')}
                            >
                                Cancel
                            </Button>

                        </CardActions>
                    </SectionDocumentsProvider>
                </Form>)
            }}

        </Formik>

        <Toast autoHideDuration={2500} id='success-message' message={generalSuccessMessage} open={generalSuccessMessage !== null} onClose={() => setGeneralSuccessMessage(null)} variant='success' />

        <Toast id='business-client-success' message={successMessage || `Business Client Added!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
        <Toast
            id='business-client-error'
            autoHideDuration={6000}
            message={
                errorMessage || 'We were unable to create the business client at this time. Please try again later. Please contact Enrollment Alliance support if this issue continues.'
            }
            open={isError}
            onClose={() => setErrorMessage(null)}
            variant='error'
        />

    </>


    )
};


const useStyles = makeStyles((theme: Theme) => ({
    content: {
        marginTop: theme.spacing(1)
    },
    buttonActions: {
        flexGrow: 1,
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        alignItems: 'end',
        '@media (max-width: 450px)': {
            flexDirection: 'column',
            paddingLeft: 0,
        }
    },
    saveButton: {
        '@media (max-width: 450px)': {
            width: '100%'
        }
    },
    pdfButton: {
        '@media (max-width: 450px)': {
            width: '100%',
            marginTop: theme.spacing(0.5),
        }
    },
    cancelButton: {
        '@media (max-width: 450px)': {
            width: '100%',
            marginTop: theme.spacing(0.5),
            marginLeft: `0 !important`
        }
    },
    tabContent: {
        marginTop: theme.spacing(1.5),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        }
    },
    validationAlert: {},
    alertMessage: {
        '&& ul': {
            margin: 0,
            padding: `0 0 0 ${theme.spacing(1)}`
        }
    }
}));
