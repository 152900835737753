import { createBusinessClient, updateBusinessClient } from "../../../../fetch";
import { formatUrl } from "../../../../helpers";
import { IBroker, IBusinessClientDetail, ISystemOfRecord } from "../../../../models";

export const businessClientRes = (currentBusinessClient: IBusinessClientDetail, selectedBroker: IBroker, selectedSystemOfRecord: ISystemOfRecord, values, logoUrl?: string, appLogoUrl?: string) => {

    const { qrCodes, location, ...rest } = values;

    return {
        ...rest,
        name: rest.name,
        brokerId: selectedBroker.brokerId,
        systemOfRecordId: selectedSystemOfRecord.systemOfRecordId,
        caseOverviewUrl: formatUrl(rest.caseOverviewUrl),
        newHireScheduleUrl: formatUrl(rest.newHireScheduleUrl),
        benefitProgramSupportDocumentationUrl: formatUrl(rest.benefitProgramSupportDocumentationUrl),
        logoUrl: formatUrl(logoUrl ? logoUrl : rest.logoUrl),
        appLogoUrl: formatUrl(appLogoUrl ? appLogoUrl : rest.appLogoUrl),
        systemOfRecordUrl: formatUrl(rest.systemOfRecordUrl),
        classes: rest.classes.map(c => {
            return {
                ...c,
                benefitGuideUrl: formatUrl(c.benefitGuideUrl),
                videoUrl: formatUrl(c.videoUrl)
            };
        }),
        contacts: rest.contacts.map(c => {
            return {
                businessClientContactId: c.businessClientContactId,
                businessClientId: c.businessClientId,
                name: c.name,
                email: c.email,
                role: c.role,
                hasDashboardAccess: c.hasDashboardAccess,
                hasBenAdminAccess: c.hasBenAdminAccess,
            }
        }),
        medicalInsurance: rest.medicalInsurance,
        locations: location.map(c => {
            return {
                ...c,
                calendarLinkUrl: formatUrl(c.calendarLinkUrl)
            };
        }),
        mobileAppEnabled: rest.mobileAppEnabled,
        appLogoType: rest.appLogoType,
        homeLogoType: rest.homeLogoType,
        links: rest.links.map(c => {
            if (c.type === 'Instructions') {
                return { ...c };
            }
            return {
                ...c,
                link: formatUrl(c.link)
            };
        }),
        classLocations:
            qrCodes.length > 0
                ? qrCodes.map(code => ({
                    businessClientClassId: code.businessClientClassId,
                    businessClientLocationId: code.businessClientLocationId,
                    url: code.url
                }))
                : [],
        buildSteps: rest.buildSteps
            // remove empty options with no url
            .filter(step => step.url || step.buildStep.includes('Tracker'))
            // remove the default icon field that might show up
            .map(({ icon, ...step }) => {
                return {
                    ...step,
                    buildStepStatus: step.buildStepStatus?.length === 0 ? null : step.buildStepStatus,
                    businessClientBuildStepId: step.businessClientBuildStepId < 0 ? null : step.businessClientBuildStepId,
                    url: !!step.url ? formatUrl(step.url) : step.url
                };
            }),
        totalEligibleEmployees: rest.totalEligibleEmployees ? Number(rest.totalEligibleEmployees) : 0,
        totalEmployees: rest.totalEmployees ? Number(rest.totalEmployees) : 0,
        engagementPackageType: rest.engagementPackageType,
        carrierRepGoals: rest.carrierRepGoals,
        highlightsChallenges: rest.highlightsChallenges,
        specialAttentionBusinessClientEmployees: rest.specialAttentionBusinessClientEmployees.map(c => {
            return {
                ...c,
                businessClientSpecialAttentionEmployeeId: c.businessClientSpecialAttentionEmployeeId < 0 ? 0 : c.businessClientSpecialAttentionEmployeeId
            };
        }),
        dataFileRecipients: rest.dataFileRecipients.map(c => {
            if (currentBusinessClient) {
                return {
                    ...c,
                    businessClientDataFileRecipientId: c.businessClientDataFileRecipientId
                };
            } else {
                return {
                    recipient: c.recipient,
                    otherRecipientDescription: c.otherRecipientDescription
                };
            }
        })
    };
};


export const onSubmitAction = async (values, actions, currentBusinessClient: IBusinessClientDetail, brokers: IBroker[], systemOfRecords: ISystemOfRecord[]) => {
    try {
        const { brokerName, systemOfRecord, location, qrCodes, ...rest } = values;
        const selectedBroker = brokers.find(broker => broker.name === brokerName);
        const selectedSystemOfRecord = systemOfRecords.find(system => system.name === systemOfRecord);


        const getErrorHandling = (res: any) => {
            // the api sends a boolean on success for update
            // and id on creation
            if (res && (typeof res === 'boolean' || typeof res === 'number')) {
                return false;
            }
            if (res?.Errors && Object?.values(Object?.values(res?.Errors)?.[0])?.[0]) {
                // validation errors
                actions.setErrorMessage(Object.values(Object.values(res.Errors)[0])[0] as string);
                actions.showError(true);
                return true;
            }
            if (res?.Detail) {
                actions.setErrorMessage(res?.Detail);
                actions.showError(true);
                return true;
            }
            // server errors
            actions.setErrorMessage('Error, please try again');
            actions.showError(true);
            return true;
        };
        if (currentBusinessClient) {
            const res = await updateBusinessClient(currentBusinessClient.businessClientId, businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values));
            const hasErrors = getErrorHandling(res);
            return hasErrors;
        } else {
            const res = await createBusinessClient(businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values));
            const hasErrors = getErrorHandling(res);

            return hasErrors;
            //   if (companyLogo) {
            //     const newBusinessClientId = res;
            //     // If an image was selected, perform actions for uploading and updating the business client record
            //     const create = new FormData();
            //     create.append('image', companyLogo);
            //     const imageUrl = await storeBusinessClientCompanyLogo(newBusinessClientId, create);
            //     const logo = imageUrl.toString();
            //     let appLogoHeader;
            //     if (appLogo) {
            //       const create = new FormData();
            //       create.append('image', companyLogo);
            //       const imageUrl = await storeBusinessClientAppLogo(newBusinessClientId, create);
            //       appLogoHeader = imageUrl.toString();
            //     }
            //     // After uploading image to get a URL, we need to update the client record just created with the URL
            //     const updateRes = await updateBusinessClient(newBusinessClientId, businessClientRes(selectedBroker, selectedSystemOfRecord, values, logo, appLogoHeader));
            //     if (updateRes.Errors && Object.values(updateRes.Errors)[0] && Object.values(Object.values(updateRes.Errors)[0])[0]) {
            //       setErrorMessage(Object.values(Object.values(updateRes.Errors)[0])[0] as string);
            //       return showError(true);
            //     }
            //   }


        }
    } catch (error) {
        console.log(error);
        if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
            actions.setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
        }
        actions.showError(true);
    }
}