import { FC, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, InputLabel, Select, MenuItem, Grid, Button, Divider, TextField, InputAdornment, ButtonGroup } from '@mui/material';
import { FilterList, Close, ArrowDropDown, ArrowDropUp, Search, GetApp } from '@mui/icons-material';
import { getEnrollmentMeetingOutcomes, getFollowUpItems, getSchedulingStatuses } from '../../fetch';
import { useParams } from 'react-router-dom';
import {
  IEmployeeFilter,
  ENHANCED_STATUS_FILTERS_ENUM,
  SCHEDULING_STATUS_PARENT_ENUM,
  ENROLLMENT_MEETING_OUTCOME_PARENT_ENUM,
  POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT_ENUM,
  IEmployee
} from '../../models';
// helpers
import { formatShortFriendlyDateWithTime, formatDate } from '../../helpers';
import { getEmployees } from '../../fetch';
import { TimeZoneContext } from '../../context/timezone';

interface IEmployeeFilters {
  employees: IEmployee[];
  selectedEnrollmentType: string;
  applyFilters: (clearFilters?: boolean, clearSearch?: boolean, clearProgressBar?: boolean) => void;
  setSearchValue: (val: string) => void;
  searchValue: string;
  handleSearch: (clearSearch?: boolean) => void;
  isFetching: boolean;
  selectedMeetingStatus: string;
  setSelectedMeetingStatus: (val: string) => void;
  selectedEnrollmentOutcome: string;
  setSelectedEnrollmentOutcome: (val: string) => void;
  selectedPostEnrollment: string;
  setSelectedPostEnrollment: (val: string) => void;
  enhancedFilterType: ENHANCED_STATUS_FILTERS_ENUM;
  hasAppliedFilters: boolean;
  setHasAppliedFilters: (val: boolean) => void;
  selectedProgressBar?: string;
  selectedSort: string;
  sortDirection: string;
  effectiveDateMinimum: string;
  effectiveDateMaximum: string;
}

export const EmployeeFilters: FC<IEmployeeFilters> = ({
  employees,
  applyFilters,
  selectedPostEnrollment,
  setSelectedPostEnrollment,
  searchValue,
  setSearchValue,
  handleSearch,
  isFetching,
  selectedMeetingStatus,
  setSelectedMeetingStatus,
  selectedEnrollmentOutcome,
  setSelectedEnrollmentOutcome,
  enhancedFilterType,
  hasAppliedFilters,
  setHasAppliedFilters,
  selectedProgressBar,
  selectedSort,
  sortDirection,
  effectiveDateMinimum,
  effectiveDateMaximum,
  selectedEnrollmentType
}) => {
  const [isMobileFilterButtonOpen, toggleMobileFilter] = useState<boolean>(false);
  const [isLoadingSchedulingStatuses, setIsLoadingSchedulingStatuses] = useState(true);
  const [schedulingStatuses, setSchedulingStatuses] = useState<IEmployeeFilter[]>([]);
  const [isLoadingFollowUpItems, setIsLoadingFollowUpItems] = useState(true);
  const [followUpItems, setFollowUpItems] = useState<IEmployeeFilter[]>([]);
  const [isLoadingEnrollmentMeetingOutcomes, setIsLoadingEnrollmentMeetingOutcomes] = useState(true);
  const [enrollmentMeetingOutcomes, setEnrollmentMeetingOutcomes] = useState<IEmployeeFilter[]>([]);
  const [isExporting, setExporting] = useState<boolean>(false);

  const classes = useStyles({ isMobileFilterButtonOpen });

  const { businessClientId } = useParams();

  // Time Zone
  const { timeZone } = useContext(TimeZoneContext);

  const fetchSchedulingStatuses = async (status?: SCHEDULING_STATUS_PARENT_ENUM) => {
    try {
      const res = await getSchedulingStatuses(status);
      if (res) {
        setSchedulingStatuses(res);
      } else {
        console.error(`error loading scheduling statuses`);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingSchedulingStatuses(false);
  };

  const fetchFollowUpItems = async (status?: POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT_ENUM) => {
    try {
      const res = await getFollowUpItems(status);
      if (res) {
        setFollowUpItems(res);
      } else {
        console.error(`error loading scheduling statuses`);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingFollowUpItems(false);
  };

  const fetchEnrollmentMeetingOutcomes = async (status?: ENROLLMENT_MEETING_OUTCOME_PARENT_ENUM) => {
    try {
      const res = await getEnrollmentMeetingOutcomes(status);
      if (res) {
        setEnrollmentMeetingOutcomes(res);
      } else {
        console.error(`error loading scheduling statuses`);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingEnrollmentMeetingOutcomes(false);
  };

  useEffect(() => {
    fetchSchedulingStatuses();
    fetchEnrollmentMeetingOutcomes();
    fetchFollowUpItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enhancedFilterType === 'SchedulingStatus') {
      fetchSchedulingStatuses(selectedProgressBar as SCHEDULING_STATUS_PARENT_ENUM);
    }
    if (enhancedFilterType === 'EnrollmentMeetingOutcome') {
      fetchEnrollmentMeetingOutcomes(selectedProgressBar as ENROLLMENT_MEETING_OUTCOME_PARENT_ENUM);
    }
    if (enhancedFilterType === 'PostEnrollmentFollowUpItem') {
      fetchFollowUpItems(selectedProgressBar as POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT_ENUM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgressBar]);

  const statusFilterOptions = schedulingStatuses || [];
  const enrollmentFilterOptions = enrollmentMeetingOutcomes || [];
  const postFollowUpFilterOptions = followUpItems || [];

  const exportData = async () => {
    try {
      setExporting(true);
      const res = await getEmployees(businessClientId, {
        perPage: '2147483647',
        sortBy: selectedSort,
        sortDirection,
        schedulingStatus: selectedMeetingStatus || null,
        enrollmentMeetingOutcome: selectedEnrollmentOutcome || null,
        postEnrollmentFollowupItem: selectedPostEnrollment || null,
        dashboardEnrollmentMeetingOutcome: enhancedFilterType === 'EnrollmentMeetingOutcome' && selectedProgressBar ? selectedProgressBar : null,
        dashboardPostEnrollmentFollowupItem: enhancedFilterType === 'PostEnrollmentFollowUpItem' && selectedProgressBar ? selectedProgressBar : null,
        dashboardSchedulingStatus: enhancedFilterType === 'SchedulingStatus' && selectedProgressBar ? selectedProgressBar : null,
        search: searchValue,
        effectiveDateMinimum,
        effectiveDateMaximum
      });
      const ws = XLSX.utils.json_to_sheet(
        res.records.map(employee => {
          if (selectedEnrollmentType !== 'New Hires') {
            return {
              'First Name': employee.firstName || '',
              'Last Name': employee.lastName || '',
              'Scheduling Status': employee.meetingSchedulingStatus || '',
              'Next Meeting': employee.nextScheduledMeeting ? formatShortFriendlyDateWithTime(employee.nextScheduledMeeting, timeZone) : '',
              'Meeting Outcome': employee.enrollmentMeetingOutcome || '',
              'Post Enrollment Follow-Up': employee.postEnrollmentFollowUpItem || '',
              'Communication History': employee.lastCommunication ? formatShortFriendlyDateWithTime(employee.lastCommunication, timeZone) : ''
            };
          }
          return {
            'First Name': employee.firstName || '',
            'Last Name': employee.lastName || '',
            'Effective Date': employee.effectiveDate ? formatDate(employee.effectiveDate) : '',
            'Scheduling Status': employee.meetingSchedulingStatus || '',
            'Next Meeting': employee.nextScheduledMeeting ? formatShortFriendlyDateWithTime(employee.nextScheduledMeeting, timeZone) : '',
            'Meeting Outcome': employee.enrollmentMeetingOutcome || '',
            'Post Enrollment Follow-Up': employee.postEnrollmentFollowUpItem || '',
            'Communication History': employee.lastCommunication ? formatShortFriendlyDateWithTime(employee.lastCommunication, timeZone) : ''
          };
        })
      );
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(data, `employees_${formatDate(new Date().toISOString())}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <Button
        color='secondary'
        variant='contained'
        className={classes.mobileButton}
        startIcon={<FilterList />}
        endIcon={isMobileFilterButtonOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => {
          toggleMobileFilter(!isMobileFilterButtonOpen);
        }}
      >
        Filters
      </Button>
      {!isMobileFilterButtonOpen && <Divider className={classes.divider} />}
      <Grid container spacing={1} alignItems='center' className={classes.wrapper}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            size='small'
            fullWidth
            variant='outlined'
            placeholder='Search Employees...'
            name='search'
            value={searchValue}
            disabled={isFetching}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position='start'
                  className={classes.searchIcon}
                  onClick={() => {
                    if (searchValue.length > 0) {
                      handleSearch();
                    }
                  }}
                >
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue ? (
                <InputAdornment
                  position='end'
                  className={classes.searchIcon}
                  onClick={() => {
                    setSearchValue('');
                    handleSearch(true);
                  }}
                >
                  <Close />
                </InputAdornment>
              ) : null
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && searchValue.length > 0) {
                setHasAppliedFilters(true);
                handleSearch();
              }
            }}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormControl fullWidth variant='outlined' size='small'>
            <InputLabel htmlFor='meetingStatus' className={classes.ellipsis}>
              Scheduling Status
            </InputLabel>
            <Select
              name='meetingStatus'
              labelId='meetingStatus'
              id='meetingStatus'
              disabled={isLoadingSchedulingStatuses}
              value={selectedMeetingStatus}
              onChange={({ target: { value } }) => setSelectedMeetingStatus(value as string)}
            >
              {statusFilterOptions.map((status, index) => {
                return (
                  <MenuItem key={`${index}`} value={status.value}>
                    {status.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormControl fullWidth variant='outlined' size='small'>
            <InputLabel htmlFor='enrollmentOutcome' className={classes.ellipsis}>
              Meeting Outcome
            </InputLabel>
            <Select
              disabled={isLoadingFollowUpItems}
              name='enrollmentOutcome'
              labelId='enrollmentOutcome'
              id='enrollmentOutcome'
              value={selectedEnrollmentOutcome}
              onChange={({ target: { value } }) => setSelectedEnrollmentOutcome(value as string)}
            >
              {enrollmentFilterOptions.map((outcome, index) => {
                return (
                  <MenuItem key={`${index}`} value={outcome.value}>
                    {outcome.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormControl fullWidth variant='outlined' size='small'>
            <InputLabel htmlFor='postEnrollment' className={classes.ellipsis}>
              Post Enrollment Follow-Up
            </InputLabel>
            <Select
              disabled={isLoadingEnrollmentMeetingOutcomes}
              name='postEnrollment'
              labelId='postEnrollment'
              id='postEnrollment'
              value={selectedPostEnrollment}
              onChange={({ target: { value } }) => setSelectedPostEnrollment(value as string)}
            >
              {postFollowUpFilterOptions.map((enrollment, index) => {
                return (
                  <MenuItem key={`${index}`} value={enrollment.value}>
                    {enrollment.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <ButtonGroup className={classes.buttonGroup}>
            <Button
              color='primary'
              variant='contained'
              disabled={isFetching}
              className={classes.button}
              startIcon={<FilterList />}
              onClick={() => {
                setHasAppliedFilters(true);
                applyFilters();
              }}
            >
              Apply Filters
            </Button>
            {hasAppliedFilters && (
              <Button
                className={clsx(classes.button, classes.resetButton)}
                variant='contained'
                startIcon={<Close />}
                onClick={() => {
                  setHasAppliedFilters(false);
                  setSelectedPostEnrollment('');
                  setSelectedEnrollmentOutcome('');
                  setSelectedMeetingStatus('');
                  setSearchValue('');
                  applyFilters(true, true, true);
                }}
              >
                Reset
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={1}>
          <Button
            id='export-employees'
            disabled={isFetching || isExporting || employees.length === 0}
            className={classes.button}
            startIcon={<GetApp />}
            onClick={() => exportData()}
            color='primary'
            variant='contained'
          >
            {isExporting ? 'Loading...' : 'Export'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles<Theme, { isMobileFilterButtonOpen?: boolean }>((theme: Theme) => ({
  mobileButton: {
    width: '100%',
    marginBottom: ({ isMobileFilterButtonOpen }) => (isMobileFilterButtonOpen ? 0 : theme.spacing(1)),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  button: {
    textTransform: 'capitalize',
    width: '48%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  resetButton: {
    marginLeft: 11,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  wrapper: ({ isMobileFilterButtonOpen }) =>
    isMobileFilterButtonOpen
      ? {
          marginTop: 10,
          marginBottom: 10,
          display: 'flex'
        }
      : {
          display: 'none',
          marginBottom: theme.spacing(1),
          [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginBottom: theme.spacing(1)
          }
        },
  divider: {
    display: 'block',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  ellipsis: {
    fontSize: 14,
    width: 'auto',
    whiteSpace: 'normal',
    overflow: 'visible',
    '@media (min-width: 960px)': {
      width: '9rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    '@media (min-width: 1400px)': {
      width: 'auto',
      whiteSpace: 'normal',
      overflow: 'visible'
    }
  },
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  buttonGroup: {
    width: '100%'
  }
}));
