import { FC, useState } from 'react';
import { Box, AppBar, Tabs, Tab, Typography, Menu, MenuItem, Button, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';

interface IBusinessClientDetailTabsProps {
  validateForm: () => void;
  setSelectedTab: (val: number) => void;
  selectedTab: number;
  busincessClientInfoTabValid: boolean;
  mobileAppInfoTabValid: boolean;
  enrollmentBuilderTabValid: boolean;
  enrollmentOverviewTabValid: boolean;
}

let tabOptions = [
  {
    value: 0,
    label: 'Business Client Info'
  },
  {
    value: 1,
    label: 'Enrollment Overview'
  },
  {
    value: 2,
    label: 'Mobile App Info'
  },
  {
    value: 3,
    label: 'Enrollment Builder'
  }
];

export const BusinessClientDetailTabs: FC<IBusinessClientDetailTabsProps> = ({
  enrollmentBuilderTabValid,
  mobileAppInfoTabValid,
  busincessClientInfoTabValid,
  enrollmentOverviewTabValid,
  validateForm,
  setSelectedTab,
  selectedTab
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuOption = tabOptions.find(o => o.value === selectedTab);
  return (
    <AppBar position='static' color='default' component='div' className={classes.tabBar}>
      {isMobile ? (
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h4' color='primary' sx={{ fontWeight: 600 }}>
            {menuOption?.label}
          </Typography>
          <Button
            aria-label='more'
            id='edit-button'
            aria-controls={isOpen ? 'long-menu' : undefined}
            aria-expanded={isOpen ? 'true' : undefined}
            aria-haspopup='true'
            endIcon={<MoreVert />}
            onClick={handleMenuClick}
          >
            Edit
          </Button>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'edit-button'
            }}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleMenuClose}
          >
            {tabOptions
              .filter(tab => tab.value !== menuOption.value)
              .map((tab, index) => (
                <MenuItem
                  key={`${index}`}
                  onClick={() => {
                    setSelectedTab(tab.value);
                    handleMenuClose();
                  }}
                  sx={{
                    color: theme.palette.primary.main
                  }}
                >
                  {tab.label}
                </MenuItem>
              ))}
          </Menu>
        </Box>
      ) : (
        <>
          <Tabs
            value={selectedTab}
            indicatorColor='primary'
            textColor='primary'
            onChange={(_, value) => {
              validateForm();
              setSelectedTab(value);
            }}
          >
            {tabOptions.map((tab, index) => {
              return (
                <Tab
                  id={`tab-id-${index}`}
                  key={`${index}`}
                  aria-controls={`tab-content-${index}`}
                  label={tab.label}
                  classes={{
                    root: classes.tabRoot,
                    textColorPrimary: clsx(
                      classes.tabText,
                      (tab.label === 'Business Client Info' && !busincessClientInfoTabValid) ||
                        (tab.label === 'Enrollment Overview' && !enrollmentOverviewTabValid) ||
                        (tab.label === 'Mobile App Info' && !mobileAppInfoTabValid) ||
                        (tab.label === 'Enrollment Builder' && !enrollmentBuilderTabValid)
                        ? classes.tabErrors
                        : ''
                    ),
                    selected: classes.tabSelected
                  }}
                />
              );
            })}
          </Tabs>
        </>
      )}
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  tabRoot: {
    minWidth: 0,
    fontSize: 18,
    padding: `15px 20px`,
    textTransform: 'none'
  },
  tabText: {
    color: theme.palette.grey[400]
  },
  tabSelected: {
    fontWeight: 'bold'
  },
  tabErrors: {
    color: theme.palette.error.main
  }
}));
