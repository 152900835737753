// icons
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Link, Loader, Page, PageTitle } from '../../components';
import { UserContext } from '../../context';
// fetch
import { getEnrollmentBuilderDashboard } from '../../fetch';
// helpers
import { formatDueDateKey, getBuildStepOptions } from '../../helpers';
// models
import { EUserRoles, IBuildStep, IEnrollmentBuilderDashboard, ITargetDate } from '../../models';
import { EnrollmentBuilderPod } from '../admin/business-client-detail/EnrollmentBuilderPod';
import { EnrollmentBuilderTimeline } from '../admin/business-client-detail/EnrollmentBuilderTimeline';

export const compileDefaultSteps = (id: number | string | null, steps: IBuildStep[]) => {
  // If project tracker step, set dynamic URL, else return value from API
  let updatedSteps = steps.map(step => {
    return { ...step, url: step.buildStep.includes('Tracker') ? (id ? `/business-clients/${id}/employees` : '/business-clients') : step.url };
  });
  // If only project tracker step, send empty array to trigger the redirect properly
  if (steps.length && !steps.filter(step => !step.buildStep.includes('Tracker')).length) {
    updatedSteps = [];
  }
  return updatedSteps;
};
export const Builder = () => {
  const classes = useStyles();

  const { businessClientId } = useParams();

  const [isLoadingDashboard, setIsLoadingDashboard] = useState<boolean>(true);
  const [redirectToEmployees, setRedirectToEmployees] = useState<boolean>(false);
  const [enrollmentBuilderDashboardData, setEnrollmentBuilderDashboard] = useState<IEnrollmentBuilderDashboard>();

  // Used for Build Steps and Timeline
  const { user, isFetching } = useContext(UserContext);
  const [buildSteps, setBuildSteps] = useState<IBuildStep[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [projectType, setProjectType] = useState<string | null>(null);
  const [dueDates, setDueDates] = useState<ITargetDate | null>(null);

  const hasBuilderPermissions = user && !isFetching && (user.roles.includes(EUserRoles.ADMIN) || user.roles.includes(EUserRoles.BROKER));

  const fetchEnrollmentBuilderDashboard = async () => {
    try {
      const res = await getEnrollmentBuilderDashboard(businessClientId);
      setEnrollmentBuilderDashboard(res);
      const defaultSteps = compileDefaultSteps(businessClientId, res.buildSteps);
      if (!defaultSteps.length) {
        setRedirectToEmployees(true);
      }
      setBuildSteps(defaultSteps);
      setStartDate(res.enrollmentStart);
      setEndDate(res.enrollmentEnd);
      setProjectType(res.targetDates?.projectType);
      setDueDates(res.targetDates);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDashboard(false);
    }
  };

  const options = getBuildStepOptions(projectType, buildSteps);

  useEffect(() => {
    fetchEnrollmentBuilderDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(!hasBuilderPermissions || redirectToEmployees) && (
        // If nothing to display, or no permissions, then redirect to the actual dashboard
        <Redirect to={`/business-clients/${businessClientId}/employees`} />
      )}
      <Page title={enrollmentBuilderDashboardData ? enrollmentBuilderDashboardData.name : 'Loading...'}>
        {isLoadingDashboard && <Loader position='centered' />}
        {enrollmentBuilderDashboardData && !isLoadingDashboard && (
          <>
            <PageTitle title={enrollmentBuilderDashboardData.name} />
            {/* The following Enrollment Builder content should only display for Admins and Brokers */}
            {hasBuilderPermissions && buildSteps && dueDates && (
              <Grid item container className={classes.enrollmentBuilderWrapper}>
                <Grid item container spacing={1}>
                  {options.map((option, index) => {
                    let key = formatDueDateKey(option);
                    return (
                      option.url && (
                        <Grid key={`${index}`} item xs={12} sm={6} md={3}>
                          {option?.buildStep.includes('Tracker') ? (
                            <Link to={option.url}>
                              <EnrollmentBuilderPod option={option} dueDate={dueDates[key]} />
                            </Link>
                          ) : (
                            <a className={classes.card} href={option.url} rel='noopener noreferrer' target='_blank'>
                              <EnrollmentBuilderPod option={option} dueDate={dueDates[key]} />
                            </a>
                          )}
                        </Grid>
                      )
                    );
                  })}
                </Grid>

                {startDate && <EnrollmentBuilderTimeline dueDates={dueDates} steps={buildSteps} startDate={startDate} endDate={endDate} />}
              </Grid>
            )}
          </>
        )}
      </Page>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  },
  clearSearchButton: {
    '@media (min-width: 408px)': {
      marginTop: 3,
      marginLeft: theme.spacing(1)
    }
  },
  searchWrapper: {
    margin: theme.spacing(1, 0)
  },
  addEmployeeWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media (min-width: 700px)': {
      flexDirection: 'row',
      marginBottom: theme.spacing(1)
    }
  },
  actionButtons: {
    display: 'flex'
  },
  editButton: {
    padding: 3
  },
  deleteButton: {
    color: theme.palette.error.main,
    padding: 3
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  enrollmentTypeWrapper: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '@media (min-width: 700px)': {
      width: '12rem',
      marginBottom: 0,
      marginRight: theme.spacing(1)
    }
  },
  enrollmentOptionsWrapper: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '@media (min-width: 1280px)': {
      width: 'auto',
      flex: 1
    }
  },
  subHeading: {
    marginBottom: theme.spacing(1)
  },
  mobileAddEmployee: {
    marginBottom: theme.spacing(1)
  },
  viewText: {
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  viewByWrapper: {
    '&& .MuiInput-underline': {
      '&::before': {
        border: 0
      },
      '&::after': {
        border: 0
      }
    },
    '@media (min-width: 600px)': {
      position: 'absolute',
      right: 45
    },
    '@media (min-width: 812px)': {
      right: 24
    }
  },
  enhancedSelect: {
    paddingBottom: 0
  },
  enhancedSelectIcon: {
    top: 0
  },
  uploadButton: {
    backgroundColor: 'rgb(76, 75, 76)',
    color: theme.palette.common.white
  },
  pieChartWrapper: {
    marginBottom: theme.spacing(1)
  },
  enrollmentBuilderWrapper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    textDecoration: 'none',
    '&:hover': {
      boxShadow: `0px 2px 1px -1px ${theme.palette.primary.main}, 0px 1px 1px 0px ${theme.palette.primary.main}, 0px 1px 3px 0px ${theme.palette.primary.main}`
    },
    '&.active': {
      boxShadow: `0px 0px 1px 2px ${theme.palette.primary.main}`
    }
  }
}));
