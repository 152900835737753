import React, { createContext, useContext, useState } from 'react';
import { AllBusinessClientSubMenusTypes, BusinessClientMenusTypes } from '../../../../pages/admin/business-client-detail/BusinessClientDetailPage/MenuData/types';

// Create a context for menus, submenus, and errors
export const MenuSubMenuWithErrorsContext = createContext<{
  menusWithErrors: BusinessClientMenusTypes[];
  subMenusWithErrors: AllBusinessClientSubMenusTypes[];
  setMenusWithErrors: (menusWithErrors: BusinessClientMenusTypes[]) => void;
  setSubMenusWithErrors: (subMenusWithErrors: AllBusinessClientSubMenusTypes[]) => void;
} | undefined>(undefined);

// Create a context provider component
export const MenuSubMenuWithErrorsProvider: React.FC = ({ children }) => {
  const [menusWithErrors, setMenus] = useState<BusinessClientMenusTypes[]>([]);
  const [subMenusWithErrors, setSubmenus] = useState<AllBusinessClientSubMenusTypes[]>([]);


  const setMenusWithErrors = (newMenusWithErrors: BusinessClientMenusTypes[]) => {
    setMenus(newMenusWithErrors);
  };

  const setSubMenusWithErrors = (newSubMenusWithErrors: AllBusinessClientSubMenusTypes[]) => {
    setSubmenus(newSubMenusWithErrors);
  };


  return (
    <MenuSubMenuWithErrorsContext.Provider value={{ menusWithErrors, subMenusWithErrors, setMenusWithErrors, setSubMenusWithErrors }}>
      {children}
    </MenuSubMenuWithErrorsContext.Provider>
  );
};

// Custom hook to access the context
export const useMenuSubMenuWithErrors = () => {
  const context = useContext(MenuSubMenuWithErrorsContext);
  if (context === undefined) {
    throw new Error('useMenuSubMenuWithErrors must be used within a MenuSubMenuWithErrorsProvider');
  }
  return context;
};
