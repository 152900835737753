

import { useFormikContext } from 'formik';

import { Box, Typography, Menu, MenuItem, Button } from '@mui/material';
import { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import { useLateralFormMenuControl } from '../../../../../contexts/LateralFormMenuControlContext';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import { AllBusinessClientSubMenusTypes, TabOptionType } from '../../../../MenuData/types';

interface IMobileTabsProps<T> {
    validateForm: () => void;
    tabOptions: TabOptionType<T>[]
}

export const MobileTabs = <T extends AllBusinessClientSubMenusTypes>({ validateForm, tabOptions }: IMobileTabsProps<T>) => {

    const theme = useTheme();

    const { selectedSubMenu, setSubMenu } = useLateralFormMenuControl();

    const selectedTab = tabOptions.find(t => t.subMenuRelatedId === selectedSubMenu)?.value ?? 0;

    const { errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);

    const handleMenuClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const menuOption = tabOptions.find(o => o.value === selectedTab);

    return (

        <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography variant='h4' color='primary' sx={{ fontWeight: 600 }}>
                {menuOption?.label}
            </Typography>
            <Button
                aria-label='more'
                id='edit-button'
                aria-controls={isOpen ? 'long-menu' : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
                aria-haspopup='true'
                endIcon={<MoreVert />}
                onClick={handleMenuClick}
            >
                Tabs
            </Button>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'edit-button'
                }}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleMenuClose}
            >
                {tabOptions
                    .filter(tab => tab.value !== menuOption.value)
                    .map((tab, index) => {

                        const validateAction = menusSubMenusValidationStatusMap.get(tab.subMenuRelatedId);
                        const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

                        return (
                            <MenuItem
                                key={`${index}`}
                                onClick={() => {

                                    const newSelectedTab = tabOptions.find(t => t.value === tab.value);
                                    setSubMenu(newSelectedTab.subMenuRelatedId);

                                    handleMenuClose();
                                }}
                                sx={{
                                    color: IsThereSomethingInvalidInTheTab ? theme.palette.error.main : theme.palette.primary.main
                                }}
                            >
                                {tab.label}
                            </MenuItem>
                        )
                    })}
            </Menu>
        </Box>

    )
}

