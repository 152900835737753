import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { AssignmentLate } from '@mui/icons-material';

interface INoResultsProps {
  message: string;
}

export const NoResultsEnrollment: FC<INoResultsProps> = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <AssignmentLate className={classes.icon} />
      <Typography variant='h2' className={classes.text}>
        {message}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2)
  },
  icon: {
    fontSize: '4rem',
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  text: {
    textAlign: 'center'
  }
}));
