import { axios, getUserAccessTokenCookie } from '../helpers';
import {
  IBusinessClientRes,
  IBusinessClientPost,
  IBusinessClientDetail,
  ISystemOfRecordRes,
  IDropdownResponse,
  IBusinessClientDashboard,
  IImageUploadRes,
  ITargetDate,
  IEnrollmentBuilderDashboard,
  IMedicalInsuranceOptions,
  IFinancialOptions,
  IInsuranceCoverageOptions,
  IDefaultDropdownsOptions,
  IBusinessClassOptions,
  IGeneralDropdownsOptions,
  IMinimalAddBusinessClient
} from '../models';

export const getBusinessClients = async (filters: {
  page?: number;
  perPage: number | string;
  sortBy?: string;
  sortDirection?: string;
  search?: string;
}): Promise<IBusinessClientRes> => {
  try {
    const res = await axios.get(
      `BusinessClients`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      filters
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBusinessClient = async (id: number): Promise<IBusinessClientDetail> => {
  try {
    const res = await axios.get(`BusinessClients/${id}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

//

export const createBusinessClientWithMinimalData = async (data: IMinimalAddBusinessClient) => {
  try {
    const res = await axios.post(`BusinessClients/minimal-data`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const createBusinessClient = async (data: IBusinessClientPost) => {
  try {
    const res = await axios.post(`BusinessClients`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const downloadBusinessClientPdf = async (id: number) => {
  try {
    const res = await axios.get(`BusinessClients/${id}/download-pdf`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      },
    });

    const blob = new Blob([res.data], { type: 'application/pdf' });

    // Create a URL for the blob object
    const url = window.URL.createObjectURL(blob);

    // Create a link element and simulate a click to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Enrollment Overview.pdf'; // Specify the desired file name
    link.click();

    // Clean up by revoking the blob URL
    window.URL.revokeObjectURL(url);

    return true;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const updateBusinessClient = async (id: number, data: IBusinessClientPost) => {
  try {
    const res = await axios.put(`BusinessClients/${id}`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const deleteBusinessClient = async (id: number) => {
  try {
    const res = await axios.delete(`BusinessClients/${id}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getSystemOfRecords = async (filters: { page?: number; perPage?: number | string; sortBy?: string; sortDirection?: string }): Promise<ISystemOfRecordRes> => {
  try {
    const res = await axios.get(
      `SystemsOfRecord`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      filters
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getWaitingPeriods = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`WaitingPeriods`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBusinessClassOptions = async (): Promise<IBusinessClassOptions> => {
  try {
    const res = await axios.get(`BusinessClients/business-class-options`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};


export const getBusinessClientDashboard = async (id: number, type?: string, newHireMinimum?: string, newHireMaximum?: string): Promise<IBusinessClientDashboard> => {
  try {
    const res = await axios.get(
      `BusinessClients/${id}/Dashboard`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      {
        type,
        EffectiveDateMinimum: newHireMinimum,
        EffectiveDateMaximum: newHireMaximum
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const storeBusinessClientCompanyLogo = async (id: number, data: FormData): Promise<IImageUploadRes> => {
  try {
    const res = await axios.put(`BusinessClients/${id}/logo/HomeLogo`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const storeBusinessClientAppLogo = async (id: number, data: FormData): Promise<IImageUploadRes> => {
  try {
    const res = await axios.put(`BusinessClients/${id}/logo/AppLogo`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBusinessClientLinkTypes = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/link-types`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBusinessClientProjectTypes = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/project-types`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBusinessClientTargetDate = async (startDate: string, projectType: string): Promise<ITargetDate> => {
  try {
    const res = await axios.get(
      `BusinessClients/target-date`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      {
        startDate,
        projectType
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
export const getEnrollmentBuilderDashboard = async (id: number): Promise<IEnrollmentBuilderDashboard> => {
  try {
    const res = await axios.get(`BusinessClients/${id}/enrollment-builder`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getStates = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/states`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getIndustries = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/industries`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getIndustriesTiers = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/industries-tiers`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBenAdminBuildTypes = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/ben-admin-build-types`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getEngagementPercentages = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/engagement-percentages`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getMedicalInsuranceOptionsRequest = async (): Promise<IMedicalInsuranceOptions> => {
  try {
    const res = await axios.get(`BusinessClients/medical-insurance-options`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getDefaultDropdownsOptionsRequest = async (): Promise<IDefaultDropdownsOptions> => {
  try {
    const res = await axios.get(`BusinessClients/default-dropdowns-options`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getGeneralDropdownsOptionsRequest = async (): Promise<IGeneralDropdownsOptions> => {
  try {
    const res = await axios.get(`BusinessClients/general-dropdowns-options`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};


export const getFinancialOptionsRequest = async (): Promise<IFinancialOptions> => {
  try {
    const res = await axios.get(`BusinessClients/financial-options`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getInsuranceCoverageOptionsRequest = async (): Promise<IInsuranceCoverageOptions> => {
  try {
    const res = await axios.get(`BusinessClients/insurance-coverage-options`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getAnticipatedSupports = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/anticipated-supports`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getContactRoles = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/contact-roles`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getDataFileRecipients = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`BusinessClients/data-file-recipients`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
