import { axios, getUserAccessTokenCookie } from '../helpers';
import { IDropdownResponse } from '../models';

export const getSectionDocumentsStatus = async (): Promise<IDropdownResponse[]> => {
  try {
    const res = await axios.get(`SectionDocuments/section-documents-status`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const deleteEssentialDoc = async (sectionId: number, essentialDocId: number) => {
  try {
    const res = await axios.delete(`SectionDocuments/${sectionId}/EssentialDoc/${essentialDocId}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
}

export const updateSectionStatus = async (sectionId: number, newStatus: { newStatus: string }) => {
  try {
    const res = await axios.patch(`SectionDocuments/${sectionId}/Status`, newStatus, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
}

export const insertNewEssentialDoc = async (sectionId: number, data: FormData) => {
  try {
    const res = await axios.patch(`SectionDocuments/${sectionId}/EssentialDoc`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
}

export const updateEssentialDoc = async (sectionId: number, essentialDocId: number, data: FormData) => {
  try {
    const res = await axios.patch(`SectionDocuments/${sectionId}/EssentialDoc/${essentialDocId}`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
}

export const createSectionDocuments = async (data: FormData) => {
  try {
    const res = await axios.post(`SectionDocuments`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};