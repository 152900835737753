import { FC, useRef, useState } from 'react'
import { Box, IconButton } from '@mui/material';

import { Typography } from '@mui/material';

import { IDropdownResponse, ISectionDocuments } from '../../../../../../../../../models';
import EssentialDoc from '../EssentialDoc/EssentialDoc';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import { createSectionDocuments, updateSectionStatus } from '../../../../../../../../../fetch/section-documents';
import { RequestState } from '../../../../../../../../../models/request-state';
import { useSectionDocuments } from '../contexts/SectionDocumentsContext';
import useErrorMessage from '../../../../../../../../../context/ErrorMessageCtx';
import makeStyles from '@mui/styles/makeStyles';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import useSuccessMessage from '../../../../../../../../../context/SuccessMessageCtx';
import { useLateralFormMenuControl } from '../../../../../../contexts/LateralFormMenuControlContext';
import { AllBusinessClientSubMenusTypes, BusinessClientMenusTypes, SubMenuAccordionCategoryTypes } from '../../../../../MenuData/types';

interface IDocSectionProps {
    businessClientId: string;
    section: ISectionDocuments;
    index: number;
    optionsResponse: RequestState<IDropdownResponse[]>;
}

const sectionMenuSubmenuDictionary: { [key: string]: { menu: BusinessClientMenusTypes, submenu: AllBusinessClientSubMenusTypes } } = {

    'MedicalInsurance': { menu: 'product-details', submenu: 'medical' },
    'HealthSavingsAccount': { menu: 'product-details', submenu: 'financial' },
    'HealthReimbursementAccount': { menu: 'product-details', submenu: 'financial' },
    'FlexibleSpendingAccount': { menu: 'product-details', submenu: 'financial' },
    'DependentCareFlexSpendingAccount': { menu: 'product-details', submenu: 'financial' },
    'LimitedPurposeFlexSpendingAccount': { menu: 'product-details', submenu: 'financial' },

    'Dental': { menu: 'product-details', submenu: 'ancillary' },
    'Vision': { menu: 'product-details', submenu: 'ancillary' },
    'ShortTermDisability': { menu: 'product-details', submenu: 'ancillary' },
    'LongTermDisability': { menu: 'product-details', submenu: 'ancillary' },
    'BasicLife': { menu: 'product-details', submenu: 'ancillary' },
    'VoluntaryLife': { menu: 'product-details', submenu: 'ancillary' },

    'AccidentCoverage': { menu: 'product-details', submenu: 'worksite' },
    'HospitalCoverage': { menu: 'product-details', submenu: 'worksite' },
    'CancerInsurance': { menu: 'product-details', submenu: 'worksite' },
    'CriticalIllnessInsurance': { menu: 'product-details', submenu: 'worksite' },
    'PortableLifeInsurance': { menu: 'product-details', submenu: 'worksite' },

    'SupplementalProducts': { menu: 'product-details', submenu: 'supplemental' },
}

export const DocSection: FC<IDocSectionProps> = ({ businessClientId, section, index, optionsResponse }) => {
    const classes = useStyles();
    const [, setErrorMessage] = useErrorMessage();
    const [, setSuccessMessage] = useSuccessMessage();
    const { setMenuSubMenuAccordionCategory } = useLateralFormMenuControl();
    const [_, setTick] = useState(0);

    const relatedMenuSubmenu = sectionMenuSubmenuDictionary[section.category];

    const { openAddNewFileModal, replaceSection } = useSectionDocuments();

    const sectionsStatus = optionsResponse.data;
    const sectionsStatusLoading = optionsResponse.status === 'loading';

    const currentStatus = useRef(section?.status);

    const updateSectionStatusHandler = async (section: ISectionDocuments, newStatus: string) => {

        const shouldCreateTheSession = section?.id === null;

        if (shouldCreateTheSession) {
            const formData = new FormData();
            formData.append('Details.BusinessClientId', String(businessClientId));
            formData.append('Details.Category', section.category);
            formData.append('Details.Status', newStatus);

            try {
                const newSection: ISectionDocuments = await createSectionDocuments(formData);
                replaceSection(index, newSection);
                setSuccessMessage('Status updated!')
            } catch (error) {
                setErrorMessage(error.Title)
            }
        } else {
            try {
                await updateSectionStatus(section.id, { newStatus });

                const updatedSection: ISectionDocuments = { ...section, status: newStatus };

                replaceSection(index, updatedSection);
                setSuccessMessage('Status updated!')
            } catch (error) {
                setErrorMessage(error.Title)
            }

        }


    }
    return (

        <div id={section.category} className={classes.container}>

            <div className={classes.header}>
                 <Typography title={section.categoryDescription} variant='h4' className={classes.heading}>
                    {section.categoryDescription}
                </Typography>

                <IconButton
                    aria-label='Upload'
                    size='small'
                    onClick={() => openAddNewFileModal(section, index)}
                >
                    <NoteAddIcon />
                </IconButton>

            </div>
 
            <div>

                {relatedMenuSubmenu && <button className={classes.button} type='button' onClick={() => setMenuSubMenuAccordionCategory(relatedMenuSubmenu.menu, relatedMenuSubmenu.submenu, section.category as SubMenuAccordionCategoryTypes)}>
                    <p>Go to {section.categoryDescription}</p>
                </button>}

            </div>

            <div className={classes.files}>
                {section.files
                    .map((file) => {
                        return (
                            <EssentialDoc file={file} section={section} index={index} />
                        )
                    })}

            </div>

            <GenericDropdown handleBlur={async () => { }}

                options={sectionsStatus ?? []}
                isLoading={sectionsStatusLoading}
                setFieldValue={async (_, value) => {
                    currentStatus.current = value;
                    setTick((prev) => prev + 1);
                    await updateSectionStatusHandler(section, value)
                }}
                value={currentStatus.current}
                label='Documents Status'
                id={`documents_status`}
            /> 
        </div>

    )
}

const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid rgb(160 160 160)',
        borderRadius: '2px',
        padding: '8px 10px'
    },
    button: {
        maxWidth: '100%',
        background: 'none!important',
        border: 'none',
        padding: '0!important',
        '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: '0'
        },
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    heading: {
        marginRight: '5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1.1,
        fontWeight: 600,
        fontSize: '17px', color: theme.palette.primary.main,
    },

    header: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center'
    },
    files: {
        height: '200px',
        overflowY: 'auto',
        marginBottom: theme.spacing(1),
    }

}));