import React, { createContext, useContext, useState } from 'react';
import { IBusinessClientDetail } from '../../../../models';

// Create a context for the currentBusinessClient
const CurrentBusinessClientContext = createContext<IBusinessClientDetail>(null);

interface CurrentBusinessClientContextProviderProps {
    initialClient: IBusinessClientDetail | null;
}

// Create a provider component to wrap your application
const CurrentBusinessClientContextProvider: React.FC<CurrentBusinessClientContextProviderProps> = ({ children, initialClient }) => {
    const [currentBusinessClient] = useState<IBusinessClientDetail | null>(initialClient);

    return (
        <CurrentBusinessClientContext.Provider value={currentBusinessClient}>
            {children}
        </CurrentBusinessClientContext.Provider>
    );
};

// Create a custom hook to access the currentBusinessClient and its setter
export const useCurrentBusinessClient = () => {
    const currentBusinessClient = useContext(CurrentBusinessClientContext);
    return currentBusinessClient;
};

export default CurrentBusinessClientContextProvider;
