import React, { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
// components
import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import MuiDialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

interface IModalProps extends DialogProps {
  DialogContentProps?: DialogContentProps;
  DialogTitleProps?: DialogTitleProps;
  subtitle?: string;
  title?: string;
  actions?: JSX.Element;
  noPaddingContent?: boolean;
  showClose?: boolean;
}

export const Modal: FC<IModalProps> = ({ DialogContentProps, DialogTitleProps, subtitle, title, actions, children, noPaddingContent, showClose = true, ...dialogProps }) => {
  const classes = modalStyles({ noPaddingContent });

  return (
    <Dialog fullWidth={true} maxWidth='xl' {...dialogProps}>
      <MuiDialogTitle classes={{ root: classes.dialogTitle }} {...DialogTitleProps}>
        {title && (
          <Typography className={classes.title} variant='h6'>
            {title}
          </Typography>
        )}
        {showClose && (
          <IconButton
            aria-label='Close'
            className={classes.close}
            onClick={() => {
              if (dialogProps && dialogProps.onClose) {
                dialogProps.onClose({}, 'backdropClick');
              }
            }}
            size='large'
          >
            <CloseIcon />
          </IconButton>
        )}
      </MuiDialogTitle>
      <MuiDialogContent classes={{ root: classes.dialogContent }} dividers={false} {...DialogContentProps}>
        {subtitle && (
          <Typography className={classes.subtitle} variant='h4'>
            {subtitle}
          </Typography>
        )}
        {children}
      </MuiDialogContent>
      {actions && <DialogActions sx={{
        'justifyContent': 'start'
      }}>{actions}</DialogActions>}
    </Dialog>
  );
};

const modalStyles = makeStyles<Theme, { noPaddingContent?: boolean }>((theme: Theme) => {
  return {
    close: {
      position: 'absolute',
      right: theme.spacing(0.25),
      top: theme.spacing(0.25),
      color: theme.palette.grey[600]
    },
    dialogContent: {
      // minHeight is to give <Loader /> component top and bottom space, if shown with no content to overlay
      minHeight: theme.spacing(4),
      padding: ({ noPaddingContent }) => (noPaddingContent ? 0 : theme.spacing(0.5, 1.5, 1.5))
    },
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(1, 4, .8, 1.5)
    },
    subtitle: {
      color: theme.palette.secondary.main,
      fontSize: 28,
      lineHeight: 1.3,
      margin: theme.spacing(0, 0, 1)
    },
    title: {
      fontSize: 20,
      textTransform: 'uppercase'
    }
  };
});
