import React from 'react';
import {
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import { FormLabel } from '..';
import { IDropdownResponse } from '../../models';
import FieldErrorMessage from '../field-error-message/FieldErrorMessage ';

interface Props {
    options: IDropdownResponse[];
    isLoading: boolean;
    error?: string;
    value: any;
    setFieldValue: (field: string, value: any) => void;
    handleBlur: any;
    label: string;
    id: string;
}

const GenericDropdown: React.FC<Props> = ({
    options,
    isLoading,
    error,
    value,
    setFieldValue,
    handleBlur,
    label,
    id
}) => {
    return (
        <FormControl
            fullWidth variant='outlined' size='small' error={error ? true : false}>
            <FormLabel label={label} id={id} data-cy={id}/>
            <Select
                
                name={id}
                onChange={(e) => setFieldValue(id, e.target.value as string)}
                labelId={id}
                id={id}
                size="small"
                value={value}
                onBlur={handleBlur}
                disabled={isLoading}
            >
                {!isLoading &&
                    options.map((option, index) => (
                        <MenuItem
                            data-cy={`select-option-${id}-${option.value}`}
                            key={index} value={option.value}>
                            {option.description}
                        </MenuItem>
                    ))}
            </Select>

            <FieldErrorMessage message={error} error={!!error}/>
        </FormControl>
    );
};

export default GenericDropdown;