import * as Yup from 'yup';
import { urlRegex, phoneRegExp } from '../../../helpers';
import { EMobileAppLinkTypeEnums, mobileAppLinkTypes } from '../../../models';
import { validateAtLeastOneFilledItem, validateAtLeastOneFilledItemGeneric } from '../../../util';

export const BusinessClientSchema = Yup.object().shape({
    name: Yup.string().max(255, 'Max 255 characters').required('Required'),
    brokerName: Yup.string().required('Required'),

    projectedNumberOfBenefitMeetingsRequired: Yup.string().max(510, 'Max 510 characters').nullable().notRequired(),
    proposedDatesAndTimes: Yup.string().max(510, 'Max 510 characters').nullable().notRequired(),
    timeZone: Yup.string().max(510, 'Max 510 characters').nullable().notRequired(),
    enrollmentMethodAndSchedulingNotes: Yup.string().max(510, 'Max 510 characters').nullable().notRequired(),

    previousYearEnrollmentDetails: Yup.string().max(510, 'Max 510 characters').nullable().notRequired(),

    shouldCompanyNameUsedInCommunication: Yup.boolean(),
    companyNameUsedInCommunication: Yup.string().when('shouldCompanyNameUsedInCommunication', {
        is: false,
        then: Yup.string().nullable().required('Required').max(255, 'Max 255 characters'),
        otherwise: Yup.string().nullable().notRequired(),
    }),

    areDomesticPartnersEligibleToEnroll: Yup.boolean(),
    arePartTimeEmployeesEligibleToEnroll: Yup.boolean(),

    partTimeEmployeesEligibility: Yup.boolean(),
    domesticPartnerPlanEligibility: Yup.boolean(),

    caseOverviewUrl: Yup.string().matches(urlRegex, 'Not a valid URL'),
    benefitProgramSupportDocumentationUrl: Yup.string().matches(urlRegex, 'Not a valid URL'),
    importantCaseNotes: Yup.string().notRequired().nullable().max(255, 'Max 255 characters'),
    logoUrl: Yup.string().optional().nullable(),
    appLogoUrl: Yup.string().optional().nullable(),
    systemOfRecord: Yup.string().required('Required'),
    systemOfRecordUrl: Yup.string().matches(urlRegex, 'Not a valid URL'),
    systemOfRecordUsername: Yup.string().optional().nullable(),
    systemOfRecordPassword: Yup.string().optional().nullable(),
    openEnrollmentEffectiveDate: Yup.string().optional().nullable(),
    openEnrollmentStartDate: Yup.string().optional().nullable(),
    dashboardOpenEnrollmentView: Yup.number().optional().nullable(),
    selfServiceStartDate: Yup.string().optional().nullable(),
    selfServiceEndDate: Yup.string().optional().nullable(),
    enrollmentDeadline: Yup.string().optional().nullable(),
    newHireScheduleUrl: Yup.string().matches(urlRegex, 'Not a valid URL'),
    enrollmentSchedulingTools: Yup.array().of(
        Yup.object({
            schedulingToolDescription: Yup.string().optional().nullable(),
            otherToolDescription: Yup.string().when(['schedulingToolDescription', 'isSelected'], {
                is: (schedulingToolDescription, isSelected) =>
                    schedulingToolDescription === 'Other' && isSelected,
                then: Yup.string().nullable().required('Other tool description is required when "Other" is selected'),
                otherwise: Yup.string().optional().nullable(),
            }),
            isSelected: Yup.boolean(),
        })
    ).nullable(),
    classes: Yup.array().of(
        Yup.object().shape({
            businessClientClassId: Yup.string().optional().nullable(),
            name: Yup.string().optional().nullable(),
            benefitGuideUrl: Yup.string().matches(urlRegex, 'Not a valid URL').nullable(),
            videoUrl: Yup.string().matches(urlRegex, 'Not a valid URL').nullable(),
            waitingPeriod: Yup.string().required('Required'),
            classPayCycle: Yup.string().nullable().optional(),
            classEligibilityPeriod: Yup.string().nullable().optional(),
            classEligibilityRule: Yup.string().nullable().optional(),
        })
    ).nullable(),
    contacts: Yup.array().of(
        Yup.object().shape({
            businessClientClassId: Yup.number().nullable(),
            businessClientId: Yup.number().optional().nullable(),
            name: Yup.string().optional().nullable(),
            email: Yup.string().notRequired().email(),
            role: Yup.string().optional().nullable(),
            hasDashboardAccess: Yup.boolean(),
            hasBenAdminAccess: Yup.boolean(),

        })
    ).nullable(),

    location: Yup.array().of(
        Yup.object().shape({
            businessClientLocationId: Yup.string().optional().nullable(),
            name: Yup.string().optional().nullable(),
            calendarLinkUrl: Yup.string().matches(urlRegex, 'Not a valid URL').nullable(),
            supervisorName: Yup.string().nullable()
        })
    ),
    mobileAppEnabled: Yup.boolean(),
    appLogoType: Yup.string().optional().nullable(),
    homeLogoType: Yup.string().optional().nullable(),
    hexColor: Yup.string().optional().nullable(),
    secondaryHexColor: Yup.string().optional().nullable(),
    textHexColor: Yup.string().optional().nullable(),
    header: Yup.string().max(150, 'Max 150 characters'),
    links: Yup.mixed().when('mobileAppEnabled', {
        is: (val: boolean) => val === true,
        then: Yup.array().of(
            Yup.object().shape({
                businessClientMobileAppLinkId: Yup.string().optional().nullable(),
                enabled: Yup.boolean(),
                link: Yup.mixed().when(['enabled', 'type'], {
                    is: (enabled: boolean, type: mobileAppLinkTypes) =>
                        // don't bother checking for this icons since the URLSs are auto assigned on the BE
                        enabled === true &&
                        type !== EMobileAppLinkTypeEnums.CALENDAR &&
                        type !== EMobileAppLinkTypeEnums.BENEFITS_INFO &&
                        type !== EMobileAppLinkTypeEnums.INSTRUCTIONS &&
                        type !== EMobileAppLinkTypeEnums.MEDIA,
                    then: Yup.string().matches(urlRegex, 'Not a valid URL').required('Required'),
                    // Need to check if the type is EMobileAppLinkTypeEnums.INSTRUCTIONS, since it just needs to be a string
                    otherwise: Yup.mixed().when(['enabled', 'type'], {
                        is: (enabled: boolean, type: mobileAppLinkTypes) => enabled === true && type === EMobileAppLinkTypeEnums.INSTRUCTIONS,
                        then: Yup.string().optional().nullable(),
                        otherwise: Yup.string().matches(urlRegex, 'Not a valid URL').notRequired().nullable()
                    })
                }),
                name: Yup.string().optional().nullable(),
                type: Yup.string()
            })
        ),
        otherwise: Yup.array().of(
            Yup.object().shape({
                businessClientMobileAppLinkId: Yup.string().optional().nullable(),
                enabled: Yup.boolean(),
                link: Yup.string().matches(urlRegex, 'Not a valid URL').notRequired().nullable(),
                name: Yup.string().optional().nullable(),
                type: Yup.string()
            })
        )
    }),
    hrManagerFirstName: Yup.string().optional().nullable(),
    hrManagerLastName: Yup.string().optional().nullable(),
    hrManagerEmail: Yup.string().max(255, 'Max 255 characters').email('Email address invalid').nullable(),
    hrManagerPhoneNumber: Yup.string().matches(phoneRegExp, { excludeEmptyString: true, message: 'Invalid phone number' }).nullable(),
    automatedEmployeeCommunication: Yup.boolean(),
    qrCodes: Yup.array().of(
        Yup.object().shape({
            businessClientClassId: Yup.number().optional().nullable(),
            businessClientLocationId: Yup.mixed().when(['name'], {
                is: (name: string) => name.includes('New Hire'),
                then: Yup.number().nullable(),
                otherwise: Yup.number()
            }),
            link: Yup.string().matches(urlRegex, 'Not a valid URL').required('Required'),
            name: Yup.string()
        })
    ),
    // enrollment builder
    projectType: Yup.string().required('Required'),
    enrollmentStart: Yup.string().required('Required'),
    enrollmentEnd: Yup.string().required('Required'),
    targetDate: Yup.string().optional().nullable(),
    buildSteps: Yup.array().of(
        Yup.object().shape({
            businessClientBuildStepId: Yup.number().optional().nullable(),
            buildStep: Yup.string().optional().nullable(),
            url: Yup.string().matches(urlRegex, 'Not a valid URL'),
            buildStepStatus: Yup.mixed().when(['url', 'buildStep'], {
                is: (url: string, buildStep: string) => {
                    return url && buildStep !== 'ProjectTracker' ? true : false;
                },
                then: Yup.string().required('Required'),
                otherwise: Yup.string().notRequired().nullable()
            })
        })
    ),
    // enrollment overview
    situsState: Yup.string().optional().nullable(),
    industry: Yup.string().optional().nullable(),
    industryTier: Yup.string().optional().nullable(),
    benAdminBuildType: Yup.string().optional().nullable(),
    engagementPercentage: Yup.string().optional().nullable(),
    anticipatedSupport: Yup.string().optional().nullable(),



    healthSavingsAccount: Yup.object().shape({
        offerHealthSavingsAccount: Yup.boolean(),
        erContributionsDistribution: Yup.string().optional().nullable(),
        employeeMaxAnnualContribution: Yup.string().nullable().nullable().max(255, 'Max 255 characters'),
        familyMaxAnnualContribution: Yup.string().nullable().nullable().max(255, 'Max 255 characters'),
        setupRequirements: Yup.string().optional().nullable(),
        erContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        bankName: Yup.string().optional().nullable(),
        contingentPlans: Yup.string().optional().nullable(),
        coverageTermination: Yup.string().optional().nullable(),
    }).nullable(),

    healthReimbursementAccount: Yup.object().shape({
        offerHealthReimbursementAccount: Yup.boolean().optional(),
        erContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        contingentPlans: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        highlights: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        coverageTerminatesOn: Yup.string().optional().nullable(),
    }).nullable(),

    flexibleSpendingAccount: Yup.object().shape({
        offerFlexibleSpendingAccount: Yup.boolean().optional(),
        erContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        employeeMaxAnnualAccount: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        coverageTerminatesOn: Yup.string().optional().nullable(),
    }).nullable(),

    dependentCareFlexSpendingAccount: Yup.object().shape({
        offerDependentCareFlexSpendingAccount: Yup.boolean().optional(),
        erContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        employeeMaxAnnualContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        familyMaxAnnualContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        coverageTerminatesOn: Yup.string().optional().nullable(),
    }).nullable(),

    limitedPurposeFlexSpendingAccount: Yup.object().shape({
        offerLimitedPurposeFlexSpendingAccount: Yup.boolean().optional(),
        erContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        employeeMaxAnnualContribution: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        coverageTerminatesOn: Yup.string().optional().nullable(),
    }).nullable(),

    financialProductNotes: Yup.string().optional().max(510, 'Max 510 characters'),

    dentalInsurance: Yup.object().shape({
        offerDentalCoverage: Yup.boolean(),
        dentalCarrier: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        previousDentalCarrier: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        dentalPlanOffering: Yup.string().optional().nullable(),
        coverageTerminatesOn: Yup.string().optional().nullable(),
        dentalNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),
        dentalInsurancePlans: Yup.array()
            .of(
                Yup.object().shape({
                    planName: validateAtLeastOneFilledItem(),
                    pcpRequired: validateAtLeastOneFilledItemGeneric(Yup.boolean()),
                    mostSimilarPreviousPlan: validateAtLeastOneFilledItem(),

                })
            )
            .nullable(),
    }).nullable(),

    visionInsurance: Yup.object().shape({
        offerVisionCoverage: Yup.boolean(),
        visionCarrier: Yup.string().nullable().optional(),
        previousVisionCarrier: Yup.string().nullable().optional(),
        visionPlanOffering: Yup.string().nullable().optional(),
        coverageTerminatesOn: Yup.string().nullable().optional(),
        visionNotes: Yup.string().nullable().optional().max(510, 'Max 510 characters'),
        visionInsurancePlans: Yup.array().of(Yup.object().shape({
            planName: validateAtLeastOneFilledItem(),
            mostSimilarPreviousPlan: validateAtLeastOneFilledItem(),
        })),
    }).nullable(),

    groupLifeInsurance: Yup.object().shape({
        id: Yup.number().nullable(),
        offerBasicLifeCoverage: Yup.boolean(),
        basicLifeCarrier: Yup.string().optional().nullable(),
        previousBasicLifeCarrier: Yup.string().optional().nullable(),
        basicLifeOffering: Yup.string().optional().nullable(),
        voluntaryLifeCoverage: Yup.boolean(),
        voluntaryLifeCarrier: Yup.string().optional().nullable(),
        previousVoluntaryLifeCarrier: Yup.string().optional().nullable(),
        lifeNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),

        basicADeD: Yup.string().optional().nullable(),
        volADeD: Yup.string().optional().nullable(),
        volLifeGiEligibility: Yup.string().optional().nullable(),
        volLifeOffering: Yup.string().optional().nullable(),
        volLifePortability: Yup.string().optional().nullable(),
        volLifeEmployeeRateBasis: Yup.string().optional().nullable(),
        volLifeSpouseRateBasis: Yup.string().optional().nullable(),
        volLifeTobaccoRateBasis: Yup.string().optional().nullable(),


        groupLifeInsuranceClasses: Yup.array()
            .of(
                Yup.object().shape({
                    className: validateAtLeastOneFilledItem(),
                    notes: validateAtLeastOneFilledItem(),
                    faceAmount: validateAtLeastOneFilledItem(),
                    maxFactor: validateAtLeastOneFilledItem(),
                })
            )
            .nullable(),


        groupLifeInsuranceGuaranteedIssues: Yup.array()
            .of(
                Yup.object().shape({
                    giAmount: validateAtLeastOneFilledItem(),
                    maxAmount: validateAtLeastOneFilledItem(),
                    maxFactor: validateAtLeastOneFilledItem(),
                    increments: validateAtLeastOneFilledItem(),
                })
            )
            .nullable(),
    }).nullable(),

    voluntaryLifeCoverage: Yup.object().shape({
        id: Yup.number().nullable(),
        offerVoluntaryLifeCoverage: Yup.boolean(),

        voluntaryLifeCarrier: Yup.string().optional().nullable(),
        previousVoluntaryLifeCarrier: Yup.string().optional().nullable(),

        volADeD: Yup.string().optional().nullable(),
        volLifeGiEligibility: Yup.string().optional().nullable(),
        volLifeOffering: Yup.string().optional().nullable(),
        volLifePortability: Yup.string().optional().nullable(),
        volLifeEmployeeRateBasis: Yup.string().optional().nullable(),
        volLifeSpouseRateBasis: Yup.string().optional().nullable(),
        volLifeTobaccoRateBasis: Yup.string().optional().nullable(),

        voluntaryLifeCoverageGuaranteedIssues: Yup.array()
            .of(
                Yup.object().shape({
                    giAmount: validateAtLeastOneFilledItem(),
                    maxAmount: validateAtLeastOneFilledItem(),
                    maxFactor: validateAtLeastOneFilledItem(),
                    increments: validateAtLeastOneFilledItem(),

                })
            )
            .nullable(),
    }).nullable(),

    accidentInsurance: Yup.object().shape({
        id: Yup.number().optional().nullable(),
        offerAccidentCoverage: Yup.boolean(),
        accidentCarrier: Yup.string().optional().nullable(),
        previousAccidentCarrier: Yup.string().optional().nullable(),
        highlights: Yup.string().optional().nullable(),
        accidentTaxStatus: Yup.boolean(),
        accidentNotes: Yup.string().nullable().optional().max(510, 'Max 510 characters'),
    }).nullable(),

    hospitalCoverage: Yup.object().shape({
        id: Yup.number().optional().nullable(),
        offerHospitalCoverage: Yup.boolean(),
        hospitalCarrier: Yup.string().optional().nullable(),
        previousHospitalCarrier: Yup.string().optional().nullable(),
        hospitalTaxStatus: Yup.boolean(),
        hospitalNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),

        hospitalOffering: Yup.string().optional().nullable(),
        hospitalGIEligibility: Yup.string().optional().nullable(),
        hospitalPlanType: Yup.string().optional().nullable(),
        hospitalPreExRules: Yup.string().optional().nullable(),
        hospitalPortability: Yup.string().optional().nullable(),
        hospitalCreditForTimeServed: Yup.string().optional().nullable(),

    }).nullable(),

    cancerInsurance: Yup.object().shape({
        id: Yup.number().optional().nullable(),
        offerCancerCoverage: Yup.boolean(),
        cancerCarrier: Yup.string().optional().nullable(),
        previousCancerCarrier: Yup.string().optional().nullable(),
        cancerTaxStatus: Yup.boolean(),
        cancerNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),
        cancerOffering: Yup.string().optional().nullable(),
        cancerPlanType: Yup.string().optional().nullable(),
        cancerPreExRules: Yup.string().optional().nullable(),
        cancerPortability: Yup.string().optional().nullable(),
        cancerCreditForTimeServed: Yup.string().optional().nullable(),
    }).nullable(),

    medicalInsurance: Yup.object().shape({
        medicalPlanOffering: Yup.string().optional().nullable(),
        medicalDeductibleType: Yup.string().optional().nullable(),
        telemedicine: Yup.string().optional().nullable(),
        medicalNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),
        medicalCoverageTerminatesOn: Yup.string().optional().nullable(),
        offerMedicalInsurance: Yup.boolean().nullable(),
        medicalCarrier: Yup.string().optional().nullable().max(255, 'Max 255 characters'),
        previousMedicalCarrier: Yup.string().optional().nullable().max(255, 'Max 255 characters'),

        medicalPlans: Yup.array().of(
            Yup.object().shape({
                planName: validateAtLeastOneFilledItem().max(255, 'Max 255 characters'),
                pcpRequired: validateAtLeastOneFilledItemGeneric(Yup.boolean()),

                // Yup.boolean().test(
                //     'oneOfRequired',
                //     'At least one field must be filled',
                //     function () {
                //         const { parent } = this;
                //         const properties = Object.keys(parent);
                //         return anyPropertieFilledButId(properties, parent);
                //     }
                // ),
                mostSimilarPreviousPlan: validateAtLeastOneFilledItem(),
            })
        ).nullable(),
    }).nullable(),

    criticalIllnessInsurance: Yup.object().shape({
        id: Yup.number().nullable(),
        offerCriticalIllnessCoverage: Yup.boolean(),
        criticalIllnessCarrier: Yup.string().optional().nullable(),
        previousCriticalIllnessCarrier: Yup.string().optional().nullable(),
        ciTaxStatus: Yup.boolean(),
        criticalIllnessNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),
        criticalIllnessInsuranceGuaranteedIssues: Yup.array()
            .of(Yup.object().shape({
                giAmount:
                    validateAtLeastOneFilledItem(),
                increments: validateAtLeastOneFilledItem(),
                maxAmount: validateAtLeastOneFilledItem(),
                maxFactor: validateAtLeastOneFilledItem(),

            })).nullable(),
    }).nullable(),

    portableLifeInsurance: Yup.object().shape({
        offerPortableLifeInsuranceCoverage: Yup.boolean(),
        portableLifeCarrier: Yup.string().nullable().optional(),
        previousPortableLifeCarrier: Yup.string().nullable().optional(),
        portableLifeTaxStatus: Yup.boolean(),
        portableLifeNotes: Yup.string().nullable().optional().max(510, 'Max 510 characters'),
        portableLifeInsuranceGuaranteedIssues: Yup.array().of(Yup.object().shape({
            giAmount: validateAtLeastOneFilledItem(),
            maxAmount: validateAtLeastOneFilledItem(),
            maxFactor: validateAtLeastOneFilledItem(),
        })).nullable(),
    }).nullable(),

    supplementalProducts: Yup.array()
        .of(
            Yup.object().shape({
                offerSupplementalProducts:
                    validateAtLeastOneFilledItemGeneric(Yup.boolean()),
                carrierName: validateAtLeastOneFilledItem(),
                notes: validateAtLeastOneFilledItem(),
            })
        )
        .nullable(),

    longTermDisabilityCoverage: Yup.object().shape({
        longTermDisabilityCoverageId: Yup.number().nullable(),
        offerLongTermDisabilityCoverage: Yup.boolean(),
        ltdCarrier: Yup.string().optional().nullable(),
        previousLTDCarrier: Yup.string().optional().nullable(),
        areMultiplePlansAvailable: Yup.boolean(),
        ltdOffering: Yup.string().optional().nullable(),

        ltdPreExRules: Yup.string().optional().nullable(),
        eliminationAccumulationPeriods: Yup.string().optional().nullable(),

        eligibleClasses: Yup.string().optional().nullable(),
        ltdMaxMonthlyBenefit: Yup.string().optional().nullable(),
        ltdNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),
        maxMonthlyBenefit: Yup.string().optional().nullable(),

        ltdPolicyType: Yup.string().optional().nullable(),
        ltdPortability: Yup.string().optional().nullable(),
        ltdgiEligibility: Yup.string().optional().nullable(),
        ltdBuyUpOptions: Yup.string().optional().nullable(),
        ltdPaymentType: Yup.string().optional().nullable(),

        ltdTaxStatus: Yup.string().optional().nullable(),
        coverageTerminatesOn: Yup.string().optional().nullable(),

        longTermDisabilityCoverageClasses: Yup.array()
            .of(Yup.object().shape({


                policyType: validateAtLeastOneFilledItem(),
                giEligibility: validateAtLeastOneFilledItem(),
                paymentType: validateAtLeastOneFilledItem(),

                eliminationAccumulationPeriods: validateAtLeastOneFilledItem(),

                className: validateAtLeastOneFilledItem(),
            })).nullable(),
    }).nullable(),

    eligibilityRules: Yup.object().shape({
        arePartTimeEmployeesEligibleToEnroll: Yup.boolean().nullable(),
        partTimeEmployeesEligibility: Yup.boolean().nullable(),
        areDomesticPartnersEligibleToEnroll: Yup.boolean().nullable(),
        domesticPartnerPlanEligibility: Yup.boolean().nullable(),
        eligibilityRulesDeductions: Yup.array().of(Yup.object().shape({
            deductionFrequency: validateAtLeastOneFilledItem(),
            hoursRequiredForEligibility: validateAtLeastOneFilledItem(),
        })),
    }).nullable(),

    shortTermDisabilityCoverage: Yup.object().shape({
        shortTermDisabilityCoverageId: Yup.number().nullable(),
        offerShortTermDisabilityCoverage: Yup.boolean(),
        stdCarrier: Yup.string().optional().nullable(),
        previousSTDCarrier: Yup.string().optional().nullable(),
        areMultiplePlansAvailable: Yup.boolean(),
        stdOffering: Yup.string().optional().nullable(),
        eligibleClasses: Yup.string().optional().nullable(),
        stdPreExRules: Yup.string().optional().nullable(),
        stdMaxWeeklyBenefit: Yup.string().optional().nullable(),
        stdTaxStatus: Yup.string().optional().nullable(),
        preExExceptionForPregnancy: Yup.boolean(),
        stdNotes: Yup.string().optional().nullable().max(510, 'Max 510 characters'),

        stdBuyUpOptions: Yup.string().optional().nullable(),
        stdPortability: Yup.string().optional().nullable(),
        stdPolicyType: Yup.string().optional().nullable(),
        accidentSicknessEliminationPeriods: Yup.string().optional().nullable(),
        coverageTerminatesOn: Yup.string().optional().nullable(),

        shortTermDisabilityCoverageClasses: Yup.array()
            .of(Yup.object().shape({
                eliminationPeriods: validateAtLeastOneFilledItem(),
                preExRules: validateAtLeastOneFilledItem(),
                className: validateAtLeastOneFilledItem(),
                maxWeeklyBenefit: validateAtLeastOneFilledItem(),
                policyType: validateAtLeastOneFilledItem(),
                giEligibility: validateAtLeastOneFilledItem(),
                paymentType: validateAtLeastOneFilledItem(),

            }))
            .nullable(),
    }).nullable(),

    faceToFaceEnrollment: Yup.object().shape({
        multipleLocationsRequired: Yup.boolean(),
        notesAboutEnrollmentLogistics: Yup.string().nullable().optional().max(510, 'Max 510 characters'),

        locations: Yup.array().of(Yup.object().shape({
            locationName: validateAtLeastOneFilledItem().max(255, 'Max 255 characters'),
            streetAddress: validateAtLeastOneFilledItem().max(255, 'Max 255 characters'),

            city: validateAtLeastOneFilledItem().max(50, 'Max 50 characters'),
            state: validateAtLeastOneFilledItem().max(128, 'Max 128 characters'),
            zip: validateAtLeastOneFilledItem().max(24, 'Max 24 characters'),
        })),
    }).nullable(),

    businessClientLocationAndLogistic: Yup.object().shape({

        isThereNeedForOtherLanguages: Yup.boolean(),
        isThereNeedForSpanish: Yup.boolean(),
        areAnyOtherLanguagesNeeded: Yup.boolean(),
        additionalLanguages: Yup.string().when('areAnyOtherLanguagesNeeded', {
            is: (value) => value === true,
            then: Yup.string().required('Please provide additional languages ​​required').max(255, 'Max 255 characters'),
            otherwise: Yup.string().nullable().optional().max(255, 'Max 255 characters'),
        }).nullable(),


    }).nullable(),

    challengesAndSolutions: Yup.object().shape({
        topChallengesToSolveForEnrollment: Yup.string().nullable().optional().max(510, 'Max 510 characters'),
        solutionsImplemented: Yup.string().nullable().optional().max(255, 'Max 255 characters'),
        keyTakeawaysFromStrategy: Yup.string().nullable().optional().max(510, 'Max 510 characters'),
    }).nullable(),


    businessClientFollowUpMessages: Yup.object().shape({
        targetAudience: Yup.string().nullable().optional(),
        messages: Yup.array().of(Yup.object().shape({
            messageDeliveryDate: validateAtLeastOneFilledItem(),
            textMessage: validateAtLeastOneFilledItem().max(160, 'The maximum number of characters for a text message is 160 characters'),
            emailSubjectLine: validateAtLeastOneFilledItem(),
            emailMessage: validateAtLeastOneFilledItem()
        })),
    }).nullable(),


    sicCode: Yup.string().nullable().optional(),
    street: Yup.string().nullable().optional(),
    street2: Yup.string().nullable().optional(),
    city: Yup.string().nullable().optional(),
    state: Yup.string().nullable().optional(),
    postalCode: Yup.string().nullable().optional(),
    taxId: Yup.string().nullable().optional(),
    totalEligibleEmployees: Yup.string().nullable().optional(),
    totalEmployees: Yup.string().nullable().optional(),
    engagementPackageType: Yup.string().nullable().max(255, 'Max 255 characters'),
    // client preferences
    highlightsChallenges: Yup.string().max(255, 'Max 255 characters').nullable(),
    specialAttentionBusinessClientEmployees: Yup.array()
        .of(
            Yup.object().shape({
                businessClientSpecialAttentionEmployeeId: Yup.number().optional().nullable(),
                name: Yup.string().max(255, 'Max 255 characters').required('Required'),
                title: Yup.string().max(255, 'Max 255 characters').nullable()
            })
        )
        .nullable(),
    hrDeadline: Yup.string().optional().nullable(),
    provideDataFile: Yup.boolean(),
    dataDeliveryDate: Yup.string().optional().nullable(),
    dataFileRecipients: Yup.array()
        .of(
            Yup.object().shape({
                businessClientDataFileRecipientId: Yup.number().optional().nullable(),
                recipient: Yup.string().nullable().optional(),
                otherRecipientDescription: Yup.mixed().when(['recipient'], {
                    is: (recipient: string) => {
                        return recipient === 'Other' ? true : false;
                    },
                    then: Yup.string().max(255, 'Max 255 characters').required('Required'),
                    otherwise: Yup.string().max(255, 'Max 255 characters').notRequired().nullable()
                })
            })
        )
        .nullable(),
    accountManagerUserId: Yup.string().required('Required')
});