import React, { useReducer } from "react";
import { IBusinessClassOptions, IDefaultDropdownsOptions, IDropdownResponse, IFinancialOptions, IGeneralDropdownsOptions, IInsuranceCoverageOptions, IMedicalInsuranceOptions, ISystemOfRecord, IUser } from "../models";
import requestReducer, { RequestAction } from "../reducers/request-reducer";
import { RequestState } from "../models/request-state";

// Define the context data type
interface DropDownCollectionsCtxType {
    industriesRequest: RequestState<IDropdownResponse[]>;
    dispatchIndustries?: React.Dispatch<RequestAction<IDropdownResponse[]>>;
    anticipatedSupportsRequest: RequestState<IDropdownResponse[]>;
    dispatchAnticipatedSupports?: React.Dispatch<RequestAction<IDropdownResponse[]>>;
    contactRolesRequest: RequestState<IDropdownResponse[]>;
    dispatchContactRoles?: React.Dispatch<RequestAction<IDropdownResponse[]>>;

    medicalInsuranceOptionsRequest: RequestState<IMedicalInsuranceOptions>;
    dispatchMedicalInsuranceOptions?: React.Dispatch<RequestAction<IMedicalInsuranceOptions>>;

    financialOptionsRequest: RequestState<IFinancialOptions>;
    dispatchFinancialOptions?: React.Dispatch<RequestAction<IFinancialOptions>>;

    insuranceCoverageOptionsRequest: RequestState<IInsuranceCoverageOptions>;
    dispatchInsuranceCoverageOptionsRequest?: React.Dispatch<RequestAction<IInsuranceCoverageOptions>>;

    defaultDropdownsOptionsRequest: RequestState<IDefaultDropdownsOptions>;
    dispatchDefaultDropdownsOptionsRequest?: React.Dispatch<RequestAction<IDefaultDropdownsOptions>>;

    businessClassOptionsRequest: RequestState<IBusinessClassOptions>;
    dispatchBusinessClassOptionsRequest?: React.Dispatch<RequestAction<IBusinessClassOptions>>;

    states: RequestState<IDropdownResponse[]>;
    systemOfRecords: RequestState<ISystemOfRecord[]>;
    users: RequestState<IUser[]>;
    dataFileRecipients: RequestState<IDropdownResponse[]>;

    generalDropdownsOptionsRequest: RequestState<IGeneralDropdownsOptions>;
    dispatchGeneralDropdownsOptionsRequest?: React.Dispatch<RequestAction<IGeneralDropdownsOptions>>;

    sectionsDocumentsStatusRequest: RequestState<IDropdownResponse[]>;
    dispatchSectionsDocumentsStatusRequest?: React.Dispatch<RequestAction<IDropdownResponse[]>>;
}

// Create the initial context data
const initialContextData: DropDownCollectionsCtxType = {
    sectionsDocumentsStatusRequest:{
        status: 'idle',
        data: null,
        error: null
    },
    generalDropdownsOptionsRequest: {
        status: 'idle',
        data: null,
        error: null
    },
    businessClassOptionsRequest: {
        status: 'idle',
        data: null,
        error: null
    },
    defaultDropdownsOptionsRequest: {
        status: 'idle',
        data: null,
        error: null
    },
    financialOptionsRequest: {
        status: 'idle',
        data: null,
        error: null
    },
    insuranceCoverageOptionsRequest: {
        status: 'idle',
        data: null,
        error: null
    },
    medicalInsuranceOptionsRequest: {
        status: 'idle',
        data: null,
        error: null
    },
    states: {
        status: 'idle',
        data: [],
        error: null,
    },
    industriesRequest: {
        status: 'idle',
        data: [],
        error: null,
    },
    anticipatedSupportsRequest: {
        status: 'idle',
        data: [],
        error: null,
    },
    contactRolesRequest: {
        status: 'idle',
        data: [],
        error: null,
    },
    systemOfRecords: {
        status: 'idle',
        data: [],
        error: null,
    },
    users: {
        status: 'idle',
        data: [],
        error: null,
    },
    dataFileRecipients: {
        status: 'idle',
        data: [],
        error: null,
    },
};

// Create the context
const DropDownCollectionsCtx = React.createContext<DropDownCollectionsCtxType>(initialContextData);
export { DropDownCollectionsCtx };

// Create the provider component
const DropDownCollectionsProvider: React.FC = ({ children }) => {
    // You can define the state to store the data items here (if you want to change them over time)
    const [industriesRequest, dispatchIndustries] = useReducer(requestReducer<IDropdownResponse[]>(), {
        status: 'idle',
        data: [],
        error: null,
    } as RequestState<IDropdownResponse[]>);

    const [anticipatedSupportsRequest, dispatchAnticipatedSupports] = useReducer(requestReducer<IDropdownResponse[]>(), {
        status: 'idle',
        data: [],
        error: null,
    } as RequestState<IDropdownResponse[]>);

    const [contactRolesRequest, dispatchContactRoles] = useReducer(requestReducer<IDropdownResponse[]>(), {
        status: 'idle',
        data: [],
        error: null,
    } as RequestState<IDropdownResponse[]>);

    const [medicalInsuranceOptionsRequest, dispatchMedicalInsuranceOptions] = useReducer(requestReducer<IMedicalInsuranceOptions>(), {
        status: 'idle',
        data: null,
        error: null,
    } as RequestState<IMedicalInsuranceOptions>);

    const [financialOptionsRequest, dispatchFinancialOptions] = useReducer(requestReducer<IFinancialOptions>(), {
        status: 'idle',
        data: null,
        error: null,
    } as RequestState<IFinancialOptions>);

    const [insuranceCoverageOptionsRequest, dispatchInsuranceCoverageOptionsRequest] = useReducer(requestReducer<IInsuranceCoverageOptions>(), {
        status: 'idle',
        data: null,
        error: null,
    } as RequestState<IInsuranceCoverageOptions>);


    const [defaultDropdownsOptionsRequest, dispatchDefaultDropdownsOptionsRequest] = useReducer(requestReducer<IDefaultDropdownsOptions>(), {
        status: 'idle',
        data: null,
        error: null,
    } as RequestState<IDefaultDropdownsOptions>);

    const [businessClassOptionsRequest, dispatchBusinessClassOptionsRequest] = useReducer(requestReducer<IBusinessClassOptions>(), {
        status: 'idle',
        data: null,
        error: null,
    } as RequestState<IBusinessClassOptions>);


    const [generalDropdownsOptionsRequest, dispatchGeneralDropdownsOptionsRequest] = useReducer(requestReducer<IGeneralDropdownsOptions>(), {
        status: 'idle',
        data: null,
        error: null,
    } as RequestState<IGeneralDropdownsOptions>);
    
    const [sectionsDocumentsStatusRequest, dispatchSectionsDocumentsStatusRequest] = useReducer(requestReducer<IDropdownResponse[]>(), {
        status: 'idle',
        data: null,
        error: null,
    } as RequestState<IDropdownResponse[]>);

    return (
        <DropDownCollectionsCtx.Provider value={{
            ...initialContextData,
            industriesRequest,
            dispatchIndustries,
            anticipatedSupportsRequest,
            dispatchAnticipatedSupports,
            contactRolesRequest,
            dispatchContactRoles,
            medicalInsuranceOptionsRequest,
            dispatchMedicalInsuranceOptions,
            financialOptionsRequest,
            dispatchFinancialOptions,
            insuranceCoverageOptionsRequest,
            dispatchInsuranceCoverageOptionsRequest,
            defaultDropdownsOptionsRequest,
            dispatchDefaultDropdownsOptionsRequest,
            businessClassOptionsRequest,
            dispatchBusinessClassOptionsRequest,
            generalDropdownsOptionsRequest,
            dispatchGeneralDropdownsOptionsRequest,
            sectionsDocumentsStatusRequest,
            dispatchSectionsDocumentsStatusRequest
        }}>
            {children}
        </DropDownCollectionsCtx.Provider>
    );
};

export default DropDownCollectionsProvider;