import { FC, useState, useEffect } from 'react';
import { Field, FormikErrors, FormikTouched, FieldArray } from 'formik';
import clsx from 'clsx';
import { DatePicker } from '../../../components';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { IBusinessClientDetailFormValues, IDropdownResponse, ITargetDate } from '../../../models';
import { formatDueDateKey, formatShortFriendlyDateWithTime, getBuildStepOptions, reorder } from '../../../helpers';
import { getBusinessClientProjectTypes, getBusinessClientTargetDate } from '../../../fetch';
import { NoteAlt, FileCopy, AddShoppingCart, FolderShared, School, Groups, Forum, Timeline as TimelineIcon, DragIndicator } from '@mui/icons-material';
import { Card, CardContent, Button, Divider, Box, TextField, Typography, MenuItem, Select, InputLabel, Grid, FormControl, FormHelperText } from '@mui/material';
import { EnrollmentBuilderPod } from './EnrollmentBuilderPod';
import { EnrollmentBuilderTimeline } from './EnrollmentBuilderTimeline';
import { compileDefaultSteps } from '../../builder';

interface IEnrollmentBuilder {
  errors: FormikErrors<any>;
  values: IBusinessClientDetailFormValues;
  handleBlur?: (e: any) => void;
  setFieldValue: (key: string, val: any) => void;
  touched: FormikTouched<any>;
  businessClientId: number | string | null;
}

export const formatName = val => {
  if (val.includes('HR')) {
    return 'HR Overview';
  } else if (val.includes('Tracker')) {
    return 'Enrollment Tracker';
  } else {
    return val.replace(/([A-Z])/g, ' $1').trim();
  }
};

export const buildSteps = [
  {
    buildStep: 'ProductOverview',
    businessClientBuildStepId: -1,
    url: '',
    icon: <AddShoppingCart fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    icon: <FileCopy fontSize='large' color='primary' />,
    businessClientBuildStepId: -2,
    buildStep: 'CensusProfile',
    url: '',
    buildStepStatus: ''
  },
  {
    buildStep: 'EssentialDocs',
    businessClientBuildStepId: -3,
    url: '',
    icon: <NoteAlt fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'EnrollmentOverview',
    businessClientBuildStepId: -4,
    url: '',
    icon: <School fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'HROverview',
    businessClientBuildStepId: -5,
    url: '',
    icon: <Groups fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'ClientProfile',
    businessClientBuildStepId: -6,
    url: '',
    icon: <FolderShared fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'CommunicationOverview',
    businessClientBuildStepId: -7,
    url: '',
    icon: <Forum fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'ProjectTracker',
    businessClientBuildStepId: -8,
    url: '',
    icon: <TimelineIcon fontSize='large' color='primary' />,
    buildStepStatus: ''
  }
];

export const EnrollmentBuilder: FC<IEnrollmentBuilder> = ({ errors, values, handleBlur, setFieldValue, touched, businessClientId }) => {
  const classes = useStyles();
  const [isEnrollmentPreview, showPreview] = useState(false);
  const [projectTypes, setProjectTypes] = useState<IDropdownResponse[]>([]);
  const [dueDates, setDueDates] = useState<ITargetDate | null>(null);

  const fetchProjectTypes = async () => {
    try {
      const res = await getBusinessClientProjectTypes();
      setProjectTypes(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTargetDate = async (start: string, projectType: string) => {
    try {
      const res = await getBusinessClientTargetDate(start, projectType);
      setFieldValue('targetDate', res.targetDate);
      setDueDates(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (values.enrollmentStart && values.projectType) {
      fetchTargetDate(values.enrollmentStart, values.projectType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.enrollmentStart, values.projectType]);

  useEffect(() => {
    fetchProjectTypes();
  }, []);

  const options = getBuildStepOptions(values.projectType, values.buildSteps);
  let previewOptions = getBuildStepOptions(values.projectType, values.buildSteps);
  previewOptions = compileDefaultSteps(businessClientId, previewOptions);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = reorder(options, oldIndex, newIndex);
    setFieldValue('buildSteps', items);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Field name={`projectType`}>
            {({ field }) => {
              return (
                <FormControl fullWidth variant='outlined' size='small' required error={!!errors?.projectType}>
                  <InputLabel id='project-type' className={clsx(classes.outlinedLabel)}>
                    Project Type
                  </InputLabel>
                  <Select
                    {...field}
                    name='project-type'
                    labelId='project-type'
                    id='project-type'
                    size='small'
                    value={values.projectType}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('projectType', e.target.value as string)}
                  >
                    {projectTypes.map((pt: IDropdownResponse, index) => {
                      return (
                        <MenuItem key={`${index}`} value={pt.value}>
                          {pt.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={!!errors?.projectType}>{errors?.projectType}</FormHelperText>
                </FormControl>
              );
            }}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant='outlined'>
            <DatePicker
              label='Enrollment Start'
              value={values.enrollmentStart ? new Date(values.enrollmentStart) : null}
              maxDate={values.enrollmentEnd ? new Date(values.enrollmentEnd) : null}
              renderInput={props => (
                <TextField
                  {...props}
                  error={Boolean(touched && errors?.enrollmentStart)}
                  required
                  size='small'
                  label='Enrollment Start'
                  value={values.enrollmentStart ? new Date(values.enrollmentStart) : null}
                />
              )}
              onChange={(date: Date) => {
                setFieldValue(`enrollmentStart`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
            <FormHelperText error={Boolean(touched && errors?.enrollmentStart)}>{touched && errors?.enrollmentStart}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required variant='outlined'>
            <DatePicker
              label='Enrollment End'
              minDate={values.enrollmentStart ? new Date(values.enrollmentStart) : null}
              value={values.enrollmentEnd ? new Date(values.enrollmentEnd) : null}
              renderInput={props => (
                <TextField
                  {...props}
                  error={Boolean(touched && errors?.enrollmentEnd)}
                  required
                  size='small'
                  label='Enrollment End'
                  value={values.enrollmentEnd ? new Date(values.enrollmentEnd) : null}
                />
              )}
              onChange={(date: Date) => {
                setFieldValue(`enrollmentEnd`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
          </FormControl>
          <FormHelperText error={Boolean(touched && errors?.enrollmentEnd)}>{touched && errors?.enrollmentEnd}</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size='small'
            autoComplete='nope'
            label='Target Date'
            name='name'
            InputProps={{
              readOnly: true
            }}
            value={values.targetDate ? formatShortFriendlyDateWithTime(values.targetDate) : ''}
            onBlur={handleBlur}
            onChange={e => setFieldValue('targetDate', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {values.projectType && (
          <Box display='flex' width='100%' justifyContent='flex-end' paddingTop='1rem'>
            <Button
              variant='contained'
              color='secondary'
              disabled={!values.enrollmentEnd && !values.enrollmentStart && !values.targetDate}
              onClick={() => showPreview(!isEnrollmentPreview)}
            >
              {isEnrollmentPreview ? 'Finish Preview' : 'Preview Enrollment Builder'}
            </Button>
          </Box>
        )}
        {isEnrollmentPreview && (
          <>
            <Grid item container spacing={1}>
              {previewOptions.map((option, index) => {
                let key = formatDueDateKey(option);
                return (
                  option.url && (
                    <Grid key={`${index}`} item xs={12} sm={6} md={3}>
                      <a className={classes.card} href={option.url} rel='noopener noreferrer' target='_blank'>
                        <EnrollmentBuilderPod option={option} dueDate={dueDates[key]} />
                      </a>
                    </Grid>
                  )
                );
              })}
            </Grid>
            <EnrollmentBuilderTimeline dueDates={dueDates} steps={options} startDate={values.enrollmentStart} endDate={values.enrollmentEnd} />
          </>
        )}
        {values.projectType && !isEnrollmentPreview && (
          <FieldArray name='buildSteps'>
            {() => {
              return <SortableList useDragHandle options={options} values={values} handleBlur={handleBlur} touched={touched} errors={errors} onSortEnd={onSortEnd} axis='xy' />;
            }}
          </FieldArray>
        )}
      </Grid>
    </>
  );
};

const DragHandle = SortableHandle(() => {
  const classes = useStyles();
  return <DragIndicator className={classes.dragIcon} />;
});

const SortableList = SortableContainer(({ options, values, handleBlur, touched, errors }: any) => {
  return (
    <Grid item container spacing={1}>
      {options.map((option, buildStepIndex) => {
        const selected = buildSteps.find(pod => pod.buildStep === option.buildStep);
        const selectedIndex = values.buildSteps.findIndex(pod => pod.buildStep === option.buildStep);
        return (
          <SortableItem
            key={`item-${buildStepIndex}`}
            selectedIndex={selectedIndex}
            selected={selected}
            values={values}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            option={option}
            index={buildStepIndex}
          />
        );
      })}
    </Grid>
  );
});

const SortableItem = SortableElement(({ selectedIndex, selected, values, handleBlur, touched, errors, option }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={3} sx={{ zIndex: 1 }}>
      <Card sx={{ position: 'relative' }}>
        <CardContent>
          <Box display='flex' justifyContent='center'>
            {selected?.icon}
          </Box>
          <DragHandle />
          <Box display='flex' flexDirection='column' alignItems='center' minHeight='9rem'>
            {/* make space between uppercase words */}
            <Typography sx={{ marginBottom: '1rem' }}>{formatName(option.buildStep)}</Typography>
            {option.buildStep !== 'ProjectTracker' && (
              <>
                <Field name={`buildSteps.${selectedIndex}.url`}>
                  {({ field }) => {
                    return (
                      <TextField
                        {...field}
                        sx={{ marginBottom: '.85rem' }}
                        fullWidth
                        size='small'
                        autoComplete='nope'
                        label='URL'
                        name={`buildSteps.${selectedIndex}.url`}
                        value={values.buildSteps[selectedIndex].url}
                        onBlur={handleBlur}
                        error={Boolean(touched && errors?.buildSteps?.[selectedIndex]?.url ? true : false)}
                        helperText={touched && errors?.buildSteps?.[selectedIndex]?.url}
                      />
                    );
                  }}
                </Field>
                <Field name={`buildSteps.${selectedIndex}.buildStepStatus`}>
                  {({ field }) => {
                    return (
                      <FormControl fullWidth variant='outlined' size='small' error={errors?.buildSteps?.[selectedIndex]?.buildStepStatus ? true : false}>
                        <InputLabel id={`buildSteps.${selectedIndex}.buildStepStatus`} className={clsx(classes.outlinedLabel)}>
                          Status
                        </InputLabel>
                        <Select
                          {...field}
                          name={`buildSteps.${selectedIndex}.buildStepStatus`}
                          labelId={` buildSteps.${selectedIndex}.buildStepStatus`}
                          id={`buildSteps.${selectedIndex}.buildStepStatus`}
                          size='small'
                          value={values.buildSteps[selectedIndex].buildStepStatus}
                          onBlur={handleBlur}
                        >
                          {[
                            { label: 'Overdue', value: 'Overdue' },
                            { label: 'Attention Needed', value: 'AttentionNeeded' },
                            { label: 'In Progress', value: 'InProgress' },
                            { label: 'Done', value: 'Done' }
                          ].map((status: { label: string; value: string }, index) => {
                            return (
                              <MenuItem key={`${index}`} value={status.value}>
                                {status.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText error={errors?.buildSteps?.[selectedIndex]?.buildStepStatus ? true : false}>
                          {errors?.buildSteps?.[selectedIndex]?.buildStepStatus}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                </Field>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  previewMainIcon: {
    '& svg': {
      width: '4rem',
      height: '4rem'
    }
  },
  previewWrapper: {
    minHeight: '7.5rem'
  },
  previewTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: '0.5rem',
    marginBottom: '-1rem',
    textAlign: 'center'
  },
  battIcon: {
    width: '6rem',
    height: '6rem',
    transform: 'rotateZ(90deg)',
    marginBottom: '-1rem'
  },
  card: {
    position: 'relative',
    textDecoration: 'none',
    '&:hover': {
      boxShadow: `0px 2px 1px -1px ${theme.palette.primary.main}, 0px 1px 1px 0px ${theme.palette.primary.main}, 0px 1px 3px 0px ${theme.palette.primary.main}`
    },
    '&.active': {
      boxShadow: `0px 0px 1px 2px ${theme.palette.primary.main}`
    }
  },
  date: {
    position: 'absolute',
    bottom: 5,
    left: 10
  },
  dragIcon: {
    position: 'absolute',
    top: 8,
    right: 10,
    background: 'white',
    fill: theme.palette.grey[600],
    fontSize: '2rem',
    cursor: 'grab'
  }
}));
