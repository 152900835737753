import React, { useEffect } from 'react'
import { useLateralFormMenuControl } from '../../contexts/LateralFormMenuControlContext';
import { BusinessClientMenusTypes } from '../MenuData/types';
import ClientDetails from './tabs-forms-contents/ClientDetails/ClientDetails';
import ProductDetails from './tabs-forms-contents/ProductDetails/ProductDetails';
import PostEnrollmentData from './tabs-forms-contents/PostEnrollmentData/PostEnrollmentData';
import menuData from '../MenuData';
import useMenuSubMenuFormErrorsSynchronizer from '../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import DesignCenter from './tabs-forms-contents/DesignCenter/DesignCenter';
import Home from './tabs-forms-contents/Home/Home';
import { getBusinessClientTargetDate } from '../../../../../fetch';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../models';
import EnrollmentStrategy from './tabs-forms-contents/EnrollmentStrategy/EnrollmentStrategy';
import HRPreferences from './tabs-forms-contents/HRPreferences/HRPreferences';
import EnrollmentPlanner from './tabs-forms-contents/EnrollmentPlanner/EnrollmentPlanner';


const componentDictionary: Record<BusinessClientMenusTypes, JSX.Element> = {
    'home': <Home />,
    'client-details': <ClientDetails />,
    'enrollment-planner': <EnrollmentPlanner />,
    'enrollment-strategy': <><EnrollmentStrategy /></>,
    'product-details': <ProductDetails />,
    'hr-preferences': <HRPreferences />,
    'design-center': <DesignCenter />,
    'post-enrollment-data': <PostEnrollmentData />,
};

type Props = {}

const TabsForms = ({ }: Props) => {

    const { selectedMenu } = useLateralFormMenuControl();

    useMenuSubMenuFormErrorsSynchronizer(menuData);

    const { values, setFieldValue } = useFormikContext<IBusinessClientDetailFormValues>();




    useEffect(() => {

        const fetchTargetDate = async (start: string, projectType: string) => {
            try {
                const res = await getBusinessClientTargetDate(start, projectType);
                setFieldValue('targetDate', res.targetDate);
            } catch (error) {
                console.log(error);
            }
        };

        if (values.enrollmentStart && values.projectType) {
            fetchTargetDate(values.enrollmentStart, values.projectType);
        }
        
    }, [values.enrollmentStart, values.projectType, setFieldValue]);

    return (
        <>
            {componentDictionary[selectedMenu]}
        </>
    )
}

export default TabsForms