import React, { FC } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Link as MLink } from 'react-router-dom';

interface ILink {
  className?: string;
  to: string;
  type?: 'primary' | 'white';
  testId?: string;
}

export const Link: FC<ILink> = ({ to, children, className, type = 'primary', testId }) => {
  const classes = useStyles();
  return (
    <MLink data-cy={testId} to={to} className={clsx(classes[type], className)}>
      {children}
    </MLink>
  );
};

const useStyles = makeStyles(theme => ({
  white: {
    color: theme.palette.background.paper,
    textDecoration: 'none'
  },
  primary: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }
}));
