import React from 'react';
import { Box, Grid, TextField, FormLabel as MuiLabel, FormControlLabel, Switch, Checkbox } from '@mui/material';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import { ICriticalIllnessInsurance } from '../../../../../../../../../models';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import useDefaultDropdownsOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const CriticalIllnessCoverage: React.FC = () => {

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: ICriticalIllnessInsurance = values.criticalIllnessInsurance;


    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';


    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' sx={{ marginRight: '1rem' }}>
                            Critical Illness Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerCriticalIllnessCoverage}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('criticalIllnessInsurance.offerCriticalIllnessCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerCriticalIllnessCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                    <SectionsFilesList category='CriticalIllnessInsurance' />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} >
                <PerformantTextField
                    fullWidth
                    name='criticalIllnessInsurance.criticalIllnessCarrier'
                    variant='outlined'
                    size='small'
                    label='Critical Illness Carrier'
                    value={data?.criticalIllnessCarrier}
                    onChange={(e) => {
                        setFieldValue('criticalIllnessInsurance.criticalIllnessCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.criticalIllnessInsurance?.criticalIllnessCarrier} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <PerformantTextField
                    fullWidth
                    name='criticalIllnessInsurance.previousCriticalIllnessCarrier'
                    variant='outlined'
                    size='small'
                    label='Previous Critical Illness Carrier'
                    value={data?.previousCriticalIllnessCarrier}
                    onChange={(e) => {
                        setFieldValue('criticalIllnessInsurance.previousCriticalIllnessCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.criticalIllnessInsurance?.previousCriticalIllnessCarrier} />
            </Grid>
            <Grid item xs={12} sm={6} >

                <FormControlLabel
                    value={data?.ciTaxStatus}
                    control={
                        <Checkbox
                            color='primary'
                            checked={data?.ciTaxStatus}
                            name='criticalIllnessInsurance.ciTaxStatus'
                            onChange={(_, checked: boolean) => setFieldValue('criticalIllnessInsurance.ciTaxStatus', checked)}
                        />
                    }
                    label={'Critical Illness Tax Status'}
                />

            </Grid>
            <Grid item xs={12} sm={6} >
                <PerformantTextField
                    fullWidth
                    name='criticalIllnessInsurance.criticalIllnessNotes'
                    multiline
                    variant='outlined'
                    size='small'
                    label='Critical Illness Notes'
                    value={data?.criticalIllnessNotes}
                    onChange={(e) => {
                        setFieldValue('criticalIllnessInsurance.criticalIllnessNotes', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.criticalIllnessInsurance?.criticalIllnessNotes} />
            </Grid>

            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.criticalIllnessOffering ?? ''}
                    error={errors?.criticalIllnessInsurance?.criticalIllnessOffering}
                    label='Critical Illness Offering'
                    id='criticalIllnessInsurance.criticalIllnessOffering'
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.criticalIllnessGIEligibility ?? ''}
                    error={errors?.criticalIllnessInsurance?.criticalIllnessGIEligibility}
                    label='Critical Illness GI Eligibility'
                    id='criticalIllnessInsurance.criticalIllnessGIEligibility'
                />
            </Grid>


            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPreExRulesOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.ciPreExRules ?? ''}
                    error={errors?.criticalIllnessInsurance?.ciPreExRules}
                    label='CI Pre-Ex Rules'
                    id='criticalIllnessInsurance.ciPreExRules'
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.ciPortability ?? ''}
                    error={errors?.criticalIllnessInsurance?.ciPortability}
                    label='CI Portability'
                    id='criticalIllnessInsurance.ciPortability'
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.creditForTimeServedOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.ciCreditForTimeServed ?? ''}
                    error={errors?.criticalIllnessInsurance?.ciCreditForTimeServed}
                    label='CI Credit For Time Served?'
                    id='criticalIllnessInsurance.ciCreditForTimeServed'
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.criticalIllnessPlanTypeOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.criticalIllnessPlan ?? ''}
                    error={errors?.criticalIllnessInsurance?.criticalIllnessPlan}
                    label='Critical Illness Plan Type'
                    id='criticalIllnessInsurance.criticalIllnessPlan'
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.employeeRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.ciEmployeeRateBasis ?? ''}
                    error={errors?.criticalIllnessInsurance?.ciEmployeeRateBasis}
                    label='CI Employee Rate Basis'
                    id='criticalIllnessInsurance.ciEmployeeRateBasis'
                />
            </Grid>


            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.spouseRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.ciSpouseRateBasis ?? ''}
                    error={errors?.criticalIllnessInsurance?.ciSpouseRateBasis}
                    label='CI Spouse Rate Basis'
                    id='criticalIllnessInsurance.ciSpouseRateBasis'
                />
            </Grid>

            <Grid item xs={12} sm={6} >
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.tobaccoRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.ciTobaccoRateBasis ?? ''}
                    error={errors?.criticalIllnessInsurance?.ciTobaccoRateBasis}
                    label='CI Tobacco Rate Basis'
                    id='criticalIllnessInsurance.ciTobaccoRateBasis'
                />
            </Grid>
        </Grid>
    );
};

export default CriticalIllnessCoverage;
