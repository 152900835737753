import React, { FC, Fragment, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FieldArray, Field } from 'formik';
import { Close } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
// helpers
import { formatInputPhoneNumber } from '../../../helpers';
// Components
import { Accordion } from '../../../components';
import {
  Box,
  MenuItem,
  Select,
  InputLabel,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  IconButton,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import { FileDrop, FileUploaded } from '../../../components/file';
// models
import { IBroker, IDropdownResponse, IBusinessClientDetail } from '../../../models';
import { Alert } from '@mui/material';
import clsx from 'clsx';
import { formLabel } from './shared/styles';
import BusinessClientClassesTable from './BusinessClientClassesTable';
import BusinessClientClasses from './BusinessClientClasses';

interface IBusinessClientInfoProps {
  values: any;
  handleBlur: any;
  touched: any;
  errors: any;
  setFieldValue: (key: string, val: any) => void;
  setFieldTouched: (key: string, val: any) => void;
  selectedTab: number;
  mobileAppInfoTabValid: boolean;
  currentBusinessClient: IBusinessClientDetail | null;
  isMobile: boolean;
  imageDZUrl: any;
  previewError: any;
  brokers: IBroker[];
  brokersLoading: boolean;
  waitingPeriodsLoading: boolean;
  waitingPeriods: IDropdownResponse[];
}

export const BusinessClientInfo: FC<IBusinessClientInfoProps> = ({
  imageDZUrl,
  previewError,
  isMobile,
  setFieldTouched,
  mobileAppInfoTabValid,
  currentBusinessClient,
  values,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  selectedTab,
  brokers,
  waitingPeriodsLoading,
  waitingPeriods,
  brokersLoading
}) => {
  const classes = useStyles();

  useEffect(() => {
    console.log('errors', errors)
  }, [errors])


  const [shouldCompanyNameUsedInCommunication, setShouldCompanyNameUsedInCommunication] = useState(values?.shouldCompanyNameUsedInCommunication ?? false);

  return (
    <div role='tabpanel' hidden={selectedTab !== 0} id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
      {!mobileAppInfoTabValid && (
        <Alert severity='error' variant='filled' className={classes.validationAlert}>
          There are required fields and/or validation errors on Mobile App Info that need to be resolved before{' '}
          {currentBusinessClient ? 'changes can be saved.' : 'the business client can be created.'}
        </Alert>
      )}
      <Box marginBottom='1rem'>
        <FormLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
          Automated Employee Communication
        </FormLabel>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={values.automatedEmployeeCommunication}
              id='automatedEmployeeCommunication'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('automatedEmployeeCommunication', e.target.checked);
              }}
              onBlur={handleBlur}
            />
          }
          label={values.automatedEmployeeCommunication ? 'ON' : 'OFF'}
        />
      </Box>
      <Box marginBottom='1rem'>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              size='small'
              autoComplete='nope'
              label='Company Name'
              name='name'
              value={values.name}
              onBlur={handleBlur}
              onChange={e => setFieldValue('name', e.target.value)}
              error={Boolean(touched && errors.name)}
              helperText={touched && errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant='outlined' required error={touched && errors.brokerName ? true : false}>
              <Autocomplete
                value={values.brokerName}
                classes={{
                  paper: classes.paperBorder
                }}
                onChange={(event, newValue: IBroker) => {
                  if (newValue) {
                    setFieldValue('brokerName', newValue.name);
                  } else {
                    setFieldValue('brokerName', '');
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                loading={brokersLoading}
                id='brokerName'
                options={brokers}
                getOptionLabel={(broker: IBroker) => {
                  // Value selected with enter, right from the input
                  if (typeof broker === 'string') {
                    return broker;
                  }
                  return broker.name;
                }}
                renderOption={(props, option: IBroker) => {
                  return <li {...props}>{option.name}</li>;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    size='small'
                    autoComplete='off'
                    error={Boolean(touched && errors.brokerName)}
                    onBlur={handleBlur}
                    label='Assign Broker'
                    variant='outlined'
                  />
                )}
              />

              {touched && errors.brokerName && <FormHelperText>{errors.brokerName}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box marginBottom='1rem'>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} md={6}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Should the Company Name used in communications to employees?</FormLabel>
              <RadioGroup
                row
                aria-label={`shouldCompanyNameUsedInCommunication`}
                name={`shouldCompanyNameUsedInCommunication`}
                value={shouldCompanyNameUsedInCommunication}
                color='primary'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value === 'true') {
                    setShouldCompanyNameUsedInCommunication(true);
                    setFieldValue('shouldCompanyNameUsedInCommunication', true);
                  } else {
                    setShouldCompanyNameUsedInCommunication(false);
                    setFieldValue('shouldCompanyNameUsedInCommunication', false);
                    setFieldValue('companyNameUsedInCommunication', null)
                  }
                }}
              >
                <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>

            {shouldCompanyNameUsedInCommunication && <TextField
              fullWidth
              required
              size='small'
              autoComplete='nope'
              label='Company Name used in communications'
              name='companyNameUsedInCommunication'
              value={values.companyNameUsedInCommunication}
              onBlur={handleBlur}
              onChange={e => setFieldValue('companyNameUsedInCommunication', e.target.value)}
              error={Boolean(touched && errors.companyNameUsedInCommunication)}
              helperText={touched && errors.companyNameUsedInCommunication}
            />}

          </Grid>

        </Grid>
      </Box>
      <Accordion title='Human Resource Contact' defaultExpanded component='h3' variant='h4'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='First Name'
              name='hrManagerFirstName'
              value={values.hrManagerFirstName}
              onBlur={handleBlur}
              onChange={e => setFieldValue('hrManagerFirstName', e.target.value)}
              error={Boolean(touched.hrManagerFirstName && errors.hrManagerFirstName)}
              helperText={touched.hrManagerFirstName && errors.hrManagerFirstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Last Name'
              name='hrManagerLastName'
              value={values.hrManagerLastName}
              onBlur={handleBlur}
              onChange={e => setFieldValue('hrManagerLastName', e.target.value)}
              error={Boolean(touched.hrManagerLastName && errors.hrManagerLastName)}
              helperText={touched.hrManagerLastName && errors.hrManagerLastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Email'
              name='hrManagerEmail'
              placeholder='test@example.com'
              value={values.hrManagerEmail}
              onBlur={handleBlur}
              onChange={e => setFieldValue('hrManagerEmail', e.target.value)}
              error={Boolean(touched.hrManagerEmail && errors.hrManagerEmail)}
              helperText={touched.hrManagerEmail && errors.hrManagerEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Phone Number'
              name='hrManagerPhoneNumber'
              value={values.hrManagerPhoneNumber}
              onBlur={handleBlur}
              onChange={e => setFieldValue('hrManagerPhoneNumber', formatInputPhoneNumber(e.target.value))}
              error={Boolean(touched.hrManagerPhoneNumber && errors.hrManagerPhoneNumber)}
              helperText={touched.hrManagerPhoneNumber && errors.hrManagerPhoneNumber}
            />
          </Grid>
        </Grid>
      </Accordion>
      <Accordion title='Client Benefit Details' component='h3' variant='h4'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Case Overview'
              name='caseOverviewUrl'
              placeholder='www.example.com'
              value={values.caseOverviewUrl}
              onBlur={handleBlur}
              onChange={e => setFieldValue('caseOverviewUrl', e.target.value)}
              error={Boolean(touched.caseOverviewUrl && errors.caseOverviewUrl)}
              helperText={touched.caseOverviewUrl && errors.caseOverviewUrl}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Benefit Program Support Docs'
              name='benefitProgramSupportDocumentationUrl'
              placeholder='www.example.com'
              value={values.benefitProgramSupportDocumentationUrl}
              onBlur={handleBlur}
              onChange={e => setFieldValue('benefitProgramSupportDocumentationUrl', e.target.value)}
              error={Boolean(touched.benefitProgramSupportDocumentationUrl && errors.benefitProgramSupportDocumentationUrl)}
              helperText={touched.benefitProgramSupportDocumentationUrl && errors.benefitProgramSupportDocumentationUrl}
            />
          </Grid>
        </Grid>

        {/* <BusinessClientClassesTable
          businessClientClasses={values.classes}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        /> */}

        <BusinessClientClasses
          businessClientClasses={values.classes}
          errors={errors}
          values={values}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />

        {/* <FieldArray name='classes'>
          {({ push, remove }) => {
            return (
              <div>
                {values.classes.length > 0 &&
                  values.classes.map((clientClass, index) => {
                    return (
                      <Fragment key={`${index}`}>
                        <Typography variant='h5' component='h5' className={classes.classText}>
                          Class {index + 1}
                        </Typography>
                        <Grid container spacing={1} alignItems='center'>
                          <Grid item xs={12}>
                            <Field name={`classes.${index}.name`}>
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    autoComplete='nope'
                                    label='Class Name'
                                    name={`classes.${index}.name`}
                                    value={values.classes[index].name}
                                    onBlur={handleBlur}
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`classes.${index}.waitingPeriod`}>
                              {({ field }) => {
                                return (
                                  <FormControl
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    required
                                    error={
                                      errors &&
                                        errors.classes &&
                                        errors.classes[index] &&
                                        // @ts-ignore
                                        errors.classes[index].waitingPeriod
                                        ? true
                                        : false
                                    }
                                  >
                                    <InputLabel id={`waiting-peroid-${index}`} className={clsx(classes.outlinedLabel, classes.smallerLeading)}>
                                      Waiting Period
                                    </InputLabel>
                                    <Select
                                      {...field}
                                      name={`classes.${index}.waitingPeriod`}
                                      labelId={`classes.${index}.waitingPeriod`}
                                      id={`classes.${index}.waitingPeriod`}
                                      size='small'
                                      value={values.classes[index].waitingPeriod}
                                      onBlur={handleBlur}
                                    >
                                      {!waitingPeriodsLoading &&
                                        waitingPeriods.map((period: IDropdownResponse, index) => {
                                          return (
                                            <MenuItem key={`${index}`} value={period.value}>
                                              {period.description}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                    <FormHelperText
                                      error={
                                        errors &&
                                          errors.classes &&
                                          errors.classes[index] &&
                                          // @ts-ignore
                                          errors.classes[index].waitingPeriod
                                          ? true
                                          : false
                                      }
                                    >
                                      {errors &&
                                        errors.classes &&
                                        errors.classes[index] &&
                                        // @ts-ignore
                                        errors.classes[index].waitingPeriod}
                                    </FormHelperText>
                                  </FormControl>
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field name={`classes.${index}.benefitGuideUrl`}>
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    autoComplete='nope'
                                    size='small'
                                    label='Benefit Guide URL'
                                    name={`classes.${index}.benefitGuideUrl`}
                                    value={values.classes[index].benefitGuideUrl || ''}
                                    onBlur={handleBlur}
                                    placeholder='www.example.com'
                                    error={
                                      errors &&
                                        errors.classes &&
                                        errors.classes[index] &&
                                        // @ts-ignore
                                        errors.classes[index].benefitGuideUrl
                                        ? true
                                        : false
                                    }
                                    // @ts-ignore
                                    helperText={
                                      errors &&
                                      errors.classes &&
                                      errors.classes[index] &&
                                      // @ts-ignore
                                      errors.classes[index].benefitGuideUrl
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field name={`classes.${index}.videoUrl`}>
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    autoComplete='nope'
                                    label='Video URL'
                                    name={`classes.${index}.videoUrl`}
                                    value={values.classes[index].videoUrl || ''}
                                    onBlur={handleBlur}
                                    placeholder='www.example.com'
                                    error={
                                      errors &&
                                        errors.classes &&
                                        errors.classes[index] &&
                                        // @ts-ignore
                                        errors.classes[index].videoUrl
                                        ? true
                                        : false
                                    }
                                    // @ts-ignore
                                    helperText={
                                      errors &&
                                      errors.classes &&
                                      errors.classes[index] &&
                                      // @ts-ignore
                                      errors.classes[index].videoUrl
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} className={classes.removeButtonWrapper}>
                            <Button
                              className={classes.removeButton}
                              startIcon={<Close />}
                              onClick={() => {
                                remove(index);
                                const newQrCodes = [...values.qrCodes];
                                setFieldValue(
                                  'qrCodes',
                                  newQrCodes.filter(code => code.businessClientClassId !== clientClass.businessClientClassId)
                                );
                              }}
                            >
                              Remove Class
                            </Button>
                          </Grid>
                        </Grid>
                      </Fragment>
                    );
                  })}
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.addClassButton}
                  onClick={() =>
                    push({
                      name: '',
                      videoUrl: '',
                      benefitGuideUrl: '',
                      waitingPeriod: ''
                    })
                  }
                >
                  Add Class
                </Button>
              </div>
            );
          }}
        </FieldArray> */}
      </Accordion>
      <Accordion title='Location and Scheduling' component='h3' variant='h4'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='New Hire Schedule Link'
              name='newHireScheduleLink'
              placeholder='www.example.com'
              value={values.newHireScheduleUrl}
              onBlur={handleBlur}
              onChange={e => setFieldValue('newHireScheduleUrl', e.target.value)}
              error={Boolean(touched.newHireScheduleUrl && errors.newHireScheduleUrl)}
              helperText={touched.newHireScheduleUrl && errors.newHireScheduleUrl}
            />
          </Grid>
        </Grid>
        <FieldArray name='location'>
          {({ push, remove }) => {
            return (
              <Grid container spacing={1}>
                {values.location.length > 0 &&
                  values.location.map((clientLocation, index) => {
                    return (
                      <Grid item xs={12} lg={6} key={`${index}`}>
                        <Typography variant='h5' component='h5' className={classes.classText}>
                          Location {index + 1}
                        </Typography>
                        <Grid container spacing={1} alignItems='center'>
                          <Grid item xs={12} sm={6} md={5} lg={5}>
                            <Field name={`location.${index}.name`}>
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    autoComplete='nope'
                                    label='Location Name'
                                    name={`location.${index}.name`}
                                    value={values.location[index].name}
                                    onBlur={handleBlur}
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={5}>
                            <Field name={`location.${index}.calendarLinkUrl`}>
                              {({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    autoComplete='nope'
                                    size='small'
                                    label='Calendar URL'
                                    name={`location.${index}.calendarLinkUrl`}
                                    value={values.location[index].calendarLinkUrl || ''}
                                    onBlur={handleBlur}
                                    placeholder='www.example.com'
                                    error={
                                      errors &&
                                        errors.location &&
                                        errors.location[index] &&
                                        // @ts-ignore
                                        errors.location[index].calendarLinkUrl
                                        ? true
                                        : false
                                    }
                                    // @ts-ignore
                                    helperText={
                                      errors &&
                                      errors.location &&
                                      errors.location[index] &&
                                      // @ts-ignore
                                      errors.location[index].calendarLinkUrl
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={1} lg={2} className={classes.removeButtonWrapper}>
                            {isMobile ? (
                              <Button
                                className={classes.removeButton}
                                startIcon={<Close />}
                                onClick={() => {
                                  remove(index);
                                  const newQrCodes = [...values.qrCodes];
                                  setFieldValue(
                                    'qrCodes',
                                    newQrCodes.filter(code => code.businessClientLocationId !== clientLocation.businessClientLocationId)
                                  );
                                }}
                              >
                                Remove Location
                              </Button>
                            ) : (
                              <IconButton
                                color='default'
                                className={classes.removeButton}
                                onClick={() => {
                                  remove(index);
                                  const newQrCodes = [...values.qrCodes];
                                  setFieldValue(
                                    'qrCodes',
                                    newQrCodes.filter(code => code.businessClientLocationId !== clientLocation.businessClientLocationId)
                                  );
                                }}
                                size='large'
                              >
                                <Close />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() =>
                      push({
                        name: '',
                        calendarLinkUrl: '',
                        supervisorName: ''
                      })
                    }
                  >
                    Add Location
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        </FieldArray>
      </Accordion>
      <Accordion title='Company Logo' component='h3' variant='h4'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            {values.logoUrl && (
              <FileUploaded
                className={classes.logoUploaded}
                id='company-logo-image-preview'
                onRemove={() => {
                  setFieldValue(`logoUrl`, '');
                  setFieldTouched('logoUrl', true);
                }}
                src={values.logoUrl as unknown as string}
                backgroundColor={'#000'}
              />
            )}
            {!values.logoUrl && (
              <>
                <FileDrop id='company-logo-image-drop' {...imageDZUrl} title='Add Company Logo' className={classes.logoDrop}>
                  <Typography variant='body1'>
                    Drag and drop a PNG or JPG file or{' '}
                    <a href='/#' onClick={e => e.preventDefault()}>
                      browse and pick
                    </a>{' '}
                    a file.
                  </Typography>
                  <Typography variant='body2'>
                    Recommended transparent background with white logo.
                    <br />
                    Minimum height of 140 pixels.
                  </Typography>
                  {previewError && <FormHelperText error={true}>{previewError}</FormHelperText>}
                </FileDrop>
              </>
            )}
          </Grid>
        </Grid>
      </Accordion>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  content: {
    marginTop: theme.spacing(1)
  },
  modalActions: {
    marginTop: theme.spacing(2),
    '@media (max-width: 450px)': {
      flexDirection: 'column'
    }
  },
  saveButton: {
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  cancelButton: {
    '@media (max-width: 450px)': {
      width: '100%',
      marginTop: theme.spacing(0.5),
      marginLeft: `0 !important`
    }
  },
  paperBorder: {
    border: `1px solid ${theme.palette.grey[300]}`
  },
  subHeader: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    marginBottom: '-10px'
  },
  subHeaderOpen: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    marginBottom: '-10px'
  },
  divider: {
    marginTop: theme.spacing(1)
  },
  addClassButton: {
    marginTop: theme.spacing(1)
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  smallerLeading: {
    letterSpacing: '-.25px'
  },
  removeButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-8px'
  },
  removeButton: {
    color: theme.palette.error.main
  },
  classText: {
    margin: theme.spacing(0.5, 0)
  },
  inputAdornment: {
    marginRight: 3
  },
  logoDrop: {
    marginTop: theme.spacing(1),
    marginBottom: 0
  },
  logoUploaded: {
    margin: theme.spacing(1, 0, 0)
  },
  tabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  tabRoot: {
    minWidth: 0,
    fontSize: 18,
    padding: `15px 20px`,
    textTransform: 'none'
  },
  tabText: {
    color: theme.palette.grey[400]
  },
  tabSelected: {
    fontWeight: 'bold'
  },
  tabErrors: {
    color: theme.palette.error.main
  },
  tabContent: {
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  preview: {
    display: 'flex',
    justifyContent: 'center'
  },
  validationAlert: {
    marginBottom: theme.spacing(2)
  },
  noPaddingTop: {
    '&&.MuiGrid-item': {
      paddingTop: 0
    }
  },
  formLabel: formLabel,
  switchWrapper: {
    '@media (max-width: 520px)': {
      width: '100%',
      '&& > .MuiGrid-item:first-child': { paddingBottom: '.25rem' },
      '&& > .MuiGrid-item:last-child': { paddingTop: '.25rem' }
    },
    '@media (min-width: 521px)': {
      '&& > .MuiGrid-item:first-child': { maxWidth: '55%' }
    }
  }
}));
