import React, { FC, useState } from 'react';
import * as Yup from 'yup';

import { FormikErrors, useFormik } from 'formik';

import { Button, Grid } from '@mui/material';
import { ISectionDocuments, ISectionDocumentsFile } from '../../../../../../../../../../../models';
import { TextField } from "@mui/material";
import { updateEssentialDoc } from '../../../../../../../../../../../fetch/section-documents';
import { useSectionDocuments } from '../../../contexts/SectionDocumentsContext';
import useErrorMessage from '../../../../../../../../../../../context/ErrorMessageCtx';
import useSuccessMessage from '../../../../../../../../../../../context/SuccessMessageCtx';
import FieldErrorMessage from '../../../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import { makeStyles } from '@mui/styles';
import { getDocumentIcon } from '../../util';

interface IEditFileManagementFormProps {
    cancelCallback: () => void;
    section?: ISectionDocuments;
    sectionIndex?: number;
    documentFile: ISectionDocumentsFile;
}

interface FormValues {
    fileData: {
        otherInformations: string;
        externalFileUrl: string;
        docContentType: string;
        newFileVersion: any;
    };
}

const validationSchema = Yup.object().shape({
    fileData: Yup.object().shape({
        otherInformations: Yup.string().nullable(),
        externalFileUrl: Yup.string().nullable(),
        newFileVersion: Yup.mixed().test('fileRequired', 'Either File or External File URL is required', function (value) {

            if (this.parent.docContentType === 'OnlyExternalFileUrl'
                && !this.parent.externalFileUrl
                && !value) {
                return false;
            }

            return true;
        }),
    })
});

export const EditFileManagementForm: FC<IEditFileManagementFormProps> = ({ cancelCallback, section, sectionIndex, documentFile }) => {

    const { replaceSection, } = useSectionDocuments();
    const [, setErrorMessage] = useErrorMessage();
    const [, setSuccessMessage] = useSuccessMessage();

    const classes = useStyles();

    const [isUploading, setIsUploading] = useState(false);

    const haveUploadFile = ['OnlyFile', 'FileAndExternalFileUrl'].includes(documentFile?.docContentType);

    const initialValues: FormValues = {
        fileData:
        {
            otherInformations: documentFile?.otherInformations,
            docContentType: documentFile?.docContentType,
            externalFileUrl: documentFile?.externalFileUrl,
            newFileVersion: null
        },
    };

    const onSubmit = async (values: FormValues) => {

        setIsUploading(true);

        const formData = new FormData();
        formData.append('Details.ExternalFileUrl', values.fileData.externalFileUrl ?? '');
        formData.append('Details.OtherInformations', values.fileData.otherInformations ?? '');
        formData.append('Details.NewFileVersion', values.fileData.newFileVersion);

        try {
            const newSection: ISectionDocuments = await updateEssentialDoc(section.id, documentFile.id, formData);
            replaceSection(sectionIndex, newSection);
            setSuccessMessage(`Document file updated!`);
        }
        catch (error) {
            setErrorMessage(error.Title)
        }

        setIsUploading(false);
        cancelCallback();
    };

    const { handleSubmit, errors, setFieldValue, values } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const fileDataErrors = errors?.fileData as FormikErrors<{ otherInformations: '', externalFileUrl: '', newFileVersion: null, docContentType: null }>;
    const SelectedIcon = getDocumentIcon(documentFile);
    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction='column' spacing={1}>


                <Grid item md={12} sx={{
                    'marginBottom': '.9em'
                }}>
                    <div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px'
                        }}>
                            <SelectedIcon height="40px" width="40px" />
                            {haveUploadFile && <strong className={classes.fileWarning}>Choose only if you want to replace the current file: <i>{documentFile.fileName}</i></strong>}
                        </div>




                        <input
                            type="file"
                            id={`fileData.newFileVersion`}
                            name={`fileData.newFileVersion`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(`fileData.newFileVersion`, event.target.files[0])
                            }
                            }
                        />

                    </div>
                    <FieldErrorMessage message={fileDataErrors?.newFileVersion as string} />
                </Grid>

                <Grid item md={12} >
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type="url"
                        label='External File Url'
                        id={`fileData.externalFileUrl`}
                        name={`fileData.externalFileUrl`}
                        value={values?.fileData?.externalFileUrl}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`fileData.externalFileUrl`, event.target.value)
                        }
                        }
                    />
                    <FieldErrorMessage message={fileDataErrors?.externalFileUrl as string} />
                </Grid>

                <Grid item md={12} >
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        multiline
                        label='Other Informations'
                        id={`fileData.otherInformations`}
                        name={`fileData.otherInformations`}
                        value={values?.fileData?.otherInformations ?? ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`fileData.otherInformations`, event.target.value)
                        }
                        }
                    />
                    <FieldErrorMessage message={fileDataErrors?.otherInformations as string} />
                </Grid>

            </Grid>

            <div style={{
                'justifyContent': 'start',
                'margin': '1em 0 0 0'
            }}>
                <Button disabled={isUploading} type="submit" variant='contained' sx={{ marginRight: '10px' }}>
                    {isUploading ? 'Sending...' : 'Save'}
                </Button>

                <Button disabled={isUploading} color='inherit' variant='contained' onClick={() => cancelCallback()}>
                    Cancel
                </Button>
            </div>
        </form>
    );
};


const useStyles = makeStyles(theme => ({
    fileWarning: {
        backgroundColor: 'darkorange',
        padding: '10px',
        margin: '5px 0',
        fontSize: '12px',
        marginLeft: '5px',
        flexGrow: 1
    }

}));

export default EditFileManagementForm;
