import { FC } from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import { Button, Paper, Table, TableBody, Box, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';
import { Close } from '@mui/icons-material';
//import { IBusinessClientDetailFormValues, IFaceToFaceEnrollmentLocation } from '../../../../../../../../../../models';

import { useSharedStyles } from '../../../../../../shared/styles';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import { IBusinessClientDetailFormValues, IFaceToFaceEnrollmentLocation } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';

const FaceToFaceEnrollmentLocationsTable: FC = () => {
    const classes = useSharedStyles();
    const { values, handleBlur, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const locations = values.faceToFaceEnrollment?.locations ?? [];

    const locationsErrors = errors.faceToFaceEnrollment?.locations as FormikErrors<IFaceToFaceEnrollmentLocation>[];

    return (<FieldArray name='faceToFaceEnrollment.locations' >
        {({ push, remove }) => {
            return (
                <div className={classes.container}>
                    {locations.length > 0 ?
                        <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                <TableContainer component={Paper}>
                                    <Table  >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableCellSmall}></TableCell>
                                                <TableCell className={classes.tableCell}>Location Name</TableCell>
                                                <TableCell className={classes.tableCell}>Street Address</TableCell>
                                                <TableCell className={classes.tableCell}>City</TableCell>
                                                <TableCell className={classes.tableCell}>State</TableCell>
                                                <TableCell className={classes.tableCell}>Zip</TableCell>
                                                <TableCell className={classes.tableCellSmall}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>



                                            {locations.length > 0 &&
                                                locations.map((item, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell className={classes.tableCellSmall}>{index + 1}</TableCell>

                                                            <TableCell className={classes.tableCell}>
                                                                <Field name={`faceToFaceEnrollment.locations.${index}.locationName`}>
                                                                    {({ field }) => {
                                                                        return (
                                                                            <PerformantTextField
                                                                                {...field}
                                                                                fullWidth
                                                                                variant='outlined'
                                                                                size='small'
                                                                                autoComplete='nope'
                                                                                label='Location Name'
                                                                                name={`faceToFaceEnrollment.locations.${index}.locationName`}
                                                                                value={item.locationName}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        );
                                                                    }}
                                                                </Field>
                                                                <FieldErrorMessage message={locationsErrors?.[index]?.locationName} />
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}>
                                                                <Field name={`faceToFaceEnrollment.locations.${index}.streetAddress`}>
                                                                    {({ field }) => {
                                                                        return (
                                                                            <PerformantTextField
                                                                                {...field}
                                                                                fullWidth
                                                                                variant='outlined'
                                                                                size='small'
                                                                                autoComplete='nope'
                                                                                label='Street Address'
                                                                                name={`faceToFaceEnrollment.locations.${index}.streetAddress`}
                                                                                value={item.streetAddress}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        );
                                                                    }}
                                                                </Field>
                                                                <FieldErrorMessage message={locationsErrors?.[index]?.streetAddress} />
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}>
                                                                <Field name={`faceToFaceEnrollment.locations.${index}.city`}>
                                                                    {({ field }) => {
                                                                        return (
                                                                            <PerformantTextField
                                                                                {...field}
                                                                                fullWidth
                                                                                variant='outlined'
                                                                                size='small'
                                                                                autoComplete='nope'
                                                                                label='City'
                                                                                name={`faceToFaceEnrollment.locations.${index}.city`}
                                                                                value={item.city}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        );
                                                                    }}
                                                                </Field>
                                                                <FieldErrorMessage message={locationsErrors?.[index]?.city} />
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}>
                                                                <Field name={`faceToFaceEnrollment.locations.${index}.state`}>
                                                                    {({ field }) => {
                                                                        return (
                                                                            <PerformantTextField
                                                                                {...field}
                                                                                fullWidth
                                                                                variant='outlined'
                                                                                size='small'
                                                                                autoComplete='nope'
                                                                                label='State'
                                                                                name={`faceToFaceEnrollment.locations.${index}.state`}
                                                                                value={item.state}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        );
                                                                    }}
                                                                </Field>
                                                                <FieldErrorMessage message={locationsErrors?.[index]?.state} />
                                                            </TableCell>

                                                            <TableCell className={classes.tableCell}>
                                                                <Field name={`faceToFaceEnrollment.locations.${index}.zip`}>
                                                                    {({ field }) => {
                                                                        return (
                                                                            <PerformantTextField
                                                                                {...field}
                                                                                fullWidth
                                                                                variant='outlined'
                                                                                size='small'
                                                                                autoComplete='nope'
                                                                                label='Zip'
                                                                                name={`faceToFaceEnrollment.locations.${index}.zip`}
                                                                                value={item.zip}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        );
                                                                    }}
                                                                </Field>
                                                                <FieldErrorMessage message={locationsErrors?.[index]?.zip} />
                                                            </TableCell>

                                                            <TableCell className={classes.tableCellSmall}>
                                                                <Button
                                                                    className={classes.removeButton}
                                                                    startIcon={<Close />}
                                                                    onClick={() => {
                                                                        remove(index);
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}

                                        </TableBody>
                                    </Table>
                                </TableContainer> </Box></Box> : <></>}


                    {locations.length < 10 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    locationName: '',
                                    streetAddress: '',
                                    city: '',
                                    state: '',
                                    zip: ''
                                })
                            }
                        >
                            Add Location
                        </Button>
                    )}
                </div>
            );
        }}
    </FieldArray>
    )
};


export default FaceToFaceEnrollmentLocationsTable;