import React from 'react';
import { Box, Grid, FormLabel as MuiLabel, FormControlLabel, Switch, Checkbox } from '@mui/material';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import { IPortableLifeInsurance } from '../../../models';
import useDefaultDropdownsOptionsDropdownCollection from '../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface IPortableLifeCoverageProps {
    data: IPortableLifeInsurance;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const PortableLifeCoverage: React.FC<IPortableLifeCoverageProps> = ({ data, handleBlur, setFieldValue, errors }) => {
    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';


    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' sx={{ marginRight: '1rem' }}>
                            Offer Portable Life Insurance Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerPortableLifeInsuranceCoverage}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('portableLifeInsurance.offerPortableLifeInsuranceCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerPortableLifeInsuranceCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    name='portableLifeInsurance.portableLifeCarrier'
                    variant='outlined'
                    size='small'
                    label='Portable Life Carrier'
                    value={data?.portableLifeCarrier}
                    onChange={(e) => {
                        setFieldValue('portableLifeInsurance.portableLifeCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    name='portableLifeInsurance.previousPortableLifeCarrier'
                    variant='outlined'
                    size='small'
                    label='Previous Portable Life Carrier'
                    value={data?.previousPortableLifeCarrier}
                    onChange={(e) => {
                        setFieldValue('portableLifeInsurance.previousPortableLifeCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>

                <FormControlLabel
                    value={data?.portableLifeTaxStatus}
                    control={
                        <Checkbox
                            color='primary'
                            checked={data?.portableLifeTaxStatus}
                            name='portableLifeInsurance.portableLifeTaxStatus'
                            onChange={(_, checked: boolean) => setFieldValue('portableLifeInsurance.portableLifeTaxStatus', checked)}
                        />
                    }
                    label={'Portable Life Tax Status'}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    name='portableLifeInsurance.portableLifeNotes'
                    variant='outlined'
                    size='small'
                    label='Portable Life Notes'
                    value={data?.portableLifeNotes}
                    onChange={(e) => {
                        setFieldValue('portableLifeInsurance.portableLifeNotes', e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifeGIEligibility ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifeGIEligibility}
                    label='Portable Life GI Eligibility'
                    id='portableLifeInsurance.portableLifeGIEligibility'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifePortability ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifePortability}
                    label='Portable Life Portability'
                    id='portableLifeInsurance.portableLifePortability'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifeOffering ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifeOffering}
                    label='Portable Life Offering'
                    id='portableLifeInsurance.portableLifeOffering'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.portableLifePlanTypeOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifePlanType ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifePlanType}
                    label='Portable Life Offering'
                    id='portableLifeInsurance.portableLifePlanType'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPreExRulesOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifePreExRules ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifePreExRules}
                    label='Portable Life Pre-Ex Rules'
                    id='portableLifeInsurance.portableLifePreExRules'
                />
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.creditForTimeServedOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifeCreditForTimeServed ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifeCreditForTimeServed}
                    label='Portable Life Credit For Time Served'
                    id='portableLifeInsurance.portableLifeCreditForTimeServed'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.criticalIllnessIncrementsOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifeEmployeeRateBasedOn ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifeEmployeeRateBasedOn}
                    label='Portable Life Employee Rate Based On'
                    id='portableLifeInsurance.portableLifeEmployeeRateBasedOn'
                />
            </Grid>



            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.tobaccoRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifeTobaccoRate ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifeTobaccoRate}
                    label='Portable Life Tobacco Rate'
                    id='portableLifeInsurance.portableLifeTobaccoRate'
                />
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.spouseRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.portableLifeSpouseRateBasis ?? ''}
                    error={errors?.portableLifeInsurance?.portableLifeSpouseRateBasis}
                    label='Portable Life Spouse Rate Basis'
                    id='portableLifeInsurance.portableLifeSpouseRateBasis'
                />
            </Grid>
        </Grid>
    );
};

export default PortableLifeCoverage;
