import { useState } from 'react';

type RequestState<T> = {
    isLoading: boolean;
    error: Error | null;
    data: T | null;
};

const useRequestState = <T>() => {
    const [requestState, setRequestState] = useState<RequestState<T>>({
        isLoading: false,
        error: null,
        data: null,
    });

    const request = async (requestFunction: () => Promise<T>) => {
        setRequestState({
            isLoading: true,
            error: null,
            data: null,
        });

        try {
            const response = await requestFunction();
            setRequestState({
                isLoading: false,
                error: null,
                data: response,
            });
        } catch (err) {
            setRequestState({
                isLoading: false,
                error: err as Error,
                data: null,
            });
        }
    };

    return { ...requestState, request };
};

export default useRequestState;