import { useEffect, useContext } from "react";

import useErrorMessage from "../../context/ErrorMessageCtx";
import { RequestState } from "../../models/request-state";
import { DropDownCollectionsCtx } from "../../context/DropDownCollectionsCtx";
import { getBusinessClassOptions } from "../../fetch";
import { IBusinessClassOptions } from "../../models";

const useBusinessClassOptionsDropdownCollection = (): RequestState<IBusinessClassOptions> => {
    const [, setErrorMessage] = useErrorMessage();
    const { businessClassOptionsRequest, dispatchBusinessClassOptionsRequest } = useContext(DropDownCollectionsCtx);

    const status = businessClassOptionsRequest.status;


    useEffect(() => {

        if (status !== "idle") return;

        dispatchBusinessClassOptionsRequest({ type: 'REQUEST_LOADING' });

        const fetchCollection = async () => {
            try {

                const res = await getBusinessClassOptions();
                if (res) {

                    dispatchBusinessClassOptionsRequest({ type: 'REQUEST_SUCCESS', payload: res })
                } else {
                    setErrorMessage('Error loading business class options, please try again.');
                    dispatchBusinessClassOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading business class options, please try again.' })
                }
            } catch (error) {
                console.error(error);
                dispatchBusinessClassOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading business class options, please try again.' })
                setErrorMessage('Error loading business class options, please try again.');
            }


        };

        fetchCollection();
    }, [status, dispatchBusinessClassOptionsRequest, setErrorMessage]);

    return businessClassOptionsRequest;

};

export default useBusinessClassOptionsDropdownCollection