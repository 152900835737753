import { Alert } from '@mui/material';
import { useSharedStyles } from '../../../../../shared/styles';
import { Accordion } from '../../../../../../../../components';
import MedicalInsurance from './MedicalInsurance/MedicalInsurance';
import BusinessClientMedicalPlansTable from './MedicalPlansTable/MedicalPlansTable';
import BusinessClientTabContentBase from '../../../../../../../../components/business-client-tab-content-base';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { useLateralFormMenuControl } from '../../../../../contexts/LateralFormMenuControlContext';
export default function Medical() {

    const { values, errors } = useFormikContext<IBusinessClientDetailFormValues>();
    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('medical');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    const { selectAccordionCategory } = useLateralFormMenuControl();

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <BusinessClientTabContentBase benefitsList={[
                {
                    benefitName: 'Medical Insurance',
                    offered: values.medicalInsurance?.offerMedicalInsurance === true
                }
            ]} >


                <Accordion defaultExpanded={!selectAccordionCategory || selectAccordionCategory === 'MedicalInsurance'} title='Medical Insurance' hasErrors={errors?.medicalInsurance?.medicalPlans !== undefined} component='h3' variant='h4'>
                    <MedicalInsurance />
                    <BusinessClientMedicalPlansTable />

                </Accordion>

            </BusinessClientTabContentBase>

        </div>
    );
}
