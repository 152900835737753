import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Theme } from '@mui/material';

interface ITimelineProps {
  children: any;
  minWidth?: string;
}

export const calculateTimelineMinWidth = (days: number) => {
  const width = days * 140; // Minimum event width is 140px
  return width.toString() + 'px';
};

export const Timeline: FC<ITimelineProps> = ({ children, minWidth }) => {
  const classes = useStyles({ minWidth });

  return (
    <>
      <div className={clsx(classes.timeline, 'timeline--responsive')}>{children}</div>
    </>
  );
};
export const timelineResponsiveBreakpoint = 'sm';
export const timelineLabelBreakpoint = 'md';
const useStyles = makeStyles<Theme, { minWidth?: string }>((theme: Theme) => ({
  timeline: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    position: 'relative',
    padding: 0,
    transition: 'height .2s ease-in-out',
    flexWrap: 'wrap',
    width: '100%',
    boxSizing: 'content-box',
    fontSize: theme.spacing(0.8),

    '&&.timeline--responsive': {
      flexFlow: 'row wrap',

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        alignItems: 'flex-end', // assumes date is single line and content is multiple, thus need to bottom align to keep alignment
        flexFlow: 'row nowrap',
        minWidth: ({ minWidth }) => (minWidth ? minWidth : undefined)
      }
    }
  }
}));
