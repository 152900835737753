import React from 'react';
import { Box, Grid, TextField, FormLabel as MuiLabel, FormControlLabel, Switch, Checkbox } from '@mui/material';
import { useSharedStyles } from '../../../../../../shared/styles';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import useInsuranceCoverageOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useInsuranceCoverageOptionsDropdownCollection';
import { IBusinessClientDetailFormValues, IShortTermDisabilityCoverage } from '../../../../../../../../../models'; // Replace this with the actual model interface
import useDefaultDropdownsOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { useFormikContext } from 'formik';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const ShortTermDisabilityCoverage: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const data: IShortTermDisabilityCoverage = values.shortTermDisabilityCoverage;

    const insuranceCoverageOptionsRequest = useInsuranceCoverageOptionsDropdownCollection();
    const insuranceCoverageOptions = insuranceCoverageOptionsRequest.data;
    const insuranceCoverageOptionsLoading = insuranceCoverageOptionsRequest.status === 'loading';

    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';


    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Short Term Disability Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerShortTermDisabilityCoverage}
                                    id='shortTermDisabilityCoverage.offerShortTermDisabilityCoverage'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('shortTermDisabilityCoverage.offerShortTermDisabilityCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerShortTermDisabilityCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                    <SectionsFilesList category='ShortTermDisability' />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='STD Carrier'
                    name='shortTermDisabilityCoverage.stdCarrier'
                    value={data?.stdCarrier}
                    onChange={(e) => {
                        setFieldValue('shortTermDisabilityCoverage.stdCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Previous STD Carrier'
                    name='shortTermDisabilityCoverage.previousSTDCarrier'
                    value={data?.previousSTDCarrier}
                    onChange={(e) => {
                        setFieldValue('shortTermDisabilityCoverage.previousSTDCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControlLabel
                    value={data?.areMultiplePlansAvailable}
                    control={
                        <Checkbox
                            color='primary'
                            checked={data?.areMultiplePlansAvailable}
                            name='shortTermDisabilityCoverage.areMultiplePlansAvailable'
                            onChange={(_, checked: boolean) => setFieldValue('shortTermDisabilityCoverage.areMultiplePlansAvailable', checked)}
                        />
                    }
                    label={'Are there multiple plans/classes?'}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={insuranceCoverageOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={insuranceCoverageOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdOffering ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdOffering}
                    label='STD Offering'
                    id='shortTermDisabilityCoverage.stdOffering'
                />
            </Grid>


            {/* <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={preExRulesOptions ?? []}
                    isLoading={preExRulesOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdPreExRules ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdPreExRules}
                    label='STD Pre-Ex Rules'
                    id='shortTermDisabilityCoverage.stdPreExRules'
                />
            </Grid> */}



            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Class(es) Eligible'
                    name='shortTermDisabilityCoverage.eligibleClasses'
                    value={data?.eligibleClasses}
                    onChange={(e) => {
                        setFieldValue('shortTermDisabilityCoverage.eligibleClasses', e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.stdBuyUpOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdBuyUpOptions ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdBuyUpOptions}
                    label='STD Buy-Up Options'
                    id='shortTermDisabilityCoverage.stdBuyUpOptions'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdPortability ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdPortability}
                    label='STD Portability'
                    id='shortTermDisabilityCoverage.stdPortability'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.stdCoverageClassPolicyTypes ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdPolicyType ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdPolicyType}
                    label='STD Policy Type'
                    id='shortTermDisabilityCoverage.stdPolicyType'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.accidentSicknessEliminationPeriods ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.accidentSicknessEliminationPeriods ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.accidentSicknessEliminationPeriods}
                    label='Accident/Sickness Elimination Periods'
                    id='shortTermDisabilityCoverage.accidentSicknessEliminationPeriods'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.stdCoverageTerminatesOn ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.coverageTerminatesOn ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.coverageTerminatesOn}
                    label='If an employee is terminated, when should benefits be terminated?'
                    id='shortTermDisabilityCoverage.coverageTerminatesOn'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='STD Max Weekly Benefit'
                    name='shortTermDisabilityCoverage.stdMaxWeeklyBenefit'
                    value={data?.stdMaxWeeklyBenefit}
                    onChange={(e) => {
                        setFieldValue('shortTermDisabilityCoverage.stdMaxWeeklyBenefit', e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.creditForTimeServedOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdCreditForTimeServed ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdCreditForTimeServed}
                    label='STD Credit For Time Served?'
                    id='shortTermDisabilityCoverage.stdCreditForTimeServed'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPreExRulesOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdPreExRules ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdPreExRules}
                    label='STD Pre-Ex Rules'
                    id='shortTermDisabilityCoverage.stdPreExRules'
                />
            </Grid>



            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdgiEligibility ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdgiEligibility}
                    label='STD GI Eligibility'
                    id='shortTermDisabilityCoverage.stdgiEligibility'
                />

            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPaymentTypeOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.stdPaymentType ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdPaymentType}
                    label='STD Payment Type'
                    id='shortTermDisabilityCoverage.stdPaymentType'
                />
            </Grid>


            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={[
                        {
                            description:
                                "Pre-Tax",
                            shorthand
                                :
                                "Pre-Tax",
                            value
                                :
                                "PreTax"
                        }, {
                            description:
                                "Post Tax",
                            shorthand
                                :
                                "Post Tax",
                            value
                                :
                                "PostTax"
                        }]} // Replace with appropriate options
                    isLoading={false}
                    setFieldValue={setFieldValue}
                    value={data?.stdTaxStatus ?? ''}
                    error={errors?.shortTermDisabilityCoverage?.stdTaxStatus}
                    label='STD Tax Status'
                    id='shortTermDisabilityCoverage.stdTaxStatus'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <MuiLabel color='primary'>Pre-Ex Exception for Pregnancy?</MuiLabel>
                <FormControlLabel
                    control={
                        <Switch
                            color='primary'
                            checked={data?.preExExceptionForPregnancy}
                            id='shortTermDisabilityCoverage.preExExceptionForPregnancy'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('shortTermDisabilityCoverage.preExExceptionForPregnancy', e.target.checked);
                            }}
                            onBlur={handleBlur}
                        />
                    }
                    label={data?.preExExceptionForPregnancy ? 'Yes' : 'No'}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='STD Notes'
                    name='shortTermDisabilityCoverage.stdNotes'
                    value={data?.stdNotes}
                    onChange={(e) => {
                        setFieldValue('shortTermDisabilityCoverage.stdNotes', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.shortTermDisabilityCoverage?.stdNotes} />
            </Grid>
        </Grid>
    );
};

export default ShortTermDisabilityCoverage;
