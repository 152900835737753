import React, { useContext, createContext, useState } from 'react';

interface AppLogoContextType {
    appLogo: File | undefined;
    setAppLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const AppLogoContext = createContext<AppLogoContextType>({} as AppLogoContextType);

export const AppLogoProvider: React.FC = ({ children }) => {
    const [appLogo, setAppLogo] = useState<File | undefined>(undefined);

    return (
        <AppLogoContext.Provider value={{ appLogo, setAppLogo }}>
            {children}
        </AppLogoContext.Provider>
    );
};

export const useAppLogo = () => {
    const context = useContext(AppLogoContext);
    if (!context) {
        throw new Error('useAppLogo must be used within an AppLogoProvider');
    }
    return context
}

export const useAppLogoContext = () => React.useContext(AppLogoContext);