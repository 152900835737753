import React from 'react'
import { FormControl, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { DatePicker } from '../../../../../../../../../components';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';

export default function HRDates() {

    const { values, setFieldValue } = useFormikContext<IBusinessClientDetailFormValues>();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={6}>
                <FormControl fullWidth variant='outlined'>
                    <DatePicker
                        label='Deadline for HR Changes'
                        value={values.hrDeadline ? new Date(values.hrDeadline) : null}
                        renderInput={props => <TextField {...props} size='small' label='Deadline for HR Changes' value={values.hrDeadline ? new Date(values.hrDeadline) : null} />}
                        onChange={(date: Date) => {
                            setFieldValue(`hrDeadline`, date?.toISOString?.() ? date?.toISOString() : '');
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>

    )
}