import * as React from 'react';
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import { ClientDetailsSubMenusTypes } from '../../../MenuData/types';
import { ClientDetailsTabs } from './tabs-header';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import Dates from './Dates/Dates';
import { DefaultTabBase } from '../shared';
import Industry from './Industry/Industry';
import CompanyPreferences from './CompanyPreferences/CompanyPreferences';
import Classes from './Classes/Classes';
import Contacts from './Contacts/Contacts';

const componentDictionary: Record<ClientDetailsSubMenusTypes, JSX.Element> = {
    'company_info': <DefaultTabBase>
        <CompanyInfo />
    </DefaultTabBase>,
    'dates':
        <DefaultTabBase>
            <Dates />
        </DefaultTabBase>
    ,
    'contacts': <DefaultTabBase>
        <Contacts />
    </DefaultTabBase>,
    'classes': <DefaultTabBase>
        <Classes />
    </DefaultTabBase>,
    'industry': <DefaultTabBase>
        <Industry />
    </DefaultTabBase>,
    'company_preferences': <DefaultTabBase>
        <CompanyPreferences />
    </DefaultTabBase>,
};

export default function ClientDetails() {

    const { selectedSubMenu } = useLateralFormMenuControl();
    const typedSelectedSubMenu = selectedSubMenu as ClientDetailsSubMenusTypes

    return (
        <div>
            <ClientDetailsTabs validateForm={() => { }} />

            <div>
                {componentDictionary[typedSelectedSubMenu]}
            </div>
        </div>
    );
}
