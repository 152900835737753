import { FC, useState, useEffect, Fragment } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IBuildStep, ITargetDate } from '../../../models';
import { Timeline, ITimelineEvent, TimelineEvent, timelineResponsiveBreakpoint, calculateTimelineMinWidth, timelineLabelBreakpoint } from '../../../components/timeline';
import { Box } from '@mui/material';
import { buildSteps, formatName } from './EnrollmentBuilder';
import { formatDate, formatDueDateKey } from '../../../helpers';
import { TimelineSpacer, calculateSpacerWidth } from '../../../components/timeline/TimelineSpacer';

interface IEnrollmentBuilderTimeline {
  steps: IBuildStep[];
  dueDates: ITargetDate;
  startDate?: string;
  endDate?: string;
}

export const EnrollmentBuilderTimeline: FC<IEnrollmentBuilderTimeline> = ({ steps, dueDates, startDate, endDate }) => {
  const classes = useStyles();
  const [timelineData, setTimelineData] = useState<ITimelineEvent[]>([]);
  const [timelineMinWidth, setTimelineMinWidth] = useState('');

  const formatStepContent = step => {
    const selected = buildSteps.find(pod => pod.buildStep === step.buildStep);

    return (
      <a className={classes.link} title={formatName(selected.buildStep)} href={step.url} rel='noopener noreferrer' target='_blank'>
        <Box display='flex' justifyContent='center' className={classes.icon}>
          {selected?.icon}
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center' className={classes.content}>
          <strong className={classes.label}>{formatName(selected.buildStep)}</strong>
        </Box>
      </a>
    );
  };

  const assembleTimeline = () => {
    if (steps || startDate || endDate) {
      let eventArray = [];
      if (steps) {
        steps.forEach(step => {
          let key = formatDueDateKey(step);
          if (step.url && dueDates[key]) {
            const formattedStep: ITimelineEvent = { content: [formatStepContent(step)], date: dueDates[key] };
            eventArray.push(formattedStep);
          }
        });
      }
      if (formatDate(startDate) === formatDate(endDate)) {
        eventArray.push({ content: [<strong>Enrollment Date</strong>], date: startDate });
      } else {
        if (startDate) {
          eventArray.push({ content: [<strong>Enrollment Start Date</strong>], date: startDate });
        }
        if (endDate) {
          eventArray.push({ content: [<strong>Enrollment End Date</strong>], date: endDate });
        }
      }
      // Sort events by date
      eventArray.sort((a, b) => (a.date > b.date ? 1 : -1));
      // Update date Formatting
      const formattedEventArray = eventArray.map(event => {
        return { ...event, date: formatDate(event.date) };
      });
      // Check for duplicates and merge content if duplicate exists
      const mergedEventArray = [];
      formattedEventArray.forEach(function (event) {
        var existing = mergedEventArray.filter(function (v, i) {
          return v.date === event.date;
        });
        if (existing.length) {
          var existingIndex = mergedEventArray.indexOf(existing[0]);
          //console.log(mergedEventArray[existingIndex].content);
          mergedEventArray[existingIndex].content = mergedEventArray[existingIndex].content.concat(event.content);
        } else {
          mergedEventArray.push(event);
        }
      });
      setTimelineData(mergedEventArray);
      setTimelineMinWidth(calculateTimelineMinWidth(mergedEventArray.length));
    }
  };

  useEffect(() => {
    assembleTimeline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, startDate, endDate]);
  if (timelineData) {
    return (
      <Timeline minWidth={timelineMinWidth}>
        {timelineData.map((event, index) => {
          const prev = timelineData[index - 1];
          return (
            <Fragment key={`${index}`}>
              <TimelineSpacer width={calculateSpacerWidth(prev, event, timelineData)} />
              <TimelineEvent event={event} />
            </Fragment>
          );
        })}
      </Timeline>
    );
  } else return null;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(0.25),
    [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
      marginRight: 0
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        width: '2rem',
        height: '2rem'
      }
    }
  },
  content: {
    marginTop: theme.spacing(0.25)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
      flexFlow: 'column wrap'
    },
    '&&:hover, &&:focus': {
      color: theme.palette.primary.dark
    }
  },
  spacer: {
    overflow: 'hidden',
    '&&:first-child': {
      width: 0,
      flex: 1
    }
  },
  label: {
    [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
      display: 'none'
    },
    [theme.breakpoints.up(timelineLabelBreakpoint)]: {
      display: 'block'
    }
  }
}));
