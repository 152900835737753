import React from 'react';
import { Grid, TextField } from '@mui/material';
import { IChallengesAndSolutions, IEnrollmentEngagementNumbers } from '../../../../../../../../models';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import GenericDropdown from '../../../../../../../../components/generic-dropdown/GenericDropdown';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import { useSharedStyles } from '../../../../../shared/styles';
import FieldErrorMessage from '../../../../../../../../components/field-error-message/FieldErrorMessage ';

const ChallengesAndSolutions = () => {


    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: IChallengesAndSolutions = values.challengesAndSolutions;

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('product_partipation');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <Grid container spacing={2}>
                
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Enter Top Challenges to Solve for Enrollment"
                        name="challengesAndSolutions.topChallengesToSolveForEnrollment"
                        value={data?.topChallengesToSolveForEnrollment}
                        onChange={(e) => setFieldValue('challengesAndSolutions.topChallengesToSolveForEnrollment', e.target.value)}
                    />
                    <FieldErrorMessage message={errors.challengesAndSolutions?.topChallengesToSolveForEnrollment} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Enter Solutions Implemented"
                        name="challengesAndSolutions.solutionsImplemented"
                        value={data?.solutionsImplemented}
                        onChange={(e) => setFieldValue('challengesAndSolutions.solutionsImplemented', e.target.value)}
                    />
                    <FieldErrorMessage message={errors.challengesAndSolutions?.solutionsImplemented} />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        size="small"
                        label="Key Takeaways from Strategy"
                        name="challengesAndSolutions.keyTakeawaysFromStrategy"
                        value={data?.keyTakeawaysFromStrategy}
                        onChange={(e) => setFieldValue('challengesAndSolutions.keyTakeawaysFromStrategy', e.target.value)}
                    />
                    <FieldErrorMessage message={errors.challengesAndSolutions?.keyTakeawaysFromStrategy} />
                </Grid>
                
            </Grid>
        </div>
    );
};

export default ChallengesAndSolutions;
