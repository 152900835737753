import { FC } from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import { Button, Paper, Table, TableBody, Box, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IBusinessClientDetailFormValues, IMedicalInsurancePlan } from '../../../../../../../../../models';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import { useSharedStyles } from '../../../../../../shared/styles';


const BusinessClientMedicalPlansTable: FC = () => {
    const classes = useSharedStyles();
    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const medicalPlans = values.medicalInsurance?.medicalPlans ?? [];

    const medicalPlansErrors = errors.medicalInsurance?.medicalPlans as FormikErrors<IMedicalInsurancePlan>[];

    return (<FieldArray name='medicalInsurance.medicalPlans' >
        {({ push, remove }) => {
            return (
                <div className={classes.container}>
                    {medicalPlans.length > 0 ? 
                    <Box sx={{ overflow: "auto" }}>
                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        <Table  >
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCellSmall}></TableCell>
                                    <TableCell className={classes.tableCell}>Plan Name</TableCell>
                                    <TableCell className={classes.tableCell}>PCP Required</TableCell>
                                    <TableCell className={classes.tableCell}>Most Similar Previous Plan</TableCell>
                                    <TableCell className={classes.tableCellSmall}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>



                                {medicalPlans.length > 0 &&
                                    medicalPlans.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableCellSmall}>{index + 1}</TableCell>

                                                <TableCell className={classes.tableCell}>
                                                    <Field name={`medicalInsurance.medicalPlans.${index}.planName`}>
                                                        {({ field }) => {
                                                            return (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Plan Name'
                                                                    name={`medicalInsurance.medicalPlans.${index}.planName`}
                                                                    value={item.planName}
                                                                    onBlur={handleBlur}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                    <FieldErrorMessage message={medicalPlansErrors?.[index]?.planName} />
                                                </TableCell>
                                                <TableCell align='center'>

                                                    <Checkbox
                                                        color="primary"
                                                        onBlur={handleBlur}
                                                        checked={item.pcpRequired}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        name={`medicalInsurance.medicalPlans.${index}.pcpRequired`}
                                                        onChange={(_, checked: boolean) =>
                                                            setFieldValue(`medicalInsurance.medicalPlans.${index}.pcpRequired`, checked)}
                                                    />
                                                    <FieldErrorMessage message={medicalPlansErrors?.[index]?.pcpRequired} />
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <Field name={`medicalInsurance.medicalPlans.${index}.mostSimilarPreviousPlan`}>
                                                        {({ field }) => {
                                                            return (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Most Similar Previous Plan'
                                                                    name={`medicalInsurance.medicalPlans.${index}.mostSimilarPreviousPlan`}
                                                                    value={item.mostSimilarPreviousPlan}
                                                                    onBlur={handleBlur}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                    <FieldErrorMessage message={medicalPlansErrors?.[index]?.mostSimilarPreviousPlan} />
                                                </TableCell>
                                                <TableCell className={classes.tableCellSmall}>
                                                    <Button
                                                        className={classes.removeButton}
                                                        startIcon={<Close />}
                                                        onClick={() => {
                                                            remove(index);
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer> </Box></Box>: <></>}


                    {medicalPlans.length < 7 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    planName: '',
                                    pcpRequired: false,
                                    mostSimilarPreviousPlan: ''
                                })
                            }
                        >
                            Add Plan
                        </Button>
                    )}
                </div>
            );
        }}
    </FieldArray>
    )
};

export default BusinessClientMedicalPlansTable;