import Axios, { Method } from 'axios';

import { logError, logInfo } from '../services';
//let buildConfig = require('../buildSettings');
// uncomment for hitting staging
let buildConfig = require('../buildSettings.json');

const axiosInstance = Axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/api/`
});

const axiosRequest = async (method: Method, url: string, data: object | null, options: object, params?: any) => {
  logInfo(null, `axios.js request started: ${url}`);
  try {
    return await axiosInstance({
      method,
      url,
      ...(data ? { data } : {}),
      ...options,
      params
    });
  } catch (err) {
    logError(err, 'axios.js request failed');
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: async (url: string, options = {}, params?: any) => {
    return await axiosRequest('get', url, null, options, params);
  },
  post: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('post', url, data, { ...options });
  },
  put: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('put', url, data, { ...options });
  },
  patch: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('patch', url, data, { ...options });
  },
  delete: async (url: string, options = {}) => {
    return await axiosRequest('delete', url, null, { ...options });
  }
};
