import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Divider, Typography } from '@mui/material';
import React, { FC } from 'react';
import clsx from 'clsx';
// icons
import { Delete } from '@mui/icons-material';
// components
import { Button } from '../button';

export interface IFileUploadedPreview {
  name: string;
  src: string;
}

interface IFileUploadedProps {
  backgroundColor?: string;
  id: string;
  name?: string;
  onRemove: () => void;
  src: string;
  title?: string;
  margin?: boolean;
  border?: boolean;
  className?: string;
}

export const FileUploaded: FC<IFileUploadedProps> = ({ backgroundColor, id, name, onRemove, src, title, margin = true, border = true, className }) => {
  const classes = useStyles({ backgroundColor, id, name, onRemove, src, title });
  return (
    <div className={clsx(classes.fileUploaded, className, margin ? '' : classes.marginOff, border ? '' : classes.borderOff)}>
      <div className={classes.display}>
        {title && (
          <Typography className={classes.title} variant='h6'>
            {title}
          </Typography>
        )}
        <img alt='' className={classes.image} src={src} />
      </div>
      <Divider className={classes.divider} />
      <div className={classes.actions}>
        {name && (
          <Typography className={classes.name} variant='caption'>
            {name}
          </Typography>
        )}
        <Button
          className={classes.remove}
          id={`file-uploaded-remove-${id}`}
          onClick={onRemove}
          startIcon={
            <>
              <Delete className={classes.icon} />
            </>
          }
        >
          Remove &amp; Replace
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, IFileUploadedProps>((theme: Theme) => {
  return createStyles({
    actions: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing(1)
    },
    fileUploaded: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 0,
      boxShadow: '0 2px 3px rgba(0,0,0,0.06)',
      margin: theme.spacing(2, 0),
      width: '100%'
    },
    display: {
      padding: theme.spacing(1.5, 2, 2, 2)
    },
    divider: {},
    icon: {
      height: '15px',
      marginLeft: theme.spacing(0.5)
    },
    image: {
      backgroundColor: props => (props.backgroundColor ? props.backgroundColor : '#ffffff'),
      maxHeight: '350px',
      maxWidth: '100%',
      width: '100%',
      padding: theme.spacing(1)
    },
    name: {
      margin: theme.spacing(0, 1, 1, 0),
      wordBreak: 'break-word'
    },
    remove: {
      color: theme.palette.error.main,
      fontSize: theme.typography.caption.fontSize,
      margin: 0
    },
    title: {
      color: theme.palette.grey[600],
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: theme.spacing(1)
    },
    marginOff: {
      margin: 0
    },
    borderOff: {
      border: 'none'
    }
  });
});
