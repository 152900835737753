import React, { ChangeEvent, FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';

interface IEmployeeDetailCardProps {
  title: string;
  icon?: JSX.Element;
  expanded?: boolean;
  scrollable?: boolean;
  onChange?: (e: ChangeEvent<{}>, expanded: boolean) => void;
}

export const EmployeeDetailCard: FC<IEmployeeDetailCardProps> = ({ icon, title, expanded = true, scrollable = false, onChange, children }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.root} onChange={onChange} expanded={expanded} defaultExpanded>
      <AccordionSummary
        className={clsx(classes.accordion, classes.background)}
        expandIcon={<ExpandMore className={classes.icon} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div className={classes.summary}>
          {icon}
          <Typography className={classes.title}>{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={scrollable ? classes.scrollDetails : classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    overflowX: 'hidden'
  },
  accordion: {
    padding: theme.spacing(0.5, 1),
    '&& .MuiAccordionSummary-content': {
      marginBottom: 0,
      marginTop: 0
    }
  },
  summary: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fontSize: '2rem',
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.up('sm')]: {
        fontSize: '3rem'
      }
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  title: {
    fontWeight: 600
  },
  background: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  icon: {
    display: 'block',
    color: theme.palette.common.white,
    padding: 0
  },
  scrollDetails: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  }
}));
