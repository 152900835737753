import React from 'react';
import { FC } from 'react';
import { Field, FieldArray } from 'formik';
import { Button, FormControlLabel, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ISupplementalProduct } from '../../../models'; // Replace this with the actual model interface
import { useSharedStyles } from './shared/styles';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';

interface IPortableLifeCoverageGuaranteedIssuesProps {
    supplementalProducts: ISupplementalProduct[];
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
}

const SupplementalProductsTable: FC<IPortableLifeCoverageGuaranteedIssuesProps> = ({ supplementalProducts, handleBlur, setFieldValue, errors }) => {
    const classes = useSharedStyles();

    return (
        <FieldArray name='supplementalProducts'>
            {({ push, remove }) => {
                return (
                    <div className={classes.container}>
                        <TableContainer component={Paper}>
                            {supplementalProducts.length > 0 && <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCell}>Offered?</TableCell>
                                        <TableCell className={classes.tableCell}>Vendor/Carrier Name </TableCell>
                                        <TableCell className={classes.tableCell}>Notes</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {supplementalProducts.length > 0 &&
                                        supplementalProducts.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color='primary'
                                                                    checked={item?.offerSupplementalProducts}
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setFieldValue(`supplementalProducts.${index}.offerSupplementalProducts`, e.target.checked);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                            }
                                                            label={item?.offerSupplementalProducts ? 'Offered' : 'Not offered'}
                                                        />
                                                        <FieldErrorMessage message={errors?.supplementalProducts?.[index]?.offerSupplementalProducts} />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Field
                                                            name={`supplementalProducts.${index}.carrierName`}
                                                            as={PerformantTextField}
                                                            fullWidth
                                                            variant='outlined'
                                                            size='small'
                                                            autoComplete='nope'
                                                            label='Vendor/Carrier Name'
                                                            onBlur={handleBlur}
                                                        />
                                                        <FieldErrorMessage message={errors?.supplementalProducts?.[index]?.carrierName} />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Field
                                                            name={`supplementalProducts.${index}.notes`}
                                                            multiline
                                                            as={PerformantTextField}
                                                            fullWidth
                                                            variant='outlined'
                                                            size='small'
                                                            autoComplete='nope'
                                                            label='Notes'
                                                            onBlur={handleBlur}
                                                        />
                                                        <FieldErrorMessage message={errors?.supplementalProducts?.[index]?.notes} />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Button
                                                            className={classes.removeButton}
                                                            startIcon={<Close />}
                                                            onClick={() => {
                                                                remove(index);
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>}
                        </TableContainer>

                        {supplementalProducts.length < 5 && (
                            <Button
                                color='primary'
                                variant='contained'
                                className={classes.addClassButton}
                                onClick={() =>
                                    push({
                                        giAmount: undefined,
                                    })
                                }
                            >
                                Add supplemental Product
                            </Button>
                        )}
                    </div>
                );
            }}
        </FieldArray>
    );
};

export default SupplementalProductsTable;
