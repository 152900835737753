import React from 'react';
import { FC } from 'react';
import { Field, FieldArray } from 'formik';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IGroupLifeInsuranceClass } from '../../../models'; // Replace this with the actual model interface
import { useSharedStyles } from './shared/styles';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface IGroupLifeInsuranceClassesProps {
    groupLifeInsuranceClasses: IGroupLifeInsuranceClass[];
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
}

const GroupLifeInsuranceClassesTable: FC<IGroupLifeInsuranceClassesProps> = ({ groupLifeInsuranceClasses, handleBlur, errors, setFieldValue }) => {
    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    return (
        <FieldArray name='groupLifeInsurance.groupLifeInsuranceClasses'>
            {({ push, remove }) => {
                return (
                    <div className={classes.container}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCell}>Class Name</TableCell>
                                        <TableCell className={classes.tableCell}>Notes</TableCell>
                                        <TableCell className={classes.tableCell}>Face Amount</TableCell>
                                        <TableCell className={classes.tableCell}>Max Factor</TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groupLifeInsuranceClasses.length > 0 &&
                                        groupLifeInsuranceClasses.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Field name={`groupLifeInsurance.groupLifeInsuranceClasses.${index}.className`}>
                                                            {({ field }) => (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Class Name'
                                                                    value={item.className}
                                                                    onBlur={handleBlur}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FieldErrorMessage message={errors.groupLifeInsurance?.groupLifeInsuranceClasses?.[index]?.className} />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Field name={`groupLifeInsurance.groupLifeInsuranceClasses.${index}.notes`}>
                                                            {({ field }) => (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    multiline
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Notes'
                                                                    value={item.notes}
                                                                    onBlur={handleBlur}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FieldErrorMessage message={errors.groupLifeInsurance?.groupLifeInsuranceClasses?.[index]?.notes} />
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                        <GenericDropdown
                                                            handleBlur={handleBlur}
                                                            options={generalDropdownsOptions?.faceAmountOptions ?? []}
                                                            isLoading={generalDropdownsOptionsLoading}
                                                            setFieldValue={setFieldValue}
                                                            value={item?.faceAmount ?? ''}
                                                            error={errors?.groupLifeInsurance?.groupLifeInsuranceClasses?.[index]?.faceAmount}
                                                            label='Face Amount'
                                                            id={`groupLifeInsurance.groupLifeInsuranceClasses.${index}.faceAmount`}
                                                        />
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                        <GenericDropdown
                                                            handleBlur={handleBlur}
                                                            options={generalDropdownsOptions?.maxFactorOptions ?? []}
                                                            isLoading={generalDropdownsOptionsLoading}
                                                            setFieldValue={setFieldValue}
                                                            value={item?.maxFactor ?? ''}
                                                            error={errors?.groupLifeInsurance?.groupLifeInsuranceClasses?.[index]?.maxFactor}
                                                            label='Max Factor'
                                                            id={`groupLifeInsurance.groupLifeInsuranceClasses.${index}.maxFactor`}
                                                        />
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                        <Button
                                                            className={classes.removeButton}
                                                            startIcon={<Close />}
                                                            onClick={() => {
                                                                remove(index);
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {groupLifeInsuranceClasses.length < 5 && (
                            <Button
                                color='primary'
                                variant='contained'
                                className={classes.addClassButton}
                                onClick={() =>
                                    push({
                                        className: '',
                                        notes: '',
                                    })
                                }
                            >
                                Add Class
                            </Button>
                        )}
                    </div>
                );
            }}
        </FieldArray>
    );
};

export default GroupLifeInsuranceClassesTable;
