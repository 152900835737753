import React, { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
// Components
import { FormLabel, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material';
import { ExpandMore, Edit, Delete } from '@mui/icons-material';
// Types
import { IBusinessClient } from '../../../models';

interface IMobileBusinessClient {
  original: IBusinessClient;
  handleEdit: (val: IBusinessClient) => void;
  handleDelete: (val: IBusinessClient) => void;
}

export const MobileBusinessClient: FC<IMobileBusinessClient> = ({ original, handleEdit, handleDelete }) => {
  const classes = useStyles();
  const { name, broker } = original;

  return (
    <Accordion className={classes.root}>
      <AccordionSummary className={classes.accordion} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.topPanelSummaryWrapper}>
          <Typography color='primary' className={classes.boldName}>
            {name}
          </Typography>
          <div className={classes.buttonsWrapper}>
            <IconButton
              className={classes.editButton}
              color='primary'
              onClick={e => {
                e.stopPropagation();
                handleEdit(original);
              }}
              size="large">
              <Edit />
            </IconButton>
            <IconButton
              className={classes.deleteButton}
              color='default'
              onClick={e => {
                e.stopPropagation();
                handleDelete(original);
              }}
              size="large">
              <Delete />
            </IconButton>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelEmail)}>
            Business Client:
          </FormLabel>
          <Typography component='span'>{name || '--'}</Typography>
        </div>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelEmail)}>
            Broker:
          </FormLabel>
          <Typography component='span'>{broker && broker.name ? broker.name : '--'}</Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    overflowX: 'hidden'
  },
  accordion: {
    padding: '0 16px',
    '&& .MuiAccordionSummary-expandIcon': {
      padding: 3
    }
  },
  topPanelSummaryWrapper: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '&:first-child': { marginTop: 0 }
  },
  panelSummaryWrapper: {
    '&:first-child': { marginTop: 0 }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px',
    backgroundColor: theme.palette.grey[100]
  },
  roleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  editButton: {
    padding: `2px 5px`
  },
  deleteButton: {
    padding: `2px 5px`,
    color: theme.palette.error.main
  },
  boldName: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  subLabelEmail: {
    marginRight: 10
  },
  buttonsWrapper: {
    display: 'flex'
  }
}));
