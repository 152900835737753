import React from 'react';
import {
    Box,
    FormControlLabel,
    Grid,
    Switch,
    InputAdornment,
    FormLabel as MuiLabel
} from '@mui/material';
import { ILimitedPurposeFlexSpendingAccount } from '../../models';
import { useSharedStyles } from './business-client-detail/shared/styles';
import GenericDropdown from '../../components/generic-dropdown/GenericDropdown';
import { PerformantTextField } from '../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface LimitedPurposeFlexSpendingAccountProps {
    data: ILimitedPurposeFlexSpendingAccount;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const LimitedPurposeFlexSpendingAccount: React.FC<LimitedPurposeFlexSpendingAccountProps> = ({
    data,
    handleBlur,
    setFieldValue,
    errors,
    touched,
}) => {
    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Limited Purpose Flex Spending Account
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerLimitedPurposeFlexSpendingAccount}
                                    id='limitedPurposeFlexSpendingAccount.offerLimitedPurposeFlexSpendingAccount'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('limitedPurposeFlexSpendingAccount.offerLimitedPurposeFlexSpendingAccount', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={
                                data?.offerLimitedPurposeFlexSpendingAccount ? 'Offered' : 'Not offered'
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='ER Contribution'
                    name='limitedPurposeFlexSpendingAccount.erContribution'
                    value={data?.erContribution}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('limitedPurposeFlexSpendingAccount.erContribution', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    label='Employee Max Annual Contribution'
                    name='limitedPurposeFlexSpendingAccount.employeeMaxAnnualContribution'
                    value={data?.employeeMaxAnnualContribution}
                    onBlur={handleBlur}
                    onChange={(e) => {
                        setFieldValue('limitedPurposeFlexSpendingAccount.employeeMaxAnnualContribution', e.target.value);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    error={Boolean(
                        touched.limitedPurposeFlexSpendingAccount?.employeeMaxAnnualContribution &&
                        errors.limitedPurposeFlexSpendingAccount?.employeeMaxAnnualContribution
                    )}
                    helperText={
                        touched.limitedPurposeFlexSpendingAccount?.employeeMaxAnnualContribution &&
                            errors.limitedPurposeFlexSpendingAccount?.employeeMaxAnnualContribution
                            ? errors.limitedPurposeFlexSpendingAccount?.employeeMaxAnnualContribution
                            : ''
                    }
                />

            </Grid>


            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalOptions?.defaultPeriodOptions ?? []}
                    isLoading={generalOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.coverageTerminatesOn ?? ''}
                    error={errors.limitedPurposeFlexSpendingAccount?.coverageTerminatesOn}
                    label='When an employee is terminated, coverage terminates on:' id={`limitedPurposeFlexSpendingAccount.coverageTerminatesOn`}
                />
            </Grid>


        </Grid>
    );
};

export default LimitedPurposeFlexSpendingAccount;
