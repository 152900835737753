import { FC } from 'react';

import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import LateralFormMenuControl from './LateralFormMenuControl/LateralFormMenuControl';
import { LateralFormMenuControlProvider } from '../contexts/LateralFormMenuControlContext';
import TabsForms from './TabsForms/TabsForms';
import { MenuSubMenuWithErrorsProvider } from '../../../../components/list-menu-sub-menu/contexts/MenuSubMenuWithErrorsContext';
import { Loader, Page, PageTitle } from '../../../../components';
import { withTracker } from '../../../../services';
import useBusinessClient from '../hooks/useBusinessClient';

import { FormAndActions } from './form-actions';
import { AppLogoProvider } from '../contexts/AppLogoContext';
import CurrentBusinessClientContextProvider from '../contexts/CurrentBusinessClientContext';
import { EntitiesDropdownsOptionsCollectionProvider } from '../../../../context/EntitiesDropdownsOptionsCollectionCtx';
import useBrokers from '../hooks/useBrokers';
import useUsers from '../hooks/useUsers';
import useSystemOfRecords from '../hooks/useSystemOfRecords';
import { CompanyLogoProvider } from '../contexts/CompanyLogoContext';

const drawerWidth = 400;

export const BusinessClientDetailPage: FC = withTracker(() => {

    const { id }: { id: string } = useParams();
    const isNew = id === 'new';

    const businessClientRequest = useBusinessClient(Number(id));
    const currentBusinessClient = businessClientRequest.data;
    const isLoadingBusinessClient = businessClientRequest.status === 'loading';
    const loadedBusinessClient = businessClientRequest.status === 'success';

    const brokersRequestState = useBrokers();
    const brokers = brokersRequestState.data ?? [];

    const usersRequestState = useUsers();
    const users = usersRequestState.data ?? [];


    const systemOfRecordsRequestState = useSystemOfRecords();
    const systemOfRecords = systemOfRecordsRequestState.data?.records ?? [];

    const title = isLoadingBusinessClient ? 'Loading...' : currentBusinessClient?.name ?? 'Add New Business Client';


    return (
        <Page title={title}>

            {isLoadingBusinessClient && <Loader position='centered' type='inline' />}

            {loadedBusinessClient && <><PageTitle title={title} showDivider={false} />
                <LateralFormMenuControlProvider>
                    <MenuSubMenuWithErrorsProvider>
                        <Box sx={{ display: "flex", flexGrow: 1 }} >


                            <LateralFormMenuControl />
                            <Box
                                component="main"
                                sx={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    p: .9,
                                    width: { xs: `calc(100vw - ${drawerWidth}px)` }
                                }}
                            >
                                <AppLogoProvider>
                                    <CompanyLogoProvider>

                                        <CurrentBusinessClientContextProvider initialClient={currentBusinessClient}>
                                            <EntitiesDropdownsOptionsCollectionProvider
                                                brokers={brokers}
                                                systemOfRecords={systemOfRecords}
                                                users={users}>
                                                <FormAndActions isNew={isNew}>
                                                    <TabsForms />
                                                </FormAndActions>
                                            </EntitiesDropdownsOptionsCollectionProvider>
                                        </CurrentBusinessClientContextProvider>

                                    </CompanyLogoProvider>
                                </AppLogoProvider>

                            </Box>
                        </Box>
                    </MenuSubMenuWithErrorsProvider>
                </LateralFormMenuControlProvider>
            </>
            }
        </Page>
    )
});
