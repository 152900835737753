import React, { useMemo, useState, useEffect, useContext } from 'react';
import { TextField, InputAdornment, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { withTracker } from '../../../services';
// Components
import { Page } from '../../../components/skeleton';
import { Table, Pagination, Toast, PageTitle, Button, Loader } from '../../../components';
import { MobileBusinessClient } from './MobileBusinessClients';
// icons
import { Edit, Add, Delete, Close, Search } from '@mui/icons-material';
// Types
import { DESC_SORT, ASC_SORT, IBusinessClient } from '../../../models';
// Fetch
import { getBusinessClients, deleteBusinessClient } from '../../../fetch';
// hooks
import { useDebounce } from '../../../hooks';
// context
import { ManageBusinessClientCtx } from '../../../context';
import AddNewBusinessClientModal from './AddNewBusinessClient/AddNewBusinessClientModal';

export const ManageBusinessClients = withTracker(() => {
  const classes = useStyles();
  const history = useHistory();
  const { selectedSort, setSelectedSort, searchValue, setSearchValue, sortDirection, setSortDirection } = useContext(ManageBusinessClientCtx);
  // state
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [businessClients, setBusinessClients] = useState<IBusinessClient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isDeleting, setDeleting] = React.useState<boolean>(false);

  const [isAddNewBusinessClientModal, showAddNewBusinessClientModal] = useState<boolean>(false);

  const fetchBusinessClients = async (search?: string, startPage?: number) => {
    setIsLoading(true);
    try {
      const res = await getBusinessClients({
        page: startPage ?? page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        search: search === null ? undefined : search || searchValue
      });
      if (startPage) {
        setPage(0);
      }
      if (res.records) {
        setBusinessClients(res.records);
        setRecordCount(res.totalRecordCount);
      } else {
        console.error(`No 'records' on business clients response.`);
        setErrorMessage('Error loading business clients, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading business clients, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async id => {
    const result = window.confirm('Are you sure you want to delete this business client?');
    if (result) {
      setDeleting(true);
      try {
        await deleteBusinessClient(id);
        fetchBusinessClients();
        setSuccessMessage('Business Client Deleted!');
      } catch (error) {
        setErrorMessage(error);
      } finally {
        setDeleting(false);
      }
    }
  };

  const handleEdit = async id => {
    history.push(`/manage-business-clients/${id}`);
  };

  useEffect(() => {
    fetchBusinessClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === ASC_SORT ? DESC_SORT : ASC_SORT
    });
  };
  const columns = useMemo(() => {
    return [
      {
        Header: 'Business Client',
        accessor: 'name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => handleClickColumn('Name')
      },
      {
        Header: 'Broker',
        accessor: 'broker.name',
        isServerSorted: selectedSort === 'Broker',
        isServerSortedDesc: sortDirection.Broker === DESC_SORT,
        handleClickColumn: () => handleClickColumn('Broker')
      },
      {
        id: 'actions',
        Header: '',
        className: classes.editButton,
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IBusinessClient } };
        }) => {
          return (
            <>
              <Button
                id='edit'
                color='primary'
                startIcon={<Edit />}
                onClick={async () => {
                  handleEdit(original.businessClientId);
                }}
              >
                Edit
              </Button>
              <Button
                id='delete'
                className={classes.deleteButton}
                startIcon={<Delete />}
                onClick={() => {
                  handleDelete(original.businessClientId);
                }}
              >
                Delete
              </Button>
            </>
          );
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessClients, sortDirection, selectedSort, page, perPage]);

  const hasData = businessClients && businessClients.length > 0;
  const isMobile = useMediaQuery('(max-width: 960px)');

  useDebounce(
    () => {
      if (searchValue) {
        fetchBusinessClients(searchValue, 1);
      }
    },
    500,
    [searchValue]
  );

  return (
    <>
      {isDeleting && (
        <Loader position='centered' type='fullscreen'>
          Deleting...
        </Loader>
      )}
      <Page title='Manage Business Clients'>
        <PageTitle title='Manage Business Clients' />
        <div className={classes.wrapper}>
          <Box className={classes.searchWrapper}>
            <Box flex={1}>
              <TextField
                size='small'
                variant='outlined'
                placeholder='Search Business Clients...'
                name='search'
                value={searchValue || ''}
                disabled={isLoading}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '20rem'
                  },
                  marginBottom: {
                    xs: '1rem',
                    sm: 0
                  }
                }}
                InputProps={{
                  autoFocus: true,
                  startAdornment: (
                    <InputAdornment position='start' className={classes.searchIcon}>
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchValue ? (
                    <InputAdornment
                      position='end'
                      className={classes.searchCloseIcon}
                      onClick={() => {
                        setSearchValue('');
                        fetchBusinessClients(null);
                      }}
                    >
                      <Close />
                    </InputAdornment>
                  ) : null
                }}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
              />
            </Box>
            <Button
              id='add-business-client'
              data-cy='add-business-client'
              startIcon={<Add />}
              onClick={() => showAddNewBusinessClientModal(true)}
              color='primary'
              variant='contained'
              className={classes.addButton}
            >
              Add Business Client
            </Button>
          </Box>
          <Table
            columns={columns}
            data={businessClients}
            isLoading={isLoading}
            hidePagination
            ResponsiveComponent={isMobile ? MobileBusinessClient : undefined}
            mobileProps={{
              handleEdit: (bussinessClient: IBusinessClient) => handleEdit(bussinessClient.businessClientId),
              handleDelete: (bussinessClient: IBusinessClient) => handleDelete(bussinessClient.businessClientId)
            }}
          />

          <AddNewBusinessClientModal open={isAddNewBusinessClientModal}
            onClose={() => showAddNewBusinessClientModal(false)}
            onSave={(result) => { setSearchValue(result) }} />
          {!isLoading && hasData && (
            <div className={classes.paginationWrapper}>
              <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
            </div>
          )}
        </div>
      </Page>
      <Toast id='bokers-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
      <Toast id='bokers-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0.125)
    }
  },
  addButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  },
  mobileItem: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(0.5)
  },
  editButton: {
    textAlign: 'right'
  },
  deleteButton: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main
  },
  searchIcon: {
    color: theme.palette.grey[500]
  },
  searchCloseIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  searchWrapper: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  }
}));
