import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
// Components
import { Box, FormLabel, Grid, Switch, FormControlLabel, FormControl, RadioGroup, Radio, Divider, Typography, FormHelperText, TextField } from '@mui/material';
import { QRCodeLink } from './QRCodeLink';
import { ColorPicker } from '../../../components';
import { MobilePreviewScreen } from './MobilePreviewScreen';
import { FileDrop, FileUploaded } from '../../../components/file';
import { useDropzone } from 'react-dropzone';
// Types
import { IBusinessClientDetail, mobileAppLogoTypeOptions } from '../../../models';
import { getBroker, storeBusinessClientAppLogo } from '../../../fetch';
import EALogo from '../../../assets/logos/ea-logo-sm-2021.png';
import { Field, FieldProps, FieldArray } from 'formik';
import clsx from 'clsx';
import { SortableLinksList } from './MobileAppSortableLinksList';
import { formLabel } from './shared/styles';

interface IMobileAppInfoProps {
  currentBusinessClient: IBusinessClientDetail | null;
  appToggle: boolean;
  formValues: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  showError: Dispatch<SetStateAction<boolean>>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  appLogo: File | undefined;
  setAppLogo: Dispatch<SetStateAction<File | undefined>>;
  handleBlur?: (e: any) => void;
  touched: any;
  errors: any;
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const MobileAppInfo: FC<IMobileAppInfoProps> = ({
  setFieldValue,
  handleBlur,
  setErrorMessage,
  showError,
  setFieldTouched,
  currentBusinessClient,
  appToggle,
  formValues,
  appLogo,
  setAppLogo,
  touched,
  errors
}) => {
  const classes = useStyles();
  const disableFields = appToggle ? false : true;
  const [businessClientLogo, setBusinessClientLogo] = useState(null);
  const [brokerLogo, setBrokerLogo] = useState(null);

  const [previewError, setPreviewError] = useState<string>('');
  const defaultLogo = EALogo;

  const fetchBroker = async (id: number) => {
    try {
      const broker = await getBroker(id);
      setBrokerLogo(broker.logoUrl);
    } catch (error) {
      console.error(error);
    }
  };

  /* eslint-disable react-hooks/rules-of-hooks */
  const imageDZUrlEdit = (acceptedFile: File) => {
    const imageUrl = URL.createObjectURL(acceptedFile);
    const img = document.createElement('img');
    img.src = imageUrl;
    img.onload = async () => {
      // icon needs a minimum height of 140px
      if (img.height < 140) {
        setFieldValue('appLogoUrl', '');
        setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
      } else {
        const create = new FormData();
        create.append('image', acceptedFile);
        try {
          const imageUrl = await storeBusinessClientAppLogo(currentBusinessClient.businessClientId, create);
          setFieldValue('appLogoUrl', imageUrl);
        } catch (error) {
          console.log(error);
          if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
            setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
          }
          showError(true);
        }
        setPreviewError('');
      }
    };
  };
  const imageDZUrlAdd = (acceptedFile: File) => {
    const imageUrl = URL.createObjectURL(acceptedFile);
    const img = document.createElement('img');
    img.src = imageUrl;
    img.onload = async () => {
      // icon needs a minimum height of 140px
      if (img.height < 140) {
        setAppLogo(null);
        setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
      } else {
        setAppLogo(acceptedFile);
        setFieldValue('appLogoUrl', imageUrl);
        setPreviewError('');
      }
    };
  };
  const imageDZUrl = useDropzone({
    accept: '.jpg, .jpeg, .png',
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > 0 && currentBusinessClient) {
        imageDZUrlEdit(acceptedFiles[0]);
      } else if (acceptedFiles.length > 0) {
        imageDZUrlAdd(acceptedFiles[0]);
      }
    }
  });

  useEffect(() => {
    if (currentBusinessClient?.broker?.brokerId) {
      fetchBroker(currentBusinessClient?.broker?.brokerId);
    }
    setBusinessClientLogo(currentBusinessClient?.logoUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppLogo = logoType => {
    switch (logoType) {
      // Adding Ternaries to catch undefined logo error
      case 'Broker':
        return brokerLogo ? brokerLogo : defaultLogo;
      case 'BusinessClient':
        return businessClientLogo ? businessClientLogo : defaultLogo;
      case 'Custom':
        return formValues.appLogoUrl ? formValues.appLogoUrl : defaultLogo;
      default:
        return defaultLogo;
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = reorder(formValues.links, oldIndex, newIndex);
    setFieldValue('links', items);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field name='mobileAppEnabled'>
                    {({ field, form }: FieldProps<IBusinessClientDetail>) => {
                      return (
                        <Grid container alignItems='center' spacing={3}>
                          <Grid item xs='auto'>
                            <FormLabel color='primary' className={classes.formLabel}>
                              Mobile App
                            </FormLabel>
                          </Grid>
                          <Grid item sm>
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}
                                  color='primary'
                                  checked={form.values.mobileAppEnabled}
                                  id='mobile-app-enabled'
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    form.setFieldValue('mobileAppEnabled', e.target.checked);
                                  }}
                                  onBlur={form.handleBlur}
                                />
                              }
                              label={form.values.mobileAppEnabled ? 'ON' : 'OFF'}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name='appLogoType' disabled={disableFields}>
                {({ field, form }: FieldProps<IBusinessClientDetail>) => {
                  return (
                    <FormControl component='fieldset' fullWidth={true} disabled={disableFields}>
                      <FormLabel component='legend' className={clsx(classes.radioGroupLabel, classes.formLabel)} color='primary'>
                        App Icon Logo
                      </FormLabel>
                      <RadioGroup
                        aria-label={`appLogoType`}
                        name={`appLogoType`}
                        value={field.value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(`appLogoType`, e.target.value);
                        }}
                      >
                        {mobileAppLogoTypeOptions.map((option, index) => {
                          return (
                            <Fragment key={index}>
                              <FormControlLabel value={option.type} control={<Radio />} label={option.title} disabled={disableFields} />
                            </Fragment>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  );
                }}
              </Field>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Field name='homeLogoType' disabled={disableFields}>
                {({ field, form }: FieldProps<IBusinessClientDetail>) => (
                  <FormControl component='fieldset' fullWidth={true} disabled={disableFields}>
                    <FormLabel component='legend' className={clsx(classes.radioGroupLabel, classes.formLabel)} color='primary'>
                      Header Logo
                    </FormLabel>
                    <RadioGroup
                      aria-label={`homeLogoType`}
                      name={`homeLogoType`}
                      value={field.value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue(`homeLogoType`, e.target.value);
                      }}
                    >
                      {mobileAppLogoTypeOptions
                        .filter(option => option.type !== 'Custom')
                        .map((option, index) => {
                          return (
                            <Fragment key={index}>
                              <FormControlLabel value={option.type} control={<Radio />} label={option.title} disabled={disableFields} />
                            </Fragment>
                          );
                        })}
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </Grid>
            {formValues.appLogoType === 'Custom' && (
              <Grid className={classes.appIconLogoPicker} order={{ xs: 1, md: 2 }} item xs={12} sm={6}>
                <Typography variant='h5' component='h5' className={classes.subHeader} color='primary'>
                  App Icon Logo
                </Typography>
                {formValues.appLogoUrl && (
                  <FileUploaded
                    className={classes.logoUploaded}
                    id='company-logo-image-preview'
                    onRemove={() => {
                      setFieldValue(`appLogoUrl`, '');
                      setFieldTouched('appLogoUrl', true);
                    }}
                    src={formValues.appLogoUrl as unknown as string}
                    backgroundColor={'#000'}
                  />
                )}
                {!formValues.appLogoUrl && (
                  <>
                    <FileDrop id='company-logo-image-drop' {...imageDZUrl} title='Add App Logo' className={classes.logoDrop}>
                      <Typography variant='body1'>
                        Drag and drop a PNG or JPG file or{' '}
                        <a href='/#' onClick={e => e.preventDefault()}>
                          browse and pick
                        </a>{' '}
                        a file.
                      </Typography>
                      <Typography variant='body2'>
                        Recommended transparent background with white logo.
                        <br />
                        Minimum height of 140 pixels.
                        <br />
                        For best results, use a square image between 180x180 pixels and 192x192 pixels.
                      </Typography>
                      {previewError && <FormHelperText error={true}>{previewError}</FormHelperText>}
                    </FileDrop>
                  </>
                )}
              </Grid>
            )}
          </Grid>
          <Grid className={classes.appIconLogoPicker} container spacing={1}></Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={1} marginBottom='2rem'>
            <Grid item xs={12}>
              <Field name='hexColor' disabled={disableFields}>
                {({ field, form }: FieldProps<IBusinessClientDetail>) => {
                  return (
                    <ColorPicker
                      color={form.values.hexColor}
                      disable={disableFields}
                      label='Header Color'
                      onChangeComplete={c => {
                        form.setFieldValue('hexColor', c.hex);
                      }}
                    />
                  );
                }}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name='secondaryHexColor' disabled={disableFields}>
                {({ field, form }: FieldProps<IBusinessClientDetail>) => {
                  return (
                    <ColorPicker
                      color={form.values.secondaryHexColor}
                      disable={disableFields}
                      label='App Buttons Color'
                      onChangeComplete={c => {
                        form.setFieldValue('secondaryHexColor', c.hex);
                      }}
                    />
                  );
                }}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name='textHexColor' disabled={disableFields}>
                {({ field, form }: FieldProps<IBusinessClientDetail>) => {
                  return (
                    <ColorPicker
                      color={form.values.textHexColor}
                      disable={disableFields}
                      label='App Text Color'
                      onChangeComplete={c => {
                        form.setFieldValue('textHexColor', c.hex);
                      }}
                    />
                  );
                }}
              </Field>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box marginBottom='2rem'>
        <Divider />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography component='h3' variant='h4' className={classes.subHeader} color='primary'>
            Custom Mobile App Header
          </Typography>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Header Text'
              name='header'
              value={formValues.header}
              onBlur={handleBlur}
              onChange={e => setFieldValue('header', e.target.value)}
              error={Boolean(touched.header && errors.header)}
              helperText={touched.header && errors.header}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: theme => theme.spacing(1) }}>
          <Typography component='h3' variant='h4' className={classes.subHeader} color='primary'>
            Custom Mobile App Icons
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
          <div className={classes.scrollable}>
            <FieldArray name='links'>
              {({ form }) => {
                return <SortableLinksList useDragHandle links={formValues.links} onSortEnd={onSortEnd} axis='y' form={form} disableFields={disableFields} />;
              }}
            </FieldArray>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
          <MobilePreviewScreen
            logo={getAppLogo(formValues.homeLogoType)}
            formValues={formValues}
            headerColor={formValues.hexColor}
            appButtonColor={formValues.secondaryHexColor}
            textColor={formValues.textHexColor}
            icons={formValues.links}
            appToggle={appToggle}
          />
        </Grid>
      </Grid>
      {formValues.qrCodes.length > 0 && (
        <>
          <Divider className={classes.divider} />
          <Typography variant='h4' component='h4' className={classes.subHeader} color='primary'>
            Mobile App URLs
          </Typography>
          <Grid container spacing={2}>
            {formValues.qrCodes.map((qrCode, index) => {
              return <QRCodeLink key={index} qrCode={qrCode} appToggle={appToggle} handleLinkChange={val => setFieldValue(`qrCodes.${index}.url`, val)} />;
            })}
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formLabel: formLabel,
  radioGroupLabel: { marginBottom: theme.spacing(0.5) },
  linkSwitchLabel: {
    marginRight: 0
  },
  linkIcon: {
    fontSize: theme.spacing(2)
  },
  linkColumnGrow: {
    minWidth: '1px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      flex: 1
    }
  },
  linkIconColumn: {
    '&&.MuiGrid-item': {
      paddingTop: theme.spacing(1.25)
    },
    [theme.breakpoints.down('lg')]: {
      '&&.MuiGrid-item': {
        paddingBottom: 0,
        marginBottom: -theme.spacing(0.25)
      }
    }
  },
  linkSwitchColumn: {
    '&&.MuiGrid-item': {
      paddingTop: theme.spacing(1)
    },
    [theme.breakpoints.down('lg')]: {
      '&&.MuiGrid-item': {
        paddingBottom: 0,
        marginBottom: -theme.spacing(0.25)
      }
    }
  },
  linkRow: {
    alignItems: 'center',
    '&&.MuiGrid-container': {
      paddingBottom: theme.spacing(2)
    }
  },
  scrollable: {
    paddingTop: theme.spacing(0.5),
    maxHeight: 650,
    paddingRight: 50,
    overflowY: 'scroll'
  },
  disableOpacity: {
    opacity: '.3'
  },
  activeIcon: {
    color: theme.palette.primary.dark
  },
  preview: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subHeader: {
    marginTop: '-10px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5)
  },
  nameTextField: {
    marginTop: theme.spacing(1)
  },
  colorPicker: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-2.5)
    }
  },
  logoDrop: {
    marginTop: theme.spacing(1),
    marginBottom: 0
  },
  logoUploaded: {
    margin: theme.spacing(1, 0, 0)
  },
  appIconLogoPicker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  dragIcon: {
    position: 'absolute',
    top: 25,
    right: 25,
    background: 'white',
    fill: theme.palette.grey[600],
    fontSize: '2rem',
    cursor: 'grab'
  }
}));
