import React from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { IBusinessClientDetailFormValues, IVisionInsurancePlan } from '../../../../../../../../../models';
import { useSharedStyles } from '../../../../../../shared/styles';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';


const VisionPlansTable: React.FC = () => {
  const classes = useSharedStyles();

  
  const { values, handleBlur, setFieldValue, errors } = useFormikContext<IBusinessClientDetailFormValues>();

  const visionInsurancePlans: IVisionInsurancePlan[] = values.visionInsurance?.visionInsurancePlans ?? [];

  const visionPlansErrors = errors.visionInsurance?.visionInsurancePlans as FormikErrors<IVisionInsurancePlan>[];


  return (
    <FieldArray name='visionInsurance.visionInsurancePlans'>
      {({ push, remove }) => (
        <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
          {visionInsurancePlans.length > 0 ?
          <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className={classes.tableCell}>Plan Name</TableCell>
                    <TableCell className={classes.tableCell}>Most Similar Previous Plan</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visionInsurancePlans.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field name={`visionInsurance.visionInsurancePlans.${index}.planName`}>
                          {({ field }) => (
                            <PerformantTextField
                              {...field}
                              fullWidth
                              variant='outlined'
                              size='small'
                              autoComplete='nope'
                              label='Plan Name'
                              onBlur={handleBlur}
                            />
                          )}
                        </Field>
                        <FieldErrorMessage message={visionPlansErrors?.[index]?.planName} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field
                          name={`visionInsurance.visionInsurancePlans.${index}.mostSimilarPreviousPlan`}
                        >
                          {({ field }) => (
                            <PerformantTextField
                              {...field}
                              fullWidth
                              variant='outlined'
                              size='small'
                              autoComplete='nope'
                              label='Most Similar Previous Plan'
                              onBlur={handleBlur}
                            />
                          )}
                        </Field>
                        <FieldErrorMessage message={visionPlansErrors?.[index]?.mostSimilarPreviousPlan} />
                      </TableCell>
                      
                      <TableCell>
                        <Button
                          startIcon={<Close />}
                          onClick={() => remove(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> </Box></Box>: <></>}

          {visionInsurancePlans.length < 3 && (
            <Button
              color='primary'
              variant='contained'
              className={classes.addClassButton}
              onClick={() =>
                push({
                  planName: '',
                  mostSimilarPreviousPlan: '',
                })
              }
            >
              Add Plan
            </Button>
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default VisionPlansTable;
