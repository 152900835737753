import { useTabPanelStyles } from "../../pages/admin/business-client-detail/BusinessClientDetailPage/TabsForms/tabs-forms-contents/styles";
import { useSharedStyles } from "../../pages/admin/business-client-detail/shared/styles";

import { PropsWithChildren } from 'react'
import OfferedNotOfferedBenefitSummary from "../offered-not-offered-benefit-summary";
import { OfferedNotOfferedBenefit } from "../../models";

interface IBusinessClientTabContentBaseProps {
    benefitsList: OfferedNotOfferedBenefit[]
}

export default function BusinessClientTabContentBase({ benefitsList, children }: PropsWithChildren<IBusinessClientTabContentBaseProps>) {

    const classes = useSharedStyles();

    const internalClasses = useTabPanelStyles();

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={`${classes.tabContent} ${internalClasses.tabsPannelContainer}`}>

            <div className={internalClasses.summaryContainer}>
                <OfferedNotOfferedBenefitSummary benefitsList={benefitsList} />
            </div>

            <div className={internalClasses.accordionContainer}>
                {children}
            </div>
        </div>
    );
}