import { FC } from 'react';
import { Fade } from '@mui/material';
import { Modal } from '../../../../../../../../../../components';

interface IFileManagementModal {
    open: boolean;
    handleClose: () => void;
    title: string;

    children: React.ReactNode;
}

export const FileManagementModal: FC<IFileManagementModal> = ({ open, children, handleClose, title }) => {
    return (
        <Modal
            open={open}
            title={title}
            showClose={false}
            onClose={(e, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleClose();
                }
            }}
            maxWidth='xs'
        >
            <Fade in={open}>
                <div>
                    {children}
                </div>
            </Fade>
        </Modal>
    );
};
