import { Button as MuiButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonProps } from '@mui/material/Button';
import { Link } from 'react-router-dom';
import React, { forwardRef } from 'react';
// components
import { Loader, ILoaderProps } from '../loader';

export interface IButtonProps extends ButtonProps {
  id: string;
  loading?: JSX.Element | string;
  LoaderProps?: ILoaderProps;
  target?: string;
  rel?: string;
  asLink?: boolean;
  to?: string;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const classes = buttonStyles();

  const { children, color, id, loading, LoaderProps, asLink, to, ...rest } = props;

  return (
    <MuiButton
      {...rest}
      data-cy={id}
      color={color}
      data-testid={id}
      disabled={loading || props.disabled ? true : false}
      id={id}
      ref={ref}
      startIcon={loading ? null : props.startIcon}
      component={asLink ? Link : undefined}
      to={to}
    >
      {loading ? (
        <Loader className={classes.loader} position='centered' {...LoaderProps}>
          {loading}
        </Loader>
      ) : (
        children
      )}
    </MuiButton>
  );
});

Button.displayName = 'Button';

const buttonStyles = makeStyles(() => {
  return {
    loader: {
      color: 'inherit'
    }
  };
});
