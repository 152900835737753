import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material';
import { timelineLabelBreakpoint, timelineResponsiveBreakpoint } from './Timeline';

export interface ITimelineEvent {
  content?: React.ReactNode[];
  date: string;
}

interface ITimelineEventProps {
  event?: ITimelineEvent;
}

export const TimelineEvent: FC<ITimelineEventProps> = ({ event }) => {
  const classes = useStyles();
  return (
    <div className={classes.timelineEvent}>
      <div className={classes.timelineEventBar}>
        <div className={classes.timelineMarker}></div>
        <div className={classes.timelineLine}></div>
      </div>
      <div className={classes.timelineEventContent}>
        {event.content &&
          event.content.map((item, index) => {
            return (
              <div key={`${index}`} className={classes.timelineEventContentInner}>
                {item}
              </div>
            );
          })}
      </div>

      <div className={classes.timelineEventDate}>{event.date}</div>
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  timelineEvent: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    flexFlow: 'column wrap',

    '.timeline--responsive &&': {
      justifyContent: 'start',
      alignContent: 'flex-start',
      width: '100%',

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column wrap',
        flex: '0 0 auto',
        textAlign: 'center',
        width: '90px'
      },
      [theme.breakpoints.up(timelineLabelBreakpoint)]: {
        width: '140px'
      },
      '&&:first-child': {
        [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
          flex: '0 0 auto'
        }
      }
    }
  },
  timelineEventBar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '.timeline--responsive &&': {
      height: '100%',
      order: 0,
      width: theme.spacing(2),
      margin: theme.spacing(0, 0.25),

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        width: '100%',
        height: 'auto',
        order: 1,
        margin: theme.spacing(0.35, 0)
      }
    }
  },
  timelineMarker: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    border: `${theme.spacing(0.5)} solid ${theme.palette.common.white}`,
    zIndex: 1,

    '.timeline--responsive &&': {}
  },
  timelineLine: {
    position: 'absolute',
    zIndex: 0,

    '.timeline--responsive &&': {
      borderLeft: `1px solid ${grey[400]}`,
      height: '100%',
      width: '0',
      top: '0',
      left: '50%',

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        borderTop: `1px solid ${grey[400]}`,
        borderLeft: 'none',
        height: 0,
        width: '100%',
        top: '50%',
        left: 0,
        right: 0
      }
    }
  },
  timelineEventContent: {
    '.timeline--responsive &&': {
      order: 2,
      position: 'relative',
      marginTop: theme.spacing(0.25),

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        order: 0,
        marginTop: 0,
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center'
      },
      [theme.breakpoints.up(timelineLabelBreakpoint)]: {
        padding: theme.spacing(0.75),
        border: `1px solid ${grey[400]}`
      }
    },
    '.timeline--responsive &&::before, .timeline--responsive &&::after': {
      [theme.breakpoints.up(timelineLabelBreakpoint)]: {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '100%',
        right: 'auto',
        bottom: 'auto',
        left: '50%',
        borderLeft: `10px solid transparent`,
        borderRight: `10px solid transparent`,
        borderBottom: 0,
        width: 0,
        height: 0,
        transform: 'translate(-50%, 0)'
      }
    },
    '.timeline--responsive &&::before': {
      [theme.breakpoints.up(timelineLabelBreakpoint)]: {
        borderTop: `8px solid ${grey[400]}`,
        zIndex: 2,
        marginTop: '1px'
      }
    },
    '.timeline--responsive &&::after': {
      [theme.breakpoints.up(timelineLabelBreakpoint)]: {
        borderTop: `8px solid ${theme.palette.common.white}`,
        zIndex: 3
      }
    }
  },
  timelineEventContentInner: {
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
      marginLeft: theme.spacing(0.25),
      paddingTop: 0
    },
    [theme.breakpoints.up(timelineLabelBreakpoint)]: {
      borderTop: `1px solid ${grey[400]}`,
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      marginLeft: 0
    },
    '&&:first-child': {
      borderTop: 'none',
      margin: 0,
      padding: 0
    }
  },
  timelineEventDate: {
    '.timeline--responsive &&': {
      paddingTop: theme.spacing(0.35),
      order: 1,

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        paddingTop: 0,
        order: 2
      }
    }
  }
}));
