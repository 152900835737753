import React from 'react'
import { FormControl, Grid, TextField, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';

import { DatePicker } from '../../../../../../../../components';
import { formatShortFriendlyDateWithTime } from '../../../../../../../../helpers';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { useSharedStyles } from '../../../../../shared/styles';


export default function Dates() {

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('dates');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth variant='outlined'>
                        <DatePicker
                            label='Effective Date'
                            value={values.openEnrollmentEffectiveDate ? new Date(values.openEnrollmentEffectiveDate) : null}
                            renderInput={props => (
                                <TextField {...props} size='small' label='Effective Date' value={values.openEnrollmentEffectiveDate ? new Date(values.openEnrollmentEffectiveDate) : null} />
                            )}
                            onChange={(date: Date) => {
                                setFieldValue(`openEnrollmentEffectiveDate`, date?.toISOString?.() ? date?.toISOString() : '');
                            }}
                        />
                    </FormControl>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required variant='outlined'>
                        <DatePicker
                            label='Enrollment Start'
                            value={values.enrollmentStart ? new Date(values.enrollmentStart) : null}
                            maxDate={values.enrollmentEnd ? new Date(values.enrollmentEnd) : null}
                            renderInput={props => (
                                <TextField
                                    {...props}
                                    error={Boolean(touched && errors?.enrollmentStart)}
                                    required
                                    size='small'
                                    label='Enrollment Start'
                                    value={values.enrollmentStart ? new Date(values.enrollmentStart) : null}
                                />
                            )}
                            onChange={(date: Date) => {
                                setFieldValue(`enrollmentStart`, date?.toISOString?.() ? date?.toISOString() : '');
                            }}
                        />
                        <FormHelperText error={Boolean(touched && errors?.enrollmentStart)}>{touched && errors?.enrollmentStart}</FormHelperText>
                    </FormControl>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required variant='outlined'>
                        <DatePicker
                            label='Enrollment End'
                            minDate={values.enrollmentStart ? new Date(values.enrollmentStart) : null}
                            value={values.enrollmentEnd ? new Date(values.enrollmentEnd) : null}
                            renderInput={props => (
                                <TextField
                                    {...props}
                                    error={Boolean(touched && errors?.enrollmentEnd)}
                                    required
                                    size='small'
                                    label='Enrollment End'
                                    value={values.enrollmentEnd ? new Date(values.enrollmentEnd) : null}
                                />
                            )}
                            onChange={(date: Date) => {
                                setFieldValue(`enrollmentEnd`, date?.toISOString?.() ? date?.toISOString() : '');
                            }}
                        />
                    </FormControl>
                    <FormHelperText error={Boolean(touched && errors?.enrollmentEnd)}>{touched && errors?.enrollmentEnd}</FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        size='small'
                        autoComplete='nope'
                        label='Target Date'
                        name='name'
                        InputProps={{
                            readOnly: true
                        }}
                        value={values.targetDate ? formatShortFriendlyDateWithTime(values.targetDate) : ''}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('targetDate', e.target.value)}
                    />
                </Grid>
            </Grid>

        </div>
    )
}