import React from 'react';
import { Grid, TextField, Typography, Button, IconButton, useMediaQuery } from '@mui/material';


import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import { useSharedStyles } from '../../../../../../shared/styles';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Close } from '@mui/icons-material';

const Locations: React.FC = () => {
    const classes = useSharedStyles();

    const isMobile = useMediaQuery('(max-width: 960px)');

    const { handleBlur, values, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data = values.eligibilityRules;

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        autoComplete='nope'
                        label='New Hire Schedule Link'
                        name='newHireScheduleLink'
                        placeholder='www.example.com'
                        value={values.newHireScheduleUrl}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('newHireScheduleUrl', e.target.value)}
                        error={Boolean(touched.newHireScheduleUrl && errors.newHireScheduleUrl)}
                        helperText={touched.newHireScheduleUrl && errors.newHireScheduleUrl}
                    />
                </Grid>
            </Grid>
            <FieldArray name='location'>
                {({ push, remove }) => {
                    return (
                        <Grid container spacing={1}>
                            {values.location.length > 0 &&
                                values.location.map((clientLocation, index) => {
                                    return (
                                        <Grid item xs={12} lg={6} key={`${index}`}>
                                            <Typography variant='h5' component='h5' className={classes.classText}>
                                                Location {index + 1}
                                            </Typography>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item xs={12} sm={6} md={5} lg={5}>
                                                    <Field name={`location.${index}.name`}>
                                                        {({ field }) => {
                                                            return (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Location Name'
                                                                    name={`location.${index}.name`}
                                                                    value={values.location[index].name}
                                                                    onBlur={handleBlur}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} lg={5}>
                                                    <Field name={`location.${index}.calendarLinkUrl`}>
                                                        {({ field }) => {
                                                            return (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    autoComplete='nope'
                                                                    size='small'
                                                                    label='Calendar URL'
                                                                    name={`location.${index}.calendarLinkUrl`}
                                                                    value={values.location[index].calendarLinkUrl || ''}
                                                                    onBlur={handleBlur}
                                                                    placeholder='www.example.com'
                                                                    error={
                                                                        errors &&
                                                                            errors.location &&
                                                                            errors.location[index] &&
                                                                            // @ts-ignore
                                                                            errors.location[index].calendarLinkUrl
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    // @ts-ignore
                                                                    helperText={
                                                                        errors &&
                                                                        errors.location &&
                                                                        errors.location[index] &&
                                                                        // @ts-ignore
                                                                        errors.location[index].calendarLinkUrl
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12} md={1} lg={2} className={classes.removeButtonWrapper}>
                                                    {isMobile ? (
                                                        <Button
                                                            className={classes.removeButton}
                                                            startIcon={<Close />}
                                                            onClick={() => {
                                                                remove(index);
                                                                const newQrCodes = [...values.qrCodes];
                                                                setFieldValue(
                                                                    'qrCodes',
                                                                    newQrCodes.filter(code => code.businessClientLocationId !== clientLocation.businessClientLocationId)
                                                                );
                                                            }}
                                                        >
                                                            Remove Location
                                                        </Button>
                                                    ) : (
                                                        <IconButton
                                                            color='default'
                                                            className={classes.removeButton}
                                                            onClick={() => {
                                                                remove(index);
                                                                const newQrCodes = [...values.qrCodes];
                                                                setFieldValue(
                                                                    'qrCodes',
                                                                    newQrCodes.filter(code => code.businessClientLocationId !== clientLocation.businessClientLocationId)
                                                                );
                                                            }}
                                                            size='large'
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    onClick={() =>
                                        push({
                                            name: '',
                                            calendarLinkUrl: '',
                                            supervisorName: ''
                                        })
                                    }
                                >
                                    Add Location
                                </Button>
                            </Grid>
                        </Grid>
                    );
                }}
            </FieldArray>
        </>
    );
};

export default Locations;
