import React from 'react';
import { Field, FieldArray } from 'formik';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Checkbox,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { IDentalInsurancePlan } from '../../../models';
import { useSharedStyles } from './shared/styles';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';

interface IDentalInsurancePlansProps {
    dentalInsurancePlans: IDentalInsurancePlan[];
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
}

const DentalInsurancePlansTable: React.FC<IDentalInsurancePlansProps> = ({
    dentalInsurancePlans,
    handleBlur,
    setFieldValue,
    errors
}) => {
    const classes = useSharedStyles();
    return (
        <FieldArray name='dentalInsurance.dentalInsurancePlans'>
            {({ push, remove }) => (
                <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
                    {dentalInsurancePlans.length > 0 ? <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}>Plan Name</TableCell>
                                    <TableCell className={classes.tableCell}>PCP Required</TableCell>
                                    <TableCell className={classes.tableCell}>Most Similar Previous Plan</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dentalInsurancePlans.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Field name={`dentalInsurance.dentalInsurancePlans.${index}.planName`}>
                                                {({ field }) => (
                                                    <PerformantTextField
                                                        {...field}
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        autoComplete='nope'
                                                        label='Plan Name'
                                                        onBlur={handleBlur}
                                                    />
                                                )}
                                            </Field>
                                            <FieldErrorMessage message={errors?.dentalInsurance?.dentalInsurancePlans?.[index]?.planName} />
                                        </TableCell>
                                        <TableCell align='center'>

                                            <Checkbox
                                                color="primary"
                                                onBlur={handleBlur}
                                                checked={item.pcpRequired}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                name={`dentalInsurance.dentalInsurancePlans.${index}.pcpRequired`}
                                                onChange={(_, checked: boolean) =>
                                                    setFieldValue(`dentalInsurance.dentalInsurancePlans.${index}.pcpRequired`, checked)}
                                            />

                                            <FieldErrorMessage message={errors?.dentalInsurance?.dentalInsurancePlans?.[index]?.pcpRequired} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Field
                                                name={`dentalInsurance.dentalInsurancePlans.${index}.mostSimilarPreviousPlan`}
                                            >
                                                {({ field }) => (
                                                    <PerformantTextField
                                                        {...field}
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        autoComplete='nope'
                                                        label='Most Similar Previous Plan'
                                                        onBlur={handleBlur}
                                                    />
                                                )}
                                            </Field>
                                            <FieldErrorMessage message={errors?.dentalInsurance?.dentalInsurancePlans?.[index]?.mostSimilarPreviousPlan} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Button
                                                startIcon={<Close />}
                                                onClick={() => remove(index)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> : <></>}


                    {dentalInsurancePlans.length < 3 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    planName: '',
                                    pcpRequired: false,
                                    mostSimilarPreviousPlan: '',
                                    dentalNotes: '',
                                })
                            }
                        >
                            Add Plan
                        </Button>
                    )}
                </div>
            )}
        </FieldArray>
    );
};

export default DentalInsurancePlansTable;