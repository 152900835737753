import React from 'react';
import { FC } from 'react';
import { Field, FieldArray } from 'formik';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IPortableLifeInsuranceGuaranteedIssue } from '../../../models'; // Replace this with the actual model interface
import { useSharedStyles } from './shared/styles';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface IPortableLifeCoverageGuaranteedIssuesProps {
    portableLifeInsuranceGuaranteedIssues: IPortableLifeInsuranceGuaranteedIssue[];
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
}

const PortableLifeCoverageGuaranteedIssuesTable: FC<IPortableLifeCoverageGuaranteedIssuesProps> = ({ portableLifeInsuranceGuaranteedIssues, handleBlur, errors, setFieldValue }) => {
    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    return (
        <FieldArray name='portableLifeInsurance.portableLifeInsuranceGuaranteedIssues'>
            {({ push, remove }) => {
                return (
                    <div className={classes.container}>
                        <TableContainer component={Paper}>
                            {portableLifeInsuranceGuaranteedIssues.length > 0 &&
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}></TableCell>
                                            <TableCell className={classes.tableCell}>Guaranteed Issue Amount</TableCell>
                                            <TableCell className={classes.tableCell}>Max Amount</TableCell>
                                            <TableCell className={classes.tableCell}>Max Factor</TableCell>
                                            <TableCell className={classes.tableCell}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {portableLifeInsuranceGuaranteedIssues.length > 0 &&
                                            portableLifeInsuranceGuaranteedIssues.map((item, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Field name={`portableLifeInsurance.portableLifeInsuranceGuaranteedIssues.${index}.giAmount`}>
                                                                {({ field }) => (
                                                                    <PerformantTextField
                                                                        {...field}
                                                                        fullWidth
                                                                        variant='outlined'
                                                                        size='small'
                                                                        autoComplete='nope'
                                                                        label='Guaranteed Issue Amount'
                                                                        value={item.giAmount}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FieldErrorMessage message={errors.portableLifeInsurance?.portableLifeInsuranceGuaranteedIssues?.[index]?.giAmount} />
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            <GenericDropdown
                                                                handleBlur={handleBlur}
                                                                options={generalDropdownsOptions?.criticalIllnessMaxAmountOptions ?? []}
                                                                isLoading={generalDropdownsOptionsLoading}
                                                                setFieldValue={setFieldValue}
                                                                value={item?.maxAmount ?? ''}
                                                                error={errors.portableLifeInsurance?.portableLifeInsuranceGuaranteedIssues?.[index]?.maxAmount}
                                                                label='Max Amount'
                                                                id={`portableLifeInsurance.portableLifeInsuranceGuaranteedIssues.${index}.maxAmount`}
                                                            />
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            <GenericDropdown
                                                                handleBlur={handleBlur}
                                                                options={generalDropdownsOptions?.criticalIllnessMaxFactorOptions ?? []}
                                                                isLoading={generalDropdownsOptionsLoading}
                                                                setFieldValue={setFieldValue}
                                                                value={item?.maxFactor ?? ''}
                                                                error={errors.portableLifeInsurance?.portableLifeInsuranceGuaranteedIssues?.[index]?.maxFactor}
                                                                label='Max Factor'
                                                                id={`portableLifeInsurance.portableLifeInsuranceGuaranteedIssues.${index}.maxFactor`}
                                                            />
                                                        </TableCell>

                                                        <TableCell className={classes.tableCell}>
                                                            <Button
                                                                className={classes.removeButton}
                                                                startIcon={<Close />}
                                                                onClick={() => {
                                                                    remove(index);
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>}
                        </TableContainer>

                        {portableLifeInsuranceGuaranteedIssues.length < 3 && (
                            <Button
                                color='primary'
                                variant='contained'
                                className={classes.addClassButton}
                                onClick={() =>
                                    push({
                                        giAmount: undefined,
                                    })
                                }
                            >
                                Add Guaranteed Issue
                            </Button>
                        )}
                    </div>
                );
            }}
        </FieldArray>
    );
};

export default PortableLifeCoverageGuaranteedIssuesTable;