import * as React from 'react';
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import { HRPreferencesSubMenusTypes } from '../../../MenuData/types';

import { DefaultTabBase } from '../shared';
import { HRPreferencesTabs } from './tabs-header';
import CampaignStrategy from './CampaignStrategy/CampaignStrategy';

const componentDictionary: Record<HRPreferencesSubMenusTypes, JSX.Element> = {
    'campaign_strategy': <DefaultTabBase>
        <CampaignStrategy />
    </DefaultTabBase>

};

export default function HRPreferences() {

    const { selectedSubMenu } = useLateralFormMenuControl();
    const typedSelectedSubMenu = selectedSubMenu as HRPreferencesSubMenusTypes

    return (
        <div>
            <HRPreferencesTabs validateForm={() => { }} />

            <div>
                {componentDictionary[typedSelectedSubMenu]}
            </div>
        </div>
    );
}
