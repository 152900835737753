import React from 'react';
import { Field, FieldArray } from 'formik';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { IVisionInsurancePlan } from '../../../models';
import { useSharedStyles } from './shared/styles';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';



interface IVisionInsurancePlansProps {
  visionInsurancePlans: IVisionInsurancePlan[];
  handleBlur: any;
  setFieldValue: (field: string, value: any) => void;
  values: any;
  errors: any;
}

const VisionInsurancePlansTable: React.FC<IVisionInsurancePlansProps> = ({
  visionInsurancePlans,
  handleBlur,
  errors
}) => {
  const classes = useSharedStyles();
  return (
    <FieldArray name='visionInsurance.visionInsurancePlans'>
      {({ push, remove }) => (
        <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
          {visionInsurancePlans.length > 0 ?
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}></TableCell>
                    <TableCell className={classes.tableCell}>Plan Name</TableCell>
                    <TableCell className={classes.tableCell}>Most Similar Previous Plan</TableCell>
                    <TableCell className={classes.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visionInsurancePlans.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field name={`visionInsurance.visionInsurancePlans.${index}.planName`}>
                          {({ field }) => (
                            <PerformantTextField
                              {...field}
                              fullWidth
                              variant='outlined'
                              size='small'
                              autoComplete='nope'
                              label='Plan Name'
                              onBlur={handleBlur}
                            />
                          )}
                        </Field>
                        <FieldErrorMessage message={errors?.visionInsurance?.visionInsurancePlans?.[index]?.planName} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field
                          name={`visionInsurance.visionInsurancePlans.${index}.mostSimilarPreviousPlan`}
                        >
                          {({ field }) => (
                            <PerformantTextField
                              {...field}
                              fullWidth
                              variant='outlined'
                              size='small'
                              autoComplete='nope'
                              label='Most Similar Previous Plan'
                              onBlur={handleBlur}
                            />
                          )}
                        </Field>
                        <FieldErrorMessage message={errors?.visionInsurance?.visionInsurancePlans?.[index]?.mostSimilarPreviousPlan} />
                      </TableCell>
                      
                      <TableCell className={classes.tableCell}>
                        <Button
                          startIcon={<Close />}
                          onClick={() => remove(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> : <></>}

          {visionInsurancePlans.length < 3 && (
            <Button
              color='primary'
              variant='contained'
              className={classes.addClassButton}
              onClick={() =>
                push({
                  planName: '',
                  mostSimilarPreviousPlan: '',
                })
              }
            >
              Add Plan
            </Button>
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default VisionInsurancePlansTable;
