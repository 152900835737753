import React from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { IBusinessClientDetailFormValues, IDentalInsurancePlan } from '../../../../../../../../../models';
import { useSharedStyles } from '../../../../../../shared/styles';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';


const DentalPlansTable: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const dentalInsurancePlans: IDentalInsurancePlan[] = values.dentalInsurance?.dentalInsurancePlans ?? [];

    const dentalPlansErrors = errors.dentalInsurance?.dentalInsurancePlans as FormikErrors<IDentalInsurancePlan>[];


    return (
        <FieldArray name='dentalInsurance.dentalInsurancePlans'>
            {({ push, remove }) => (
                <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
                    {dentalInsurancePlans.length > 0 ?
                        <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.tableCell}>Plan Name</TableCell>
                                                <TableCell className={classes.tableCell}>PCP Required</TableCell>
                                                <TableCell className={classes.tableCell}>Most Similar Previous Plan</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dentalInsurancePlans.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Field name={`dentalInsurance.dentalInsurancePlans.${index}.planName`}>
                                                            {({ field }) => (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Plan Name'
                                                                    onBlur={handleBlur}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FieldErrorMessage message={dentalPlansErrors?.[index]?.planName} />
                                                    </TableCell>
                                                    <TableCell align='center'>

                                                        <Checkbox
                                                            color="primary"
                                                            onBlur={handleBlur}
                                                            checked={item.pcpRequired}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            name={`dentalInsurance.dentalInsurancePlans.${index}.pcpRequired`}
                                                            onChange={(_, checked: boolean) =>
                                                                setFieldValue(`dentalInsurance.dentalInsurancePlans.${index}.pcpRequired`, checked)}
                                                        />

                                                        <FieldErrorMessage message={dentalPlansErrors?.[index]?.pcpRequired} />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Field
                                                            name={`dentalInsurance.dentalInsurancePlans.${index}.mostSimilarPreviousPlan`}
                                                        >
                                                            {({ field }) => (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Most Similar Previous Plan'
                                                                    onBlur={handleBlur}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FieldErrorMessage message={dentalPlansErrors?.[index]?.mostSimilarPreviousPlan} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            startIcon={<Close />}
                                                            onClick={() => remove(index)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer></Box></Box> : <></>}


                    {dentalInsurancePlans.length < 3 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    planName: '',
                                    pcpRequired: false,
                                    mostSimilarPreviousPlan: '',
                                    dentalNotes: '',
                                })
                            }
                        >
                            Add Plan
                        </Button>
                    )}
                </div>
            )}
        </FieldArray>
    );
};

export default DentalPlansTable;