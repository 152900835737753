import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { EmployeeDetailCard } from '../../../../pages/employees/EmployeeDetailCard';
import { Email, MailOutline } from '@mui/icons-material';
import { Loader } from '../../../loader';
import { Button, Popover, Typography } from '@mui/material';
import { Pagination, Table } from '../../../table';
import { ICommunication, IEmployeeDetailedCommunicationTimeline, IEmployeeEmail } from '../../../../models';
import { getEmployeeDetailedCommunicationTimeline, getEmployeeEmail } from '../../../../fetch';
import { ClassNameMap } from '@mui/styles/withStyles';
import { formatDate } from '../../../../helpers';

interface Props {
    businessClientId: string;
    employeeId: number;
    classes: ClassNameMap<any>
}

const DetailedCommunicationTimeline: React.FC<Props> = ({ businessClientId, employeeId, classes }) => {
    const [isDCTLoading, setDCTLoading] = useState<boolean>(true);
    const [isDCTExpanded, setIsDCTExpanded] = useState(false);
    const [employeeDCT, setEmployeeDCT] = useState<IEmployeeDetailedCommunicationTimeline | null>(null);

    // DCT Pagination
    const [page, setPage] = useState<number>(0);
    const [perPage, setRowsPerPage] = useState<number>(5);
    const [recordCount, setRecordCount] = useState<number>(0);
    const [firstDCTUpdate, setFirstDCTUpdate] = useState<boolean>(true);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [popoverNote, setPopoverNote] = useState<string | null>(null);
    const [email, setEmail] = useState<IEmployeeEmail | null>(null);
    const [isEmail, setIsEmail] = useState<boolean>(false);


    const fetchEmployeeDetailedCommunicationTimeline = async (filters = {}) => {
        setEmployeeDCT(null);
        setDCTLoading(true);
        try {
            const res = await getEmployeeDetailedCommunicationTimeline(businessClientId, employeeId, {
                page: page + 1,
                perPage
            });
            setEmployeeDCT(res);
            setRecordCount(res.totalRecordCount);
        } catch (error) {
            console.error(error);
        } finally {
            setDCTLoading(false);
        }
    };

    const toggleEmployeeDetailedCommunicationTimelineAccordion = async isExpanded => {
        setIsDCTExpanded(isExpanded);
        if (isExpanded) {
            setEmployeeDCT(null);
            fetchEmployeeDetailedCommunicationTimeline();
        }
    };

    const fetchEmployeeEmail = async emailId => {
        try {
            const res = await getEmployeeEmail(businessClientId, employeeId, emailId);
            setEmail(res);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, record: ICommunication) => {
        if (record.type === 'Email') {
            setIsEmail(true);
            setPopoverNote(record.note || 'No title');
            fetchEmployeeEmail(record.id);
        }
        setPopoverNote(record.note || 'No record provided.');
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setEmail(null);
        setIsEmail(false);
    };
    const open = Boolean(anchorEl);
    const popoverId = open ? 'simple-popover' : undefined;


    const columns = useMemo(() => {
        return [
            {
                Header: 'Type',
                accessor: 'type',
                overrideWidth: 400,
                Cell: ({
                    cell: {
                        row: { original }
                    }
                }: {
                    cell: { row: { original: ICommunication } };
                }) => {
                    return (
                        <div className={classes.row}>
                            <MailOutline />
                            <Typography className={classes.rowText} variant='body2' color='textPrimary'>
                                {original.type}
                            </Typography>
                        </div>
                    );
                }
            },
            {
                Header: 'Date',
                accessor: 'date',
                overrideWidth: 400,
                Cell: ({
                    cell: {
                        row: { original }
                    }
                }: {
                    cell: { row: { original: ICommunication } };
                }) => {
                    return (
                        <div className={classes.row}>
                            <Typography variant='body2' color='textPrimary'>
                                {formatDate(original.date)}
                            </Typography>
                        </div>
                    );
                }
            },
            {
                Header: 'Sent To',
                accessor: 'sentTo',
                overrideWidth: 400
            },
            {
                Header: 'View Communications Details',
                accessor: 'enrollmentMeetingOutcome',
                overrideWidth: 400,
                // className: classes.viewDetailsColumn,
                Cell: ({
                    cell: {
                        row: { original }
                    }
                }: {
                    cell: { row: { original: ICommunication } };
                }) => {
                    return (
                        <div>
                            <Typography variant='body2' color='textPrimary'>
                                <Button color='primary' className={classes.caseSensitive} onClick={event => handlePopoverOpen(event, original)}>
                                    View Details
                                </Button>
                            </Typography>
                        </div>
                    );
                }
            }
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        if (firstDCTUpdate) {
            setFirstDCTUpdate(false);
            return;
        }
        fetchEmployeeDetailedCommunicationTimeline();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, perPage]);

    useEffect(() => {
        setAnchorEl(null);
        setEmail(null);
        setIsEmail(false);
    }, []);

    return (
        <EmployeeDetailCard
            title='Detailed Communication Timeline'
            expanded={isDCTExpanded}
            onChange={() => toggleEmployeeDetailedCommunicationTimelineAccordion(!isDCTExpanded)}
            icon={<Email />}
        >
            {isDCTLoading && <Loader position={'centered'} type={'inline'} />}
            {!isDCTLoading && (!employeeDCT || (employeeDCT.records && employeeDCT.records.length === 0)) && (
                <>
                    <Typography align='center'>No Detailed Communication Timeline is found</Typography>
                </>
            )}
            {employeeDCT && employeeDCT.records && employeeDCT.records.length > 0 && (
                <>
                    <div>
                        <Table
                            rowClasses={classes.tableRow}
                            columns={columns}
                            tableSize={'small'}
                            data={employeeDCT.records}
                            isLoading={isDCTLoading}
                            hidePagination={true}
                            hover={true}
                            rowOnClick={(e, val) => handlePopoverOpen(e, val)}
                        />
                        {anchorEl && (
                            <>
                                <Popover
                                    id={popoverId}
                                    className={classes.popover}
                                    classes={{
                                        paper: classes.paper
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    onClose={() => handlePopoverClose()}
                                >
                                    {/* Populate popover content based on the selected record */}
                                    {!isEmail ? (
                                        <Typography className={classes.title}>{popoverNote}</Typography>
                                    ) : (
                                        <>
                                            <Typography className={classes.title} paragraph>
                                                {popoverNote}
                                            </Typography>
                                            <div className={classes.emailBody}>
                                                {email ? (
                                                    <Typography variant='body2' color='textPrimary' dangerouslySetInnerHTML={{ __html: email.htmlContent }} />
                                                ) : (
                                                    <Loader position='centered' />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Popover>
                            </>
                        )}
                    </div>
                    <div className={classes.paginationWrapper}>
                        <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
                    </div>
                </>
            )}
        </EmployeeDetailCard>
    );
};

export default DetailedCommunicationTimeline;
