import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
// Pages
import {
  Home,
  ManageUsers,
  Login,
  ManageBrokers,
  ManageBusinessClients,
  ForgotPassword,
  ResetPassword,
  SetupPassword,
  Employees,
  NotFound,
  Builder,
  BusinessClientDetailPageOriginal
} from './pages';
import { EmployeeDetail } from './pages/employees/EmployeeDetail';
// context
import { UserContextHandler, ManageBusinessClientCtxHandler } from './context';
import { PrivateRoute, AdminRoute } from './components';
import { withTracker } from './services/ga';
import { TimeZoneContextHandler } from './context/timezone';
import { ErrorMessageProvider } from './context/ErrorMessageCtx';
import DropDownCollectionsProvider from './context/DropDownCollectionsCtx';
import { BusinessClientDetailPage } from './pages/admin/business-client-detail/BusinessClientDetailPage/BusinessClientDetailPage';
import { SuccessMessageProvider } from './context/SuccessMessageCtx';
const { REACT_APP_GA_TRACKING } = require('./buildSettings.json');

declare global {
  interface Window {
    ga: any | undefined;
  }
}

export default function App() {
  const initializeGoogleAnalytics = () => {
    if (!window.ga && REACT_APP_GA_TRACKING) {
      ReactGA.initialize(REACT_APP_GA_TRACKING, {
        gtagOptions: {
          titleCase: false
        }
      });
    }
  };

  useEffect(() => {
    initializeGoogleAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Router>
      <TimeZoneContextHandler>
        <UserContextHandler>
          <Switch>
            <PrivateRoute exact path='/' component={withTracker(Home)} />
            <Route exact path='/login' component={withTracker(Login)} />
            <Route exact path='/forgot-password' component={withTracker(ForgotPassword)} />
            <Route path='/reset-password' component={withTracker(ResetPassword)} />
            <Route exact path='/setup-password' component={withTracker(SetupPassword)} />
            <PrivateRoute exact path='/business-clients/:businessClientId/employees' component={withTracker(Employees)} />
            <PrivateRoute exact path='/business-clients/:businessClientId/employees/:id' component={withTracker(EmployeeDetail)} />
            <PrivateRoute exact path='/business-clients/:businessClientId/builder' component={withTracker(Builder)} />
            <AdminRoute exact path='/manage-users' component={withTracker(ManageUsers)} />

            <AdminRoute
              exact
              path='/manage-business-clients'
              render={props => {
                return (
                  <ManageBusinessClientCtxHandler>
                    <ManageBusinessClients {...props} />

                    {/* <DropDownCollectionsProvider>
                      <BusinessClientDetailPage />
                    </DropDownCollectionsProvider> */}


                  </ManageBusinessClientCtxHandler>
                );
              }}
            />
            <AdminRoute
              exact
              path='/manage-business-clients/:id'
              render={props => {
                return (
                  <ManageBusinessClientCtxHandler>
                    <ErrorMessageProvider>
                      <SuccessMessageProvider>
                        <DropDownCollectionsProvider>
                          <BusinessClientDetailPage {...props} />
                        </DropDownCollectionsProvider>
                      </SuccessMessageProvider>
                    </ErrorMessageProvider>
                  </ManageBusinessClientCtxHandler>
                );
              }}
            />

            <AdminRoute
              exact
              path='/manage-business-clients/:id/original'
              render={props => {
                return (
                  <ManageBusinessClientCtxHandler>
                    <ErrorMessageProvider>
                      <DropDownCollectionsProvider>
                        <BusinessClientDetailPageOriginal {...props} />
                      </DropDownCollectionsProvider>
                    </ErrorMessageProvider>
                  </ManageBusinessClientCtxHandler>
                );
              }}
            />
            <AdminRoute exact path='/manage-brokers' component={withTracker(ManageBrokers)} />
            <Route component={withTracker(NotFound)} />
          </Switch>
        </UserContextHandler>
      </TimeZoneContextHandler>
    </Router>
  );
}
