
import { useSharedStyles } from '../../../../../shared/styles';

import { Accordion } from '../../../../../../../../components';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import EnrollmentDates from './EnrollmentDates/EnrollmentDates';
import HRDates from './HRDates/HRDates';
import EnrollmentPreferencesAccordion from './EnrollmentPreferencesAccordion/EnrollmentPreferencesAccordion';
import ScheduleManagement from './ScheduleManagement/ScheduleManagement';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';

export default function Scheduling() {

    const classes = useSharedStyles();

    const { errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const validateAction = menusSubMenusValidationStatusMap.get('scheduling');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;


    return (
        <div role='tabpanel' id='enrollment-preferences-tab-content' aria-labelledby='enrollment-preferences-tab-content' className={classes.tabContent}>

            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />

            <Accordion defaultExpanded={true} title='Enrollment Dates' component='h3' variant='h4'>
                <EnrollmentDates />
            </Accordion>

            <Accordion title='HR Dates' component='h3' variant='h4'>
                <HRDates />
            </Accordion>

            <Accordion title='Enrollment Preferences' component='h3' variant='h4'>
                <EnrollmentPreferencesAccordion />
            </Accordion>

            <Accordion title='Schedule Management' component='h3' variant='h4'>
                <ScheduleManagement />
            </Accordion>

        </div>
    );
}
