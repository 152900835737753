import React from 'react';
import { IDependentCareFlexSpendingAccount } from '../../../models';
import { Box, Grid, InputAdornment, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface IDependentCareFlexSpendingAccountProps {
    data: IDependentCareFlexSpendingAccount;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const DependentCareFlexSpendingAccount: React.FC<IDependentCareFlexSpendingAccountProps> = ({
    data,
    handleBlur,
    setFieldValue,
    errors,
    touched
}) => {

    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    return (<Grid container spacing={1}>
        <Grid container spacing={1}>
            <Grid item marginLeft={1}>
                <Box marginBottom='1rem'>
                    <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                        Dependent Care Flex Spending Account
                    </MuiLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                color='primary'
                                checked={data?.offerDependentCareFlexSpendingAccount}
                                id='dependentCareFlexSpendingAccount.offerDependentCareFlexSpendingAccount'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('dependentCareFlexSpendingAccount.offerDependentCareFlexSpendingAccount', e.target.checked);
                                }}
                                onBlur={handleBlur}
                            />
                        }
                        label={
                            data?.offerDependentCareFlexSpendingAccount ? 'Offered' : 'Not offered'
                        }
                    />
                </Box>
            </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
                fullWidth
                variant='outlined'
                size='small'
                autoComplete='nope'
                label='ER Contribution'
                name='dependentCareFlexSpendingAccount.erContribution'
                value={data?.erContribution}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('dependentCareFlexSpendingAccount.erContribution', e.target.value)}
            />
        </Grid>

        {/* Number Field - Employee Max Annual Contribution */}
        <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
                fullWidth
                size='small'
                variant='outlined'
                label='Employee Max Annual Contribution'
                name='dependentCareFlexSpendingAccount.employeeMaxAnnualContribution'
                value={data?.employeeMaxAnnualContribution}
                onBlur={handleBlur}
                onChange={(e) => {
                    setFieldValue('dependentCareFlexSpendingAccount.employeeMaxAnnualContribution', e.target.value);
                }}
                InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                error={Boolean(
                    touched.dependentCareFlexSpendingAccount?.employeeMaxAnnualContribution &&
                    errors.dependentCareFlexSpendingAccount?.employeeMaxAnnualContribution
                )}
                helperText={
                    touched.dependentCareFlexSpendingAccount?.employeeMaxAnnualContribution &&
                        errors.dependentCareFlexSpendingAccount?.employeeMaxAnnualContribution
                        ? errors.dependentCareFlexSpendingAccount?.employeeMaxAnnualContribution
                        : ''
                }
            />
        </Grid>

        {/* Number Field - Family Max Annual Contribution */}
        <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
                fullWidth
                size='small'
                variant='outlined'
                label='Family Max Annual Contribution'
                name='dependentCareFlexSpendingAccount.familyMaxAnnualContribution'
                value={data?.familyMaxAnnualContribution}
                onBlur={handleBlur}
                onChange={(e) => {
                    setFieldValue('dependentCareFlexSpendingAccount.familyMaxAnnualContribution', e.target.value);
                }}
                InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                error={Boolean(
                    touched.dependentCareFlexSpendingAccount?.familyMaxAnnualContribution &&
                    errors.dependentCareFlexSpendingAccount?.familyMaxAnnualContribution
                )}
                helperText={
                    touched.dependentCareFlexSpendingAccount?.familyMaxAnnualContribution &&
                        errors.dependentCareFlexSpendingAccount?.familyMaxAnnualContribution
                        ? errors.dependentCareFlexSpendingAccount?.familyMaxAnnualContribution
                        : ''
                }
            />
        </Grid>


        <Grid item xs={12} sm={6} md={4}>
            <GenericDropdown handleBlur={handleBlur}
                options={generalOptions?.defaultPeriodOptions ?? []}
                isLoading={generalOptionsLoading}
                setFieldValue={setFieldValue}
                value={data?.coverageTerminatesOn ?? ''}
                error={errors.dependentCareFlexSpendingAccount?.coverageTerminatesOn}
                label='When an employee is terminated, coverage terminates on:' id={`dependentCareFlexSpendingAccount.coverageTerminatesOn`}
            />
        </Grid>
    </Grid>)
};

export default DependentCareFlexSpendingAccount;
