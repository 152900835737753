import { FormikErrors } from "formik";
import { IBusinessClientDetailFormValues } from "../../../../../../../../models";

export default (errors: FormikErrors<IBusinessClientDetailFormValues>) => {
    return !errors?.dependentCareFlexSpendingAccount &&
        !errors?.flexibleSpendingAccount &&
        !errors?.healthReimbursementAccount &&
        !errors?.healthSavingsAccount &&
        !errors?.limitedPurposeFlexSpendingAccount &&
        !errors?.financialProductNotes
}