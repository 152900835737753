import * as React from 'react';
import { MobileAppInfo } from './MobileAppInfo/MobileAppInfo';
import { SingleTabsBase } from '../../base/single-tab-base';
import { DefaultTabBase } from '../shared';



export default function DesignCenter() {

    return (
        <div>
            <SingleTabsBase tabOption={{ label: 'Design Center', menuRelatedId: 'design-center' }} />

            <div>
                <DefaultTabBase>
                    <MobileAppInfo />
                </DefaultTabBase>
            </div>
        </div>
    );
}
