import React, { createContext, useContext, ReactNode } from 'react';
import { IBroker, ISystemOfRecord, IUser } from '../models';

// Define the types for your entities
interface EntitiesDropdownsOptionsCollection {
    systemOfRecords: ISystemOfRecord[];
    users: IUser[];
    brokers: IBroker[];
}

// Create a context with initial values
const EntitiesDropdownsOptionsCollectionContext = createContext<EntitiesDropdownsOptionsCollection | undefined>(undefined);

// Create a custom provider component
interface EntitiesProviderProps extends EntitiesDropdownsOptionsCollection {
    children: ReactNode;
}

export function EntitiesDropdownsOptionsCollectionProvider({ systemOfRecords, users, brokers, children }: EntitiesProviderProps) {
    // Define your initial entity data here
    const initialData: EntitiesDropdownsOptionsCollection = {
        systemOfRecords,
        users,
        brokers
    };

    return <EntitiesDropdownsOptionsCollectionContext.Provider value={initialData}>{children}</EntitiesDropdownsOptionsCollectionContext.Provider>;
}

// Create a custom hook for using the context
export function useEntitiesDropdownsOptionsCollection() {
    const context = useContext(EntitiesDropdownsOptionsCollectionContext);
    if (context === undefined) {
        throw new Error('useEntitiesDropdownsOptionsCollection must be used within an EntitiesDropdownsOptionsCollectionProvider');
    }
    return context;
}
