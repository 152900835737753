import * as React from 'react';
import { Alert } from '@mui/material';
import { useSharedStyles } from '../../pages/admin/business-client-detail/shared/styles';

export interface ITabErrorMessageProps {
    show: boolean
}

export default function TabErrorMessage({ show }: ITabErrorMessageProps) {
    const classes = useSharedStyles();

    return (show ? <Alert
        severity='error'
        variant='filled'
        data-cy="error-alert"
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        classes={{
            message: classes.alertMessage
        }}
    >
        <ul>
            {<li>There are required fields and/or validation errors</li>}
        </ul>
    </Alert> : <></>)


        ;
}
