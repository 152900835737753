import { FormControl, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikHelpers, Field, FieldProps, FormikProps, Form } from 'formik';
import React, { FC, useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { Toast, MinimalPage, Button } from '../../components';
import { login } from '../../fetch';
import { UserContext } from '../../context';

interface ILoginProps {}

interface ILoginValues {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').required('Required'),
  password: Yup.string().required('Required')
});

export const Login: FC<ILoginProps> = () => {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<any>(null);

  const { user, isFetching, setUser } = useContext(UserContext);

  useEffect(() => {
    document.title = `Login | Enrollment Alliance`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return null;
  }

  if (user && !isFetching) {
    if (user.businessClientIds && user.businessClientIds.length === 1) {
      // business clients go to employees which is their dashboard
      return <Redirect to={`/business-clients/${user.businessClientIds[0]}/builder`} />;
    }
    return <Redirect to='/' />;
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={async (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => {
          try {
            const res: any = await login({ email: values.email, password: values.password });

            if (!res || res.Detail) {
              throw Error();
            } else if (res && res.firstName) {
              setUser(res);
            } else {
              throw Error();
            }
          } catch (error) {
            if (error && error.Detail) {
              setError(error.Detail);
            } else {
              setError('Error logging in, please try again');
            }
            actions.resetForm({ values: { email: values.email, password: '' } });
            const emailInput = document.getElementById('email');
            if (emailInput) {
              emailInput.focus();
            }
          } finally {
            actions.setSubmitting(false);
          }
        }}
        validationSchema={LoginSchema}
      >
        {(formikProps: FormikProps<ILoginValues>) => (
          <MinimalPage title='Login'>
            <Form data-testid='admin-login-form' onSubmit={formikProps.handleSubmit}>
              <FormControl margin='normal' fullWidth>
                <Field name='email'>
                  {({ field, form }: FieldProps<ILoginValues>) => (
                    <TextField
                      {...field}
                      error={form.touched.email && form.errors && form.errors.email ? true : false}
                      fullWidth={true}
                      helperText={form.touched.email && form.errors && form.errors.email}
                      id='email'
                      label='Email'
                      margin='none'
                      required={true}
                      type='email'
                      value={formikProps.values.email}
                    />
                  )}
                </Field>
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <Field name='password'>
                  {({ field, form }: FieldProps<ILoginValues>) => (
                    <TextField
                      {...field}
                      error={form.touched.password && form.errors && form.errors.password ? true : false}
                      fullWidth={true}
                      helperText={form.touched.password && form.errors && form.errors.password}
                      id='password'
                      label='Password'
                      margin='none'
                      required={true}
                      type='password'
                      value={formikProps.values.password}
                    />
                  )}
                </Field>
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <Button
                  color='primary'
                  disabled={!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid}
                  id='submit'
                  loading={formikProps.isSubmitting ? 'Logging in...' : ''}
                  size='large'
                  type='submit'
                  variant='contained'
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      formikProps.handleSubmit();
                    }
                  }}
                >
                  {!formikProps.isSubmitting ? 'Login' : ''}
                </Button>
              </FormControl>
              <FormControl fullWidth={true} margin='normal'>
                <Button className={classes.forgotPassword} color='secondary' fullWidth={true} id='forgot-password' variant='text' asLink={true} to={'/forgot-password'}>
                  Forgot Password?
                </Button>
              </FormControl>
            </Form>
          </MinimalPage>
        )}
      </Formik>
      <Toast
        id='login-success'
        message={success && success.message}
        onClick={() => setSuccess(null)}
        onClose={() => setSuccess(null)}
        open={success ? true : false}
        variant='success'
      />
      <Toast id='login-error' message={error} onClick={() => setError(null)} onClose={() => setError(null)} open={error ? true : false} variant='error' />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    forgotPassword: {
      textDecoration: 'underline',
      textTransform: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };
});
