import * as React from 'react';
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import { EnrollmentPlannerSubMenusTypes } from '../../../MenuData/types';
import { DefaultTabBase } from '../shared';
import EssentialDocsList from './EssentialDocsList/EssentialDocsList';
import Census from './Census/Census';
import { EnrollmentPlannerTabs } from './tabs-header';

const componentDictionary: Record<EnrollmentPlannerSubMenusTypes, JSX.Element> = {
    'census': <DefaultTabBase>
        <Census />
        </DefaultTabBase>,
    'ess_docs': <DefaultTabBase>
        <EssentialDocsList />
    </DefaultTabBase>,
};

export default function EnrollmentPlanner() {

    const { selectedSubMenu } = useLateralFormMenuControl();
    const typedSelectedSubMenu = selectedSubMenu as EnrollmentPlannerSubMenusTypes

    return (
        <div>

            <EnrollmentPlannerTabs validateForm={() => { }} />
            <div>
                {componentDictionary[typedSelectedSubMenu]}
            </div>
        </div>
    );
}
