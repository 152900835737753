type IconsType =
    "census_upload_filled" |
    "census_upload" |
    "client_profile_2_filled" |
    "client_profile_2" |
    "client_profile_filled" |
    "client_profile" |
    "communication_strategy_2_filled" |
    "communication_strategy_2" |
    "communication_strategy_filled" |
    "communication_strategy" |
    "enrollment_overview_filled" |
    "enrollment_overview" |
    "enrollment_tracker_filled" |
    "enrollment_tracker" |
    "essential_documents_filled" |
    "essential_documents" |
    "hr_strategy_3_filled" |
    "hr_strategy_3" |
    "hr_strategy_filled" |
    "hr_strategy" |
    "post_enrollment_reporting_filled" |
    "post_enrollment_reporting" |
    "products_offered_2_filled" |
    "products_offered_2" |
    "products_offered_filled" |
    "products_offered"|
    "excel_icon" |
    "pdf_icon" |
    "design_center" |
    "design_center_filled" |
    "enrollment_strategy" |
    "enrollment_strategy_filled";



export interface IFontIconProps {
    icon: IconsType;
    size?: number;
}

export default function FontIcon({ icon, size = 13 }: IFontIconProps) {
    return (
        <span className={`icon_${icon}`} style={{ fontSize: `${size}pt` }} />
    );
}

export const instanceFontIcon = (icon: IconsType, size = 15): React.ReactElement<any> => {
    return <FontIcon icon={icon} size={size} />
}