import React from 'react';
import { Box, Grid, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { useSharedStyles } from './shared/styles';
import { IBusinessClientDetailFormValues } from '../../../models';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useMedicalInsuranceOptionsDropdownCollection from '../../../hooks/dropdown-collection/useMedicalInsuranceOptionsDropdownCollection';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';

interface IMedicalInsuranceProps {
    data: IBusinessClientDetailFormValues;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const MedicalInsurance: React.FC<IMedicalInsuranceProps> = ({ data, handleBlur, setFieldValue, errors }) => {
    const classes = useSharedStyles();

    const medicalInsuranceOptionsRequest = useMedicalInsuranceOptionsDropdownCollection();
    const medicalInsuranceOptions = medicalInsuranceOptionsRequest.data;
    const medicalInsuranceLoading = medicalInsuranceOptionsRequest.status === "loading";

    return (
        <Grid container spacing={1}>

            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Medical
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data.medicalInsurance?.offerMedicalInsurance}
                                    id='medicalInsurance.offerMedicalInsurance'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('medicalInsurance.offerMedicalInsurance', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data.medicalInsurance?.offerMedicalInsurance ? 'Offered' : 'Not offered'}
                        />
                    </Box>

                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Medical Carrier'
                    name='medicalInsurance.medicalCarrier'
                    value={data.medicalInsurance?.medicalCarrier}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('medicalInsurance.medicalCarrier', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Previous Medical Carrier'
                    name='medicalInsurance.previousMedicalCarrier'
                    value={data.medicalInsurance?.previousMedicalCarrier}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('medicalInsurance.previousMedicalCarrier', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.medicalPlanOfferingOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.medicalPlanOffering ?? ''}
                    error={errors.medicalPlanOffering}
                    label='Medical Plan Offering' id={`medicalInsurance.medicalPlanOffering`}
                />

            </Grid>

            <Grid item xs={12} sm={6} md={4}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.medicalDeductibleTypeOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.medicalDeductibleType ?? ''}
                    error={errors.medicalDeductibleType}
                    label='Medical Deductible Type' id={`medicalInsurance.medicalDeductibleType`}
                />

            </Grid>

            <Grid item xs={12} sm={6} md={4}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.telemedicineOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.telemedicine ?? ''}
                    error={errors.telemedicine}
                    label='Telemedicine' id={`medicalInsurance.telemedicine`}
                />

            </Grid>

            <Grid item xs={12} sm={6} md={4}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.medicalCoverageTerminatesOnOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.medicalCoverageTerminatesOn ?? ''}
                    error={errors.medicalCoverageTerminatesOn}
                    label='When an employee is terminated, coverage terminates on:' id={`medicalInsurance.medicalCoverageTerminatesOn`}
                />

            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Medical Notes'
                    name='medicalNotes'
                    value={data.medicalInsurance?.medicalNotes}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('medicalInsurance.medicalNotes', e.target.value)}
                />
            </Grid>

        </Grid>
    );
};

export default MedicalInsurance;
