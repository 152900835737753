import { FormikErrors } from "formik";
import { IBusinessClientDetailFormValues } from "../../../../../../../../models";

export default (errors: FormikErrors<IBusinessClientDetailFormValues>) => {
    return !errors?.name
        && !errors?.brokerName
        && !errors?.accountManagerUserId
        && !errors?.projectType
        && !errors?.systemOfRecord
        && !errors?.eligibilityRules;
}