import React, { FC, useState } from 'react';
import * as Yup from 'yup';

import { FormikErrors, useFormik } from 'formik';

import { Button, Grid } from '@mui/material';
import { ISectionDocuments } from '../../../../../../../../../../../models';
import { TextField } from "@mui/material";
import { createSectionDocuments, insertNewEssentialDoc } from '../../../../../../../../../../../fetch/section-documents';
import { useSectionDocuments } from '../../../contexts/SectionDocumentsContext';
import useErrorMessage from '../../../../../../../../../../../context/ErrorMessageCtx';
import useSuccessMessage from '../../../../../../../../../../../context/SuccessMessageCtx';
import FieldErrorMessage from '../../../../../../../../../../../components/field-error-message/FieldErrorMessage ';

interface INewFileManagementFormProps {
    businessClientId: number;
    cancelCallback: () => void;
    section?: ISectionDocuments;
    sectionIndex?: number;
}

interface FormValues {
    businessClientId: number;
    category: string;
    filesData: {
        otherInformations: string;
        externalFileUrl: string;
        file: any;
    }[];
}

const validationSchema = Yup.object().shape({
    filesData: Yup.array().of(
        Yup.object().shape({
            otherInformations: Yup.string().nullable(),
            externalFileUrl: Yup.string(),
            file: Yup.mixed().test('fileRequired', 'Either File or External File URL is required', function (value) {
                return this.parent.externalFileUrl || value;
            }),
        })
    ),
});


export const NewFileManagementForm: FC<INewFileManagementFormProps> = ({ cancelCallback, section, sectionIndex, businessClientId }) => {

    const { replaceSection, selectedSectionInfo, closeAddEditFileModal } = useSectionDocuments();
    const [, setErrorMessage] = useErrorMessage();
    const [, setSuccessMessage] = useSuccessMessage();
    const shouldCreateTheSession = selectedSectionInfo?.[0].id === null;

    const [isUploading, setIsUploading] = useState(false);

    const initialValues: FormValues = {
        businessClientId,
        category: section?.category,
        filesData: [
            { otherInformations: '', externalFileUrl: '', file: null }
        ],
    };

    const onSubmit = async (values: FormValues) => {

        setIsUploading(true);
        if (shouldCreateTheSession) {
            //CreateSectionDocuments

            const formData = new FormData();
            formData.append('Details.FilesData[0].File', values.filesData[0].file);
            formData.append('Details.FilesData[0].OtherInformations', values.filesData[0].otherInformations);
            formData.append('Details.FilesData[0].ExternalFileUrl', values.filesData[0].externalFileUrl);

            formData.append('Details.BusinessClientId', String(values.businessClientId));
            formData.append('Details.Category', values.category);
            //
            //
            try {
                const newSection: ISectionDocuments = await createSectionDocuments(formData);
                replaceSection(sectionIndex, newSection);
                closeAddEditFileModal();
                setSuccessMessage(`New file added to ${section.categoryDescription}!`);
            } catch (error) {
                setErrorMessage(error.Title)
            }

        } else {
            //InsertNewEssentialDoc
            const formData = new FormData();
            formData.append('FileData.File', values.filesData[0].file);
            formData.append('FileData.OtherInformations', values.filesData[0].otherInformations);
            formData.append('FileData.ExternalFileUrl', values.filesData[0].externalFileUrl);

            try {
                const newSection: ISectionDocuments = await insertNewEssentialDoc(section.id, formData);
                replaceSection(sectionIndex, newSection);
                closeAddEditFileModal();
                setSuccessMessage(`New file added to ${section.categoryDescription}!`);
            }
            catch (error) {
                setErrorMessage(error.Title)
            }

        }

        setIsUploading(false);
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const filesDataErrors = formik.errors?.filesData as FormikErrors<{ otherInformations: '', externalFileUrl: '', file: null }>[];

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction='column' spacing={1}>

                <Grid item md={12} sx={{
                    'marginBottom': '1em'
                }}>
                    <label htmlFor={`filesData[0].file`}>File:</label>
                    <input
                        type="file"
                        id={`filesData[0].file`}
                        name={`filesData[0].file`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue(`filesData[0].file`, event.target.files[0])
                        }
                        }
                    />
                    <FieldErrorMessage message={filesDataErrors?.[0]?.file as string} />
                </Grid>

                <Grid item md={12} >
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type="url"
                        label='External File Url'
                        id={`filesData[0].externalFileUrl`}
                        name={`filesData[0].externalFileUrl`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue(`filesData[0].externalFileUrl`, event.target.value)
                        }
                        }
                    />
                    <FieldErrorMessage message={filesDataErrors?.[0]?.externalFileUrl as string} />
                </Grid>

                <Grid item md={12} >
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        multiline
                        label='Other Informations'
                        id={`filesData[0].otherInformations`}
                        name={`filesData[0].otherInformations`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue(`filesData[0].otherInformations`, event.target.value)
                        }
                        }
                    />
                    <FieldErrorMessage message={filesDataErrors?.[0]?.otherInformations as string} />
                </Grid>

            </Grid>

            <div style={{
                'justifyContent': 'start',
                'margin': '1em 0 0 0'
            }}>
                <Button disabled={isUploading} type="submit" variant='contained' sx={{ marginRight: '10px' }}>
                    {isUploading ? 'Sending...' : 'Upload file'}
                </Button>

                <Button disabled={isUploading} color='inherit' variant='contained' onClick={() => cancelCallback()}>
                    Cancel
                </Button>
            </div>
        </form>
    );
};

export default NewFileManagementForm;
