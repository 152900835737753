import { FC } from 'react';
import { Grid, FormControl, Select, MenuItem, FormHelperText, TextField, RadioGroup, FormControlLabel, Radio, FormLabel as MuiLabel, Theme } from '@mui/material';
import { IBusinessClientDetailFormValues, IBusinessClientDetail, IDropdownResponse, ISystemOfRecord, IUser } from '../../../models';
import { FormikErrors, FormikTouched } from 'formik';
import { FormLabel, Accordion } from '../../../components';
import { ClientPreferences } from './ClientPreferences';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import BusinessClientContacts from './BusinessClientContacts';
import HealthReimbursementAccount from './HealthReimbursementAccount';
import FlexibleSpendingAccount from './FlexibleSpendingAccount';
import DependentCareFlexSpendingAccount from './DependentCareFlexSpendingAccount';
import LimitedPurposeFlexSpendingAccount from '../LimitedPurposeFlexSpendingAccount';
import DentalInsurance from './DentalInsurance';
import BusinessClientMedicalPlansTable from './BusinessClientMedicalPlansTable';
import DentalInsurancePlansTable from './DentalInsurancePlansTable';
import VisionInsurancePlansTable from './VisionInsurancePlans';
import VisionInsurance from './VisionInsurance';
import MedicalInsurance from './MedicalInsurance';
import HealthSavingsAccount from './HealthSavingsAccount';
import ShortTermDisabilityCoverage from './ShortTermDisabilityCoverage';
import ShortTermDisabilityCoverageClassesTable from './ShortTermDisabilityCoverageClassesTable';
import LongTermDisabilityCoverage from './LongTermDisabilityCoverage';
import LongTermDisabilityCoverageClassesTable from './LongTermDisabilityCoverageClassesTable';
import GroupLifeInsurance from './GroupLifeInsurance';
import GroupLifeInsuranceClassesTable from './GroupLifeInsuranceClassesTable';
import AccidentInsurance from './AccidentInsurance';
import HospitalCoverage from './HospitalCoverage';
import CancerCoverage from './CancerCoverage';
import CriticalIllnessCoverage from './CriticalIllnessCoverage';
import CriticalIllnessCoverageGuaranteedIssuesTable from './CriticalIllnessCoverageGuaranteedIssuesTable';
import PortableLifeCoverage from './PortableLifeCoverage';
import PortableLifeCoverageGuaranteedIssuesTable from './PortableLifeCoverageGuaranteedIssuesTable';
import SupplementalProductsTable from './SupplementalProductsTable';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import EligibilityRules from './EligibilityRules';
import EligibilityRulesDeductionTable from './EligibilityRulesDeductionTable';
import EnrollmentEngagementNumbers from './EnrollmentEngagementNumbers';
import VoluntaryLifeCoverage from './VoluntaryLifeCoverage';
import VoluntaryLifeCoverageGuaranteedIssuesTable from './VoluntaryLifeCoverageGuaranteedIssues';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface IEnrollmentOverviewProps {
  values: IBusinessClientDetailFormValues;
  handleBlur: any;
  touched: FormikTouched<IBusinessClientDetailFormValues>;
  errors: FormikErrors<IBusinessClientDetailFormValues>;
  setFieldValue: (key: string, val: any) => void;
  setFieldTouched: (key: string, val: any) => void;
  selectedTab: number;
  currentBusinessClient: IBusinessClientDetail | null;
  isMobile: boolean;

  statesLoading: boolean;
  states: IDropdownResponse[];

  industriesTiers: IDropdownResponse[];
  industriesTiersLoading: boolean;
  systemOfRecordsLoading: boolean;
  systemOfRecords: ISystemOfRecord[];

  benAdminBuildTypes: IDropdownResponse[];
  benAdminBuildTypesLoading: boolean;

  engagementPercentages: IDropdownResponse[];
  engagementPercentagesLoading: boolean;

  isLoadingUsers: boolean;
  users: IUser[];
  dataFileRecipientsLoading: boolean;
  dataFileRecipients: IDropdownResponse[];
}

export const EnrollmentOverview: FC<IEnrollmentOverviewProps> = ({
  values,
  errors,
  handleBlur,
  setFieldValue,
  touched,
  setFieldTouched,
  isMobile,
  currentBusinessClient,
  states,
  statesLoading,
  industriesTiers,
  industriesTiersLoading,
  benAdminBuildTypes,
  benAdminBuildTypesLoading,
  engagementPercentages,
  engagementPercentagesLoading,
  systemOfRecordsLoading,
  systemOfRecords,
  isLoadingUsers,
  users,
  dataFileRecipientsLoading,
  dataFileRecipients
}) => {

  const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
  const generalOptions = generalDropdownsOptionsRequest.data;
  const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

  const industries = generalOptions?.industryOptions;
  const industriesLoading = generalOptionsLoading;


  const anticipatedSupports = generalOptions?.anticipatedSupportOptions;
  const anticipatedSupportsLoading = generalOptionsLoading;

  return (
    <>
      <Accordion title='Client Details' defaultExpanded component='h3' variant='h4'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth variant='outlined' size='small' error={errors?.situsState ? true : false}>
              <FormLabel label='Situs State' id={`situs-state`} />
              <Select
                name={`situs-state`}
                labelId={`situs-state`}
                onChange={e => setFieldValue('situsState', e.target.value)}
                id={`situs-state`}
                size='small'
                value={values.situsState}
                onBlur={handleBlur}
                disabled={statesLoading}
              >
                {!statesLoading &&
                  states.map((state: IDropdownResponse, index) => {
                    return (
                      <MenuItem key={`${index}`} value={state.value}>
                        {state.description}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText error={errors?.situsState ? true : false}>{errors?.situsState}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='SIC Code'
              name='sicCode'
              value={values.sicCode}
              onBlur={handleBlur}
              onChange={e => setFieldValue('sicCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Street Address'
              name='street'
              value={values.street}
              onBlur={handleBlur}
              onChange={e => setFieldValue('street', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Street Address Line 2'
              name='streetLine2'
              value={values.streetLine2}
              onBlur={handleBlur}
              onChange={e => setFieldValue('streetLine2', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='City'
              name='city'
              value={values.city}
              onBlur={handleBlur}
              onChange={e => setFieldValue('city', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='State / Province'
              name='state'
              value={values.state}
              onBlur={handleBlur}
              onChange={e => setFieldValue('state', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Postal / ZIP Code'
              name='postalCode'
              value={values.postalCode}
              onBlur={handleBlur}
              onChange={e => setFieldValue('postalCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Tax ID'
              name='taxId'
              value={values.taxId}
              onBlur={handleBlur}
              onChange={e => setFieldValue('taxId', e.target.value)}
            />
          </Grid>

        </Grid>
      </Accordion>
      <Accordion
        title='Client Preferences'
        hasRequiredFields
        hasErrors={
          touched &&
            (errors.systemOfRecord ||
              errors.systemOfRecordUrl ||
              errors.systemOfRecordUsername ||
              errors.systemOfRecordPassword ||
              errors.highlightsChallenges ||
              errors.specialAttentionBusinessClientEmployees ||
              errors.dashboardOpenEnrollmentView ||
              errors.openEnrollmentEffectiveDate ||
              errors.openEnrollmentStartDate ||
              errors.enrollmentDeadline ||
              errors.accountManagerUserId ||
              errors.dataFileRecipients)
            ? true
            : false
        }
        component='h3'
        variant='h4'
      >
        <ClientPreferences
          values={values}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          isMobile={isMobile}
          currentBusinessClient={currentBusinessClient}
          systemOfRecords={systemOfRecords}
          systemOfRecordsLoading={systemOfRecordsLoading}
          isLoadingUsers={isLoadingUsers}
          users={users}
          dataFileRecipientsLoading={dataFileRecipientsLoading}
          dataFileRecipients={dataFileRecipients}
        />
      </Accordion>
      {/* <Accordion title='Locations and Logistic' component='h3' variant='h4'>
      <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            
          </Grid>
          <Grid item xs={12} sm={6}>
            
          </Grid>
          <Grid item xs={12} sm={6}>
            
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Additional languages'
              name='hrManagerPhoneNumber'
              value={values.hrManagerPhoneNumber}
              onBlur={handleBlur}
              onChange={e => setFieldValue('hrManagerPhoneNumber', formatInputPhoneNumber(e.target.value))}
              error={Boolean(touched.hrManagerPhoneNumber && errors.hrManagerPhoneNumber)}
              helperText={touched.hrManagerPhoneNumber && errors.hrManagerPhoneNumber}
            />
          </Grid>
        </Grid>
      </Accordion> */}
      <Accordion title='Products Offered' component='h3' variant='h4'>
        test
      </Accordion>
      <Accordion title='Enrollment Strategy' component='h3' variant='h4'>
        test
      </Accordion>
      <Accordion title='Confirmation' component='h3' variant='h4'>
        test
      </Accordion>

      <Accordion title='Eligibility Rules' component='h3' variant='h4'>
        <EligibilityRules
          data={values.eligibilityRules}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue} />

        <EligibilityRulesDeductionTable
          eligibilityRulesDeductions={values.eligibilityRules?.eligibilityRulesDeductions ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Accordion>

      <Accordion title='Contacts' component='h3' variant='h4'>
        <BusinessClientContacts
          contacts={values.contacts}
          handleBlur={handleBlur}
          errors={errors}
          setFieldValue={setFieldValue}
          values={values} />
      </Accordion>

      <Accordion title='Company Informations' component='h3' variant='h4'>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>

            <GenericDropdown handleBlur={handleBlur}
              options={industries}
              isLoading={industriesLoading}
              setFieldValue={setFieldValue}
              value={values.industry ?? ''}
              error={errors.industry}
              label='Industry Type' id={`industry`}
            />

          </Grid>

          <Grid item xs={12} sm={6} md={4}>

            <GenericDropdown handleBlur={handleBlur}
              options={industriesTiers}
              isLoading={industriesTiersLoading}
              setFieldValue={setFieldValue}
              value={values.industryTier ?? ''}
              error={errors.industryTier}
              label='Industry Tier' id={`industryTier`}
            />

          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Total Eligible Employees'
              name='totalEligibleEmployees'
              value={values.totalEligibleEmployees}
              onBlur={handleBlur}
              onChange={e => setFieldValue('totalEligibleEmployees', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Total Employees'
              name='totalEmployees'
              value={values.totalEmployees}
              onBlur={handleBlur}
              onChange={e => setFieldValue('totalEmployees', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Engagement - Package Type'
              name='engagementPackageType'
              value={values.engagementPackageType}
              onBlur={handleBlur}
              onChange={e => setFieldValue('engagementPackageType', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <PerformantTextField
              fullWidth
              variant='outlined'
              size='small'
              autoComplete='nope'
              label='Carrier Rep Goals'
              name='carrierRepGoals'
              value={values.carrierRepGoals}
              onBlur={handleBlur}
              onChange={e => setFieldValue('carrierRepGoals', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <GenericDropdown handleBlur={handleBlur}
              options={benAdminBuildTypes}
              isLoading={benAdminBuildTypesLoading}
              setFieldValue={setFieldValue}
              value={values.benAdminBuildType ?? ''}
              error={errors.benAdminBuildType}
              label='Ben Admin - Build Type'
              id={`benAdminBuildType`}
            />

          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <GenericDropdown handleBlur={handleBlur}
              options={engagementPercentages}
              isLoading={engagementPercentagesLoading}
              setFieldValue={setFieldValue}
              value={values.engagementPercentage ?? ''}
              error={errors.engagementPercentage}
              label='Engagement Percentage'
              id={`engagementPercentage`}
            />

          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <GenericDropdown handleBlur={handleBlur}
              options={anticipatedSupports}
              isLoading={anticipatedSupportsLoading}
              setFieldValue={setFieldValue}
              value={values.anticipatedSupport ?? ''}
              error={errors.anticipatedSupport}
              label='Anticipated Support'
              id={`anticipatedSupport`}
            />

          </Grid>

        </Grid>



      </Accordion>

      <Accordion title='Medical Insurance' component='h3' variant='h4'>
        <MedicalInsurance
          data={values}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched}
        />

        <BusinessClientMedicalPlansTable
          medicalPlans={values.medicalInsurance?.medicalPlans ?? []}
          handleBlur={handleBlur}
          errors={errors}
          setFieldValue={setFieldValue}
          values={values} />

      </Accordion>

      <Accordion title='Health Savings Account' component='h3' variant='h4'>
        <HealthSavingsAccount
          data={values.healthSavingsAccount}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />
      </Accordion>

      <Accordion title='Health Reimbursement Account' component='h3' variant='h4'>
        <HealthReimbursementAccount
          data={values.healthReimbursementAccount}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue} />
      </Accordion>

      <Accordion title='Flexible Spending Account' component='h3' variant='h4'>
        <FlexibleSpendingAccount
          data={values.flexibleSpendingAccount}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />
      </Accordion>

      <Accordion title='Dependent Care Flex Spending Account' component='h3' variant='h4'>
        <DependentCareFlexSpendingAccount
          data={values.dependentCareFlexSpendingAccount}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />
      </Accordion>

      <Accordion title='Limited Purpose Flex Spending Account' component='h3' variant='h4'>
        <LimitedPurposeFlexSpendingAccount
          data={values.limitedPurposeFlexSpendingAccount}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <Grid item xs={12} sm={6} md={4}>
          <PerformantTextField
            fullWidth
            multiline
            variant='outlined'
            size='small'
            autoComplete='nope'
            label='Financial Product Notes'
            name='financialProductNotes'
            value={values.financialProductNotes}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue('financialProductNotes', e.target.value)}
          />

        </Grid>
      </Accordion>

      <Accordion title='Dental and Vision Insurance' component='h3' variant='h4'>
        <DentalInsurance
          data={values.dentalInsurance}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <DentalInsurancePlansTable
          dentalInsurancePlans={values.dentalInsurance?.dentalInsurancePlans ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />


        <VisionInsurance
          data={values.visionInsurance}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <VisionInsurancePlansTable
          visionInsurancePlans={values.visionInsurance?.visionInsurancePlans ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Accordion>

      <Accordion title='Short Term Disability Coverage' component='h3' variant='h4'>

        <ShortTermDisabilityCoverage
          data={values.shortTermDisabilityCoverage}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <ShortTermDisabilityCoverageClassesTable
          shortTermDisabilityCoverageClasses={values.shortTermDisabilityCoverage?.shortTermDisabilityCoverageClasses ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />

      </Accordion>

      <Accordion title='Long Term Disability Coverage' component='h3' variant='h4'>

        <LongTermDisabilityCoverage
          data={values.longTermDisabilityCoverage}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <LongTermDisabilityCoverageClassesTable
          longTermDisabilityClassTable={values.longTermDisabilityCoverage?.longTermDisabilityCoverageClasses ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />

      </Accordion>


      <Accordion title='Voluntary Life Coverage' component='h3' variant='h4'>
        <VoluntaryLifeCoverage
          data={values.voluntaryLifeCoverage}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <VoluntaryLifeCoverageGuaranteedIssuesTable
          voluntaryLifeCoverageGuaranteedIssues={values.voluntaryLifeCoverage?.voluntaryLifeCoverageGuaranteedIssues ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values} />
      </Accordion>

      <Accordion title='Group Life Insurance' component='h3' variant='h4'>

        <GroupLifeInsurance
          data={values.groupLifeInsurance}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <GroupLifeInsuranceClassesTable
          groupLifeInsuranceClasses={values.groupLifeInsurance?.groupLifeInsuranceClasses ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />

      </Accordion>

      <Accordion title='Accident Insurance' component='h3' variant='h4'>
        <AccidentInsurance
          data={values.accidentInsurance}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />
      </Accordion>

      <Accordion title='Hospital Coverage' component='h3' variant='h4'>
        <HospitalCoverage
          data={values.hospitalCoverage}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />
      </Accordion>

      <Accordion title='Cancer Coverage' component='h3' variant='h4'>
        <CancerCoverage
          data={values.cancerInsurance}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />
      </Accordion>

      <Accordion title='Critical Illness Coverage' component='h3' variant='h4'>
        <CriticalIllnessCoverage
          data={values.criticalIllnessInsurance}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <CriticalIllnessCoverageGuaranteedIssuesTable
          criticalIllnessInsuranceGuaranteedIssues={values.criticalIllnessInsurance?.criticalIllnessInsuranceGuaranteedIssues ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Accordion>
      <Accordion title='Portable Life Coverage' component='h3' variant='h4'>
        <PortableLifeCoverage
          data={values.portableLifeInsurance}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          touched={touched} />

        <PortableLifeCoverageGuaranteedIssuesTable
          portableLifeInsuranceGuaranteedIssues={values.portableLifeInsurance?.portableLifeInsuranceGuaranteedIssues ?? []}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
        />
      </Accordion>
      <Accordion title='Supplemental Products' component='h3' variant='h4'>
        <SupplementalProductsTable
          supplementalProducts={values.supplementalProducts}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values} />
      </Accordion>

      <Accordion title='Enrollment Engagement Numbers' component='h3' variant='h4'>
        <EnrollmentEngagementNumbers
          data={values.enrollmentEngagementNumbers}
          errors={errors}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values} />
      </Accordion>

    </>
  );
};


