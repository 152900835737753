import { FC } from 'react';
import { EnrollmentStrategySubMenusTypes, TabOptionType } from '../../../../MenuData/types';
import { TabsBase } from '../../../base/tabs-base';


interface IEnrollmentStrategyTabsProps {
    validateForm: () => void;
}

const tabOptions: TabOptionType<EnrollmentStrategySubMenusTypes>[] = [
    {
        value: 0,
        label: 'Enrollment Preferences',
        subMenuRelatedId: 'enrollment_preferences'
    },
    {
        value: 1,
        label: 'Scheduling',
        subMenuRelatedId: 'scheduling'
    },
    {
        value: 2,
        label: 'Locations & Logistics',
        subMenuRelatedId: 'locations_&_logistics'
    }
];

//'industry'
//'company_preferences'

export const EnrollmentStrategyTabs: FC<IEnrollmentStrategyTabsProps> = ({ validateForm }) => {
    return <TabsBase validateForm={validateForm} tabOptions={tabOptions} />
}
