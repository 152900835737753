import React, { useMemo, useState, useEffect } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
// Components
import { Page } from '../../../components/skeleton';
import { Table, Pagination, Toast, PageTitle, Button, Loader } from '../../../components';
import { MobileManageUser } from './MobileManageUser';
import { ManageUsersFilters } from './ManageUsersFilters';
import { UserModal } from './UserModal';
// icons
import { Edit, Add, Close } from '@mui/icons-material';
// Types
import { SortOptions, DESC_SORT, ASC_SORT, IUser, IUserDetail } from '../../../models';
// Fetch
import { getUser, getUsers } from '../../../fetch';

interface IFilters {
  search?: string;
  role?: string;
  isActive?: boolean;
}

export const ManageUsers = () => {
  const classes = useStyles();

  // state
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<{
    ClientName?: SortOptions;
    FirstName?: SortOptions;
    LastName?: SortOptions;
    Email?: SortOptions;
    PhoneNumber?: SortOptions;
    Role?: SortOptions;
    IsActive?: SortOptions;
  }>({
    FirstName: ASC_SORT
  });
  const [selectedSort, setSelectedSort] = useState<string>('FirstName');
  const [filters, setFilters] = useState<IFilters | null>(null);
  const [isUserModalOpen, showUserModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUserDetail | null>(null);
  const [isLoadingOverlay, setLoadingOverlay] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('Active');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [searchValue, setSearch] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const searchParams = {
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        role: filters && filters.role ? filters.role : null,
        isActive: filters && filters.isActive !== null ? filters.isActive : true,
        search: searchValue ? searchValue : null
      };
      const usersResponse = await getUsers(searchParams);
      if (searchValue) {
        setSearchedValue(searchValue);
      } else {
        setSearchedValue('');
      }
      if (usersResponse.records) {
        setUsers(usersResponse.records);
        setRecordCount(usersResponse.totalRecordCount);
      } else {
        console.error(`No 'records' on users response.`);
        setErrorMessage('Error loading users, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading users, please try again.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage, filters]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === ASC_SORT ? DESC_SORT : ASC_SORT
    });
  };

  const handleEdit = async id => {
    try {
      setLoadingOverlay(true);
      const res = await getUser(id);
      setCurrentUser(res);
      showUserModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOverlay(false);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Broker/Client Name',
        accessor: 'clientName',
        isServerSorted: selectedSort === 'ClientName',
        isServerSortedDesc: sortDirection.ClientName === DESC_SORT,
        handleClickColumn: () => handleClickColumn('ClientName')
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        isServerSorted: selectedSort === 'FirstName',
        isServerSortedDesc: sortDirection.FirstName === DESC_SORT,
        handleClickColumn: () => handleClickColumn('FirstName')
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        isServerSorted: selectedSort === 'LastName',
        isServerSortedDesc: sortDirection.LastName === DESC_SORT,
        handleClickColumn: () => handleClickColumn('LastName')
      },
      {
        Header: 'Email',
        accessor: 'email',
        isServerSorted: selectedSort === 'Email',
        isServerSortedDesc: sortDirection.Email === DESC_SORT,
        handleClickColumn: () => handleClickColumn('Email')
      },
      {
        Header: 'Phone',
        accessor: 'phoneNumber',
        isServerSorted: selectedSort === 'PhoneNumber',
        isServerSortedDesc: sortDirection.PhoneNumber === DESC_SORT,
        handleClickColumn: () => handleClickColumn('PhoneNumber')
      },
      {
        Header: 'Role',
        accessor: 'role',
        isServerSorted: selectedSort === 'Role',
        isServerSortedDesc: sortDirection.Role === DESC_SORT,
        handleClickColumn: () => handleClickColumn('Role')
      },
      {
        Header: 'Active',
        accessor: (d: IUser) => (d.isActive ? 'Yes' : 'No'),
        isServerSorted: selectedSort === 'IsActive',
        isServerSortedDesc: sortDirection.IsActive === DESC_SORT,
        handleClickColumn: () => handleClickColumn('IsActive'),
        overrideWidth: 60
      },
      {
        id: 'actions',
        overrideWidth: 60,
        Header: '',
        className: classes.editButton,
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IUser } };
        }) => {
          return <>
            <IconButton
              color='primary'
              onClick={async () => {
                handleEdit(original.userId);
              }}
              size="large">
              <Edit />
            </IconButton>
          </>;
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, sortDirection, selectedSort, page, perPage, filters]);

  const hasData = users && users.length > 0;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const isSmMobile = useMediaQuery('(max-width: 600px)');

  return <>
    {isLoadingOverlay && (
      <Loader position='centered' type='fullscreen'>
        Loading...
      </Loader>
    )}
    <Page title='Manage Users'>
      <PageTitle title='Manage Users' />
      <Grid container alignItems='center' justifyContent={isSmMobile ? 'center' : 'flex-end'} className={classes.addUserWrapper}>
        <Button id='create-user' startIcon={<Add />} onClick={() => showUserModal(true)} color='primary' variant='contained' className={classes.addUserButton}>
          Add User
        </Button>
      </Grid>
      <ManageUsersFilters
        isLoading={isLoading}
        selectedStatus={selectedStatus}
        selectedRole={selectedRole}
        searchValue={searchValue}
        setSearchValue={(val: string) => setSearch(val)}
        setSelectedRole={(val: string) => setSelectedRole(val)}
        setSelectedStatus={(val: string) => setSelectedStatus(val)}
        handleSearch={(clearSearch?: boolean) => {
          setFilters({
            isActive: selectedStatus === 'Active' ? true : false,
            role: selectedRole,
            search: clearSearch ? null : searchValue
          });
          if (clearSearch) {
            setSearchedValue('');
          }
        }}
        applyFilters={(clearFilters?: boolean, clearSearch?: boolean) => {
          if (clearFilters) {
            setFilters({
              isActive: true,
              role: '',
              search: ''
            });
          } else {
            setFilters({
              ...filters,
              isActive: selectedStatus === 'Active' ? true : false,
              role: selectedRole
            });
          }
        }}
      />
      {searchedValue && (
        <Grid container alignItems='center' className={classes.searchWrapper}>
          <Typography variant='h4' color='secondary'>
            Showing results for "{searchedValue}"
          </Typography>
          <Button
            id='clear-search'
            size='small'
            startIcon={<Close />}
            className={classes.clearSearchButton}
            onClick={() => {
              setSearch('');
              setSearchedValue('');
              setFilters({
                isActive: selectedStatus === 'Active' ? true : false,
                role: selectedRole,
                search: null
              });
            }}>
            Clear Search
          </Button>
        </Grid>
      )}
      <div className={classes.wrapper}>
        <Table
          columns={columns}
          data={users}
          isLoading={isLoading}
          hidePagination
          ResponsiveComponent={isMobile ? MobileManageUser : undefined}
          mobileProps={{
            handleEdit: (val: IUser) => {
              handleEdit(val.userId);
            }
          }}
        />
        {!isLoading && hasData && (
          <div className={classes.paginationWrapper}>
            <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
          </div>
        )}
      </div>
      <UserModal
        currentUser={currentUser}
        open={isUserModalOpen}
        onClose={() => {
          showUserModal(false);
          setTimeout(() => {
            setCurrentUser(null);
          }, 500);
        }}
        onSave={() => fetchUsers()}
      />
      <Toast id='reports-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
      <Toast id='reports-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
    </Page>
  </>;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  },
  editButton: {
    textAlign: 'right'
  },
  deleteButton: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main
  },
  addUserWrapper: {
    marginBottom: theme.spacing(1)
  },
  addUserButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  searchWrapper: {
    margin: theme.spacing(1, 0)
  },
  clearSearchButton: {
    '@media (min-width: 408px)': {
      marginTop: 3,
      marginLeft: theme.spacing(1)
    }
  }
}));
