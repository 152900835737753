import React, { FC, useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, TextField, Theme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Formik, FormikHelpers, Field, FieldProps, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import { Toast, Button } from '../../components';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
// fetch
import { login, resetPassword } from '../../fetch';
// helpers
import { passwordRegex } from '../../helpers';
import { IResetPasswordPost } from '../../models';
// context
import { UserContext } from '../../context';

interface IResetPasswordProps {
  // a way to bypass the token check and still render the form like normal
  // mainly used for unit tests
  overrideToken?: any;
}

const resetPasswordSchema = Yup.object().shape({
  confirm: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters')
    .matches(passwordRegex, {
      message: 'Invalid Password'
    })
    .oneOf([Yup.ref('password'), null], `Passwords don't match`),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters').matches(passwordRegex, {
    message: 'Invalid password'
  })
});

interface IResetPasswordFormValues {
  confirm: string;
  email: string;
  password: string;
}

/**
 * Reset password should be sent at least on prop of onClose, redirect, or reload
 * @param props IResetPasswordProps
 */
export const ResetPasswordForm: FC<IResetPasswordProps> = ({ overrideToken }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<any>(null);

  const { setUser } = useContext(UserContext);

  const history = useHistory();

  // Get token from URL
  const location = useLocation();
  const params = location.search;
  const tokenCode = params.split('?token=')[1]; // Remove token query string and return exact remaining from URL, don't decode

  // Prevent users from getting to this screen without a token in the URL
  // or someone passes in an override flag
  if (!tokenCode && !overrideToken) {
    return <Redirect to='/' />;
  }

  const handleOnSubmit = () => {
    setTimeout(function () {
      history.push(`/`);
    }, 3000);
  };
  return (
    <>
      <Formik
        initialValues={{
          confirm: '',
          email: '',
          password: ''
        }}
        onSubmit={async (values: IResetPasswordFormValues, actions: FormikHelpers<IResetPasswordFormValues>) => {
          setLoading(true);
          const data: IResetPasswordPost = {
            email: values.email,
            resetPasswordToken: tokenCode,
            newPassword: values.password
          };

          try {
            const res: any = await resetPassword(data);
            if (!res || res.Detail || res.Errors) {
              ///reset-password?token=O1EXxWsJn5dw7twrFP11c9ON+EQoOwRg11N+a/TybT0/LEV/Yz2j2vnOUwrUp/vh54rOT19gTNQH5GncnBNTaA==
              if (res.Detail) {
                setError({ message: res.Detail });
              } else {
                setError({ message: res.Errors && res.Errors.Body && res.Errors.Body[0] });
              }
              return;
            } else if (res) {
              setSuccess({ message: `Password Reset Successfully. Logging in...` });
            } else {
              throw Error();
            }
            actions.resetForm({
              values: {
                confirm: '',
                email: '',
                password: ''
              }
            });

            // Log user in with new password upon password reset
            const res1: any = await login({ email: values.email, password: values.password });
            if (!res1 || res1.Detail) {
              setError({ message: res1.Detail });
              return;
            } else if (res1 && res1.firstName) {
              setUser(res1);
            } else {
              throw Error();
            }
          } catch (error) {
            if (error && error.Detail) {
              setError({ message: error.Detail });
            } else {
              setError(error);
            }
          } finally {
            actions.setSubmitting(false);
            setLoading(false);
          }
        }}
        validationSchema={resetPasswordSchema}
      >
        {(formikProps: FormikProps<IResetPasswordFormValues>) => {
          return (
            <>
              <Form data-testid='reset-password-form' id='reset-password-form' onSubmit={formikProps.handleSubmit}>
                <FormControl fullWidth={true} margin='normal'>
                  <Field name='email'>
                    {({ field, form: { touched, errors, setFieldValue } }: FieldProps<IResetPasswordFormValues>) => (
                      <TextField
                        {...field}
                        error={touched.email && errors.email ? true : false}
                        fullWidth={true}
                        helperText={touched.email && errors.email}
                        id='reset-password-email'
                        label='Email'
                        onChange={e => setFieldValue('email', e.target.value)}
                        required={true}
                        type='email'
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth={true} margin='normal'>
                  <Field name='password'>
                    {({ field, form: { touched, errors, setFieldValue } }: FieldProps<IResetPasswordFormValues>) => (
                      <TextField
                        {...field}
                        error={touched.password && errors.password ? true : false}
                        fullWidth={true}
                        helperText={touched.password && errors.password}
                        id='reset-password-password'
                        label='Password'
                        onChange={e => setFieldValue('password', e.target.value)}
                        required={true}
                        type='password'
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth={true} size='small'>
                  <ul className={classes.list}>
                    <li>Must be at least 8 characters long</li>
                    <li>Must have at least one numeric character</li>
                    <li>Must have at least one &quot;special&quot; character, ex: !$#@%</li>
                  </ul>
                </FormControl>
                <FormControl fullWidth={true} margin='normal'>
                  <Field name='confirm'>
                    {({ field, form: { touched, errors, setFieldValue } }: FieldProps<IResetPasswordFormValues>) => (
                      <TextField
                        {...field}
                        error={touched.confirm && errors.confirm ? true : false}
                        fullWidth={true}
                        helperText={touched.confirm && errors.confirm}
                        id='reset-password-confirm'
                        label='Confirm Password'
                        onChange={e => setFieldValue('confirm', e.target.value)}
                        required={true}
                        type='password'
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth={true} margin='normal'>
                  <Button
                    color='primary'
                    disabled={!formikProps.dirty || !formikProps.isValid || loading}
                    fullWidth={true}
                    id='reset-password-button'
                    loading={loading ? 'Resetting...' : undefined}
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Save and Login
                  </Button>
                </FormControl>
                <FormControl fullWidth={true} margin='normal'>
                  <Button className={classes.back} color='secondary' fullWidth={true} id='back-to-login' asLink={true} to={'/'} variant='text' startIcon={<ArrowBackIcon />}>
                    Back to Login
                  </Button>
                </FormControl>
              </Form>
            </>
          );
        }}
      </Formik>
      <Toast
        autoHideDuration={4000}
        id='reset-password-success'
        message={success ? success.message : null}
        onClick={() => setSuccess(null)}
        onClose={() => {
          setSuccess(null);
          handleOnSubmit();
        }}
        open={success ? true : false}
        variant='success'
      />
      <Toast
        autoHideDuration={5000}
        id='reset-password-failed'
        message={error ? error.message || error.error_message : null}
        onClick={() => setError(null)}
        onClose={() => {
          setError(null);
        }}
        open={error ? true : false}
        variant='error'
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    back: {
      textDecoration: 'none',
      textTransform: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    icon: {
      height: '15px',
      marginRight: theme.spacing(1),
      width: '15px !important'
    },
    paper: {
      maxWidth: 360
    },
    list: {
      fontSize: '0.875rem',
      margin: 0,
      padding: '0 0 0 1rem'
    }
  };
});
