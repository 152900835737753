import { Form, Formik } from 'formik';
import React, { FC, useState, useEffect } from 'react';
import { Button, Loader, Modal, Toast } from '../../../../../components';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import {
    Fade,
    Grid,
    TextField,
    FormControl,
    Typography,
    CardActions,
} from '@mui/material';
import { createBusinessClientWithMinimalData, getBusinessClientProjectTypes } from '../../../../../fetch';
import useRequestState from '../../../../../hooks/useRequestState';
import { IDropdownResponse, IMinimalAddBusinessClient } from '../../../../../models';
import { DatePicker } from '../../../../../components';
import { Save } from '@mui/icons-material';
import * as Yup from 'yup';
import { SubmitStatus } from '../../../../../models/util';
import GenericDropdown from '../../../../../components/generic-dropdown/GenericDropdown';

interface IAddNewBusinessClientModal {
    open: boolean;
    onClose: () => void;
    onSave: (result?: string) => void;
}

const schema = Yup.object().shape({
    name: Yup.string().max(255, 'Max 255 characters').required('Required'),
    projectType: Yup.string().optional().nullable(),
    openEnrollmentEffectiveDate: Yup.string().optional().nullable(),
});


const hasErrorsOnResponse = (res: any) => {
    if (res && (typeof res === 'boolean' || typeof res === 'number')) {
        return false;
    }

    return true;
}

const getErrorMessageOnResponse = (res: any) => {

    if (res?.Errors && Object?.values(Object?.values(res?.Errors)?.[0])?.[0]) {
        // validation errors
        return Object.values(Object.values(res.Errors)[0])[0] as string;
    }
    if (res?.Detail) {
        return res?.Detail;
    }
};

const AddNewBusinessClientModal: FC<IAddNewBusinessClientModal> = ({ open, onClose, onSave }) => {
    const initialValues: IMinimalAddBusinessClient = { name: '', projectType: null, openEnrollmentEffectiveDate: null };
    const classes = useStyles();
    const { isLoading: isLoadingProjectTypes, data: projectTypes, request } = useRequestState<IDropdownResponse[]>();


    const [submitStatus, setSubmitStatus] = useState<SubmitStatus>({ status: 'idle' });

    useEffect(() => {
        const fetchProjectTypes = async () => {
            try {
                const res = await getBusinessClientProjectTypes();
                return res;
            } catch (error) {
                console.error(error);
            }
        };

        request(fetchProjectTypes);
    }, []);

    return <>
        <Toast id='business-client-success' autoHideDuration={6000}
            message={`Business Client Added!`}
            open={submitStatus.status === 'success'} variant='success' onClose={() => { }} />
        <Toast
            id='business-client-error'
            autoHideDuration={6000}
            message={
                submitStatus.errorMessage ?? 'We were unable to create the business client at this time. Please try again later. Please contact Enrollment Alliance support if this issue continues.'
            }
            open={submitStatus.status === 'error'}
            onClose={() => { }}
            variant='error'
        />
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={async (values, actions) => {

                setSubmitStatus({ status: 'submitting' });

                const res = await createBusinessClientWithMinimalData(values);
                const hasErrors = hasErrorsOnResponse(res);

                if (hasErrors) {
                    const errorMessage = getErrorMessageOnResponse(res);
                    setSubmitStatus({ status: 'error', errorMessage: errorMessage });
                    return;
                }

                setSubmitStatus({ status: 'success' });
                actions.resetForm();

                onClose();
                onSave(values.name);

            }}
            validationSchema={schema}

        >
            {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, touched, handleSubmit, dirty, isValid, handleBlur }) => {
                return (<Modal
                    title='ADD NEW BUSINESS CLIENT'
                    open={open}
                    onClose={() => {
                        setSubmitStatus({ status: 'idle' });
                        resetForm();
                        onClose()
                    }}
                    maxWidth='md'
                >
                    <Fade in={open}>
                        <Form onSubmit={handleSubmit} autoComplete='none'>
                            {isSubmitting && <Loader type='fullscreen' position='centered' />}
                            <div className={classes.content}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            size='small'
                                            autoComplete='nope'
                                            label='Company Name'
                                            name='name'
                                            data-cy="name"
                                            value={values.name}
                                            onBlur={handleBlur}
                                            onChange={e => setFieldValue('name', e.target.value)}
                                            error={Boolean(touched && errors.name)}
                                            helperText={touched && errors.name}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <GenericDropdown
                                            handleBlur={handleBlur}
                                            options={projectTypes ?? []}
                                            isLoading={isLoadingProjectTypes}
                                            setFieldValue={setFieldValue}
                                            value={values?.projectType}
                                            error={errors.projectType as string}
                                            label='Project Type'
                                            id={`projectType`}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormControl fullWidth variant='outlined'>
                                            <DatePicker
                                                data-cy="openEnrollmentEffectiveDate"
                                                label='Effective Date'
                                                value={values.openEnrollmentEffectiveDate ? new Date(values.openEnrollmentEffectiveDate) : null}
                                                renderInput={props => (
                                                    <TextField {...props} name="openEnrollmentEffectiveDate" size='small' label='Effective Date' value={values.openEnrollmentEffectiveDate ? new Date(values.openEnrollmentEffectiveDate) : null} />
                                                )}
                                                onChange={(date: Date) => {
                                                    setFieldValue(`openEnrollmentEffectiveDate`, date?.toISOString?.() ? date?.toISOString() : '');
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>


                                </Grid>
                                <div className={classes.buttonsContainer}>
                                    <Button
                                        className={classes.saveButton}
                                        disabled={!dirty || isSubmitting || !isValid}
                                        type='submit'
                                        startIcon={<Save />}
                                        variant='contained'
                                        color='primary'
                                        id='btn-new-business-client'
                                        data-cy="btn-new-business-client"
                                    >
                                        {'Save'}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Fade>

                </Modal>)
            }}
        </Formik></>
}

const useStyles = makeStyles((theme: Theme) => ({
    buttonsContainer: {
        marginTop: theme.spacing(1)
    },
    saveButton: {
        '@media (max-width: 450px)': {
            width: '100%'
        }
    },
    primaryHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginBottom: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        '& > div:not(:first-of-type)': {
            marginTop: theme.spacing(1)
        }
    },
    outlinedLabel: {
        backgroundColor: theme.palette.common.white,
        paddingLeft: 2,
        paddingRight: 2
    },
    content: {
        marginTop: theme.spacing(1)
    },
    cardContent: {
        paddingTop: 0
    },
    paperBorder: {
        border: `1px solid ${theme.palette.grey[300]}`
    }
}));

export default AddNewBusinessClientModal;