import React from 'react';
import {
  Box,
  Grid,
  TextField,
  FormLabel as MuiLabel,
  InputLabel,
  FormControlLabel,
  FormControl,
  Switch,
  Checkbox
} from '@mui/material';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useInsuranceCoverageOptionsDropdownCollection from '../../../hooks/dropdown-collection/useInsuranceCoverageOptionsDropdownCollection';
import { ILongTermDisabilityCoverage } from '../../../models';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useDefaultDropdownsOptionsDropdownCollection from '../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface ILongTermDisabilityProps {
  data: ILongTermDisabilityCoverage;
  handleBlur: any;
  setFieldValue: (field: string, value: any) => void;
  errors: any;
  touched: any;
}

const LongTermDisabilityCoverage: React.FC<ILongTermDisabilityProps> = ({
  data,
  handleBlur,
  setFieldValue,
  errors,
  touched
}) => {
  const classes = useSharedStyles();

  const insuranceCoverageOptionsRequest =
    useInsuranceCoverageOptionsDropdownCollection();
  const insuranceCoverageOptions = insuranceCoverageOptionsRequest.data;
  const insuranceCoverageOptionsLoading =
    insuranceCoverageOptionsRequest.status === 'loading';

  const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
  const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
  const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';

  const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
  const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
  const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

  return (
    <Grid container spacing={1}>
      <Grid container spacing={1}>
        <Grid item marginLeft={1}>
          <Box marginBottom="1rem">
            <MuiLabel
              color="primary"
              className={classes.formLabel}
              sx={{ marginRight: '1rem' }}
            >
              Long Term Disability Coverage
            </MuiLabel>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={data?.offerLongTermDisabilityCoverage}
                  id="longTermDisabilityCoverage.offerLongTermDisabilityCoverage"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(
                      'longTermDisabilityCoverage.offerLongTermDisabilityCoverage',
                      e.target.checked
                    );
                  }}
                  onBlur={handleBlur}
                />
              }
              label={
                data?.offerLongTermDisabilityCoverage ? 'Offered' : 'Not offered'
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <PerformantTextField
          fullWidth
          variant="outlined"
          size="small"
          label="LTD Carrier"
          name="longTermDisabilityCoverage.ltdCarrier"
          value={data?.ltdCarrier}
          onChange={(e) => {
            setFieldValue(
              'longTermDisabilityCoverage.ltdCarrier',
              e.target.value
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <PerformantTextField
          fullWidth
          variant="outlined"
          size="small"
          label="Previous LTD Carrier"
          name="longTermDisabilityCoverage.previousLTDCarrier"
          value={data?.previousLTDCarrier}
          onChange={(e) => {
            setFieldValue(
              'longTermDisabilityCoverage.previousLTDCarrier',
              e.target.value
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControlLabel
          value={data?.areMultiplePlansAvailable}
          control={
            <Checkbox
              color='primary'
              checked={data?.areMultiplePlansAvailable}
              name='longTermDisabilityCoverage.areMultiplePlansAvailable'
              onChange={(_, checked: boolean) => setFieldValue('longTermDisabilityCoverage.areMultiplePlansAvailable', checked)}
            />
          }
          label={'Are there multipe plans/classes?'}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={insuranceCoverageOptions?.defaultPlanOfferOptions ?? []}
          isLoading={insuranceCoverageOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdOffering ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdOffering}
          label="LTD Offering"
          id="longTermDisabilityCoverage.ltdOffering"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={defaultDropdownsOptions?.defaultPreExRulesOptions ?? []}
          isLoading={defaultDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdPreExRules ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdPreExRules}
          label="LTD Pre-Ex Rules"
          id="longTermDisabilityCoverage.ltdPreExRules"
        />
      </Grid>



      <Grid item xs={12} sm={6} md={4}>
        <PerformantTextField
          fullWidth
          variant="outlined"
          size="small"
          label="Class(es) Eligible"
          name="longTermDisabilityCoverage.eligibleClasses"
          value={data?.eligibleClasses}
          onChange={(e) => {
            setFieldValue(
              'longTermDisabilityCoverage.eligibleClasses',
              e.target.value
            );
          }}
        />
      </Grid>






      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={generalDropdownsOptions?.stdCoverageClassPolicyTypes ?? []}
          isLoading={generalDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdPolicyType ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdPolicyType}
          label='LTD Policy Type'
          id='longTermDisabilityCoverage.ltdPolicyType'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
          isLoading={defaultDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdPortability ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdPortability}
          label='LTD Portability'
          id='longTermDisabilityCoverage.ltdPortability'
        />
      </Grid>


      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
          isLoading={defaultDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdgiEligibility ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdgiEligibility}
          label='LTD GI Eligibility'
          id='longTermDisabilityCoverage.ltdgiEligibility'
        />

      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={generalDropdownsOptions?.stdBuyUpOptions ?? []}
          isLoading={generalDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdBuyUpOptions ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdBuyUpOptions}
          label='LTD Buy-Up Options'
          id='longTermDisabilityCoverage.ltdBuyUpOptions'
        />


      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={generalDropdownsOptions?.creditForTimeServedOptions ?? []}
          isLoading={generalDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdCreditForTimeServed ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdCreditForTimeServed}
          label='LTD Credit For Time Served?'
          id='longTermDisabilityCoverage.ltdCreditForTimeServed'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <PerformantTextField
          fullWidth
          variant="outlined"
          size="small"
          label="LTD Max Monthly Benefit"
          name="longTermDisabilityCoverage.ltdMaxMonthlyBenefit"
          value={data?.ltdMaxMonthlyBenefit}
          onChange={(e) => {
            setFieldValue(
              'longTermDisabilityCoverage.ltdMaxMonthlyBenefit',
              e.target.value
            );
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={defaultDropdownsOptions?.defaultPaymentTypeOptions ?? []}
          isLoading={defaultDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.ltdPaymentType ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdPaymentType}
          label='LTD Payment Type'
          id='longTermDisabilityCoverage.ltdPaymentType'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={defaultDropdownsOptions?.defaultEliminationAccumulationPeriodsOptions ?? []}
          isLoading={defaultDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.eliminationAccumulationPeriods ?? ''}
          error={errors?.longTermDisabilityCoverage?.eliminationAccumulationPeriods}
          label="Elimination/Accumulation Periods"
          id="longTermDisabilityCoverage.eliminationAccumulationPeriods"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={[
            {
              description:
                "Pre-Tax",
              shorthand
                :
                "Pre-Tax",
              value
                :
                "PreTax"
            }, {
              description:
                "Post Tax",
              shorthand
                :
                "Post Tax",
              value
                :
                "PostTax"
            }]} // Replace with appropriate options
          isLoading={false}
          setFieldValue={setFieldValue}
          value={data?.ltdTaxStatus ?? ''}
          error={errors?.longTermDisabilityCoverage?.ltdTaxStatus}
          label='LTD Tax Status'
          id='longTermDisabilityCoverage.ltdTaxStatus'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <GenericDropdown
          handleBlur={handleBlur}
          options={generalDropdownsOptions?.stdCoverageTerminatesOn ?? []}
          isLoading={generalDropdownsOptionsLoading}
          setFieldValue={setFieldValue}
          value={data?.coverageTerminatesOn ?? ''}
          error={errors?.longTermDisabilityCoverage?.coverageTerminatesOn}
          label='If an employee is terminated, when should benefits be terminated?'
          id='longTermDisabilityCoverage.coverageTerminatesOn'
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <PerformantTextField
          fullWidth
          multiline
          variant="outlined"
          size="small"
          label="LTD Notes"
          name="longTermDisabilityCoverage.ltdNotes"
          value={data?.ltdNotes}
          onChange={(e) => {
            setFieldValue(
              'longTermDisabilityCoverage.ltdNotes',
              e.target.value
            );
          }}
        />
      </Grid>

    </Grid>
  );
};

export default LongTermDisabilityCoverage;
