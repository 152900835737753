import { FC } from 'react';
import { ClientDetailsSubMenusTypes, TabOptionType } from '../../../../MenuData/types';
import { TabsBase } from '../../../base/tabs-base';


interface IClientDetailsTabsProps {
    validateForm: () => void;
}

const tabOptions: TabOptionType<ClientDetailsSubMenusTypes>[] = [
    {
        value: 0,
        label: 'Company Info',
        subMenuRelatedId: 'company_info'
    },
    {
        value: 1,
        label: 'Dates',
        subMenuRelatedId: 'dates'
    },
    {
        value: 2,
        label: 'Contacts',
        subMenuRelatedId: 'contacts'
    },
    {
        value: 3,
        label: 'Classes',
        subMenuRelatedId: 'classes'
    },
    {
        value: 4,
        label: 'Industry',
        subMenuRelatedId: 'industry'
    },
    {
        value: 5,
        label: 'Company Preferences',
        subMenuRelatedId: 'company_preferences'
    }
];

//'industry'
//'company_preferences'

export const ClientDetailsTabs: FC<IClientDetailsTabsProps> = ({ validateForm }) => {
    return <TabsBase validateForm={validateForm} tabOptions={tabOptions} />
}
