import React, { FC } from 'react';
import { SnackbarProps } from '@mui/material/Snackbar';
import { Toast } from './Toast';
import { Button } from '../../components';

interface IEmployeeWarningToastProps extends SnackbarProps {
  autoHideDuration?: number | null; // Null makes the alert sticky. Requires confirmation before it can be closed.
  message?: React.ReactNode | string;
  open: boolean;
  variant?: 'error' | 'info' | 'success' | 'warning';
  onConfirm: () => void;
}

const defaultWarningMessage = (
  <>
    <strong>Friendly Reminder:</strong> You are attempting to add new hires with an effective date that is less than 15 days away. This creates a challenge to connect with and
    enroll employees prior to their deadline. Please double check date of hire/effective dates to ensure all employees have the correct information uploaded. THANK YOU!
  </>
);

export const EmployeeWarningToast: FC<IEmployeeWarningToastProps> = ({
  autoHideDuration = null,
  message = defaultWarningMessage,
  open = false,
  variant = 'error',
  onConfirm,
  ...props
}) => {
  return (
    <Toast
      {...props}
      id='employees-warning'
      message={message}
      open={open}
      onClose={() => {
        //Do Nothing
      }}
      variant={variant}
      autoHideDuration={autoHideDuration}
      action={
        <Button id='confirm-employee-warning' color='inherit' size='small' onClick={onConfirm}>
          CONFIRM
        </Button>
      }
    />
  );
};
