import React from 'react';
import { FC } from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IPortableLifeInsuranceGuaranteedIssue } from '../../../../../../../../../models'; // Replace this with the actual model interface
import { useSharedStyles } from '../../../../../../shared/styles';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';


const PortableLifeCoverageGuaranteedIssuesTable: FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const portableLifeInsuranceGuaranteedIssues: IPortableLifeInsuranceGuaranteedIssue[] = values.portableLifeInsurance?.portableLifeInsuranceGuaranteedIssues ?? [];

    const portableLifeInsuranceGuaranteedIssuesErrors = errors.portableLifeInsurance?.portableLifeInsuranceGuaranteedIssues as FormikErrors<IPortableLifeInsuranceGuaranteedIssue>[];

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    return (
        <FieldArray name='portableLifeInsurance.portableLifeInsuranceGuaranteedIssues'>
            {({ push, remove }) => {
                return (
                    <div className={classes.container}>
                        {portableLifeInsuranceGuaranteedIssues.length > 0 &&
                            <Box sx={{ overflow: "auto" }}>
                                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                    <TableContainer component={Paper}>

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell className={classes.tableCell}>Guaranteed Issue Amount</TableCell>
                                                    <TableCell className={classes.tableCell}>Max Amount</TableCell>
                                                    <TableCell className={classes.tableCell}>Max Factor</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {portableLifeInsuranceGuaranteedIssues.length > 0 &&
                                                    portableLifeInsuranceGuaranteedIssues.map((item, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell className={classes.tableCell}>
                                                                    <Field name={`portableLifeInsurance.portableLifeInsuranceGuaranteedIssues.${index}.giAmount`}>
                                                                        {({ field }) => (
                                                                            <PerformantTextField
                                                                                {...field}
                                                                                fullWidth
                                                                                variant='outlined'
                                                                                size='small'
                                                                                autoComplete='nope'
                                                                                label='Guaranteed Issue Amount'
                                                                                value={item.giAmount}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FieldErrorMessage message={portableLifeInsuranceGuaranteedIssuesErrors?.[index]?.giAmount} />
                                                                </TableCell>

                                                                <TableCell className={classes.tableCell}>
                                                                    <GenericDropdown
                                                                        handleBlur={handleBlur}
                                                                        options={generalDropdownsOptions?.criticalIllnessMaxAmountOptions ?? []}
                                                                        isLoading={generalDropdownsOptionsLoading}
                                                                        setFieldValue={setFieldValue}
                                                                        value={item?.maxAmount ?? ''}
                                                                        error={portableLifeInsuranceGuaranteedIssuesErrors?.[index]?.maxAmount}
                                                                        label='Max Amount'
                                                                        id={`portableLifeInsurance.portableLifeInsuranceGuaranteedIssues.${index}.maxAmount`}
                                                                    />
                                                                </TableCell>

                                                                <TableCell className={classes.tableCell}>
                                                                    <GenericDropdown
                                                                        handleBlur={handleBlur}
                                                                        options={generalDropdownsOptions?.criticalIllnessMaxFactorOptions ?? []}
                                                                        isLoading={generalDropdownsOptionsLoading}
                                                                        setFieldValue={setFieldValue}
                                                                        value={item?.maxFactor ?? ''}
                                                                        error={portableLifeInsuranceGuaranteedIssuesErrors?.[index]?.maxFactor}
                                                                        label='Max Factor'
                                                                        id={`portableLifeInsurance.portableLifeInsuranceGuaranteedIssues.${index}.maxFactor`}
                                                                    />
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Button
                                                                        startIcon={<Close />}
                                                                        onClick={() => {
                                                                            remove(index);
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer></Box></Box>
                        }
                        {portableLifeInsuranceGuaranteedIssues.length < 3 && (
                            <Button
                                color='primary'
                                variant='contained'
                                className={classes.addClassButton}
                                onClick={() =>
                                    push({
                                        giAmount: undefined,
                                    })
                                }
                            >
                                Add Guaranteed Issue
                            </Button>
                        )}
                    </div>
                );
            }}
        </FieldArray>
    );
};

export default PortableLifeCoverageGuaranteedIssuesTable;