import React, { useEffect } from 'react';

import { FormikErrors, useFormikContext } from "formik";
import { IBusinessClientDetailFormValues } from '../../../../../models';
import { useMenuSubMenuWithErrors } from '../../../../../components/list-menu-sub-menu/contexts/MenuSubMenuWithErrorsContext';
import { AllBusinessClientSubMenusTypes, BusinessClientMenusTypes, MenuItem, MenuSubMenuType } from '../../BusinessClientDetailPage/MenuData/types';

import clientDetails from './validations/menus/client-details';
import companyInfo from './validations/sub-menus/client-details/company-info';
import dates from './validations/sub-menus/client-details/dates';
import classes from './validations/sub-menus/client-details/classes';

import productDetails from './validations/menus/product-details';
import medical from './validations/sub-menus/product-details/medical'
import financial from './validations/sub-menus/product-details/financial'
import ancillary from './validations/sub-menus/product-details/ancillary'
import worksite from './validations/sub-menus/product-details/worksite'
import supplemental from './validations/sub-menus/product-details/supplemental'

import postEnrollmentData from './validations/menus/post-enrollment-data';
import enrollmentEngagementNumbers from './validations/sub-menus/post-enrollment-data/enrollment_engagement_numbers'

import enrollmentStrategy from './validations/menus/enrollment-strategy';
import locationsAndLogistics from './validations/sub-menus/enrollment-strategy/locations_&_logistics'

import hrPreferences from './validations/menus/hr-preferences'
import campaignStrategy from './validations/sub-menus/hr-preferences/campaign_strategy'



const useMenuSubMenuFormErrorsSynchronizer = (menuData: MenuItem[]) => {
    const { setMenusWithErrors, setSubMenusWithErrors } = useMenuSubMenuWithErrors();
    const { errors } = useFormikContext<IBusinessClientDetailFormValues>();

    useEffect(() => {

        if (!errors) return;

        const menuTitles: BusinessClientMenusTypes[] = [];

        menuData.forEach((menuItem) => {
            const validateAction = menusSubMenusValidationStatusMap.get(menuItem.id);
            const IsThereSomethingInvalidInTheMenu = validateAction && validateAction(errors) === false;

            if (IsThereSomethingInvalidInTheMenu) {
                menuTitles.push(menuItem.id as BusinessClientMenusTypes)
            }

        });

        setMenusWithErrors(menuTitles);

        const subMenuTitles: AllBusinessClientSubMenusTypes[] = [];

        const submenus = menuData.flatMap((menuItem) =>
            menuItem.subMenu?.map(s => s) ?? []);

        submenus.forEach((item) => {
            const validateAction = menusSubMenusValidationStatusMap.get(item.id);
            const IsThereSomethingInvalidInTheSubMenu = validateAction && validateAction(errors) === false;

            if (IsThereSomethingInvalidInTheSubMenu) {
                subMenuTitles.push(item.id as AllBusinessClientSubMenusTypes)
            }

        });

        setSubMenusWithErrors(subMenuTitles)

        console.log('errors', errors)
    }, [errors])

};

const menusSubMenusValidationStatusMap: Map<MenuSubMenuType, (errors: FormikErrors<IBusinessClientDetailFormValues>) => boolean> = new Map();
menusSubMenusValidationStatusMap.set('client-details', clientDetails);
menusSubMenusValidationStatusMap.set('company_info', companyInfo);
menusSubMenusValidationStatusMap.set('dates', dates);
menusSubMenusValidationStatusMap.set('classes', classes);


menusSubMenusValidationStatusMap.set('product-details', productDetails);
menusSubMenusValidationStatusMap.set('medical', medical);
menusSubMenusValidationStatusMap.set('financial', financial);
menusSubMenusValidationStatusMap.set('ancillary', ancillary);
menusSubMenusValidationStatusMap.set('worksite', worksite);
menusSubMenusValidationStatusMap.set('supplemental', supplemental);

menusSubMenusValidationStatusMap.set('enrollment-strategy', enrollmentStrategy);
menusSubMenusValidationStatusMap.set('locations_&_logistics', locationsAndLogistics);

menusSubMenusValidationStatusMap.set('post-enrollment-data', postEnrollmentData);
menusSubMenusValidationStatusMap.set('enrollment_engagement_numbers', enrollmentEngagementNumbers);

menusSubMenusValidationStatusMap.set('hr-preferences', hrPreferences);
menusSubMenusValidationStatusMap.set('campaign_strategy', campaignStrategy);

export { menusSubMenusValidationStatusMap }


export default useMenuSubMenuFormErrorsSynchronizer;