import { FC } from 'react';
import { Field, FieldArray } from 'formik';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IMedicalInsurancePlan } from '../../../models';
import { useSharedStyles } from './shared/styles';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';


interface IBusinessClientMedicalPlansTableProps {
    medicalPlans: IMedicalInsurancePlan[];
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
}

const BusinessClientMedicalPlansTable: FC<IBusinessClientMedicalPlansTableProps> = ({ medicalPlans, handleBlur, setFieldValue, values, errors }) => {
    const classes = useSharedStyles();

    return (<FieldArray name='medicalPlans' >
        {({ push, remove }) => {
            return (
                <div className={classes.container}>
                    {medicalPlans.length > 0 ? <TableContainer component={Paper}>
                        <Table  >
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}>Plan Name</TableCell>
                                    <TableCell className={classes.tableCell}>PCP Required</TableCell>
                                    <TableCell className={classes.tableCell}>Most Similar Previous Plan</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>



                                {medicalPlans.length > 0 &&
                                    medicalPlans.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableCell}>{index + 1}</TableCell>

                                                <TableCell className={classes.tableCell}>
                                                    <Field name={`medicalPlans.${index}.planName`}>
                                                        {({ field }) => {
                                                            return (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Plan Name'
                                                                    name={`medicalPlans.${index}.planName`}
                                                                    value={item.planName}
                                                                    onBlur={handleBlur}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                    <FieldErrorMessage message={errors.medicalPlans?.[index]?.planName} />
                                                </TableCell>
                                                <TableCell align='center'>

                                                    <Checkbox
                                                        color="primary"
                                                        onBlur={handleBlur}
                                                        checked={item.pcpRequired}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        name={`medicalPlans.${index}.pcpRequired`}
                                                        onChange={(_, checked: boolean) =>
                                                            setFieldValue(`medicalPlans.${index}.pcpRequired`, checked)}
                                                    />
                                                    <FieldErrorMessage message={errors.medicalPlans?.[index]?.pcpRequired} />
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>

                                                    <Field name={`medicalPlans.${index}.mostSimilarPreviousPlan`}>
                                                        {({ field }) => {
                                                            return (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Most Similar Previous Plan'
                                                                    name={`medicalPlans.${index}.mostSimilarPreviousPlan`}
                                                                    value={item.mostSimilarPreviousPlan}
                                                                    onBlur={handleBlur}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                    <FieldErrorMessage message={errors.medicalPlans?.[index]?.mostSimilarPreviousPlan} />
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <Button
                                                        className={classes.removeButton}
                                                        startIcon={<Close />}
                                                        onClick={() => {
                                                            remove(index);
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer> : <></>}


                    {medicalPlans.length < 4 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    planName: '',
                                    pcpRequired: false,
                                    mostSimilarPreviousPlan: ''
                                })
                            }
                        >
                            Add Plan
                        </Button>
                    )}
                </div>
            );
        }}
    </FieldArray>
    )
};

export default BusinessClientMedicalPlansTable;