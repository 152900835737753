import { format, parseISO } from 'date-fns';
import { IBuildStep } from '../models';

export const formatInputPhoneNumber = (val: string | undefined) => {
  let number = val?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  // taken from here, https://stackoverflow.com/questions/17651207/mask-us-phone-number-string-with-javascript
  return number && (!number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`);
};

export const phoneRegExp = /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;
export const passwordRegex = new RegExp(/^(?=.*[A-Z])(?=.*[A-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])\S{6,20}$/);
// eslint-disable-next-line
export const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

// append https:// if the url doesn't have it
export const formatUrl = (text?: string, protocol: 'http' | 'https' = 'https'): string => {
  if (text && !text.startsWith('https://') && !text.startsWith('http://')) {
    return `${protocol}://${text}`;
  }
  return text;
};

/**
 * Format date into a short friendly date with time
 * @example 1/6/2020 2:00pm
 * @example 12/12/2020 12:00am
 * @param date Date | number | string
 * @returns string
 */
export const formatShortFriendlyDateWithTime = (date: Date | number | string, timeZone = 'EST'): string => {
  // need to append "Z" to the end of the date string so true UTC is reflected and we show the correct zone
  const parsedDate: Date | number | string = typeof date === 'string' ? parseISO(`${date}Z`) : date;
  // Make sure the value is a date
  var timeZoneDate = new Date(parsedDate);
  // Convert from string back to a date
  const newTimeZoneDate = new Date(timeZoneDate.toLocaleString('en-US', { timeZone: timeZone }));
  return format(newTimeZoneDate, 'L/d/yyyy h:mma');
};

/**
 * Format date string to Date
 * @example "2020-06-09T00:00:00+00:00" => Tue Jun 09 2020 00:00:00 GMT-0400
 * @param date string
 * @returns Date
 */
export const stringToDate = (date: string): Date => {
  const d = date.split('T')[0].split('-');
  // Months are 0 index so subtract 1
  return new Date(+d[0], +d[1] - 1, +d[2]);
};

/**
 * Format date
 * @example 1/6/2020
 * @example 12/12/2020
 * @param date Date | number | string
 * @returns string
 */
export const formatDate = (date: Date | number | string | null): string | null => {
  if (date) {
    const stringDate: Date | number = typeof date === 'string' ? stringToDate(date) : date;
    return format(stringDate, 'L/d/yyyy');
  }
  return null;
};

/**
 * Formats a string or number into USD
 * @example formatMoney(1) => $1.00
 * @example formatMoney(0) => $0.00
 * @example formatMoney('1.99') => $1.99
 * @example formatMoney('9999.99') => $9,999.99
 * @example formatMoney(undefined) => $0.00
 * @example formatMoney(null) => $0.00
 * @param value number | string | undefined | null
 * @param digits number | undefined
 * @returns string
 */
export const formatMoney = (value: number | string | undefined | null, digits = 2): string => {
  let amount = 0;

  if (value) {
    if (typeof value === 'string') {
      // strip out any commas or dollar signs so that $9,999.99 passes !isNaN test
      value = value.includes(',') || value.includes('$') ? value.replace(',', '').replace('$', '') : value;
      // make sure the string is a number
      if (!isNaN(value as unknown as number)) {
        amount = Number(value);
      }
    } else if (typeof value === 'number' && value > 0) {
      amount = value;
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: digits, minimumFractionDigits: digits, style: 'currency', currency: 'USD' }).format(amount);
};

export const formatDueDateKey = (step: IBuildStep) => {
  // this turns this, i.e. `ProductOverview` into `productOverviewDate` to match the correct key for the
  // target date endpoint
  let key = `${step.buildStep.charAt(0).toLowerCase() + step.buildStep.slice(1)}Date`;

  if (step.buildStep === 'HROverview') {
    key = 'hrOverviewDate';
  }
  if (step.buildStep === 'CensusProfile') {
    key = 'censusDate';
  }
  return key;
};

export const getBuildStepOptions = (projectType: string, steps: IBuildStep[]) => {
  // filter out product overview and hr overview for this selection
  if (projectType === 'CommunicationOnly') {
    return steps.filter(p => p.buildStep !== 'ProductOverview' && p.buildStep !== 'HROverview');
  }
  // filter out enrollment overview and hr overview and communication overview for this selection
  if (projectType === 'TechOnly') {
    return steps.filter(p => p.buildStep !== 'EnrollmentOverview' && p.buildStep !== 'HROverview' && p.buildStep !== 'Communication Overview');
  }
  // filter out communication overview and hr overview for this selection
  if (projectType === 'EnrollmentOnly') {
    return steps.filter(p => p.buildStep !== 'CommunicationOverview');
  }
  // filter out he overview for this selection
  if (projectType === 'TechAndCommunication') {
    return steps.filter(p => p.buildStep !== 'HROverview');
  }
  // filter out communication overview for this selection
  if (projectType === 'TechAndEnrollment') {
    return steps.filter(p => p.buildStep !== 'CommunicationOverview');
  }
  return steps;
};

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
