import { Alert } from '@mui/material';
import { useSharedStyles } from '../../../../../shared/styles';
import { Accordion } from '../../../../../../../../components';
import HealthSavingsAccount from './HealthSavingsAccount/HealthSavingsAccount';
import HealthReimbursementAccount from './HealthReimbursementAccount/HealthReimbursementAccount';
import FlexibleSpendingAccount from './FlexibleSpendingAccount/FlexibleSpendingAccount';
import DependentCareFlexSpendingAccount from './DependentCareFlexSpendingAccount/DependentCareFlexSpendingAccount';
import LimitedPurposeFlexSpendingAccount from './LimitedPurposeFlexSpendingAccount/LimitedPurposeFlexSpendingAccount';
import BusinessClientTabContentBase from '../../../../../../../../components/business-client-tab-content-base';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { useLateralFormMenuControl } from '../../../../../contexts/LateralFormMenuControlContext';

export default function Financial() {

    const { values, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('financial');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    const { selectAccordionCategory } = useLateralFormMenuControl();

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <BusinessClientTabContentBase benefitsList={[
                {
                    benefitName: 'Health Savings Account',
                    offered: values.healthSavingsAccount?.offerHealthSavingsAccount === true
                },
                {
                    benefitName: 'Health Reimbursement Account',
                    offered: values.healthReimbursementAccount?.offerHealthReimbursementAccount === true
                },
                {
                    benefitName: 'Flexible Spending Account',
                    offered: values.flexibleSpendingAccount?.offerFlexibleSpendingAccount === true
                },
                {
                    benefitName: 'Dependent Care Flex Spending Account',
                    offered: values.dependentCareFlexSpendingAccount?.offerDependentCareFlexSpendingAccount === true
                },
                {
                    benefitName: 'Limited Purpose Flex Spending Account',
                    offered: values.limitedPurposeFlexSpendingAccount?.offerLimitedPurposeFlexSpendingAccount === true
                }
            ]} >


                <Accordion defaultExpanded={!selectAccordionCategory || selectAccordionCategory === 'HealthSavingsAccount'} hasErrors={errors?.healthSavingsAccount != undefined} title='Health Savings Account' component='h3' variant='h4'>
                    <HealthSavingsAccount />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'HealthReimbursementAccount'} hasErrors={errors?.healthReimbursementAccount != undefined} title='Health Reimbursement Account' component='h3' variant='h4'>
                    <HealthReimbursementAccount />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'FlexibleSpendingAccount'}  hasErrors={errors?.flexibleSpendingAccount != undefined} title='Flexible Spending Account' component='h3' variant='h4'>
                    <FlexibleSpendingAccount />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'DependentCareFlexSpendingAccount'}  hasErrors={errors?.dependentCareFlexSpendingAccount != undefined} title='Dependent Care Flex Spending Account' component='h3' variant='h4'>
                    <DependentCareFlexSpendingAccount />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'LimitedPurposeFlexSpendingAccount'}  hasErrors={errors?.limitedPurposeFlexSpendingAccount != undefined} title='Limited Purpose Flex Spending Account' component='h3' variant='h4'>
                    <LimitedPurposeFlexSpendingAccount />
                </Accordion>

            </BusinessClientTabContentBase>

        </div>
    );
}
