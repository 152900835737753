import { Button, Grid, TextField, InputAdornment } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { QRCode } from '../../../models';
import { formLabel } from './shared/styles';

interface IMobileAppInfoTab {
  qrCode: QRCode;
  appToggle: boolean;
  handleLinkChange: (val: string) => void;
}

export const QRCodeLink: FC<IMobileAppInfoTab> = ({ qrCode, appToggle, handleLinkChange }) => {
  const classes = useStyles();
  const disableFields = appToggle ? false : true;

  const [isCopied, setCopied] = useState(false);
  return (
    <>
      <Grid item xs={12} md={6} className={classes.qrCode}>
        <TextField
          id={qrCode.name}
          variant='outlined'
          size='small'
          fullWidth
          disabled={disableFields}
          defaultValue={qrCode.name}
          InputProps={{
            readOnly: true
          }}
        />
        <Button
          className={classes.qrCodeButton}
          variant='contained'
          color='primary'
          disabled={disableFields}
          onClick={() => {
            navigator.clipboard.writeText(qrCode.link);
            setCopied(true);
            // reset the boolen prop after a couple sections
            setTimeout(() => {
              setCopied(false);
            }, 1500);
          }}
        >
          {isCopied ? 'Copied!' : 'Copy'}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} className={classes.qrCode}>
        <TextField
          id={qrCode.link}
          variant='outlined'
          size='small'
          fullWidth
          disabled={disableFields}
          value={qrCode.url || ''}
          label='URL'
          InputProps={{
            startAdornment: <InputAdornment className={classes.qrAdornment} position='start'>{`${qrCode.link.split('.com/')[0]}.com/`}</InputAdornment>
          }}
          onChange={e => {
            handleLinkChange(e.target.value);
          }}
        />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  qrCode: {
    display: 'flex',
    alignItems: 'center'
  },
  qrCodeButton: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    flexBasis: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formLabel: formLabel,
  radioGroupLabel: { marginBottom: theme.spacing(0.5) },
  qrAdornment: {
    marginRight: 1
  }
}));
