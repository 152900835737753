import { Box, FormLabel as MuiLabel, FormControlLabel, Grid, Switch } from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues, IFaceToFaceEnrollmentDetails } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import { useSharedStyles } from '../../../../../../shared/styles';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';

export default function FaceToFaceEnrollment() {

    const { values, setFieldValue, handleBlur, errors } = useFormikContext<IBusinessClientDetailFormValues>();
    const classes = useSharedStyles();

    const faceToFaceEnrollmentErrors = errors.enrollmentSchedulingTools as FormikErrors<IFaceToFaceEnrollmentDetails>;

    return (
        <Grid container spacing={1}>

            <Grid container spacing={1} >
                <Grid item marginLeft={1} marginTop={.7}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            If enrolling face-to-face, are there multiple locations that Enrollment Alliance will need to visit?
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={values.faceToFaceEnrollment?.multipleLocationsRequired}
                                    id='faceToFaceEnrollment.multipleLocationsRequired'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('faceToFaceEnrollment.multipleLocationsRequired', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={values.faceToFaceEnrollment?.multipleLocationsRequired ? 'Yes' : 'No'}
                        />
                    </Box>

                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Notes About Enrollment Logistics'
                    name='faceToFaceEnrollment.notesAboutEnrollmentLogistics'
                    value={values.faceToFaceEnrollment?.notesAboutEnrollmentLogistics}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('faceToFaceEnrollment.notesAboutEnrollmentLogistics', e.target.value)}
                />
                <FieldErrorMessage message={faceToFaceEnrollmentErrors?.notesAboutEnrollmentLogistics} />
            </Grid>



        </Grid>

    )
}