import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';

export default function LanguageRequirements() {

    const { values, setFieldValue, handleBlur, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const areAnyOtherLanguagesNeeded = values.businessClientLocationAndLogistic?.areAnyOtherLanguagesNeeded;

    const isThereNeedForOtherLanguages = values.businessClientLocationAndLogistic?.isThereNeedForOtherLanguages;

    return (
        <Grid container spacing={1}>

            <Grid item xs={12} sm={6} md={6}>
                <FormControlLabel
                    value={values.businessClientLocationAndLogistic?.isThereNeedForOtherLanguages}
                    control={
                        <Checkbox
                            color='primary'
                            checked={values.businessClientLocationAndLogistic?.isThereNeedForOtherLanguages}
                            name='businessClientLocationAndLogistic.isThereNeedForOtherLanguages'
                            onChange={(_, checked: boolean) => setFieldValue('businessClientLocationAndLogistic.isThereNeedForOtherLanguages', checked)}
                        />
                    }
                    label={'Is there a need for any languages other than English?'}
                />
            </Grid>

            {isThereNeedForOtherLanguages &&
                <>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControlLabel
                            value={values.businessClientLocationAndLogistic?.isThereNeedForSpanish}
                            control={
                                <Checkbox
                                    color='primary'
                                    checked={values.businessClientLocationAndLogistic?.isThereNeedForSpanish}
                                    name='businessClientLocationAndLogistic.isThereNeedForSpanish'
                                    onChange={(_, checked: boolean) => setFieldValue('businessClientLocationAndLogistic.isThereNeedForSpanish', checked)}
                                />
                            }
                            label={'Is there a need for Spanish?'}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <FormControlLabel
                            value={values.businessClientLocationAndLogistic?.areAnyOtherLanguagesNeeded}
                            control={
                                <Checkbox
                                    color='primary'
                                    checked={values.businessClientLocationAndLogistic?.areAnyOtherLanguagesNeeded}
                                    name='businessClientLocationAndLogistic.areAnyOtherLanguagesNeeded'
                                    onChange={(_, checked: boolean) => setFieldValue('businessClientLocationAndLogistic.areAnyOtherLanguagesNeeded', checked)}
                                />
                            }
                            label={'Are any other languages needed?'}
                        />
                    </Grid>

                    {areAnyOtherLanguagesNeeded && <Grid item xs={12} sm={12} md={12}>
                        <PerformantTextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            autoComplete='nope'
                            label='Additional languages:'
                            name='businessClientLocationAndLogistic.additionalLanguages'
                            value={values.businessClientLocationAndLogistic?.additionalLanguages}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('businessClientLocationAndLogistic.additionalLanguages', e.target.value)}
                        />
                        <FieldErrorMessage message={errors.businessClientLocationAndLogistic?.additionalLanguages} />
                    </Grid>}</>
            }


        </Grid>

    )
}