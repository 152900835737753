import React, { FC, useContext } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, Link as MLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
// Components
import { AppBar, Button, Grid, Divider, MenuItem, Menu, MenuList } from '@mui/material';
import { Logo } from './Logo';
import { BrokerLogo } from './BrokerLogo';
import { Link } from '../link';
import { MobileDrawer } from './MobileDrawer';
// Icons
import { HomeOutlined, ExitToAppOutlined, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { userLogout } from '../../helpers';
// context
import { UserContext } from '../../context';
import { TimeZoneContext } from '../../context/timezone';

export const Header: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const classes = useStyles();

  const { setUser, user } = useContext(UserContext);
  const { timeZone, setTimeZone } = useContext(TimeZoneContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const timeZones = [
    { name: 'EST', tzName: 'America/New_York' },
    { name: 'CST', tzName: 'America/Chicago' },
    { name: 'MST', tzName: 'America/Phoenix' },
    { name: 'PST', tzName: 'America/Los_Angeles' }
  ];

  return (
    <>
      <AppBar elevation={0} position='relative' className={classes.topHeader}>
        <Grid className={classes.appBarGrid} container direction='row' justifyContent='space-between' alignItems='center'>
          <div className={classes.logosWrapper}>
            <MLink to='/'>
              <Logo className={classes.logo} isSmall />
            </MLink>
            {user && user.brokerLogo && (
              <>
                <Divider orientation='vertical' className={classes.divider} flexItem />
                <MLink
                  className={classes.brokerLogoLink}
                  to={user && user.businessClientIds && user.businessClientIds.length === 1 ? `/business-clients/${user.businessClientIds[0]}/builder` : '/'}
                >
                  <BrokerLogo className={classes.brokerLogo} src={user.brokerLogo} isSmall />
                </MLink>
              </>
            )}
          </div>

          <div className={classes.menuOptionsWrapper}>
            <div className={clsx(classes.tzDropdownContainer)}>
              <Button
                size='large'
                startIcon={open ? <ArrowDownward fontSize='large' /> : <ArrowUpward fontSize='large' />}
                className={clsx(classes.menuButton)}
                id='timeZone-button'
                aria-controls={open ? 'time-zone-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                {/* The Default is EST */}
                {timeZone ? `Time Zone: ${timeZones.filter(tz => tz.tzName === timeZone)[0].name}` : `Time Zone: EST`}
              </Button>
              <Menu
                id='time-zone-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuList style={{ width: 200, maxWidth: '100%' }}>
                  {timeZones.map(timeZone => (
                    <MenuItem
                      className={classes.tzMenuItem}
                      key={timeZone.name}
                      onClick={() => {
                        setTimeZone(timeZone.tzName);
                        handleClose();
                      }}
                    >
                      {timeZone.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
            <Link to={user && user.businessClientIds && user.businessClientIds.length === 1 ? `/business-clients/${user.businessClientIds[0]}/builder` : '/'} type='white'>
              <Button size='large' startIcon={<HomeOutlined fontSize='large' />} className={clsx(classes.menuButton, pathname === '/' && 'active')}>
                Home
              </Button>
            </Link>
            <Button
              size='large'
              onClick={async () => {
                await userLogout();
                setUser(null);
                history.push('/login');
              }}
              startIcon={<ExitToAppOutlined fontSize='large' />}
              className={clsx(classes.menuButton, pathname === '/logout' && 'active')}
            >
              Logout
            </Button>
          </div>
          <MobileDrawer user={user} />
        </Grid>
      </AppBar>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  topHeader: {
    height: 78,
    boxShadow: '0px 2px 7.2px 0px rgba(0, 0, 0, 0.1)',
    '@media (min-width: 912px)': {
      height: 100
    },
    backgroundColor: '#262626'
  },
  logo: {
    marginTop: 5,
    marginRight: 16,
    marginLeft: 8
  },
  brokerLogo: {
    paddingLeft: theme.spacing(1),
    width: 'auto',
    maxWidth: 90,
    height: 'auto',
    maxHeight: 64
  },
  appBarGrid: {
    height: '100%',
    paddingLeft: theme.spacing(0.5),
    '@media (min-width: 912px)': {
      paddingLeft: 0
    }
  },
  menuOptionsWrapper: {
    alignItems: 'center',
    display: 'none',
    '@media (min-width: 912px)': {
      display: 'flex'
    }
  },
  tzMenuItem: {
    fontSize: theme.spacing(1.25)
  },
  tzDropdownContainer: {},
  tzDropdown: {
    backgroundColor: theme.palette.common.white
  },
  logosWrapper: {
    alignItems: 'center',
    display: 'flex'
  },
  mobileDrawer: {
    alignItems: 'left'
  },
  divider: {
    backgroundColor: theme.palette.grey[700]
  },
  menuButton: {
    height: 78,
    borderRadius: 0,
    boxShadow: 'none',
    letterSpacing: theme.spacing(0.2),
    color: theme.palette.background.paper,
    fontSize: theme.spacing(1.25),
    padding: theme.spacing(1, 0.75),
    fontWeight: 100,
    textTransform: 'uppercase',
    borderBottom: '4px solid #262626',
    '&:hover': {
      color: theme.palette.primary.light,
      borderBottom: `4px solid ${theme.palette.primary.light}`
    },
    '&.active': {
      color: theme.palette.primary.main,
      borderBottom: `4px solid ${theme.palette.primary.main}`
    },
    '@media (min-width: 912px)': {
      height: 99,
      padding: theme.spacing(1, 1.5)
    }
  },
  brokerLogoLink: {
    lineHeight: 0
  }
}));
