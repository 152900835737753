import React, { FC, useState, useContext, useEffect } from 'react';
import { Formik, FormikHelpers, Field, FieldProps, FormikProps, Form } from 'formik';
import { useLocation, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
// Components
import { Toast, Button, MinimalPage } from '../../components';
import { FormControl, TextField } from '@mui/material';
// Fetch
import { setupPassword } from '../../fetch';
// Context
import { UserContext } from '../../context';
// Helpers
import { isValidPassword, PasswordRequirements } from '../../helpers';

interface ISetupPasswordProps {}

interface ISetupPasswordValues {
  email: string;
  newPassword: string;
  confirmPassword: string;
}

const SetupPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').required('Required'),
  newPassword: Yup.string().required('Required'),
  confirmPassword: Yup.string().required('Required')
});

export const SetupPassword: FC<ISetupPasswordProps> = () => {
  const { search } = useLocation();

  const [token, setToken] = useState<string>('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<any>(null);

  // Signifies that we have reset the password and can redirect,
  // otherwise we would redirect a user who MAY be logged in but also
  // accessing setup password for someone else
  const [hasReset, setHasReset] = useState<boolean>(false);

  const { user, isFetching, setUser } = useContext(UserContext);

  useEffect(() => {
    document.title = `Setup Password | Enrollment Alliance`;

    const tokenCode = search.split('?token=')[1]; // Remove token query string and return exact remaining from URL, don't decode
    setToken(tokenCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return null;
  }

  if (user && !isFetching && hasReset) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          newPassword: '',
          confirmPassword: ''
        }}
        onSubmit={async (values: ISetupPasswordValues, actions: FormikHelpers<ISetupPasswordValues>) => {
          try {
            const res: any = await setupPassword({ email: values.email, newPassword: values.newPassword, resetPasswordToken: token });

            if (!res || res.Detail) {
              throw Error();
            } else if (res && res.firstName) {
              setUser(res);
              setHasReset(true);
            } else {
              throw Error();
            }
          } catch (error) {
            if (error && error.Detail) {
              setError(error.Detail);
            } else {
              setError(error);
            }
            actions.resetForm({ values: { email: values.email, newPassword: '', confirmPassword: '' } });
            const emailInput = document.getElementById('email');
            if (emailInput) {
              emailInput.focus();
            }
          } finally {
            actions.setSubmitting(false);
          }
        }}
        validationSchema={SetupPasswordSchema}
      >
        {(formikProps: FormikProps<ISetupPasswordValues>) => (
          <MinimalPage title='Setup Password'>
            <Form data-testid='admin-login-form' onSubmit={formikProps.handleSubmit}>
              <FormControl margin='normal' fullWidth>
                <Field name='email'>
                  {({ field, form }: FieldProps<ISetupPasswordValues>) => (
                    <TextField
                      {...field}
                      error={form.touched.email && form.errors && form.errors.email ? true : false}
                      fullWidth={true}
                      helperText={form.touched.email && form.errors && form.errors.email}
                      id='email'
                      label='Confirm Email'
                      margin='none'
                      required={true}
                      type='email'
                      value={formikProps.values.email}
                    />
                  )}
                </Field>
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <Field name='newPassword' validate={value => (isValidPassword(value) ? false : 'invalid')}>
                  {({ field, form }: FieldProps<ISetupPasswordValues>) => (
                    <>
                      <TextField
                        {...field}
                        error={form.touched.newPassword && form.errors && form.errors.newPassword ? true : false}
                        fullWidth={true}
                        helperText={
                          form.touched.newPassword &&
                          form.errors &&
                          form.errors.newPassword &&
                          (form.errors.newPassword === 'invalid' ? <PasswordRequirements /> : form.errors.newPassword)
                        }
                        id='newPassword'
                        label='Password'
                        margin='none'
                        required={true}
                        type='password'
                        value={formikProps.values.newPassword}
                        onFocus={() => {
                          // we want to make sure we untouch the field if password is empty, otherwise
                          // we flash the requirements error before we need to
                          if (formikProps.values.newPassword === '') {
                            formikProps.setFieldTouched('newPassword', false);
                          }
                        }}
                      />
                    </>
                  )}
                </Field>
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <Field name='confirmPassword' validate={() => (formikProps.values.newPassword !== formikProps.values.confirmPassword ? 'Passwords do not match!' : false)}>
                  {({ field, form }: FieldProps<ISetupPasswordValues>) => (
                    <TextField
                      {...field}
                      error={form.touched.confirmPassword && form.errors && form.errors.confirmPassword ? true : false}
                      fullWidth={true}
                      helperText={form.touched.confirmPassword && form.errors && form.errors.confirmPassword}
                      id='confirmPassword'
                      label='Confirm Password'
                      margin='none'
                      required={true}
                      type='password'
                      value={formikProps.values.confirmPassword}
                    />
                  )}
                </Field>
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <Button
                  color='primary'
                  disabled={!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid}
                  id='submit'
                  loading={formikProps.isSubmitting ? 'Setting up...' : ''}
                  size='large'
                  type='submit'
                  variant='contained'
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      formikProps.handleSubmit();
                    }
                  }}
                >
                  {!formikProps.isSubmitting ? 'Setup Password' : ''}
                </Button>
              </FormControl>
            </Form>
          </MinimalPage>
        )}
      </Formik>
      <Toast
        id='setup-password-success'
        message={success && success.message}
        onClick={() => setSuccess(null)}
        onClose={() => setSuccess(null)}
        open={success ? true : false}
        variant='success'
      />
      <Toast id='setup-password-error' message={error} onClick={() => setError(null)} onClose={() => setError(null)} open={error ? true : false} variant='error' />
    </>
  );
};
