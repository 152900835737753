import * as Yup from 'yup';

export const validateAtLeastOneFilledItem = () => {
    return Yup.string().optional().nullable().test(
        'oneOfRequired',
        'At least one field must be filled',
        function () {
            const { parent } = this;
            const properties = Object.keys(parent);
            return anyPropertieFilledButId(properties, parent);
        }
    )
}

export const validateAtLeastOneFilledItemGeneric = (schema: Yup.BaseSchema<any, any, any>) => {
    return schema.optional().nullable().test(
        'oneOfRequired',
        'At least one field must be filled',
        function () {
            const { parent } = this;
            const properties = Object.keys(parent);
            return anyPropertieFilledButId(properties, parent);
        }
    )
}

export const isObjectEmpty = (object) =>
    Object.keys(object).length === 0


export const anyPropertieFilledButId = (properties: string[], parent: any) => properties?.filter(p => p?.toLowerCase() !== "id" && !p?.toLowerCase()?.endsWith("id"))?.some((field) => !!parent[field])