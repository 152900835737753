export const months = (isFuture?: boolean) => {
  const year = isFuture ? new Date().getFullYear() + 1 : new Date().getFullYear();
  return [
    {
      label: 'January',
      value: new Date(year, 0, 1).toISOString(),
      lastDay: new Date(year, 1, 0).toISOString()
    },
    {
      label: 'February',
      value: new Date(year, 1, 1).toISOString(),
      lastDay: new Date(year, 2, 0).toISOString()
    },
    {
      label: 'March',
      value: new Date(year, 2, 1).toISOString(),
      lastDay: new Date(year, 3, 0).toISOString()
    },
    {
      label: 'April',
      value: new Date(year, 3, 1).toISOString(),
      lastDay: new Date(year, 4, 0).toISOString()
    },
    {
      label: 'May',
      value: new Date(year, 4, 1).toISOString(),
      lastDay: new Date(year, 5, 0).toISOString()
    },
    {
      label: 'June',
      value: new Date(year, 5, 1).toISOString(),
      lastDay: new Date(year, 6, 0).toISOString()
    },
    {
      label: 'July',
      value: new Date(year, 6, 1).toISOString(),
      lastDay: new Date(year, 7, 0).toISOString()
    },
    {
      label: 'August',
      value: new Date(year, 7, 1).toISOString(),
      lastDay: new Date(year, 8, 0).toISOString()
    },
    {
      label: 'September',
      value: new Date(year, 8, 1).toISOString(),
      lastDay: new Date(year, 9, 0).toISOString()
    },
    {
      label: 'October',
      value: new Date(year, 9, 1).toISOString(),
      lastDay: new Date(year, 10, 0).toISOString()
    },
    {
      label: 'November',
      value: new Date(year, 10, 1).toISOString(),
      lastDay: new Date(year, 11, 0).toISOString()
    },
    {
      label: 'December',
      value: new Date(year, 11, 1).toISOString(),
      lastDay: new Date(year, 12, 0).toISOString()
    }
  ];
};

export const nextSixMonthsFromCurrent = (currentMonth?: number) => {
  const selectedMonths = [];
  for (var cal = 0; cal < 6; cal++) {
    const currentMonthNumber = typeof currentMonth === 'number' ? currentMonth : new Date().getMonth();
    let numberDate = currentMonthNumber + cal + 1;

    // conditional checks here to loop the array to grab the next months correctly
    // since we are adding up the values and loop through six times
    if (numberDate === 12) {
      numberDate = 0;
    }
    if (numberDate === 13) {
      numberDate = 1;
    }
    if (numberDate === 14) {
      numberDate = 2;
    }
    if (numberDate === 15) {
      numberDate = 3;
    }
    if (numberDate === 16) {
      numberDate = 4;
    }
    if (numberDate === 17) {
      numberDate = 5;
    }
    selectedMonths.push(months()[numberDate]);
  }
  return selectedMonths;
};

const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

export const addMonths = (input: Date, months: number): Date => {
  const date = new Date(input);
  date.setDate(1);
  date.setMonth(date.getMonth() + months);
  date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)));
  return date;
};
