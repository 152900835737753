import { NoteAlt, FileCopy, AddShoppingCart, FolderShared, School, Groups, Forum, Timeline as TimelineIcon } from '@mui/icons-material';

export const buildSteps = [
  {
    buildStep: 'ProductOverview',
    businessClientBuildStepId: -1,
    url: '',
    icon: <AddShoppingCart fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    icon: <FileCopy fontSize='large' color='primary' />,
    businessClientBuildStepId: -2,
    buildStep: 'CensusProfile',
    url: '',
    buildStepStatus: ''
  },
  {
    buildStep: 'EssentialDocs',
    businessClientBuildStepId: -3,
    url: '',
    icon: <NoteAlt fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'EnrollmentOverview',
    businessClientBuildStepId: -4,
    url: '',
    icon: <School fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'HROverview',
    businessClientBuildStepId: -5,
    url: '',
    icon: <Groups fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'ClientProfile',
    businessClientBuildStepId: -6,
    url: '',
    icon: <FolderShared fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'CommunicationOverview',
    businessClientBuildStepId: -7,
    url: '',
    icon: <Forum fontSize='large' color='primary' />,
    buildStepStatus: ''
  },
  {
    buildStep: 'ProjectTracker',
    businessClientBuildStepId: -8,
    url: '',
    icon: <TimelineIcon fontSize='large' color='primary' />,
    buildStepStatus: ''
  }
];
