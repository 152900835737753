import { Fragment, FC } from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Theme, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { PerformantTextField } from '../../../../../../../../components/inputs/PerformantTextField';
import GenericDropdown from '../../../../../../../../components/generic-dropdown/GenericDropdown';
import useContactRolesDropdownCollection from '../../../../../../../../hooks/dropdown-collection/useContactRolesDropdownCollection';
import { IBusinessClientContact, IBusinessClientDetailFormValues } from '../../../../../../../../models';


const Contacts: FC = () => {

    const { values, handleBlur, setFieldValue, errors } = useFormikContext<IBusinessClientDetailFormValues>();
    const contacts = values.contacts ?? []


    const contactsErrors = errors.contacts as FormikErrors<IBusinessClientContact>[];


    const classes = useStyles();

    const contactRolesRequest = useContactRolesDropdownCollection();
    const contactRolesSupports = contactRolesRequest.data;
    const contactRolesLoading = contactRolesRequest.status === "loading";

    return (<FieldArray name='contacts' >
        {({ push, remove }) => {
            return (
                <div className={classes.container}>
                    {contacts.length > 0 &&
                        contacts.map((contact, index) => {
                            return (
                                <Fragment key={`${index}`}>
                                    <Typography variant='h5' component='h5' className={classes.classText}>
                                        Contact {index + 1}
                                    </Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Field name={`contacts.${index}.name`}>
                                                {({ field }) => {
                                                    return (
                                                        <PerformantTextField
                                                            {...field}
                                                            fullWidth
                                                            variant='outlined'
                                                            size='small'
                                                            autoComplete='nope'
                                                            label='Name'
                                                            name={`contacts.${index}.name`}
                                                            value={contact.name}
                                                            onBlur={handleBlur}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <Field name={`contacts.${index}.email`}>
                                                {({ field }) => {
                                                    return (
                                                        <PerformantTextField
                                                            {...field}
                                                            fullWidth
                                                            variant='outlined'
                                                            size='small'
                                                            autoComplete='nope'
                                                            label='Email'
                                                            name={`contacts.${index}.email`}
                                                            value={contact.email}
                                                            onBlur={handleBlur}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <GenericDropdown handleBlur={handleBlur}
                                                options={contactRolesSupports}
                                                isLoading={contactRolesLoading}
                                                setFieldValue={setFieldValue}
                                                value={values.contacts?.[index]?.role ?? ''}
                                                error={contactsErrors?.[index]?.role}
                                                label='Role'
                                                id={`contacts.${index}.role`}
                                            />

                                        </Grid>

                                        <Grid item xs={12} md={3} >
                                            <FormControl component='fieldset'>
                                                <FormLabel component='legend'>BenAdmin Access?</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-label={`contacts.${index}.hasBenAdminAccess`}
                                                    name={`contacts.${index}.hasBenAdminAccess`}
                                                    color='primary'
                                                    value={values.contacts?.[index]?.hasBenAdminAccess}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (e.target.value === 'true') {
                                                            setFieldValue(`contacts.${index}.hasBenAdminAccess`, true);
                                                        } else {
                                                            setFieldValue(`contacts.${index}.hasBenAdminAccess`, false);
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                                                    <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={3}>
                                            <FormControl component='fieldset'>
                                                <FormLabel component='legend'>Dashboard Access?</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-label={`hasDashboardAccess`}
                                                    name={`contacts.${index}.hasDashboardAccess`}
                                                    color='primary'
                                                    value={values.contacts?.[index]?.hasDashboardAccess}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (e.target.value === 'true') {
                                                            setFieldValue(`contacts.${index}.hasDashboardAccess`, true);
                                                        } else {
                                                            setFieldValue(`contacts.${index}.hasDashboardAccess`, false);
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                                                    <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>







                                    </Grid>

                                    <Grid item xs={12} justifyContent={'end'} className={classes.removeButtonWrapper}>
                                        <Button
                                            className={classes.removeButton}
                                            startIcon={<Close />}
                                            onClick={() => {
                                                remove(index);
                                            }}
                                        >
                                            Remove Contact
                                        </Button>
                                    </Grid>


                                </Fragment>
                            );
                        })}

                    {contacts.length < 4 && ( // Change 3 to the desired maximum
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    name: '',
                                    email: '',
                                    hasBenAdminAccess: false,
                                    hasDashboardAccess: false
                                })
                            }
                        >
                            Add Contact
                        </Button>
                    )}
                </div>
            );
        }}
    </FieldArray>
    )
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginBottom: '2em'
    },

    addClassButton: {
        marginTop: theme.spacing(1)
    },

    removeButtonWrapper: {
        textAlign: 'right',
        marginTop: '-16px',
    },
    removeButton: {
        color: theme.palette.error.main
    },
    classText: {
        margin: theme.spacing(0.5, 0)
    }
}));

export default Contacts;