// icons
import { Add, Close, Delete, Edit, GetApp, Publish, Visibility, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { ConfirmationModal, Button, EmployeeWarningToast, Link, Loader, Page, PageTitle, Pagination, Table, Toast } from '../../components';
import { TimeZoneContext } from '../../context/timezone';
// fetch
import {
  deleteEmployee,
  downloadEmployeesTemplate,
  downloadOutcomesTemplate,
  getBusinessClient,
  getBusinessClientDashboard,
  getEmployee,
  getEmployees,
  uploadEmployees,
  uploadOutcomes
} from '../../fetch';
// helpers
import { addMonths, enhancedFilterTypeArray, formatDate, formatShortFriendlyDateWithTime, months, nextSixMonthsFromCurrent } from '../../helpers';
// models
import {
  ASC_SORT,
  DESC_SORT,
  ENHANCED_STATUS_FILTERS_ENUM,
  IBusinessClientClass,
  IBusinessClientDashboard,
  IBusinessClientLocation,
  IEmployee,
  IEmployeeDetail,
  SortOptions
} from '../../models';
// components
import { EmployeeFilters } from './EmployeeFilters';
import { EmployeeModal } from './EmployeeModal';
import { EmployeePieChart } from './EmployeePieChart';
import { MobileEmployee } from './MobileEmployee';
import { EmployeesUploadsStatus } from '../../components/employees-upload-status/EmployeesUploadsStatus';

export const Employees = () => {
  const classes = useStyles();

  const { businessClientId } = useParams();

  const [page, setPage] = useState<number>(0);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [perPage, setRowsPerPage] = useState<number>(50);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [selectedSort, setSelectedSort] = useState<string>('FirstName');
  const [sortDirection, setSortDirection] = useState<{
    FirstName?: SortOptions;
    LastName?: SortOptions;
    MeetingSchedulingStatus?: SortOptions;
    NextScheduledMeeting?: SortOptions;
    EnrollmentMeetingOutcome?: SortOptions;
    PostEnrollmentFollowUpItems?: SortOptions;
    LastCommunication?: SortOptions;
    BenefitCounselorNotes?: SortOptions;
    EffectiveDate?: SortOptions;
  }>({
    FirstName: ASC_SORT
  });
  const [selectedMeetingStatus, setSelectedMeetingStatus] = useState<string>('');
  const [selectedEnrollmentOutcome, setSelectedEnrollmentOutcome] = useState<string>('');
  const [selectedPostEnrollment, setSelectedPostEnrollment] = useState<string>('');
  const [searchValue, setSearch] = useState<string>('');
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [isEmployeeModalOpen, showEmployeeModal] = useState<boolean>(false);
  const [currentEmployee, setCurrentEmployee] = useState<IEmployeeDetail | null>(null);
  const [isLoadingOverlay, setLoadingOverlay] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [locations, setLocations] = useState<IBusinessClientLocation[]>([]);
  const [businessClientClasses, setClasses] = useState<IBusinessClientClass[]>([]);
  const [isLoadingBusinessClient, setIsLoadingBusinessClient] = useState<boolean>(true);
  const [businessClientDashboardData, setBusinessClientDashboard] = useState<IBusinessClientDashboard>();
  const [isLoadingBusinessClientDashboard, setIsLoadingBusinessClientDashboard] = useState<boolean>(true);
  const [isUploadingEmployees, setIsUploadingEmployees] = useState<boolean>(false);

  const [uploadFinished, setUploadFinished] = useState<boolean>(false);

  const [isUploadingOutcomes, setIsUploadingOutcomes] = useState<boolean>(false);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<'employees' | 'outcomes' | null>(null);
  // Used for progress bar
  const [selectedEnrollmentType, setEnrollmentType] = useState<string>('');
  const [enhancedFilterType, setEnhancedFilterType] = useState<ENHANCED_STATUS_FILTERS_ENUM>('SchedulingStatus');
  const [selectedNewHiresOption, setNewHiresOption] = useState<string>('');
  const [minimumEffectiveDate, setMinimumEffectiveDate] = useState<string>('');
  const [maximumEffectiveDate, setMaximumEffectiveDate] = useState<string>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  // Employee Bulk Upload Warning Message
  const [showBulkEmployeeWarning, setShowBulkEmployeeWarning] = useState<boolean>(false);
  const [bulkEffectiveDateConfirmed, setBulkEffectiveDateConfirmed] = useState<boolean>(false);

  // Clicked progress bar column
  const [selectedProgressBar, setSelectedProgressBar] = useState<string>('');

  // Time Zone
  const { timeZone } = useContext(TimeZoneContext);

  // used for the error csv upload confirmation modal
  const [showConfirmationModal, setShowConfirmationModal] = useState<any[] | null>(null);
  // Template Dropdowns
  const [templateAnchorEl, setTemplateAnchorEl] = useState<null | HTMLElement>(null);
  const templateIsOpen = Boolean(templateAnchorEl);

  const fetchEmployees = async (filters?: {
    clearFilters?: boolean;
    clearSearch?: boolean;
    clearDates?: boolean;
    schedulingStatus?: string;
    enrollmentMeetingOutcome?: string;
    postEnrollmentFollowUpItem?: string;
    effectiveDateMinimum?: string;
    effectiveDateMaximum?: string;
  }) => {
    setIsLoading(true);
    let selectedSchedulingStatus = null;
    let selectedEnrollmentMeetingOutcome = null;
    let selectedPostEnrollmentFollowupItem = null;
    let selectedSearch = null;
    let effectiveDateMinimum = null;
    let effectiveDateMaximum = null;

    if (filters && filters.schedulingStatus) {
      selectedSchedulingStatus = filters.schedulingStatus;
    } else if (filters && !filters.clearFilters && selectedMeetingStatus) {
      selectedSchedulingStatus = selectedMeetingStatus;
    }

    if (filters && filters.enrollmentMeetingOutcome) {
      selectedEnrollmentMeetingOutcome = filters.enrollmentMeetingOutcome;
    } else if (filters && !filters.clearFilters && selectedEnrollmentOutcome) {
      selectedEnrollmentMeetingOutcome = selectedEnrollmentOutcome;
    }

    if (filters && filters.postEnrollmentFollowUpItem) {
      selectedPostEnrollmentFollowupItem = filters.postEnrollmentFollowUpItem;
    } else if (filters && !filters.clearFilters && selectedPostEnrollment) {
      selectedPostEnrollmentFollowupItem = selectedPostEnrollment;
    }
    if (filters && filters.clearSearch) {
      selectedSearch = '';
    } else if (searchValue) {
      selectedSearch = searchValue;
    }
    if (filters && filters.clearDates) {
      effectiveDateMinimum = null;
      effectiveDateMaximum = null;
    } else if (filters && filters.effectiveDateMinimum && filters.effectiveDateMaximum) {
      effectiveDateMinimum = filters.effectiveDateMinimum;
      effectiveDateMaximum = filters.effectiveDateMaximum;
    } else {
      effectiveDateMinimum = minimumEffectiveDate;
      effectiveDateMaximum = maximumEffectiveDate;
    }
    try {
      const res = await getEmployees(businessClientId, {
        page: page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        schedulingStatus: selectedSchedulingStatus || null,
        dashboardSchedulingStatus: enhancedFilterType === 'SchedulingStatus' && selectedProgressBar ? selectedProgressBar : null,
        enrollmentMeetingOutcome: selectedEnrollmentMeetingOutcome || null,
        dashboardEnrollmentMeetingOutcome: enhancedFilterType === 'EnrollmentMeetingOutcome' && selectedProgressBar ? selectedProgressBar : null,
        postEnrollmentFollowupItem: selectedPostEnrollmentFollowupItem || null,
        dashboardPostEnrollmentFollowupItem: enhancedFilterType === 'PostEnrollmentFollowUpItem' && selectedProgressBar ? selectedProgressBar : null,
        search: selectedSearch,
        effectiveDateMinimum,
        effectiveDateMaximum
      });
      if (filters && filters.clearSearch) {
        setSearchedValue('');
        setSearch('');
      } else if (searchValue) {
        setSearchedValue(searchValue);
        setSearch(searchValue);
      }
      if (res.records) {
        setEmployees(res.records);
        setRecordCount(res.totalRecordCount);
      } else {
        console.error(`No 'records' on employess response.`);
        setErrorMessage('Error loading employess, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading employess, please try again.');
    }
    setIsLoading(false);
  };

  const fetchBusinessClient = async () => {
    try {
      const res = await getBusinessClient(businessClientId);
      if (res) {
        setLocations(res.location);
        setClasses(res.classes);
      } else {
        console.error(`No 'records' on business client response.`);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBusinessClient(false);
  };

  const fetchBusinessClientDashboard = async () => {
    try {
      let res = await getBusinessClientDashboard(businessClientId, enhancedFilterType as ENHANCED_STATUS_FILTERS_ENUM);
      if (res.isOpenEnrollment) {
        setEnrollmentType('Open Enrollment');
        fetchEmployees();
      } else {
        const currentMonthIndx = new Date().getMonth();
        let selectedMonth = months().find((month, index) => {
          return currentMonthIndx === index;
        });
        if (selectedMonth) {
          setMinimumEffectiveDate(selectedMonth.value);
          setMaximumEffectiveDate(selectedMonth.lastDay);
          setEnrollmentType('New Hires');
          // refecth the progress bar data
          fetchEmployees({
            effectiveDateMinimum: selectedMonth.value,
            effectiveDateMaximum: selectedMonth.lastDay
          });
        }
      }
      setBusinessClientDashboard(res);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingBusinessClientDashboard(false);
    }
  };

  useEffect(() => {
    fetchBusinessClient();
    fetchBusinessClientDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage]);

  useEffect(() => {
    // Used for progress bar click event
    // Reset filters
    if (selectedProgressBar === '') {
      setHasAppliedFilters(false);
      setSelectedMeetingStatus('');
      setSelectedEnrollmentOutcome('');
      setSelectedPostEnrollment('');
      fetchEmployees({
        clearFilters: true,
        clearSearch: true
      });
    }

    if (selectedProgressBar !== '') {
      setHasAppliedFilters(true);
      fetchEmployees({
        clearFilters: false,
        clearSearch: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgressBar]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === ASC_SORT ? DESC_SORT : ASC_SORT
    });
  };

  const handleEdit = async id => {
    try {
      setLoadingOverlay(true);
      const client = await getBusinessClient(businessClientId);
      const res = await getEmployee(businessClientId, id);
      const selectedLocation = client.location.find(l => l.businessClientLocationId === res.locationId);
      const selectedClass = client.classes.find(c => c.businessClientClassId === res.classId);
      setCurrentEmployee({
        ...res,
        locationName: selectedLocation && selectedLocation.name ? selectedLocation.name : '',
        className: selectedClass && selectedClass.name ? selectedClass.name : ''
      });
      showEmployeeModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOverlay(false);
    }
  };

  const handleDelete = async id => {
    const result = window.confirm('Are you sure you want to delete this employee?');
    if (result) {
      setDeleting(true);
      try {
        await deleteEmployee(businessClientId, id);
        fetchEmployees();
        setSuccessMessage('Employee Deleted!');
      } catch (error) {
        setErrorMessage(error);
      } finally {
        setDeleting(false);
      }
    }
  };

  const setEffectiveDates = (min: string, max: string) => {
    setMinimumEffectiveDate(min);
    setMaximumEffectiveDate(max);
    // filter by 6 months previous and future 6 months
    fetchEmployees({
      effectiveDateMinimum: min,
      effectiveDateMaximum: max
    });
  };

  const handleDateEnrollmentChange = e => {
    setNewHiresOption(e.target.value as string);
    if (e.target.value === 'All New Hires') {
      setEffectiveDates(new Date(addMonths(new Date(), -6)).toISOString(), new Date(addMonths(new Date(), 6)).toISOString());
    } else {
      const currentMonth = new Date().getMonth();
      // make sure we are the correct year in the next six months
      const isFuture =
        (currentMonth >= 6 && e.target.value === 'January') ||
        (currentMonth >= 6 && e.target.value === 'February') ||
        (currentMonth >= 6 && e.target.value === 'March') ||
        (currentMonth >= 6 && e.target.value === 'April') ||
        (currentMonth >= 6 && e.target.value === 'May') ||
        (currentMonth >= 6 && e.target.value === 'June');
      const selectedMonth = months(isFuture).find(month => month.label === e.target.value);

      setEffectiveDates(selectedMonth.value, selectedMonth.lastDay);
    }
  };
  const columns = useMemo(() => {
    const effectiveDateCol =
      selectedEnrollmentType === 'New Hires'
        ? {
          Header: 'Effective Date',
          title: 'EffectiveDate',
          accessor: 'effectiveDate',
          isServerSorted: selectedSort === 'EffectiveDate',
          isServerSortedDesc: sortDirection.EffectiveDate === DESC_SORT,
          handleClickColumn: () => handleClickColumn('EffectiveDate'),
          Cell: ({
            cell: {
              row: { original }
            }
          }: {
            cell: { row: { original: IEmployee } };
          }) => {
            return <>{original.effectiveDate ? formatDate(original.effectiveDate) : null}</>;
          }
        }
        : undefined;
    return [
      {
        Header: 'First Name',
        accessor: 'firstName',
        isServerSorted: selectedSort === 'FirstName',
        isServerSortedDesc: sortDirection.FirstName === DESC_SORT,
        handleClickColumn: () => handleClickColumn('FirstName'),
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IEmployee } };
        }) => {
          return original.firstName;
        }
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        isServerSorted: selectedSort === 'LastName',
        isServerSortedDesc: sortDirection.LastName === DESC_SORT,
        handleClickColumn: () => handleClickColumn('LastName'),
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IEmployee } };
        }) => {
          return original.lastName;
        }
      },
      effectiveDateCol,
      {
        Header: 'Scheduling Status',
        title: 'Meeting Scheduling Status',
        accessor: 'meetingSchedulingStatus',
        isServerSorted: selectedSort === 'MeetingSchedulingStatus',
        isServerSortedDesc: sortDirection.MeetingSchedulingStatus === DESC_SORT,
        handleClickColumn: () => handleClickColumn('MeetingSchedulingStatus')
      },
      {
        Header: 'Next Meeting',
        title: 'Next Scheduled Meeting',
        accessor: 'nextScheduledMeeting',
        isServerSorted: selectedSort === 'NextScheduledMeeting',
        isServerSortedDesc: sortDirection.NextScheduledMeeting === DESC_SORT,
        handleClickColumn: () => handleClickColumn('NextScheduledMeeting'),
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IEmployee } };
        }) => {
          return <>{original.nextScheduledMeeting ? formatShortFriendlyDateWithTime(original.nextScheduledMeeting, timeZone) : null}</>;
        }
      },
      {
        Header: 'Meeting Outcome',
        title: 'Enrollment Meeting Outcome',
        accessor: 'enrollmentMeetingOutcome',
        isServerSorted: selectedSort === 'EnrollmentMeetingOutcome',
        isServerSortedDesc: sortDirection.EnrollmentMeetingOutcome === DESC_SORT,
        handleClickColumn: () => handleClickColumn('EnrollmentMeetingOutcome')
      },
      {
        Header: 'Post Enrollment Follow-Up',
        title: 'Post Enrollment Follow Up Item',
        accessor: 'postEnrollmentFollowUpItem',
        isServerSorted: selectedSort === 'PostEnrollmentFollowUpItems',
        isServerSortedDesc: sortDirection.PostEnrollmentFollowUpItems === DESC_SORT,
        handleClickColumn: () => handleClickColumn('PostEnrollmentFollowUpItems')
      },
      {
        Header: 'Communication History',
        accessor: 'lastCommunication',
        isServerSorted: selectedSort === 'LastCommunication',
        isServerSortedDesc: sortDirection.LastCommunication === DESC_SORT,
        handleClickColumn: () => handleClickColumn('LastCommunication'),
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IEmployee } };
        }) => {
          return <>{original.lastCommunication ? formatShortFriendlyDateWithTime(original.lastCommunication, timeZone) : null}</>;
        }
      },
      {
        id: 'actions',
        Header: '',
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IEmployee } };
        }) => {
          return (
            <div className={classes.actionButtons}>
              <Link to={`/business-clients/${businessClientId}/employees/${original.employeeId}`}>
                <IconButton className={classes.editButton} color='primary' size='large'>
                  <Visibility />
                </IconButton>
              </Link>
              <IconButton
                className={classes.editButton}
                color='primary'
                onClick={async () => {
                  handleEdit(original.employeeId);
                }}
                size='large'
              >
                <Edit />
              </IconButton>
              <IconButton
                className={classes.deleteButton}
                color='default'
                onClick={async () => {
                  handleDelete(original.employeeId);
                }}
                size='large'
              >
                <Delete />
              </IconButton>
            </div>
          );
        }
      }
    ].filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage, selectedEnrollmentType, timeZone]);

  const hasData = employees && employees.length > 0;
  const isMobile = useMediaQuery('(max-width: 960px)');

  const enrollmentTypes = businessClientDashboardData && businessClientDashboardData.isOpenEnrollment ? ['Open Enrollment', 'New Hires'] : ['New Hires'];

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: '.csv',
    onDrop: async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {

        setUploadFinished(false);

        const formData = new FormData();
        formData.append('File', acceptedFiles[0]);
        const employeeWarningError = 'You are attempting to add new hires with an effective date that is less than 15 days away';
        if (uploadType === 'outcomes') {
          setIsUploadingOutcomes(true);
          const res = await uploadOutcomes(businessClientId, formData);
          if (res && res.Detail) {
            setIsUploadingOutcomes(false);
            if (res?.Errors && Object.values(res?.Errors)?.length) {
              return setShowConfirmationModal(Object.values(res?.Errors) as any[]);
            } else {
              return setShowConfirmationModal([res.Detail]);
            }
          }
        } else {
          setIsUploadingEmployees(true);

          try {
            await uploadEmployees(businessClientId, formData, bulkEffectiveDateConfirmed);

            setUploadFinished(true);
            setHasAppliedFilters(false);
            setSelectedMeetingStatus('');
            setSelectedEnrollmentOutcome('');
            setSelectedPostEnrollment('');
            fetchEmployees({
              clearFilters: true,
              clearSearch: true
            });
            setSuccessMessage(`Success! Uploaded ${uploadType}!`);
            setBulkEffectiveDateConfirmed(false);
            setIsUploadingEmployees(false);
            setIsUploadingOutcomes(false);
          } catch (res) {
            setIsUploadingEmployees(false);

            const errorMessages = res?.results.map(r => r.errorMessage) as string[];

            if (errorMessages.includes(employeeWarningError)) {
              return setShowBulkEmployeeWarning(true);
            }

            return setShowConfirmationModal(errorMessages);
          }
        }


      } else if (fileRejections.length > 0) {
        setErrorMessage('Invalid file type (only CSV or Excel is allowed)');
      } else {
        setErrorMessage(`Error uploading ${uploadType}, please try again.`);
      }
    }
  });

  return (
    <>
      {isLoadingOverlay && (
        <Loader position='centered' type='fullscreen'>
          Loading...
        </Loader>
      )}
      {isDeleting && (
        <Loader position='centered' type='fullscreen'>
          Deleting...
        </Loader>
      )}
      <Page title={businessClientDashboardData ? businessClientDashboardData.name : 'Loading...'}>
        {isLoadingBusinessClientDashboard && <Loader position='centered' />}
        {businessClientDashboardData && !isLoadingBusinessClientDashboard && (
          <>
            <PageTitle title={businessClientDashboardData.name} />
            <Grid container alignItems='center' className={classes.addEmployeeWrapper}>
              {businessClientDashboardData && (
                <div className={classes.enrollmentOptionsWrapper}>
                  <FormControl className={classes.enrollmentTypeWrapper} variant='outlined' size='small'>
                    <InputLabel id={`enrollment-type`} className={classes.outlinedLabel}>
                      Enrollment Type
                    </InputLabel>
                    <Select
                      onChange={async e => {
                        if (selectedEnrollmentType === 'New Hires') {
                          fetchEmployees({
                            clearDates: true
                          });
                          setNewHiresOption(null);
                        }
                        setEnrollmentType(e.target.value as string);
                      }}
                      name={`enrollment-type`}
                      labelId={`enrollment-type`}
                      id={`enrollment-type`}
                      size='small'
                      value={selectedEnrollmentType}
                    >
                      {enrollmentTypes.map((item: string, index) => {
                        return (
                          <MenuItem key={`${index}`} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {selectedEnrollmentType === 'New Hires' && (
                    <FormControl className={classes.enrollmentTypeWrapper} variant='outlined' size='small'>
                      <InputLabel id={`enrollment-type`} className={classes.outlinedLabel}>
                        Upcoming Enrollments
                      </InputLabel>
                      <Select
                        onChange={handleDateEnrollmentChange}
                        name={`enrollment-type`}
                        labelId={`enrollment-type`}
                        id={`enrollment-type`}
                        size='small'
                        value={selectedNewHiresOption}
                      >
                        {[...nextSixMonthsFromCurrent(), { label: 'All New Hires', value: 'All' }].map((item: { label: string; value: string }, index) => {
                          return (
                            <MenuItem key={`${index}`} value={item.label}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                </div>
              )}
              {!isMobile && (
                <>
                  <Button id='create-employee' startIcon={<Add />} onClick={() => showEmployeeModal(true)} color='primary' variant='contained'>
                    Add Employee
                  </Button>
                  <div
                    {...getRootProps({
                      onClick: () => {
                        setUploadType('employees');
                      }
                    })}
                  >
                    <input {...getInputProps()} />
                    <Button
                      id='upload-employees'
                      loading={isUploadingEmployees ? 'Uploading...' : ''}
                      LoaderProps={{
                        size: 'small'
                      }}
                      className={classes.uploadButton}
                      startIcon={<Publish />}
                      onClick={open}
                      variant='contained'
                    >
                      {!isUploadingEmployees ? 'Upload Employees' : ''}
                    </Button>
                  </div>
                  <div
                    {...getRootProps({
                      onClick: () => {
                        setUploadType('outcomes');
                      }
                    })}
                  >
                    <input {...getInputProps()} />
                    <Button
                      id='upload-outcomes'
                      loading={isUploadingOutcomes ? 'Uploading...' : ''}
                      LoaderProps={{
                        size: 'small'
                      }}
                      className={classes.uploadButton}
                      startIcon={<Publish />}
                      onClick={open}
                      variant='contained'
                    >
                      {!isUploadingOutcomes ? 'Upload Outcomes' : ''}
                    </Button>
                  </div>

                  <Button
                    id='download-template-menu-toggle'
                    className={classes.uploadButton}
                    loading={isDownloading ? 'Downloading...' : ''}
                    LoaderProps={{
                      size: 'small'
                    }}
                    aria-controls={templateIsOpen ? 'download-template-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={templateIsOpen ? 'true' : undefined}
                    variant='contained'
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setTemplateAnchorEl(event.currentTarget);
                    }}
                    startIcon={<GetApp />}
                    endIcon={templateIsOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                  >
                    {!isDownloading ? 'CSV Templates' : ''}
                  </Button>
                  <Menu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    id='download-template-menu'
                    MenuListProps={{
                      'aria-labelledby': 'download-template-menu-toggle'
                    }}
                    anchorEl={templateAnchorEl}
                    open={templateIsOpen}
                    onClose={() => {
                      setTemplateAnchorEl(null);
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setTemplateAnchorEl(null);
                      }}
                      disableRipple
                    >
                      <Button
                        id='download-employees-template'
                        LoaderProps={{
                          size: 'small'
                        }}
                        startIcon={<GetApp />}
                        onClick={async () => {
                          try {
                            setDownloading(true);
                            const columnNames = await downloadEmployeesTemplate();
                            // add UTF-8 BOM to beginning so excel doesn't get confused.
                            // *THIS IS THE KEY*
                            const BOM = String.fromCharCode(0xfeff);
                            const el = document.createElement('a');
                            el.href = `data:text/csv;charset=UTF-8,${encodeURI(`${BOM}${columnNames}`)}`;
                            el.target = '_blank';
                            el.download = `employees-template.csv`;
                            el.click();
                          } catch (error) {
                            console.log(error);
                          } finally {
                            setDownloading(false);
                          }
                        }}
                        variant='text'
                      >
                        Employees Template
                      </Button>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setTemplateAnchorEl(null);
                      }}
                      disableRipple
                    >
                      <Button
                        id='download-outcomes-template'
                        LoaderProps={{
                          size: 'small'
                        }}
                        startIcon={<GetApp />}
                        onClick={async () => {
                          try {
                            setDownloading(true);
                            const columnNames = await downloadOutcomesTemplate();
                            // add UTF-8 BOM to beginning so excel doesn't get confused.
                            // *THIS IS THE KEY*
                            const BOM = String.fromCharCode(0xfeff);
                            const el = document.createElement('a');
                            el.href = `data:text/csv;charset=UTF-8,${encodeURI(`${BOM}${columnNames}`)}`;
                            el.target = '_blank';
                            el.download = `outcomes-template.csv`;
                            el.click();
                          } catch (error) {
                            console.log(error);
                          } finally {
                            setDownloading(false);
                          }
                        }}
                        variant='text'
                      >
                        Outcomes Template
                      </Button>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>

            <EmployeesUploadsStatus businessClientId={businessClientId} shouldLoadData={uploadFinished} />

            {businessClientDashboardData && (
              <Grid container className={classes.pieChartWrapper}>
                {enhancedFilterTypeArray.map(type => {
                  return (
                    <Fragment key={`${type.value}`}>
                      <EmployeePieChart
                        type={type.value}
                        label={type.description}
                        businessClientId={businessClientId}
                        setEnhancedFilterType={() => {
                          setEnhancedFilterType(type.value as ENHANCED_STATUS_FILTERS_ENUM);
                        }}
                        selectedMonth={{
                          value: minimumEffectiveDate,
                          lastDay: maximumEffectiveDate
                        }}
                        setSelectedProgressBar={setSelectedProgressBar}
                      />
                    </Fragment>
                  );
                })}
              </Grid>
            )}
            {isMobile && (
              <>
                <Typography variant='h3' className={classes.subHeading}>
                  Employees
                </Typography>
                <Button id='create-employee' startIcon={<Add />} onClick={() => showEmployeeModal(true)} color='primary' variant='contained' className={classes.mobileAddEmployee}>
                  Add Employee
                </Button>
              </>
            )}
            <EmployeeFilters
              employees={employees}
              selectedEnrollmentType={selectedEnrollmentType}
              setSelectedEnrollmentOutcome={(val: string) => setSelectedEnrollmentOutcome(val)}
              setSelectedMeetingStatus={(val: string) => setSelectedMeetingStatus(val)}
              setSelectedPostEnrollment={(val: string) => setSelectedPostEnrollment(val)}
              selectedEnrollmentOutcome={selectedEnrollmentOutcome}
              selectedMeetingStatus={selectedMeetingStatus}
              selectedPostEnrollment={selectedPostEnrollment}
              isFetching={isLoading}
              searchValue={searchValue}
              selectedSort={selectedSort}
              sortDirection={sortDirection[selectedSort]}
              setSearchValue={(val: string) => setSearch(val)}
              enhancedFilterType={enhancedFilterType}
              setHasAppliedFilters={(val: boolean) => setHasAppliedFilters(val)}
              hasAppliedFilters={hasAppliedFilters}
              handleSearch={(clearSearch?: boolean) => {
                fetchEmployees({
                  clearSearch
                });

                if (clearSearch) {
                  setSearchedValue('');
                }
              }}
              applyFilters={(clearFilters?: boolean, clearSearch?: boolean, clearProgressBar?: boolean) => {
                if (clearProgressBar) {
                  setSelectedProgressBar('');
                }
                fetchEmployees({
                  clearFilters,
                  clearSearch
                });
              }}
              selectedProgressBar={selectedProgressBar}
              effectiveDateMinimum={minimumEffectiveDate}
              effectiveDateMaximum={maximumEffectiveDate}
            />
            {searchedValue && (
              <Grid container alignItems='center' className={classes.searchWrapper}>
                <Typography variant='h4' color='secondary'>
                  Showing results for "{searchedValue}"
                </Typography>
                <Button
                  id='clear-search'
                  size='small'
                  startIcon={<Close />}
                  className={classes.clearSearchButton}
                  onClick={() => {
                    setSearch('');
                    setSearchedValue('');

                    fetchEmployees({
                      clearFilters: false,
                      clearSearch: true
                    });
                  }}
                >
                  Clear Search
                </Button>
              </Grid>
            )}
            <div className={classes.wrapper}>
              <Table
                columns={columns}
                data={employees}
                isLoading={isLoading}
                hidePagination
                ResponsiveComponent={isMobile ? MobileEmployee : undefined}
                mobileProps={{
                  handleEdit: (val: IEmployee) => {
                    handleEdit(val.employeeId);
                  },
                  handleDelete: (val: IEmployee) => {
                    handleDelete(val.employeeId);
                  }
                }}
              />
              {!isLoading && hasData && (
                <div className={classes.paginationWrapper}>
                  <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
                </div>
              )}
            </div>
            <EmployeeModal
              locations={locations}
              businessClientClasses={businessClientClasses}
              isLoadingBusinessClient={isLoadingBusinessClient}
              currentEmployee={currentEmployee}
              open={isEmployeeModalOpen}
              onClose={() => {
                showEmployeeModal(false);
                setTimeout(() => {
                  setCurrentEmployee(null);
                }, 500);
              }}
              onSave={() => fetchEmployees()}
            />
            <Toast id='employees-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
            <Toast id='employees-error' autoHideDuration={5000} message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
            <EmployeeWarningToast
              open={showBulkEmployeeWarning}
              onConfirm={() => {
                setBulkEffectiveDateConfirmed(true); // Update flag, the user confirmed they know about the dates
                setShowBulkEmployeeWarning(false); // Hide alert when confirmed
              }}
            />
            <ConfirmationModal
              open={!!showConfirmationModal}
              handleClose={() => setShowConfirmationModal(null)}
              items={showConfirmationModal}
              title='Encountered errors uploading employees'
            />
          </>
        )}
      </Page>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(0.125)
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  },
  clearSearchButton: {
    '@media (min-width: 408px)': {
      marginTop: 3,
      marginLeft: theme.spacing(1)
    }
  },
  searchWrapper: {
    margin: theme.spacing(1, 0)
  },
  addEmployeeWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media (min-width: 700px)': {
      flexDirection: 'row',
      marginBottom: theme.spacing(1)
    }
  },
  actionButtons: {
    display: 'flex'
  },
  editButton: {
    padding: 3
  },
  deleteButton: {
    color: theme.palette.error.main,
    padding: 3
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  enrollmentTypeWrapper: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '@media (min-width: 700px)': {
      width: '12rem',
      marginBottom: 0,
      marginRight: theme.spacing(1)
    }
  },
  enrollmentOptionsWrapper: {
    width: '100%',
    marginBottom: theme.spacing(1),
    '@media (min-width: 1280px)': {
      width: 'auto',
      flex: 1
    }
  },
  subHeading: {
    marginBottom: theme.spacing(1)
  },
  mobileAddEmployee: {
    marginBottom: theme.spacing(1)
  },
  viewText: {
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  viewByWrapper: {
    '&& .MuiInput-underline': {
      '&::before': {
        border: 0
      },
      '&::after': {
        border: 0
      }
    },
    '@media (min-width: 600px)': {
      position: 'absolute',
      right: 45
    },
    '@media (min-width: 812px)': {
      right: 24
    }
  },
  enhancedSelect: {
    paddingBottom: 0
  },
  enhancedSelectIcon: {
    top: 0
  },
  uploadButton: {
    backgroundColor: 'rgb(76, 75, 76)',
    color: theme.palette.common.white
  },
  pieChartWrapper: {
    marginBottom: theme.spacing(1)
  },
  enrollmentBuilderWrapper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    textDecoration: 'none',
    '&:hover': {
      boxShadow: `0px 2px 1px -1px ${theme.palette.primary.main}, 0px 1px 1px 0px ${theme.palette.primary.main}, 0px 1px 3px 0px ${theme.palette.primary.main}`
    },
    '&.active': {
      boxShadow: `0px 0px 1px 2px ${theme.palette.primary.main}`
    }
  }
}));
