import { axios, getUserAccessTokenCookie } from '../helpers';
import { IEmployeeRes, IEmployeeFilter, IEmployeeDetail, IEmployeePost, IEmployeePut, IEmployeeUploadProcessing } from '../models';

export const getEmployees = async (
  businessClientId: number,
  filters: {
    page?: number;
    perPage: number | string;
    sortBy?: string;
    sortDirection?: string;
    search?: string;
    schedulingStatus?: string;
    enrollmentMeetingOutcome?: string;
    postEnrollmentFollowupItem?: string;
    effectiveDateMinimum?: string;
    effectiveDateMaximum?: string;
    dashboardSchedulingStatus?: string;
    dashboardEnrollmentMeetingOutcome?: string;
    dashboardPostEnrollmentFollowupItem?: string;
  }
): Promise<IEmployeeRes> => {
  try {
    const res = await axios.get(
      `BusinessClients/${businessClientId}/Employees`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      filters
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getEmployeesUploadProcessing = async (businessClientId: number): Promise<IEmployeeUploadProcessing[]> => {
  try {
    const res = await axios.get(`BusinessClients/${businessClientId}/Employees/uploads-processing`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getSchedulingStatuses = async (parentStatus?: string): Promise<IEmployeeFilter[]> => {
  try {
    const res = await axios.get(
      `Employees/SchedulingStatuses`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      {
        DashboardSchedulingStatus: parentStatus
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getEnrollmentMeetingOutcomes = async (parentStatus?: string): Promise<IEmployeeFilter[]> => {
  try {
    const res = await axios.get(
      `Employees/EnrollmentMeetingOutcomes`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      {
        DashboardEnrollmentMeetingOutcome: parentStatus
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getFollowUpItems = async (parentStatus?: string): Promise<IEmployeeFilter[]> => {
  try {
    const res = await axios.get(
      `Employees/FollowUpItems`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      {
        DashboardPostEnrollmentFollowUpItem: parentStatus
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getEmployee = async (businessClientId: string, id: string): Promise<IEmployeeDetail> => {
  try {
    const res = await axios.get(`BusinessClients/${businessClientId}/Employees/${id}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const createEmployee = async (businessClientId: string, data: IEmployeePost) => {
  try {
    const res = await axios.post(`BusinessClients/${businessClientId}/Employees`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};

export const updateEmployee = async (businessClientId: string, employeeId: number, data: IEmployeePut) => {
  try {
    const res = await axios.put(`BusinessClients/${businessClientId}/Employees/${employeeId}`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error.response.data;
  }
};

export const deleteEmployee = async (businessClientId: string, employeeId: number) => {
  try {
    const res = await axios.delete(`BusinessClients/${businessClientId}/Employees/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const deleteCSVFile = async (businessClientId: number,csvFileId: number) => {
  try {
    const res = await axios.delete(`BusinessClients/${businessClientId}/Employees/cancel-uploads-processing/${csvFileId}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getEmployeeDetailedCommunicationTimeline = async (
  businessClientId: string,
  employeeId: number,
  filters?: {
    page: number;
    perPage: number;
  }
) => {
  try {
    const res = await axios.get(
      `BusinessClients/${businessClientId}/Employees/${employeeId}/Communications`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      filters
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getEmployeeTurboDialNotes = async (businessClientId: string, employeeId: number) => {
  try {
    const res = await axios.get(`/BusinessClients/${businessClientId}/Employees/${employeeId}/turbo-dial-notes`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getEmployeeEmail = async (businessClientId: string, employeeId: number, emailId: number) => {
  try {
    const res = await axios.get(`/BusinessClients/${businessClientId}/Employees/${employeeId}/Emails/${emailId}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadEmployees = async (businessClientId: string, data: FormData, effectiveDateConfirmed: boolean) => {
  try {
    const res = await axios.put(`/BusinessClients/${businessClientId}/Employees?effectiveDateConfirmed=${effectiveDateConfirmed}`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const downloadEmployeesTemplate = async () => {
  try {
    const res = await axios.get(`/employees/upload-template`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const calculateEmployeeEffectiveDate = async (businessClientId: string, classId: string, dateOfHire: string) => {
  try {
    const res = await axios.get(
      `/BusinessClients/${businessClientId}/Employees/calculate-effective-date`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      {
        ClassId: classId,
        DateOfHire: dateOfHire
      }
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const downloadOutcomesTemplate = async () => {
  try {
    const res = await axios.get(`/employees/outcomes/upload-template`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
export const uploadOutcomes = async (businessClientId: string, data: FormData) => {
  try {
    const res = await axios.put(`/BusinessClients/${businessClientId}/Employees/outcomes`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
