import React, { FC, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ChromePicker, MaterialPicker, MaterialPickerProps, ColorChangeHandler } from 'react-color';
import { FormControl, FormLabel, InputLabel, ClickAwayListener, FormHelperText, FormControlProps, InputLabelProps, FormHelperTextProps } from '@mui/material';
interface IColorPickerProps {
  color: string;
  FormControlProps?: FormControlProps;
  FormHelperTextProps?: FormHelperTextProps;
  helperText?: string;
  InputLabelProps?: InputLabelProps;
  label?: string;
  MaterialPickerProps?: MaterialPickerProps;
  onChangeComplete?: ColorChangeHandler;
  disable?: boolean;
  margin?: boolean;
}
export const ColorPicker: FC<IColorPickerProps> = ({
  color,
  FormControlProps,
  FormHelperTextProps,
  helperText,
  InputLabelProps,
  label,
  MaterialPickerProps,
  onChangeComplete,
  disable,
  margin
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showGradient, setShowGradient] = useState(false);
  // For more style props: https://github.com/casesandberg/react-color/blob/master/src/components/material/Material.js
  const pickerStyles = {
    default: {
      HEXlabel: { fontFamily: theme.typography.fontFamily },
      HEXinput: { fontFamily: theme.typography.fontFamily },
      RGBlabel: { fontFamily: theme.typography.fontFamily },
      RGBinput: { fontFamily: theme.typography.fontFamily }
    }
  };
  return (
    <FormControl margin={margin ? 'normal' : undefined} className={classes.wrapper} {...FormControlProps}>
      {label && (
        <InputLabel disabled={disable} className={classes.inputLabel} disableAnimation={true} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <div className={classes.colorPicker} style={{ opacity: disable ? '.3' : '1' }}>
        <div className={classes.colorPickerInner}>
          <button
            aria-label={`Pick a color. Current color is ${color}.`}
            className={classes.preview}
            onClick={() => {
              setShowGradient(true);
            }}
            style={{ backgroundColor: color, cursor: disable ? 'default' : 'pointer' }}
            type='button'
            disabled={disable}
          />

          {!disable ? (
            <MaterialPicker color={color} onChangeComplete={onChangeComplete} styles={pickerStyles} {...MaterialPickerProps} />
          ) : (
            <div className={classes.disabledColorPicker}>
              <FormLabel>Hex</FormLabel>
              <span>{color}</span>
            </div>
          )}
        </div>
        {showGradient && (
          <ClickAwayListener
            onClickAway={() => {
              setShowGradient(false);
            }}
          >
            <div className={classes.gradient}>
              <ChromePicker
                color={color}
                disableAlpha={true}
                onChangeComplete={onChangeComplete}
                styles={{
                  default: {
                    color: { display: 'none' }
                  }
                }}
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
      {helperText && <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%'
  },
  colorPicker: {
    marginTop: theme.spacing(1),
    position: 'relative',
    maxWidth: '260px'
  },
  colorPickerInner: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    //borderRadius: `${theme.shape.borderRadius}px`,
    //boxShadow: '0 2px 3px rgba(0,0,0,0.06)',
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    '& > div:nth-child(2)': {
      '& > div': {
        border: '0 !important',
        boxShadow: `none !important`,
        '& > .material-picker': {
          height: '130px !important',
          width: '130px !important'
        }
      }
    }
  },
  gradient: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 2px 3px rgba(0,0,0,0.06)',
    left: theme.spacing(1),
    padding: theme.spacing(0, 1, 1, 1),
    position: 'absolute',
    top: '90%',
    zIndex: 10,
    '& > .chrome-picker': {
      boxShadow: `none !important`,
      '& > div:last-child': {
        paddingBottom: `${theme.spacing(1)} !important`,
        paddingLeft: `${theme.spacing(1)} !important`,
        paddingRight: `${theme.spacing(1)} !important`,
        '& > div:last-child': {
          display: 'none !important'
        }
      }
    },
    '&:before': {
      borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
      borderStyle: 'solid',
      borderWidth: `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
      content: '""',
      display: 'block',
      height: 0,
      position: 'relative',
      top: theme.spacing(-1),
      width: 0,
      zIndex: 10
    }
  },
  inputLabel: {
    position: 'static',
    transform: 'none',
    color: theme.palette.primary.main
  },
  preview: {
    appearance: 'none',
    border: 0,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer',
    height: '130px',
    margin: 0,
    padding: 0,
    width: '130px'
  },
  disabledColorPicker: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '130px',
    height: '130px',
    float: 'right',
    alignItems: 'center',
    justifyContent: 'center',
    '&& > label': {
      display: 'block',
      marginBottom: theme.spacing(0.25),
      fontSize: '11px',
      fontWeight: 'bold'
    }
  }
}));
