import React from 'react';
import { IHealthSavingsAccount } from '../../../models';
import { Grid, FormControlLabel, FormLabel as MuiLabel, Box, Switch, InputAdornment } from '@mui/material';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface HealthReimbursementAccountProps {
    data: IHealthSavingsAccount;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const HealthSavingsAccount: React.FC<HealthReimbursementAccountProps> = ({
    data,
    handleBlur,
    setFieldValue,
    errors,
    touched
}) => {
    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    return (
        <Grid container spacing={1}>

            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Health Savings Account
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerHealthSavingsAccount}
                                    id='healthSavingsAccount.offerHealthSavingsAccount'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('healthSavingsAccount.offerHealthSavingsAccount', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerHealthSavingsAccount ? 'Offered' : 'Not offered'}
                        />
                    </Box>

                </Grid>

            </Grid>


            <Grid item xs={12} sm={6} md={4}>

                <GenericDropdown handleBlur={handleBlur}
                    options={generalOptions?.defaultPeriodOptions ?? []}
                    isLoading={generalOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.erContributionsDistribution ?? ''}
                    error={errors.healthSavingsAccount?.erContributionsDistribution}
                    label="How are ER contributions distributed?" id={`healthSavingsAccount.erContributionsDistribution`}
                />

            </Grid>

            <Grid item xs={12} sm={6} md={4}>

                <GenericDropdown handleBlur={handleBlur}
                    options={generalOptions?.defaultPeriodOptions ?? []}
                    isLoading={generalOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.coverageTermination ?? ''}
                    error={errors.healthSavingsAccount?.coverageTermination}
                    label="When an employee is terminated, coverage terminates on:" id={`healthSavingsAccount.coverageTermination`}
                />

            </Grid>



            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    label='Employee Max Annual Contribution'
                    name='healthSavingsAccount.employeeMaxAnnualContribution'
                    value={data?.employeeMaxAnnualContribution}
                    onBlur={handleBlur}
                    onChange={e => {
                        setFieldValue('healthSavingsAccount.employeeMaxAnnualContribution', e.target.value);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                    error={Boolean(touched.healthSavingsAccount?.employeeMaxAnnualContribution && errors.healthSavingsAccount?.employeeMaxAnnualContribution)}
                    helperText={touched.healthSavingsAccount?.employeeMaxAnnualContribution && errors.healthSavingsAccount?.employeeMaxAnnualContribution ? errors.healthSavingsAccount?.employeeMaxAnnualContribution : ''}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    label='Family Max Annual Contribution'
                    name='healthSavingsAccount.familyMaxAnnualContribution'
                    value={data?.familyMaxAnnualContribution}
                    onBlur={handleBlur}
                    onChange={e => {
                        setFieldValue('healthSavingsAccount.familyMaxAnnualContribution', e.target.value);
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                    error={Boolean(touched.healthSavingsAccount?.familyMaxAnnualContribution && errors.healthSavingsAccount?.familyMaxAnnualContribution)}
                    helperText={touched.healthSavingsAccount?.familyMaxAnnualContribution && errors.healthSavingsAccount?.familyMaxAnnualContribution ? errors.healthSavingsAccount?.familyMaxAnnualContribution : ''}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Bank Name'
                    name='healthSavingsAccount.bankName'
                    value={data?.bankName}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('healthSavingsAccount.bankName', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Contingent Plan(s)'
                    name='healthSavingsAccount.contingentPlans'
                    value={data?.contingentPlans}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('healthSavingsAccount.contingentPlans', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Setup Requirements'
                    name='healthSavingsAccount.setupRequirements'
                    value={data?.setupRequirements}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('healthSavingsAccount.setupRequirements', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='ER Contribution'
                    name='healthSavingsAccount.erContribution'
                    value={data?.erContribution}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('healthSavingsAccount.erContribution', e.target.value)}
                />
            </Grid>

        </Grid>
    );
};

export default HealthSavingsAccount;
