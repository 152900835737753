import React from 'react';
import { Box, Grid, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { useSharedStyles } from '../../../../../../shared/styles';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import useInsuranceCoverageOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useInsuranceCoverageOptionsDropdownCollection';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues, IDentalInsurance } from '../../../../../../../../../models';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const DentalInsurance: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const data: IDentalInsurance = values.dentalInsurance;

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'


    const insuranceCoverageOptionsRequest = useInsuranceCoverageOptionsDropdownCollection();
    const insuranceCoverageOptions = insuranceCoverageOptionsRequest.data;
    const insuranceCoverageOptionsLoading = insuranceCoverageOptionsRequest.status === "loading";

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Dental Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerDentalCoverage}
                                    id='dentalInsurance.offerDentalCoverage'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('dentalInsurance.offerDentalCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={
                                data?.offerDentalCoverage ? 'Offered' : 'Not offered'
                            }
                        />
                    </Box>
                    <SectionsFilesList category='Dental' />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Dental Carrier'
                    name='dentalInsurance.dentalCarrier'
                    value={data?.dentalCarrier}
                    onChange={e => {
                        setFieldValue('dentalInsurance.dentalCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.dentalInsurance?.dentalCarrier} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Previous Dental Carrier'
                    name='dentalInsurance.previousDentalCarrier'
                    value={data?.previousDentalCarrier}
                    onChange={e => {
                        setFieldValue('dentalInsurance.previousDentalCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.dentalInsurance?.previousDentalCarrier} />
            </Grid>

            <Grid item xs={12} sm={6}>

                <GenericDropdown handleBlur={handleBlur}
                    options={insuranceCoverageOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={insuranceCoverageOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.dentalPlanOffering ?? ''}
                    error={errors?.dentalInsurance?.dentalPlanOffering}
                    label="Dental Plan Offering" id={`dentalInsurance.dentalPlanOffering`}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalOptions?.defaultPeriodOptions ?? []}
                    isLoading={generalOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.coverageTerminatesOn ?? ''}
                    error={errors?.dentalInsurance?.coverageTerminatesOn}
                    label="When an employee is terminated, coverage terminates on:" 
                    id={`dentalInsurance.coverageTerminatesOn`}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Dental Notes'
                    name='dentalInsurance.dentalNotes'
                    value={data?.dentalNotes}
                    onChange={e => {
                        setFieldValue('dentalInsurance.dentalNotes', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.dentalInsurance?.dentalNotes} />
            </Grid>
        </Grid>
    );
};

export default DentalInsurance;
