import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import { Collapse } from "@mui/material";
import { AllBusinessClientSubMenusTypes, BusinessClientMenusTypes, MenuItem } from "../../pages/admin/business-client-detail/BusinessClientDetailPage/MenuData/types";
import { useLateralFormMenuControl } from "../../pages/admin/business-client-detail/contexts/LateralFormMenuControlContext";
import ListMenuItem from "./ListMenuItem";
import { useMenuSubMenuWithErrors } from "./contexts/MenuSubMenuWithErrorsContext";

const drawerWidth = 240;

interface Props {
    menuData: MenuItem[]
    window?: () => Window;
}

export default function ListMenuSubMenu({ menuData, window }: Props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles();
    const { menusWithErrors, subMenusWithErrors } = useMenuSubMenuWithErrors();

    const { selectedMenu, setMenu, selectedSubMenu, setSubMenu, expandedMenu, setExpandedMenu } = useLateralFormMenuControl();

    function handleOpenSettings(item: MenuItem) {

        if (expandedMenu === item.id) {
            setExpandedMenu(null);
        } else {
            setExpandedMenu(item.id);
        }


        if (selectedMenu !== item.id) {
            setMenu(item);
        }


    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <List sx={{
            width: '235px', position: 'sticky',
            top: '10px'
        }}>
            {
                menuData.map((item, index) => (
                    <>

                        <ListMenuItem disablePadding={true}
                            key={item.id}
                            hasErrors={menusWithErrors.includes(item.id as BusinessClientMenusTypes)}
                            item={item}
                            selected={selectedMenu === item.id}
                            expanded={expandedMenu === item.id}
                            onClick={() => handleOpenSettings(item)} />



                        {item.subMenu && item.subMenu?.length && (
                            <Collapse
                                key={`Collapse-${item.id}`}
                                in={expandedMenu === item.id}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding key={`List-${item.id}`}>
                                    {item.subMenu?.map((subMenu, index) => (

                                        <ListMenuItem disablePadding={false}
                                            key={subMenu.id}
                                            hasErrors={subMenusWithErrors.includes(subMenu.id as AllBusinessClientSubMenusTypes)}
                                            item={subMenu}
                                            insetTitle={true}
                                            selected={selectedSubMenu === subMenu.id}
                                            onClick={() => setSubMenu(subMenu.id as AllBusinessClientSubMenusTypes)} />

                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </>
                ))
            }
        </List >
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div>

            <Box
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                {drawer}
            </Box >

            <Toolbar sx={{ position: 'absolute', padding: '0', top: '100px' }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.mobileMenuButton}
                    sx={{
                        mr: 2, display: { sm: "none" },



                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>

            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth
                    }
                }}
            >
                {drawer}
            </Drawer>

        </div>
    );
}

const useStyles = makeStyles(theme => ({

    mobileMenuButton: {
        position: 'fixed',
        top: theme.spacing(5.5),
        left: theme.spacing(1.3),
        backgroundColor: '#f5f5f5',
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);',
        zIndex: 2,
        '@media (min-width: 676px)': {
            top: theme.spacing(7)
        }
    }

}));