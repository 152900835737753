import React, { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

interface IPaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
}

export const TablePaginationActions: FC<IPaginationActionsProps> = ({ count, page, rowsPerPage, onPageChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery('(min-width: 960px)');

  const handleFirstPageButtonClick = () => {
    onPageChange(null, 0);
  };

  const handleBackButtonClick = () => {
    onPageChange(null, page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(null, page + 1);
  };

  const handleLastPageButtonClick = () => {
    onPageChange(null, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      {isDesktop && (
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page' size='large'>
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      )}
      <IconButton className={classes.button} onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page' size='large'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton className={classes.button} onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='next page' size='large'>
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {isDesktop && (
        <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='last page' size='large'>
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2.5)
    }
  },
  button: {
    [theme.breakpoints.down('lg')]: {
      padding: 0
    }
  }
}));
