import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[A-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])\S{8,20}$/;
export const isValidPassword = (value: string = ''): boolean | string => passwordRegex.test(value);
export const PasswordRequirements = (): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      Please enter a valid password
      <ul className={classes.list}>
        <li>Must be between 8 and 20 characters long </li>
        <li> Must have at least one numeric character </li>
        <li> Must have at least one "special" character, ex: !$#@% </li>
      </ul>
    </>
  );
};
const useStyles = makeStyles(() => {
  return {
    list: {
      marginTop: 0
    }
  };
});
