import { ExpandMore } from '@mui/icons-material';
import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, Box, Theme, Typography } from '@mui/material';

interface IAccordionProps {
  title: string | JSX.Element;
  defaultExpanded?: boolean;
  component?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  variant?: 'inherit' | 'button' | 'overline' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
  hasRequiredFields?: boolean;
  hasErrors?: boolean;
}

export const Accordion: FC<IAccordionProps> = ({ title, children, defaultExpanded, component = 'p', variant = 'inherit', hasRequiredFields, hasErrors }) => {
  const classes = useStyles();
  return (
    <MuiAccordion  TransitionProps={{ unmountOnExit: true }} className={classes.root} defaultExpanded={defaultExpanded}>
      <MuiAccordionSummary data-cy={`Accordion ${title}`} className={classes.accordion} expandIcon={<ExpandMore />} aria-controls={`${title}-content`} id={`${title}-content`}>
        <Typography className={classes.boldName} color='primary' component={component} variant={variant}>
          <Box component='span' sx={{ color: hasErrors ? theme => theme.palette.error.main : 'inherit' }}>
            {title}
            {hasRequiredFields && ' *'}
          </Box>
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails className={classes.details}>{children}</MuiAccordionDetails>
    </MuiAccordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    '&:focus': {
      border: '1px dashed red'
    }
  },
  accordion: {
    padding: '0 16px',
    '&& .MuiAccordionSummary-expandIcon': {
      padding: 3
    }
  },
  boldName: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderTop: `1px solid ${theme.palette.grey[300]}`
  }
}));
