import { useEffect, useContext } from "react";

import useErrorMessage from "../../context/ErrorMessageCtx";
import { RequestState } from "../../models/request-state";
import { DropDownCollectionsCtx } from "../../context/DropDownCollectionsCtx";
import { getGeneralDropdownsOptionsRequest } from "../../fetch";
import { IGeneralDropdownsOptions } from "../../models";

const useGeneralDropdownsOptionsCollection = (): RequestState<IGeneralDropdownsOptions> => {
    const [, setErrorMessage] = useErrorMessage();
    const { generalDropdownsOptionsRequest, dispatchGeneralDropdownsOptionsRequest } = useContext(DropDownCollectionsCtx);

    const status = generalDropdownsOptionsRequest.status;


    useEffect(() => {

        if (status !== "idle") return;

        dispatchGeneralDropdownsOptionsRequest({ type: 'REQUEST_LOADING' });

        const fetchCollection = async () => {
            try {

                const res = await getGeneralDropdownsOptionsRequest();
                if (res) {

                    dispatchGeneralDropdownsOptionsRequest({ type: 'REQUEST_SUCCESS', payload: res })
                } else {
                    setErrorMessage('Error loading options, please try again.');
                    dispatchGeneralDropdownsOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading, please try again.' })
                }
            } catch (error) {
                console.error(error);
                dispatchGeneralDropdownsOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading, please try again.' })
                setErrorMessage('Error loading options, please try again.');
            }


        };

        fetchCollection();
    }, [status, dispatchGeneralDropdownsOptionsRequest, setErrorMessage]);

    return generalDropdownsOptionsRequest;

};

export default useGeneralDropdownsOptionsCollection