import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
// images
import EALogo from '../../assets/logos/ea-logo-sm-2021.png';

interface ILogoProps {
  className?: string;
  isSmall?: boolean;
  src?: string;
  alt?: string;
}

export const BrokerLogo: FC<ILogoProps> = ({ className, isSmall = false, src=EALogo, alt="Logo" }) => {
  const classes = useStyles({ isSmall });
  return <img src={src} alt={alt} className={clsx(classes.logo, className)} />;
};

const useStyles = makeStyles<Theme, { isSmall?: boolean }>((theme: Theme) => ({
  logo: ({ isSmall }) =>
    isSmall
      ? {
        width: 115,
        height: 'auto',
        '@media (min-width: 812px)': {
          width: 175
        }
      }
      : {
        width: 175,
        height: 'auto',
        '@media (min-width: 812px)': {
          width: 250
        }
      }
}));
