import { useEffect, useContext } from "react";

import useErrorMessage from "../../context/ErrorMessageCtx";
import { IDropdownResponse } from "../../models";
import { RequestState } from "../../models/request-state";
import { DropDownCollectionsCtx } from "../../context/DropDownCollectionsCtx";
import { getContactRoles } from "../../fetch";

const useContactRolesDropdownCollection = (): RequestState<IDropdownResponse[]> => {
    const [, setErrorMessage] = useErrorMessage();
    const { contactRolesRequest, dispatchContactRoles } = useContext(DropDownCollectionsCtx);

    const status = contactRolesRequest.status;


    useEffect(() => {

        if (status !== "idle") return;

        dispatchContactRoles({ type: 'REQUEST_LOADING' });

        const fetchCollection = async () => {
            try {

                const res = await getContactRoles();
                if (res) {

                    dispatchContactRoles({ type: 'REQUEST_SUCCESS', payload: res })
                } else {
                    setErrorMessage('Error loading contact roles, please try again.');
                    dispatchContactRoles({ type: 'REQUEST_ERROR', payload: 'Error loading industries, please try again.' })
                }
            } catch (error) {
                console.error(error);
                dispatchContactRoles({ type: 'REQUEST_ERROR', payload: 'Error loading industries, please try again.' })
                setErrorMessage('Error loading contact roles, please try again.');
            }


        };

        fetchCollection();
    }, [status, dispatchContactRoles, setErrorMessage]);

    return contactRolesRequest;

};

export default useContactRolesDropdownCollection