import { createTheme, Theme, lighten, darken } from '@mui/material/styles';

const baseFontSize = 14; // .875rem

export const theme: Theme = createTheme({
  spacing: 16, // 1rem
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    // type: 'light',
    primary: {
      light: lighten('#11a5c5', 0.1),
      main: '#11a5c5',
      dark: darken('#11a5c5', 0.4),
      contrastText: '#fff'
    },
    secondary: {
      light: lighten('#000', 0.1),
      main: '#000',
      dark: darken('#000', 0.4),
      contrastText: '#fff'
    },
    error: {
      main: '#A94442'
    },
    warning: {
      main: '#8A6D3B'
    },
    info: {
      main: '#31708F'
    },
    success: {
      main: '#3C763D'
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    fontFamily: 'droid-sans, sans-serif',
    fontSize: baseFontSize,
    h1: {
      fontSize: baseFontSize * 2.25,
      fontFamily: 'DinPro-Light, sans-serif'
    },
    h2: {
      fontSize: baseFontSize * 2,
      fontFamily: 'DinPro-Light, sans-serif'
    },
    h3: {
      fontSize: baseFontSize * 1.75,
      fontFamily: 'DinPro-Light, sans-serif'
    },
    h4: {
      fontSize: baseFontSize * 1.5,
      fontFamily: 'DinPro-Light, sans-serif'
    },
    h5: {
      fontSize: baseFontSize * 1.25,
      fontFamily: 'DinPro-Light, sans-serif'
    }
  }
});

// Break this out so that we can reference palette and other options
theme.components = {
  MuiButton: {
    defaultProps: {
      disableElevation: true
    },
    styleOverrides: {
      root: {
        borderRadius: 0
      }
    }
  },
  MuiPaper: {
    defaultProps: {
      square: true,
      elevation: 1
    }
  },
  MuiAccordion: {
    defaultProps: {
      square: true
    }
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary'
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        borderRadius: 0
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        lineHeight: 1.25
      }
    }
  }
};
