import React, { createContext, useContext, useState } from 'react';
import { AllBusinessClientSubMenusTypes, BusinessClientMenusTypes, MenuItem, MenuSubMenuType, SubMenuAccordionCategoryTypes } from '../BusinessClientDetailPage/MenuData/types';
import { unstable_batchedUpdates } from "react-dom";

type LateralFormMenuControlContextType = {
  expandedMenu: BusinessClientMenusTypes;
  selectedMenu: BusinessClientMenusTypes;
  selectedSubMenu: AllBusinessClientSubMenusTypes;

  setExpandedMenu: React.Dispatch<React.SetStateAction<any>>;

  selectAccordionCategory?: SubMenuAccordionCategoryTypes;
  setMenuSubMenuAccordionCategory: (menu: BusinessClientMenusTypes, subMenu: AllBusinessClientSubMenusTypes, accordionCategory?: SubMenuAccordionCategoryTypes) => void;
  setMenu: (item: MenuItem) => void;

  setSubMenu: (subMenu: AllBusinessClientSubMenusTypes) => void;
};

const LateralFormMenuControlContext = createContext<LateralFormMenuControlContextType | undefined>(
  undefined
);

export const useLateralFormMenuControl = () => {
  const context = useContext(LateralFormMenuControlContext);
  if (!context) {
    throw new Error('useLateralFormMenuControl must be used within a LateralFormMenuControlProvider');
  }
  return context;
};

export const LateralFormMenuControlProvider: React.FC = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState<BusinessClientMenusTypes>("home");
  const [selectedSubMenu, setSelectedSubMenu] = useState<AllBusinessClientSubMenusTypes>(null);

  const [selectAccordionCategory, setSelectAccordionCategory] = useState<SubMenuAccordionCategoryTypes>();

  const [expandedMenu, setExpandedMenu] = React.useState<BusinessClientMenusTypes>(null);

  const setMenu = (item: MenuItem) => {

    unstable_batchedUpdates(() => {
      setSelectedMenu(item.id as BusinessClientMenusTypes);

      if (item.subMenu && item.subMenu?.length) {
        setSelectedSubMenu(item.subMenu[0].id as AllBusinessClientSubMenusTypes)
      } else {
        setSelectedSubMenu(null);
      }

      setSelectAccordionCategory(undefined);

    }, 0);


  }


  const setMenuSubMenuAccordionCategory = (menu: BusinessClientMenusTypes, subMenu: AllBusinessClientSubMenusTypes, accordionCategory?: SubMenuAccordionCategoryTypes) => {

    unstable_batchedUpdates(() => {
      setExpandedMenu(menu);
      setSelectedMenu(menu);
      setSelectedSubMenu(subMenu);
      setSelectAccordionCategory(accordionCategory);
    }, 0);
  }

  const setSubMenu = (subMenu: AllBusinessClientSubMenusTypes) => {

    unstable_batchedUpdates(() => {
      setSelectedSubMenu(subMenu);
      setSelectAccordionCategory(undefined);
    }, 0);


  }

  return (
    <LateralFormMenuControlContext.Provider
      value={{
        setExpandedMenu,
        selectedMenu,
        selectedSubMenu,
        setMenu,
        expandedMenu,
        selectAccordionCategory,
        setMenuSubMenuAccordionCategory,
        setSubMenu,

      }}
    >
      {children}
    </LateralFormMenuControlContext.Provider>
  );
};
