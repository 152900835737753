import { Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import { useSharedStyles } from '../../../../../shared/styles';

import { Accordion } from '../../../../../../../../components';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import BenAdminSystem from './BenAdminSystem/BenAdminSystem';
import EnrollmentConditions from './EnrollmentConditions/EnrollmentConditions';
import FieldErrorMessage from '../../../../../../../../components/field-error-message/FieldErrorMessage ';
export default function EnrollmentPreferences() {

    const classes = useSharedStyles();
    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const validateAction = menusSubMenusValidationStatusMap.get('enrollment_preferences');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;


    return (
        <div role='tabpanel' id='enrollment-preferences-tab-content' aria-labelledby='enrollment-preferences-tab-content' className={classes.tabContent}>

            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />

            <Grid item xs={12} sm={6} marginBottom={1}>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    autoComplete='nope'
                    label='What was the previous year enrollment method? How did open enrollment go?'
                    name='previousYearEnrollmentDetails'
                    value={values.previousYearEnrollmentDetails}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('previousYearEnrollmentDetails', e.target.value)}
                />
                <FieldErrorMessage message={errors.previousYearEnrollmentDetails} />
            </Grid>


            <Accordion title='BenAdmin System' component='h3' variant='h4'>
                <BenAdminSystem />

            </Accordion>

            <Accordion title='Enrollment Conditions' component='h3' variant='h4'>
                <EnrollmentConditions />
            </Accordion>


        </div>
    );
}
