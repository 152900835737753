import { FC } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '../../components';
import { Card, CardContent, Typography, CardMedia, Grid } from '@mui/material';
import { HourglassEmpty, EventAvailable, CheckCircle, Business } from '@mui/icons-material';
// models
import { IBrokerDashboard, IBrokerDashboardEmployeeCount } from '../../models';
import { formatDate } from '../../helpers';

interface IClientCardProps {
  client: IBrokerDashboard;
  totalCount: number;
}

export const ClientCard: FC<IClientCardProps> = ({ client, totalCount }) => {
  const classes = useStyles(client.isOpenEnrollment);
  const sortSchedulingStatuses = (data: IBrokerDashboardEmployeeCount[]) => {
    const sortedArray = [
      { status: 'NotScheduled', count: 0 },
      { status: 'Scheduled', count: 0 },
      { status: 'Complete', count: 0 }
    ];

    for (const item of data) {
      if (item.status === 'NotScheduled') {
        sortedArray[0].count = item.count;
      }
      if (item.status === 'Scheduled') {
        sortedArray[1].count = item.count;
      }
      if (item.status === 'Complete') {
        sortedArray[2].count = item.count;
      }
    }
    return sortedArray;
  };

  const calculateBarPercentage = (count: number) => {
    const value = (count / totalCount) * 100;
    let maxValue = 50;
    let minValue = 25;
    return value > minValue ? Math.min(maxValue, value) + '%' : minValue + '%';
  };

  return (
    <Link to={`/business-clients/${client.businessClientId}/builder`}>
      <Card className={classes.root} style={client.isOpenEnrollment ? { minHeight: 200 } : null}>
        {client.logoUrl ? (
          <CardMedia className={classes.image} image={client.logoUrl} title={client.name} />
        ) : (
          <div className={classes.image}>
            <Business className={classes.defaultIcon} />
          </div>
        )}
        <div className={classes.details}>
          <CardContent className={clsx(classes.content, classes.alignCenter)}>
            <Typography component='h2' variant='h2' className={classes.clientTitle}>
              {client.name}
            </Typography>
            {client.isOpenEnrollment && (
              <>
                <Typography variant='subtitle1' color='primary'>
                  Open Enrollment {client.openEnrollmentEffectiveDate ? `- Effective Date: ${formatDate(client.openEnrollmentEffectiveDate)}` : null}
                </Typography>
                {client.openEnrollmentStartDate && (
                  <Typography variant='subtitle2' color='primary'>
                    {client.openEnrollmentStartDate && `Start Date: ${formatDate(client.openEnrollmentStartDate)}`}
                  </Typography>
                )}
                {client.enrollmentDeadline && (
                  <Typography variant='subtitle2' color='primary'>
                    {client.enrollmentDeadline && `End Date: ${formatDate(client.enrollmentDeadline)}`}
                  </Typography>
                )}
              </>
            )}
          </CardContent>
          {client.employeeStatusCounts.length > 0 && (
            <Grid container className={classes.bar}>
              {sortSchedulingStatuses(client.employeeStatusCounts).map((statusCount, index) => {
                return (
                  <div
                    key={`${index}`}
                    className={clsx(
                      statusCount.status === 'NotScheduled' && classes.barNotScheduled,
                      statusCount.status === 'Scheduled' && classes.barScheduled,
                      statusCount.status === 'Complete' && classes.barCompleted
                    )}
                    style={{
                      flexBasis: calculateBarPercentage(statusCount.count) || 'auto',
                      width: calculateBarPercentage(statusCount.count) || null
                    }}
                  >
                    {statusCount.status === 'NotScheduled' && <HourglassEmpty />}
                    {statusCount.status === 'Scheduled' && <EventAvailable />}
                    {statusCount.status === 'Complete' && <CheckCircle />}
                    <Typography variant='subtitle1' component='span' className={classes.employeesCountText}>
                      {statusCount.count}
                    </Typography>
                  </div>
                );
              })}
            </Grid>
          )}
        </div>
      </Card>
    </Link>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    borderRadius: 0,
    boxShadow: 'none',
    border: `1px solid black`,
    flexDirection: 'column',
    minHeight: 150,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  content: {
    flex: '1 0 auto',
    backgroundColor: theme.palette.grey[200]
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  clientTitle: {
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0
    }
  },
  image: {
    borderBottom: `1px solid black`,
    backgroundColor: '#000',
    minHeight: 140,
    backgroundSize: 120,
    [theme.breakpoints.up('sm')]: {
      width: 200,
      borderRight: `1px solid black`,
      borderBottom: 'none',
      flexDirection: 'row'
    }
  },
  bar: {
    height: 40,
    color: '#fff',
    width: `calc(100% - -1px)`,
    flexFlow: 'row nowrap'
  },
  barNotScheduled: {
    backgroundColor: '#4C4B4C',
    display: 'flex',
    alignItems: 'center',
    padding: `0 8px`,
    justifyContent: 'space-between'
  },
  barScheduled: {
    backgroundColor: '#7CDEF3',
    display: 'flex',
    alignItems: 'center',
    padding: `0 8px`,
    justifyContent: 'space-between'
  },
  barCompleted: {
    backgroundColor: '#11A5C5',
    display: 'flex',
    alignItems: 'center',
    padding: `0 8px`,
    justifyContent: 'space-between'
  },
  employeesCountText: {
    marginLeft: 5
  },
  defaultIcon: {
    height: 140,
    width: '100%',
    fill: '#fff'
  }
}));
