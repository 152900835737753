import { useSharedStyles } from "../../../../shared/styles";

const DefaultTabBase = ({ children }) => {

    const classes = useSharedStyles();

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            {children}
        </div>
    );
}

export { DefaultTabBase }