import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { UserContext } from '../../context';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = props => {
  const { user, isFetching } = useContext(UserContext);

  const location = useLocation();

  if (isFetching) {
    return null;
  }

  if (!user && !isFetching) {
    return (
      <Redirect
        to={{
          pathname: '/login', // redirect to login screen
          state: { from: location }
        }}
      />
    );
  }

  return <Route {...props} />;
};
