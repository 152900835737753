import { RequestState } from "../models/request-state";

export type RequestAction<T> =
    | { type: 'REQUEST_LOADING' }
    | { type: 'REQUEST_SUCCESS'; payload: T }
    | { type: 'REQUEST_ERROR'; payload: string };

const requestReducer = <T>() => (state: RequestState<T>, action: RequestAction<T>): RequestState<T> => {
    switch (action.type) {
        case 'REQUEST_LOADING':
            return { ...state, status: 'loading', data: null, error: null };
        case 'REQUEST_SUCCESS':
            return { ...state, status: 'success', data: action.payload, error: null };
        case 'REQUEST_ERROR':
            return { ...state, status: 'error', data: null, error: action.payload };
        default:
            return state;
    }
};
export default requestReducer;