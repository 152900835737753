import React from 'react';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
// Components
import { Button, Grid } from '@mui/material';
import { Link } from '../link';
// Icons
import { Home, SupervisorAccount, LocationCity, Work } from '@mui/icons-material';

export const AdminMenu = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <div className={classes.adminNavigation}>
      <Grid container direction='column' justifyContent='space-between' alignItems='center'>
        <div>
          <Link to='/' type='white'>
            <Button size='large' startIcon={<Home fontSize='large' />} className={clsx(classes.adminMenuButton, pathname === '/' && 'active')}>
              Home
            </Button>
          </Link>
          <Link to='/manage-users' type='white'>
            <Button size='large' startIcon={<SupervisorAccount fontSize='large' />} className={clsx(classes.adminMenuButton, pathname === '/manage-users' && 'active')}>
              Manage Users
            </Button>
          </Link>
          <Link to='/manage-brokers' type='white'>
            <Button size='large' startIcon={<Work fontSize='large' />} className={clsx(classes.adminMenuButton, pathname === '/manage-brokers' && 'active')}>
              Manage Brokers
            </Button>
          </Link>
          <Link to='/manage-business-clients' type='white' testId='manage-business-clients'>
            <Button size='large' startIcon={<LocationCity fontSize='large' />} className={clsx(classes.adminMenuButton, pathname.includes('/manage-business-clients') && 'active')}>
              Manage Business Clients
            </Button>
          </Link>
        </div>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  adminNavigation: {
    width: 100,
    display: 'block',
    borderRight: '1px solid'
  },
  adminMenuButton: {
    width: '100%',
    padding: `${theme.spacing(2)} 4px`,
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'initial',
    color: '#000',
    lineHeight: 1.25,
    '& > span': {
      margin: 0
    },
    '&.active': {
      color: theme.palette.primary.main,
      borderRight: `4px solid ${theme.palette.primary.main}`
    }
  }
}));
