import React from 'react';
import { Box, Grid, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';

import { useFormikContext } from 'formik';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import useMedicalInsuranceOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useMedicalInsuranceOptionsDropdownCollection';
import { useSharedStyles } from '../../../../../../shared/styles';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const MedicalInsurance: React.FC = () => {
    const classes = useSharedStyles();

    const { values: data, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const medicalInsuranceOptionsRequest = useMedicalInsuranceOptionsDropdownCollection();
    const medicalInsuranceOptions = medicalInsuranceOptionsRequest.data;
    const medicalInsuranceLoading = medicalInsuranceOptionsRequest.status === "loading";


    return (
        <Grid container spacing={1}>

            <Grid container spacing={1}>
                <Grid item marginLeft={1} sx={{ width: '100%' }}>
                    <Box marginBottom='1rem' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px'
                    }}>

                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Medical
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    data-cy="offerMedicalInsurance"
                                    color='primary'
                                    checked={data.medicalInsurance?.offerMedicalInsurance}
                                    id='medicalInsurance.offerMedicalInsurance'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('medicalInsurance.offerMedicalInsurance', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data.medicalInsurance?.offerMedicalInsurance ? 'Offered' : 'Not offered'}
                        />


                    </Box>
                    <SectionsFilesList category='MedicalInsurance' />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Medical Carrier'
                    name='medicalInsurance.medicalCarrier'
                    value={data.medicalInsurance?.medicalCarrier}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('medicalInsurance.medicalCarrier', e.target.value)}
                />
                <FieldErrorMessage message={errors?.medicalInsurance?.medicalCarrier} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Previous Medical Carrier'
                    name='medicalInsurance.previousMedicalCarrier'
                    value={data.medicalInsurance?.previousMedicalCarrier}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('medicalInsurance.previousMedicalCarrier', e.target.value)}
                />
                <FieldErrorMessage message={errors?.medicalInsurance?.previousMedicalCarrier} />
            </Grid>
            <Grid item xs={12} sm={6}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.medicalPlanOfferingOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.medicalPlanOffering ?? ''}
                    error={errors.medicalInsurance?.medicalPlanOffering}
                    label='Medical Plan Offering' id={`medicalInsurance.medicalPlanOffering`}
                />

            </Grid>

            <Grid item xs={12} sm={6}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.medicalDeductibleTypeOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.medicalDeductibleType ?? ''}
                    error={errors.medicalInsurance?.medicalDeductibleType}
                    label='Medical Deductible Type' id={`medicalInsurance.medicalDeductibleType`}
                />

            </Grid>

            <Grid item xs={12} sm={6}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.telemedicineOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.telemedicine ?? ''}
                    error={errors.medicalInsurance?.telemedicine}
                    label='Telemedicine' id={`medicalInsurance.telemedicine`}
                />

            </Grid>

            <Grid item xs={12} sm={6}>

                <GenericDropdown handleBlur={handleBlur}
                    options={medicalInsuranceOptions?.medicalCoverageTerminatesOnOptions ?? []}
                    isLoading={medicalInsuranceLoading}
                    setFieldValue={setFieldValue}
                    value={data.medicalInsurance?.medicalCoverageTerminatesOn ?? ''}
                    error={errors.medicalInsurance?.medicalCoverageTerminatesOn}
                    label='When an employee is terminated, coverage terminates on:' id={`medicalInsurance.medicalCoverageTerminatesOn`}
                />

            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Medical Notes'
                    name='medicalInsurance.medicalNotes'
                    value={data.medicalInsurance?.medicalNotes}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('medicalInsurance.medicalNotes', e.target.value)}
                />
                <FieldErrorMessage message={errors.medicalInsurance?.medicalNotes} />
            </Grid>
        </Grid>
    );
};

export default MedicalInsurance;
