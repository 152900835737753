import { ProductDetailsSubMenusTypes, TabOptionType } from '../../../../MenuData/types';
import { TabsBase } from '../../../base/tabs-base';

interface ITabsProps {
    validateForm: () => void;
}

const tabOptions: TabOptionType<ProductDetailsSubMenusTypes>[] = [
    {
        value: 0,
        label: 'Medical',
        subMenuRelatedId: "medical"
    },
    {
        value: 1,
        label: 'Financial',
        subMenuRelatedId: "financial"
    },
    {
        value: 2,
        label: 'Ancillary',
        subMenuRelatedId: "ancillary"
    },
    {
        value: 3,
        label: 'Worksite',
        subMenuRelatedId: "worksite"
    },
    {
        value: 4,
        label: 'Supplemental',
        subMenuRelatedId: "supplemental"
    }
];

const Index = ({ validateForm }: ITabsProps) => {
    return (
        <TabsBase validateForm={validateForm} tabOptions={tabOptions} />
    )
}

export default Index;