import React from 'react';
import { Field, FieldArray } from 'formik';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSharedStyles } from './shared/styles';
import { IEligibilityRulesDeduction } from '../../../models';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useBusinessClassOptionsDropdownCollection from '../../../hooks/dropdown-collection/useBusinessClassOptionsDropdownCollection';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';

interface IEligibilityRulesDeductionTableProps {
    eligibilityRulesDeductions: IEligibilityRulesDeduction[];
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
}

const EligibilityRulesDeductionTable: React.FC<IEligibilityRulesDeductionTableProps> = ({
    eligibilityRulesDeductions,
    handleBlur,
    setFieldValue,
    errors
}) => {
    const classes = useSharedStyles();

    const businessClassOptionsRequest = useBusinessClassOptionsDropdownCollection();
    const businessClassOptions = businessClassOptionsRequest.data;
    const businessClassLoading = businessClassOptionsRequest.status === "loading";

    return (
        <FieldArray name='eligibilityRules.eligibilityRulesDeductions'>
            {({ push, remove }) => (
                <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
                    {eligibilityRulesDeductions.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Deduction Frequency</TableCell>
                                        <TableCell>Hours Required for Eligibility</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {eligibilityRulesDeductions.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <GenericDropdown handleBlur={handleBlur}
                                                    options={businessClassOptions?.classPayCycles ?? []}
                                                    isLoading={businessClassLoading}
                                                    setFieldValue={setFieldValue}
                                                    value={item.deductionFrequency ?? ''}
                                                    error={errors.eligibilityRules?.eligibilityRulesDeductions?.[index]?.deductionFrequency}
                                                    label='Deduction Frequency'
                                                    id={`eligibilityRules.eligibilityRulesDeductions.${index}.deductionFrequency`}
                                                />

                                            </TableCell>
                                            <TableCell>
                                                <Field name={`eligibilityRules.eligibilityRulesDeductions.${index}.hoursRequiredForEligibility`}>
                                                    {({ field }) => (
                                                        <PerformantTextField
                                                            {...field}
                                                            fullWidth
                                                            variant='outlined'
                                                            size='small'
                                                            autoComplete='nope'
                                                            label='Hours Required for Eligibility'
                                                            onBlur={handleBlur}
                                                        />
                                                    )}
                                                </Field>
                                                <FieldErrorMessage message={errors.eligibilityRules?.eligibilityRulesDeductions?.[index]?.hoursRequiredForEligibility} />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    startIcon={<Close />}
                                                    onClick={() => remove(index)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <></>
                    )}

                    {eligibilityRulesDeductions.length < 8 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    deductionFrequency: null,
                                    hoursRequiredForEligibility: null,
                                })
                            }
                        >
                            Add Deduction Rule
                        </Button>
                    )}
                </div>
            )}
        </FieldArray>
    );
};

export default EligibilityRulesDeductionTable;
