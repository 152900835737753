import { Typography, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Block, AccountCircle } from '@mui/icons-material';
import clsx from 'clsx';
import React, { FC, HTMLAttributes } from 'react';

interface IMessageProps extends HTMLAttributes<HTMLDivElement> {
  action?: (e: React.MouseEvent) => void;
  actionLink?: JSX.Element;
  actionText?: string;
  drawer?: boolean;
  id?: string;
  message: string;
  size?: 'small' | 'medium';
  stateType?: 'cart' | 'empty' | 'error';
  title?: string;
  icon?: 'User' | 'NotFound';
}

export const Message: FC<IMessageProps> = props => {
  const { action, actionLink, actionText, id, icon, message, stateType = 'empty', title, ...divProps } = props;

  const classes = useStyles(props);

  return (
    <div {...divProps} className={clsx(classes.state, props.className)}>
      {icon && icon === 'User' ? <AccountCircle className={classes.icon} /> : <Block className={classes.icon} />}
      <div className={classes.actions}>
        {title && (
          <Typography className={classes.title} variant='body1'>
            {title}
          </Typography>
        )}
        <Typography className={classes.message} variant='body1'>
          {message}
        </Typography>
        {action && (
          <Button
            color='primary'
            id={`message-action-${id}`}
            onClick={e => {
              action(e);
            }}
            variant='contained'
          >
            {actionText || (stateType === 'empty' ? 'Undo' : 'Refresh')}
          </Button>
        )}
        {actionLink && actionLink}
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, IMessageProps>(theme => {
  return {
    actions: {
      maxWidth: '100%',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left'
      }
    },
    state: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      margin: '0 auto',
      maxWidth: 700,
      padding: theme.spacing(3),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },
    icon: {
      color: props => (props.drawer ? theme.palette.grey[400] : theme.palette.text.secondary),
      display: 'block',
      fontSize: props => (props.size === 'small' ? '2.5rem' : '5rem'),
      flexShrink: 0,
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        fontSize: props => (props.size === 'small' ? '3.5rem' : '7rem'),
        margin: 0,
        marginRight: theme.spacing(3)
      }
    },
    message: {
      color: props => (props.drawer ? theme.palette.grey[700] : theme.palette.text.secondary),
      fontSize: props => (props.size === 'small' ? '0.875rem' : '1rem'),
      fontWeight: 400,
      lineHeight: 1.4,
      margin: theme.spacing(1.5, 0),
      [theme.breakpoints.up('sm')]: {
        fontSize: props => (props.size === 'small' ? '1rem' : '1.125rem'),
        margin: theme.spacing(0, 0, 1)
      }
    },
    title: {
      color: props => (props.drawer ? theme.palette.grey[400] : theme.palette.text.primary),
      fontSize: props => (props.size === 'small' ? '1rem' : '1.125rem'),
      fontWeight: 700,
      lineHeight: 1.2,
      margin: theme.spacing(1.5, 0, 0),
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        fontSize: props => (props.size === 'small' ? '1.125rem' : '1.25rem'),
        margin: theme.spacing(1, 0)
      }
    }
  };
});
