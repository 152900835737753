import React from 'react';
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel as MuiLabel } from '@mui/material';


import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import { useSharedStyles } from '../../../../../../shared/styles';
import { useFormikContext } from 'formik';

const EligibilityRules: React.FC = () => {
    const classes = useSharedStyles();

    const { values, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data = values.eligibilityRules;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
                <FormControl component='fieldset'>
                    <MuiLabel component='legend'>Are part time employees eligible to enroll in any plans?</MuiLabel>

                    <RadioGroup
                        row
                        aria-label={`Part Time Employees Eligibility`}
                        name={`eligibilityRules.arePartTimeEmployeesEligibleToEnroll`}
                        color='primary'
                        value={data?.arePartTimeEmployeesEligibleToEnroll ?? false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === 'true') {
                                setFieldValue(`eligibilityRules.arePartTimeEmployeesEligibleToEnroll`, true);
                            } else {
                                setFieldValue(`eligibilityRules.arePartTimeEmployeesEligibleToEnroll`, false);
                            }
                        }}
                    >
                        <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                        <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
                <FormControl component='fieldset'>
                    <MuiLabel component='legend'>Are domestic partners eligible to enroll in any plans?</MuiLabel>

                    <RadioGroup
                        row
                        aria-label={`Are domestic partners eligible to enroll in any plans?`}
                        name={`eligibilityRules.areDomesticPartnersEligibleToEnroll`}
                        color='primary'
                        value={data?.areDomesticPartnersEligibleToEnroll ?? false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === 'true') {
                                setFieldValue(`eligibilityRules.areDomesticPartnersEligibleToEnroll`, true);
                            } else {
                                setFieldValue(`eligibilityRules.areDomesticPartnersEligibleToEnroll`, false);
                            }
                        }}
                    >
                        <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                        <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
                    </RadioGroup>
                </FormControl>
            </Grid>


        </Grid>
    );
};

export default EligibilityRules;
