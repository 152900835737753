import React from 'react';
import { Box, Grid, TextField, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useInsuranceCoverageOptionsDropdownCollection from '../../../hooks/dropdown-collection/useInsuranceCoverageOptionsDropdownCollection';
import { IVisionInsurance } from '../../../models';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface IVisionInsuranceProps {
    data: IVisionInsurance;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const VisionInsurance: React.FC<IVisionInsuranceProps> = ({ data, handleBlur, setFieldValue, errors }) => {
    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'


    const insuranceCoverageOptionsRequest = useInsuranceCoverageOptionsDropdownCollection();
    const insuranceCoverageOptions = insuranceCoverageOptionsRequest.data;
    const insuranceCoverageOptionsLoading = insuranceCoverageOptionsRequest.status === "loading";

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Vision Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerVisionCoverage}
                                    id='visionInsurance.offerVisionCoverage'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('visionInsurance.offerVisionCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={
                                data?.offerVisionCoverage ? 'Offered' : 'Not offered'
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Vision Carrier'
                    name='visionInsurance.visionCarrier'
                    value={data?.visionCarrier}
                    onChange={e => {
                        setFieldValue('visionInsurance.visionCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Previous Vision Carrier'
                    name='visionInsurance.previousVisionCarrier'
                    value={data?.previousVisionCarrier}
                    onChange={e => {
                        setFieldValue('visionInsurance.previousVisionCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>

                <GenericDropdown handleBlur={handleBlur}
                    options={insuranceCoverageOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={insuranceCoverageOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.visionPlanOffering ?? ''}
                    error={errors?.visionInsurance?.visionPlanOffering}
                    label="Vision Plan Offering"
                    id={`visionInsurance.visionPlanOffering`}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalOptions?.defaultPeriodOptions ?? []}
                    isLoading={generalOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.coverageTerminatesOn ?? ''}
                    error={errors?.visionInsurance?.coverageTerminatesOn}
                    label="When an employee is terminated, coverage terminates on:" id={`visionInsurance.coverageTerminatesOn`}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Vision Notes'
                    name='visionInsurance.visionNotes'
                    value={data?.visionNotes}
                    onChange={e => {
                        setFieldValue('visionInsurance.visionNotes', e.target.value);
                    }}
                />
            </Grid>

        </Grid>
    );
};

export default VisionInsurance;
