import { Accordion } from '../../../../../../../../components';
import DentalInsurance from './DentalInsurance/DentalInsurance';
import DentalPlansTable from './DentalPlansTable/DentalPlansTable';
import VisionInsurance from './VisionInsurance/VisionInsurance';
import VisionPlansTable from './VisionPlansTable/VisionPlansTable';
import ShortTermDisabilityCoverageClassesTable from './ShortTermDisabilityClassesTable/ShortTermDisabilityClassesTable';
import ShortTermDisabilityCoverage from './ShortTermDisabilityCoverage/ShortTermDisabilityCoverage';
import LongTermDisabilityCoverage from './LongTermDisabilityCoverage/LongTermDisabilityCoverage';
import LongTermDisabilityClassesTable from './LongTermDisabilityClassesTable/LongTermDisabilityClassesTable';
import BasicLifeInsurance from './BasicLifeInsurance/BasicLifeInsurance';
import BasicLifeClassesTable from './BasicLifeClassesTable/BasicLifeClassesTable';
import VoluntaryLifeCoverage from './VoluntaryLifeCoverage/VoluntaryLifeCoverage';
import VoluntaryLifeGuaranteedIssues from './VoluntaryLifeGuaranteedIssues/VoluntaryLifeGuaranteedIssues';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import BusinessClientTabContentBase from '../../../../../../../../components/business-client-tab-content-base';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { useSharedStyles } from '../../../../../shared/styles';
import { useLateralFormMenuControl } from '../../../../../contexts/LateralFormMenuControlContext';

export default function Ancillary() {

    const { values, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('ancillary');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    const { selectAccordionCategory } = useLateralFormMenuControl();

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <BusinessClientTabContentBase benefitsList={[
                {
                    benefitName: 'Dental',
                    offered: values.dentalInsurance?.offerDentalCoverage === true
                },
                {
                    benefitName: 'Vision',
                    offered: values.visionInsurance?.offerVisionCoverage === true
                },
                {
                    benefitName: 'STD',
                    offered: values.shortTermDisabilityCoverage?.offerShortTermDisabilityCoverage === true
                },
                {
                    benefitName: 'LTD',
                    offered: values.longTermDisabilityCoverage?.offerLongTermDisabilityCoverage === true
                },
                {
                    benefitName: 'Basic Life',
                    offered: values.groupLifeInsurance?.offerBasicLifeCoverage === true
                },
                {
                    benefitName: 'Voluntary Life',
                    offered: values.voluntaryLifeCoverage?.offerVoluntaryLifeCoverage === true
                }
            ]} >

                <Accordion defaultExpanded={!selectAccordionCategory || selectAccordionCategory === 'Dental'} hasErrors={errors?.dentalInsurance != undefined} title='Dental' component='h3' variant='h4'>
                    <DentalInsurance />
                    <DentalPlansTable />
                </Accordion>


                <Accordion defaultExpanded={selectAccordionCategory === 'Vision'} hasErrors={errors?.visionInsurance != undefined} title='Vision' component='h3' variant='h4'>
                    <VisionInsurance />
                    <VisionPlansTable />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'ShortTermDisability'} hasErrors={errors?.shortTermDisabilityCoverage != undefined} title='Short Term Disability' component='h3' variant='h4'>
                    <ShortTermDisabilityCoverage />
                    <ShortTermDisabilityCoverageClassesTable />
                </Accordion>


                <Accordion defaultExpanded={selectAccordionCategory === 'LongTermDisability'} hasErrors={errors?.longTermDisabilityCoverage != undefined} title='Long Term Disability' component='h3' variant='h4'>
                    <LongTermDisabilityCoverage />
                    <LongTermDisabilityClassesTable />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'BasicLife'} hasErrors={errors?.groupLifeInsurance != undefined} title='Basic Life' component='h3' variant='h4'>
                    <BasicLifeInsurance />
                    <BasicLifeClassesTable />
                </Accordion>


                <Accordion defaultExpanded={selectAccordionCategory === 'VoluntaryLife'} hasErrors={errors?.voluntaryLifeCoverage != undefined} title='Voluntary Life' component='h3' variant='h4'>
                    <VoluntaryLifeCoverage />
                    <VoluntaryLifeGuaranteedIssues />
                </Accordion>

            </BusinessClientTabContentBase>

        </div>
    );
}