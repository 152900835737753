import { FormikErrors } from "formik";
import { IBusinessClientDetailFormValues } from "../../../../../../../models";

import companyInfo from '../sub-menus/client-details/company-info'
import dates from '../sub-menus/client-details/dates'
import classes from '../sub-menus/client-details/classes'

export default (errors: FormikErrors<IBusinessClientDetailFormValues>) => {
    return companyInfo(errors)
        && dates(errors)
        && classes(errors);
}