import { FC, Fragment } from 'react';
import { Field, FieldArray } from 'formik';
import { Button, Select, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, TextField, Typography, Grid, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IBusinessClientClass } from '../../../models';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useBusinessClassOptionsDropdownCollection from '../../../hooks/dropdown-collection/useBusinessClassOptionsDropdownCollection';


interface IBusinessClientClassesProps {
    businessClientClasses: IBusinessClientClass[];
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    values: any;
}

const BusinessClientClasses: FC<IBusinessClientClassesProps> = ({ businessClientClasses, handleBlur, setFieldValue, errors, values }) => {
    const classes = useSharedStyles();

    const businessClassOptionsRequest = useBusinessClassOptionsDropdownCollection();
    const businessClassOptions = businessClassOptionsRequest.data;
    const businessClassLoading = businessClassOptionsRequest.status === "loading";

    return (<FieldArray name='classes'>
        {({ push, remove }) => {
            return (
                <div>
                    {businessClientClasses.length > 0 &&
                        businessClientClasses.map((clientClass, index) => {
                            return (
                                <Fragment key={`${index}`}>
                                    <Typography variant='h5' component='h5' className={classes.classText}>
                                        Class {index + 1}
                                    </Typography>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={12}>
                                            <Field name={`classes.${index}.name`}>
                                                {({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant='outlined'
                                                            size='small'
                                                            autoComplete='nope'
                                                            label='Class Name'
                                                            name={`classes.${index}.name`}
                                                            value={businessClientClasses[index].name}
                                                            onBlur={handleBlur}
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <GenericDropdown handleBlur={handleBlur}
                                                options={businessClassOptions?.waitingPeriods ?? []}
                                                isLoading={businessClassLoading}
                                                setFieldValue={setFieldValue}
                                                value={clientClass.waitingPeriod ?? ''}
                                                error={errors.classes?.[index]?.waitingPeriod}
                                                label='Waiting Period'
                                                id={`classes.${index}.waitingPeriod`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name={`classes.${index}.benefitGuideUrl`}>
                                                {({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant='outlined'
                                                            autoComplete='nope'
                                                            size='small'
                                                            label='Benefit Guide URL'
                                                            name={`classes.${index}.benefitGuideUrl`}
                                                            value={businessClientClasses[index].benefitGuideUrl || ''}
                                                            onBlur={handleBlur}
                                                            placeholder='www.example.com'
                                                            error={
                                                                errors &&
                                                                    errors.classes &&
                                                                    errors.classes[index] &&
                                                                    // @ts-ignore
                                                                    errors.classes[index].benefitGuideUrl
                                                                    ? true
                                                                    : false
                                                            }
                                                            // @ts-ignore
                                                            helperText={
                                                                errors &&
                                                                errors.classes &&
                                                                errors.classes[index] &&
                                                                // @ts-ignore
                                                                errors.classes[index].benefitGuideUrl
                                                            }
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name={`classes.${index}.videoUrl`}>
                                                {({ field }) => {
                                                    return (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            variant='outlined'
                                                            size='small'
                                                            autoComplete='nope'
                                                            label='Video URL'
                                                            name={`classes.${index}.videoUrl`}
                                                            value={businessClientClasses[index].videoUrl || ''}
                                                            onBlur={handleBlur}
                                                            placeholder='www.example.com'
                                                            error={
                                                                errors &&
                                                                    errors.classes &&
                                                                    errors.classes[index] &&
                                                                    // @ts-ignore
                                                                    errors.classes[index].videoUrl
                                                                    ? true
                                                                    : false
                                                            }
                                                            // @ts-ignore
                                                            helperText={
                                                                errors &&
                                                                errors.classes &&
                                                                errors.classes[index] &&
                                                                // @ts-ignore
                                                                errors.classes[index].videoUrl
                                                            }
                                                        />
                                                    );
                                                }}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <GenericDropdown handleBlur={handleBlur}
                                                options={businessClassOptions?.classPayCycles ?? []}
                                                isLoading={businessClassLoading}
                                                setFieldValue={setFieldValue}
                                                value={clientClass.classPayCycle ?? ''}
                                                error={errors.classes?.[index]?.classPayCycle}
                                                label='Class Pay Cycle'
                                                id={`classes.${index}.classPayCycle`}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <GenericDropdown handleBlur={handleBlur}
                                                options={businessClassOptions?.classEligibilityPeriods ?? []}
                                                isLoading={businessClassLoading}
                                                setFieldValue={setFieldValue}
                                                value={clientClass.classEligibilityPeriod ?? ''}
                                                error={errors.classes?.[index]?.classEligibilityPeriod}
                                                label='Class Eligibility Period'
                                                id={`classes.${index}.classEligibilityPeriod`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <GenericDropdown handleBlur={handleBlur}
                                                options={businessClassOptions?.classEligibilityRules ?? []}
                                                isLoading={businessClassLoading}
                                                setFieldValue={setFieldValue}
                                                value={clientClass.classEligibilityRule ?? ''}
                                                error={errors.classes?.[index]?.classEligibilityRule}
                                                label='Class Eligibility Rule'
                                                id={`classes.${index}.classEligibilityRule`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.removeButtonWrapper}>
                                            <Button
                                                className={classes.removeButton}
                                                startIcon={<Close />}
                                                onClick={() => {
                                                    remove(index);
                                                    const newQrCodes = [...values.qrCodes];
                                                    setFieldValue(
                                                        'qrCodes',
                                                        newQrCodes.filter(code => code.businessClientClassId !== clientClass.businessClientClassId)
                                                    );
                                                }}
                                            >
                                                Remove Class
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            );
                        })}
                    <Button
                        color='primary'
                        variant='contained'
                        className={classes.addClassButton}
                        onClick={() =>
                            push({
                                name: '',
                                videoUrl: '',
                                benefitGuideUrl: '',
                                waitingPeriod: ''
                            })
                        }
                    >
                        Add Class
                    </Button>
                </div>
            );
        }}
    </FieldArray>
    )
};

export default BusinessClientClasses;