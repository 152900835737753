import { FormikErrors } from "formik";
import { IBusinessClientDetailFormValues } from "../../../../../../../models";

import medical from '../sub-menus/product-details/medical'
import financial from '../sub-menus/product-details/financial'
import ancillary from '../sub-menus/product-details/ancillary'
import worksite from '../sub-menus/product-details/worksite'
import supplemental from '../sub-menus/product-details/supplemental'

export default (errors: FormikErrors<IBusinessClientDetailFormValues>) => {
    return medical(errors)
        && financial(errors)
        && ancillary(errors)
        && worksite(errors)
        && supplemental(errors);
}