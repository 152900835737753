import { ReactComponent as PdfIcon } from '../../../../../../../../../../styles/icons/pdf_icon.svg'
import { ReactComponent as XlsIcon } from '../../../../../../../../../../styles/icons/xls_icon.svg'
import { ReactComponent as RarIcon } from '../../../../../../../../../../styles/icons/rar_icon.svg'
import { ReactComponent as CsvIcon } from '../../../../../../../../../../styles/icons/csv_icon.svg'
import { ReactComponent as DocIcon } from '../../../../../../../../../../styles/icons/doc_icon.svg'
import { ReactComponent as NotFoundExtensionFileIcon } from '../../../../../../../../../../styles/icons/default_file_icon.svg'
import { ReactComponent as PngIcon } from '../../../../../../../../../../styles/icons/png_icon.svg'
import { ReactComponent as JpgIcon } from '../../../../../../../../../../styles/icons/jpg_icon.svg'

import { ReactComponent as MixedIcon } from '../../../../../../../../../../styles/icons/mixed_icon.svg'
import { ReactComponent as LinkIcon } from '../../../../../../../../../../styles/icons/link_icon.svg'
import { ISectionDocumentsFile } from '../../../../../../../../../../models'



const fileIconDictionary: { [key: string]: React.FC } = {
    '.pdf': PdfIcon,
    '.xls': XlsIcon,
    '.xlsx': XlsIcon,
    '.rar': RarIcon,
    '.csv': CsvIcon,
    '.docx': DocIcon,
    '.doc': DocIcon,
    '.png': PngIcon,
    '.jpg': JpgIcon,
};

const getDocumentIcon = (file: ISectionDocumentsFile) => {

    if (!file) return NotFoundExtensionFileIcon;
    
    switch (file.docContentType) {
        case "OnlyExternalFileUrl":
            return LinkIcon;
        case "OnlyFile":
            const SelectedIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = fileIconDictionary[file.fileExtension] ?? NotFoundExtensionFileIcon;
            return SelectedIcon;
        case "FileAndExternalFileUrl":
            return MixedIcon;
    }

    return NotFoundExtensionFileIcon;

}

export { fileIconDictionary, NotFoundExtensionFileIcon, MixedIcon, LinkIcon, getDocumentIcon }
