import React, { useState } from 'react';
import { ITurboNote } from '../../../../models';
import { EmployeeDetailCard } from '../../../../pages/employees/EmployeeDetailCard';
import { Note } from '@mui/icons-material';
import { Loader } from '../../../loader';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { getEmployeeTurboDialNotes } from '../../../../fetch';
import { formatShortFriendlyDateWithTime } from '../../../../helpers';
import { ClassNameMap } from '@mui/styles/withStyles';

interface Props {
    businessClientId: string;
    employeeId: number;
    classes: ClassNameMap<any>
    timeZone?: string;
}

const BenefitsCounselorNotes: React.FC<Props> = ({ businessClientId, employeeId, classes, timeZone }) => {

    const [isTurboNotesLoading, setTurboDialNotesLoading] = useState<boolean>(true);
    const [isTurboNotesExpanded, setIsTurboNotesExpanded] = useState(false);
    const [turboDialNotes, setTurboDialNotes] = useState<ITurboNote[] | null>(null);

    const toggleTurboDialNotesAccordion = async isExpanded => {
        setIsTurboNotesExpanded(isExpanded);
        setTurboDialNotes(null);
        if (isExpanded) {
            fetchTurboDialNotes();
        }
    };

    const fetchTurboDialNotes = async () => {
        setTurboDialNotesLoading(true);
        try {
            const res = await getEmployeeTurboDialNotes(businessClientId, employeeId);
            setTurboDialNotes(res);
        } catch (error) {
            console.error(error);
        } finally {
            setTurboDialNotesLoading(false);
        }
    };

    return (
        <EmployeeDetailCard
            title={'Benefits Counselor Notes'}
            scrollable={true}
            expanded={isTurboNotesExpanded}
            onChange={() => toggleTurboDialNotesAccordion(!isTurboNotesExpanded)}
            icon={<Note />}
        >
            {isTurboNotesLoading && <Loader position={'centered'} type={'inline'} />}
            {!isTurboNotesLoading && (!turboDialNotes || (turboDialNotes && turboDialNotes.length === 0)) && (
                <>
                    <Typography align='center'>No Benefits Counselor Notes are found</Typography>
                </>
            )}
            {!isTurboNotesLoading && turboDialNotes && turboDialNotes.length > 0 && (
                <Grid className={classes.cardGrid} container spacing={3}>
                    {turboDialNotes.map((turboDialNote, index) => (
                        <Grid key={index} container sm={12} lg={4} item>
                            <Card className={classes.card}>
                                <CardHeader className={classes.cardHeader} titleTypographyProps={{ variant: 'h3' }} title={`${turboDialNote.title}`} />
                                <Grid item>
                                    <CardContent className={classes.cardContent}>
                                        <Typography className={classes.cardContentText} variant='body2' color='textPrimary'>
                                            {turboDialNote.note || 'No note body.'}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item>
                                    <CardContent className={classes.cardFooter}>
                                        <Grid container justifyContent={'space-between'}>
                                            <Grid item>
                                                <Typography variant='body2' color='textSecondary' component='p'>
                                                    {`When: ${formatShortFriendlyDateWithTime(turboDialNote.date, timeZone) || 'No date available.'}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2' color='textSecondary' component='p'>
                                                    {`Caller ID: ${turboDialNote.callerId || 'No number available.'}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Grid>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </EmployeeDetailCard>
    );
};

export default BenefitsCounselorNotes;
