import React, { useContext } from 'react';
import { createContext, useState } from 'react';

const SuccessMessageCtx = createContext<[string, React.Dispatch<React.SetStateAction<string>>]>([null, () => { }]);

const SuccessMessageProvider: React.FC = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState<string>(null);

    return (
        <SuccessMessageCtx.Provider value={[errorMessage, setErrorMessage]}>
            {children}
        </SuccessMessageCtx.Provider>
    );
};

export { SuccessMessageCtx, SuccessMessageProvider };

const useSuccessMessage = (): [string, React.Dispatch<React.SetStateAction<string>>] => {
    const [successMessage, setSuccessMessage] = useContext(SuccessMessageCtx);
    return [successMessage, setSuccessMessage];
};

export default useSuccessMessage;
