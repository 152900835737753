
import { useSharedStyles } from '../../../../../shared/styles';
import { Accordion } from '../../../../../../../../components';
import SupplementalProductsTable from './SupplementalProductsTable/SupplementalProductsTable';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';

export default function Supplemental() {
    const { errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('supplemental');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />

            <Accordion hasErrors={errors?.supplementalProducts !== undefined} title='Supplemental Products' component='h3' variant='h4' defaultExpanded>
                <SupplementalProductsTable />

            </Accordion>

        </div>
    );
}
