import React, { FC, useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, FormikTouched, FormikErrors } from 'formik';
import { deepEqual } from 'fast-equals';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Add, Save, Close, PictureAsPdf } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { withTracker } from '../../../services';
import { useParams, Prompt, useHistory } from 'react-router-dom';
// helpers
import { formatUrl, formatInputPhoneNumber } from '../../../helpers';
// Components
import { Toast, Loader, Page, PageTitle } from '../../../components';
import { CardActions, Button, Alert } from '@mui/material';
import { MobileAppInfo } from './MobileAppInfo';
// models
import {
  IBroker,
  ISystemOfRecord,
  IBuildStep,
  IBusinessClientDetailFormValues,
  IBusinessClientDetail,
  getDefaultLinks,
  IDropdownResponse,
  IUser
} from '../../../models';
// fetch
import {
  createBusinessClient,
  updateBusinessClient,
  getBusinessClient,
  storeBusinessClientCompanyLogo,
  storeBusinessClientAppLogo,
  getBrokers,
  getSystemOfRecords,
  getWaitingPeriods,
  getDataFileRecipients,
  getUsers,
  getIndustriesTiers,
  getBenAdminBuildTypes,
  getEngagementPercentages,
  downloadBusinessClientPdf
} from '../../../fetch';
import { BusinessClientInfo } from './BusinessClientInfo';
import { EnrollmentBuilder } from './EnrollmentBuilder';
import { buildSteps } from './build-steps';
import { BusinessClientDetailTabs } from './BusinessClientDetailTabs';
import { EnrollmentOverview } from './EnrollmentOverview';
import useErrorMessage from '../../../context/ErrorMessageCtx';
import { BusinessClientSchema } from './validation';
import { businessClientRes } from './BusinessClientDetailPage/actions';
import { isObjectEmpty } from '../../../util';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { createBusinessClientFormValues } from './actions';

interface IBusinessClientDetailPage { }

const getBuildSteps = (currentSelections: IBuildStep[]) => {
  const buildOptions: IBuildStep[] = buildSteps;
  // remove duplicates
  const filteredArray = currentSelections?.filter((obj, index, arr) => {
    return arr.map(mapObj => mapObj.buildStep).indexOf(obj.buildStep) === index;
  });
  const existingSteps = filteredArray?.map(a => a.buildStep);
  // combine the pre-existing options with the defaults to show them all to the user
  return [...(filteredArray || []), ...buildOptions.filter(option => !existingSteps?.includes(option.buildStep))].filter(Boolean);
};
export const BusinessClientDetailPage: FC<IBusinessClientDetailPage> = withTracker(() => {
  const classes = useStyles();
  const history = useHistory();

  const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
  const generalOptions = generalDropdownsOptionsRequest?.data;
  const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

  const states = generalOptions?.stateOptions;
  const statesLoading = generalOptionsLoading;


  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);

  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [isLoadingBusinessClient, setLoadingBusinessClient] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useErrorMessage();


  const [successMessage, setSuccessMessage] = useState<string>('');
  const [companyLogo, setCompanyLogo] = useState<File | undefined>(undefined);
  const [appLogo, setAppLogo] = useState<File | undefined>(undefined);
  const [previewError, setPreviewError] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [currentBusinessClient, setCurrentBusinessClient] = useState<IBusinessClientDetail | null>(null);
  const [brokersLoading, setBrokersLoading] = useState<boolean>(true);
  const [systemOfRecordsLoading, setSystemOfRecordsLoading] = useState<boolean>(true);
  const [waitingPeriods, setWaitingPeriods] = useState<IDropdownResponse[]>([]);
  const [waitingPeriodsLoading, setWaitingPeriodsLoading] = useState<boolean>(true);

  const [industriesTiers, setIndustriesTiers] = useState<IDropdownResponse[]>([]);
  const [industriesTiersLoading, setIndustriesTiersLoading] = useState<boolean>(true);

  const [benAdminBuildTypes, setBenAdminBuildTypes] = useState<IDropdownResponse[]>([]);
  const [benAdminBuildTypesLoading, setBenAdminBuildTypesLoading] = useState<boolean>(true);

  const [engagementPercentages, setEngagementPercentages] = useState<IDropdownResponse[]>([]);
  const [engagementPercentagesLoading, setEngagementPercentagesLoading] = useState<boolean>(true);



  const [dataFileRecipients, setDataFileRecipients] = useState<IDropdownResponse[]>([]);
  const [dataFileRecipientsLoading, setDataFileRecipientsLoading] = useState<boolean>(true);
  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const [systemOfRecords, setSystemOfRecords] = useState<ISystemOfRecord[]>([]);
  const [isLoadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  const isMobile = useMediaQuery('(max-width: 960px)');
  const theme = useTheme();
  const { id }: { id: string } = useParams();
  const isNew = id === 'new';

  const fetchBusinessClient = async () => {
    try {
      setLoadingBusinessClient(true);
      const res = await getBusinessClient(Number(id));
      setCurrentBusinessClient(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBusinessClient(false);
    }
  };
  const fetchBrokers = async () => {
    try {
      const res = await getBrokers({
        perPage: '2147483647' // get all records
      });
      if (res.records) {
        setBrokers(res.records);
      } else {
        console.error(`No 'records' on brokers response.`);
        setErrorMessage('Error loading brokers, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading brokers, please try again.');
    }
    setBrokersLoading(false);
  };

  const fetchSystemOfRecords = async () => {
    try {
      const res = await getSystemOfRecords({
        perPage: '2147483647' // get all records
      });
      if (res.records) {
        setSystemOfRecords(res.records);
      } else {
        console.error(`No 'records' on system of records response.`);
        setErrorMessage('Error loading system of records, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading system of records, please try again.');
    }
    setSystemOfRecordsLoading(false);
  };

  const fetchWaitingPeriods = async () => {
    try {
      const res = await getWaitingPeriods();
      if (res) {
        setWaitingPeriods(res);
      } else {
        console.error(`No 'records' on waiting periods response.`);
        setErrorMessage('Error loading waiting periods, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading waiting periods, please try again.');
    }
    setWaitingPeriodsLoading(false);
  };

  const fetchIndustriesTiers = async () => {
    try {
      const res = await getIndustriesTiers();
      if (res) {
        setIndustriesTiers(res);
      } else {
        setErrorMessage('Error loading industries tries, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading industries tries, please try again.');
    }
    setIndustriesTiersLoading(false);
  };

  const fetchBenAdminBuildTypes = async () => {
    try {
      const res = await getBenAdminBuildTypes();
      if (res) {
        setBenAdminBuildTypes(res);
      } else {
        setErrorMessage('Error loading ben admin build types, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading ben admin build types, please try again.');
    }
    setBenAdminBuildTypesLoading(false);
  };

  const fetchEngagementPercentages = async () => {
    try {
      const res = await getEngagementPercentages();
      if (res) {
        setEngagementPercentages(res);
      } else {
        setErrorMessage('Error loading industries tries, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading industries tries, please try again.');
    }
    setEngagementPercentagesLoading(false);
  };


  const fetchUsers = async () => {
    setLoadingUsers(true);
    try {
      const searchParams = {
        sortBy: 'FirstName',
        sortDirection: 'Asc',
        isActive: true,
        perPage: '2147483647', // get all records
        userRole: 'Administrator'
      };
      const usersResponse = await getUsers(searchParams);

      setUsers(usersResponse.records);
    } catch (error) {
      console.error(error);
    }
    setLoadingUsers(false);
  };

  const fetchDataFileRecipients = async () => {
    try {
      const res = await getDataFileRecipients();
      if (res) {
        setDataFileRecipients(res);
      } else {
        console.error(`No 'records' on data file recipients response.`);
        setErrorMessage('Error loading data file recipients, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading data file recipients, please try again.');
    }
    setDataFileRecipientsLoading(false);
  };

  useEffect(() => {
    fetchBrokers();
    fetchSystemOfRecords();
    fetchWaitingPeriods();
    fetchIndustriesTiers();
    fetchBenAdminBuildTypes();
    fetchEngagementPercentages();
    fetchUsers();
    fetchDataFileRecipients();
    if (!isNew) {
      fetchBusinessClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);
  const title = isLoadingBusinessClient ? 'Loading...' : currentBusinessClient?.name ?? 'Add New Business Client';

  const onSuccessActions = (actions, message: string) => {
    showSuccess(true);
    setSuccessMessage(message);
    actions.resetForm();
    setSelectedTab(0);
    setTimeout(() => {
      history.push('/manage-business-clients');
    }, 1000);
  }

  const hasErrorsOnResponse = (res: any) => {
    if (res && (typeof res === 'boolean' || typeof res === 'number')) {
      return false;
    }

    return true;
  }

  const handlerErrorMessageOnResponse = (res: any) => {

    if (res?.Errors && Object?.values(Object?.values(res?.Errors)?.[0])?.[0]) {
      // validation errors
      setErrorMessage(Object.values(Object.values(res.Errors)[0])[0] as string);
      showError(true);
    }
    if (res?.Detail) {
      setErrorMessage(res?.Detail);
      showError(true);
    }
    // server errors
    setErrorMessage('Error, please try again');
    showError(true);
  };

  const onSubmitBeforeDownloadPdf = async (dirty: boolean, setSubmitting: (isSubmitting: boolean) => void, values) => {
    if (dirty) {
      setSubmitting(true);

      const { businessClientId, res, hasErrors } = await onSubmitAction(values);
      setSubmitting(false);

      if (hasErrors) {
        handlerErrorMessageOnResponse(res);
        return null;
      }

      return businessClientId;
    }

    return currentBusinessClient.businessClientId;
  }

  const onSubmitAction = async (values): Promise<any> => {

    try {
      const { brokerName, systemOfRecord } = values;
      const selectedBroker = brokers.find(broker => broker.name === brokerName);
      const selectedSystemOfRecord = systemOfRecords.find(system => system.name === systemOfRecord);

      if (currentBusinessClient) {
        const res = await updateBusinessClient(currentBusinessClient.businessClientId, businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values));
        const hasErrors = hasErrorsOnResponse(res);

        return { businessClientId: currentBusinessClient.businessClientId, res, hasErrors };

      } else {
        const res = await createBusinessClient(businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values));
        const hasErrors = hasErrorsOnResponse(res);

        if (hasErrors) return { res, hasErrors };

        const newBusinessClientId = res;

        if (!companyLogo) return { businessClientId: newBusinessClientId, res, hasErrors };

        // If an image was selected, perform actions for uploading and updating the business client record
        const create = new FormData();
        create.append('image', companyLogo);
        const imageUrl = await storeBusinessClientCompanyLogo(newBusinessClientId, create);
        const logo = imageUrl.toString();
        let appLogoHeader;
        if (appLogo) {
          const create = new FormData();
          create.append('image', companyLogo);
          const imageUrl = await storeBusinessClientAppLogo(newBusinessClientId, create);
          appLogoHeader = imageUrl.toString();
        }
        // After uploading image to get a URL, we need to update the client record just created with the URL
        const updateRes = await updateBusinessClient(newBusinessClientId, businessClientRes(currentBusinessClient, selectedBroker, selectedSystemOfRecord, values, logo, appLogoHeader));
        const hasErrorsUpdateRes = hasErrorsOnResponse(updateRes);

        return { businessClientId: newBusinessClientId, res, hasErrors: hasErrorsUpdateRes };

      }
    } catch (error) {
      return { res: error, hasErrors: true };
    }
  }

  return (
    <Page title={title}>
      {isLoadingBusinessClient && <Loader position='centered' type='inline' />}
      {!isLoadingBusinessClient && (
        <>
          <PageTitle title={title} showDivider={!isMobile} />
          <Formik
            enableReinitialize={true}
            initialValues={createBusinessClientFormValues(currentBusinessClient, theme)}
            validationSchema={BusinessClientSchema}
            onSubmit={async (values, actions) => {
              const { res, hasErrors } = await onSubmitAction(values);

              if (hasErrors) {
                handlerErrorMessageOnResponse(res);
              } else {
                onSuccessActions(actions, currentBusinessClient ? 'Business Client Updated!' : 'Business Client Created!');

                setTimeout(() => {
                  history.push('/manage-business-clients');
                }, 1000);
              }
            }}
          >
            {({ resetForm, isSubmitting, setSubmitting, values, initialValues, setFieldValue, setFieldTouched, errors, touched, handleSubmit, dirty, isValid, handleBlur, validateForm }) => {
              /* eslint-disable react-hooks/rules-of-hooks */
              const imageDZUrlEdit = (acceptedFile: File) => {
                const imageUrl = URL.createObjectURL(acceptedFile);
                const img = document.createElement('img');
                img.src = imageUrl;
                img.onload = async () => {
                  // icon needs a minimum height of 140px
                  if (img.height < 140) {
                    setFieldValue('logoUrl', '');
                    setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
                  } else {
                    const create = new FormData();
                    create.append('image', acceptedFile);
                    try {
                      const imageUrl = await storeBusinessClientCompanyLogo(currentBusinessClient.businessClientId, create);
                      setFieldValue('logoUrl', imageUrl);
                    } catch (error) {
                      console.log(error);
                      if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
                        setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
                      }
                      showError(true);
                    }
                    setPreviewError('');
                  }
                };
              };
              const imageDZUrlAdd = (acceptedFile: File) => {
                const imageUrl = URL.createObjectURL(acceptedFile);
                const img = document.createElement('img');
                img.src = imageUrl;
                img.onload = async () => {
                  // icon needs a minimum height of 140px
                  if (img.height < 140) {
                    setCompanyLogo(null);
                    setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
                  } else {
                    setCompanyLogo(acceptedFile);
                    setFieldValue('logoUrl', imageUrl);
                    setPreviewError('');
                  }
                };
              };
              const imageDZUrl = useDropzone({
                accept: '.jpg, .jpeg, .png',
                onDrop: acceptedFiles => {
                  if (acceptedFiles.length > 0 && currentBusinessClient) {
                    imageDZUrlEdit(acceptedFiles[0]);
                  } else if (acceptedFiles.length > 0) {
                    imageDZUrlAdd(acceptedFiles[0]);
                  }
                }
              });
              const busincessClientInfoTabValid =
                errors.name ||
                  errors.brokerName ||
                  errors.caseOverviewUrl ||
                  errors.newHireScheduleUrl ||
                  errors.benefitProgramSupportDocumentationUrl ||
                  errors.logoUrl ||
                  errors.classes ||
                  errors.location ||
                  errors.hrManagerEmail ||
                  errors.hrManagerPhoneNumber
                  ? false
                  : true;

              const enrollmentOverviewTabValid =
                errors.systemOfRecord ||
                  errors.systemOfRecordUrl ||
                  errors.systemOfRecordUsername ||
                  errors.systemOfRecordPassword ||
                  errors.highlightsChallenges ||
                  errors.specialAttentionBusinessClientEmployees ||
                  errors.dashboardOpenEnrollmentView ||
                  errors.openEnrollmentEffectiveDate ||
                  errors.openEnrollmentStartDate ||
                  errors.enrollmentDeadline ||
                  errors.accountManagerUserId ||
                  errors.dataFileRecipients
                  ? false
                  : true;

              const mobileAppInfoTabValid = errors.links ? false : true;
              const enrollmentBuilderTabValid = errors.projectType || errors.accountManagerUserId || errors.enrollmentStart || errors.enrollmentEnd ? false : true;
              /* eslint-enable react-hooks/rules-of-hooks */
              return (
                <>
                  <Form onSubmit={handleSubmit} autoComplete='none'>
                    {isSubmitting && <Loader type='fullscreen' position='centered' />}
                    {isGeneratingPdf && <Loader type='fullscreen' position='centered' title='Creating the pdf file...' />}
                    <div className={classes.content}>
                      <BusinessClientDetailTabs
                        validateForm={validateForm}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        busincessClientInfoTabValid={busincessClientInfoTabValid}
                        enrollmentOverviewTabValid={enrollmentOverviewTabValid}
                        mobileAppInfoTabValid={mobileAppInfoTabValid}
                        enrollmentBuilderTabValid={enrollmentBuilderTabValid}
                      />
                      {selectedTab === 0 && (
                        <>
                          {(!enrollmentOverviewTabValid || !enrollmentBuilderTabValid) && (
                            <Alert
                              severity='error'
                              variant='filled'
                              sx={{ marginTop: '1rem' }}
                              classes={{
                                message: classes.alertMessage
                              }}
                            >
                              <ul>
                                {!enrollmentOverviewTabValid && <li>There are required fields and/or validation errors on Enrollment Overview that need to be resolved</li>}
                                {!enrollmentBuilderTabValid && <li>There are required fields and/or validation errors on Enrollment Builder that need to be resolved</li>}
                              </ul>
                            </Alert>
                          )}
                          <BusinessClientInfo
                            values={values}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            selectedTab={selectedTab}
                            mobileAppInfoTabValid={mobileAppInfoTabValid}
                            currentBusinessClient={currentBusinessClient}
                            brokers={brokers}
                            waitingPeriodsLoading={waitingPeriodsLoading}
                            waitingPeriods={waitingPeriods}
                            brokersLoading={brokersLoading}
                            isMobile={isMobile}
                            imageDZUrl={imageDZUrl}
                            previewError={previewError}
                          />
                        </>
                      )}
                      {selectedTab === 1 && (
                        <>
                          {(!busincessClientInfoTabValid || !enrollmentBuilderTabValid) && (
                            <Alert
                              severity='error'
                              variant='filled'
                              sx={{ marginTop: '1rem' }}
                              classes={{
                                message: classes.alertMessage
                              }}
                            >
                              <ul>
                                {!busincessClientInfoTabValid && <li>There are required fields and/or validation errors on Business Client Info that need to be resolved</li>}
                                {!enrollmentBuilderTabValid && <li>There are required fields and/or validation errors on Enrollment Builder that need to be resolved</li>}
                              </ul>
                            </Alert>
                          )}
                          <div role='tabpanel' hidden={selectedTab !== 1} id='enrollment-overview-content' aria-labelledby='enrollment-overview' className={classes.tabContent}>
                            <EnrollmentOverview
                              values={values as IBusinessClientDetailFormValues}
                              handleBlur={handleBlur}
                              touched={touched as FormikTouched<IBusinessClientDetailFormValues>}
                              errors={errors as FormikErrors<IBusinessClientDetailFormValues>}
                              statesLoading={statesLoading}
                              states={states ?? []}
                              industriesTiers={industriesTiers}
                              industriesTiersLoading={industriesTiersLoading}
                              benAdminBuildTypes={benAdminBuildTypes}
                              benAdminBuildTypesLoading={benAdminBuildTypesLoading}
                              engagementPercentages={engagementPercentages}
                              engagementPercentagesLoading={engagementPercentagesLoading}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              selectedTab={selectedTab}
                              isMobile={isMobile}
                              currentBusinessClient={currentBusinessClient}
                              systemOfRecordsLoading={systemOfRecordsLoading}
                              systemOfRecords={systemOfRecords}
                              isLoadingUsers={isLoadingUsers}
                              users={users}
                              dataFileRecipientsLoading={dataFileRecipientsLoading}
                              dataFileRecipients={dataFileRecipients}
                            />
                          </div>
                        </>
                      )}
                      {selectedTab === 2 && (
                        <>
                          {(!busincessClientInfoTabValid || !enrollmentOverviewTabValid || !enrollmentBuilderTabValid) && (
                            <Alert
                              severity='error'
                              variant='filled'
                              sx={{ marginTop: '1rem' }}
                              classes={{
                                message: classes.alertMessage
                              }}
                            >
                              <ul>
                                {!busincessClientInfoTabValid && <li>There are required fields and/or validation errors on Business Client Info that need to be resolved</li>}
                                {!enrollmentOverviewTabValid && <li>There are required fields and/or validation errors on Enrollment Overview that need to be resolved</li>}
                                {!enrollmentBuilderTabValid && <li>There are required fields and/or validation errors on Enrollment Builder that need to be resolved</li>}
                              </ul>
                            </Alert>
                          )}
                          <div role='tabpanel' hidden={selectedTab !== 2} id='mobile-app-info-tab-content' aria-labelledby='mobile-app-info-tab' className={classes.tabContent}>
                            <MobileAppInfo
                              currentBusinessClient={currentBusinessClient}
                              appToggle={values.mobileAppEnabled}
                              formValues={values}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              setErrorMessage={setErrorMessage}
                              showError={showError}
                              setFieldTouched={setFieldTouched}
                              appLogo={appLogo}
                              setAppLogo={setAppLogo}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {selectedTab === 3 && (
                      <>
                        {(!busincessClientInfoTabValid || !enrollmentOverviewTabValid) && (
                          <Alert
                            severity='error'
                            variant='filled'
                            sx={{ marginTop: '1rem' }}
                            classes={{
                              message: classes.alertMessage
                            }}
                          >
                            <ul>
                              {!busincessClientInfoTabValid && <li>There are required fields and/or validation errors on Business Client Info that need to be resolved</li>}
                              {!enrollmentOverviewTabValid && <li>There are required fields and/or validation errors on Enrollment Overview that need to be resolved</li>}
                            </ul>
                          </Alert>
                        )}
                        <div role='tabpanel' hidden={selectedTab !== 3} id='enrollment-builder' aria-labelledby='enrollment-builder' className={classes.tabContent}>
                          <EnrollmentBuilder
                            errors={errors}
                            values={values as IBusinessClientDetailFormValues}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            businessClientId={currentBusinessClient?.businessClientId ?? null}
                          />
                        </div>
                      </>
                    )}
                    <CardActions className={classes.buttonActions}>
                      <Button
                        className={classes.saveButton}
                        disabled={!dirty || isSubmitting || !isValid}
                        type='submit'
                        startIcon={currentBusinessClient ? <Save /> : <Add />}
                        variant='contained'
                        color='primary'
                      >
                        {currentBusinessClient ? 'Save' : 'Add Business Client'}
                      </Button>

                      <Button
                        className={classes.saveButton}
                        disabled={(isNew && !dirty) || isSubmitting || !isValid}
                        type='button'
                        startIcon={<PictureAsPdf />}
                        variant='contained'
                        color='primary'
                        onClick={async () => {

                          const errors = await validateForm();

                          if (!isObjectEmpty(errors)) return;

                          const businessClientId = await onSubmitBeforeDownloadPdf(dirty, setSubmitting, values);

                          if (businessClientId == null) return;

                          setIsGeneratingPdf(true);
                          await downloadBusinessClientPdf(businessClientId);
                          setIsGeneratingPdf(false);

                          resetForm();

                          setTimeout(() => {
                            history.push('/manage-business-clients');
                          }, 1000);

                        }}
                      >
                        {'Submit to pdf'}
                      </Button>

                      <Button
                        type='button'
                        variant='contained'
                        color='inherit'
                        startIcon={<Close />}
                        className={classes.cancelButton}
                        onClick={() => history.push('/manage-business-clients')}
                      >
                        Cancel
                      </Button>
                    </CardActions>
                  </Form>
                  <Prompt
                    when={!deepEqual(initialValues, values) ? true : false}
                    message='Are you sure you want to navigate away from this page? Your unsaved changes will be lost.'
                  />
                </>
              );
            }}
          </Formik>
        </>
      )}
      <Toast id='business-client-success' message={successMessage || `Business Client Added!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='business-client-error'
        autoHideDuration={6000}
        message={
          errorMessage || 'We were unable to create the business client at this time. Please try again later. Please contact Enrollment Alliance support if this issue continues.'
        }
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </Page>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginTop: theme.spacing(1)
  },
  buttonActions: {
    marginTop: theme.spacing(2),
    '@media (max-width: 450px)': {
      flexDirection: 'column'
    }
  },
  saveButton: {
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  cancelButton: {
    '@media (max-width: 450px)': {
      width: '100%',
      marginTop: theme.spacing(0.5),
      marginLeft: `0 !important`
    }
  },
  tabContent: {
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  validationAlert: {},
  alertMessage: {
    '&& ul': {
      margin: 0,
      padding: `0 0 0 ${theme.spacing(1)}`
    }
  }
}));
