import React, { createContext, FC, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// helpers
import { setUserLocalStorage, removeUserLocalStorage, getUserLocalStorage, getIsUserExpired, userLogout } from '../helpers';
// models
import { ILoginUser } from '../models';

interface IUserContext {
  setUser: (user: ILoginUser | undefined) => void;
  user: ILoginUser | undefined;
  isFetching: boolean;
}

export const UserContext = createContext<IUserContext>({ setUser: () => {}, user: undefined, isFetching: false });

interface IUserContextHandlerProps {}

export const UserContextHandler: FC<IUserContextHandlerProps> = ({ children }): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState<ILoginUser | undefined>(undefined);
  const [isFetching, setFetching] = useState<boolean>(true);

  const handleSetUser = (user: ILoginUser | undefined) => {
    if (user) {
      setUserLocalStorage(user);
    } else {
      removeUserLocalStorage();
    }
    setUser(user);
  };

  const fetchUserFromLocalStorage = async () => {
    try {
      const user = await getUserLocalStorage();
      if (user) {
        // token expired
        if (getIsUserExpired(user.accessTokenExpiresAt)) {
          userLogout();
          history.push('/login');
        } else {
          setUser(user);
        }
      }
    } catch (e) {
      console.log(e, 'fetchUserFromLocalStorage');
    } finally {
      setFetching(false);
    }
  };
  useEffect(() => {
    fetchUserFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <UserContext.Provider value={{ user, setUser: handleSetUser, isFetching }}>{children}</UserContext.Provider>;
};
