import * as React from 'react';
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import { EnrollmentStrategySubMenusTypes } from '../../../MenuData/types';

import { DefaultTabBase } from '../shared';
import { EnrollmentStrategyTabs } from './tabs-header';
import EnrollmentPreferences from './EnrollmentPreferences/EnrollmentPreferences';
import Scheduling from './Scheduling/Scheduling';
import LocationsAndLogistics from './LocationsAndLogistics/LocationsAndLogistics';

const componentDictionary: Record<EnrollmentStrategySubMenusTypes, JSX.Element> = {
    'enrollment_preferences': <DefaultTabBase>
        <EnrollmentPreferences />
    </DefaultTabBase>,
    'scheduling':
        <DefaultTabBase>
            <Scheduling />
        </DefaultTabBase>
    ,
    'locations_&_logistics': <DefaultTabBase>
        <LocationsAndLogistics />
    </DefaultTabBase>,

};

export default function EnrollmentStrategy() {

    const { selectedSubMenu } = useLateralFormMenuControl();
    const typedSelectedSubMenu = selectedSubMenu as EnrollmentStrategySubMenusTypes

    return (
        <div>
            <EnrollmentStrategyTabs validateForm={() => { }} />

            <div>
                {componentDictionary[typedSelectedSubMenu]}
            </div>
        </div>
    );
}
