import { Box, Card, CardContent, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { buildSteps, formatName } from './EnrollmentBuilder';
import { formatDate } from '../../../helpers';
import { IBuildStep } from '../../../models';
import OverdueBatteryIcon from '../../../assets/icons/Battery Icon Sm - Overdue.png';
import NeedsAttentionBatteryIcon from '../../../assets/icons/Battery Icon Sm - Needs Attention.png';
import InProgressBatteryIcon from '../../../assets/icons/Battery Icon Sm - In Progress.png';
import CompleteBatteryIcon from '../../../assets/icons/Battery Icon Sm - Complete.png';

interface IEnrollmentBuilderPod {
  option: IBuildStep;
  dueDate: string;
}

export const EnrollmentBuilderPod = ({ option, dueDate }: IEnrollmentBuilderPod) => {
  const classes = useStyles();
  const selected = buildSteps.find(pod => pod.buildStep === option.buildStep);
  const setStatusColor = () => {
    if (option.buildStepStatus === 'Overdue') {
      return theme => theme.palette.error.main;
    }
    if (option.buildStepStatus === 'AttentionNeeded') {
      return theme => theme.palette.warning.main;
    }
    if (option.buildStepStatus === 'InProgress') {
      return theme => theme.palette.info.main;
    }
    return theme => theme.palette.success.main;
  };
  const setStatusLabel = () => {
    if (option.buildStepStatus === 'AttentionNeeded') {
      return 'Attention Needed';
    }
    if (option.buildStepStatus === 'InProgress') {
      return 'In Progress';
    }
    return option.buildStepStatus;
  };
  return (
    <Card className={classes.card}>
      <CardContent>
        <Box display='flex' justifyContent='center' className={classes.previewMainIcon}>
          {selected?.icon}
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center' className={classes.previewWrapper}>
          {/* make space between uppercase words */}
          <Typography className={classes.previewTitle}>{formatName(option.buildStep)}</Typography>
          {option.buildStepStatus === 'Overdue' && <img src={OverdueBatteryIcon} alt='Overdue' className={classes.battIcon} />}
          {option.buildStepStatus === 'AttentionNeeded' && <img src={NeedsAttentionBatteryIcon} alt='Attention Needed' className={classes.battIcon} />}
          {option.buildStepStatus === 'InProgress' && <img src={InProgressBatteryIcon} alt='In Progress' className={classes.battIcon} />}
          {option.buildStepStatus === 'Done' && <img src={CompleteBatteryIcon} alt='Done' className={classes.battIcon} />}
          {option.buildStepStatus && (
            <Typography
              sx={{
                fontWeight: 'bold',
                color: setStatusColor()
              }}
            >
              {setStatusLabel()}
            </Typography>
          )}
          {dueDate && <Typography>Due {formatDate(dueDate)}</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  previewMainIcon: {
    '& svg': {
      width: '4rem',
      height: '4rem'
    }
  },
  previewWrapper: {
    minHeight: '7.5rem'
  },
  previewTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  battIcon: {
    width: '4rem',
    marginTop: '.5rem',
    marginBottom: '.5rem'
  },
  card: {
    position: 'relative',
    textDecoration: 'none',
    '&:hover': {
      boxShadow: `0px 2px 1px -1px ${theme.palette.primary.main}, 0px 1px 1px 0px ${theme.palette.primary.main}, 0px 1px 3px 0px ${theme.palette.primary.main}`
    },
    '&.active': {
      boxShadow: `0px 0px 1px 2px ${theme.palette.primary.main}`
    }
  }
}));
