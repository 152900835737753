import React, { Fragment } from 'react';
import { FieldArray, Field, useFormikContext, FormikErrors } from 'formik';
import {
    Grid,
    Typography,
    Button,
    FormControl,
    TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { IBusinessClientDetailFormValues, IBusinessClientFollowUpMessageItem } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import { useSharedStyles } from '../../../../../../shared/styles';
import { DatePicker } from '../../../../../../../../../components';

const BusinessClientFollowUpMessageItemTable: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const messages: IBusinessClientFollowUpMessageItem[] = values.businessClientFollowUpMessages?.messages ?? [];

    const messagesErrors = errors.businessClientFollowUpMessages?.messages as FormikErrors<IBusinessClientFollowUpMessageItem>[];

    return (
        <FieldArray name="businessClientFollowUpMessages.messages">


            {({ push, remove }) => {
                return (
                    <div>
                        {messages.length > 0 &&
                            messages.map((item, index) => {
                                return (
                                    <Fragment key={`${index}`}>
                                        <Typography variant='h5' component='h5' className={classes.classText}>
                                            Message {index + 1}
                                        </Typography>
                                        <Grid container spacing={1} alignItems='center'>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormControl fullWidth variant='outlined'>
                                                    <DatePicker
                                                        label='Message Delivery Date'
                                                        value={item?.messageDeliveryDate ? new Date(item?.messageDeliveryDate) : null}
                                                        renderInput={props => (
                                                            <TextField {...props} size='small' label='Message Delivery Date' value={item?.messageDeliveryDate ? new Date(item?.messageDeliveryDate) : null} />
                                                        )}
                                                        onChange={(date: Date) => {
                                                            setFieldValue(`businessClientFollowUpMessages.messages.${index}.messageDeliveryDate`, date?.toISOString?.() ? date?.toISOString() : '');
                                                        }}
                                                    />
                                                    <FieldErrorMessage message={messagesErrors?.[index]?.messageDeliveryDate} />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <PerformantTextField
                                                    name={`businessClientFollowUpMessages.messages.${index}.emailSubjectLine`}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    autoComplete="nope"
                                                    label="Email Subject Line"
                                                    onBlur={handleBlur}
                                                />
                                                <FieldErrorMessage message={messagesErrors?.[index]?.emailSubjectLine} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <PerformantTextField
                                                    name={`businessClientFollowUpMessages.messages.${index}.textMessage`}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    autoComplete="nope"
                                                    label="Text Message"
                                                    onBlur={handleBlur}
                                                />
                                                <FieldErrorMessage message={messagesErrors?.[index]?.textMessage} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <PerformantTextField
                                                    multiline
                                                    name={`businessClientFollowUpMessages.messages.${index}.emailMessage`}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    autoComplete="nope"
                                                    label="Email Message"
                                                    onBlur={handleBlur}
                                                />
                                                <FieldErrorMessage message={messagesErrors?.[index]?.emailMessage} />
                                            </Grid>
                                            <Grid item xs={12} className={classes.removeButtonWrapper}>
                                                <Button
                                                    className={classes.removeButton}
                                                    startIcon={<Close />}
                                                    onClick={() => remove(index)}
                                                >
                                                    Remove Class
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                );
                            })}
                        {messages.length < 3 && (
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.addClassButton}
                                onClick={() =>
                                    push({
                                        messageDeliveryDate: null,
                                        textMessage: '',
                                        emailSubjectLine: '',
                                        emailMessage: ''
                                    })
                                }
                            >
                                Add Message
                            </Button>
                        )}
                    </div>
                );
            }}
        </FieldArray>
    );
};

export default BusinessClientFollowUpMessageItemTable;
