import { isAfter } from 'date-fns';
// helpers
import { getEnvironment } from './environment';
import { setCookie, getCookie, removeCookie } from './cookies';
import { setLocalStorage, removeLocalStorage, getLocalStorage } from './local-storage';
// models
import { ILoginUser } from '../models';

/**
 * Set user to local storage
 * @param user ILoginUser
 */
export const setUserLocalStorage = (user: ILoginUser | undefined): void => {
  if (user) {
    setLocalStorage(`enrollmentalliance-${getEnvironment()}-user`, user);
  }
};

/**
 * Remove user from local storage
 */
export const removeUserLocalStorage = async (): Promise<void> => {
  await removeLocalStorage(`enrollmentalliance-${getEnvironment()}-user`);
};

/**
 * Get user from local storage
 */
export const getUserLocalStorage = async (): Promise<ILoginUser | undefined> => {
  return await getLocalStorage(`enrollmentalliance-${getEnvironment()}-user`);
};

/**
 * Set access token and refresh token cookies for a user
 * @param accessToken string
 * @param tokenExpiresAt string
 */
export const setUserTokenCookies = (accessToken: string, tokenExpiresAt: string): void => {
  setCookie(`enrollmentalliance-${getEnvironment()}-token`, accessToken, { expires: new Date(tokenExpiresAt) });
};

/**
 * Remove access token and refresh token cookies. Used when logging out.
 */
export const removeUserTokenCookies = (): void => {
  removeCookie(`enrollmentalliance-${getEnvironment()}-token`);
};

/**
 * Get user access token
 * @returns string | undefined
 */
export const getUserAccessTokenCookie = (): string | undefined => {
  return getCookie(`enrollmentalliance-${getEnvironment()}-token`);
};

/**
 * Check to see if a user's JWT token has expired
 * @returns boolean
 */
export const getIsUserExpired = (tokenExpiresAt?: string): boolean => {
  let isExpired = true;

  if (tokenExpiresAt) {
    const now = new Date();
    const expires = new Date(tokenExpiresAt);
    if (!isAfter(now, expires)) {
      isExpired = false;
    }
  }

  return isExpired;
};

/**
 * When logging a user out, remove their tokens and local storage
 */
export const userLogout = async () => {
  await removeUserLocalStorage();
  removeUserTokenCookies();
};
