import {
  SCHEDULING_STATUS_ENUM,
  SCHEDULING_STATUS,
  ENROLLMENT_MEETING_OUTCOME,
  ENHANCED_STATUS_FILTERS_ENUM,
  ENROLLMENT_MEETING_OUTCOME_ENUM,
  POST_ENROLLMENT_FOLLOWUP_ITEM_ENUM,
  POST_ENROLLMENT_FOLLOWUP_ITEM,
  POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT,
  POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT_ENUM
} from '../models';

export const formatCountData = (enhancedFilterType, status): string => {
  if (enhancedFilterType === 'SchedulingStatus') {
    return getEmployeeStatus(status as SCHEDULING_STATUS_ENUM);
  }
  if (enhancedFilterType === 'EnrollmentMeetingOutcome') {
    return getEmployeeEnrollmentMtgStatus(status as ENROLLMENT_MEETING_OUTCOME_ENUM);
  }
  if (enhancedFilterType === 'PostEnrollmentFollowUpItem') {
    return getEmployeePostEnrollmentStatusParent(status as POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT_ENUM);
  }
};

export const getEmployeeStatus = (statusEnum: SCHEDULING_STATUS_ENUM): string => {
  if (statusEnum === 'FailedToReschedule') {
    return 'Failed to Reschedule';
  }

  if (statusEnum === 'NeedsToReschedule') {
    return 'Needs to Reschedule';
  }

  if (statusEnum === 'NotScheduled') {
    return 'Not Scheduled';
  }

  if (statusEnum === 'NonResponsive') {
    return 'Non-Responsive';
  }

  if (statusEnum === 'CancelledMeeting') {
    return 'Cancelled Meeting';
  }

  if (statusEnum === 'HRAssistanceNeeded') {
    return 'HR Assistance Needed';
  }

  return statusEnum;
};

export const getEmployeeStatusEnum = (status: SCHEDULING_STATUS): string => {
  if (status === 'Failed to Reschedule') {
    return 'FailedToReschedule';
  }

  if (status === 'Needs to Reschedule') {
    return 'NeedsToReschedule';
  }

  if (status === 'Not Scheduled') {
    return 'NotScheduled';
  }

  if (status === 'Non-Responsive') {
    return 'NonResponsive';
  }

  if (status === 'Cancelled Meeting') {
    return 'CancelledMeeting';
  }
  if (status === 'HR Assistance Needed') {
    return 'HRAssistanceNeeded';
  }

  return status;
};

export const getEmployeeEnrollmentMtgStatus = (statusEnum: ENROLLMENT_MEETING_OUTCOME_ENUM): string => {
  if (statusEnum === 'NotStarted') {
    return 'Not Started';
  }

  return statusEnum;
};

export const getEmployeeEnrollmentMtgStatusEnum = (status: ENROLLMENT_MEETING_OUTCOME): string => {
  if (status === 'Cancelled Meeting') {
    return 'CancelledMeeting';
  }

  if (status === 'Missed Meeting') {
    return 'MissedMeeting';
  }

  if (status === 'Not Started') {
    return 'NotStarted';
  }

  if (status === 'Upcoming Meeting') {
    return 'UpcomingMeeting';
  }

  return status;
};

export const getEmployeePostEnrollmentStatus = (statusEnum: POST_ENROLLMENT_FOLLOWUP_ITEM_ENUM): string => {
  if (statusEnum === 'ContactInfoInvalid') {
    return 'Limitation: Employee Contact Info Invalid';
  }
  if (statusEnum === 'EmployeeNotEligible') {
    return 'Limitation: Employee Termed or Not Eligible';
  }
  if (statusEnum === 'EmployeeNotInSystem') {
    return 'Limitation: Employee Not In System';
  }
  if (statusEnum === 'EnrolledPrior') {
    return 'Complete: Enrolled on System Prior to Benefit Meeting';
  }
  if (statusEnum === 'FurtherInfoToCollect') {
    return 'Complete: Further Info to Collect (Dep., SS, EOI)';
  }
  if (statusEnum === 'IncorrectInfo') {
    return 'Limitation: System Displaying Incorrect Rates / Products/ Info';
  }
  if (statusEnum === 'Indecisive') {
    return 'Incomplete: Indecisive / Not Prepared to Finalize Elections';
  }
  if (statusEnum === 'NoFollowUpRequired') {
    return 'Complete: No Follow Up Required';
  }
  if (statusEnum === 'NoMeetingOccurred') {
    return 'Missed Meeting: Meeting Did Not Occur';
  }
  if (statusEnum === 'RequestToReschedule') {
    return 'Incomplete: Employee Request to Reschedule';
  }
};

export const getEmployeePostEnrollmentStatusEnum = (status: POST_ENROLLMENT_FOLLOWUP_ITEM): string => {
  if (status === 'Limitation: Employee Contact Info Invalid') {
    return 'ContactInfoInvalid';
  }

  if (status === 'Limitation: Employee Termed or Not Eligible') {
    return 'EmployeeNotEligible';
  }

  if (status === 'Limitation: Employee Not In System') {
    return 'EmployeeNotInSystem';
  }

  if (status === 'Complete: Enrolled on System Prior to Benefit Meeting') {
    return 'EnrolledPrior';
  }
  if (status === 'Complete: Further Info to Collect (Dep., SS, EOI)') {
    return 'FurtherInfoToCollect';
  }
  if (status === 'Limitation: System Displaying Incorrect Rates / Products/ Info') {
    return 'IncorrectInfo';
  }
  if (status === 'Incomplete: Indecisive / Not Prepared to Finalize Elections') {
    return 'Indecisive';
  }
  if (status === 'Complete: No Follow Up Required') {
    return 'NoFollowUpRequired';
  }
  if (status === 'Missed Meeting: Meeting Did Not Occur') {
    return 'NoMeetingOccurred';
  }
  if (status === 'Incomplete: Employee Request to Reschedule') {
    return 'RequestToReschedule';
  }
};

export const getEmployeePostEnrollmentStatusParent = (status: POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT_ENUM): string => {
  if (status === 'NoFollowUpRequired') {
    return 'No Follow-Up Required';
  }
  if (status === 'HrAssistanceRecommended') {
    return 'HR Assistance Recommended';
  }
  if (status === 'ReviewInBenefitsAdminSystem') {
    return 'Review in Benefits Admin. System';
  }
};

export const getEmployeePostEnrollmentStatusParentEnum = (status: POST_ENROLLMENT_FOLLOWUP_ITEM_PARENT): string => {
  if (status === 'No Follow-Up Required') {
    return 'NoFollowUpRequired';
  }
  if (status === 'HR Assistance Recommended') {
    return 'HrAssistanceRecommended';
  }
  if (status === 'Review in Benefits Admin. System') {
    return 'ReviewInBenefitsAdminSystem';
  }
};

export const enhancedFilterTypeArray = [
  { value: 'SchedulingStatus', description: 'Scheduling Status' },
  { value: 'EnrollmentMeetingOutcome', description: 'Meeting Outcome' },
  { value: 'PostEnrollmentFollowUpItem', description: 'Post Enrollment Follow-Up' }
];

export const getEnhancedFilterType = (type: ENHANCED_STATUS_FILTERS_ENUM): string => {
  if (type === 'SchedulingStatus') {
    return 'Mtg. Schedule Status';
  }

  if (type === 'EnrollmentMeetingOutcome') {
    return 'Enrollment Mtg. Outcome';
  }

  if (type === 'PostEnrollmentFollowUpItem') {
    return 'Post Enrollment Follow-Up';
  }
};
