import React, { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import { Logo } from '../../components';

interface IMinimalPage {
  children: any;
  title: string;
}

export const MinimalPage: FC<IMinimalPage> = ({ children, title }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const renderTitle = `${title} | Enrollment Alliance`;
  useEffect(() => {
    document.title = renderTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return (
    <Container maxWidth='sm'>
      <Grid alignItems='center' className={classes.grid} container={true} direction='column' justifyContent='center'>
        <Card className={classes.card}>
          <FormControl margin='none' className={classes.logoWrapper}>
            <Logo className={classes.logo} />
          </FormControl>
          <CardContent className={classes.cardContent}>
            <Grid container={true} direction='column'>
              <FormControl size='small'>
                <Typography className={classes.h1} variant='h1'>
                  {title}
                </Typography>
              </FormControl>
              <Grid container={true} direction='column'>
                {children}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

export const MinimalPageLoading = () => {
  return (
    <MinimalPage title='Loading'>
      <div />
    </MinimalPage>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      width: '100%',
      '@media (min-width: 500px)': {
        width: 400
      }
    },
    cardContent: {
      '@media (min-width: 500px)': {
        padding: theme.spacing(1.5, 3)
      }
    },
    forgotPassword: {
      textDecoration: 'underline',
      textTransform: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    grid: {
      width: '100%',
      '@media (min-height: 568px)': {
        minHeight: '100vh'
      }
    },
    h1: {
      textAlign: 'center',
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 'bold'
    },
    image: {
      display: 'block',
      maxWidth: 150,
      marginBottom: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(3),
      width: '100%'
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
      background: '#000'
    },
    logo: {
      width: 200
    }
  };
});
