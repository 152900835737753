import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// Material setup
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './styles/theme';
import CssBaseline from '@mui/material/CssBaseline';
import './styles/index.css';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);
