import React, { useState } from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useSectionDocuments } from '../../pages/admin/business-client-detail/BusinessClientDetailPage/TabsForms/tabs-forms-contents/EnrollmentPlanner/EssentialDocsList/contexts/SectionDocumentsContext';
import { useLateralFormMenuControl } from '../../pages/admin/business-client-detail/contexts/LateralFormMenuControlContext';
import { NotFoundExtensionFileIcon, fileIconDictionary, getDocumentIcon } from '../../pages/admin/business-client-detail/BusinessClientDetailPage/TabsForms/tabs-forms-contents/EnrollmentPlanner/EssentialDocsList/EssentialDoc/util';
import { SubMenuAccordionCategoryTypes } from '../../pages/admin/business-client-detail/BusinessClientDetailPage/MenuData/types';
import makeStyles from '@mui/styles/makeStyles';
import { ISectionDocumentsFile } from '../../models';
import DocumentFileOptionsMenu from '../document-file-options-menu/DocumentFileOptionsMenu';

export type SectionDocumentCategoryTypes = SubMenuAccordionCategoryTypes;

type OpenedMenuFileInfoType = {
    file: ISectionDocumentsFile,
    anchorEl: null | HTMLElement
}

interface ISectionsFilesListProps {
    category: SectionDocumentCategoryTypes
}

const SectionsFilesList: React.FC<ISectionsFilesListProps> = ({ category }) => {
    const classes = useStyles();
    const { sectionDocuments, openAddNewFileModal } = useSectionDocuments();

    const [openedMenuFileType, setOpenedMenuFileType] = useState<OpenedMenuFileInfoType>(null);

    const currentFile = openedMenuFileType?.file;
    const anchorEl = openedMenuFileType?.anchorEl;

    const section = sectionDocuments.find(s => s.category === category);
    const sectionIndex = sectionDocuments.findIndex(s => s.category === category);

    const { setMenuSubMenuAccordionCategory } = useLateralFormMenuControl();

    const openMenuHandler = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, file: ISectionDocumentsFile) => {
        setOpenedMenuFileType({ anchorEl: event.currentTarget, file });
    };

    const closeMenuHandler = () => {
        setOpenedMenuFileType(null);
    };

    return (
        <div style={{ display: 'flex', marginBottom: '8px' }}>
            <div style={{
                maxWidth: '100%',
            }}>
                {section.files
                    .map((file) => {

                        const SelectedIcon = getDocumentIcon(file);

                        return (<>
                            <span className={classes.file} onClick={(e) => openMenuHandler(e, file)} title={file.fileName} style={{
                                marginRight: '5px',
                                display: 'inline-block',
                                height: "32px",
                                border: currentFile?.id === file.id ? '1px dashed #11a5c5' : 'none',


                            }}>
                                <SelectedIcon height="30px" width="30px" />
                            </span>

                        </>
                        )
                    })}

                <NoteAddIcon sx={
                    {
                        'color': 'rgba(0, 0, 0, 0.54)',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }
                } onClick={() => openAddNewFileModal(section, sectionIndex)} />

                <DocumentFileOptionsMenu
                    closeMenuCallback={closeMenuHandler}
                    anchorEl={anchorEl}
                    documentFile={currentFile}
                    section={section}
                    sectionIndex={sectionIndex} />

                <button className={classes.button} type='button' onClick={() => setMenuSubMenuAccordionCategory("enrollment-planner", "ess_docs", section.category as SubMenuAccordionCategoryTypes)}>Manage files</button>
            </div>
        </div>
    );
};
const useStyles = makeStyles(theme => ({

    file: {
        '&:hover': {
            cursor: 'pointer'
        },

    },

    button: {
        maxWidth: '100%',
        background: 'none!important',
        border: 'none',
        padding: '0!important',
        marginLeft: '10px',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer'
    }

}));

export default SectionsFilesList;
