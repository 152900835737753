import React, { FC } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
// images
import EALogoPrimary from '../../assets/logos/ea-logo-blue-white.png';
import EALogoSecondary from '../../assets/logos/ea-logo-sm-2021.png';

interface ILogoProps {
  className?: string;
  isSmall?: boolean;
  isPrimary?: boolean;
}

export const Logo: FC<ILogoProps> = ({ className, isSmall = false, isPrimary = true }) => {
  const classes = useStyles({ isSmall });
  return <img src={isPrimary ? EALogoPrimary : EALogoSecondary} alt='logo' className={clsx(classes.logo, className)} />;
};

const useStyles = makeStyles<Theme, { isSmall?: boolean }>((theme: Theme) => ({
  logo: ({ isSmall }) =>
    isSmall
      ? {
          width: 115,
          height: 'auto',
          '@media (min-width: 910px)': {
            width: 150
          }
        }
      : {
          width: 175,
          height: 'auto',
          '@media (min-width: 910px)': {
            width: 250
          }
        }
}));
