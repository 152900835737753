import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

export const PasswordRequirements = (): JSX.Element => {
  const classes = useStyles();
  return (
    <ul className={classes.list}>
      <li>Must be at least 6 characters long</li>
      <li>Must have at least one capital letter</li>
      <li>Must have at least one numeric character</li>
      <li>Must have at least one &quot;special&quot; character, ex: !$#@%</li>
    </ul>
  );
};
const useStyles = makeStyles(() => {
  return {
    list: {
      fontSize: 14,
      paddingLeft: 24
    }
  };
});
