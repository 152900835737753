import { useEffect, useContext } from "react";

import useErrorMessage from "../../context/ErrorMessageCtx";
import { RequestState } from "../../models/request-state";
import { DropDownCollectionsCtx } from "../../context/DropDownCollectionsCtx";
import { getDefaultDropdownsOptionsRequest } from "../../fetch";
import { IDefaultDropdownsOptions } from "../../models";

const useDefaultDropdownsOptionsDropdownCollection = (): RequestState<IDefaultDropdownsOptions> => {
    const [, setErrorMessage] = useErrorMessage();
    const { defaultDropdownsOptionsRequest, dispatchDefaultDropdownsOptionsRequest } = useContext(DropDownCollectionsCtx);

    const status = defaultDropdownsOptionsRequest.status;


    useEffect(() => {

        if (status !== "idle") return;

        dispatchDefaultDropdownsOptionsRequest({ type: 'REQUEST_LOADING' });

        const fetchCollection = async () => {
            try {

                const res = await getDefaultDropdownsOptionsRequest();
                if (res) {

                    dispatchDefaultDropdownsOptionsRequest({ type: 'REQUEST_SUCCESS', payload: res })
                } else {
                    setErrorMessage('Error loading options, please try again.');
                    dispatchDefaultDropdownsOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading, please try again.' })
                }
            } catch (error) {
                console.error(error);
                dispatchDefaultDropdownsOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading, please try again.' })
                setErrorMessage('Error loading options, please try again.');
            }


        };

        fetchCollection();
    }, [status, dispatchDefaultDropdownsOptionsRequest, setErrorMessage]);

    return defaultDropdownsOptionsRequest;

};

export default useDefaultDropdownsOptionsDropdownCollection