import { FC, useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Grid, Typography, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from '../../components';
// helpers
import { formatCountData } from '../../helpers';
import { getBusinessClientDashboard } from '../../fetch';

interface IEmployeePieChartsProps {
  type: string;
  businessClientId: number;
  setEnhancedFilterType: () => void;
  setSelectedProgressBar: (val: string) => void;
  selectedMonth?: {
    value?: string;
    lastDay?: string;
  };
  label: string;
}

export const EmployeePieChart: FC<IEmployeePieChartsProps> = ({ businessClientId, selectedMonth, type, label, setEnhancedFilterType, setSelectedProgressBar }) => {
  const classes = useStyles();

  const [data, setData] = useState<any[]>(null);
  const [isLoading, setLoading] = useState(false);
  const [oldSelectedMonth, setOldSelectedMonth] = useState(selectedMonth);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const res = await getBusinessClientDashboard(businessClientId, type, selectedMonth ? selectedMonth.value : null, selectedMonth ? selectedMonth.lastDay : null);
      if (res && res.employeeStatusCounts && res.employeeStatusCounts.length > 0) {
        const d = res.employeeStatusCounts.reduce((prev, next) => {
          return {
            ...prev,
            [formatCountData(type, next.status)]: next.count
          };
        }, {});
        setData(mapStatuses([d]));
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedMonth)
      if (oldSelectedMonth.lastDay === selectedMonth.lastDay && oldSelectedMonth.value === selectedMonth.value) {
        return;
      } else {
        fetchDashboardData();
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    setOldSelectedMonth(selectedMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);
  const mapSchedulingStatuses = (
    data: {
      [key: string]: any;
    }[]
  ) => {
    let completeTotal = 0;
    let scheduledTotal = 0;
    let notScheduledTotal = 0;
    if (Object.entries(data[0]).length) {
      for (const [key, value] of Object.entries(data[0])) {
        if (key === 'Complete') {
          completeTotal += value;
        } else if (key === 'Scheduled') {
          scheduledTotal += value;
        } else {
          notScheduledTotal += value;
        }
      }
    }

    return [
      {
        id: 'NotScheduled',
        label: 'Not Scheduled',
        value: notScheduledTotal,
        color: '#4C4B4C'
      },
      {
        id: 'Scheduled',
        label: 'Scheduled',
        value: scheduledTotal,
        color: '#7CDEF3'
      },
      {
        id: 'Complete',
        label: 'Complete',
        value: completeTotal,
        color: '#11A5C5'
      }
    ];
  };
  const mapEnrollmentMtgStatuses = (
    data: {
      [key: string]: any;
    }[]
  ) => {
    let completeTotal = 0;
    let incompleteTotal = 0;
    let notStartedTotal = 0;
    if (Object.entries(data[0]).length) {
      for (const [key, value] of Object.entries(data[0])) {
        if (key === 'Complete') {
          completeTotal += value;
        } else if (key === 'Incomplete') {
          incompleteTotal += value;
        } else {
          notStartedTotal += value;
        }
      }
    }

    return [
      {
        id: 'NotStarted',
        label: 'Not Started',
        value: notStartedTotal,
        color: '#4C4B4C'
      },
      {
        id: 'Incomplete',
        label: 'Incomplete',
        value: incompleteTotal,
        color: '#7CDEF3'
      },
      {
        id: 'Complete',
        label: 'Complete',
        value: completeTotal,
        color: '#11A5C5'
      }
    ];
  };
  const mapPostEnrollmentStatuses = (
    data: {
      [key: string]: any;
    }[]
  ) => {
    let completeTotal = 0;
    let followUpTotal = 0;
    let assistanceTotal = 0;
    if (Object.entries(data[0]).length) {
      for (const [key, value] of Object.entries(data[0])) {
        if (key === 'No Follow-Up Required') {
          completeTotal += value;
        } else if (key === 'Review in Benefits Admin. System') {
          followUpTotal += value;
        } else {
          assistanceTotal += value;
        }
      }
    }

    return [
      {
        id: 'HrAssistanceRecommended',
        label: 'HR Assistance Recommended',
        value: assistanceTotal,
        color: '#4C4B4C'
      },
      {
        id: 'ReviewInBenefitsAdminSystem',
        label: 'Review in Benefits Admin. System',
        value: followUpTotal,
        color: '#7CDEF3'
      },
      {
        id: 'NoFollowUpRequired',
        label: 'No Follow-Up Required',
        value: completeTotal,
        color: '#11A5C5'
      }
    ];
  };
  const mapStatuses = (data: any[]) => {
    if (type === 'EnrollmentMeetingOutcome') {
      return mapEnrollmentMtgStatuses(data);
    } else if (type === 'PostEnrollmentFollowUpItem') {
      return mapPostEnrollmentStatuses(data);
    } else {
      return mapSchedulingStatuses(data);
    }
  };
  return (
    <Grid item xs={12} md={6} lg={4}>
      {isLoading && (
        <Box height='350px' position='relative' margin='1rem'>
          <Loader position='centered' />
        </Box>
      )}
      {!isLoading && data && data.length > 0 && (
        <Box className={classes.dataWrapper}>
          <Typography className={classes.dataLabel}>{label}</Typography>
          <Box height='350px' position='relative' margin='1rem' className={classes.pieWrapper}>
            <ResponsivePie
              data={data}
              margin={{ top: 20, right: 0, bottom: 80, left: 0 }}
              animate={true}
              activeOuterRadiusOffset={8}
              onClick={val => {
                setEnhancedFilterType();
                setSelectedProgressBar(val.id as string);
              }}
              colors={{ datum: 'data.color' }}
              enableArcLinkLabels={false}
              arcLinkLabel='label'
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor='#fff'
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLinkLabelsDiagonalLength={5}
              arcLinkLabelsStraightLength={5}
              arcLinkLabelsTextOffset={4}
              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
              legends={[
                {
                  anchor: 'bottom-left',
                  direction: 'column',
                  justify: false,
                  translateX: 0,
                  translateY: 68,
                  itemsSpacing: 10,
                  itemWidth: 100,
                  itemHeight: 15,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />
          </Box>
        </Box>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dataWrapper: {
    border: `1px solid #e1e1e1`,
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.5)
  },
  pieWrapper: {
    '&& text[text-anchor="middle"]': {
      fontSize: `16px !important`,
      fontWeight: 'bold',
      fill: `#fff !important`
    }
  },
  dataLabel: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 'bold'
  }
}));
