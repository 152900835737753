import React, { useEffect, useState, FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
// Components
import { Header } from './Header';
import { Footer } from './Footer';
import { AdminMenu } from './AdminMenu';
import { SwipeableDrawer, Fab } from '@mui/material';
// Icons
import { Settings } from '@mui/icons-material';
// context
import { UserContext } from '../../context';
import { EUserRoles } from '../../models';

interface IPage {
  children: any;
  title: string;
}

export const Page: FC<IPage> = ({ children, title }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const renderTitle = `${title} | Enrollment Alliance`;
  const [isMobileAdminMenuOpen, setIsMobileAdminMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    document.title = renderTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, title]);

  const { user } = useContext(UserContext);

  const isAdmin = user && user.roles.includes(EUserRoles.ADMIN);
  return (
    <>
      <Header />
      <div className={classes.mainContent}>
        {isAdmin && (
          <>
            <div className={classes.adminMenuWrapper}>
              <AdminMenu />
            </div>
            <div className={classes.adminMenuWrapperWithCog}>
              <Fab onClick={() => setIsMobileAdminMenuOpen(!isMobileAdminMenuOpen)} size='small' className={classes.mobileAdminMenuButton} color='primary' aria-label='admin-menu'>
                <Settings />
              </Fab>
              <SwipeableDrawer anchor='left' open={isMobileAdminMenuOpen} onClose={() => setIsMobileAdminMenuOpen(false)} onOpen={() => setIsMobileAdminMenuOpen(true)}>
                <AdminMenu />
              </SwipeableDrawer>
            </div>
          </>
        )}
        <div className={classes.contentArea}>{children}</div>
      </div>
      <Footer />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  mainContent: {
    minHeight: `calc(100vh - 168px)`,
    '@media (min-width: 676px)': {
      minHeight: `calc(100vh - 183px)`
    },
    display: 'flex'
  },
  contentArea: {
    flexGrow: 1,
    padding: theme.spacing(1.8, 1, 1, 1.8),
    maxWidth: '100%',

    '@media (max-width: 960px)': {
      padding: theme.spacing(1),
    },

    display: 'flex',
    flexDirection: 'column'
  },
  mobileAdminMenuButton: {
    position: 'fixed',
    top: theme.spacing(5.5),
    right: theme.spacing(0.5),
    zIndex: 2,
    '@media (min-width: 676px)': {
      top: theme.spacing(7)
    }
  },
  adminMenuWrapper: {
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'inherit'
    }
  },
  adminMenuWrapperWithCog: {
    display: 'inherit',
    '@media (min-width: 960px)': {
      display: 'none'
    }
  }
}));
