

import { Accordion } from '../../../../../../../../components';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { useSharedStyles } from '../../../../../shared/styles';
import FollowUpMessages from './FollowUpMessages/FollowUpMessages';
import BusinessClientFollowUpMessageItemTable from './BusinessClientFollowUpMessageItemTable/BusinessClientFollowUpMessageItemTable';
import { Divider } from '@mui/material';

export default function CampaignStrategy() {

    const { values, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('campaign_strategy');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />

            <Accordion title='Follow Up Messages' component='h3' variant='h4'>
                <FollowUpMessages />
                <Divider sx={{marginBottom: 1}} />
                <BusinessClientFollowUpMessageItemTable />
            </Accordion>

        </div>
    );
}