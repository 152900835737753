import React, { Fragment } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { InfoOutlined, DragIndicator } from '@mui/icons-material';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
// Components
import { Grid, Switch, FormControlLabel, TextField, Box, useMediaQuery } from '@mui/material';
// Types
import { EMobileAppLinkTypeEnums } from '../../../models';
import { Field } from 'formik';
import clsx from 'clsx';
import { getAppIcon } from './AppLinks';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';

interface ISortableList {
  links: any[];
  form: any;
  disableFields: boolean;
}

interface ISortableItem {
  itemIndex: number;
  form: any;
  disableFields: boolean;
  disableUrlField: boolean;
  urlLabel: string;
  linkUrl: string;
  icon: any;
  isMobile: boolean;
  linkType: string;
}

const DragHandle = SortableHandle(() => {
  const classes = useStyles();
  return <DragIndicator className={classes.dragIcon} />;
});

const SortableItem = SortableElement(({ isMobile, itemIndex, icon, form, disableFields, disableUrlField, urlLabel, linkUrl, linkType }: ISortableItem) => {
  const classes = useStyles();
  const isInstructions = linkType === EMobileAppLinkTypeEnums.INSTRUCTIONS;
  const isAutoAssigned = linkType === EMobileAppLinkTypeEnums.CALENDAR || linkType === EMobileAppLinkTypeEnums.BENEFITS_INFO || linkType === EMobileAppLinkTypeEnums.MEDIA;
  return (
    <Box position='relative' zIndex={1500}>
      <Grid container spacing={1} className={classes.linkRow}>
        <Grid item xs='auto' className={classes.linkSwitchColumn}>
          <Field name={`links.${itemIndex}.enabled`}>
            {({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      color='primary'
                      checked={form.values.links[itemIndex].enabled}
                      id={`links.${itemIndex}.enabled`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue(`links.${itemIndex}.enabled`, e.target.checked);
                      }}
                      onBlur={form.handleBlur}
                      disabled={disableFields}
                      margin='false'
                    />
                  }
                  title={form.values.links[itemIndex].enabled ? `Turn ${urlLabel} OFF` : `Turn ${urlLabel} ON`}
                  label=''
                  className={classes.linkSwitchLabel}
                />
              );
            }}
          </Field>
        </Grid>
        <Grid
          item
          xs='auto'
          className={clsx(
            classes.linkIconColumn,
            form.values.links[itemIndex].enabled ? classes.activeIcon : '',
            disableFields || !form.values.links[itemIndex].enabled ? classes.disableOpacity : ''
          )}
        >
          <Field name={`links.${itemIndex}.type`}>
            {({ field }) => {
              return icon;
            }}
          </Field>
        </Grid>
        <Grid item className={classes.linkColumnGrow}>
          <div>
            <Field name={`links.${itemIndex}.link`}>
              {({ field }) => {
                return (
                  <PerformantTextField
                    {...field}
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label={`${urlLabel} - URL`}
                    name={`links.${itemIndex}.link`}
                    placeholder='www.example.com'
                    value={isInstructions ? 'Information will be provided in the app' : isAutoAssigned ? 'URL Auto-Assigned' : linkUrl}
                    onBlur={form.handleBlur}
                    required={!disableUrlField && form.values.links[itemIndex].enabled && !isInstructions}
                    error={Boolean(form.touched && form.errors.links && form.errors.links[itemIndex] && form.errors.links[itemIndex].link) && !isInstructions}
                    helperText={form.touched && form.errors.links && form.errors.links[itemIndex] && form.errors.links[itemIndex].link && !isInstructions}
                    disabled={disableUrlField || !form.values.links[itemIndex].enabled || isInstructions}
                  />
                );
              }}
            </Field>
            <Field name={`links.${itemIndex}.name`}>
              {({ field }) => {
                return (
                  <PerformantTextField
                    {...field}
                    fullWidth
                    className={classes.nameTextField}
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label={`${urlLabel} - Tile Name`}
                    name={`links.${itemIndex}.name`}
                    inputProps={{
                      maxLength: 50
                    }}
                    value={isInstructions ? 'Instructions will be provided in the app' : field.value || ''}
                    onBlur={form.handleBlur}
                    disabled={!form.values.links[itemIndex].enabled || !form.values.mobileAppEnabled || isInstructions}
                  />
                );
              }}
            </Field>
          </div>
        </Grid>
      </Grid>
      {!isMobile && <DragHandle />}
    </Box>
  );
});

export const SortableLinksList = SortableContainer(({ form, disableFields, links }: ISortableList) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 960px)');
  return (
    <Box>
      {links &&
        links.length > 0 &&
        links.map((item, index) => {
          let urlLabel = 'URL';
          let disableUrlField = disableFields ? true : false;
          let icon = <InfoOutlined className={classes.linkIcon} />;
          let linkUrl = form.values.links[index].link || '';
          const { label, component } = getAppIcon(form.values.links[index].type);
          urlLabel = label;
          // disable schedule and benefits fields since those come from the other business client modal
          disableUrlField =
            form.values.links[index].type === EMobileAppLinkTypeEnums.CALENDAR ||
            form.values.links[index].type === EMobileAppLinkTypeEnums.BENEFITS_INFO ||
            form.values.links[index].type === EMobileAppLinkTypeEnums.MEDIA;
          icon = React.cloneElement(component, {
            className: classes.linkIcon
          });

          return (
            <Fragment key={`${index}`}>
              <SortableItem
                itemIndex={index}
                urlLabel={urlLabel}
                linkUrl={linkUrl}
                form={form}
                index={index}
                disableUrlField={disableUrlField}
                disableFields={disableFields}
                icon={icon}
                isMobile={isMobile}
                linkType={item.type}
              />
            </Fragment>
          );
        })}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  linkSwitchLabel: {
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1)
    }
  },
  linkIcon: {
    fontSize: theme.spacing(2)
  },
  linkColumnGrow: {
    minWidth: '1px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      flex: 1
    }
  },
  linkIconColumn: {
    '&&.MuiGrid-item': {
      paddingTop: theme.spacing(1.25)
    },
    [theme.breakpoints.down('lg')]: {
      '&&.MuiGrid-item': {
        paddingBottom: 0,
        marginBottom: -theme.spacing(0.25)
      }
    }
  },
  linkSwitchColumn: {
    '&&.MuiGrid-item': {
      paddingTop: theme.spacing(1)
    },
    [theme.breakpoints.down('lg')]: {
      '&&.MuiGrid-item': {
        paddingBottom: 0,
        marginBottom: -theme.spacing(0.25)
      }
    }
  },
  linkRow: {
    alignItems: 'center',
    '&&.MuiGrid-container': {
      paddingBottom: theme.spacing(2)
    }
  },
  disableOpacity: {
    opacity: '.3'
  },
  activeIcon: {
    color: theme.palette.primary.dark
  },

  nameTextField: {
    marginTop: theme.spacing(1)
  },

  dragIcon: {
    position: 'absolute',
    top: 40,
    right: -40,
    background: 'white',
    fill: theme.palette.grey[600],
    fontSize: '2.5rem',
    cursor: 'grab'
  }
}));
