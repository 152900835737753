import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, TextField } from '@mui/material';
import { Page, PageTitle } from '../../components/skeleton';
import { EmployeeDetailCard } from './EmployeeDetailCard';
import { Loader, Message } from '../../components';
// fetch
import { getEmployee } from '../../fetch';
// models
import { IEmployeeDetail, EUserRoles } from '../../models';
// helpers
import { formatDate } from '../../helpers';
// icons
import { AccountCircle } from '@mui/icons-material';
// context
import { UserContext } from '../../context';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TimeZoneContext } from '../../context/timezone';
import BenefitsCounselorNotes from '../../components/pages/employees/BenefitsCounselorNotes';
import DetailedCommunicationTimeline from '../../components/pages/employees/DetailedCommunicationTimeline';

export const EmployeeDetail = () => {
  const classes = useStyles();
  const { businessClientId, id } = useParams();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [currentEmployee, setEmployee] = useState<IEmployeeDetail | null>(null);

  const { user } = useContext(UserContext);

  const history = useHistory();

  const isAdmin = user && user.roles.includes(EUserRoles.ADMIN);

  // Time Zone
  const { timeZone } = useContext(TimeZoneContext);


  const fetchEmployee = async () => {
    try {
      const res = await getEmployee(businessClientId, id);
      if (res.firstName) {
        setEmployee(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchEmployee();
  }, []);


  return (
    <Page title='Employee Detail'>
      {isLoading && <Loader position='centered' />}
      {!currentEmployee && !isLoading && (
        <Message
          title='Employee Not Found'
          message={`No Employee was found with id ${id}`}
          icon='User'
          actionText='Go back to employees'
          action={() => {
            history.push(`/business-clients/${businessClientId}/employees`);
          }}
        />
      )}
      {currentEmployee && !isLoading && (
        <>
          <PageTitle title={`${currentEmployee.firstName} ${currentEmployee.lastName}`} />
          <EmployeeDetailCard title='Employee Details' icon={<AccountCircle />}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='first-name'
                  label='First Name'
                  defaultValue={currentEmployee.firstName || ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='last-name'
                  label='Last Name'
                  defaultValue={currentEmployee.lastName || ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='phone'
                  label='Phone'
                  defaultValue={currentEmployee.phoneNumber && currentEmployee.phoneNumber[0] ? currentEmployee.phoneNumber[0].number : ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='email'
                  label='Email'
                  defaultValue={currentEmployee.email || ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='class'
                  label='Class'
                  defaultValue={currentEmployee.class && currentEmployee.class.name ? currentEmployee.class.name : ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='location'
                  label='Location'
                  defaultValue={currentEmployee.location && currentEmployee.location.name ? currentEmployee.location.name : ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='date'
                  label='Date of Hire'
                  defaultValue={formatDate(currentEmployee.dateOfHire) || ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='effective-date'
                  label='Effective Date'
                  defaultValue={formatDate(currentEmployee.effectiveDate) || ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id='preferred-language'
                  label='Preferred Language'
                  defaultValue={currentEmployee.preferredLanguage || ' '}
                  InputProps={{
                    readOnly: true
                  }}
                  variant='standard'
                />
              </Grid>
              {isAdmin && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id='notes'
                    label='Notes from HR'
                    defaultValue={currentEmployee.notes || ' '}
                    InputProps={{
                      readOnly: true,
                      style: {
                        resize: 'vertical'
                      },
                      rows: 2,
                      multiline: true
                    }}
                    variant='standard'
                  />
                </Grid>
              )}
            </Grid>
          </EmployeeDetailCard>

          <DetailedCommunicationTimeline
            businessClientId={businessClientId}
            classes={classes}
            employeeId={id} />

          {isAdmin && (<BenefitsCounselorNotes
            businessClientId={businessClientId}
            classes={classes}
            employeeId={id}
            timeZone={timeZone} />
          )}
        </>
      )}
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    backgroundColor: theme.palette.secondary.light
  },
  caseSensitive: {
    textTransform: 'none',
    padding: 0
  },
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  detailsRow: {},
  rowText: {
    marginLeft: theme.spacing(1)
  },
  cardContent: {},
  cardContentText: {
    wordBreak: 'break-word'
  },
  cardHeader: {},
  cardFooter: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.grey['200']
  },
  turboNoteContainer: {
    maxHeight: 500,
    overflow: 'scroll'
  },
  cardGrid: {
    maxHeight: 600
  },
  paginationWrapper: {},
  popover: {},
  arrow: {
    Position: 'absolute',
    Top: '-18px',
    left: '20px',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: '20px solid rgb(233, 212, 222)',
    Width: 0,
    Height: 0,
    webkitFilter: 'drop-shadow(0 -2px 2px #AAA)'
  },
  paper: {
    //https://github.com/mui-org/material-ui/issues/18928
    borderRadius: 10,
    overflowX: 'unset',
    overflowY: 'unset',
    '&::before': {
      content: '""',
      position: 'absolute',
      marginLeft: '0.71em',
      bottom: 1,
      left: '10%',
      width: 30,
      height: 30,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      transform: 'translate(-50%, 50%)',
      clipPath: 'polygon(50% 0%, 75% 50%, 50% 100%, 25% 50%)'
    },
    padding: theme.spacing(1),
    maxWidth: 300,
    wordBreak: 'break-word',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500
    }
  },
  emailBody: {
    maxWidth: 500,
    [theme.breakpoints.down('md')]: {
      maxHeight: 250,
      overflowY: 'scroll'
    }
  },
  title: {
    fontSize: 18
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey['50']} !important`
    }
  },
  viewDetailsColumn: {
    textAlign: 'center'
  }
}));
