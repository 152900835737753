// @ts-ignore
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import React, { ReactElement } from 'react';
import { Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { render } from '@testing-library/react';
import { theme } from '../styles/theme';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


interface Props {
  children: React.ReactNode;
}

export const renderWithProviders = (ui: ReactElement<any>) => {
  setWindowToDesktop();
  const Providers = ({ children }: Props) => {
    return (
      <Router history={createMemoryHistory()}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyledEngineProvider>
      </Router>
    );
  };
  // @ts-ignore
  return render(ui, { wrapper: Providers });
};

export const setWindowToDesktop = () => {
  if (process.env.NODE_ENV === 'test') {
    window.matchMedia = jest.fn().mockImplementation(query => {
      return {
        matches: true,
        media: query,
        onchange: null,
        addListener: jest.fn(), // deprecated
        removeListener: jest.fn(), // deprecated
        addEventListener: jest.fn(),
        removeEventListener: jest.fn(),
        dispatchEvent: jest.fn()
      };
    });
  }
};
