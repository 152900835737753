import React from 'react';
import { FieldArray, Field, useFormikContext, FormikErrors } from 'formik';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { IBusinessClientDetailFormValues, IShortTermDisabilityCoverageClass } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import { useSharedStyles } from '../../../../../../shared/styles';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import useDefaultDropdownsOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';


const ShortTermDisabilityCoverageClassesTable: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const shortTermDisabilityCoverageClasses: IShortTermDisabilityCoverageClass[] = values.shortTermDisabilityCoverage?.shortTermDisabilityCoverageClasses ?? [];

    const shortTermDisabilityCoverageClassesErrors = errors.shortTermDisabilityCoverage?.shortTermDisabilityCoverageClasses as FormikErrors<IShortTermDisabilityCoverageClass>[];

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';

    return (
        <FieldArray name='shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses'>
            {({ push, remove }) => (
                <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
                    {shortTermDisabilityCoverageClasses.length > 0 ? (
                        <Box sx={{ overflow: "auto" }}>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>Class Name</TableCell>
                                        <TableCell className={classes.tableCell}>Max Weekly Benefit</TableCell>

                                        <TableCell className={classes.tableCell}>Policy Type</TableCell>
                                        <TableCell className={classes.tableCell}>GI Eligibility</TableCell>
                                        <TableCell className={classes.tableCell}>Payment Type</TableCell>
                                        <TableCell className={classes.tableCell}>Elimination Periods</TableCell>
                                        <TableCell className={classes.tableCell}>Pre-Ex Rules</TableCell>

                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shortTermDisabilityCoverageClasses.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.tableCell}>
                                                <Field
                                                    name={`shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses.${index}.className`}
                                                    as={PerformantTextField}
                                                    fullWidth
                                                    variant='outlined'
                                                    size='small'
                                                    autoComplete='nope'
                                                    label='Class Name'
                                                    onBlur={handleBlur}
                                                />
                                                <FieldErrorMessage message={shortTermDisabilityCoverageClassesErrors?.[index]?.className} />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Field
                                                    name={`shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses.${index}.maxWeeklyBenefit`}
                                                    as={PerformantTextField}
                                                    fullWidth
                                                    variant='outlined'
                                                    size='small'
                                                    autoComplete='nope'
                                                    label='Max Weekly Benefit'
                                                    onBlur={handleBlur}
                                                />
                                                <FieldErrorMessage message={shortTermDisabilityCoverageClassesErrors?.[index]?.maxWeeklyBenefit} />
                                            </TableCell>

                                            <TableCell className={classes.tableCell}>

                                                <GenericDropdown
                                                    handleBlur={handleBlur}
                                                    options={generalDropdownsOptions?.stdCoverageClassPolicyTypes ?? []}
                                                    isLoading={generalDropdownsOptionsLoading}
                                                    setFieldValue={setFieldValue}
                                                    value={item?.policyType}
                                                    error={shortTermDisabilityCoverageClassesErrors?.[index]?.policyType}
                                                    label='Policy Type'

                                                    id={`shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses.${index}.policyType`}
                                                />

                                            </TableCell>

                                            <TableCell className={classes.tableCell}>
                                                <GenericDropdown
                                                    handleBlur={handleBlur}
                                                    options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
                                                    isLoading={defaultDropdownsOptionsLoading}
                                                    setFieldValue={setFieldValue}
                                                    value={item?.giEligibility}
                                                    error={shortTermDisabilityCoverageClassesErrors?.[index]?.giEligibility}
                                                    label='GI Eligibility'

                                                    id={`shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses.${index}.giEligibility`}
                                                />

                                            </TableCell>

                                            <TableCell className={classes.tableCell}>
                                                <GenericDropdown
                                                    handleBlur={handleBlur}
                                                    options={defaultDropdownsOptions?.defaultPaymentTypeOptions ?? []}
                                                    isLoading={defaultDropdownsOptionsLoading}
                                                    setFieldValue={setFieldValue}
                                                    value={item?.paymentType}
                                                    error={shortTermDisabilityCoverageClassesErrors?.[index]?.paymentType}
                                                    label='Payment Type'

                                                    id={`shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses.${index}.paymentType`}
                                                />

                                            </TableCell>

                                            <TableCell className={classes.tableCell}>
                                                <GenericDropdown
                                                    handleBlur={handleBlur}
                                                    options={defaultDropdownsOptions?.defaultEliminationAccumulationPeriodsOptions ?? []}
                                                    isLoading={defaultDropdownsOptionsLoading}
                                                    setFieldValue={setFieldValue}
                                                    value={item?.eliminationPeriods ?? ''}
                                                    error={shortTermDisabilityCoverageClassesErrors?.[index]?.eliminationPeriods}
                                                    label="Elimination Periods"
                                                    id={`shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses.${index}.eliminationPeriods`}
                                                />
                                            </TableCell>

                                            <TableCell className={classes.tableCell}>
                                                <GenericDropdown
                                                    handleBlur={handleBlur}
                                                    options={defaultDropdownsOptions?.defaultPreExRulesOptions ?? []}
                                                    isLoading={defaultDropdownsOptionsLoading}
                                                    setFieldValue={setFieldValue}
                                                    value={item?.preExRules ?? ''}
                                                    error={shortTermDisabilityCoverageClassesErrors?.[index]?.preExRules}
                                                    label="LTD Pre-Ex Rules"
                                                    id={`shortTermDisabilityCoverage.shortTermDisabilityCoverageClasses.${index}.preExRules`}
                                                />

                                            </TableCell>

                                            <TableCell>
                                                <Button startIcon={<Close />} onClick={() => remove(index)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Box></Box>
                    ) : (
                        <></>
                    )}

                    {shortTermDisabilityCoverageClasses.length < 3 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    className: '',
                                    maxWeeklyBenefit: '',
                                })
                            }
                        >
                            Add Class
                        </Button>
                    )}
                </div>
            )}
        </FieldArray>
    );
};

export default ShortTermDisabilityCoverageClassesTable;
