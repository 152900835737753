import { IBroker } from './broker';

export interface ISectionDocumentsFile {
  id: number;
  fileVersionId: number;
  fileUrl: string;
  fileName: string;
  otherInformations: string;
  docContentType: string;
  externalFileUrl: string;
  fileExtension: string;
  uploadedBy: string;
  uploadedDateTime: string;
}

export interface ISectionDocuments {
  id: number | null;
  status: string;
  category: string;
  categoryDescription: string;
  files: ISectionDocumentsFile[];
  orderIndex: number;
}

export type OfferedNotOfferedBenefit = {
  benefitName: string;
  offered: boolean;
}

export interface ISystemOfRecordRes {
  records: ISystemOfRecord[];
  totalRecordCount: number;
}

export interface ISystemOfRecord {
  systemOfRecordId: number;
  name: string;
}

export interface IDropdownResponse {
  value: string;
  description: string;
  shorthand: string;
}

export interface IDefaultDropdownsOptions {
  defaultPreExRulesOptions: IDropdownResponse[];
  defaultPlanOfferOptions: IDropdownResponse[];
  defaultPeriodOptions: IDropdownResponse[];

  defaultPortabilityOptions: IDropdownResponse[];
  defaultGIEligibilityOptions: IDropdownResponse[];
  defaultPaymentTypeOptions: IDropdownResponse[];
  defaultEliminationAccumulationPeriodsOptions: IDropdownResponse[];
}

export interface IEnrollmentEngagementNumbers {
  id?: number;
  priorYearEnrollmentStyle: string;
  priorYearEngagement: string;
  employeeEngagement: string;
  numberOfEnrollerDays: string;
  engagementPercentage: string;
}

export interface IProductParticipation {
  id?: number | null;
  hsaParticipationPriorYear: string;
  hsaParticipation: string;
  fieldTitle: string;
}

export interface IChallengesAndSolutions {
  id?: number | null;
  topChallengesToSolveForEnrollment: string;
  solutionsImplemented: string;
  keyTakeawaysFromStrategy: string;
}

export interface IGeneralDropdownsOptions {
  projectTypesOptions: IDropdownResponse[];
  stdBuyUpOptions: IDropdownResponse[];
  accidentSicknessEliminationPeriods: IDropdownResponse[];
  stdCoverageTerminatesOn: IDropdownResponse[];
  stdCoverageClassPolicyTypes: IDropdownResponse[];

  basicADeDOptions: IDropdownResponse[];
  employeeRateBasisOptions: IDropdownResponse[];
  faceAmountOptions: IDropdownResponse[];
  incrementsOptions: IDropdownResponse[];
  maxFactorOptions: IDropdownResponse[];
  spouseRateBasisOptions: IDropdownResponse[];
  tobaccoRateBasisOptions: IDropdownResponse[];
  volADeDOptions: IDropdownResponse[];
  volLifeEmployeeRateBasisOptions: IDropdownResponse[];
  volLifeSpouseRateBasisOptions: IDropdownResponse[];
  volLifeTobaccoRateBasisOptions: IDropdownResponse[];
  accidentPlanTypeOptions: IDropdownResponse[];

  hospitalPlanTypeOptions: IDropdownResponse[];
  creditForTimeServedOptions: IDropdownResponse[];
  cancerPlanTypeOptions: IDropdownResponse[];
  criticalIllnessPlanTypeOptions: IDropdownResponse[];

  criticalIllnessMaxAmountOptions: IDropdownResponse[];
  criticalIllnessMaxFactorOptions: IDropdownResponse[];
  criticalIllnessIncrementsOptions: IDropdownResponse[];

  portableLifePlanTypeOptions: IDropdownResponse[];

  priorYearEnrollmentStyleOptions: IDropdownResponse[];
  defaultPeriodOptions: IDropdownResponse[];
  stateOptions: IDropdownResponse[];

  anticipatedSupportOptions: IDropdownResponse[];
  industryOptions: IDropdownResponse[];

  dataFileRecipientsOptions: IDropdownResponse[];

  typeOfEnrollmentOptions: IDropdownResponse[];
  enrollmentConditionsOptions: IDropdownResponse[];
  primaryEnrollmentMethodOptions: IDropdownResponse[];
  secondaryEnrollmentMethodOptions: IDropdownResponse[];
  engagementPercentageOptions: IDropdownResponse[];
}

export interface IFinancialOptions {
  standardPeriodOptions: IDropdownResponse[];
}

export interface IInsuranceCoverageOptions {
  defaultPlanOfferOptions: IDropdownResponse[];
}

export interface IMedicalInsuranceOptions {
  telemedicineOptions: IDropdownResponse[];
  medicalDeductibleTypeOptions: IDropdownResponse[];
  medicalPlanOfferingOptions: IDropdownResponse[];
  medicalCoverageTerminatesOnOptions: IDropdownResponse[];
}

export interface IBusinessClassOptions {
  waitingPeriods: IDropdownResponse[];
  classPayCycles: IDropdownResponse[];
  classEligibilityPeriods: IDropdownResponse[];
  classEligibilityRules: IDropdownResponse[];
}

export interface IDentalInsurance {
  dentalInsuranceId?: number;
  offerDentalCoverage: boolean;
  dentalCarrier: string;
  previousDentalCarrier: string;
  dentalPlanOffering: string;
  coverageTerminatesOn?: string;
  dentalNotes: string;
  dentalInsurancePlans: IDentalInsurancePlan[];
}

export interface IDentalInsurancePlan {
  dentalInsurancePlanId?: number;
  planName: string;
  pcpRequired: boolean;
  mostSimilarPreviousPlan: string;
}

export interface IVisionInsurance {
  visionInsuranceId?: number;
  offerVisionCoverage: boolean;
  visionCarrier: string;
  previousVisionCarrier: string;
  visionPlanOffering: string;
  coverageTerminatesOn: string;
  visionNotes: string;
  visionInsurancePlans: IVisionInsurancePlan[];
}

export interface IVisionInsurancePlan {
  visionInsurancePlanId?: number;
  planName: string;
  mostSimilarPreviousPlan: string;
}

export interface IMedicalInsurance {
  id?: number;
  offerMedicalInsurance: boolean;
  medicalCarrier: string;
  previousMedicalCarrier: string;
  medicalPlanOffering?: string;
  medicalDeductibleType?: string;
  telemedicine?: string;
  medicalCoverageTerminatesOn?: string;
  medicalNotes: string;
  medicalPlans: IMedicalInsurancePlan[];
}

export interface IMedicalInsurancePlan {
  id: number;
  planName: string;
  pcpRequired: boolean;
  mostSimilarPreviousPlan: string;
}

export interface IVoluntaryLifeCoverage {
  id?: number;
  offerVoluntaryLifeCoverage: boolean;
  voluntaryLifeCarrier: string;
  previousVoluntaryLifeCarrier: string;
  volADeD?: string;
  volLifeGiEligibility?: string;
  volLifeOffering?: string;
  volLifePortability?: string;
  volLifeEmployeeRateBasis?: string;
  volLifeSpouseRateBasis?: string;
  volLifeTobaccoRateBasis?: string;
  voluntaryLifeCoverageGuaranteedIssues: IVoluntaryLifeCoverageGuaranteedIssue[];
}

export interface IVoluntaryLifeCoverageGuaranteedIssue {
  id?: number;
  giAmount: string;
  maxAmount?: string;
  maxFactor?: string;
  increments?: string;
}


export interface IGroupLifeInsurance {
  id?: number;
  offerBasicLifeCoverage: boolean;
  basicLifeCarrier: string;
  previousBasicLifeCarrier: string;
  basicLifeOffering?: string;
  lifeNotes: string;
  groupLifeInsuranceClasses: IGroupLifeInsuranceClass[];
  groupLifeInsuranceGuaranteedIssues: IGroupLifeInsuranceGuaranteedIssue[];

  basicADeD?: string;
}

export interface IGroupLifeInsuranceClass {
  id?: number;
  className: string;
  notes: string;

  faceAmount: string;
  maxFactor: string;
}

export interface IGroupLifeInsuranceGuaranteedIssue {
  id?: number;
  giAmount?: string;

  maxAmount?: string;
  maxFactor?: string;
  increments?: string;
}

export interface ICriticalIllnessCoverageGuaranteedIssue {
  id?: number;
  giAmount?: string;

  maxAmount?: string;
  maxFactor?: string;
  increments?: string;
}

export interface IAccidentInsurance {
  id?: number;
  offerAccidentCoverage: boolean;
  accidentCarrier: string;
  previousAccidentCarrier: string;
  highlights: string;
  accidentTaxStatus: boolean;
  accidentNotes: string;

  accidentOffering: string;
  accidentPlanType: string;
  accidentPortability: string;
}

export interface IHospitalCoverage {
  id?: number;
  offerHospitalCoverage: boolean;
  hospitalCarrier: string;
  previousHospitalCarrier: string;
  hospitalTaxStatus: boolean;
  hospitalNotes: string;

  hospitalOffering: string;
  hospitalGIEligibility: string;
  hospitalPlanType: string;
  hospitalPreExRules: string;
  hospitalPortability: string;
  hospitalCreditForTimeServed: string;

}

export interface ICancerInsurance {
  id?: number;
  offerCancerCoverage: boolean;
  cancerCarrier: string;
  previousCancerCarrier: string;
  cancerTaxStatus: boolean;
  cancerNotes: string;

  cancerOffering: string;
  cancerPlanType: string;
  cancerPreExRules: string;
  cancerPortability: string;
  cancerCreditForTimeServed: string;
}

export interface ICriticalIllnessInsurance {
  id?: number;
  offerCriticalIllnessCoverage: boolean;
  criticalIllnessCarrier: string;
  previousCriticalIllnessCarrier: string;
  ciTaxStatus: boolean;
  criticalIllnessNotes: string;

  criticalIllnessOffering: string;
  criticalIllnessGIEligibility: string;
  criticalIllnessPlan: string;
  ciPreExRules: string;
  ciPortability: string;
  ciCreditForTimeServed: string;

  ciEmployeeRateBasis: string;
  ciSpouseRateBasis: string;
  ciTobaccoRateBasis: string;

  criticalIllnessInsuranceGuaranteedIssues: ICriticalIllnessCoverageGuaranteedIssue[]
}

export interface IPortableLifeInsurance {
  id?: number;
  offerPortableLifeInsuranceCoverage: boolean;
  portableLifeCarrier: string;
  previousPortableLifeCarrier: string;
  portableLifeTaxStatus: boolean;
  portableLifeNotes: string;

  portableLifeGIEligibility: string;
  portableLifePortability: string;

  portableLifeOffering: string;
  portableLifePlanType: string;
  portableLifePreExRules: string;
  portableLifeCreditForTimeServed: string;
  portableLifeEmployeeRateBasedOn: string;
  portableLifeTobaccoRate: string;
  portableLifeSpouseRateBasis: string;

  portableLifeInsuranceGuaranteedIssues: IPortableLifeInsuranceGuaranteedIssue[]
}

export interface IPortableLifeInsuranceGuaranteedIssue {
  id?: number;
  giAmount?: string;

  maxAmount?: string;
  maxFactor?: string;
}

export interface IShortTermDisabilityCoverage {
  shortTermDisabilityCoverageId?: number;
  offerShortTermDisabilityCoverage: boolean;
  stdCarrier: string;
  previousSTDCarrier: string;
  areMultiplePlansAvailable: boolean;
  stdOffering: string;

  stdBuyUpOptions: string;
  stdPortability: string;
  stdPolicyType: string;
  accidentSicknessEliminationPeriods: string;
  coverageTerminatesOn: string;


  eligibleClasses: string;
  stdPreExRules: string;
  stdMaxWeeklyBenefit: string;
  stdTaxStatus: string;
  preExExceptionForPregnancy: boolean;
  stdNotes: string;

  stdCreditForTimeServed: string;
  stdgiEligibility: string;
  stdPaymentType: string;

  shortTermDisabilityCoverageClasses: IShortTermDisabilityCoverageClass[];
}

export interface IShortTermDisabilityCoverageClass {
  id?: number;
  shortTermDisabilityCoverageId?: number;
  className: string;
  maxWeeklyBenefit: string;

  policyType: string;
  preExRules: string;
  giEligibility: string;
  paymentType: string;
  eliminationPeriods: string;

}

export interface ILongTermDisabilityCoverage {
  longTermDisabilityCoverageId?: number;
  offerLongTermDisabilityCoverage: boolean;
  ltdCarrier: string;
  previousLTDCarrier: string;
  areMultiplePlansAvailable: boolean;
  ltdOffering: string;
  eligibleClasses: string;
  ltdMaxMonthlyBenefit: string;
  ltdNotes: string;
  maxMonthlyBenefit: string;

  ltdPreExRules: string;
  eliminationAccumulationPeriods: string;

  ltdPolicyType: string;
  ltdPortability: string;
  ltdgiEligibility: string;
  ltdBuyUpOptions: string;
  ltdCreditForTimeServed: string;
  ltdPaymentType: string;

  ltdTaxStatus: string;
  coverageTerminatesOn: string;

  longTermDisabilityCoverageClasses: ILongTermDisabilityCoverageClass[];
}

export interface ILongTermDisabilityCoverageClass {
  id?: number;
  className: string;

  policyType: string;
  giEligibility: string;
  paymentType: string;

  eliminationAccumulationPeriods: string;
}

//start
export interface IHealthReimbursementAccount {
  offerHealthReimbursementAccount: boolean;
  erContribution?: string;
  contingentPlans?: string;
  coverageTerminatesOn?: string;
  highlights?: string;
}

export interface IFlexibleSpendingAccount {
  offerFlexibleSpendingAccount: boolean;
  erContribution: string;
  employeeMaxAnnualAccount?: string;
  coverageTerminatesOn: string;
}

export interface IDependentCareFlexSpendingAccount {
  offerDependentCareFlexSpendingAccount: boolean;
  erContribution: string;
  employeeMaxAnnualContribution?: string;
  familyMaxAnnualContribution?: string;
  coverageTerminatesOn: string;
}

export interface ILimitedPurposeFlexSpendingAccount {
  offerLimitedPurposeFlexSpendingAccount: boolean;
  erContribution: string;
  employeeMaxAnnualContribution?: string;
  coverageTerminatesOn: string;

}
//end 

export interface IBusinessClient {
  businessClientId: number;
  name: string;
  broker: IBroker;
}

export interface IBuildStep {
  businessClientBuildStepId: number;
  buildStep: string;
  url: string;
  buildStepStatus: string;
  icon?: any;
}

export interface ISpecialAttentionBusinessClientEmployee {
  businessClientSpecialAttentionEmployeeId?: number;
  name: string | null;
  title: string | null;
}

export interface IDataFileRecipient {
  businessClientDataFileRecipientId?: number;
  recipient: string;
  otherRecipientDescription: string | null;
}

export interface IHealthSavingsAccount {
  offerHealthSavingsAccount: boolean;
  erContributionsDistribution: string;
  employeeMaxAnnualContribution?: string;
  familyMaxAnnualContribution?: string;
  bankName: string;
  contingentPlans: string;
  coverageTermination: string;
  setupRequirements: string;
  erContribution: string;
}

export interface ISupplementalProduct {
  id?: number;
  offerSupplementalProducts: boolean;
  carrierName: string;
  notes: string;
}

export interface IEligibilityRules {
  id?: number;
  arePartTimeEmployeesEligibleToEnroll: boolean;
  partTimeEmployeesEligibility: boolean;
  areDomesticPartnersEligibleToEnroll: boolean;
  domesticPartnerPlanEligibility: boolean;
  eligibilityRulesDeductions: IEligibilityRulesDeduction[];
}

export interface IEligibilityRulesDeduction {
  id?: number;
  deductionFrequency: string;
  hoursRequiredForEligibility: string;
}

export interface IBusinessClientDetailFormValues {
  name: string;
  brokerName: string;

  shouldCompanyNameUsedInCommunication: boolean;
  companyNameUsedInCommunication: string;

  eligibilityRules?: IEligibilityRules;

  caseOverviewUrl: string;
  benefitProgramSupportDocumentationUrl: string;
  importantCaseNotes: string;
  logoUrl: string;
  appLogoUrl: string;
  systemOfRecord: string;
  systemOfRecordUrl: string;
  systemOfRecordUsername: string;
  systemOfRecordPassword: string;
  openEnrollmentEffectiveDate: string;
  openEnrollmentStartDate: string | null;
  dashboardOpenEnrollmentView: number;
  selfServiceStartDate: string;
  selfServiceEndDate: string;
  enrollmentDeadline: string;
  newHireScheduleUrl: string;
  classes: IBusinessClientClass[];
  location: IBusinessClientLocation[];
  contacts?: IBusinessClientContact[];
  supplementalProducts?: ISupplementalProduct[];

  mobileAppEnabled: boolean;
  appLogoType: string;
  homeLogoType: string;
  hexColor: string;
  secondaryHexColor: string;
  textHexColor: string;
  header: string;
  links: any[];
  hrManagerFirstName: string;
  hrManagerLastName: string;
  hrManagerEmail: string | null;
  hrManagerPhoneNumber: string | null;
  automatedEmployeeCommunication: boolean;
  qrCodes: QRCode[];
  // enrollment builder
  projectType: string | null;
  enrollmentStart: string | null;
  enrollmentEnd: string | null;
  targetDate: string | null;
  buildSteps: IBuildStep[];
  // enrollment overview
  // -- client details
  situsState: string;
  industry: string;
  industryTier?: string;
  benAdminBuildType?: string;
  engagementPercentage?: string;
  anticipatedSupport?: string;


  healthSavingsAccount: IHealthSavingsAccount;
  healthReimbursementAccount: IHealthReimbursementAccount;
  flexibleSpendingAccount: IFlexibleSpendingAccount;
  dependentCareFlexSpendingAccount: IDependentCareFlexSpendingAccount;
  limitedPurposeFlexSpendingAccount: ILimitedPurposeFlexSpendingAccount;
  financialProductNotes: string;

  enrollmentEngagementNumbers: IEnrollmentEngagementNumbers;

  productParticipation: IProductParticipation;
  challengesAndSolutions: IChallengesAndSolutions;

  medicalInsurance: IMedicalInsurance;

  dentalInsurance: IDentalInsurance;
  visionInsurance: IVisionInsurance;
  shortTermDisabilityCoverage: IShortTermDisabilityCoverage;
  longTermDisabilityCoverage: ILongTermDisabilityCoverage;
  groupLifeInsurance: IGroupLifeInsurance;
  voluntaryLifeCoverage: IVoluntaryLifeCoverage;

  accidentInsurance: IAccidentInsurance;
  hospitalCoverage: IHospitalCoverage;
  cancerInsurance: ICancerInsurance;
  criticalIllnessInsurance: ICriticalIllnessInsurance;
  portableLifeInsurance: IPortableLifeInsurance;

  sicCode: string;
  street: string;
  streetLine2: string;
  city: string;
  state: string;
  postalCode: string | null;
  taxId: string | number | null;
  totalEligibleEmployees: string | number;
  totalEmployees: string | number;
  engagementPackageType: string;
  carrierRepGoals: string;
  // -- client preferences
  highlightsChallenges: string | null;
  specialAttentionBusinessClientEmployees: ISpecialAttentionBusinessClientEmployee[];
  hrDeadline: string | null;
  provideDataFile: boolean;
  dataDeliveryDate: string | null;
  dataFileRecipients: IDataFileRecipient[];
  accountManagerUserId: string | number | null;

  //pretty new
  previousYearEnrollmentDetails: string | null;
  isEnrollmentAllianceProvidingBenAdminSystem: boolean;

  projectedNumberOfBenefitMeetingsRequired: string | null;

  estimatedEngagementRate: string | null;

  proposedDatesAndTimes: string | null;
  timeZone: string | null;
  enrollmentMethodAndSchedulingNotes: string | null;

  businessClientLocationAndLogistic: IBusinessClientLocationAndLogistic;
  faceToFaceEnrollment: IFaceToFaceEnrollmentDetails;

  businessClientFollowUpMessages: IBusinessClientFollowUpMessages;

  typeOfEnrollment?: string;
  enrollmentConditions?: string;
  primaryEnrollmentMethod?: string;
  secondaryEnrollmentMethod?: string;

  enrollmentSchedulingTools: IEnrollmentSchedulingTool[];

  sectionsDocuments: ISectionDocuments[];
}

export interface IEnrollmentSchedulingTool {
  schedulingTool?: string;
  schedulingToolDescription?: string;
  otherToolDescription?: string;
  isSelected?: boolean;
}

export interface IMinimalAddBusinessClient {
  name: string;
  projectType: string | null;
  openEnrollmentEffectiveDate: string | null;
}

export interface IBusinessClientDetail {
  businessClientId: number;
  name: string;
  brokerId: number;

  shouldCompanyNameUsedInCommunication: boolean;
  companyNameUsedInCommunication: string;

  eligibilityRules?: IEligibilityRules;

  importantCaseNotes: string;
  caseOverviewUrl: string;
  dashboardOpenEnrollmentView: number;
  newHireScheduleUrl: string;
  benefitProgramSupportDocumentationUrl: string;
  systemOfRecordId: 0;
  systemOfRecordUrl: string;
  systemOfRecordUsername: string;
  systemOfRecordPassword: string;
  logoUrl: string;
  appLogoUrl: string;
  openEnrollmentEffectiveDate: string;
  openEnrollmentStartDate: string;
  selfServiceEndDate: string;
  selfServiceStartDate: string;
  enrollmentDeadline: string;
  broker: IBroker;
  systemOfRecord: ISystemOfRecord;
  classes: IBusinessClientClass[];
  location: IBusinessClientLocation[];
  contacts: IBusinessClientContact[];
  supplementalProducts?: ISupplementalProduct[];
  mobileAppEnabled: boolean;
  appLogoType: string;
  homeLogoType: string;
  hexColor: string;
  secondaryHexColor: string;
  textHexColor: string;
  header: string;
  links: IMobileAppLink[];
  qrCodes: QRCode[];
  hrManagerFirstName: string;
  hrManagerLastName: string;
  hrManagerEmail: string;
  hrManagerPhoneNumber: string;
  automatedEmployeeCommunication: boolean;
  projectType: string | null;
  enrollmentStart: string;
  enrollmentEnd: string;
  targetDate: string;
  buildSteps: IBuildStep[];
  situsState: string;
  industry: string;
  industryTier?: string;
  benAdminBuildType?: string;
  engagementPercentage?: string;
  anticipatedSupport?: string;

  healthSavingsAccount: IHealthSavingsAccount;
  healthReimbursementAccount: IHealthReimbursementAccount;
  flexibleSpendingAccount: IFlexibleSpendingAccount;
  dependentCareFlexSpendingAccount: IDependentCareFlexSpendingAccount;
  limitedPurposeFlexSpendingAccount: ILimitedPurposeFlexSpendingAccount;
  financialProductNotes: string;

  enrollmentEngagementNumbers: IEnrollmentEngagementNumbers;

  productParticipation: IProductParticipation;
  challengesAndSolutions: IChallengesAndSolutions;

  medicalInsurance: IMedicalInsurance;

  dentalInsurance: IDentalInsurance;
  visionInsurance: IVisionInsurance;
  shortTermDisabilityCoverage: IShortTermDisabilityCoverage;
  longTermDisabilityCoverage: ILongTermDisabilityCoverage;
  groupLifeInsurance: IGroupLifeInsurance;
  voluntaryLifeCoverage: IVoluntaryLifeCoverage;

  accidentInsurance: IAccidentInsurance;
  hospitalCoverage: IHospitalCoverage;
  cancerInsurance: ICancerInsurance;
  criticalIllnessInsurance: ICriticalIllnessInsurance;
  portableLifeInsurance: IPortableLifeInsurance;

  sicCode: string;
  street: string;
  streetLine2: string;
  city: string;
  state: string;
  postalCode: string | null;
  taxId: string;
  totalEligibleEmployees: number;
  totalEmployees: string | number;
  engagementPackageType: string;
  carrierRepGoals: string;
  highlightsChallenges: string | null;
  specialAttentionBusinessClientEmployees: ISpecialAttentionBusinessClientEmployee[];
  hrDeadline: string | null;
  provideDataFile: boolean;
  dataDeliveryDate: string | null;
  dataFileRecipients: IDataFileRecipient[];
  accountManagerUserId: string | number;

  previousYearEnrollmentDetails: string | null;
  isEnrollmentAllianceProvidingBenAdminSystem: boolean;

  projectedNumberOfBenefitMeetingsRequired: string | null;

  estimatedEngagementRate: string | null;

  proposedDatesAndTimes: string | null;
  timeZone: string | null;
  enrollmentMethodAndSchedulingNotes: string | null;

  businessClientLocationAndLogistic: IBusinessClientLocationAndLogistic;
  faceToFaceEnrollment: IFaceToFaceEnrollmentDetails;

  businessClientFollowUpMessages: IBusinessClientFollowUpMessages;

  typeOfEnrollment?: string;
  enrollmentConditions?: string;
  primaryEnrollmentMethod?: string;
  secondaryEnrollmentMethod?: string;

  enrollmentSchedulingTools: IEnrollmentSchedulingTool[];

  sectionsDocuments: ISectionDocuments[];
}

export interface QRCode {
  name: string;
  link: string;
  url: string;
  businessClientClassId: number;
  businessClientLocationId: number;
}

export interface IBusinessClientLocationAndLogistic {
  id?: number | null;
  isThereNeedForOtherLanguages: boolean;
  isThereNeedForSpanish: boolean;
  areAnyOtherLanguagesNeeded: boolean;
  additionalLanguages: string;
}

export interface IFaceToFaceEnrollmentLocation {
  id?: number | null;
  locationName: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
}

export interface IFaceToFaceEnrollmentDetails {
  id?: number | null;
  multipleLocationsRequired: boolean;
  notesAboutEnrollmentLogistics: string;
  locations: IFaceToFaceEnrollmentLocation[];
}

export interface IBusinessClientFollowUpMessages {
  id?: number | null;
  targetAudience: string | null;
  messages: IBusinessClientFollowUpMessageItem[];
}

export interface IBusinessClientFollowUpMessageItem {
  id?: number | null;
  messageDeliveryDate: string | null;
  textMessage: string | null;
  emailSubjectLine: string | null;
  emailMessage: string | null;
}


export interface IBusinessClientLocation {
  businessClientLocationId?: number;
  name: string;
  calendarLinkUrl: string | null;
  supervisorName?: string | null;
}

export interface IBusinessClientRes {
  records: IBusinessClient[];
  totalRecordCount: number;
}

export interface IBusinessClientClass {
  businessClientClassId?: number;
  name: string;
  benefitGuideUrl: string | null;
  benefitGuideMultilingualUrl: string | null;
  videoUrl: string | null;
  videoMultilingualUrl: string | null;
  waitingPeriod: string;

  classPayCycle: string;
  classEligibilityPeriod: string;
  classEligibilityRule: string;
}

export interface IBusinessClientContact {
  businessClientContactId?: number;
  businessClientId?: number;
  name: string;
  email: string;
  hasBenAdminAccess: boolean;
  hasDashboardAccess: boolean;
  role?: string | null;
}

export interface IBusinessClientPost {
  name: string;
  brokerId: number;
  caseOverviewUrl?: string;
  newHireScheduleUrl?: string;
  benefitProgramSupportDocumentationUrl?: string;
  systemOfRecordId?: number;
  systemOfRecordUrl?: string;
  systemOfRecordUsername?: string;
  systemOfRecordPassword?: string;
  logoUrl?: string;
  openEnrollmentEffectiveDate?: string;
  openEnrollmentStartDate?: string;
  enrollmentDeadline?: string;
  classes?: IBusinessClientClass[];
  locations?: IBusinessClientLocation[];
  contacts?: IBusinessClientContact[];
  supplementalProducts?: ISupplementalProduct[];
  mobileAppEnabled: boolean;
  appLogoType: string;
  homeLogoType: string;
  hexColor: string;
  links: IMobileAppLink[];
  hrManagerFirstName?: string;
  hrManagerLastName?: string;
  hrManagerEmail?: string;
  hrManagerPhoneNumber?: string;
  automatedEmployeeCommunication?: boolean;
  classLocations: {
    businessClientClassId: number;
    businessClientLocationId: number;
    url: string;
  }[];
  situsState: string;
  industry: string;
  industryTier?: string;
  benAdminBuildType?: string;
  engagementPercentage?: string;
  anticipatedSupport?: string;


  healthSavingsAccount: IHealthSavingsAccount;
  healthReimbursementAccount: IHealthReimbursementAccount;
  flexibleSpendingAccount: IFlexibleSpendingAccount;
  dependentCareFlexSpendingAccount: IDependentCareFlexSpendingAccount;
  limitedPurposeFlexSpendingAccount: ILimitedPurposeFlexSpendingAccount;
  financialProductNotes: string;

  medicalInsurance: IMedicalInsurance;

  dentalInsurance: IDentalInsurance;
  visionInsurance: IVisionInsurance;
  shortTermDisabilityCoverage: IShortTermDisabilityCoverage;
  longTermDisabilityCoverage: ILongTermDisabilityCoverage;
  groupLifeInsurance: IGroupLifeInsurance;
  voluntaryLifeCoverage: IVoluntaryLifeCoverage;

  accidentInsurance: IAccidentInsurance;
  hospitalCoverage: IHospitalCoverage;
  cancerInsurance: ICancerInsurance;
  criticalIllnessInsurance: ICriticalIllnessInsurance;
  portableLifeInsurance: IPortableLifeInsurance;

  medicalDeductibleType?: string;
  telemedicine?: string;
  medicalNotes?: string;

  sicCode: string;
  street: string;
  streetLine2: string;
  city: string;
  state: string;
  postalCode: string;
  taxId: string;
  totalEligibleEmployees: number;
  totalEmployees: string | number;
  engagementPackageType: string;
  carrierRepGoals: string;
  highlightsChallenges: string | null;
  specialAttentionBusinessClientEmployees?: ISpecialAttentionBusinessClientEmployee[];
  hrDeadline: string | null;
  provideDataFile: boolean;
  dataDeliveryDate: string | null;
  dataFileRecipients: IDataFileRecipient[];
  accountManagerUserId: string | number;

  previousYearEnrollmentDetails: string | null;
  isEnrollmentAllianceProvidingBenAdminSystem: boolean;

  projectedNumberOfBenefitMeetingsRequired: string | null;

  estimatedEngagementRate: string | null;

  proposedDatesAndTimes: string | null;
  timeZone: string | null;
  enrollmentMethodAndSchedulingNotes: string | null;

  businessClientLocationAndLogistic: IBusinessClientLocationAndLogistic;
  faceToFaceEnrollment: IFaceToFaceEnrollmentDetails;

  businessClientFollowUpMessages: IBusinessClientFollowUpMessages;

  typeOfEnrollment?: string;
  enrollmentConditions?: string;
  primaryEnrollmentMethod?: string;
  secondaryEnrollmentMethod?: string;

  enrollmentSchedulingTools: IEnrollmentSchedulingTool[];

  sectionsDocuments: ISectionDocuments[];
}

export interface IBusinessClientPut {
  name: string;
}

export interface IBusinessClientDashboardEmployeeCount {
  status: string;
  count: number;
}

export interface IBusinessClientDashboard {
  businessClientId: number;
  name: string;
  logoUrl: string;
  isOpenEnrollment: boolean;
  employeeStatusCounts: IBusinessClientDashboardEmployeeCount[];
}

export interface IImageUploadRes {
  url: string;
}

export type mobileAppLogoTypes = 'Broker' | 'BusinessClient' | 'Custom' | 'Default';

export type mobileAppLogoTypeTitles = 'Broker Logo' | 'Business Client Logo' | 'Enrollment Alliance' | 'Custom App Icon Logo';

export enum EMobileAppLogoTypeEnums {
  BROKER = 'Broker',
  BUSINESS_CLIENT = 'BusinessClient',
  DEFAULT = 'Default'
}

export interface IMobileAppLogoType {
  type: mobileAppLogoTypes;
  title: mobileAppLogoTypeTitles;
}

export const mobileAppLogoTypeOptions: IMobileAppLogoType[] = [
  { type: 'Broker', title: 'Broker Logo' },
  { type: 'BusinessClient', title: 'Business Client Logo' },
  { type: 'Custom', title: 'Custom App Icon Logo' },
  { type: 'Default', title: 'Enrollment Alliance' }
];

export type mobileAppLinkTypes =
  | 'Instructions'
  | 'Calendar'
  | 'TalkToAdvisor'
  | 'BenefitsInfo'
  | 'BenefitsInfoMultilingual'
  | 'Media'
  | 'MediaMultilingual'
  | 'Chat'
  | 'FindADoctor'
  | 'EnrollInBenefits'
  | 'EnrollInBenefitsMultilingual'
  | 'VirtualDoctorVisit'
  | 'PrescriptionDiscounts'
  | 'WellnessIncentives'
  | 'MentalHealth'
  | 'SpeakToConcierge'
  | 'Retirement'
  | 'Payroll'
  | 'ScreenShare'
  | 'Webinar'
  | 'WebinarMultilingual'
  | 'Legal'
  | 'IdentityProtection'
  | 'PetInsurance'
  | 'AutoInsurance'
  | 'BoatInsurance'
  | 'Forms'
  | 'HomeInsurance'
  | 'LifeInsurance';

export enum EMobileAppLinkTypeEnums {
  INSTRUCTIONS = 'Instructions',
  CALENDAR = 'Calendar',
  TALK_TO_ADVISOR = 'TalkToAdvisor',
  BENEFITS_INFO = 'BenefitsInfo',
  BENEFITS_INFO_MULTILINGUAL = 'BenefitsInfoMultilingual',
  MEDIA = 'Media',
  MEDIA_MULTILINGUAL = 'MediaMultilingual',
  CHAT = 'Chat',
  FIND_A_DOCTOR = 'FindADoctor',
  ENROLL_In_BENEFITS = 'EnrollInBenefits',
  ENROLL_In_BENEFITS_MULTILINGUAL = 'EnrollInBenefitsMultilingual',
  VIRTUAL_DOCTOR_VISIT = 'VirtualDoctorVisit',
  PRESCRIPTION_DISCOUNTS = 'PrescriptionDiscounts',
  WELLNESS_INCENTIVES = 'WellnessIncentives',
  MENTAL_HEALTH = 'MentalHealth',
  SPEAK_TO_CONCIERGE = 'SpeakToConcierge',
  RETIREMENT = 'Retirement',
  PAYROLL = 'Payroll',
  SCREEN_SHARE = 'ScreenShare',
  WEBINAR = 'Webinar',
  WEBINAR_MULTILINGUAL = 'WebinarMultilingual',
  LEGAL = 'Legal',
  IDENTITY_PROTECTION = 'IdentityProtection',
  PET_INSURANCE = 'PetInsurance',
  AUTO_INSURANCE = 'AutoInsurance',
  BOAT_INSURANCE = 'BoatInsurance',
  FORMS = 'Forms',
  HOME_INSURANCE = 'HomeInsurance',
  LIFE_INSURANCE = 'LifeInsurance'
}

export interface IMobileAppLink {
  businessClientMobileAppLinkId?: number;
  enabled: boolean;
  link: string;
  type: mobileAppLinkTypes;
  name: string | null;
}

export const instructionsForDownloadAppLinkOptions: IMobileAppLink[] = [
  { enabled: false, link: 'https://enrollmentalliance.com/', type: EMobileAppLinkTypeEnums.INSTRUCTIONS, name: 'Add app to Home Screen' }
];

export const sharedAppLinkOptions: IMobileAppLink[] = [
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.AUTO_INSURANCE, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.BOAT_INSURANCE, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.FORMS, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.HOME_INSURANCE, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.LIFE_INSURANCE, name: '' },
];

// this was created to possible handle some clients that saved their link options, but were missing FIND_A_DOCTOR
export const multilingualAppLinkOptions: IMobileAppLink[] = [
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.BENEFITS_INFO_MULTILINGUAL, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.MEDIA_MULTILINGUAL, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.WEBINAR_MULTILINGUAL, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.ENROLL_In_BENEFITS_MULTILINGUAL, name: '' }
];
export const missingMobileAppLinkOptions: IMobileAppLink[] = [
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.RETIREMENT, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.PAYROLL, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.SCREEN_SHARE, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.WEBINAR, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.LEGAL, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.IDENTITY_PROTECTION, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.PET_INSURANCE, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.FIND_A_DOCTOR, name: '' },
  ...multilingualAppLinkOptions,
  ...instructionsForDownloadAppLinkOptions
];

export const extraMobileAppLinkOptions: IMobileAppLink[] = [
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.RETIREMENT, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.PAYROLL, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.SCREEN_SHARE, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.WEBINAR, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.LEGAL, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.IDENTITY_PROTECTION, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.PET_INSURANCE, name: '' },
  ...multilingualAppLinkOptions,
  ...instructionsForDownloadAppLinkOptions
];

export const newMobileAppLinkOptions: IMobileAppLink[] = [
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.ENROLL_In_BENEFITS, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.VIRTUAL_DOCTOR_VISIT, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.PRESCRIPTION_DISCOUNTS, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.WELLNESS_INCENTIVES, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.MENTAL_HEALTH, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.SPEAK_TO_CONCIERGE, name: '' },
  ...extraMobileAppLinkOptions,
  ...instructionsForDownloadAppLinkOptions
];

export const mobileAppLinkOptions: IMobileAppLink[] = [
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.CALENDAR, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.BENEFITS_INFO, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.TALK_TO_ADVISOR, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.MEDIA, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.CHAT, name: '' },
  { enabled: false, link: '', type: EMobileAppLinkTypeEnums.FIND_A_DOCTOR, name: '' },
  ...newMobileAppLinkOptions,
  ...instructionsForDownloadAppLinkOptions
];


// Had to add incase doubled links got through
const makeLinksDistinct = (array, property = 'type') => array.filter((value, index, self) => index === self.findIndex(t => t[property] === value[property]));

export const getDefaultLinks = (currentLinks: IMobileAppLink[]) => {
  // // Add instructions no matter what
  // currentLinks = makeLinksDistinct([...currentLinks, ...instructionsForDownloadAppLinkOptions]);
  // this check is for possible some clients missing one of the options (11) and saved their selections with one less option
  if (currentLinks.length === 11) {
    return makeLinksDistinct([...currentLinks, ...missingMobileAppLinkOptions, ...sharedAppLinkOptions]);
  }
  // this check is for when a client has all of the latest options (12) except the most recent icons
  if (currentLinks.length === 12) {
    return makeLinksDistinct([...currentLinks, ...extraMobileAppLinkOptions, ...sharedAppLinkOptions]);
  }
  // this check is for when a client has all of the latest options (6) except the most recent icons
  if (currentLinks.length === 6) {
    return makeLinksDistinct([...currentLinks, ...newMobileAppLinkOptions, ...sharedAppLinkOptions]);
  }
  // use the client options if they have already saved all of the latest options to the API
  if (currentLinks.length > 12) {
    return makeLinksDistinct([...currentLinks, ...instructionsForDownloadAppLinkOptions, ...sharedAppLinkOptions]);
  }

  // return all of the static default options for brand new clients
  // Adding Multilingual options and making them distinct
  return makeLinksDistinct([...mobileAppLinkOptions, ...sharedAppLinkOptions]);
};

export interface ITargetDate {
  targetDate: string;
  projectType: string;
  censusDate: string;
  essentialDocsDate: string;
  productOverviewDate: string;
  enrollmentOverviewDate: string;
  hrOverviewDate: string;
  communicationOverviewDate: string;
}

export interface IEnrollmentBuilderDashboard {
  businessClientId: number;
  name: string | null;
  enrollmentStart: string | null;
  enrollmentEnd: string | null;
  targetDates: ITargetDate;
  buildSteps: IBuildStep[];
}
