import { axios, setUserTokenCookies, setUserLocalStorage } from '../helpers';
import { ILoginPost, ILoginUser, IResetPasswordPost, IError } from '../models';

/**
 * auths a user on login
 * @param data ILoginPost
 * @returns user or error
 */
export const login = async (data: ILoginPost): Promise<ILoginUser | IError> => {
  try {
    const res = await axios.post('Login', data);

    if (res && res.data && res.data.accessToken) {
      const resMe = await axios.get('Me', {
        headers: {
          Authorization: `Bearer ${res.data.accessToken}`
        }
      });
      setUserTokenCookies(res.data.accessToken, res.data.accessTokenExpiresAt);
      setUserLocalStorage({
        ...res.data,
        ...resMe.data
      });
      return {
        ...res.data,
        ...resMe.data
      };
    }
    const error = { Detail: 'Error logging in. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};

/**
 * sets up a users password
 * @param data ILoginPost
 * @returns user or error
 */
export const setupPassword = async (data: IResetPasswordPost): Promise<ILoginUser | IError> => {
  try {
    const res = await axios.post('ResetPassword', data);

    if (res && res.data && res.data.accessToken) {
      const resMe = await axios.get('Me', {
        headers: {
          Authorization: `Bearer ${res.data.accessToken}`
        }
      });
      setUserTokenCookies(res.data.accessToken, res.data.accessTokenExpiresAt);
      setUserLocalStorage({
        ...res.data,
        ...resMe.data
      });

      return {
        ...res.data,
        ...resMe.data
      };
    }
    const error = { Detail: 'Error logging in. Please try again.', status_code: 500 };
    return Promise.reject(error);
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};
