import { BusinessClientMenusTypes, SingleTabOptionType, TabOptionType } from '../../../MenuData/types';
import { menusSubMenusValidationStatusMap } from '../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../models';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Theme, Tabs, Tab } from '@mui/material';

interface ITabsBaseProps<T> {
    tabOption: SingleTabOptionType<T>
}

export const SingleTabsBase = <T extends BusinessClientMenusTypes>({ tabOption }: ITabsBaseProps<T>) => {

    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width: 960px)');

    const { errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const validateAction = menusSubMenusValidationStatusMap.get(tabOption.menuRelatedId);
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;


    return (<>
        {isMobile ? (
            <></>
            // <MobileTabs validateForm={validateForm} tabOptions={tabOptions} />
        ) : (
            <Tabs
                value={0}
                indicatorColor='primary'
                textColor='primary'
            >

                <Tab
                    id={`tab-id-0`}
                    classes={{
                        root: classes.tabRoot,
                        textColorPrimary: clsx(
                            classes.tabText,
                            IsThereSomethingInvalidInTheTab
                                ? classes.tabErrors
                                : ''
                        ),
                        selected: classes.tabSelected
                    }}
                    key={`0`}
                    aria-controls={`tab-content-0`}
                    label={<div style={{ 'display': 'flex', 'alignItems': 'center' }}>
                        <p>{tabOption.label}</p>
                        {tabOption.secondaryText && <small style={{ 'fontSize': '7pt', 'marginLeft': '5px' }}>{tabOption.secondaryText}</small>}
                    </div>}
                />


            </Tabs>)}</>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    tabRoot: {
        minWidth: 0,
        fontSize: 18,
        padding: `15px 20px`,
        textTransform: 'none'
    },
    tabText: {
        color: theme.palette.grey[400]
    },
    tabSelected: {
        fontWeight: 'bold'
    },
    tabErrors: {
        color: theme.palette.error.main
    }
}));
