import React, {useEffect} from 'react';
import { Grid, TextField } from '@mui/material';
import { IEnrollmentEngagementNumbers } from '../../../../../../../../models';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import GenericDropdown from '../../../../../../../../components/generic-dropdown/GenericDropdown';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import { useSharedStyles } from '../../../../../shared/styles';

const EnrollmentEngagementNumbers = () => {


    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: IEnrollmentEngagementNumbers = values.enrollmentEngagementNumbers;

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    const classes = useSharedStyles();

    
useEffect(() => {
    console.log('data', data)
  }, [data])
  

    const validateAction = menusSubMenusValidationStatusMap.get('enrollment_engagement_numbers');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <GenericDropdown
                        handleBlur={handleBlur}
                        options={generalDropdownsOptions?.priorYearEnrollmentStyleOptions ?? []}
                        isLoading={generalDropdownsOptionsLoading}
                        setFieldValue={setFieldValue}
                        value={data?.priorYearEnrollmentStyle ?? ''}
                        error={errors.enrollmentEngagementNumbers?.priorYearEnrollmentStyle}
                        label='Prior Year Enrollment Style'
                        id={`enrollmentEngagementNumbers.priorYearEnrollmentStyle`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Prior Year Engagement"
                        name="enrollmentEngagementNumbers.priorYearEngagement"
                        value={data?.priorYearEngagement}
                        onChange={(e) => setFieldValue('enrollmentEngagementNumbers.priorYearEngagement', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Employee Engagement"
                        name="enrollmentEngagementNumbers.employeeEngagement"
                        value={data?.employeeEngagement}
                        onChange={(e) => setFieldValue('enrollmentEngagementNumbers.employeeEngagement', e.target.value)}
                    />
                </Grid>
                
            </Grid>
        </div>
    );
};

export default EnrollmentEngagementNumbers;
