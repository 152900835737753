import React from 'react';
import { Box, Grid, TextField, FormLabel as MuiLabel, FormControlLabel, Switch, Checkbox } from '@mui/material';
import { useSharedStyles } from '../../../../../../shared/styles';
import { IHospitalCoverage } from '../../../../../../../../../models'; // Use the correct model interface
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import useDefaultDropdownsOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const HospitalCoverage: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: IHospitalCoverage = values.hospitalCoverage;

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';


    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';


    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Hospital Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerHospitalCoverage}
                                    id='hospitalCoverage.offerHospitalCoverage'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('hospitalCoverage.offerHospitalCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerHospitalCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                    <SectionsFilesList category='HospitalCoverage' />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Hospital Carrier'
                    name='hospitalCoverage.hospitalCarrier'
                    value={data?.hospitalCarrier}
                    onChange={(e) => {
                        setFieldValue('hospitalCoverage.hospitalCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.hospitalCoverage?.hospitalCarrier} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Previous Hospital Carrier'
                    name='hospitalCoverage.previousHospitalCarrier'
                    value={data?.previousHospitalCarrier}
                    onChange={(e) => {
                        setFieldValue('hospitalCoverage.previousHospitalCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.hospitalCoverage?.previousHospitalCarrier} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControlLabel
                    value={data?.hospitalTaxStatus}
                    control={
                        <Checkbox
                            color='primary'
                            checked={data?.hospitalTaxStatus}
                            name='hospitalCoverage.hospitalTaxStatus'
                            onChange={(_, checked: boolean) => setFieldValue('hospitalCoverage.hospitalTaxStatus', checked)}
                        />
                    }
                    label={'Hospital Tax Status'}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.hospitalOffering ?? ''}
                    error={errors?.hospitalCoverage?.hospitalOffering}
                    label='Hospital Offering'
                    id='hospitalCoverage.hospitalOffering'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.hospitalGIEligibility ?? ''}
                    error={errors?.hospitalCoverage?.hospitalGIEligibility}
                    label='Hospital GI Eligibility'
                    id='hospitalCoverage.hospitalGIEligibility'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.hospitalPlanTypeOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.hospitalPlanType ?? ''}
                    error={errors?.hospitalCoverage?.hospitalPlanType}
                    label='Hospital Plan Type'
                    id='hospitalCoverage.hospitalPlanType'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPreExRulesOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.hospitalPreExRules ?? ''}
                    error={errors?.hospitalCoverage?.hospitalPreExRules}
                    label='Hospital Pre-Ex Rules'
                    id='hospitalCoverage.hospitalPreExRules'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.hospitalPortability ?? ''}
                    error={errors?.hospitalCoverage?.hospitalPortability}
                    label='Hospital Portability'
                    id='hospitalCoverage.hospitalPortability'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.creditForTimeServedOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.hospitalCreditForTimeServed ?? ''}
                    error={errors?.hospitalCoverage?.hospitalCreditForTimeServed}
                    label='Hospital Credit For Time Served?'
                    id='hospitalCoverage.hospitalCreditForTimeServed'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Hospital Notes'
                    name='hospitalCoverage.hospitalNotes'
                    value={data?.hospitalNotes}
                    onChange={(e) => {
                        setFieldValue('hospitalCoverage.hospitalNotes', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.hospitalCoverage?.hospitalNotes} />
            </Grid>


        </Grid>
    );
};

export default HospitalCoverage;
