import Cookies, { CookieSetOptions } from 'universal-cookie';

/**
 * Set a cookie client-side.
 * @param key string
 * @param value any
 * @returns boolean
 */
export const setCookie = (key: string, value: any, options?: CookieSetOptions): boolean => {
  const cookies = new Cookies();
  cookies.set(key, value, { path: '/', ...options });
  return true;
};

/**
 * Get a cookie client-side.
 * @param key string
 * @returns any
 */
export const getCookie = (key: string): any => {
  const cookies = new Cookies();
  const cookie = cookies.get(key);
  return cookie;
};

/**
 * Remove a cookie client-side.
 * @param key string
 * @returns boolean
 */
export const removeCookie = (key: string, options?: CookieSetOptions): boolean => {
  const cookies = new Cookies();
  cookies.remove(key, { path: '/', ...options });
  return true;
};
