import { Theme } from "@mui/material";
import { theme } from "../../../../styles/theme";
import { makeStyles } from '@mui/styles';

export const formLabel = { color: theme.palette.primary.main }

export const container = {
  marginBottom: '2em'
};

export const addClassButton = {
  marginTop: theme.spacing(1)
};

export const removeButton = {
  color: theme.palette.error.main
};

export const classText = {
  margin: theme.spacing(0.5, 0)
};

export const useSharedStyles = makeStyles((theme: Theme) => ({
  formLabel: formLabel,
  container,
  addClassButton,
  removeButton,
  classText,
  tableCellSmall: {
    padding: '0.7em 0.4em',
  },
  logoUploaded: {
    margin: theme.spacing(1, 0, 0)
  },
  logoDrop: {
    marginTop: theme.spacing(1),
    marginBottom: 0
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  tableCell: {
    padding: '0.7em 0.4em',

    '@media (min-width: 820px)': {
      minWidth: '190px'
    },

    '@media (max-width: 820px)': {
      minWidth: '180px'
    },
  },
  alertMessage: {
    '&& ul': {
      margin: 0,
      padding: `0 0 0 ${theme.spacing(1)}`
    }
  },
  removeButtonWrapper: {
    marginTop: '-16px',
    textAlign: 'right'
  },
  paperBorder: {
    border: `1px solid ${theme.palette.grey[300]}`
  },

  tabContent: {
    minHeight: '35vh',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
}));