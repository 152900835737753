import React, { FC, useContext } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
// context
import { UserContext } from '../../context';
// Components
import { Link } from '../../components';
import { FormLabel, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material';
import { ExpandMore, Edit, Delete, Visibility } from '@mui/icons-material';
// Types
import { IEmployee, EUserRoles } from '../../models';
// helpers
import { formatShortFriendlyDateWithTime, formatDate } from '../../helpers';
import { TimeZoneContext } from '../../context/timezone';

interface IMobileEmployee {
  original: IEmployee;
  handleEdit: (val: IEmployee) => void;
  handleDelete: (val: IEmployee) => void;
}

export const MobileEmployee: FC<IMobileEmployee> = ({ original, handleEdit, handleDelete }) => {
  const classes = useStyles();

  const { businessClientId } = useParams();

  const { user } = useContext(UserContext);

  // Time Zone
  const { timeZone } = useContext(TimeZoneContext);

  return (
    <Accordion className={classes.root}>
      <AccordionSummary className={classes.accordion} expandIcon={<ExpandMore />} aria-controls='panel1a-content' id='panel1a-header'>
        <div className={classes.topPanelSummaryWrapper}>
          {original.firstName} {original.lastName}
          <div className={classes.buttonsWrapper}>
            <Link to={`/business-clients/${businessClientId}/employees/${original.employeeId}`}>
              <IconButton className={classes.editButton} color='primary' size='large'>
                <Visibility />
              </IconButton>
            </Link>
            <IconButton
              className={classes.editButton}
              color='primary'
              onClick={e => {
                e.stopPropagation();
                handleEdit(original);
              }}
              size='large'
            >
              <Edit />
            </IconButton>
            <IconButton
              className={classes.deleteButton}
              color='default'
              onClick={e => {
                e.stopPropagation();
                handleDelete(original);
              }}
              size='large'
            >
              <Delete />
            </IconButton>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelEmail)}>
            Effective Date:
          </FormLabel>
          <Typography component='span'>{original.effectiveDate ? formatDate(original.effectiveDate) : '--'}</Typography>
        </div>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelEmail)}>
            Scheduling Status:
          </FormLabel>
          <Typography component='span'>{original.meetingSchedulingStatus || '--'}</Typography>
        </div>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelPhone)}>
            Next Meeting:
          </FormLabel>
          <Typography component='span'>{original.nextScheduledMeeting ? formatShortFriendlyDateWithTime(original.nextScheduledMeeting, timeZone) : '--'}</Typography>
        </div>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelPhone)}>
            Meeting Outcome:
          </FormLabel>
          <Typography component='span'>{original.enrollmentMeetingOutcome || '--'}</Typography>
        </div>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelPhone)}>
            Post Enrollment Follow-Up:
          </FormLabel>
          <Typography component='span'>{original.postEnrollmentFollowUpItem || '--'}</Typography>
        </div>
        <div className={classes.panelSummaryWrapper}>
          <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelPhone)}>
            Communication History:
          </FormLabel>
          <Typography component='span'>{original.lastCommunication ? formatShortFriendlyDateWithTime(original.lastCommunication, timeZone) : '--'}</Typography>
        </div>
        {user && user.roles && user.roles.includes(EUserRoles.ADMIN) && (
          <div className={classes.panelSummaryWrapper}>
            <FormLabel component='span' className={clsx(classes.boldName, classes.subLabelPhone)}>
              Notes:
            </FormLabel>
            <Typography component='span'>{original.benefitCounselorNotes || '--'}</Typography>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    overflowX: 'hidden'
  },
  accordion: {
    padding: '0 16px',
    '&& .MuiAccordionSummary-content': {
      marginBottom: 0,
      marginTop: 0
    }
  },
  panelSummaryWrapper: {
    marginTop: theme.spacing(0.5),
    '&:first-child': { marginTop: 0 }
  },
  topPanelSummaryWrapper: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '&:first-child': { marginTop: 0 }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 16px',
    backgroundColor: theme.palette.grey[100]
  },
  roleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  boldName: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 16
  },
  activeName: {
    color: theme.palette.grey[500]
  },
  divider: {
    margin: '0 5px'
  },
  subLabelEmail: {
    marginRight: 10
  },
  subLabelPhone: {
    marginRight: 5
  },
  editButton: {
    padding: `2px 5px`
  },
  deleteButton: {
    padding: `2px 5px`,
    color: theme.palette.error.main
  },
  buttonsWrapper: {
    display: 'flex'
  }
}));
