import React, { createContext, FC, useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage } from '../helpers';
// helpers

interface ITimeZoneContext {
  setTimeZone: (timezone: string) => void;
  timeZone: string;
}

export const TimeZoneContext = createContext<ITimeZoneContext>({ setTimeZone: () => {}, timeZone: undefined });

interface ITimeZoneContextHandlerProps {}

export const TimeZoneContextHandler: FC<ITimeZoneContextHandlerProps> = ({ children }): JSX.Element => {
  const [timeZone, setTimeZone] = useState<string>('America/New_York');
  useEffect(() => {
    getLocalStorage('timezone')
      .then(data => {
        if (data) {
          setTimeZone(data);
        } else {
          setTimeZone('America/New_York');
        }
      })
      .catch(err => {
        console.log('Error', err);
        setTimeZone('America/New_York');
      });
  }, []);

  useEffect(() => {
    setLocalStorage('timezone', timeZone);
  }, [timeZone]);

  return <TimeZoneContext.Provider value={{ timeZone, setTimeZone }}>{children}</TimeZoneContext.Provider>;
};
