
import { useSharedStyles } from '../../../../../shared/styles';
import { Accordion } from '../../../../../../../../components';
import AccidentCoverage from './AccidentCoverage/AccidentCoverage';
import HospitalCoverage from './HospitalCoverage/HospitalCoverage';
import CancerCoverage from './CancerInsurance/CancerCoverage';
import CriticalIllnessCoverage from './CriticalIllnessCoverage/CriticalIllnessCoverage';
import CriticalIllnessCoverageGuaranteedIssuesTable from './CriticalIllnessGuaranteedIssuesTable/CriticalIllnessGuaranteedIssuesTable';
import PortableLifeCoverage from './PortableLifeCoverage/PortableLifeCoverage';
import PortableLifeCoverageGuaranteedIssuesTable from './PortableLifeGuaranteedIssuesTable/PortableLifeGuaranteedIssuesTable';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import BusinessClientTabContentBase from '../../../../../../../../components/business-client-tab-content-base';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { useLateralFormMenuControl } from '../../../../../contexts/LateralFormMenuControlContext';

export default function Worksite() {

    const { values, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('worksite');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    const { selectAccordionCategory } = useLateralFormMenuControl();

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <BusinessClientTabContentBase benefitsList={[
                {
                    benefitName: 'Accident Coverage',
                    offered: values.accidentInsurance?.offerAccidentCoverage === true
                },
                {
                    benefitName: 'Hospital Coverage',
                    offered: values.hospitalCoverage?.offerHospitalCoverage === true
                },
                {
                    benefitName: 'Cancer Insurance',
                    offered: values.cancerInsurance?.offerCancerCoverage === true
                },
                {
                    benefitName: 'Critical Illness Insurance',
                    offered: values.criticalIllnessInsurance?.offerCriticalIllnessCoverage === true
                },
                {
                    benefitName: 'Portable Life Insurance',
                    offered: values.portableLifeInsurance?.offerPortableLifeInsuranceCoverage === true
                }
            ]} >


                <Accordion defaultExpanded={!selectAccordionCategory || selectAccordionCategory === 'AccidentCoverage'} hasErrors={errors?.accidentInsurance !== undefined} title='Accident Coverage' component='h3' variant='h4'>
                    <AccidentCoverage />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'HospitalCoverage'} hasErrors={errors?.hospitalCoverage !== undefined} title='Hospital Coverage' component='h3' variant='h4'>
                    <HospitalCoverage />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'CancerInsurance'} hasErrors={errors?.cancerInsurance !== undefined} title='Cancer Insurance' component='h3' variant='h4'>
                    <CancerCoverage />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'CriticalIllnessInsurance'} hasErrors={errors?.criticalIllnessInsurance !== undefined} title='Critical Illness Insurance' component='h3' variant='h4'>
                    <CriticalIllnessCoverage />
                    <CriticalIllnessCoverageGuaranteedIssuesTable />
                </Accordion>

                <Accordion defaultExpanded={selectAccordionCategory === 'PortableLifeInsurance'} hasErrors={errors?.portableLifeInsurance !== undefined} title='Portable Life Insurance' component='h3' variant='h4'>
                    <PortableLifeCoverage />
                    <PortableLifeCoverageGuaranteedIssuesTable />
                </Accordion>

            </BusinessClientTabContentBase>

        </div>
    );
}
