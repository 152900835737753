import { FormikErrors, useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues, IEnrollmentSchedulingTool } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';

import { Grid, FormGroup, FormControlLabel, FormLabel as MuiLabel, FormControl, Checkbox } from '@mui/material';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';


export default function ScheduleManagement() {

    const { values, setFieldValue, handleBlur, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const enrollmentSchedulingTools = values.enrollmentSchedulingTools;

    const isOtherOptionSelected = enrollmentSchedulingTools.some(e => e.schedulingTool === 'Other' && e.isSelected);
    const otherOptionSelectedIndex = enrollmentSchedulingTools.findIndex(e => e.schedulingTool === 'Other' && e.isSelected);

    const enrollmentSchedulingToolsErrors = errors.enrollmentSchedulingTools as FormikErrors<IEnrollmentSchedulingTool>[];



    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
                <FormControl component="fieldset" style={{ display: "flex" }}>
                    <MuiLabel>What type of scheduling tool is needed for this enrollment? (Check all that apply)</MuiLabel>
                    <FormGroup>
                        {enrollmentSchedulingTools.map((opt, index) => (

                            <FormControlLabel
                                value={opt.isSelected}
                                control={
                                    <Checkbox
                                        color="primary"
                                        onBlur={handleBlur}
                                        checked={opt.isSelected}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        name={`enrollmentSchedulingTools.${index}.isSelected`}
                                        onChange={(_, checked: boolean) => {
                                            setFieldValue(`enrollmentSchedulingTools.${index}.isSelected`, checked)
                                        }}
                                    />
                                }
                                label={opt.schedulingToolDescription}
                            />


                        ))}
                    </FormGroup>
                </FormControl>
            </Grid>

            {isOtherOptionSelected && <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Other Tool Description'
                    name={`enrollmentSchedulingTools.${otherOptionSelectedIndex}.otherToolDescription`}
                    value={values.enrollmentSchedulingTools[otherOptionSelectedIndex].otherToolDescription}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue(`enrollmentSchedulingTools.${otherOptionSelectedIndex}.otherToolDescription`, e.target.value)}
                />
                <FieldErrorMessage testClass={`otherToolDescriptionError`} message={enrollmentSchedulingToolsErrors?.[otherOptionSelectedIndex]?.otherToolDescription} />
            </Grid>}


            <Grid item xs={12} sm={12} md={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Proposed dates & times'
                    name='proposedDatesAndTimes'
                    value={values.proposedDatesAndTimes}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('proposedDatesAndTimes', e.target.value)}
                />
                <FieldErrorMessage message={errors.proposedDatesAndTimes} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Time Zone(s)'
                    name='timeZone'
                    value={values.timeZone}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('timeZone', e.target.value)}
                />
                <FieldErrorMessage message={errors.timeZone} />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Enrollment Method and Scheduling Notes:'
                    name='enrollmentMethodAndSchedulingNotes'
                    value={values.enrollmentMethodAndSchedulingNotes}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('enrollmentMethodAndSchedulingNotes', e.target.value)}
                />
                <FieldErrorMessage message={errors.enrollmentMethodAndSchedulingNotes} />
            </Grid>

        </Grid>

    )
}