import React, { useMemo, useState, useEffect } from 'react';
import { Grid, TextField, InputAdornment } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
// Components
import { Page } from '../../../components/skeleton';
import { Table, Pagination, Toast, PageTitle, Button, Loader } from '../../../components';
import { MobileManageBroker } from './MobileManageBroker';
import { BrokerModal } from './BrokerModal';
// icons
import { Edit, Add, Delete, Search, Close } from '@mui/icons-material';
// Types
import { SortOptions, DESC_SORT, ASC_SORT, IBroker } from '../../../models';
// fetch
import { getBrokers, deleteBroker, getBroker } from '../../../fetch';
// hooks
import { useDebounce } from '../../../hooks';

export const ManageBrokers = () => {
  const classes = useStyles();

  // state
  const [page, setPage] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<{
    Name?: SortOptions;
  }>({
    Name: ASC_SORT
  });
  const [selectedSort, setSelectedSort] = useState<string>('Name');
  const [isBrokerModalOpen, showBrokerModal] = useState<boolean>(false);
  const [currentBroker, setCurrentBroker] = useState<IBroker | null>(null);
  const [isDeleting, setDeleting] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueDebounced, setSearchValueDebounced] = useState('');

  const fetchBroker = async (id: number) => {
    try {
      const res = await getBroker(id);
      setCurrentBroker(res);
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading brokers, please try again.');
    }
  };

  const fetchBrokers = async () => {
    setIsLoading(true);
    try {
      const res = await getBrokers({
        page: searchValueDebounced ? 1 : page + 1,
        perPage,
        sortBy: selectedSort,
        sortDirection: sortDirection[selectedSort] as string,
        search: searchValueDebounced
      });
      if (searchValueDebounced) {
        setPage(0);
      }
      if (res.records) {
        setBrokers(res.records);
        setRecordCount(res.totalRecordCount);
      } else {
        console.error(`No 'records' on brokers response.`);
        setErrorMessage('Error loading brokers, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading brokers, please try again.');
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchBrokers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, selectedSort, page, perPage]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      [column]: sortDirection[column] === ASC_SORT ? DESC_SORT : ASC_SORT
    });
  };

  const handleDelete = async id => {
    const result = window.confirm('Are you sure you want to delete this broker?');
    if (result) {
      setDeleting(true);
      try {
        await deleteBroker(id);
        fetchBrokers();
        setSuccessMessage('Broker Deleted!');
      } catch (error) {
        setErrorMessage(error);
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === DESC_SORT,
        handleClickColumn: () => handleClickColumn('Name')
      },
      {
        id: 'actions',
        Header: '',
        className: classes.editButton,
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: { row: { original: IBroker } };
        }) => {
          return (
            <>
              <Button
                id='edit'
                color='primary'
                startIcon={<Edit />}
                onClick={() => {
                  fetchBroker(original.brokerId);
                  showBrokerModal(true);
                }}
              >
                Edit
              </Button>
              <Button
                id='delete'
                className={classes.deleteButton}
                startIcon={<Delete />}
                onClick={() => {
                  handleDelete(original.brokerId);
                }}
              >
                Delete
              </Button>
            </>
          );
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokers, sortDirection, selectedSort, page, perPage]);

  const hasData = brokers && brokers.length > 0;
  const isMobile = useMediaQuery('(max-width: 960px)');
  const isSmMobile = useMediaQuery('(max-width: 600px)');

  useDebounce(
    () => {
      setSearchValueDebounced(searchValue);
    },
    500,
    [searchValue]
  );

  useEffect(() => {
    fetchBrokers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueDebounced]);

  return (
    <>
      {isDeleting && (
        <Loader position='centered' type='fullscreen'>
          Deleting...
        </Loader>
      )}
      <Page title='Manage Brokers'>
        <PageTitle title='Manage Brokers' />
        <Grid container alignItems='center' justifyContent={isSmMobile ? 'center' : 'flex-end'}>
          <Button id='add-broker' startIcon={<Add />} onClick={() => showBrokerModal(true)} color='primary' variant='contained' className={classes.addBrokerButton}>
            Add Broker
          </Button>
        </Grid>
        <div className={classes.wrapper}>
          <div className={classes.searchWrapper}>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                size='small'
                variant='outlined'
                fullWidth
                placeholder='Search Brokers...'
                name='search'
                value={searchValue}
                disabled={isLoading}
                InputProps={{
                  autoFocus: true,
                  startAdornment: (
                    <InputAdornment position='start' className={classes.searchIcon}>
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchValue ? (
                    <InputAdornment
                      position='end'
                      className={classes.searchCloseIcon}
                      onClick={() => {
                        setSearchValue('');
                        setSearchValueDebounced('');
                      }}
                    >
                      <Close />
                    </InputAdornment>
                  ) : null
                }}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
              />
            </Grid>
          </div>
          <Table
            columns={columns}
            data={brokers}
            isLoading={isLoading}
            hidePagination
            ResponsiveComponent={isMobile ? MobileManageBroker : undefined}
            mobileProps={{
              handleEdit: async (broker: IBroker) => {
                await fetchBroker(broker.brokerId);
                // setCurrentBroker(broker);
                showBrokerModal(true);
              },
              handleDelete: (broker: IBroker) => handleDelete(broker.brokerId)
            }}
          />
          {!isLoading && hasData && (
            <div className={classes.paginationWrapper}>
              <Pagination page={page} count={recordCount} rowsPerPage={perPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
            </div>
          )}
        </div>
      </Page>
      <Toast id='bokers-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
      <Toast id='bokers-success' message={successMessage} open={!!successMessage} onClose={() => setSuccessMessage('')} variant='success' />
      <BrokerModal
        currentBroker={currentBroker}
        open={isBrokerModalOpen}
        onClose={() => {
          showBrokerModal(false);
          setTimeout(() => {
            setCurrentBroker(null);
          }, 500);
        }}
        onSave={() => fetchBrokers()}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0.125)
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0)
  },
  editButton: {
    textAlign: 'right'
  },
  deleteButton: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main
  },
  addBrokerButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  searchIcon: {
    color: theme.palette.grey[500]
  },
  searchCloseIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  searchWrapper: {
    marginBottom: theme.spacing(1)
  }
}));
