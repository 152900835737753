import { FC } from 'react';
import { EnrollmentPlannerSubMenusTypes, EnrollmentStrategySubMenusTypes, TabOptionType } from '../../../../MenuData/types';
import { TabsBase } from '../../../base/tabs-base';


interface IEnrollmentPlannerTabsProps {
    validateForm: () => void;
}

const tabOptions: TabOptionType<EnrollmentPlannerSubMenusTypes>[] = [
    {
        value: 0,
        label: 'Census',
        subMenuRelatedId: 'census'
    },
    {
        value: 1,
        label: 'Ess Docs',
        subMenuRelatedId: 'ess_docs'
    },
];


export const EnrollmentPlannerTabs: FC<IEnrollmentPlannerTabsProps> = ({ validateForm }) => {
    return <TabsBase validateForm={validateForm} tabOptions={tabOptions} />
}
