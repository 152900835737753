import { FormikErrors } from "formik";
import { IBusinessClientDetailFormValues } from "../../../../../../../../models";

export default (errors: FormikErrors<IBusinessClientDetailFormValues>) => {
    return !errors?.openEnrollmentEffectiveDate &&
    !errors?.enrollmentStart &&
    !errors?.enrollmentEnd &&
    !errors?.selfServiceStartDate &&
    !errors?.selfServiceEndDate &&
    !errors?.hrDeadline &&
    !errors?.targetDate;
}