import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { RouteComponentProps } from 'react-router-dom';

export const withTracker = <P extends RouteComponentProps>(WrappedComponent: React.ComponentType<P>, options = {}) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
