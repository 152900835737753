import * as React from 'react';
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import { ProductDetailsSubMenusTypes } from '../../../MenuData/types';
import ProductDetailsTabs from './tabs-header';
import Medical from './Medical/Medical';
import Ancillary from './Ancillary/Ancillary';
import Worksite from './Worksite/Worksite';
import Financial from './Financial/Financial';
import Supplemental from './Supplemental/Supplemental';

const componentDictionary: Record<ProductDetailsSubMenusTypes, JSX.Element> = {
    'ancillary': <Ancillary />,
    'financial': <Financial />,
    'medical': <Medical />,
    'supplemental': <Supplemental />,
    'worksite': <Worksite />,
};

export default function ProductDetails() {

    const { selectedSubMenu } = useLateralFormMenuControl();
    const typedSelectedSubMenu = selectedSubMenu as ProductDetailsSubMenusTypes

    return (
        <div>
            <ProductDetailsTabs validateForm={() => { }} />

            <div>
                {componentDictionary[typedSelectedSubMenu]}
            </div>
        </div>
    );
}
