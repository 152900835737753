import { useEffect, useContext } from "react";

import useErrorMessage from "../../context/ErrorMessageCtx";
import { RequestState } from "../../models/request-state";
import { DropDownCollectionsCtx } from "../../context/DropDownCollectionsCtx";
import { getInsuranceCoverageOptionsRequest } from "../../fetch";
import { IInsuranceCoverageOptions } from "../../models";

const useInsuranceCoverageOptionsDropdownCollection = (): RequestState<IInsuranceCoverageOptions> => {
    const [, setErrorMessage] = useErrorMessage();
    const { insuranceCoverageOptionsRequest, dispatchInsuranceCoverageOptionsRequest } = useContext(DropDownCollectionsCtx);

    const status = insuranceCoverageOptionsRequest.status;


    useEffect(() => {

        if (status !== "idle") return;

        dispatchInsuranceCoverageOptionsRequest({ type: 'REQUEST_LOADING' });

        const fetchCollection = async () => {
            try {

                const res = await getInsuranceCoverageOptionsRequest();
                if (res) {

                    dispatchInsuranceCoverageOptionsRequest({ type: 'REQUEST_SUCCESS', payload: res })
                } else {
                    setErrorMessage('Error loading financial options, please try again.');
                    dispatchInsuranceCoverageOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading industries, please try again.' })
                }
            } catch (error) {
                console.error(error);
                dispatchInsuranceCoverageOptionsRequest({ type: 'REQUEST_ERROR', payload: 'Error loading industries, please try again.' })
                setErrorMessage('Error loading financial options, please try again.');
            }


        };

        fetchCollection();
    }, [status, insuranceCoverageOptionsRequest, setErrorMessage]);

    return insuranceCoverageOptionsRequest;

};

export default useInsuranceCoverageOptionsDropdownCollection