
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import { AllBusinessClientSubMenusTypes, TabOptionType } from '../../../MenuData/types';
import { menusSubMenusValidationStatusMap } from '../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../models';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Theme, Tabs, Tab } from '@mui/material';
import { MobileTabs } from './MobileTabs/MobileTabs';

interface ITabsBaseProps<T> {
    validateForm: () => void;
    tabOptions: TabOptionType<T>[]
}

export const TabsBase = <T extends AllBusinessClientSubMenusTypes>({ validateForm, tabOptions }: ITabsBaseProps<T>) => {

    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width: 960px)');

    const { selectedSubMenu, setSubMenu } = useLateralFormMenuControl();

    const selectedTab = tabOptions.find(t => t.subMenuRelatedId === selectedSubMenu)?.value ?? 0;

    const { errors } = useFormikContext<IBusinessClientDetailFormValues>();

    return (<>
        {isMobile ? (
            <MobileTabs validateForm={validateForm} tabOptions={tabOptions} />
        ) : (
            <Tabs
                value={selectedTab}
                indicatorColor='primary'
                textColor='primary'
                onChange={(_, value) => {
                    validateForm();
                    const newSelectedTab = tabOptions.find(t => t.value === value);
                    setSubMenu(newSelectedTab.subMenuRelatedId);
                }}
            >
                {tabOptions.map((tab, index) => {

                    const validateAction = menusSubMenusValidationStatusMap.get(tab.subMenuRelatedId);
                    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

                    return (
                        <Tab
                        data-cy={`tab-${tab.subMenuRelatedId}`}
                            id={`tab-id-${index}`}
                            classes={{
                                root: classes.tabRoot,
                                textColorPrimary: clsx(
                                    classes.tabText,
                                    IsThereSomethingInvalidInTheTab
                                        ? classes.tabErrors
                                        : ''
                                ),
                                selected: classes.tabSelected
                            }}
                            key={`${index}`}
                            aria-controls={`tab-content-${index}`}
                            label={<div style={{ 'display': 'flex', 'alignItems': 'center' }}>
                                <p>{tab.label}</p>
                                {tab.secondaryText && <small style={{ 'fontSize': '7pt', 'marginLeft': '5px' }}>{tab.secondaryText}</small>}
                            </div>}
                        />
                    );
                })}
            </Tabs>)}</>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    tabBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    tabRoot: {
        minWidth: 0,
        fontSize: 18,
        padding: `15px 20px`,
        textTransform: 'none'
    },
    tabText: {
        color: theme.palette.grey[400]
    },
    tabSelected: {
        fontWeight: 'bold'
    },
    tabErrors: {
        color: theme.palette.error.main
    }
}));
