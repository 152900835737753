import * as React from 'react';
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import { PostEnrollmentDataSubMenusTypes } from '../../../MenuData/types';
import PostEnrollmentDataTabs from './tabs-header'
import EnrollmentEngagementNumbers from './EnrollmentEngagementNumbers/EnrollmentEngagementNumbers';
import { Accordion } from '../../../../../../../components';
import { DefaultTabBase } from '../shared';
import ProductPartipation from './ProductParticipation/ProductParticipation';
import ChallengesAndSolutions from './ChallengesAndSolutions/ChallengesAndSolutions';


const componentDictionary: Record<PostEnrollmentDataSubMenusTypes, JSX.Element> = {
    'challenges_and_solutions': <DefaultTabBase>
    <Accordion title='Challenges and Solutions' component='h3' variant='h4' defaultExpanded>
        <div>
            <ChallengesAndSolutions />
        </div>
    </Accordion>
</DefaultTabBase>,
    'enrollment_engagement_numbers':
        <DefaultTabBase>
            <Accordion title='Enrollment Engagement Numbers' component='h3' variant='h4' defaultExpanded>
                <div>
                    <EnrollmentEngagementNumbers />
                </div>
            </Accordion>
        </DefaultTabBase>,

    'product_partipation': <DefaultTabBase>
        <Accordion title='Product Partipation' component='h3' variant='h4' defaultExpanded>
            <div>
                <ProductPartipation />
            </div>
        </Accordion>
    </DefaultTabBase>,
};

export default function PostEnrollmentData() {

    const { selectedSubMenu } = useLateralFormMenuControl();
    const typedSelectedSubMenu = selectedSubMenu as PostEnrollmentDataSubMenusTypes

    return (
        <div>
            <PostEnrollmentDataTabs validateForm={() => { }} />

            <div>
                {componentDictionary[typedSelectedSubMenu]}
            </div>
        </div>
    );
}
