import React from 'react';
import { IHealthReimbursementAccount } from '../../../../../../../../../models';
import { Grid, FormControlLabel, FormLabel as MuiLabel, Box, Switch } from '@mui/material';
import { useSharedStyles } from '../../../../../../shared/styles';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const HealthReimbursementAccount: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: IHealthReimbursementAccount = values.healthReimbursementAccount;

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Health Reimbursement Account
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerHealthReimbursementAccount}
                                    id='healthReimbursementAccount.offerHealthReimbursementAccount'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('healthReimbursementAccount.offerHealthReimbursementAccount', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={
                                data?.offerHealthReimbursementAccount ? 'Offered' : 'Not offered'
                            }
                        />
                    </Box>
                    <SectionsFilesList category='HealthReimbursementAccount' />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='ER Contribution'
                    name='healthReimbursementAccount.erContribution'
                    value={data?.erContribution}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('healthReimbursementAccount.erContribution', e.target.value)}
                />
                <FieldErrorMessage message={errors?.healthReimbursementAccount?.erContribution} />
            </Grid>

            

            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Contingent Plans'
                    name='healthReimbursementAccount.contingentPlans'
                    value={data?.contingentPlans}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('healthReimbursementAccount.contingentPlans', e.target.value)}
                />
                <FieldErrorMessage message={errors?.healthReimbursementAccount?.contingentPlans} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalOptions?.defaultPeriodOptions ?? []}
                    isLoading={generalOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.coverageTerminatesOn ?? ''}
                    error={errors?.healthReimbursementAccount?.coverageTerminatesOn}
                    label='When an employee is terminated, coverage terminates on:' id={`healthReimbursementAccount.coverageTerminatesOn`}
                />
            </Grid>

            <Grid item xs={12}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Highlights'
                    name='healthReimbursementAccount.highlights'
                    value={data?.highlights}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('healthReimbursementAccount.highlights', e.target.value)}
                />
                <FieldErrorMessage message={errors?.healthReimbursementAccount?.highlights} />
            </Grid>
        </Grid>
    );
};

export default HealthReimbursementAccount;
