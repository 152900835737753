import { FC, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Typography,
  InputLabel,
  Divider,
  Button,
  RadioGroup,
  FormLabel as MuiLabel,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox
} from '@mui/material';
import { IBusinessClientDetailFormValues, IBusinessClientDetail, ISystemOfRecord, IUser, IDropdownResponse, IDataFileRecipient } from '../../../models';
import { Field, FieldArray, FormikErrors, FormikTouched } from 'formik';
import { DatePicker } from '../../../components';
import { Close } from '@mui/icons-material';

interface IClientPreferences {
  values: IBusinessClientDetailFormValues;
  handleBlur: any;
  touched: FormikTouched<IBusinessClientDetailFormValues>;
  errors: FormikErrors<IBusinessClientDetailFormValues>;
  setFieldValue: (key: string, val: any) => void;
  setFieldTouched: (key: string, val: any) => void;
  currentBusinessClient: IBusinessClientDetail | null;
  isMobile: boolean;
  systemOfRecordsLoading: boolean;
  systemOfRecords: ISystemOfRecord[];
  isLoadingUsers: boolean;
  users: IUser[];
  dataFileRecipientsLoading: boolean;
  dataFileRecipients: IDropdownResponse[];
}

export const ClientPreferences: FC<IClientPreferences> = ({
  values,
  errors,
  handleBlur,
  touched,
  setFieldValue,
  setFieldTouched,
  currentBusinessClient,
  isMobile,
  systemOfRecordsLoading,
  systemOfRecords,
  isLoadingUsers,
  users,
  dataFileRecipientsLoading,
  dataFileRecipients
}) => {
  const classes = useStyles();
  const [hasSpecialAttentionEmployees, setHasSpecialAttentionEmployees] = useState<boolean>(false);
  const [originalDataFileRecipients] = useState<IDataFileRecipient[]>(currentBusinessClient?.dataFileRecipients || []);
  const checkDataFileRecipientIds = (name: string) => {
    // Check if this field already exists on the currentBusinessClient,
    // If so, use existing ID instead of generating a new one
    const isSelected = originalDataFileRecipients.filter(option => option.recipient === name).length > 0 ? true : false;
    if (isSelected) {
      return originalDataFileRecipients.filter(option => option.recipient === name)[0].businessClientDataFileRecipientId;
    } else return 0;
  };
  const toggleSelect = (field: string, value: boolean) => {
    let copy = [...values.dataFileRecipients];
    let isSelected = values.dataFileRecipients.filter(option => option.recipient === field).length > 0 ? true : false;
    // If unchecked and already selected, remove from array
    if (isSelected && !value) {
      copy = copy.filter(option => option.recipient !== field);
    }
    // If checked and not selected, add to array
    if (!isSelected && value) {
      copy = [
        ...copy,
        {
          businessClientDataFileRecipientId: checkDataFileRecipientIds(field),
          recipient: field,
          otherRecipientDescription: null
        }
      ];
    }
    setFieldValue('dataFileRecipients', copy);
  };
  const handleUpdateOtherDescription = (value: string) => {
    let copy = values.dataFileRecipients.map(option => {
      if (option.recipient === 'Other') {
        return { ...option, otherRecipientDescription: value };
      } else return option;
    });
    setFieldValue('dataFileRecipients', copy);
  };
  useEffect(() => {
    if (currentBusinessClient?.specialAttentionBusinessClientEmployees?.length > 0) {
      setHasSpecialAttentionEmployees(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusinessClient]);

  return (
    <>
      <Typography variant='h5' component='h4' className={classes.subHeader} color='primary'>
        Enrollment System
      </Typography>
      <Grid container spacing={1} sx={{ marginTop: 0 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined' size='small' required error={touched && errors.systemOfRecord ? true : false}>
            <InputLabel id={`system-of-record`} className={classes.outlinedLabel}>
              System of Record
            </InputLabel>
            <Select
              name={`system-of-record`}
              labelId={`system-of-record`}
              id={`system-of-record`}
              value={values.systemOfRecord}
              size='small'
              onBlur={handleBlur}
              onChange={({ target: { value } }) => setFieldValue('systemOfRecord', value)}
            >
              {!systemOfRecordsLoading &&
                systemOfRecords.map((record, index) => {
                  return (
                    <MenuItem key={`${index}`} value={record.name}>
                      {record.name}
                    </MenuItem>
                  );
                })}
            </Select>
            {touched && errors.systemOfRecord && <FormHelperText>{errors.systemOfRecord}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            fullWidth
            variant='outlined'
            autoComplete='nope'
            size='small'
            label='Enrollment System URL'
            name='systemOfRecordUrl'
            placeholder='www.example.com'
            value={values.systemOfRecordUrl}
            onBlur={handleBlur}
            onChange={e => setFieldValue('systemOfRecordUrl', e.target.value)}
            error={Boolean(touched.systemOfRecordUrl && errors.systemOfRecordUrl)}
            helperText={touched.systemOfRecordUrl && errors.systemOfRecordUrl}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            fullWidth
            variant='outlined'
            autoComplete='nope'
            size='small'
            label='System Username'
            name='systemOfRecordUsername'
            value={values.systemOfRecordUsername}
            onBlur={handleBlur}
            onChange={e => setFieldValue('systemOfRecordUsername', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            autoComplete='nope'
            label='System Password'
            name='systemOfRecordPassword'
            value={values.systemOfRecordPassword}
            onBlur={handleBlur}
            onChange={e => setFieldValue('systemOfRecordPassword', e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} sx={{ marginBottom: theme => theme.spacing(1) }} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            multiline
            inputProps={{ maxLength: 255 }}
            variant='outlined'
            autoComplete='nope'
            size='small'
            label={`Highlights, Challenges, & Important Notes`}
            name='highlightsChallenges'
            value={values.highlightsChallenges}
            onBlur={handleBlur}
            onChange={e => setFieldValue('highlightsChallenges', e.target.value)}
            error={Boolean(touched && errors.highlightsChallenges)}
            helperText='Max 255 characters'
            minRows={3}
            maxRows={5}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl component='fieldset' sx={{ marginTop: isMobile ? theme => theme.spacing(0.5) : 0 }}>
            <MuiLabel component='legend'>Are there any employees at this client that our team should pay special attention to?</MuiLabel>
            <RadioGroup
              row
              aria-label={`hasSpecialAttentionBusinessClientEmployees`}
              name={`hasSpecialAttentionBusinessClientEmployees`}
              value={hasSpecialAttentionEmployees}
              color='primary'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === 'true') {
                  setHasSpecialAttentionEmployees(true);
                  setFieldValue('specialAttentionBusinessClientEmployees', [
                    {
                      businessClientSpecialAttentionEmployeeId: -(values.specialAttentionBusinessClientEmployees.length + 1),
                      name: '',
                      title: ''
                    }
                  ]);
                } else {
                  setHasSpecialAttentionEmployees(false);
                  setFieldValue('specialAttentionBusinessClientEmployees', []);
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
              <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
            </RadioGroup>
          </FormControl>
        </Grid>
        {hasSpecialAttentionEmployees && (
          <Grid item xs={12}>
            <Typography variant='h5' component='h4' className={classes.subHeader} color='primary'>
              Special Attention Employees
            </Typography>
            <FieldArray name='specialAttentionBusinessClientEmployees'>
              {({ push, remove }) => {
                return (
                  <Grid container spacing={1}>
                    {values.specialAttentionBusinessClientEmployees.length > 0 &&
                      values.specialAttentionBusinessClientEmployees.map((employee, index) => {
                        return (
                          <Grid item xs={12} lg={6} key={`${index}`}>
                            <Typography variant='h5' component='h4' className={classes.repeaterTItle} color='primary'>
                              Employee {index + 1}
                            </Typography>
                            <Grid container spacing={1} alignItems='flex-start'>
                              <Grid item xs={12} sm={6} md={5} lg={5}>
                                <Field name={`specialAttentionBusinessClientEmployees.${index}.name`}>
                                  {({ field }) => {
                                    return (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        autoComplete='nope'
                                        label='Name'
                                        name={`specialAttentionBusinessClientEmployees.${index}.name`}
                                        value={values.specialAttentionBusinessClientEmployees[index].name}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 255 }}
                                        error={
                                          touched &&
                                          touched.specialAttentionBusinessClientEmployees &&
                                          errors &&
                                          errors.specialAttentionBusinessClientEmployees &&
                                          errors.specialAttentionBusinessClientEmployees[index] &&
                                          // @ts-ignore
                                          errors.specialAttentionBusinessClientEmployees[index].name
                                            ? true
                                            : false
                                        }
                                        // @ts-ignore
                                        helperText={
                                          touched &&
                                          touched.specialAttentionBusinessClientEmployees &&
                                          errors &&
                                          errors.specialAttentionBusinessClientEmployees &&
                                          errors.specialAttentionBusinessClientEmployees[index] &&
                                          // @ts-ignore
                                          errors.specialAttentionBusinessClientEmployees[index].name
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={5}>
                                <Field name={`specialAttentionBusinessClientEmployees.${index}.title`}>
                                  {({ field }) => {
                                    return (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        autoComplete='nope'
                                        label='Title/Position'
                                        name={`specialAttentionBusinessClientEmployees.${index}.title`}
                                        value={values.specialAttentionBusinessClientEmployees[index].title}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 255 }}
                                      />
                                    );
                                  }}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={1} lg={2} className={classes.repeaterRemoveButtonWrapper} alignItems='center'>
                                <Button
                                  className={classes.repeaterRemoveButton}
                                  startIcon={<Close />}
                                  title={!isMobile ? 'Remove Employee' : undefined}
                                  size={!isMobile ? 'large' : undefined}
                                  onClick={() => {
                                    remove(index);
                                    const newEmployees = [...values.specialAttentionBusinessClientEmployees];
                                    setFieldValue(
                                      'specialAttentionBusinessClientEmployees',
                                      newEmployees.filter(code => code.businessClientSpecialAttentionEmployeeId !== employee.businessClientSpecialAttentionEmployeeId)
                                    );
                                  }}
                                >
                                  <span className={classes.repeaterRemoveButtonText}>Remove Employee</span>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                          push({
                            businessClientSpecialAttentionEmployeeId: -(values.specialAttentionBusinessClientEmployees.length + 1),
                            name: '',
                            title: ''
                          });
                        }}
                      >
                        Add Employee
                      </Button>
                    </Grid>
                  </Grid>
                );
              }}
            </FieldArray>
          </Grid>
        )}
      </Grid>
      <Divider className={classes.divider} />
      <Typography variant='h5' component='h4' className={classes.subHeader} color='primary'>
        Critical Dates
      </Typography>
      <Grid container spacing={1} sx={{ marginTop: 0 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined'>
            <DatePicker
              label='Effective Date'
              value={values.openEnrollmentEffectiveDate ? new Date(values.openEnrollmentEffectiveDate) : null}
              renderInput={props => (
                <TextField {...props} size='small' label='Effective Date' value={values.openEnrollmentEffectiveDate ? new Date(values.openEnrollmentEffectiveDate) : null} />
              )}
              onChange={(date: Date) => {
                setFieldValue(`openEnrollmentEffectiveDate`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined' size='small' required error={touched && errors.dashboardOpenEnrollmentView ? true : false}>
            <InputLabel id={`dashboard-oe-view`} className={classes.outlinedLabel}>
              Dashboard OE View
            </InputLabel>
            <Select
              name={`dashboard-oe-view`}
              labelId={`dashboard-oe-view`}
              id={`dashboard-oe-view`}
              value={values.dashboardOpenEnrollmentView}
              size='small'
              onBlur={handleBlur}
              onChange={({ target: { value } }) => setFieldValue('dashboardOpenEnrollmentView', value)}
            >
              {[
                { name: '45 days', value: 45 },
                { name: '60 days', value: 60 },
                { name: '75 days', value: 75 },
                { name: '90 days', value: 90 }
              ].map((record, index) => {
                return (
                  <MenuItem key={`${index}`} value={record.value}>
                    {record.name}
                  </MenuItem>
                );
              })}
            </Select>
            {touched && errors.dashboardOpenEnrollmentView && <FormHelperText>{errors.dashboardOpenEnrollmentView}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined'>
            <DatePicker
              label='Enrollment Meeting Start Date'
              value={values.openEnrollmentStartDate ? new Date(values.openEnrollmentStartDate) : null}
              renderInput={props => (
                <TextField {...props} size='small' label='Enrollment Meeting Start Date' value={values.openEnrollmentStartDate ? new Date(values.openEnrollmentStartDate) : null} />
              )}
              onChange={(date: Date) => {
                setFieldValue(`openEnrollmentStartDate`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined'>
            <DatePicker
              label='Enrollment Meeting End Date'
              value={values.enrollmentDeadline ? new Date(values.enrollmentDeadline) : null}
              renderInput={props => (
                <TextField {...props} size='small' label='Enrollment Meeting End Date' value={values.enrollmentDeadline ? new Date(values.enrollmentDeadline) : null} />
              )}
              onChange={(date: Date) => {
                setFieldValue(`enrollmentDeadline`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined'>
            <DatePicker
              label='Self Service Start Date'
              value={values.selfServiceStartDate ? new Date(values.selfServiceStartDate) : null}
              renderInput={props => (
                <TextField {...props} size='small' label='Self Service Start Date' value={values.selfServiceStartDate ? new Date(values.selfServiceStartDate) : null} />
              )}
              onChange={(date: Date) => {
                setFieldValue(`selfServiceStartDate`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined'>
            <DatePicker
              label='Self Service End Date'
              value={values.selfServiceEndDate ? new Date(values.selfServiceEndDate) : null}
              renderInput={props => (
                <TextField {...props} size='small' label='Self Service End Date' value={values.selfServiceEndDate ? new Date(values.selfServiceEndDate) : null} />
              )}
              onChange={(date: Date) => {
                setFieldValue(`selfServiceEndDate`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl fullWidth variant='outlined'>
            <DatePicker
              label='Deadline for HR Changes'
              value={values.hrDeadline ? new Date(values.hrDeadline) : null}
              renderInput={props => <TextField {...props} size='small' label='Deadline for HR Changes' value={values.hrDeadline ? new Date(values.hrDeadline) : null} />}
              onChange={(date: Date) => {
                setFieldValue(`hrDeadline`, date?.toISOString?.() ? date?.toISOString() : '');
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <FormControl component='fieldset' sx={{ marginTop: isMobile ? theme => theme.spacing(0.5) : 0 }}>
            <MuiLabel component='legend'>Is Enrollment Alliance responsible for providing a data file to any party?</MuiLabel>
            <RadioGroup
              row
              aria-label={`provideDataFile`}
              name={`provideDataFile`}
              value={values.provideDataFile}
              color='primary'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('provideDataFile', e.target.value === 'true' ? true : false);
                if (e.target.value === 'false') {
                  setFieldValue('dataFileRecipients', []);
                  setFieldValue('dataDeliveryDate', '');
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
              <FormControlLabel value={false} control={<Radio color='primary' />} label='No - client and/or broker is managing this process' />
            </RadioGroup>
          </FormControl>
        </Grid>
        {!!values.provideDataFile && (
          <>
            {dataFileRecipients && dataFileRecipients.length > 0 && !dataFileRecipientsLoading && (
              <Grid item xs={12} sm={6}>
                <FormControl component='fieldset' fullWidth sx={{ marginTop: isMobile ? theme => theme.spacing(0.5) : 0 }}>
                  <MuiLabel component='legend'>Provide data to:</MuiLabel>
                  <FormGroup>
                    <Grid container sx={{ marginTop: 0 }}>
                      {dataFileRecipients.map((type, index) => {
                        const field = values.dataFileRecipients.filter(option => option.recipient === type.value);
                        const isChecked = field.length > 0 ? true : false;
                        const otherDescription = isChecked ? field[0].otherRecipientDescription : null;
                        return (
                          <Grid item xs={12} md={type.value === 'Other' ? 12 : 'auto'} lg={'auto'} key={index}>
                            <FormControlLabel
                              value={isChecked}
                              control={
                                <Checkbox
                                  color='primary'
                                  checked={isChecked}
                                  name={`data-file-recipients-${type.value}`}
                                  onChange={(_, checked: boolean) => toggleSelect(type.value, checked)}
                                />
                              }
                              label={type.description}
                            />
                            {type.value === 'Other' && isChecked && (
                              <TextField
                                fullWidth
                                required
                                variant='outlined'
                                autoComplete='nope'
                                size='small'
                                label='Other'
                                name={`data-file-recipients-${type.value}-description`}
                                value={otherDescription}
                                onBlur={handleBlur}
                                error={isChecked && !otherDescription}
                                helperText={isChecked && !otherDescription ? 'Required' : undefined}
                                onChange={e => handleUpdateOtherDescription(e.target.value)}
                                sx={{ marginTop: theme => theme.spacing(0.5) }}
                              />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant='outlined'>
                <DatePicker
                  label='Data Delivery Date'
                  value={values.dataDeliveryDate ? new Date(values.dataDeliveryDate) : null}
                  renderInput={props => <TextField {...props} size='small' label='Data Delivery Date' value={values.dataDeliveryDate ? new Date(values.dataDeliveryDate) : null} />}
                  onChange={(date: Date) => {
                    setFieldValue(`dataDeliveryDate`, date?.toISOString?.() ? date?.toISOString() : '');
                  }}
                />
                <FormHelperText>Buffer period for HR and Broker to make changes</FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      <Divider className={classes.divider} />
      <Typography variant='h5' component='h4' className={classes.subHeader} color='primary'>
        Enrollment Alliance Contact
      </Typography>
      <Grid container spacing={1} sx={{ marginTop: 0 }}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant='outlined' size='small' required error={Boolean(touched && errors.accountManagerUserId)}>
            <InputLabel id={`accountManagerUserId`} className={clsx(classes.outlinedLabel)}>
              {isLoadingUsers ? 'Loading...' : 'Account Manager'}
            </InputLabel>
            <Select
              name={`accountManagerUserId`}
              disabled={isLoadingUsers}
              labelId={`accountManagerUserId`}
              id={`accountManagerUserId`}
              size='small'
              value={values.accountManagerUserId}
              onBlur={handleBlur}
              onChange={e => setFieldValue('accountManagerUserId', e.target.value as string)}
            >
              {users?.map((user: IUser, index) => {
                return (
                  <MenuItem key={`${index}`} value={user.userId}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error={Boolean(touched && errors?.accountManagerUserId)}>{touched && errors?.accountManagerUserId}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    '&&:first-child': {
      marginTop: 0
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  divider: {
    marginTop: theme.spacing(1)
  },
  repeaterTItle: {
    margin: theme.spacing(0.5, 0, 1)
  },
  repeaterRemoveButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-8px',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      justifyContent: 'flex-start'
    }
  },
  repeaterRemoveButton: {
    color: theme.palette.error.main,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      minWidth: 0
    },
    '&& .MuiButton-startIcon': {
      [theme.breakpoints.up('md')]: {
        margin: 0
      }
    }
  },
  repeaterRemoveButtonText: {
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));
