import React, { FC, useState, useEffect, useContext } from 'react';
import { Theme, darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link as MLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
// Components
import { Button, Drawer, Divider, List, ListItem, ListItemText, ListItemIcon, TextField, MenuItem } from '@mui/material';
// icons
import { Close, Menu, HomeOutlined, ExitToAppOutlined } from '@mui/icons-material';
// helpers
import { userLogout } from '../../helpers';
// context
import { UserContext } from '../../context';
// models
import { ILoginUser } from '../../models';
import { TimeZoneContext } from '../../context/timezone';

interface IMobileDrawer {
  user: ILoginUser;
}

export const MobileDrawer: FC<IMobileDrawer> = ({ user }) => {
  const classes = useStyles();

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = isOpen => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(isOpen);
  };

  const isDesktop = useMediaQuery('(min-width: 768px)');

  // close the drawer if the menu gets resized into desktop view
  useEffect(() => {
    if (isDesktop && isDrawerOpen) {
      setDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const history = useHistory();
  const { setUser } = useContext(UserContext);

  const { timeZone, setTimeZone } = useContext(TimeZoneContext);

  const timeZones = [
    { name: 'EST', tzName: 'America/New_York' },
    { name: 'CST', tzName: 'America/Chicago' },
    { name: 'MST', tzName: 'America/Phoenix' },
    { name: 'PST', tzName: 'America/Los_Angeles' }
  ];
  return (
    <div className={classes.mobileWrapper}>
      <Button className={clsx(classes.menuButton, classes.mobileMenuButton)} onClick={toggleDrawer(true)}>
        <Menu fontSize='large' />
      </Button>
      <Drawer anchor='right' open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className={classes.drawerCloseButton}>
          <div className={classes.tzDropdownContainer}>
            <TextField
              fullWidth
              className={classes.tzDropdown}
              select
              onChange={e => {
                setTimeZone(e.target.value);
              }}
              name={`timeZoneMobile`}
              id={`timeZoneMobile`}
              label='Time Zone'
              size='small'
              value={timeZone}
            >
              {timeZones.map((timezone, index) => {
                return (
                  <MenuItem key={`${index}`} value={timezone.tzName}>
                    {timezone.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <Button startIcon={<Close />} color='primary' onClick={toggleDrawer(false)}>
            Close
          </Button>
        </div>
        <div className={clsx(classes.drawerList)} role='presentation' onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            <Divider />
            <MLink
              to={user && user.businessClientIds && user.businessClientIds.length === 1 ? `/business-clients/${user.businessClientIds[0]}/builder` : '/'}
              className={clsx(classes.drawerLink)}
            >
              <ListItem button key='home'>
                <ListItemIcon className={classes.listItemIcon}>
                  <HomeOutlined color='primary' />
                </ListItemIcon>
                <ListItemText primary='Home' className={classes.drawerMobileListItem} />
              </ListItem>
            </MLink>
            <Divider />
            <Divider />
            <ListItem
              button
              className={classes.drawerLink}
              key='logout'
              onClick={async () => {
                await userLogout();
                setUser(null);
                history.push('/login');
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ExitToAppOutlined color='primary' />
              </ListItemIcon>
              <ListItemText primary='Logout' className={classes.drawerMobileListItem} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  mobileWrapper: {
    display: 'block',
    '@media (min-width: 912px)': {
      display: 'none'
    }
  },
  menuButton: {
    height: 78,
    borderRadius: 0,
    boxShadow: 'none',
    color: theme.palette.background.paper,
    fontSize: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    fontWeight: 100,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.3)
    },
    '&.active': {
      backgroundColor: darken(theme.palette.primary.main, 0.3)
    },
    '@media (min-width: 912px)': {
      height: 118
    }
  },
  mobileMenuButton: {
    backgroundColor: '#262626'
  },
  drawerList: {
    padding: theme.spacing(0.5),
    width: 300
  },
  drawerMobileListItem: {
    textTransform: 'uppercase',
    '&& .MuiListItemText-primary': {
      fontSize: theme.spacing(1),
      letterSpacing: theme.spacing(0.2)
    }
  },
  drawerLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&.active': {
      color: darken(theme.palette.primary.main, 0.3)
    }
  },
  tzDropdownContainer: {
    marginTop: theme.spacing(0.5),
    width: '7rem'
  },
  tzDropdown: {
    backgroundColor: theme.palette.common.white
  },
  drawerCloseButton: {
    display: 'flex',
    justifyContent: 'space-between',

    padding: theme.spacing(0.5)
  },
  listItemIcon: {
    minWidth: theme.spacing(2)
  }
}));
