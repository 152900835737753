import React, { FC, useContext } from 'react'
import { ISectionDocuments, ISectionDocumentsFile } from "../../../../../../../../../models";
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { formatShortFriendlyDateWithTime } from '../../../../../../../../../helpers';
import { TimeZoneContext } from '../../../../../../../../../context/timezone';
import IconButton from '@mui/material/IconButton';
import { NotFoundExtensionFileIcon, fileIconDictionary, getDocumentIcon } from './util';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DocumentFileOptionsMenu from '../../../../../../../../../components/document-file-options-menu/DocumentFileOptionsMenu';

interface IEssentialDocProps {
    file: ISectionDocumentsFile;
    section: ISectionDocuments;
    index: number;
}

export const EssentialDoc: FC<IEssentialDocProps> = ({ file, section, index }) => {
    const classes = useStyles();
    const { timeZone } = useContext(TimeZoneContext);

    const SelectedIcon = getDocumentIcon(file);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const openMenuHandler = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };


    const closeMenuHandler = () => {
        setAnchorEl(null);
    };

    return (<div className={classes.docContainer}>
        <div className={classes.docInnerContainer}>
            <div className={classes.docIcon}>
                <SelectedIcon height="35px" width="35px" onClick={(e) => openMenuHandler(e)} />
            </div>
            <div className={classes.docInfo}>

                {file.docContentType === 'OnlyExternalFileUrl' && <h4 title={file.externalFileUrl}><a href={file.externalFileUrl} className={classes.docFileLink} target='_blank'>{file.externalFileUrl}</a></h4>}

                {file.docContentType === 'OnlyFile' && <h4 title={file.fileName} className={classes.docFileName}>{file.fileName}</h4>}

                {file.docContentType === 'FileAndExternalFileUrl' &&
                    <>
                        <h4 title={file.fileName} className={classes.docFileName}>{file.fileName}</h4>
                        <h5 title={file.externalFileUrl}><a className={classes.docFileLink} target='_blank' href={file.externalFileUrl} >{file.externalFileUrl}</a></h5>
                    </>
                }

                <p className={classes.docOtherInformations}>{file.otherInformations}</p>


            </div>
            <div className={classes.docButtons}>

                <IconButton
                    aria-label="more"
                    id="long-button"
                    size='small'
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(e) => openMenuHandler(e)}
                >
                    <MoreVertIcon />
                </IconButton>

                <DocumentFileOptionsMenu
                    closeMenuCallback={closeMenuHandler}
                    anchorEl={anchorEl}
                    documentFile={file}
                    section={section}
                    sectionIndex={index} />

            </div>
        </div>
        <small title={`${file.uploadedBy} | ${formatShortFriendlyDateWithTime(file.uploadedDateTime, timeZone)}`} className={classes.docFileUploadInformation}>{file.uploadedBy} | {formatShortFriendlyDateWithTime(file.uploadedDateTime, timeZone)}</small>
    </div>

    )
}


const useStyles = makeStyles((theme: Theme) => ({
    docContainer: {
        minHeight: '90px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '5px 0 5px 5px',
        border: '1px solid rgb(160 160 160)',
        borderRadius: '7px',
        marginBottom: '9px'
    },
    docInnerContainer: {

        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',

    },
    docFileName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: '0',
        marginBottom: '5px',
        fontSize: '13px'
    },
    docFileLink: {
        margin: '0',

        float: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        /* new css */
        maxWidth: '100%'
    },
    docFileUploadInformation: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '11.5px',
        color: 'rgba(0, 0, 0, 0.6)',
        textAlign: 'center'
    },
    docOtherInformations: {
        fontSize: '13px',
        margin: '3px 0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    docIcon: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        width: '50px',

        alignItems: 'center',
        justifyContent: 'center',
    },
    docInfo: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        margin: '0 5px'
    },
    docButtons: {
    }
}));

export default EssentialDoc;