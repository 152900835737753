import React from 'react';
import { IHealthReimbursementAccount } from '../../../models';
import { Grid, FormControlLabel, FormLabel as MuiLabel, Box, Switch } from '@mui/material';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

interface HealthReimbursementAccountProps {
    data: IHealthReimbursementAccount;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
}

const HealthReimbursementAccount: React.FC<HealthReimbursementAccountProps> = ({
    data,
    handleBlur,
    setFieldValue,
    errors,
}) => {
    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Health Reimbursement Account
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerHealthReimbursementAccount}
                                    id='healthReimbursementAccount.offerHealthReimbursementAccount'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('healthReimbursementAccount.offerHealthReimbursementAccount', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={
                                data?.offerHealthReimbursementAccount ? 'Offered' : 'Not offered'
                            }
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='ER Contribution'
                    name='healthReimbursementAccount.erContribution'
                    value={data?.erContribution}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('healthReimbursementAccount.erContribution', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Contingent Plans'
                    name='healthReimbursementAccount.contingentPlans'
                    value={data?.contingentPlans}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('healthReimbursementAccount.contingentPlans', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalOptions?.defaultPeriodOptions ?? []}
                    isLoading={generalOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.coverageTerminatesOn ?? ''}
                    error={errors?.healthReimbursementAccount?.coverageTerminatesOn}
                    label='When an employee is terminated, coverage terminates on:' id={`healthReimbursementAccount.coverageTerminatesOn`}
                />
            </Grid>
        </Grid>
    );
};

export default HealthReimbursementAccount;
