import React, { createContext, FC, useState } from 'react';
import { SortOptions, ASC_SORT } from '../models';

interface IManageBusinessClientCtx {
  selectedSort: string;
  setSelectedSort: (val: string) => void;
  searchValue: string;
  setSearchValue: (val: string) => void;
  sortDirection: {
    Name?: SortOptions;
    Broker?: SortOptions;
  };
  setSortDirection: (val: { Name?: SortOptions; Broker?: SortOptions }) => void;
}

export const ManageBusinessClientCtx = createContext<IManageBusinessClientCtx>({
  selectedSort: 'Name',
  setSelectedSort: () => {},
  searchValue: '',
  setSearchValue: () => {},
  sortDirection: {},
  setSortDirection: () => {}
});

interface IManageBusinessClientCtxHandlerProps {}

export const ManageBusinessClientCtxHandler: FC<IManageBusinessClientCtxHandlerProps> = ({ children }): JSX.Element => {
  const [sortDirection, setSortDirection] = useState<{
    Name?: SortOptions;
    Broker?: SortOptions;
  }>({
    Name: ASC_SORT
  });
  const [selectedSort, setSelectedSort] = useState<string>('Name');
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <ManageBusinessClientCtx.Provider
      value={{
        selectedSort,
        setSelectedSort,
        searchValue,
        setSearchValue,
        sortDirection,
        setSortDirection
      }}
    >
      {children}
    </ManageBusinessClientCtx.Provider>
  );
};
