import React from 'react';
import { Grid } from '@mui/material';

import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';

const EnrollmentConditions: React.FC = () => {

    const { values: data, handleBlur, setFieldValue, errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    return (
        <Grid container spacing={1}>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalDropdownsOptions?.typeOfEnrollmentOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data.typeOfEnrollment ?? ''}
                    error={errors.typeOfEnrollment}
                    label='Type of Enrollment' id={`typeOfEnrollment`}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalDropdownsOptions?.enrollmentConditionsOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data.enrollmentConditions ?? ''}
                    error={errors.enrollmentConditions}
                    label='Enrollment Conditions' id={`enrollmentConditions`}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalDropdownsOptions?.primaryEnrollmentMethodOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data.primaryEnrollmentMethod ?? ''}
                    error={errors.primaryEnrollmentMethod}
                    label='Primary Enrollment Method' id={`primaryEnrollmentMethod`}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown handleBlur={handleBlur}
                    options={generalDropdownsOptions?.secondaryEnrollmentMethodOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data.secondaryEnrollmentMethod ?? ''}
                    error={errors.secondaryEnrollmentMethod}
                    label='Secondary Enrollment Method' id={`secondaryEnrollmentMethod`}
                />
            </Grid>


        </Grid>
    );
};

export default EnrollmentConditions;
