import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, InputLabel, Select, MenuItem, Grid, Button, TextField, InputAdornment, Divider } from '@mui/material';
import { FilterList, Close, ArrowDropDown, ArrowDropUp, Search } from '@mui/icons-material';

interface IManageUsersFilters {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  selectedStatus: string;
  setSelectedStatus: (val: string) => void;
  setSelectedRole: (val: string) => void;
  selectedRole: string;
  setSearchValue: (val: string) => void;
  searchValue: string;
  handleSearch: (clearSearch?: boolean) => void;
}

export const ManageUsersFilters: FC<IManageUsersFilters> = ({
  isLoading,
  applyFilters,
  selectedStatus,
  setSelectedStatus,
  selectedRole,
  setSelectedRole,
  setSearchValue,
  handleSearch,
  searchValue
}) => {
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const [isMobileFilterButtonOpen, toggleMobileFilter] = useState<boolean>(false);

  const classes = useStyles({ isMobileFilterButtonOpen });
  return (
    <>
      <Button
        color='secondary'
        variant='contained'
        className={classes.mobileButton}
        startIcon={<FilterList />}
        endIcon={isMobileFilterButtonOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => {
          toggleMobileFilter(!isMobileFilterButtonOpen);
        }}
      >
        Filters
      </Button>
      {!isMobileFilterButtonOpen && <Divider className={classes.divider} />}
      <Grid container spacing={1} alignItems='center' className={classes.wrapper}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            size='small'
            fullWidth
            variant='outlined'
            placeholder='Search Users...'
            name='search'
            value={searchValue}
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position='start'
                  className={classes.searchIcon}
                  onClick={() => {
                    if (searchValue.length > 0) {
                      setHasAppliedFilters(true);
                      handleSearch();
                    }
                  }}
                >
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue ? (
                <InputAdornment
                  position='end'
                  className={classes.searchIcon}
                  onClick={() => {
                    setSearchValue('');
                    handleSearch(true);
                  }}
                >
                  <Close />
                </InputAdornment>
              ) : null
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && searchValue.length > 0) {
                handleSearch();
              }
            }}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <FormControl fullWidth variant='outlined' size='small'>
            <InputLabel id='status'>Status</InputLabel>
            <Select disabled={isLoading} name='status' labelId='status' id='status' value={selectedStatus} onChange={({ target: { value } }) => setSelectedStatus(value as string)}>
              {['Active', 'Inactive'].map((status, index) => {
                return (
                  <MenuItem key={`${index}`} value={status}>
                    {status}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <FormControl fullWidth variant='outlined' size='small'>
            <InputLabel htmlFor='role'>Role</InputLabel>
            <Select disabled={isLoading} name='role' labelId='role' id='role' value={selectedRole} onChange={({ target: { value } }) => setSelectedRole(value as string)}>
              {['Administrator', 'Broker', 'Business Client', 'Carrier Partner', 'General Agent', 'Account Manager'].map((role, index) => {
                return (
                  <MenuItem key={`${index}`} value={role.replace(/\s+/g, '')}>
                    {role}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            startIcon={<FilterList />}
            onClick={() => {
              setHasAppliedFilters(true);
              applyFilters();
            }}
          >
            Apply Filters
          </Button>
          {hasAppliedFilters && (
            <Button
              className={clsx(classes.button, classes.resetButton)}
              variant='contained'
              disabled={isLoading}
              startIcon={<Close />}
              onClick={() => {
                setSelectedStatus('Active');
                setHasAppliedFilters(false);
                setSearchValue('');
                setSelectedRole('');
                applyFilters(true);
              }}
            >
              Reset
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles<Theme, { isMobileFilterButtonOpen?: boolean }>((theme: Theme) => ({
  mobileButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  button: {
    height: 40,
    textTransform: 'capitalize',
    width: '100%',
    '@media (min-width: 400px)': {
      width: '48%'
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  resetButton: {
    '@media (min-width: 400px)': {
      marginLeft: 11
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  wrapper: ({ isMobileFilterButtonOpen }) =>
    isMobileFilterButtonOpen
      ? {
          marginTop: 10,
          marginBottom: 10,
          display: 'flex'
        }
      : {
          display: 'none',
          marginBottom: theme.spacing(1),
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
            marginBottom: theme.spacing(1)
          }
        },
  divider: {
    display: 'block',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  }
}));
