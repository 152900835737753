import { FormControl, InputLabel, Box, Grid, TextField, FormLabel, FormControlLabel, Switch, FormHelperText, RadioGroup, Radio, Select, MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { IBroker, IBusinessClientDetailFormValues, IDropdownResponse, IUser } from '../../../../../../../../models';
import { useSharedStyles } from '../../../../../shared/styles';
import Autocomplete from '@mui/material/Autocomplete';
import useBrokers from '../../../../../hooks/useBrokers';
import { PerformantTextField } from '../../../../../../../../components/inputs/PerformantTextField';
import { Accordion, FormLabel as CustomLabel } from '../../../../../../../../components';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import EligibilityRules from './EligibilityRules/EligibilityRules';
import EligibilityRulesDeductionTable from './EligibilityRulesDeductionTable/EligibilityRulesDeductionTable';
import clsx from 'clsx';
import useUsers from '../../../../../hooks/useUsers';
import useSystemOfRecords from '../../../../../hooks/useSystemOfRecords';
import { useEntitiesDropdownsOptionsCollection } from '../../../../../../../../context/EntitiesDropdownsOptionsCollectionCtx';
import CompanyLogo from './CompanyLogo/CompanyLogo';
import Locations from './Locations/Locations';

export default function CompanyInfo() {

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest?.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    const states = generalOptions?.stateOptions ?? [];
    const statesLoading = generalOptionsLoading;

    const projectTypes = generalOptions?.projectTypesOptions ?? [];

    const { brokers, users, systemOfRecords } = useEntitiesDropdownsOptionsCollection();

    const brokersLoading = brokers.length === 0;
    const isLoadingUsers = users.length === 0;
    const systemOfRecordsLoading = systemOfRecords.length === 0;

    const classes = useSharedStyles();
    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const validateAction = menusSubMenusValidationStatusMap.get('company_info');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    const [shouldCompanyNameUsedInCommunication, setShouldCompanyNameUsedInCommunication] = React.useState(values?.shouldCompanyNameUsedInCommunication ?? false);

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>

            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <Box marginBottom='1rem'>
                <Box marginBottom='1rem'>
                    <FormLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                        Automated Employee Communication
                    </FormLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                color='primary'
                                checked={values.automatedEmployeeCommunication}
                                id='automatedEmployeeCommunication'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('automatedEmployeeCommunication', e.target.checked);
                                }}
                                onBlur={handleBlur}
                            />
                        }
                        label={values.automatedEmployeeCommunication ? 'ON' : 'OFF'}
                    />

                </Box>
                <Grid container spacing={1} alignItems='flex-start'>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            required
                            size='small'
                            autoComplete='nope'
                            label='Company Name'
                            name='name'
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('name', e.target.value)}
                            error={Boolean(touched && errors.name)}
                            helperText={touched && errors.name}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl component='fieldset'>
                            <FormLabel component='legend'>Should the Company Name above be used in communication to employees?</FormLabel>
                            <RadioGroup
                                row
                                aria-label={`shouldCompanyNameUsedInCommunication`}
                                name={`shouldCompanyNameUsedInCommunication`}
                                value={shouldCompanyNameUsedInCommunication}
                                color='primary'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.value === 'true') {
                                        setShouldCompanyNameUsedInCommunication(true);
                                        setFieldValue('shouldCompanyNameUsedInCommunication', true);
                                    } else {
                                        setShouldCompanyNameUsedInCommunication(false);
                                        setFieldValue('shouldCompanyNameUsedInCommunication', false);
                                    }
                                }}
                            >
                                <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                                <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>

                        {!shouldCompanyNameUsedInCommunication && <TextField
                            fullWidth
                            required
                            size='small'
                            autoComplete='nope'
                            label='Company Name used in communications'
                            name='companyNameUsedInCommunication'
                            value={values.companyNameUsedInCommunication}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('companyNameUsedInCommunication', e.target.value)}
                            error={Boolean(touched && errors.companyNameUsedInCommunication)}
                            helperText={touched && errors.companyNameUsedInCommunication}
                        />}

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant='outlined' required error={touched && errors.brokerName ? true : false}>
                            <Autocomplete
                                value={{
                                    name: values.brokerName
                                } as IBroker}
                                classes={{
                                    paper: classes.paperBorder
                                }}
                                onChange={(event, newValue: IBroker) => {
                                    if (newValue) {
                                        setFieldValue('brokerName', newValue.name);
                                    } else {
                                        setFieldValue('brokerName', '');
                                    }
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                loading={brokersLoading}
                                id='brokerName'
                                options={brokers}
                                getOptionLabel={(broker: IBroker) => {
                                    // Value selected with enter, right from the input
                                    if (typeof broker === 'string') {
                                        return broker;
                                    }
                                    return broker.name;
                                }}
                                renderOption={(props, option: IBroker) => {
                                    return <li {...props}>{option.name}</li>;
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        required
                                        size='small'
                                        autoComplete='off'
                                        error={Boolean(touched && errors.brokerName)}
                                        onBlur={handleBlur}
                                        label='Assign Broker'
                                        variant='outlined'
                                    />
                                )}
                            />

                            {touched && errors.brokerName && <FormHelperText>{errors.brokerName}</FormHelperText>}
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant='outlined' size='small' required error={Boolean(touched && errors.accountManagerUserId)}>


                            <InputLabel id={`accountManagerUserId`} className={clsx(classes.outlinedLabel)}>
                                {isLoadingUsers ? 'Loading...' : 'Account Manager'}
                            </InputLabel>
                            <Select
                                name={`accountManagerUserId`}
                                disabled={isLoadingUsers}
                                labelId={`accountManagerUserId`}
                                id={`accountManagerUserId`}
                                size='small'
                                value={values.accountManagerUserId}
                                onBlur={handleBlur}
                                onChange={e => setFieldValue('accountManagerUserId', e.target.value as string)}
                            >
                                {users?.map((user: IUser, index) => {
                                    return (
                                        <MenuItem key={`${index}`} value={user.userId}>
                                            {user.firstName} {user.lastName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText error={Boolean(touched && errors?.accountManagerUserId)}>{touched && errors?.accountManagerUserId}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Field name={`projectType`}>
                            {({ field }) => {
                                return (
                                    <FormControl fullWidth variant='outlined' size='small' required error={!!errors?.projectType}>

                                        <InputLabel id={`project-type`} className={clsx(classes.outlinedLabel)}>
                                            Project Type
                                        </InputLabel>

                                        <Select
                                            {...field}
                                            name='project-type'
                                            labelId='project-type'
                                            id='project-type'
                                            size='small'
                                            value={values.projectType}
                                            onBlur={handleBlur}
                                            onChange={e => setFieldValue('projectType', e.target.value as string)}
                                        >
                                            {projectTypes.map((pt: IDropdownResponse, index) => {
                                                return (
                                                    <MenuItem key={`${index}`} value={pt.value}>
                                                        {pt.description}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText error={!!errors?.projectType}>{errors?.projectType}</FormHelperText>
                                    </FormControl>
                                );
                            }}
                        </Field>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant='outlined' size='small' required error={touched && errors.systemOfRecord ? true : false}>

                            <InputLabel id={`system-of-record`} className={clsx(classes.outlinedLabel)}>
                                System of Record
                            </InputLabel>
                            <Select
                                name={`system-of-record`}
                                labelId={`system-of-record`}
                                id={`system-of-record`}
                                value={values.systemOfRecord}
                                size='small'
                                onBlur={handleBlur}
                                onChange={({ target: { value } }) => setFieldValue('systemOfRecord', value)}
                            >
                                {!systemOfRecordsLoading &&
                                    systemOfRecords.map((record, index) => {
                                        return (
                                            <MenuItem key={`${index}`} value={record.name}>
                                                {record.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            {touched && errors.systemOfRecord && <FormHelperText>{errors.systemOfRecord}</FormHelperText>}
                        </FormControl>
                    </Grid>



                </Grid>
            </Box>

            <Accordion title='Client Details' component='h3' variant='h4'>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant='outlined' size='small' error={errors?.situsState ? true : false}>
                            <CustomLabel label='Situs State' id={`situs-state`} />
                            <Select
                                name={`situs-state`}
                                labelId={`situs-state`}
                                onChange={e => setFieldValue('situsState', e.target.value)}
                                id={`situs-state`}
                                size='small'
                                value={values.situsState}
                                onBlur={handleBlur}
                                disabled={statesLoading}
                            >
                                {!statesLoading &&
                                    states.map((state: IDropdownResponse, index) => {
                                        return (
                                            <MenuItem key={`${index}`} value={state.value}>
                                                {state.description}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            <FormHelperText error={errors?.situsState ? true : false}>{errors?.situsState}</FormHelperText>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={6} md={8}>
                        <PerformantTextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            autoComplete='nope'
                            label='Street Address'
                            name='street'
                            value={values.street}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('street', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <PerformantTextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            autoComplete='nope'
                            label='Street Address Line 2'
                            name='streetLine2'
                            value={values.streetLine2}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('streetLine2', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <PerformantTextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            autoComplete='nope'
                            label='City'
                            name='city'
                            value={values.city}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('city', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <PerformantTextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            autoComplete='nope'
                            label='State / Province'
                            name='state'
                            value={values.state}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('state', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <PerformantTextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            autoComplete='nope'
                            label='Postal / ZIP Code'
                            name='postalCode'
                            value={values.postalCode}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('postalCode', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PerformantTextField
                            fullWidth
                            variant='outlined'
                            size='small'
                            autoComplete='nope'
                            label='Tax ID'
                            name='taxId'
                            value={values.taxId}
                            onBlur={handleBlur}
                            onChange={e => setFieldValue('taxId', e.target.value)}
                        />
                    </Grid>

                </Grid>
            </Accordion>

            <Accordion title='Eligibility Rules' hasErrors={errors?.eligibilityRules !== undefined} component='h3' variant='h4'>
                <EligibilityRules />
                <EligibilityRulesDeductionTable />
            </Accordion>

            <Accordion title='Locations' component='h3' variant='h4'>
                <Locations />
            </Accordion>

            <Accordion title='Company Logo' hasErrors={errors?.logoUrl !== undefined} component='h3' variant='h4'>
                <CompanyLogo />
            </Accordion>


        </div>
    );
}
