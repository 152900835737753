import React, { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarProps } from '@mui/material/Snackbar';

interface IToastProps extends SnackbarProps {
  autoHideDuration?: number | null;
  id: string;
  message: React.ReactNode | string;
  open: boolean;
  onClose: (e: React.SyntheticEvent, reason?: string) => void;
  variant: 'error' | 'info' | 'success' | 'warning';
  action?: React.ReactNode;
}

export const Toast: FC<IToastProps> = ({ autoHideDuration = 3000, id, message, onClose, open, variant, action, ...props }) => {
  const classes = useStyles();
  return (
    <Snackbar
      className={classes.toast}
      {...props}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={autoHideDuration}
      id={id}
      onClose={onClose}
      open={open}
    >
      <Alert onClose={onClose} severity={variant} variant='filled' action={action}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toast: {
    // needed so its above the footer
    bottom: theme.spacing(5)
  }
}));
