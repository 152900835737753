import { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
// Components
import { ClientCard } from './ClientCard';
import { NoResultsEnrollment } from './NoResultsEnrollment';
import { Box, AppBar, Tab, Tabs, TextField, InputAdornment, Grid, Button, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Search, FilterList } from '@mui/icons-material';
import { Loader, Page, PageTitle } from '../../components';
import { BrokersAutocomplete } from '../../components/inputs/BrokersAutocomplete';
// context
import { UserContext } from '../../context';
// hooks
import { useDebounce } from '../../hooks';
import { EUserRoles, IBrokerDashboard, IBroker, EBrokerDashboardStatus, BrokerDashboardStatus } from '../../models';
// fetch
import { getBrokersDashboard } from '../../fetch';

export const Home = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState<{
    value: number;
    type: BrokerDashboardStatus;
  } | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueDebounced, setSearchValueDebounced] = useState('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const [selectedBroker, setSelectedBroker] = useState<string>('');
  const [dashboardData, setDashboardData] = useState<IBrokerDashboard[]>([]);
  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [recordCount, setRecordCount] = useState<number>(0);

  const isMobile = useMediaQuery('(max-width: 960px)');
  const isXSMobile = useMediaQuery('(max-width: 500px)');

  useDebounce(
    () => {
      setSearchValueDebounced(searchValue);
    },
    500,
    [searchValue]
  );

  const fetchClients = async (shouldLoad?: boolean, nextPage?: number, selectedBrokerOption?: string) => {
    if (shouldLoad) {
      setLoading(true);
    }
    try {
      const brokerOption = brokers.find(b => b.name === selectedBrokerOption);
      const res = await getBrokersDashboard({
        type: selectedTab.type,
        brokerId: brokerOption ? brokerOption.brokerId : undefined,
        search: searchValueDebounced,
        page: nextPage || 1,
        perPage: 25
      });
      if (searchValueDebounced) {
        setDashboardData(res.records);
      } else {
        setDashboardData(shouldLoad ? res.records : [...(dashboardData || []), ...res.records]);
      }
      setRecordCount(res.totalRecordCount);
      setPage(nextPage || 1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSelectedTab = async () => {
    setLoading(true);
    try {
      let res = await getBrokersDashboard({ type: EBrokerDashboardStatus.OPEN_ENROLLMENT });
      if (res.records.length > 0) {
        setSelectedTab({
          value: 0,
          type: EBrokerDashboardStatus.OPEN_ENROLLMENT
        });
      } else if (res.records.length === 0) {
        const res = await getBrokersDashboard({ type: EBrokerDashboardStatus.NEW_HIRE });

        if (res.records.length > 0) {
          setSelectedTab({
            value: 1,
            type: EBrokerDashboardStatus.NEW_HIRE
          });
        } else {
          setSelectedTab({
            value: 2,
            type: EBrokerDashboardStatus.ALL
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSelectedTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTab) {
      fetchClients(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueDebounced, selectedTab]);

  // send user to their home dashboard if they happen to hit the broker dashboard
  if (user && user.businessClientIds && user.businessClientIds.length === 1) {
    // business clients with only one client go to employees which is their dashboard
    return <Redirect to={`/business-clients/${user.businessClientIds[0]}/builder`} />;
  }

  return (
    <Page title='Home'>
      <PageTitle title={`Welcome to your Enrollment Portal, ${user.firstName} ${user.lastName}`} showDivider={false} />
      {selectedTab && (
        <AppBar position='static' color='default' component='div' className={classes.tabBar}>
          <Tabs
            value={selectedTab.value}
            indicatorColor='primary'
            textColor='primary'
            onChange={(_, value) => {
              setSelectedTab({
                value,
                type: value === 0 ? EBrokerDashboardStatus.OPEN_ENROLLMENT : value === 1 ? EBrokerDashboardStatus.NEW_HIRE : 'All'
              });
            }}
            aria-label='dashboard-tabs'
            centered={isMobile}
          >
            <Tab
              disabled={isLoading}
              label={isXSMobile ? 'Open' : 'Open Enrollment'}
              classes={{ root: classes.tabRoot, textColorPrimary: classes.tabText, selected: classes.tabSelected }}
            />
            <Tab
              disabled={isLoading}
              label={isXSMobile ? 'New Hires' : 'New Hire Mgt'}
              classes={{ root: classes.tabRoot, textColorPrimary: classes.tabText, selected: classes.tabSelected }}
            />
            <Tab disabled={isLoading} label='All' classes={{ root: classes.tabRoot, textColorPrimary: classes.tabText, selected: classes.tabSelected }} />
          </Tabs>
        </AppBar>
      )}

      <Grid container spacing={1} className={classes.searchWrapper}>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            variant='outlined'
            fullWidth
            placeholder='Search Accounts/Clients...'
            name='search'
            value={searchValue}
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' className={classes.searchIcon}>
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue ? (
                <InputAdornment
                  position='end'
                  className={classes.searchIcon}
                  onClick={() => {
                    setSearchValue('');
                    setSearchValueDebounced('');
                  }}
                >
                  <Close />
                </InputAdornment>
              ) : null
            }}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
          />
        </Grid>
        {user && user.roles.includes(EUserRoles.ADMIN) && (
          <>
            <Grid item xs={12} sm={2} md={4}>
              <BrokersAutocomplete
                isDisabled={isLoading}
                brokerValue={selectedBroker}
                setBrokerValue={val => setSelectedBroker(val)}
                label='Broker'
                handleBrokers={val => setBrokers(val)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                color='primary'
                variant='contained'
                className={classes.button}
                startIcon={<FilterList />}
                disabled={isLoading}
                onClick={() => {
                  setHasAppliedFilters(true);
                  fetchClients(true, 1, selectedBroker);
                }}
              >
                Apply Filters
              </Button>
              {hasAppliedFilters && (
                <Button
                  className={clsx(classes.button, classes.resetButton)}
                  variant='contained'
                  color='inherit'
                  startIcon={<Close />}
                  onClick={() => {
                    setSearchValue('');
                    setSelectedBroker('');
                    setHasAppliedFilters(false);
                    fetchClients(true, 1, '');
                  }}
                >
                  Reset
                </Button>
              )}
            </Grid>
          </>
        )}
      </Grid>
      {isLoading && <Loader position='top-center' type='inline' />}
      {!isLoading && selectedTab && (
        <>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => fetchClients(false, page + 1, selectedBroker)}
            // searchValue, then don't allow pagination scroll
            hasMore={searchValue ? false : (dashboardData && dashboardData.length) < recordCount}
            threshold={300}
            loader={
              <Box marginTop='2rem' key={0}>
                <Loader position='centered' />
              </Box>
            }
          >
            <Grid container spacing={1}>
              {dashboardData &&
                dashboardData.map((item, index) => {
                  return (
                    <Grid key={`${index}`} item xs={12} md={6}>
                      <ClientCard client={item} totalCount={item.employeeStatusCounts.reduce<number>((acc, curr) => acc + curr.count, 0)} />
                    </Grid>
                  );
                })}
            </Grid>
          </InfiniteScroll>
          {dashboardData && dashboardData.length === 0 && <NoResultsEnrollment message='No clients are available.' />}
        </>
      )}
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  tabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  },
  tabRoot: {
    minWidth: 0,
    fontSize: 18,
    padding: `15px 20px`,
    textTransform: 'none'
  },
  tabText: {
    color: theme.palette.grey[400]
  },
  tabSelected: {
    fontWeight: 'bold'
  },
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  },
  button: {
    height: 40,
    textTransform: 'capitalize',
    width: '48%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  resetButton: {
    marginLeft: 11,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  searchWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5)
  }
}));
