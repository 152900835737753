import { axios, getUserAccessTokenCookie } from '../helpers';
import { IForgotPasswordPost, IResetPasswordPost, IUserRes, IUserDetail, IUserRole, IUserPost, IUserPut } from '../models';

export const getUsers = async (filters: {
  page?: number;
  perPage?: number | string;
  sortBy?: string;
  sortDirection?: string;
  role?: string;
  isActive: boolean;
  search?: string;
}): Promise<IUserRes> => {
  try {
    const res = await axios.get(
      `Users`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      {
        ...filters,
        UserRole: filters.role
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getUser = async (id: string): Promise<IUserDetail> => {
  try {
    const res = await axios.get(`Users/${id}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getUserRoles = async (): Promise<IUserRole[]> => {
  try {
    const res = await axios.get(`Users/Roles`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const createUser = async (data: IUserPost) => {
  try {
    const res = await axios.post('Users', data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const updateUser = async (id: string, data: IUserPut) => {
  try {
    const res = await axios.put(`Users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

/**
 * Send reset link
 * @param email string
 * @returns IForgotPasswordPost
 */
export const sendResetLink = async (data: IForgotPasswordPost): Promise<boolean> => {
  try {
    const res = await axios.post('ForgotPassword', data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
/**
 * POST password reset
 * @param email string
 * @returns IResetPasswordPost
 */
export const resetPassword = async (data: IResetPasswordPost) => {
  try {
    const res = await axios.post('ResetPassword', data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
