import { FC, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';
// models
import { IBroker } from '../../models';
// fetch
import { getBrokers } from '../../fetch';

interface IBrokersAutocompleteProps {
  brokerValue: string;
  setBrokerValue: (val: string) => void;
  hasError?: boolean;
  handleBlur?: (e: any) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  label: string;
  handleBrokers?: (brokers: IBroker[]) => void;
}

export const BrokersAutocomplete: FC<IBrokersAutocompleteProps> = ({ brokerValue, setBrokerValue, hasError, handleBlur, isRequired, label, handleBrokers, isDisabled }) => {
  const classes = useStyles();

  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const [brokersLoading, setBrokersLoading] = useState<boolean>(true);

  const fetchBrokers = async () => {
    try {
      const res = await getBrokers({
        perPage: '2147483647' // get all records
      });
      if (res.records) {
        setBrokers(res.records);
        if (handleBrokers) {
          handleBrokers(res.records);
        }
      } else {
        console.error(`No 'records' on brokers response.`);
      }
    } catch (error) {
      console.error(error);
    }
    setBrokersLoading(false);
  };
  useEffect(() => {
    fetchBrokers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Autocomplete
      value={{ name: brokerValue } as IBroker}
      classes={{
        paper: classes.paperBorder
      }}
      onChange={(event, newValue: IBroker) => {
        if (newValue) {
          setBrokerValue(newValue.name);
        } else {
          setBrokerValue('');
        }
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      loading={brokersLoading}
      id='brokerName'
      options={brokers}
      disabled={isDisabled}
      getOptionLabel={(broker: IBroker) => {
        // Value selected with enter, right from the input
        if (typeof broker === 'string') {
          return broker;
        }
        return broker.name;
      }}
      renderOption={(props, option: IBroker) => {
        return <li {...props}>{option.name}</li>;
      }}
      renderInput={params => (
        <TextField {...params} required={isRequired || false} size='small' autoComplete='off' error={hasError || false} onBlur={handleBlur} label={label} variant='outlined' />
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  content: {
    marginTop: theme.spacing(1)
  },
  bold: {
    fontWeight: 'bold'
  },
  cardContent: {
    paddingTop: 0
  },
  cardActions: {
    '@media (max-width: 450px)': {
      flexDirection: 'column'
    }
  },
  saveButton: {
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  cancelButton: {
    '@media (max-width: 450px)': {
      width: '100%',
      marginTop: theme.spacing(0.5),
      marginLeft: `0 !important`
    }
  },
  paperBorder: {
    border: `1px solid ${theme.palette.grey[300]}`
  },
  subHeader: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    marginBottom: '-16px'
  },
  subHeaderOpen: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    marginBottom: '-20px'
  },
  divider: {
    marginTop: theme.spacing(1)
  },
  addClassButton: {
    marginTop: theme.spacing(1)
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  removeButtonWrapper: {
    textAlign: 'right',
    marginTop: '-16px',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      marginTop: 0
    }
  },
  removeButton: {
    color: theme.palette.error.main
  },
  classText: {
    margin: theme.spacing(0.5, 0)
  },
  inputAdornment: {
    marginRight: 3
  }
}));
