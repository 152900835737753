import React from 'react';
import { Box, Grid, TextField, FormLabel as MuiLabel, FormControlLabel, Switch, Checkbox } from '@mui/material';
import { useSharedStyles } from '../../../../../../shared/styles';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import { IAccidentInsurance } from '../../../../../../../../../models'; // Use the correct model interface
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import useDefaultDropdownsOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const AccidentCoverage: React.FC = () => {
    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: IAccidentInsurance = values.accidentInsurance;

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';
    
    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';
  
    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Accident Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerAccidentCoverage}
                                    id='accidentInsurance.offerAccidentCoverage'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('accidentInsurance.offerAccidentCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerAccidentCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                    <SectionsFilesList category='AccidentCoverage' />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Accident Carrier'
                    name='accidentInsurance.accidentCarrier'
                    value={data?.accidentCarrier}
                    onChange={(e) => {
                        setFieldValue('accidentInsurance.accidentCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.accidentInsurance?.accidentCarrier} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Previous Accident Carrier'
                    name='accidentInsurance.previousAccidentCarrier'
                    value={data?.previousAccidentCarrier}
                    onChange={(e) => {
                        setFieldValue('accidentInsurance.previousAccidentCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.accidentInsurance?.previousAccidentCarrier} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Highlights'
                    name='accidentInsurance.highlights'
                    value={data?.highlights}
                    onChange={(e) => {
                        setFieldValue('accidentInsurance.highlights', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors?.accidentInsurance?.highlights} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControlLabel
                    value={data?.accidentTaxStatus}
                    control={
                        <Checkbox
                            color='primary'
                            checked={data?.accidentTaxStatus}
                            name='accidentInsurance.accidentTaxStatus'
                            onChange={(_, checked: boolean) => setFieldValue('accidentInsurance.accidentTaxStatus', checked)}
                        />
                    }
                    label={'Accident Tax Status'}
                />
            </Grid>
            

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.accidentOffering ?? ''}
                    error={errors?.accidentInsurance?.accidentOffering}
                    label='Accident Offering'
                    id='accidentInsurance.accidentOffering'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.accidentPlanTypeOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.accidentPlanType ?? ''}
                    error={errors?.accidentInsurance?.accidentPlanType}
                    label='Accident Plan Type'
                    id='accidentInsurance.accidentPlanType'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.accidentPortability ?? ''}
                    error={errors?.accidentInsurance?.accidentPortability}
                    label='Accident Portability'
                    id='accidentInsurance.accidentPortability'
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    label='Accident Notes'
                    name='accidentInsurance.accidentNotes'
                    value={data?.accidentNotes}
                    onChange={(e) => {
                        setFieldValue('accidentInsurance.accidentNotes', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.accidentInsurance?.accidentNotes} />
            </Grid>
        </Grid>
    );
};

export default AccidentCoverage;
