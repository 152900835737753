import FollowTheSignsIcon from "@mui/icons-material/FollowTheSignsRounded";
import PortraitIcon from "@mui/icons-material/PortraitRounded";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettingsRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgentRounded";
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import Diversity3Icon from "@mui/icons-material/Diversity3Rounded";
import FolderSharedIcon from '@mui/icons-material/FolderSharedRounded';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';

import HomeIcon from '@mui/icons-material/HomeRounded';

import { MenuItem } from "./types";
import { instanceFontIcon } from "../../../../../components/font-icon";

const menuData: MenuItem[] = [
    {
        id: 'home',
        iconComponent: HomeIcon
    },
    {
        title: "Client Details",
        id: "client-details",
        iconComponent: PortraitIcon,
        primaryIconElement: instanceFontIcon('client_profile_2'),
        secondaryIconElement:instanceFontIcon('client_profile_2_filled'),
        subMenu: [
            {
                title: "Company Info",
                id: 'company_info'
            },
            {
                title: "Dates",
                id: "dates"
            },
            {
                title: "Contacts",
                id: "contacts"
            },
            {
                title: "Classes",
                id: "classes"
            },
            {
                title: 'Industry',
                id: 'industry'
            },
            {
                title: 'Company Preferences',
                id: 'company_preferences'
            }]
    },
    {
        title: "Enrollment Planner",
        id: "enrollment-planner",
        iconComponent: FollowTheSignsIcon,
        primaryIconElement: instanceFontIcon('enrollment_overview'),
        secondaryIconElement:instanceFontIcon('enrollment_overview_filled'),
        subMenu: [
            {
                title: "Census",
                id: "census"
            },
            {
                title: "Ess Docs",
                id: "ess_docs"
            },

        ]
    },
    {
        title: "Enrollment Strategy",
        id: "enrollment-strategy",
        iconComponent: RouteOutlinedIcon,
        primaryIconElement: instanceFontIcon('enrollment_strategy'),
        secondaryIconElement:instanceFontIcon('enrollment_strategy_filled'),
        subMenu: [
            {
                title: "Enrollment Preferences",
                id: "enrollment_preferences"
            },
            {
                title: "Scheduling",
                id: "scheduling"
            },
            {
                title: "Locations & Logistics",
                id: "locations_&_logistics"
            },
        ]
    },
    {
        title: "Product Details",
        id: "product-details",
        iconComponent: DisplaySettingsIcon,
        primaryIconElement: instanceFontIcon('products_offered_2'),
        secondaryIconElement:instanceFontIcon('products_offered_2_filled'),
        subMenu: [
            {
                title: "Medical",
                id: "medical"
            },
            {
                title: "Financial",
                id: "financial"
            },
            {
                title: "Ancillary",
                id: "ancillary"
            },
            {
                title: "Worksite",
                id: "worksite"
            },
            {
                title: "Supplemental",
                id: "supplemental"
            }
        ]
    },
    {
        title: "HR Preferences",
        id: "hr-preferences",
        iconComponent: Diversity3Icon,
        primaryIconElement: instanceFontIcon('hr_strategy_3'),
        secondaryIconElement:instanceFontIcon('hr_strategy_3_filled'),
        subMenu: [{
            title: "Campaign Strategy",
            id: "campaign_strategy"
        }]
    },
    {
        title: "Design Center",
        id: "design-center",
        iconComponent: DesignServicesOutlinedIcon,
        primaryIconElement: instanceFontIcon('design_center'),
        secondaryIconElement:instanceFontIcon('design_center_filled'),
    },
    {
        title: "Post Enrollment Data",
        id: "post-enrollment-data",
        iconComponent: FolderSharedIcon,
        primaryIconElement: instanceFontIcon('post_enrollment_reporting'),
        secondaryIconElement:instanceFontIcon('post_enrollment_reporting_filled'),
        subMenu: [
            {
                title: "Enrollment Engagement Numbers",
                id: "enrollment_engagement_numbers"
            },
            {
                title: "Product Partipation",
                id: "product_partipation"
            },
            {
                title: "Challenges and Solutions",
                id: "challenges_and_solutions"
            }
        ]
    }
];

export default menuData;