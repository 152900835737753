import React, { FC, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { Add, Close, RecentActors, Save } from '@mui/icons-material';
// Components
import { Loader, Modal, Toast } from '../../../components';
import { Button, Card, CardActions, CardContent, CardHeader, Fade, FormHelperText, Grid, Paper, TextField, Typography } from '@mui/material';
// models
import { IBroker } from '../../../models';
// fetch
import { createBroker, storeBrokerCompanyLogo, updateBroker } from '../../../fetch';
import { FileDrop, FileUploaded } from '../../../components/file';
import { useDropzone } from 'react-dropzone';

interface IBrokerModal {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  currentBroker: IBroker | null;
}

const BrokerSchema = Yup.object().shape({
  name: Yup.string().max(255, 'Max 255 characters').required('Required'),
  logoUrl: Yup.string()
});

export const BrokerModal: FC<IBrokerModal> = ({ open, onClose, onSave, currentBroker }) => {
  const classes = useStyles();
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [previewError, setPreviewError] = useState<string>('');
  const [brokerLogo, setBrokerLogo] = useState<File | undefined>(undefined);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: currentBroker ? currentBroker.name : '',
          logoUrl: currentBroker ? currentBroker.logoUrl || '' : ''
        }}
        validationSchema={BrokerSchema}
        onSubmit={async (values, actions) => {
          try {
            if (currentBroker) {
              const res = await updateBroker(currentBroker.brokerId, values);
              if (res.Errors && Object.values(res.Errors)[0] && Object.values(Object.values(res.Errors)[0])[0]) {
                setErrorMessage(Object.values(Object.values(res.Errors)[0])[0] as string);
                return showError(true);
              }
              showSuccess(true);
              setSuccessMessage('Broker Updated!');
              onClose();
              onSave();
              actions.resetForm();
            } else {
              const res = await createBroker({
                name: values.name,
                logoUrl: ''
              });
              if (res.Errors && Object.values(res.Errors)[0] && Object.values(Object.values(res.Errors)[0])[0]) {
                setErrorMessage(Object.values(Object.values(res.Errors)[0])[0] as string);
                return showError(true);
              }
              if (brokerLogo) {
                const updateRes = await updateBroker(res, {
                  name: values.name,
                  logoUrl: values.logoUrl
                });
                if (updateRes.Errors && Object.values(updateRes.Errors)[0] && Object.values(Object.values(updateRes.Errors)[0])[0]) {
                  setErrorMessage(Object.values(Object.values(updateRes.Errors)[0])[0] as string);
                  return showError(true);
                }
              }
              showSuccess(true);
              setSuccessMessage('Broker Added!');
              onClose();
              onSave();
              actions.resetForm();
            }
          } catch (error) {
            console.log(error);
            if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
              setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
            }
            showError(true);
          }
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, setFieldTouched, handleSubmit, dirty, isValid, handleBlur }) => {
          /* eslint-disable react-hooks/rules-of-hooks */
          const imageDZUrlEdit = (acceptedFile: File) => {
            const imageUrl = URL.createObjectURL(acceptedFile);
            const img = document.createElement('img');
            img.src = imageUrl;
            img.onload = async () => {
              // icon needs a minimum height of 140px
              if (img.height < 140) {
                setFieldValue('logoUrl', '');
                setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
              } else {
                const create = new FormData();
                create.append('image', acceptedFile);
                try {
                  const imageUrl = await storeBrokerCompanyLogo(currentBroker.brokerId, create);
                  setFieldValue('logoUrl', imageUrl);
                } catch (error) {
                  if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
                    setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
                  }
                  showError(true);
                }
                setPreviewError('');
              }
            };
          };
          const imageDZUrlAdd = (acceptedFile: File) => {
            const imageUrl = URL.createObjectURL(acceptedFile);
            const img = document.createElement('img');
            img.src = imageUrl;
            img.onload = async () => {
              // icon needs a minimum height of 140px
              if (img.height < 140) {
                setBrokerLogo(null);
                setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
              } else {
                setBrokerLogo(acceptedFile);
                setFieldValue('logoUrl', imageUrl);
                setPreviewError('');
              }
            };
          };
          const imageDZUrl = useDropzone({
            accept: '.jpg, .jpeg, .png',
            onDrop: acceptedFiles => {
              if (acceptedFiles.length > 0 && currentBroker) {
                imageDZUrlEdit(acceptedFiles[0]);
              } else if (acceptedFiles.length > 0) {
                imageDZUrlAdd(acceptedFiles[0]);
              }
            }
          });
          /* eslint-enable react-hooks/rules-of-hooks */
          return (
            <Modal
              open={open}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
              maxWidth='sm'
            >
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <Typography variant='h4'>{currentBroker ? 'EDIT BROKER' : 'ADD NEW BROKER'}</Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Card elevation={0} variant='outlined' className={classes.marginBottom}>
                          <CardHeader avatar={<RecentActors />} title='BROKER INFO' className={classes.primaryHeader} />
                          <CardContent className={classes.cardContent}>
                            <Paper elevation={0} square>
                              <div className={classes.column}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      autoComplete='nope'
                                      label='Broker Name'
                                      name='name'
                                      className={classes.spacingInput}
                                      value={values.name}
                                      onBlur={handleBlur}
                                      onChange={e => setFieldValue('name', e.target.value)}
                                      error={Boolean(setFieldTouched.name && errors.name)}
                                      helperText={setFieldTouched.name && errors.name}
                                    />
                                  </Grid>
                                </Grid>
                                <Typography variant='h4' component='h4' className={classes.subHeader} color='primary'>
                                  Broker Logo
                                </Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    {values.logoUrl && (
                                      <FileUploaded
                                        className={classes.logoUploaded}
                                        id='broker-logo-image-preview'
                                        onRemove={() => {
                                          setFieldValue(`logoUrl`, '');
                                          setFieldTouched('logoUrl', true);
                                        }}
                                        src={values.logoUrl}
                                        backgroundColor={'#000'}
                                      />
                                    )}
                                    {!values.logoUrl && (
                                      <>
                                        <FileDrop id='broker-logo-image-drop' {...imageDZUrl} title='Add Broker Logo' className={classes.logoDrop}>
                                          <Typography variant='body1'>
                                            Drag and drop a PNG or JPG file or{' '}
                                            <a href='/#' onClick={e => e.preventDefault()}>
                                              browse and pick
                                            </a>{' '}
                                            a file.
                                          </Typography>
                                          <Typography variant='body2'>
                                            Recommended transparent background with white logo.
                                            <br />
                                            Minimum height of 140 pixels.
                                          </Typography>
                                          {previewError && <FormHelperText error={true}>{previewError}</FormHelperText>}
                                        </FileDrop>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </Paper>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                  <CardActions className={classes.cardActions}>
                    <Button
                      className={classes.saveButton}
                      disabled={!dirty || isSubmitting || !isValid}
                      type='submit'
                      startIcon={currentBroker ? <Save /> : <Add />}
                      variant='contained'
                      color='primary'
                    >
                      {currentBroker ? 'Save' : 'Add Broker'}
                    </Button>
                    <Button
                      type='button'
                      variant='contained'
                      startIcon={<Close />}
                      color='inherit'
                      className={classes.cancelButton}
                      onClick={() => {
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
      <Toast id='user-success' message={successMessage || `Broker Added!`} open={isSuccess} onClose={() => showSuccess(false)} variant='success' />
      <Toast
        id='user-error'
        autoHideDuration={6000}
        message={errorMessage || 'We were unable to create the broker at this time. Please try again later. Please contact Enrollment Alliance support if this issue continues.'}
        open={isError}
        onClose={() => showError(false)}
        variant='error'
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  subHeader: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    marginBottom: '-16px'
  },
  spacingInput: {
    marginTop: theme.spacing(0.5)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  logoUploaded: {
    margin: theme.spacing(1, 0, 0)
  },
  logoDrop: {
    marginTop: theme.spacing(1),
    marginBottom: 0
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  content: {
    marginTop: theme.spacing(1)
  },
  bold: {
    fontWeight: 'bold'
  },
  cardContent: {
    paddingTop: 0
  },
  cardActions: {
    '@media (max-width: 450px)': {
      flexDirection: 'column'
    }
  },
  saveButton: {
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  cancelButton: {
    '@media (max-width: 450px)': {
      width: '100%',
      marginTop: theme.spacing(0.5),
      marginLeft: `0 !important`
    }
  }
}));
