import { useSharedStyles } from '../../../../../shared/styles';

import { Accordion } from '../../../../../../../../components';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';

import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import LanguageRequirements from './LanguageRequirements/LanguageRequirements';
import FaceToFaceEnrollment from './FaceToFaceEnrollment/FaceToFaceEnrollment';

import { Divider } from '@mui/material';
import FaceToFaceEnrollmentLocationsTable from './LocationsTable/LocationsTable';

export default function LocationsAndLogistics() {

    const classes = useSharedStyles();

    const { errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const validateAction = menusSubMenusValidationStatusMap.get('locations_&_logistics');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;


    return (
        <div role='tabpanel' id='enrollment-preferences-tab-content' aria-labelledby='enrollment-preferences-tab-content' className={classes.tabContent}>

            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />

            <Accordion defaultExpanded={true} title='Language Requirements' component='h3' variant='h4'>
                <LanguageRequirements />
            </Accordion>

            <Accordion hasErrors={errors?.faceToFaceEnrollment !== undefined} title='Face-toFace Enrollment Location Details' component='h3' variant='h4'>
                <FaceToFaceEnrollment />
                <Divider sx={{marginBottom: 1}} />
                <FaceToFaceEnrollmentLocationsTable />
            </Accordion>



        </div>
    );
}
