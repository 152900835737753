import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material';
import { timelineResponsiveBreakpoint } from './Timeline';
import { ITimelineEvent } from './TimelineEvent';
import { differenceInDays } from 'date-fns';

interface ITimelineSpacerProps {
  width?: string;
}

export const calculateSpacerWidth = (start: ITimelineEvent, end: ITimelineEvent, total: ITimelineEvent[]) => {
  if (start && end) {
    const totalDays = totalTimelineDays(total);
    const spacerDays = totalSpacerDays(start.date, end.date);
    const percentage = (spacerDays / totalDays) * 100;
    return percentage.toString() + '%';
  } else return '0px';
};

export const totalTimelineDays = (data: ITimelineEvent[]) => {
  const first = data[0].date;
  const lastKey = data.length - 1;
  const last = data[lastKey].date;
  const difference = differenceInDays(new Date(last), new Date(first));
  return difference;
};

const totalSpacerDays = (start: string, end: string) => {
  const difference = differenceInDays(new Date(end), new Date(start));
  return difference;
};

export const TimelineSpacer: FC<ITimelineSpacerProps> = ({ width }) => {
  const classes = useStyles({ width });

  return (
    <div className={classes.timelineSpacer}>
      <div className={classes.timelineEventBar}>
        <div className={classes.timelineLine}></div>
      </div>
      <div className={classes.timelineEventDate}>&nbsp;</div>
    </div>
  );
};

const useStyles = makeStyles<Theme, { width?: string }>((theme: Theme) => ({
  timelineSpacer: {
    overflow: 'hidden',
    flex: ({ width }) => (width ? `1 1 ${width}` : 1),
    display: 'none',
    [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
      display: 'flex',
      position: 'relative',
      zIndex: 1,
      flexFlow: 'column wrap'
    },
    '&&:first-child': {
      display: 'none'
    }
  },
  timelineEventBar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '.timeline--responsive &&': {
      height: '100%',
      order: 0,
      width: theme.spacing(2),
      margin: theme.spacing(0, 0.25),

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        width: '100%',
        height: 'auto',
        order: 1,
        margin: theme.spacing(0.35, 0),
        minHeight: theme.spacing(2)
      }
    }
  },
  timelineLine: {
    position: 'absolute',
    zIndex: 0,

    '.timeline--responsive &&': {
      borderLeft: `1px solid ${grey[400]}`,
      height: '100%',
      width: '0',
      top: '0',
      left: '50%',

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        borderTop: `1px solid ${grey[400]}`,
        borderLeft: 'none',
        height: 0,
        width: '100%',
        top: '50%',
        left: 0,
        right: 0
      }
    }
  },
  timelineEventContent: {
    '.timeline--responsive &&': {
      order: 2,

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        order: 0,
        padding: theme.spacing(0, 0.25)
      }
    }
  },
  timelineEventDate: {
    '.timeline--responsive &&': {
      paddingTop: theme.spacing(0.35),
      order: 1,

      [theme.breakpoints.up(timelineResponsiveBreakpoint)]: {
        paddingTop: 0,
        order: 2,
        minHeight: theme.spacing(1)
      }
    }
  }
}));
