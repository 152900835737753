import React from 'react';
import { Box, Grid, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import { IVoluntaryLifeCoverage } from '../../../models';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import useDefaultDropdownsOptionsDropdownCollection from '../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';

interface IVoluntaryLifeCoverageProps {
    data: IVoluntaryLifeCoverage;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const VoluntaryLifeCoverage: React.FC<IVoluntaryLifeCoverageProps> = ({
    data,
    handleBlur,
    setFieldValue,
    errors,
}) => {
    const classes = useSharedStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Offer Voluntary Life Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerVoluntaryLifeCoverage}
                                    id='voluntaryLifeCoverage.offerVoluntaryLifeCoverage'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('voluntaryLifeCoverage.offerVoluntaryLifeCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerVoluntaryLifeCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                </Grid>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    label='Voluntary Life Carrier'
                    name='voluntaryLifeCoverage.voluntaryLifeCarrier'
                    value={data?.voluntaryLifeCarrier}
                    onChange={(e) => {
                        setFieldValue('voluntaryLifeCoverage.voluntaryLifeCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.voluntaryLifeCarrier} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    label='Previous Voluntary Life Carrier'
                    name='voluntaryLifeCoverage.previousVoluntaryLifeCarrier'
                    value={data?.previousVoluntaryLifeCarrier}
                    onChange={(e) => {
                        setFieldValue('voluntaryLifeCoverage.previousVoluntaryLifeCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.previousVoluntaryLifeCarrier} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.volADeDOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.volADeD ?? ''}
                    error={errors?.groupLifeInsurance?.volADeD}
                    label='Vol AD&D'
                    id='voluntaryLifeCoverage.volADeD'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.volADeD} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.volLifeGiEligibility ?? ''}
                    error={errors?.groupLifeInsurance?.volLifeGiEligibility}
                    label='Vol Life GI Eligibility'
                    id='voluntaryLifeCoverage.volLifeGiEligibility'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.volLifeGiEligibility} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.volLifeOffering ?? ''}
                    error={errors?.groupLifeInsurance?.volLifeOffering}
                    label='Vol Life Offering'
                    id='voluntaryLifeCoverage.volLifeOffering'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.volLifeOffering} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.volLifePortability ?? ''}
                    error={errors?.groupLifeInsurance?.volLifePortability}
                    label='Vol Life Portability'
                    id='voluntaryLifeCoverage.volLifePortability'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.volLifePortability} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.volLifeEmployeeRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.volLifeEmployeeRateBasis ?? ''}
                    error={errors?.groupLifeInsurance?.volLifeEmployeeRateBasis}
                    label='Vol Life Employee Rate Basis'
                    id='voluntaryLifeCoverage.volLifeEmployeeRateBasis'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.volLifeEmployeeRateBasis} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.volLifeSpouseRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.volLifeSpouseRateBasis ?? ''}
                    error={errors?.groupLifeInsurance?.volLifeSpouseRateBasis}
                    label='Vol Life Spouse Rate Basis'
                    id='voluntaryLifeCoverage.volLifeSpouseRateBasis'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.volLifeSpouseRateBasis} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.volLifeTobaccoRateBasisOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.volLifeTobaccoRateBasis ?? ''}
                    error={errors?.groupLifeInsurance?.volLifeTobaccoRateBasis}
                    label='Vol Life Tobacco Rate Basis'
                    id='voluntaryLifeCoverage.volLifeTobaccoRateBasis'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.volLifeTobaccoRateBasis} />
            </Grid>

        </Grid>
    );
};

export default VoluntaryLifeCoverage;
