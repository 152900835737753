import { FC } from 'react';
import 'html5-device-mockups/dist/device-mockups.min.css';
import { IPhone6 } from 'react-device-mockups';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import { getMobileAppPreviewIcon } from './AppLinks';
import { IMobileAppLink } from '../../../models';

interface IMobilePreviewScreen {
  logo?: string;
  formValues?: any;
  headerColor?: string;
  appButtonColor?: string;
  textColor?: string;
  icons?: IMobileAppLink[];
  appToggle?: boolean;
}

export const MobilePreviewScreen: FC<IMobilePreviewScreen> = ({
  logo,
  formValues = '',
  headerColor = '#11a5c5',
  appButtonColor = '#000000',
  textColor = '#000000',
  icons,
  appToggle = true
}) => {
  const classes = useStyles({ appToggle });
  return (
    <div className={classes.preview}>
      <IPhone6 width={300} orientation='portrait'>
        <Grid container spacing={0} direction='row' alignContent='flex-start' alignItems='center' wrap='wrap' justifyContent='center' className={classes.appBackground}>
          <Grid item xs={12} style={{ backgroundColor: headerColor }} className={classes.logoContainer}>
            <img className={classes.appLogo} src={logo} alt='Logo' />
          </Grid>
          <Grid item xs={12} style={{ color: textColor }} className={classes.headerTextContainer}>
            <Typography className={classes.headerText} variant='body1' component='div'>
              {formValues.header}
            </Typography>
          </Grid>
          <Grid container spacing={1} className={classes.linkItems}>
            {icons.map((icon, index) => {
              return icon.enabled ? (
                <Grid key={index} item xs={6} className={classes.col}>
                  {getMobileAppPreviewIcon(icon, appButtonColor, textColor, classes.link, classes.icon, classes.name)}
                </Grid>
              ) : null;
            })}
          </Grid>
        </Grid>
      </IPhone6>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: '0px 2px 7.2px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#262626',
    padding: '1rem 0'
  },
  appLogo: {
    width: '60%',
    '@media (min-width: 400px)': {
      width: '50%'
    }
  },
  icon: {
    fontSize: '2.25rem',
    color: '#fff',
    '@media (min-width: 400px)': {
      fontSize: '3.5rem'
    }
  },
  appBackground: {
    padding: 0,
    maxWidth: '320px',
    height: '100%',
    textAlign: 'center'
  },
  preview: ({ appToggle }: { appToggle: boolean }) => {
    return {
      '& .device-wrapper': {
        marginTop: theme.spacing(0.5),
        margin: '0 auto',
        opacity: `${appToggle ? '1' : '0.3'}`
      },
      '& .screen': {
        overflow: 'auto',
        pointerEvents: 'all',
        background: '#fff'
      }
    };
  },
  linkItems: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '2.25rem 1.5rem',
    '@media (min-width: 400px)': {
      padding: '1rem 2rem'
    }
  },
  col: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  link: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.75rem',
    '@media (min-width: 400px)': {
      padding: '1rem'
    }
  },
  name: {
    marginTop: 5
  },
  headerTextContainer: {
    padding: `0 ${theme.spacing(0.5)}`,
    marginTop: theme.spacing(0.5)
  },
  headerText: {
    fontSize: '.85rem'
  }
}));
