import React, { createContext, useContext, ReactNode, useCallback, useEffect } from 'react';
import { ISectionDocuments, ISectionDocumentsFile } from '../../../../../../../../../models';

export enum ESectionDocumentsState {
  default,
  addNewFile,
  editFile
}

interface SectionDocumentsContextProps {
  sectionDocuments: ISectionDocuments[];
  replaceSection: (index: number, newSection: ISectionDocuments) => void;

  selectedDocumentFile: ISectionDocumentsFile,
  selectedSectionInfo: [ISectionDocuments, number],

  openEditFileModal: (documentFile: ISectionDocumentsFile, section: ISectionDocuments, index: number) => void,
  openAddNewFileModal: (section: ISectionDocuments, index: number) => void,
  closeAddEditFileModal: () => void,

  isEditFileModalOpen: boolean,
  isAddNewFileModalOpen: boolean,
}

interface SectionDocumentsProviderProps {
  sectionDocuments: ISectionDocuments[];
  children: ReactNode;
}

export const SectionDocumentsContext = createContext<SectionDocumentsContextProps | undefined>(undefined);

export const SectionDocumentsProvider: React.FC<SectionDocumentsProviderProps> = ({
  sectionDocuments: initialSectionDocuments,
  children,
}) => {

  const mappedInitialSectionDocuments = initialSectionDocuments.map((item, index) => {
    return { ...item, orderIndex: index };
  }) as ISectionDocuments[];

  const replaceSection = useCallback(
    (orderIndex: number, newSection: ISectionDocuments) => {
      setSectionDocuments((prevSections) => {
        const updatedSections = [...prevSections];
        const index = updatedSections.findIndex(u => u.orderIndex === orderIndex)

        updatedSections[index] = { orderIndex, ...newSection };
        return updatedSections;
      });
    },
    []
  );

  const [sectionDocuments, setSectionDocuments] = React.useState<ISectionDocuments[]>(
    mappedInitialSectionDocuments
  );

  const [selectedSectionInfo, setSelectedSectionInfo] = React.useState<[ISectionDocuments, number]>(null);
  const [selectedDocumentFile, setSelectedDocumentFile] = React.useState<ISectionDocumentsFile>(null);

  const [sectionDocumentsState, setSectionDocumentsState] = React.useState<ESectionDocumentsState>(null);

  const openEditFileModal = (documentFile: ISectionDocumentsFile, section: ISectionDocuments, index: number) => {
    setSelectedDocumentFile(documentFile);
    setSelectedSectionInfo([section, index]);
    setSectionDocumentsState(ESectionDocumentsState.editFile);
  }

  const openAddNewFileModal = (section: ISectionDocuments, index: number) => {
    setSelectedSectionInfo([section, index]);
    setSectionDocumentsState(ESectionDocumentsState.addNewFile);
  }

  const closeAddEditFileModal = () => {
    setSelectedSectionInfo(null);
    setSelectedDocumentFile(null);
    setSectionDocumentsState(ESectionDocumentsState.default);
  }

  const isEditFileModalOpen = sectionDocumentsState === ESectionDocumentsState.editFile;
  const isAddNewFileModalOpen = sectionDocumentsState === ESectionDocumentsState.addNewFile;

  return (
    <SectionDocumentsContext.Provider value={{
      sectionDocuments,
      replaceSection,

      selectedSectionInfo,
      selectedDocumentFile,

      openEditFileModal,
      openAddNewFileModal,
      closeAddEditFileModal,

      isEditFileModalOpen,
      isAddNewFileModalOpen
    }}>
      {children}
    </SectionDocumentsContext.Provider>
  );
};

export const useSectionDocuments = () => {
  const context = useContext(SectionDocumentsContext);
  if (!context) {
    throw new Error('useSectionDocuments must be used within a SectionDocumentsProvider');
  }
  return context;
};
