import React from 'react'
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';

import GenericDropdown from '../../../../../../../../components/generic-dropdown/GenericDropdown';
import { PerformantTextField } from '../../../../../../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { useSharedStyles } from '../../../../../shared/styles';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';


export default function Industry() {

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    const industries = generalOptions?.industryOptions;
    const industriesLoading = generalOptionsLoading;

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('industry');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>

                    <GenericDropdown handleBlur={handleBlur}
                        options={industries}
                        isLoading={industriesLoading}
                        setFieldValue={setFieldValue}
                        value={values.industry ?? ''}
                        error={errors.industry}
                        label='Industry Type' id={`industry`}
                    />

                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <PerformantTextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        autoComplete='nope'
                        label='SIC Code'
                        name='sicCode'
                        value={values.sicCode}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('sicCode', e.target.value)}
                    />
                </Grid>
            </Grid>

        </div>
    )
}