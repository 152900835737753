import React, { FC, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { MinimalPage, ResetPasswordForm } from '../../components';
import { UserContext } from '../../context';

export const ResetPassword: FC = () => {
  const { user, isFetching } = useContext(UserContext);

  if (isFetching) {
    return null;
  }

  if (user && !isFetching) {
    return <Redirect to='/' />;
  }

  return (
    <MinimalPage title='Reset Password'>
      <ResetPasswordForm />
    </MinimalPage>
  );
};
