import React, { FC, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ForgotPasswordForm, MinimalPage } from '../../components';
import { UserContext } from '../../context';

export const ForgotPassword: FC = () => {
  const { user, isFetching } = useContext(UserContext);

  if (isFetching) {
    return null;
  }

  if (user && !isFetching) {
    return <Redirect to='/' />;
  }

  return (
    <MinimalPage title='Forgot Password'>
      <ForgotPasswordForm />
    </MinimalPage>
  );
};
