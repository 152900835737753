import React from 'react';
import { FormHelperText, Theme } from '@mui/material'; // Assuming you are using Material-UI
import makeStyles from '@mui/styles/makeStyles';

interface Props {
    message?: string;
    error?: boolean;
    testClass?: string;
}

const FieldErrorMessage: React.FC<Props> = ({ message, error = true, testClass }) => {
    const classes = useStyles();
    return message ? (
        <FormHelperText data-cy={testClass} className={classes.errorText} error={error}>{message}</FormHelperText>
    ) : null;
};
const useStyles = makeStyles((theme: Theme) => ({
    errorText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

export default FieldErrorMessage;