import { FC } from 'react';
import { Modal } from './Modal';
import { Fade, Typography, Box, Button } from '@mui/material';

interface IConfirmationModal {
  open: boolean;
  handleClose: (val?: string) => void;
  items?: string[];
  title: string;
}

export const ConfirmationModal: FC<IConfirmationModal> = ({ open, handleClose, items, title }) => {
  return (
    <Modal
      open={open}
      showClose={false}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      maxWidth='xs'
    >
      <Fade in={open}>
        <div>
          <Typography align='center' paragraph variant='h6'>
            {title}
          </Typography>
          {items && (
            <ul>
              {items
                .flatMap(item => item)
                .map((item, index) => {
                  return (
                    <li key={`${index}`}>
                      <span style={{ color: '#A94442' }}>{item}</span>
                    </li>
                  );
                })}
            </ul>
          )}
          <Box display='flex' justifyContent='center'>
            <Button variant='contained' onClick={() => handleClose('No')}>
              Ok
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};
