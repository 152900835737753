import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Divider, Typography } from '@mui/material';

interface IPageTitle {
  title: string;
  showDivider?: boolean;
  additionalHeaderContent?: JSX.Element;
}

export const PageTitle: FC<IPageTitle> = ({ title, showDivider = true, additionalHeaderContent }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems='center' className={classes.topWrapper}>
        <Typography variant='h1' className={classes.heading}>
          {title}
        </Typography>
        {additionalHeaderContent}
      </Grid>
      {showDivider && <Divider className={classes.divider} />}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  heading: {
    lineHeight: 1.2,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: 30,
    [theme.breakpoints.up('sm')]: {
      fontSize: 36,
      textAlign: 'left'
    }
  },
  topWrapper: {
    marginBottom: theme.spacing(0.5),
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      flexDirection: 'row'
    }
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}));
