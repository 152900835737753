import React from 'react';
import { Box, Grid, TextField, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import { ICancerInsurance } from '../../../models';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import useDefaultDropdownsOptionsDropdownCollection from '../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';

interface ICancerCoverageProps {
    data: ICancerInsurance;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const CancerCoverage: React.FC<ICancerCoverageProps> = ({ data, handleBlur, setFieldValue, errors }) => {

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';


    const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
    const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
    const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';


    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' sx={{ marginRight: '1rem' }}>
                            Offer Cancer Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerCancerCoverage}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('cancerInsurance.offerCancerCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerCancerCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    name='cancerInsurance.cancerCarrier'
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Cancer Carrier'
                    value={data?.cancerCarrier}
                    onChange={(e) => {
                        setFieldValue('cancerInsurance.cancerCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    name='cancerInsurance.previousCancerCarrier'
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Previous Cancer Carrier'
                    value={data?.previousCancerCarrier}
                    onChange={(e) => {
                        setFieldValue('cancerInsurance.previousCancerCarrier', e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <FormControlLabel
                    control={
                        <Switch
                            color='primary'
                            checked={data?.cancerTaxStatus}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('cancerInsurance.cancerTaxStatus', e.target.checked);
                            }}
                            onBlur={handleBlur}
                        />
                    }
                    label='Cancer Tax Status'
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    name='cancerInsurance.cancerNotes'
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Cancer Notes'
                    value={data?.cancerNotes}
                    onChange={(e) => {
                        setFieldValue('cancerInsurance.cancerNotes', e.target.value);
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.cancerOffering ?? ''}
                    error={errors?.cancerInsurance?.cancerOffering}
                    label='Cancer Offering'
                    id='cancerInsurance.cancerOffering'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.cancerPlanTypeOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.cancerPlanType ?? ''}
                    error={errors?.cancerInsurance?.cancerPlanType}
                    label='Cancer Plan Type'
                    id='cancerInsurance.cancerPlanType'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPreExRulesOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.cancerPreExRules ?? ''}
                    error={errors?.cancerInsurance?.cancerPreExRules}
                    label='Cancer Pre-Ex Rules'
                    id='cancerInsurance.cancerPreExRules'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={defaultDropdownsOptions?.defaultPortabilityOptions ?? []}
                    isLoading={defaultDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.cancerPortability ?? ''}
                    error={errors?.cancerInsurance?.cancerPortability}
                    label='Cancer Portability'
                    id='cancerInsurance.cancerPortability'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.creditForTimeServedOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.cancerCreditForTimeServed ?? ''}
                    error={errors?.cancerInsurance?.cancerCreditForTimeServed}
                    label='Cancer Credit For Time Served'
                    id='cancerInsurance.cancerCreditForTimeServed'
                />
            </Grid>
        </Grid>
    );
};

export default CancerCoverage;
