import { Box, FormLabel as MuiLabel, FormControlLabel, Grid, Switch } from '@mui/material';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';

export default function FollowUpMessages() {

    const { values, setFieldValue, handleBlur } = useFormikContext<IBusinessClientDetailFormValues>();

    return (
        <Grid container spacing={1}>

            <Grid item xs={12} sm={12} md={12}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Target Audience'
                    name='businessClientFollowUpMessages.targetAudience'
                    value={values.businessClientFollowUpMessages?.targetAudience}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('businessClientFollowUpMessages.targetAudience', e.target.value)}
                />
            </Grid>

        </Grid>

    )
}