import { useEffect, useContext } from "react";

import useErrorMessage from "../../context/ErrorMessageCtx";
import { IMedicalInsuranceOptions } from "../../models";
import { RequestState } from "../../models/request-state";
import { DropDownCollectionsCtx } from "../../context/DropDownCollectionsCtx";
import { getMedicalInsuranceOptionsRequest } from "../../fetch";

const useMedicalInsuranceOptionsDropdownCollection = (): RequestState<IMedicalInsuranceOptions> => {
    const [, setErrorMessage] = useErrorMessage();
    const { medicalInsuranceOptionsRequest, dispatchMedicalInsuranceOptions } = useContext(DropDownCollectionsCtx);

    const status = medicalInsuranceOptionsRequest.status;


    useEffect(() => {

        if (status !== "idle") return;

        dispatchMedicalInsuranceOptions({ type: 'REQUEST_LOADING' });

        const fetchCollection = async () => {
            try {

                const res = await getMedicalInsuranceOptionsRequest();
                if (res) {

                    dispatchMedicalInsuranceOptions({ type: 'REQUEST_SUCCESS', payload: res })
                } else {
                    setErrorMessage('Error loading Medical Insurance Options, please try again.');
                    dispatchMedicalInsuranceOptions({ type: 'REQUEST_ERROR', payload: 'Error loading industries, please try again.' })
                }
            } catch (error) {
                console.error(error);
                dispatchMedicalInsuranceOptions({ type: 'REQUEST_ERROR', payload: 'Error loading industries, please try again.' })
                setErrorMessage('Error loading Medical Insurance Options, please try again.');
            }


        };

        fetchCollection();
    }, [status, dispatchMedicalInsuranceOptions, setErrorMessage]);

    return medicalInsuranceOptionsRequest;

};

export default useMedicalInsuranceOptionsDropdownCollection