import React from 'react';
import { Box, Grid, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useInsuranceCoverageOptionsDropdownCollection from '../../../hooks/dropdown-collection/useInsuranceCoverageOptionsDropdownCollection';
import { IGroupLifeInsurance } from '../../../models';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';

interface IGroupLifeInsuranceProps {
    data: IGroupLifeInsurance;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    errors: any;
    touched: any;
}

const GroupLifeInsurance: React.FC<IGroupLifeInsuranceProps> = ({
    data,
    handleBlur,
    setFieldValue,
    errors,
}) => {
    const classes = useSharedStyles();

    const insuranceCoverageOptionsRequest = useInsuranceCoverageOptionsDropdownCollection();
    const insuranceCoverageOptions = insuranceCoverageOptionsRequest.data;
    const insuranceCoverageOptionsLoading = insuranceCoverageOptionsRequest.status === 'loading';


    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item marginLeft={1}>
                    <Box marginBottom='1rem'>
                        <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                            Offer Basic Life Coverage
                        </MuiLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    checked={data?.offerBasicLifeCoverage}
                                    id='groupLifeInsurance.offerBasicLifeCoverage'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('groupLifeInsurance.offerBasicLifeCoverage', e.target.checked);
                                    }}
                                    onBlur={handleBlur}
                                />
                            }
                            label={data?.offerBasicLifeCoverage ? 'Offered' : 'Not offered'}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Basic Life Carrier'
                    name='groupLifeInsurance.basicLifeCarrier'
                    value={data?.basicLifeCarrier}
                    onChange={(e) => {
                        setFieldValue('groupLifeInsurance.basicLifeCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.basicLifeCarrier} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    label='Previous Basic Life Carrier'
                    name='groupLifeInsurance.previousBasicLifeCarrier'
                    value={data?.previousBasicLifeCarrier}
                    onChange={(e) => {
                        setFieldValue('groupLifeInsurance.previousBasicLifeCarrier', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.previousBasicLifeCarrier} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={insuranceCoverageOptions?.defaultPlanOfferOptions ?? []}
                    isLoading={insuranceCoverageOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.basicLifeOffering ?? ''}
                    error={errors?.groupLifeInsurance?.basicLifeOffering}
                    label='Basic Life Offering'
                    id='groupLifeInsurance.basicLifeOffering'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.basicLifeOffering} />
            </Grid>


            

            <Grid item xs={12} sm={6} md={4}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.basicADeDOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.basicADeD ?? ''}
                    error={errors?.groupLifeInsurance?.basicADeD}
                    label='Basic AD&D'
                    id='groupLifeInsurance.basicADeD'
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.basicADeD} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <PerformantTextField
                    fullWidth
                    multiline
                    variant='outlined'
                    size='small'
                    label='Life Notes'
                    name='groupLifeInsurance.lifeNotes'
                    value={data?.lifeNotes}
                    onChange={(e) => {
                        setFieldValue('groupLifeInsurance.lifeNotes', e.target.value);
                    }}
                />
                <FieldErrorMessage message={errors.groupLifeInsurance?.lifeNotes} />
            </Grid>

        </Grid>
    );
};

export default GroupLifeInsurance;
