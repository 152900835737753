export interface IBroker {
  brokerId: number;
  name: string;
  logoUrl?: string;
}

export interface IBrokerRes {
  records: IBroker[];
  totalRecordCount: number;
}

export interface IBrokerPost {
  name: string;
  logoUrl?: string;
}

export interface IBrokerPut {
  name: string;
  logoUrl?: string;
}

export type BrokerDashboardStatus = 'OpenEnrollment' | 'NewHire' | 'All';
export enum EBrokerDashboardStatus {
  OPEN_ENROLLMENT = 'OpenEnrollment',
  NEW_HIRE = 'NewHire',
  ALL = 'All'
}

export interface IBrokerDashboardEmployeeCount {
  status: string;
  count: number;
}

export interface IBrokerDashboard {
  businessClientId: number;
  name: string;
  logoUrl: string;
  isOpenEnrollment: boolean;
  openEnrollmentStartDate: string;
  openEnrollmentEffectiveDate: string;
  enrollmentDeadline: string;
  employeeStatusCounts: IBrokerDashboardEmployeeCount[];
}

export interface IBrokerDashboardRes {
  totalRecordCount: number;
  records: IBrokerDashboard[];
}
