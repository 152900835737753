import React from 'react'
import { Grid } from '@mui/material';
import { useSharedStyles } from '../../../../../shared/styles';
import { useParams } from 'react-router-dom';
import useSectionsStatusOptionsDropdownCollection from '../../../../../../../../hooks/dropdown-collection/useSectionsStatusOptionsDropdownCollection';
import { useSectionDocuments } from '../EssentialDocsList/contexts/SectionDocumentsContext';
import { DocSection } from '../EssentialDocsList/DocSection/DocSection';
import { SingleTabsBase } from '../../../base/single-tab-base';
import { DefaultTabBase } from '../../shared';

export default function Census() {
    const { id: businessClientId }: { id: string } = useParams();

    const optionsResponse = useSectionsStatusOptionsDropdownCollection();

    const classes = useSharedStyles();

    const { sectionDocuments } = useSectionDocuments();

    const actualSectionDocuments = sectionDocuments.filter(s => ['CompletedCensus', 'CensusTemplates'].includes(s.category));

    const refs = actualSectionDocuments.reduce((acc, value) => {
        acc[value.category] = React.createRef();
        return acc;
    }, {});

    return (
        <Grid container columnSpacing={{ lg: .7 }} rowSpacing={1} sx={{ width: '100%' }}>


            {actualSectionDocuments
                .map((section) => {
                    return (<>

                        <Grid ref={refs[section.category]} item xs={12} sm={12} lg={4} xl={4}>
                            <DocSection businessClientId={businessClientId} optionsResponse={optionsResponse} section={section} index={section.orderIndex} />

                        </Grid>
                    </>
                    )
                })}

        </Grid>
    )
}