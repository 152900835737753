import { FormikErrors } from "formik";
import { IBusinessClientDetailFormValues } from "../../../../../../../../models";

export default (errors: FormikErrors<IBusinessClientDetailFormValues>) => {
    return !errors?.groupLifeInsurance &&
        !errors?.dentalInsurance &&
        !errors?.longTermDisabilityCoverage &&
        !errors?.shortTermDisabilityCoverage &&
        !errors?.visionInsurance &&
        !errors?.voluntaryLifeCoverage;
}