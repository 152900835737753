import React, { FC, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, TextField, Typography, Theme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Formik, FormikHelpers, Field, FieldProps, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import { Toast, Button } from '../../components';
import { sendResetLink } from '../../fetch';
import { useHistory } from 'react-router-dom';

interface IForgotPasswordProps {}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').required('Required')
});

interface IForgotPasswordValues {
  email: string;
}

export const ForgotPasswordForm: FC<IForgotPasswordProps> = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<any>(null);
  const history = useHistory();
  const handleOnSubmit = () => {
    setTimeout(function () {
      history.push(`/`);
    }, 1000);
  };
  return (
    <>
      <Formik
        initialValues={{
          email: ''
        }}
        onSubmit={async (values: IForgotPasswordValues, actions: FormikHelpers<IForgotPasswordValues>) => {
          setLoading(true);
          try {
            const res = await sendResetLink(values);
            if (res && typeof res === 'boolean') {
              setSuccess({ message: `If you are a user that exists in our system we will send you an email.` });
              actions.resetForm({
                values: {
                  email: ''
                }
              });
            } else {
              setError({ message: 'Error sending reset link, please try again.' });
            }
          } catch (error) {
            if (error && error.Detail) {
              setError({ message: error.Detail });
            } else {
              setError({ message: 'Error sending reset link, please try again.' });
            }
            const forgotEmail = document && document.getElementById('forgot-email');
            if (forgotEmail) {
              forgotEmail.focus();
            }
          } finally {
            actions.setSubmitting(false);
            setLoading(false);
          }
        }}
        validationSchema={forgotPasswordSchema}
      >
        {(formikProps: FormikProps<IForgotPasswordValues>) => {
          return (
            <>
              <Form data-testid='forgot-password-form' id='forgot-password-form' onSubmit={formikProps.handleSubmit}>
                <Typography variant='body1' className={classes.instructions}>
                  Enter your email address below and we will email you a code to reset your password.
                </Typography>
                <FormControl fullWidth={true} margin='normal'>
                  <Field name='email'>
                    {({ field, form }: FieldProps<IForgotPasswordValues>) => (
                      <TextField
                        {...field}
                        error={form.touched.email && form.errors.email ? true : false}
                        fullWidth={true}
                        helperText={form.touched.email && form.errors.email}
                        id='forgot-email'
                        label='Email'
                        onChange={e => formikProps.setFieldValue('email', e.target.value)}
                        type='email'
                      />
                    )}
                  </Field>
                </FormControl>
                <FormControl fullWidth={true} margin='normal'>
                  <Button
                    color='primary'
                    disabled={!formikProps.isValid || loading || !formikProps.values.email}
                    fullWidth={true}
                    id='send-reset-link'
                    loading={loading ? 'Sending link...' : undefined}
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Send Reset Link
                  </Button>
                </FormControl>
                <FormControl fullWidth={true} margin='normal'>
                  <Button className={classes.back} color='secondary' fullWidth={true} id='back-to-login' asLink={true} to={'/'} variant='text' startIcon={<ArrowBackIcon />}>
                    Back to Login
                  </Button>
                </FormControl>
              </Form>
            </>
          );
        }}
      </Formik>
      <Toast
        autoHideDuration={4000}
        id='forgot-password-success'
        message={success ? success.message : null}
        onClick={() => setSuccess(null)}
        onClose={() => {
          setSuccess(null);
          handleOnSubmit();
        }}
        open={success ? true : false}
        variant='success'
      />
      <Toast
        id='forgot-password-failed'
        message={error ? error.message || error.error_message : null}
        onClick={() => setError(null)}
        onClose={() => {
          setError(null);
        }}
        open={error ? true : false}
        variant='error'
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    back: {
      textDecoration: 'none',
      textTransform: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    icon: {
      height: '15px',
      marginRight: theme.spacing(1),
      width: '15px !important'
    },
    paper: {
      maxWidth: 360
    },
    instructions: {
      marginTop: 16
    }
  };
});
