import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ISectionDocuments, ISectionDocumentsFile } from '../../models';
import { useSectionDocuments } from '../../pages/admin/business-client-detail/BusinessClientDetailPage/TabsForms/tabs-forms-contents/EnrollmentPlanner/EssentialDocsList/contexts/SectionDocumentsContext';
import { ListItemIcon, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteEssentialDoc } from '../../fetch/section-documents';
import useErrorMessage from '../../context/ErrorMessageCtx';

interface IDocumentFileOptionsMenuProps {
  documentFile: ISectionDocumentsFile;
  anchorEl: HTMLElement,
  section: ISectionDocuments;
  sectionIndex: number;
  closeMenuCallback: Function;
}

const DocumentFileOptionsMenu: React.FC<IDocumentFileOptionsMenuProps> = ({ documentFile, anchorEl, section, sectionIndex, closeMenuCallback }) => {

  const { openEditFileModal, replaceSection } = useSectionDocuments();
  const [, setErrorMessage] = useErrorMessage();

  const open = Boolean(anchorEl);

  const closeMenuHandler = () => {
    closeMenuCallback(null);
  };

  const downloadHandler = (fileUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    closeMenuHandler()
  };

  const linkHandler = (externalFileUrl: string) => {

    if (!externalFileUrl) return closeMenuHandler();

    const link = document.createElement('a');
    link.href = externalFileUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    closeMenuHandler()
  };

  const editFileHandler = (documentFile: ISectionDocumentsFile, section: ISectionDocuments, index: number) => {
    openEditFileModal(documentFile, section, index);
    closeMenuHandler()
  }

  const removeDocHandler = async (sectionId: number, sectionIndex: number, essentialDocId: number) => {
    const result = window.confirm('Are you sure you want to delete this document?');

    if (!result) return;

    try {
      const newSection: ISectionDocuments = await deleteEssentialDoc(sectionId, essentialDocId);
      replaceSection(sectionIndex, newSection);
    } catch (error) {
      setErrorMessage(error.Title)
    }

    closeMenuHandler();

  }

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenuHandler}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >

      {documentFile && <MenuItem
        disabled={true}
        selected={false}
      >

        <Typography variant="inherit" noWrap>
          {documentFile?.fileName ?? documentFile?.externalFileUrl}
        </Typography>
      </MenuItem>}

      <MenuItem disabled={documentFile?.docContentType === 'OnlyExternalFileUrl'} onClick={() => downloadHandler(documentFile?.fileUrl, documentFile?.fileName)}>
        <ListItemIcon>
          <FileDownloadIcon fontSize="small" />
        </ListItemIcon>Download file</MenuItem>

      <MenuItem disabled={documentFile?.docContentType === 'OnlyFile'} onClick={() => linkHandler(documentFile?.externalFileUrl)}><ListItemIcon>
        <LaunchIcon fontSize="small" />
      </ListItemIcon>External link</MenuItem>

      <MenuItem onClick={() => { editFileHandler(documentFile, section, sectionIndex) }}><ListItemIcon>
        <EditIcon fontSize="small" />
      </ListItemIcon>Edit</MenuItem>

      <MenuItem onClick={() => {
        removeDocHandler(section.id, sectionIndex, documentFile.id)
      }}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>Delete
      </MenuItem>
    </Menu>
  );
}

export default DocumentFileOptionsMenu;