import React, { useContext, createContext, useState } from 'react';

interface CompanyLogoContextType {
    companyLogo: File | undefined;
    setCompanyLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const CompanyLogoContext = createContext<CompanyLogoContextType>({} as CompanyLogoContextType);

export const CompanyLogoProvider: React.FC = ({ children }) => {
    const [companyLogo, setCompanyLogo] = useState<File | undefined>(undefined);

    return (
        <CompanyLogoContext.Provider value={{ companyLogo, setCompanyLogo }}>
            {children}
        </CompanyLogoContext.Provider>
    );
};

export const useCompanyLogo = () => {
    const context = useContext(CompanyLogoContext);
    if (!context) {
        throw new Error('useCompanyLogo must be used within an CompanyLogoProvider');
    }
    return context
}

export const useCompanyLogoContext = () => React.useContext(CompanyLogoContext);