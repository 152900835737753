import React from 'react'
import { FormControl, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { DatePicker } from '../../../../../../../../../components';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';

export default function EnrollmentPreferencesAccordion() {

    const { values, setFieldValue, handleBlur } = useFormikContext<IBusinessClientDetailFormValues>();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Total Eligible Employees'
                    name='totalEligibleEmployees'
                    value={values.totalEligibleEmployees}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('totalEligibleEmployees', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                <PerformantTextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='Projected Number of Benefit Meetings Required'
                    name='projectedNumberOfBenefitMeetingsRequired'
                    value={values.projectedNumberOfBenefitMeetingsRequired}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('projectedNumberOfBenefitMeetingsRequired', e.target.value)}
                />
            </Grid>
        </Grid>

    )
}