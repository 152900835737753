import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { MouseEventHandler } from 'react';

export interface IAppProps {
    hasErrors: boolean,
    title: string,
    icon: JSX.Element,
    onClick: MouseEventHandler<any>
}

export default function CardMenu({ hasErrors, icon, title, onClick }: IAppProps) {

    const classes = useStyles();

    const clonedIcon = React.cloneElement(icon, {
        icon: icon.props?.icon,
        size: 60
    });

    return (
        <Grid onClick={onClick} key={`${0}`} item xs={12} sm={6} md={3} className={classes.cardMenuContainer}>
            <Card className={classes.cardMenu} sx={{
                backgroundColor: hasErrors ? 'rgba(255, 0, 0, .1)' : 'rgb(255, 255, 255)',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                ':hover': {
                    boxShadow:

                        hasErrors ? '0px 2px 1px -1px rgba(169, 68, 66, .4), 0px 1px 1px 0px rgba(169, 68, 66, .4), 0px 1px 4px 1px rgba(169, 68, 66, .4)' :
                            '0px 2px 1px -1px #11a5c5, 0px 1px 1px 0px #11a5c5, 0px 1px 4px 1px #11a5c5'
                },
            }}>
                <CardContent className={classes.cardcontent}>
                    <Box display='flex' justifyContent='center' height='140px'>
                        {clonedIcon}
                    </Box>

                </CardContent>
            </Card>

            <Typography data-cy={`Home menu ${title}`} className={classes.cardMenuTitle} sx={{ color: hasErrors ? 'rgb(169, 68, 66)' : 'initial' }}>
                {title}
            </Typography>
        </Grid>
    );
}


const useStyles = makeStyles({
    cardMenuContainer: {
        cursor: 'pointer'
    },
    cardcontent: {
        height: '140px',
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    cardMenu: {
        padding: 0,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    cardMenuTitle: {
        textAlign: 'center',
        margin: '.7em 0',
        fontSize: '13pt'
    },
    root: {
        '&$selected': {
            color: '#11a5c5',
            borderRight: '4px solid #11a5c5'

        },
    },
    selected: {},
});