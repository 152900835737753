import React, { useContext } from 'react';
import { createContext, useState } from 'react';

const ErrorMessageCtx = createContext<[string, React.Dispatch<React.SetStateAction<string>>]>([null, () => { }]);

const ErrorMessageProvider: React.FC = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState<string>(null);

    return (
        <ErrorMessageCtx.Provider value={[errorMessage, setErrorMessage]}>
            {children}
        </ErrorMessageCtx.Provider>
    );
};

export { ErrorMessageCtx, ErrorMessageProvider };

const useErrorMessage = (): [string, React.Dispatch<React.SetStateAction<string>>] => {
    const [errorMessage, setErrorMessage] = useContext(ErrorMessageCtx);
    return [errorMessage, setErrorMessage];
};

export default useErrorMessage;
