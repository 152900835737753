import { useEffect, useState } from "react";

import { IBroker } from "../../../../models";
import { getBrokers } from "../../../../fetch";
import { RequestState } from "../../../../models/request-state";
import useErrorMessage from "../../../../context/ErrorMessageCtx";

const useBrokers = (): RequestState<IBroker[]> => {
    const [, setErrorMessage] = useErrorMessage();

    const [brokersRequestState, setBrokersRequestState] = useState<RequestState<IBroker[]>>({
        status: 'idle'
    });

    useEffect(() => {

        if (brokersRequestState.status !== "idle") return;

        const fetchBrokers = async () => {

            setBrokersRequestState({
                status: 'loading'
            });

            try {
                const res = await getBrokers({
                    perPage: '2147483647' // get all records
                });
                if (res.records) {
                    setBrokersRequestState({
                        status: 'success',
                        data: res.records
                    });
                } else {

                    setBrokersRequestState({
                        status: 'error',
                        error: 'Error loading brokers, please try again.'
                    });

                    setErrorMessage('Error loading brokers, please try again.');
                }
            } catch (error) {

                setBrokersRequestState({
                    status: 'error',
                    error: 'Error loading brokers, please try again.'
                });

                setErrorMessage('Error loading brokers, please try again.');
            }
        };


        fetchBrokers();
    }, []);

    return brokersRequestState;

};

export default useBrokers