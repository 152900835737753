import * as React from 'react';
import { Typography, Box, Grid, TextField, FormLabel, FormControlLabel, Switch, FormHelperText, RadioGroup, Radio, Select, MenuItem, Alert } from '@mui/material';
import { FileDrop, FileUploaded } from '../../../../../../../../../components/file';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../../models';
import { storeBusinessClientCompanyLogo } from '../../../../../../../../../fetch';
import { useDropzone } from 'react-dropzone';
import { useCurrentBusinessClient } from '../../../../../../contexts/CurrentBusinessClientContext';
import useErrorMessage from '../../../../../../../../../context/ErrorMessageCtx';
import { useSharedStyles } from '../../../../../../shared/styles';
import { useCompanyLogo } from '../../../../../../contexts/CompanyLogoContext';

export default function CompanyLogo() {

    const [previewError, setPreviewError] = React.useState<string>('');
    const currentBusinessClient = useCurrentBusinessClient();

    const { setCompanyLogo } = useCompanyLogo();

    const classes = useSharedStyles();

    const [, setErrorMessage] = useErrorMessage();

    const { values, setFieldTouched, setFieldValue } = useFormikContext<IBusinessClientDetailFormValues>();

    const imageDZUrlEdit = (acceptedFile: File) => {
        const imageUrl = URL.createObjectURL(acceptedFile);
        const img = document.createElement('img');
        img.src = imageUrl;
        img.onload = async () => {
            // icon needs a minimum height of 140px
            if (img.height < 140) {
                setFieldValue('logoUrl', '');
                setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
            } else {
                const create = new FormData();
                create.append('image', acceptedFile);
                try {
                    const imageUrl = await storeBusinessClientCompanyLogo(currentBusinessClient.businessClientId, create);
                    setFieldValue('logoUrl', imageUrl);
                } catch (error) {
                    console.log(error);
                    if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
                        setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
                    }
                }
                setPreviewError('');
            }
        };
    };
    const imageDZUrlAdd = (acceptedFile: File) => {
        const imageUrl = URL.createObjectURL(acceptedFile);
        const img = document.createElement('img');
        img.src = imageUrl;
        img.onload = async () => {
            // icon needs a minimum height of 140px
            if (img.height < 140) {
                setCompanyLogo(null);
                setPreviewError(`The image provided is too small. The minimum height is 140 pixels tall. Your image is ${img.width} pixels wide by ${img.height} pixels tall.`);
            } else {
                setCompanyLogo(acceptedFile);
                setFieldValue('logoUrl', imageUrl);
                setPreviewError('');
            }
        };
    };
    const imageDZUrl = useDropzone({
        accept: '.jpg, .jpeg, .png',
        onDrop: acceptedFiles => {
            if (acceptedFiles.length > 0 && currentBusinessClient) {
                imageDZUrlEdit(acceptedFiles[0]);
            } else if (acceptedFiles.length > 0) {
                imageDZUrlAdd(acceptedFiles[0]);
            }
        }
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                {values.logoUrl && (
                    <FileUploaded
                        className={classes.logoUploaded}
                        id='company-logo-image-preview'
                        onRemove={() => {
                            setFieldValue(`logoUrl`, '');
                            setFieldTouched('logoUrl', true);
                        }}
                        src={values.logoUrl as unknown as string}
                        backgroundColor={'#000'}
                    />
                )}
                {!values.logoUrl && (
                    <>
                        <FileDrop id='company-logo-image-drop' {...imageDZUrl} title='Add Company Logo' className={classes.logoDrop}>
                            <Typography variant='body1'>
                                Drag and drop a PNG or JPG file or{' '}
                                <a href='/#' onClick={e => e.preventDefault()}>
                                    browse and pick
                                </a>{' '}
                                a file.
                            </Typography>
                            <Typography variant='body2'>
                                Recommended transparent background with white logo.
                                <br />
                                Minimum height of 140 pixels.
                            </Typography>
                            {previewError && <FormHelperText error={true}>{previewError}</FormHelperText>}
                        </FileDrop>
                    </>
                )}
            </Grid>
        </Grid>
    );
}
