import { ClientDetailsSubMenusTypes, PostEnrollmentDataSubMenusTypes, TabOptionType } from '../../../../MenuData/types';
import { TabsBase } from '../../../base/tabs-base';

interface ITabsProps {
    validateForm: () => void;
}

const tabOptions: TabOptionType<PostEnrollmentDataSubMenusTypes>[] = [
    {
        value: 0,
        label: 'Enrollment Engagement Numbers',
        subMenuRelatedId: 'enrollment_engagement_numbers'
    },
    {
        value: 1,
        label: 'Product Partipation',
        subMenuRelatedId: 'product_partipation'
    },
    {
        value: 2,
        label: 'Challenges and Solutions',
        subMenuRelatedId: 'challenges_and_solutions'
    },
];

const Index = ({ validateForm }: ITabsProps) => {
    return (
        <TabsBase validateForm={validateForm} tabOptions={tabOptions} />
    )
}

export default Index;