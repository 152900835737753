
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useTabPanelStyles = makeStyles((theme: Theme) => ({

    tabsPannelContainer: {
        display: 'flex',

        '@media (max-width: 820px)': {
            flexDirection: 'column'
        },
    },

    summaryContainer: {
        marginRight: '1em',
        '@media (max-width: 820px)': {
            marginRight: 0,
        },
    },

    accordionContainer: {
        flexGrow: 1,
    },


}));