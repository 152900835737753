import React, { useEffect, useRef, useState } from 'react'
import { Grid, TextField } from '@mui/material';
import { useSharedStyles } from '../../../../../shared/styles';
import { DocSection } from './DocSection/DocSection';
import { useParams } from 'react-router-dom';
import { useSectionDocuments } from './contexts/SectionDocumentsContext';
import useSectionsStatusOptionsDropdownCollection from '../../../../../../../../hooks/dropdown-collection/useSectionsStatusOptionsDropdownCollection';
import { useLateralFormMenuControl } from '../../../../../contexts/LateralFormMenuControlContext';
import { ISectionDocuments } from '../../../../../../../../models';

interface IFilterSections {
    filterValue: string;
    indexes: number[];
}

export default function EssentialDocsList() {
    const { id: businessClientId }: { id: string } = useParams();

    const optionsResponse = useSectionsStatusOptionsDropdownCollection();

    const [_, setTick] = useState(0);

    const classes = useSharedStyles();

    const { selectAccordionCategory } = useLateralFormMenuControl();
    const { sectionDocuments } = useSectionDocuments();

    const refs = sectionDocuments.reduce((acc, value) => {
        acc[value.category] = React.createRef();
        return acc;
    }, {});

    const filteredSectionDocumentsRef = useRef<IFilterSections>({ filterValue: null, indexes: sectionDocuments?.map(f => f.orderIndex) });

    const filterHandler = (value: string, sectionDocuments: ISectionDocuments[]) => {

        if (!value) {
            filteredSectionDocumentsRef.current.indexes = sectionDocuments.map(f => f.orderIndex);;
            filteredSectionDocumentsRef.current.filterValue = null;
            return setTick((prev) => prev + 1);
        }

        const filtered = sectionDocuments.filter(d => d.categoryDescription.toLowerCase().includes(value.toLowerCase()));
        filteredSectionDocumentsRef.current.indexes = filtered.map(f => f.orderIndex);
        filteredSectionDocumentsRef.current.filterValue = value;

        setTick((prev) => prev + 1)
    }


    useEffect(() => {

        if (!selectAccordionCategory) return;

        const scroll = (selectAccordionCategory: string) =>
            refs[selectAccordionCategory].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

        scroll(selectAccordionCategory)


    }, [selectAccordionCategory])



    useEffect(() => {
        console.log('sectionDocuments', sectionDocuments)
        filterHandler(filteredSectionDocumentsRef.current.filterValue, sectionDocuments)
    }, [sectionDocuments])

    return (
        <Grid container columnSpacing={{ lg: .7 }} rowSpacing={1} sx={{ width: '100%' }}>

            <Grid item xs={12}>
                <TextField
                    autoComplete='off'
                    size='small'
                    fullWidth
                    variant='outlined'
                    placeholder='Search...'
                    name='search'
                    disabled={!sectionDocuments}
                    onChange={e => {
                        filterHandler(e.target.value, sectionDocuments);
                    }}
                />
            </Grid>

            {sectionDocuments
                .map((section) => {
                    return (<>
                        {filteredSectionDocumentsRef.current?.indexes?.includes(section.orderIndex) ?
                            <Grid ref={refs[section.category]} item xs={12} sm={12} lg={4} xl={4}>
                                <DocSection businessClientId={businessClientId} optionsResponse={optionsResponse} section={section} index={section.orderIndex} />

                            </Grid> : <></>
                        }</>
                    )
                })}

        </Grid>
    )
}