import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { Link, Loader, Page, PageTitle } from '../../../../../../../components';
import CardMenu from './CardMenu/CardMenu'

import makeStyles from '@mui/styles/makeStyles';



import { BusinessClientMenusTypes, MenuItem } from '../../../MenuData/types';
import { useLateralFormMenuControl } from '../../../../contexts/LateralFormMenuControlContext';
import menuData from '../../../MenuData';
import { useMenuSubMenuWithErrors } from '../../../../../../../components/list-menu-sub-menu/contexts/MenuSubMenuWithErrorsContext';



export default function Home() {
    const classes = useStyles();

    const { setMenu } = useLateralFormMenuControl();
    const { menusWithErrors } = useMenuSubMenuWithErrors();

    const filteredMenuData = menuData.filter(m => m.id !== 'home');

    return (
        <Grid item container spacing={1.5}>
            {
                filteredMenuData.map((item) => (
                    <CardMenu hasErrors={menusWithErrors.includes(item.id as BusinessClientMenusTypes)} onClick={() => setMenu(item)} icon={item.primaryIconElement ? item.primaryIconElement : <item.iconComponent className={classes.icon} />} title={item.title} />)
                )
            }
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    icon: {
        [theme.breakpoints.down('xl')]: {
            width: 100,
            height: 100,
        },
        [theme.breakpoints.down('lg')]: {
            width: 95,
            height: 95,
        },
        [theme.breakpoints.down('md')]: {
            width: 90,
            height: 90,
        }
    }
}));
