import { ReactComponent as Video } from '../../../assets/icons/video.svg';
import { ReactComponent as Chat } from '../../../assets/icons/Chat.svg';
import { ReactComponent as Wellness } from '../../../assets/icons/Wellness.svg';
import { ReactComponent as Rx } from '../../../assets/icons/Rx.svg';
import { ReactComponent as Meeting } from '../../../assets/icons/schedule-advisor-meeting.svg';
import { ReactComponent as Enroll } from '../../../assets/icons/Enroll.svg';
import { ReactComponent as Telemed } from '../../../assets/icons/telemedicine.svg';
import { ReactComponent as OnlineSupport } from '../../../assets/icons/online-support.svg';
import { ReactComponent as MentalHealth } from '../../../assets/icons/mental-health.svg';
import { ReactComponent as Location } from '../../../assets/icons/find-a-provider.svg';
import { ReactComponent as BenefitInfo } from '../../../assets/icons/Benefit-Info.svg';
import { ReactComponent as MedicalConcierge } from '../../../assets/icons/Medical-Concierge.svg';
import { ReactComponent as Retirement } from '../../../assets/icons/401k.svg';
import { ReactComponent as Payroll } from '../../../assets/icons/Payroll.svg';
import { ReactComponent as ContactSupport } from '../../../assets/icons/Contact-Support.svg';
import { ReactComponent as Webinar } from '../../../assets/icons/Webinar.svg';
import { ReactComponent as Legal } from '../../../assets/icons/Legal.svg';
import { ReactComponent as IdentityProtection } from '../../../assets/icons/ID-Protection.svg';
import { ReactComponent as PetInsurance } from '../../../assets/icons/Pet-Insurance.svg';
import { ReactComponent as Instructions } from '../../../assets/icons/Instructions-icon.svg';
import { SvgIcon, Typography } from '@mui/material';
import { EMobileAppLinkTypeEnums } from '../../../models';


import { ReactComponent as AutoInsurance } from '../../../assets/icons/Auto-Insurance.svg';
import { ReactComponent as BoatInsurance } from '../../../assets/icons/Boat-Insurance.svg';
import { ReactComponent as Forms } from '../../../assets/icons/Forms.svg';
import { ReactComponent as HomeInsurance } from '../../../assets/icons/Home-Insurance.svg';
import { ReactComponent as LifeInsurance } from '../../../assets/icons/Life-Insurance.svg';

const icons = [
  {
    label: 'Auto Insurance',
    type: EMobileAppLinkTypeEnums.AUTO_INSURANCE,
    icon: <AutoInsurance />
  },
  {
    label: 'Boat Insurance',
    type: EMobileAppLinkTypeEnums.BOAT_INSURANCE,
    icon: <BoatInsurance />
  },
  {
    label: 'Forms',
    type: EMobileAppLinkTypeEnums.FORMS,
    icon: <Forms />
  },
  {
    label: 'Home Insurance',
    type: EMobileAppLinkTypeEnums.HOME_INSURANCE,
    icon: <HomeInsurance />
  },
  {
    label: 'Life Insurance',
    type: EMobileAppLinkTypeEnums.LIFE_INSURANCE,
    icon: <LifeInsurance />
  },
  {
    label: 'Download to Home Screen',
    type: EMobileAppLinkTypeEnums.INSTRUCTIONS,
    icon: <Instructions />
  },
  {
    label: 'Schedule Meeting',
    type: EMobileAppLinkTypeEnums.CALENDAR,
    icon: <Meeting />
  },
  {
    label: 'Contact Support',
    type: EMobileAppLinkTypeEnums.TALK_TO_ADVISOR,
    icon: <ContactSupport />
  },
  {
    label: 'Benefit Info',
    type: EMobileAppLinkTypeEnums.BENEFITS_INFO,
    icon: <BenefitInfo />
  },
  {
    label: 'Benefit Guide - Multilingual',
    type: EMobileAppLinkTypeEnums.BENEFITS_INFO_MULTILINGUAL,
    icon: <BenefitInfo />
  },
  {
    label: 'Video',
    type: EMobileAppLinkTypeEnums.MEDIA,
    icon: <Video />
  },
  {
    label: 'Video - Multilingual',
    type: EMobileAppLinkTypeEnums.MEDIA_MULTILINGUAL,
    icon: <Video />
  },
  {
    label: 'Chat',
    type: EMobileAppLinkTypeEnums.CHAT,
    icon: <Chat />
  },
  {
    label: 'Find A Provider',
    type: EMobileAppLinkTypeEnums.FIND_A_DOCTOR,
    icon: <Location />
  },
  {
    label: 'Enroll Online',
    type: EMobileAppLinkTypeEnums.ENROLL_In_BENEFITS,
    icon: <Enroll />
  },
  {
    label: 'Enroll Online  - Multilingual',
    type: EMobileAppLinkTypeEnums.ENROLL_In_BENEFITS_MULTILINGUAL,
    icon: <Enroll />
  },
  {
    label: 'Telemedicine',
    type: EMobileAppLinkTypeEnums.VIRTUAL_DOCTOR_VISIT,
    icon: <Telemed />
  },
  {
    label: 'Rx',
    type: EMobileAppLinkTypeEnums.PRESCRIPTION_DISCOUNTS,
    icon: <Rx />
  },
  {
    label: 'Wellness',
    type: EMobileAppLinkTypeEnums.WELLNESS_INCENTIVES,
    icon: <Wellness />
  },
  {
    label: 'Mental Health',
    type: EMobileAppLinkTypeEnums.MENTAL_HEALTH,
    icon: <MentalHealth />
  },
  {
    label: 'Medical Concierge',
    type: EMobileAppLinkTypeEnums.SPEAK_TO_CONCIERGE,
    icon: <MedicalConcierge />
  },
  {
    label: '401K',
    type: EMobileAppLinkTypeEnums.RETIREMENT,
    icon: <Retirement />
  },
  {
    label: 'Payroll',
    type: EMobileAppLinkTypeEnums.PAYROLL,
    icon: <Payroll />
  },
  {
    label: 'Screen Share',
    type: EMobileAppLinkTypeEnums.SCREEN_SHARE,
    icon: <OnlineSupport />
  },
  {
    label: 'Webinar',
    type: EMobileAppLinkTypeEnums.WEBINAR,
    icon: <Webinar />
  },
  {
    label: 'Webinar - Multilingual',
    type: EMobileAppLinkTypeEnums.WEBINAR_MULTILINGUAL,
    icon: <Webinar />
  },
  {
    label: 'Legal',
    type: EMobileAppLinkTypeEnums.LEGAL,
    icon: <Legal />
  },
  {
    label: 'Identity Protection',
    type: EMobileAppLinkTypeEnums.IDENTITY_PROTECTION,
    icon: <IdentityProtection />
  },
  {
    label: 'Pet Insurance',
    type: EMobileAppLinkTypeEnums.PET_INSURANCE,
    icon: <PetInsurance />
  }
];

export const getAppIcon = formType => {
  const selectedIcon = icons.find(i => i.type === formType);
  if (selectedIcon) {
    return {
      label: selectedIcon.label,
      component: <SvgIcon viewBox='0 0 256 256'>{selectedIcon.icon}</SvgIcon>
    };
  }
  return null;
};

export const getMobileAppPreviewIcon = (icon, appButtonColor, textColor, linkClass, iconClass, nameClass) => {
  const selectedIcon = icons.find(i => i.type === icon.type);
  if (selectedIcon) {
    return (
      <div>
        <div className={linkClass}>
          <SvgIcon style={{ color: appButtonColor }} className={iconClass} viewBox={icon.type === EMobileAppLinkTypeEnums.MEDIA ? '0 0 256 256' : '0 0 400 331'}>
            {selectedIcon.icon}
          </SvgIcon>
        </div>
        {icon.name && (
          <Typography style={{ color: textColor }} align='center' className={nameClass}>
            {icon.name}
          </Typography>
        )}
      </div>
    );
  }
  return null;
};
