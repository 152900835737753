import React from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSharedStyles } from '../../../../../../shared/styles';
import { IBusinessClientDetailFormValues, IEligibilityRulesDeduction } from '../../../../../../../../../models';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import useBusinessClassOptionsDropdownCollection from '../../../../../../../../../hooks/dropdown-collection/useBusinessClassOptionsDropdownCollection';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';


const EligibilityRulesDeductionTable: React.FC = () => {
    const classes = useSharedStyles();

    const businessClassOptionsRequest = useBusinessClassOptionsDropdownCollection();
    const businessClassOptions = businessClassOptionsRequest.data;
    const businessClassLoading = businessClassOptionsRequest.status === "loading";

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const eligibilityRulesDeductions = values.eligibilityRules?.eligibilityRulesDeductions ?? [];
    const eligibilityRulesDeductionsErrors = errors.eligibilityRules?.eligibilityRulesDeductions as FormikErrors<IEligibilityRulesDeduction>[];

    return (
        <FieldArray name='eligibilityRules.eligibilityRulesDeductions'>
            {({ push, remove }) => (
                <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
                    {eligibilityRulesDeductions.length > 0 ? (
                        <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.tableCell}>Deduction Frequency</TableCell>
                                                <TableCell className={classes.tableCell}>Hours Required for Eligibility</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {eligibilityRulesDeductions.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        <GenericDropdown handleBlur={handleBlur}
                                                            options={businessClassOptions?.classPayCycles ?? []}
                                                            isLoading={businessClassLoading}
                                                            setFieldValue={setFieldValue}
                                                            value={item.deductionFrequency ?? ''}
                                                            error={eligibilityRulesDeductionsErrors?.[index]?.deductionFrequency}
                                                            label='Deduction Frequency'
                                                            id={`eligibilityRules.eligibilityRulesDeductions.${index}.deductionFrequency`}
                                                        />

                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Field name={`eligibilityRules.eligibilityRulesDeductions.${index}.hoursRequiredForEligibility`}>
                                                            {({ field }) => (
                                                                <PerformantTextField
                                                                    {...field}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    size='small'
                                                                    autoComplete='nope'
                                                                    label='Hours Required for Eligibility'
                                                                    onBlur={handleBlur}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FieldErrorMessage message={eligibilityRulesDeductionsErrors?.[index]?.hoursRequiredForEligibility} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            startIcon={<Close />}
                                                            onClick={() => remove(index)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}

                    {eligibilityRulesDeductions.length < 8 && (
                        <Button
                            color='primary'
                            variant='contained'
                            className={classes.addClassButton}
                            onClick={() =>
                                push({
                                    deductionFrequency: null,
                                    hoursRequiredForEligibility: null,
                                })
                            }
                        >
                            Add Deduction Rule
                        </Button>
                    )}
                </div>
            )}
        </FieldArray>
    );
};

export default EligibilityRulesDeductionTable;
