import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
// import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
// Componnents
import { Typography, Divider } from '@mui/material';

export const Footer = () => {
  const classes = useStyles();

  const isDesktop = useMediaQuery('(min-width: 600px)');
  return (
    <footer className={classes.wrapper}>
      <Typography variant='caption' className={classes.copyRight}>
        &copy; Copyright {new Date().getFullYear()}. Enrollment Alliance
      </Typography>
      {!isDesktop && <Divider />}
      <div className={classes.footerLinks}>
        <div className={classes.footerLinkWrapper}>
          {/* <Link to='/' className={classes.footerLink}>
            CONTACT US
          </Link> */}
        </div>
      </div>
    </footer>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(0.25),
    '@media (min-width: 600px)': {
      display: 'flex',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  footerLinks: {
    width: '100%',
    '@media (min-width: 600px)': {
      position: 'absolute',
      right: theme.spacing(1),
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      width: 'auto'
    }
  },
  footerLinkWrapper: {
    margin: '10px 0',
    textAlign: 'center',
    '@media (min-width: 600px)': {
      margin: '0 10px',
      textAlign: 'baseline'
    }
  },
  footerLink: {
    color: theme.palette.primary.main,
    letterSpacing: theme.spacing(0.1),
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  copyRight: {
    color: theme.palette.grey[600],
    paddingBottom: 10,
    display: 'block',
    textAlign: 'center',
    '@media (min-width: 600px)': {
      paddingBottom: 0,
      textAlign: 'baseline'
    }
  }
}));
