import CachedIcon from '@mui/icons-material/Cached';
import { FC, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Loader } from '..';
import { deleteCSVFile, getEmployeesUploadProcessing } from '../../fetch';
import { IEmployeeUploadProcessing } from '../../models';
import { TimeZoneContext } from '../../context/timezone';
import { formatShortFriendlyDateWithTime } from '../../helpers';
import CloseIcon from '@mui/icons-material/Close';

interface IEmployeesUploadsStatusProps {
    businessClientId: number;
    shouldLoadData: boolean;
}

export const EmployeesUploadsStatus: FC<IEmployeesUploadsStatusProps> = ({ businessClientId, shouldLoadData }) => {
    const classes = useStyles();

    const { timeZone } = useContext(TimeZoneContext);

    const [data, setData] = useState<IEmployeeUploadProcessing[]>(null);
    const [isLoading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await getEmployeesUploadProcessing(businessClientId);

            setData(res);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    // Cancel Employees Upload Processing if Queue is not Send to Azure. 
    const deleteCSV = async id => {
        try {
            setLoading(true);
            await deleteCSVFile(businessClientId, id);
            await fetchData();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        if (shouldLoadData) {
            fetchData();
        }

    }, [shouldLoadData]);


    useEffect(() => {
        fetchData();

    }, []);

    return (<>

        {isLoading && <Loader position='centered' />}


        {!isLoading && data && data.length > 0 && (
            <Box className={classes.dataWrapper}>
                {data
                    .map((item) => {
                        return (
                            <div className={classes.uploadItemContainer}>

                                <div className={classes.container} style={{ alignItems: 'end' }}>
                                    <div className={classes.progressWrapper}>
                                        <strong className={classes.progressFileName}>{item.fileName}</strong>
                                        <progress className={classes.progress} id="processedPercentage" value={item.processedPercentage} max="100"></progress>
                                    </div>
                                    <div className={classes.deleteProgress}>
                                        <CloseIcon onClick={() => { deleteCSV(item.id); }} aria-hidden="true" style={{ cursor: 'pointer', fill: '#fff', height: '16px', width: '16px' }} />
                                    </div></div>

                                <div className={classes.progressDetails}>
                                    <p className={classes.progressDetailsText}>{item.processedAmount} of {item.totalEmployees}</p>


                                    <p className={classes.progressDetailsText}>{item.userName}</p>


                                    <p className={classes.progressDetailsText}>{
                                        formatShortFriendlyDateWithTime(item.dateTime, timeZone)
                                    }</p>


                                    <strong className={classes.progressDetailsText}>{Math.trunc(item.processedPercentage)}%</strong>

                                </div>

                            </div>
                        );
                    })}

                <Button className={classes.refreshButtom} size="small" variant="contained" id='create-employee' disabled={isLoading} startIcon={<CachedIcon />} onClick={() => fetchData()} color='info'>
                    Refresh
                </Button>
            </Box>


        )


        }



    </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({

    refreshButtom: {
        marginBottom: '.5em'
    },

    uploadItemContainer: {
        marginBottom: '0'
    },

    progressbarContainer: {
        display: 'flex',
        flexDirection: 'column'
    },

    progressDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, .5),

        '@media (max-width: 450px)': {
            flexDirection: 'column',
            marginBottom: '10px'
        }
    },


    progressDetailsText: {
        color: theme.palette.grey[500],
        marginTop: theme.spacing(.4),
        fontSize: '11pt'
    },

    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(0),
    },

    progressWrapper: {
        flexGrow: 1,
        marginRight: theme.spacing(1),
    },

    progressFileName: {
        marginBottom: theme.spacing(1),
        fontSize: '11pt',
    },

    progress: {
        '-webkit-appearance': 'none',
        width: '100%',
        height: '10px',

        '&&::-webkit-progress-bar': {
            backgroundColor: '#ddd',
            borderRadius: '10px'
        },

        '&&::-webkit-progress-value': {
            backgroundColor: '#3bbce7',
            borderRadius: '10px'
        }

    },
    dataWrapper: {
        marginTop: '1.5em',
        padding: theme.spacing(0),

    },

    deleteProgress: {
        height: '20px',
        width: '20px',
        background: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        borderRadius: '50%'
    },
    ProgressMain: {
        alignItems: 'end'
    },

}));
