import React, {useState} from 'react'
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, FormLabel as MuiLabel, FormControl, Grid, TextField, FormHelperText, Select, InputLabel, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues, IDataFileRecipient } from '../../../../../../../../../models';
import { useEntitiesDropdownsOptionsCollection } from '../../../../../../../../../context/EntitiesDropdownsOptionsCollectionCtx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { useCurrentBusinessClient } from '../../../../../../contexts/CurrentBusinessClientContext';
import { DatePicker } from '../../../../../../../../../components';

export default function BenAdminSystem() {

    const isMobile = useMediaQuery('(max-width: 960px)');

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();
    const classes = useStyles();

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const dataFileRecipients = generalDropdownsOptionsRequest.data?.dataFileRecipientsOptions;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    const { systemOfRecords, brokers } = useEntitiesDropdownsOptionsCollection();
    const systemOfRecordsLoading = systemOfRecords.length === 0;

    const currentBusinessClient = useCurrentBusinessClient();

    const [originalDataFileRecipients] = useState<IDataFileRecipient[]>(currentBusinessClient?.dataFileRecipients || []);

    const handleUpdateOtherDescription = (value: string) => {
        let copy = values.dataFileRecipients.map(option => {
            if (option.recipient === 'Other') {
                return { ...option, otherRecipientDescription: value };
            } else return option;
        });
        setFieldValue('dataFileRecipients', copy);
    };

    const checkDataFileRecipientIds = (name: string) => {
        // Check if this field already exists on the currentBusinessClient,
        // If so, use existing ID instead of generating a new one
        const isSelected = originalDataFileRecipients.filter(option => option.recipient === name).length > 0 ? true : false;
        if (isSelected) {
          return originalDataFileRecipients.filter(option => option.recipient === name)[0].businessClientDataFileRecipientId;
        } else return 0;
      };

    const toggleSelect = (field: string, value: boolean) => {
        let copy = [...values.dataFileRecipients];
        let isSelected = values.dataFileRecipients.filter(option => option.recipient === field).length > 0 ? true : false;
        // If unchecked and already selected, remove from array
        if (isSelected && !value) {
          copy = copy.filter(option => option.recipient !== field);
        }
        // If checked and not selected, add to array
        if (!isSelected && value) {
          copy = [
            ...copy,
            {
              businessClientDataFileRecipientId: checkDataFileRecipientIds(field),
              recipient: field,
              otherRecipientDescription: null
            }
          ];
        }
        setFieldValue('dataFileRecipients', copy);
      };

    return (<>
        <Grid container spacing={1} >

        <Grid item xs={12} md={12}>
            <FormControl component='fieldset'>
              <MuiLabel component='legend'>Is Enrollment Alliance providing the ben admin system for this client?</MuiLabel>
              <RadioGroup
                row
                aria-label={`isEnrollmentAllianceProvidingBenAdminSystem`}
                name={`isEnrollmentAllianceProvidingBenAdminSystem `}
                value={values.isEnrollmentAllianceProvidingBenAdminSystem}
                color='primary'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value === 'true') {
                    setFieldValue('isEnrollmentAllianceProvidingBenAdminSystem', true);
                  } else {
                    setFieldValue('isEnrollmentAllianceProvidingBenAdminSystem', false);
                  }
                }}
              >
                <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                <FormControlLabel value={false} control={<Radio color='primary' />} label='No' />
              </RadioGroup>
            </FormControl>
          </Grid>

            <Grid item xs={12} sm={6} lg={3}>
                <FormControl fullWidth variant='outlined' size='small' required error={touched && errors.systemOfRecord ? true : false}>
                    <InputLabel id={`system-of-record`} className={classes.outlinedLabel}>
                        System of Record
                    </InputLabel>
                    <Select
                        name={`system-of-record`}
                        labelId={`system-of-record`}
                        id={`system-of-record`}
                        value={values.systemOfRecord}
                        size='small'
                        onBlur={handleBlur}
                        onChange={({ target: { value } }) => setFieldValue('systemOfRecord', value)}
                    >
                        {!systemOfRecordsLoading &&
                            systemOfRecords.map((record, index) => {
                                return (
                                    <MenuItem key={`${index}`} value={record.name}>
                                        {record.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {touched && errors.systemOfRecord && <FormHelperText>{errors.systemOfRecord}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <TextField
                    fullWidth
                    variant='outlined'
                    autoComplete='nope'
                    size='small'
                    label='Enrollment System URL'
                    name='systemOfRecordUrl'
                    placeholder='www.example.com'
                    value={values.systemOfRecordUrl}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('systemOfRecordUrl', e.target.value)}
                    error={Boolean(touched.systemOfRecordUrl && errors.systemOfRecordUrl)}
                    helperText={touched.systemOfRecordUrl && errors.systemOfRecordUrl}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <TextField
                    fullWidth
                    variant='outlined'
                    autoComplete='nope'
                    size='small'
                    label='System Username'
                    name='systemOfRecordUsername'
                    value={values.systemOfRecordUsername}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('systemOfRecordUsername', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    autoComplete='nope'
                    label='System Password'
                    name='systemOfRecordPassword'
                    value={values.systemOfRecordPassword}
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('systemOfRecordPassword', e.target.value)}
                />
            </Grid>


            <Grid item xs={12}>
                <FormControl component='fieldset' sx={{ marginTop: isMobile ? theme => theme.spacing(0.5) : 0 }}>
                    <MuiLabel component='legend'>Is Enrollment Alliance responsible for providing a data file to any party?</MuiLabel>
                    <RadioGroup
                        row
                        aria-label={`provideDataFile`}
                        name={`provideDataFile`}
                        value={values.provideDataFile}
                        color='primary'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('provideDataFile', e.target.value === 'true' ? true : false);
                            if (e.target.value === 'false') {
                                setFieldValue('dataFileRecipients', []);
                                setFieldValue('dataDeliveryDate', '');
                            }
                        }}
                    >
                        <FormControlLabel value={true} control={<Radio color='primary' />} label='Yes' />
                        <FormControlLabel value={false} control={<Radio color='primary' />} label='No - client and/or broker is managing this process' />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {!!values.provideDataFile && (
                <>
                    {dataFileRecipients && dataFileRecipients.length > 0 && !generalOptionsLoading && (
                        <Grid item xs={12} sm={6}>
                            <FormControl component='fieldset' fullWidth sx={{ marginTop: isMobile ? theme => theme.spacing(0.5) : 0 }}>
                                <MuiLabel component='legend'>Provide data to:</MuiLabel>
                                <FormGroup>
                                    <Grid container sx={{ marginTop: 0 }}>
                                        {dataFileRecipients.map((type, index) => {
                                            const field = values.dataFileRecipients.filter(option => option.recipient === type.value);
                                            const isChecked = field.length > 0 ? true : false;
                                            const otherDescription = isChecked ? field[0].otherRecipientDescription : null;
                                            return (
                                                <Grid item xs={12} md={type.value === 'Other' ? 12 : 'auto'} lg={'auto'} key={index}>
                                                    <FormControlLabel
                                                        value={isChecked}
                                                        control={
                                                            <Checkbox
                                                                color='primary'
                                                                checked={isChecked}
                                                                name={`data-file-recipients-${type.value}`}
                                                                onChange={(_, checked: boolean) => toggleSelect(type.value, checked)}
                                                            />
                                                        }
                                                        label={type.description}
                                                    />
                                                    {type.value === 'Other' && isChecked && (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            variant='outlined'
                                                            autoComplete='nope'
                                                            size='small'
                                                            label='Other'
                                                            name={`data-file-recipients-${type.value}-description`}
                                                            value={otherDescription}
                                                            onBlur={handleBlur}
                                                            error={isChecked && !otherDescription}
                                                            helperText={isChecked && !otherDescription ? 'Required' : undefined}
                                                            onChange={e => handleUpdateOtherDescription(e.target.value)}
                                                            sx={{ marginTop: theme => theme.spacing(0.5) }}
                                                        />
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant='outlined'>
                            <DatePicker
                                label='Data Delivery Date'
                                value={values.dataDeliveryDate ? new Date(values.dataDeliveryDate) : null}
                                renderInput={props => <TextField {...props} size='small' label='Data Delivery Date' value={values.dataDeliveryDate ? new Date(values.dataDeliveryDate) : null} />}
                                onChange={(date: Date) => {
                                    setFieldValue(`dataDeliveryDate`, date?.toISOString?.() ? date?.toISOString() : '');
                                }}
                            />
                            <FormHelperText>Buffer period for HR and Broker to make changes</FormHelperText>
                        </FormControl>
                    </Grid>
                </>
            )}
        </Grid>
    </>

    )
}


const useStyles = makeStyles((theme: Theme) => ({
    subHeader: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        '&&:first-child': {
            marginTop: 0
        }
    },
    outlinedLabel: {
        backgroundColor: theme.palette.common.white,
        paddingLeft: 2,
        paddingRight: 2
    },
    divider: {
        marginTop: theme.spacing(1)
    },
    repeaterTItle: {
        margin: theme.spacing(0.5, 0, 1)
    },
    repeaterRemoveButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '-8px',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            justifyContent: 'flex-start'
        }
    },
    repeaterRemoveButton: {
        color: theme.palette.error.main,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            minWidth: 0
        },
        '&& .MuiButton-startIcon': {
            [theme.breakpoints.up('md')]: {
                margin: 0
            }
        }
    },
    repeaterRemoveButtonText: {
        display: 'inline',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));
