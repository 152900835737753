import { useEffect, useState } from "react";

import { IUser } from "../../../../models";
import { getUsers } from "../../../../fetch";
import { RequestState } from "../../../../models/request-state";
import useErrorMessage from "../../../../context/ErrorMessageCtx";

const useUsers = (): RequestState<IUser[]> => {
    const [, setErrorMessage] = useErrorMessage();

    const [requestState, setRequestState] = useState<RequestState<IUser[]>>({
        status: 'idle'
    });

    useEffect(() => {

        if (requestState.status !== "idle") return;

        const fetch = async () => {

            setRequestState({
                status: 'loading'
            });

            try {
                const searchParams = {
                    sortBy: 'FirstName',
                    sortDirection: 'Asc',
                    isActive: true,
                    perPage: '2147483647', // get all records
                    userRole: 'Administrator'
                };
                const res = await getUsers(searchParams);
                if (res.records) {
                    setRequestState({
                        status: 'success',
                        data: res.records
                    });
                } else {

                    setRequestState({
                        status: 'error',
                        error: 'Error loading users, please try again.'
                    });

                    setErrorMessage('Error loading users, please try again.');
                }
            } catch (error) {

                setRequestState({
                    status: 'error',
                    error: 'Error loading users, please try again.'
                });

                setErrorMessage('Error loading users, please try again.');
            }
        };


        fetch();
    }, []);

    return requestState;

};

export default useUsers