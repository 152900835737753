import React from 'react';
import { FieldArray, Field } from 'formik';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormHelperText,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ILongTermDisabilityCoverageClass } from '../../../models';
import { PerformantTextField } from '../../../components/inputs/PerformantTextField';
import FieldErrorMessage from '../../../components/field-error-message/FieldErrorMessage ';
import { useSharedStyles } from './shared/styles';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import useDefaultDropdownsOptionsDropdownCollection from '../../../hooks/dropdown-collection/useDefaultDropdownsOptionsDropdownCollection';

interface ILongTermDisabilityClassTableProps {
  longTermDisabilityClassTable: ILongTermDisabilityCoverageClass[];
  handleBlur: any;
  setFieldValue: (field: string, value: any) => void;
  values: any;
  errors: any;
}

const LongTermDisabilityCoverageClassesTable: React.FC<ILongTermDisabilityClassTableProps> = ({
  longTermDisabilityClassTable,
  handleBlur,
  errors,
  setFieldValue
}) => {
  const classes = useSharedStyles();

  const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
  const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
  const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading';

  const defaultDropdownsOptionsRequest = useDefaultDropdownsOptionsDropdownCollection();
  const defaultDropdownsOptions = defaultDropdownsOptionsRequest.data;
  const defaultDropdownsOptionsLoading = defaultDropdownsOptionsRequest.status === 'loading';

  return (
    <FieldArray name="longTermDisabilityCoverage.longTermDisabilityCoverageClasses">
      {({ push, remove }) => (
        <div style={{ marginBottom: '2em', marginTop: '.6em' }}>
          {longTermDisabilityClassTable.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}></TableCell>
                    <TableCell className={classes.tableCell}>Class Name</TableCell>

                    <TableCell className={classes.tableCell}>Policy Type</TableCell>
                    <TableCell className={classes.tableCell}>GI Eligibility</TableCell>
                    <TableCell className={classes.tableCell}>Payment Type</TableCell>
                    <TableCell className={classes.tableCell}>Elimination/Accumulation Periods</TableCell>

                    <TableCell className={classes.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {longTermDisabilityClassTable.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <Field
                          name={`longTermDisabilityCoverage.longTermDisabilityCoverageClasses.${index}.className`}
                          as={PerformantTextField}
                          fullWidth
                          variant="outlined"
                          size="small"
                          autoComplete="nope"
                          label="Class Name"
                          onBlur={handleBlur}
                        />
                        <FieldErrorMessage message={errors.longTermDisabilityCoverage?.longTermDisabilityCoverageClasses?.[index]?.className} />
                      </TableCell>
                      {/*Aqui*/}
                      <TableCell className={classes.tableCell}>

                        <GenericDropdown
                          handleBlur={handleBlur}
                          options={generalDropdownsOptions?.stdCoverageClassPolicyTypes ?? []}
                          isLoading={generalDropdownsOptionsLoading}
                          setFieldValue={setFieldValue}
                          value={item?.policyType}
                          error={errors?.longTermDisabilityCoverage?.longTermDisabilityCoverageClasses?.[index]?.policyType}
                          label='Policy Type'

                          id={`longTermDisabilityCoverage.longTermDisabilityCoverageClasses.${index}.policyType`}
                        />

                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <GenericDropdown
                          handleBlur={handleBlur}
                          options={defaultDropdownsOptions?.defaultGIEligibilityOptions ?? []}
                          isLoading={defaultDropdownsOptionsLoading}
                          setFieldValue={setFieldValue}
                          value={item?.giEligibility}
                          error={errors?.longTermDisabilityCoverage?.longTermDisabilityCoverageClasses?.[index]?.giEligibility}
                          label='GI Eligibility'

                          id={`longTermDisabilityCoverage.longTermDisabilityCoverageClasses.${index}.giEligibility`}
                        />

                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <GenericDropdown
                          handleBlur={handleBlur}
                          options={defaultDropdownsOptions?.defaultPaymentTypeOptions ?? []}
                          isLoading={defaultDropdownsOptionsLoading}
                          setFieldValue={setFieldValue}
                          value={item?.paymentType}
                          error={errors?.longTermDisabilityCoverage?.longTermDisabilityCoverageClasses?.[index]?.paymentType}
                          label='Payment Type'

                          id={`longTermDisabilityCoverage.longTermDisabilityCoverageClasses.${index}.paymentType`}
                        />

                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <GenericDropdown
                          handleBlur={handleBlur}
                          options={defaultDropdownsOptions?.defaultEliminationAccumulationPeriodsOptions ?? []}
                          isLoading={defaultDropdownsOptionsLoading}
                          setFieldValue={setFieldValue}
                          value={item?.eliminationAccumulationPeriods ?? ''}
                          error={errors?.longTermDisabilityCoverage?.longTermDisabilityCoverageClasses?.[index]?.eliminationAccumulationPeriods}
                          label="Elimination/Accumulation Periods"
                          id={`longTermDisabilityCoverage.longTermDisabilityCoverageClasses.${index}.eliminationAccumulationPeriods`}
                        />
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Button startIcon={<Close />} onClick={() => remove(index)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <></>
          )}

          {longTermDisabilityClassTable.length < 2 && (
            <Button
              color="primary"
              variant="contained"
              className={classes.addClassButton}
              onClick={() =>
                push({
                  className: '',
                  maxWeeklyBenefit: '',
                })
              }
            >
              Add Class
            </Button>
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default LongTermDisabilityCoverageClassesTable;
