export const getUserErrorHandling = (res: any): { hasError: boolean; message?: string } => {
  // the api sends a boolean on success
  // or a valid uuid string, if there is an error, the service worker sends back html
  if (res && !res.Errors && (typeof res === 'boolean' || (res && !res.startsWith('<')))) {
    return {
      hasError: false
    };
  } else if (res && res.Errors) {
    let message = '';

    if (res.Errors.UserId) {
      // grabbing the first instance of the string in the array
      message = res.Errors.UserId[0];
    }

    if (res.Errors[`Body.Password`]) {
      // grabbing the first instance of the string in the array
      message = res.Errors[`Body.Password`][0];
    }

    if (res.Errors[`Body.BusinessClientId`]) {
      // grabbing the first instance of the string in the array
      message = res.Errors[`Body.Password`][0];
    }

    if (res.Errors.command) {
      // grabbing the first instance of the string in the array
      message = res.Errors.command[0];
    }
    // validation errors
    return {
      hasError: true,
      message
    };
  } else {
    // server errors
    return {
      hasError: true,
      message: 'Error, please try again'
    };
  }
};
