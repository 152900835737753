import * as React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@mui/styles";
import { MenuItem } from '../../../pages/admin/business-client-detail/BusinessClientDetailPage/MenuData/types';
import { Typography } from '@mui/material';

export interface IAppProps {
    item: MenuItem;
    selected: boolean;
    expanded?: boolean;
    disablePadding: boolean;
    hasErrors: boolean;
    onClick(item: MenuItem): void,
    insetTitle?: boolean;
}

const useStyles = makeStyles({

    rootStyles: {
        color: '#11a5c5',
        backgroundColor: '#fff',
        '&:hover': {
            'backgroundColor': 'rgba(17, 165, 197, 0.08)',
        },

        '&.Mui-selected': {
            borderRight: '4px solid #11a5c5',
            color: '#11a5c5',
            backgroundColor: 'rgba(17, 165, 197, 0.08)',
            '&:hover': {
                'backgroundColor': 'rgba(17, 165, 197, 0.04)',
            }
        }

    },

    withErrorRootStyles: {
        color: '#A94442',
        backgroundColor: 'rgba(255, 0, 0, .1)',

        '&:hover': {
            'color': '#A94442',
            'backgroundColor': 'rgba(255, 0, 0, .2)',
        },

        '&.Mui-selected': {
            borderRight: '4px solid #A94442',
            color: '#A94442',
            backgroundColor: 'rgba(255, 0, 0, .1)',
            '&:hover': {
                'color': '#A94442',
                'backgroundColor': 'rgba(255, 0, 0, .2)',
            }
        },

    },


});

export default function ListMenuItem({ item, onClick, disablePadding, selected, expanded, hasErrors, insetTitle }: IAppProps) {

    const classes = useStyles();

    const clonedIcon = item.secondaryIconElement ? React.cloneElement(item.secondaryIconElement, {
        icon: item.secondaryIconElement?.props?.icon,
        size: 20
    }) : null;

    return (
        <ListItem
            data-cy={item.id}
            key={item.id}
            disablePadding={disablePadding}
            onClick={() => onClick(item)}
        >
            <ListItemButton classes={{ root: hasErrors ? classes.withErrorRootStyles : classes.rootStyles }} selected={selected}>
                {clonedIcon && (
                    <ListItemIcon>
                        {clonedIcon}
                    </ListItemIcon>
                )}

                {!clonedIcon && item.iconComponent && (
                    <ListItemIcon>
                        <item.iconComponent />
                    </ListItemIcon>
                )}
                <ListItemText inset={insetTitle} primary={
                    <Typography data-cy={`menu-title-${item.id}`} variant='h6' color={hasErrors ? '#A94442' : 'primary'} sx={{ fontWeight: 300, fontSize: '.86em' }}>
                        {item.title}
                    </Typography>

                } />
                {item.subMenu && item.subMenu?.length && (
                    <>
                        {item.subMenu &&
                            item.subMenu?.length &&
                            expanded ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </>
                )}
            </ListItemButton>
        </ListItem>
    );
}
