export interface IUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  clientName: string;
  isActive: boolean;
}

export interface IUserDetail {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  businessClient: {
    businessClientId: number;
    name: string;
    broker: {
      brokerId: number;
      name: string;
    };
  }[];
  broker: {
    brokerId: number;
    name: string;
  };
  isActive: boolean;
}

export type UserRoles =
  | 'Administrator'
  | 'Carrier Partner'
  | 'General Agent'
  | 'Account Manager'
  | 'Broker Client'
  | 'Broker Manager'
  | 'Broker'
  | 'Business Client'
  | 'Enrolling Agent'
  | 'Manager'
  | 'Broker Client Manager';
export enum EUserRoles {
  ADMIN = 'Administrator',
  BROKER_CLIENT = 'BrokerClient',
  BROKER_MANAGER = 'BrokerManager',
  BROKER = 'Broker',
  BUSINESS_CLIENT = 'BusinessClient',
  ENROLLING_AGENT = 'EnrollingAgent',
  MANAGER = 'Manager',
  BROKER_CLIENT_MANAGER = 'BrokerClientManager'
}

export interface IUserRes {
  records: IUser[];
  totalRecordCount: number;
}

export interface IUserRole {
  value: string;
  description: string;
  shorthand: string;
}

export interface IUserPost {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
  brokerId: number;
  businessClientIds: number[];
}

export interface IUserPut {
  isActive: boolean;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
  brokerId: number;
  businessClientIds: number[];
}

export interface ILoginPost {
  email: string;
  password: string;
}

export interface IResetPasswordPost {
  email: string;
  resetPasswordToken: string;
  newPassword: string;
}

export interface ILoginUser {
  username: string;
  name: string | null;
  email: string;
  accessTokenExpired: string;
  accessTokenExpiresAt: string;
  userId: string;
  firstName: string;
  lastName: string;
  roles: UserRoles[];
  accessToken: string;
  brokerId: number;
  brokerLogo: string;
  businessClientIds: number[];
  businessClientLogo: string;
}
export interface IForgotPasswordPost {
  email: string;
}
export interface IResetPasswordPost {
  email: string;
  resetPasswordToken: string;
  newPassword: string;
}
