import { useEffect, useState } from "react";

import { IBusinessClientDetail } from "../../../../models";
import { getBusinessClient } from "../../../../fetch";
import { RequestState } from "../../../../models/request-state";
import useErrorMessage from "../../../../context/ErrorMessageCtx";

const useBusinessClient = (id: number): RequestState<IBusinessClientDetail> => {
    const [, setErrorMessage] = useErrorMessage();

    const [requestState, setRequestState] = useState<RequestState<IBusinessClientDetail>>({
        status: 'idle'
    });

    useEffect(() => {

        if (requestState.status !== "idle") return;

        const fetchData = async () => {

            setRequestState({
                status: 'loading'
            });

            try {
                const res = await getBusinessClient(id);
                if (res) {
                    setRequestState({
                        status: 'success',
                        data: res
                    });
                } else {

                    setRequestState({
                        status: 'error',
                        error: 'Error loading Business Client, please try again.'
                    });

                    setErrorMessage('Error loading Business Client, please try again.');
                }
            } catch (error) {

                setRequestState({
                    status: 'error',
                    error: 'Error loading Business Client, please try again.'
                });

                setErrorMessage('Error loading Business Client, please try again.');
            }
        };


        fetchData();
    }, []);

    return requestState;

};

export default useBusinessClient