import { axios, getUserAccessTokenCookie } from '../helpers';
import { IBrokerRes, IBrokerPost, IBrokerPut, IBrokerDashboardRes, IImageUploadRes } from '../models';

export const getBrokers = async (filters: { page?: number; perPage?: number | string; sortBy?: string; sortDirection?: string; search?: string }): Promise<IBrokerRes> => {
  try {
    const res = await axios.get(
      `Brokers`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      filters
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBroker = async (BrokerId: number) => {
  try {
    const res = await axios.get(`Brokers/${BrokerId}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const createBroker = async (data: IBrokerPost) => {
  try {
    const res = await axios.post(`Brokers`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const updateBroker = async (id: number, data: IBrokerPut) => {
  try {
    const res = await axios.put(`Brokers/${id}`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const deleteBroker = async (id: number) => {
  try {
    const res = await axios.delete(`Brokers/${id}`, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`
      }
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getBrokersDashboard = async (params: { type: string; brokerId?: number; search?: string; page?: number; perPage?: number }): Promise<IBrokerDashboardRes> => {
  try {
    const res = await axios.get(
      `brokers/dashboard`,
      {
        headers: {
          Authorization: `Bearer ${getUserAccessTokenCookie()}`
        }
      },
      params
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const storeBrokerCompanyLogo = async (id: number, data: FormData): Promise<IImageUploadRes> => {
  try {
    const res = await axios.put(`Brokers/${id}/Logo`, data, {
      headers: {
        Authorization: `Bearer ${getUserAccessTokenCookie()}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    console.error('Error: ', error);
    return error.response.data;
  }
};
