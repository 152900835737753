import * as React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { OfferedNotOfferedBenefit } from '../../models';

interface IOfferedNotOfferedBenefitSummaryProps {
    benefitsList: OfferedNotOfferedBenefit[]
}

export default function OfferedNotOfferedBenefitSummary({ benefitsList }: IOfferedNotOfferedBenefitSummaryProps) {

    const classes = useStyles();

    return (
        <div className={classes.listContainer} id="offeredNotOfferedBenefitSummary">
            <ul className={classes.list}>
                {benefitsList
                    .map((item, index) => {
                        return (
                            <li title={item.benefitName} className={classes.listItem} key={`${index}`}>
                                <span className={classes.listTitle}>{item.benefitName}</span>
                                <span>{item.offered ? <CheckCircleOutlineIcon data-cy={`${item.benefitName} checked`} style={{ fill: "green" }} /> : <HighlightOffIcon data-cy={`${item.benefitName} unchecked`} style={{ fill: 'rgba(0, 0, 0, 0.26)' }} />}</span>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    listContainer: {
        position: 'sticky',
        top: '25px'
    },

    list: {
        width: '176px',
        padding: '0',
        margin: '.3em 0',

        '@media (max-width: 820px)': {
            padding: '0 .5em .3em .5em',
            width: '100%',
        },
    },

    listItem: {
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'space-between'
    },

    listTitle: {
        paddingRight: '.5em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

}));
