import { InputLabel } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface IFormLabelProps {
  label: string;
  id: string;
}

export const FormLabel: FC<IFormLabelProps> = ({ label, id }) => {
  const classes = useStyles();
  return (
    <InputLabel id={id} className={clsx(classes.outlinedLabel, classes.smallerLeading)}>
      {label}
    </InputLabel>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  smallerLeading: {
    letterSpacing: '-.25px'
  }
}));
