import React from 'react'
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';

import { PerformantTextField } from '../../../../../../../../components/inputs/PerformantTextField';
import { useSharedStyles } from '../../../../../shared/styles';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';


export default function CompanyPreferences() {

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();


    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('company_preferences');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
        </div>
    )
}