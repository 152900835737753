import React, { FC, useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { deepEqual } from 'fast-equals';
import { Add, Save, Close, RecentActors } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
// Components
import { Toast, Modal, Loader, PasswordRequirements } from '../../../components';
import {
  Card,
  Fade,
  CardActions,
  Button,
  CardContent,
  CardHeader,
  TextField,
  Grid,
  FormControl,
  Paper,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  FormHelperText,
  Switch,
  FormControlLabel
} from '@mui/material';
// models
import { IUserDetail, IUserRole, EUserRoles, UserRoles, IBroker, IBusinessClient } from '../../../models';
// fetch
import { getUserRoles, updateUser, createUser, getBrokers, getBusinessClients } from '../../../fetch';
// helpers
import { formatInputPhoneNumber, passwordRegex, getUserErrorHandling } from '../../../helpers';

interface IUserModal {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  currentUser: IUserDetail | null;
}

const UserSchema = Yup.object().shape({
  firstName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  lastName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  brokerName: Yup.mixed().when('role', {
    is: (val: string) => val === EUserRoles.BROKER,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired()
  }),
  businessClientNames: Yup.mixed().when('role', {
    is: (val: string) => {
      return val && val !== 'Administrator' && val !== 'Broker';
    },
    then: Yup.array().of(Yup.string()).required('Required'),
    otherwise: Yup.array().notRequired()
  }),
  newEmail: Yup.string().required('Required').max(255, 'Max 255 characters').email('Email address invalid'),
  role: Yup.string().required('Required'),
  newPassword: Yup.mixed().when('role', {
    is: (val: string) => {
      return val !== EUserRoles.ADMIN;
    },
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Required').min(6, 'Password must be at least 6 characters').matches(passwordRegex, {
      message: 'Invalid Password'
    })
  })
});

const EditUserSchema = Yup.object().shape({
  firstName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  lastName: Yup.string().max(255, 'Max 255 characters').required('Required'),
  newEmail: Yup.string().required('Required').max(255, 'Max 255 characters').email('Email address invalid'),
  status: Yup.boolean(),
  brokerName: Yup.mixed().when('role', {
    is: (val: string) => val === EUserRoles.BROKER,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired()
  }),
  businessClientNames: Yup.mixed().when('role', {
    is: (val: string) => val && val !== 'Administrator' && val !== 'Broker',
    then: Yup.array().of(Yup.string()).required('Required'),
    otherwise: Yup.array().notRequired()
  }),
  hasInitialDummyPassoword: Yup.boolean(),
  role: Yup.string().required('Required'),
  newPassword: Yup.mixed().when('hasInitialDummyPassoword', {
    is: (val: boolean) => val,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Required').min(6, 'Password must be at least 6 characters').matches(passwordRegex, {
      message: 'Invalid Password'
    })
  })
});

export const UserModal: FC<IUserModal> = ({ open, onClose, onSave, currentUser }) => {
  const classes = useStyles();
  const [isError, showError] = useState<boolean>(false);
  const [isSuccess, showSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [roles, setRoles] = useState<IUserRole[]>([]);
  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const [brokersLoading, setBrokersLoading] = useState<boolean>(true);
  const [businessClients, setBusinessClients] = useState<IBusinessClient[]>([]);
  const [businessClientsLoading, setBusinessClientsLoading] = useState<boolean>(true);

  const fetchRoles = async () => {
    try {
      const res = await getUserRoles();
      setRoles(res);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchBrokers = async () => {
    try {
      const res = await getBrokers({
        perPage: '2147483647' // get all records
      });
      if (res.records) {
        setBrokers(res.records);
      } else {
        console.error(`No 'records' on brokers response.`);
        setErrorMessage('Error loading brokers, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading brokers, please try again.');
    }
    setBrokersLoading(false);
  };

  const fetchBusinessClients = async () => {
    try {
      const res = await getBusinessClients({
        perPage: '2147483647' // get all records
      });
      if (res.records) {
        setBusinessClients(res.records);
      } else {
        console.error(`No 'records' on business clients response.`);
        setErrorMessage('Error loading business clients, please try again.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Error loading business clients, please try again.');
    }
    setBusinessClientsLoading(false);
  };

  useEffect(() => {
    fetchBrokers();
    fetchRoles();
    fetchBusinessClients();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          role: currentUser && currentUser.role ? currentUser.role : '',
          firstName: currentUser && currentUser.firstName ? currentUser.firstName : '',
          lastName: currentUser && currentUser.lastName ? currentUser.lastName : '',
          newEmail: currentUser && currentUser.email ? currentUser.email : '',
          status: currentUser ? currentUser.isActive : true,
          // this is used to fill with a dummy password that is valid, this won't be saved if a user is updated
          newPassword: currentUser ? 'Test1234!' : '',
          // if this is changed we know they are updating the password field
          hasInitialDummyPassoword: currentUser ? true : false,
          phoneNumber: currentUser ? formatInputPhoneNumber(currentUser.phoneNumber) : '',
          brokerName: currentUser && currentUser.broker && currentUser.broker.name ? currentUser.broker.name : '',
          businessClientNames: currentUser && currentUser.businessClient && currentUser.businessClient.length > 0 ? currentUser.businessClient.map(client => client.name) : []
        }}
        validationSchema={currentUser ? EditUserSchema : UserSchema}
        onSubmit={async (values, actions) => {
          try {
            if (currentUser) {
              const { brokerName, businessClientNames, ...rest } = values;
              let businessClientIds: number[] = [];

              if (businessClientNames && businessClientNames.length > 0) {
                // eslint-disable-next-line
                businessClientNames.map(name => {
                  // eslint-disable-next-line
                  businessClients.map(client => {
                    if (name === client.name) {
                      businessClientIds.push(client.businessClientId);
                    }
                  });
                });
              }
              const selectedBroker = brokers.find(broker => broker.name === brokerName);
              const selectedRole = roles.find(role => role.description === rest.role);
              const res = await updateUser(currentUser.userId, {
                firstName: rest.firstName,
                lastName: rest.lastName,
                email: rest.newEmail,
                isActive: rest.status,
                role: selectedRole && (selectedRole.value as UserRoles as any),
                password: rest.hasInitialDummyPassoword ? null : rest.newPassword,
                brokerId: selectedBroker ? selectedBroker.brokerId : undefined,
                businessClientIds: businessClientIds.length === 0 ? undefined : businessClientIds
              });
              const { hasError, message } = getUserErrorHandling(res);
              if (message && hasError) {
                setErrorMessage(message);
                showError(true);
              }
              if (!hasError) {
                showSuccess(true);
                setSuccessMessage('User Updated!');
                onClose();
                onSave();
                actions.resetForm();
              }
            } else {
              const { brokerName, businessClientNames, ...rest } = values;
              let businessClientIds: number[] = [];

              if (businessClientNames && businessClientNames.length > 0) {
                // eslint-disable-next-line
                businessClientNames.map(name => {
                  // eslint-disable-next-line
                  businessClients.map(client => {
                    if (name === client.name) {
                      businessClientIds.push(client.businessClientId);
                    }
                  });
                });
              }
              const selectedBroker = brokers.find(broker => broker.name === brokerName);
              const selectedRole = roles.find(role => role.description === rest.role);
              const res = await createUser({
                firstName: rest.firstName,
                lastName: rest.lastName,
                email: rest.newEmail,
                role: selectedRole && (selectedRole.value as UserRoles as any),
                password: rest.hasInitialDummyPassoword ? null : rest.newPassword,
                brokerId: selectedBroker ? selectedBroker.brokerId : undefined,
                businessClientIds: businessClientIds.length === 0 ? undefined : businessClientIds
              });
              const { hasError, message } = getUserErrorHandling(res);
              if (message && hasError) {
                setErrorMessage(message);
                showError(true);
              }

              if (!hasError) {
                showSuccess(true);
                onClose();
                onSave();
                actions.resetForm();
              }
            }
          } catch (error) {
            console.log(error);
            if (error && error.Errors && Object.values(error.Errors)[0] && Object.values(Object.values(error.Errors)[0])[0]) {
              setErrorMessage(Object.values(Object.values(error.Errors)[0])[0] as string);
            }
            showError(true);
          }
        }}
      >
        {({ resetForm, isSubmitting, values, initialValues, setFieldValue, errors, touched, handleSubmit, dirty, isValid, handleBlur }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                if (!deepEqual(initialValues, values)) {
                  const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                  if (result) {
                    resetForm();
                    onClose();
                  } else {
                    return;
                  }
                } else {
                  onClose();
                  resetForm();
                }
              }}
              maxWidth='md'
            >
              {isSubmitting && <Loader type='overlay' position='centered' />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete='none'>
                  <Typography variant='h4'>{currentUser ? 'EDIT USER' : 'ADD NEW USER'}</Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Card elevation={0} variant='outlined' className={classes.marginBottom}>
                          <CardHeader avatar={<RecentActors />} title='USER INFO' className={classes.primaryHeader} />
                          <CardContent className={classes.cardContent}>
                            <Paper elevation={0} square>
                              <div className={classes.column}>
                                <Grid container spacing={1}>
                                  {currentUser && (
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            color='primary'
                                            checked={values.status}
                                            id='active-inactive'
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (values.status) {
                                                const result = window.confirm(
                                                  "Are you sure you want to set this user to inactive? Proceeding with this update will remove this user's access to the application."
                                                );
                                                if (result) {
                                                  setFieldValue('status', e.target.checked);
                                                }
                                              } else {
                                                setFieldValue('status', e.target.checked);
                                              }
                                            }}
                                          />
                                        }
                                        label='Active'
                                      />
                                    </Grid>
                                  )}
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      autoComplete='nope'
                                      label='First Name'
                                      name='firstName'
                                      value={values.firstName}
                                      onBlur={handleBlur}
                                      onChange={e => setFieldValue('firstName', e.target.value)}
                                      error={Boolean(touched.firstName && errors.firstName)}
                                      helperText={touched.firstName && errors.firstName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      label='Last Name'
                                      autoComplete='nope'
                                      name='lastName'
                                      value={values.lastName}
                                      onBlur={handleBlur}
                                      onChange={e => setFieldValue('lastName', e.target.value)}
                                      error={Boolean(touched.lastName && errors.lastName)}
                                      helperText={touched.lastName && errors.lastName}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      fullWidth
                                      required
                                      variant='outlined'
                                      label='Email'
                                      name='newEmail'
                                      autoComplete={`nope-${Math.random()}`}
                                      value={values.newEmail}
                                      onBlur={handleBlur}
                                      onChange={e => {
                                        setFieldValue('newEmail', e.target.value);
                                      }}
                                      error={Boolean(touched.newEmail && errors.newEmail)}
                                      helperText={touched.newEmail && errors.newEmail}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth variant='outlined' required error={touched.role && errors.role ? true : false}>
                                      <InputLabel id='is-active-label' className={classes.outlinedLabel}>
                                        Role
                                      </InputLabel>
                                      <Select
                                        name='role'
                                        labelId='role'
                                        id='role'
                                        value={values.role}
                                        onBlur={handleBlur}
                                        onChange={({ target: { value } }) => {
                                          // reset these fields whenever the role changes
                                          setFieldValue('brokerName', '');
                                          setFieldValue('businessClientNames', []);
                                          setFieldValue('role', value);
                                        }}
                                        error={Boolean(touched.role && errors.role)}
                                      >
                                        {roles &&
                                          roles.map((role, index) => {
                                            return (
                                              <MenuItem key={`${index}`} value={role.description}>
                                                {role.description}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                      {touched.role && errors.role && <FormHelperText error>{errors.role}</FormHelperText>}
                                    </FormControl>
                                  </Grid>
                                  {(currentUser || values.role === EUserRoles.ADMIN) && (
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        fullWidth
                                        required
                                        variant='outlined'
                                        label='Password'
                                        name='newPassword'
                                        type='password'
                                        autoComplete={`nope-${Math.random()}`}
                                        value={values.newPassword}
                                        onBlur={handleBlur}
                                        onChange={e => {
                                          setFieldValue('hasInitialDummyPassoword', false);
                                          setFieldValue('newPassword', e.target.value);
                                        }}
                                        error={Boolean(touched.newPassword && errors.newPassword)}
                                        helperText={touched.newPassword && errors.newPassword ? errors.newPassword : ''}
                                      />
                                      <FormControl fullWidth={true} size='small'>
                                        <PasswordRequirements />
                                      </FormControl>
                                    </Grid>
                                  )}
                                  {values.role === EUserRoles.BROKER && (
                                    <Grid item xs={12} sm={6}>
                                      <FormControl fullWidth variant='outlined' required error={touched.brokerName && errors.brokerName ? true : false}>
                                        <Autocomplete
                                          value={{ name: values.brokerName } as IBroker}
                                          classes={{
                                            paper: classes.paperBorder
                                          }}
                                          onChange={(event, newValue: IBroker) => {
                                            if (newValue) {
                                              setFieldValue('brokerName', newValue.name);
                                            } else {
                                              setFieldValue('brokerName', '');
                                            }
                                          }}
                                          selectOnFocus
                                          clearOnBlur
                                          handleHomeEndKeys
                                          loading={brokersLoading}
                                          id='brokerName'
                                          options={brokers}
                                          getOptionLabel={(broker: IBroker) => {
                                            // Value selected with enter, right from the input
                                            if (typeof broker === 'string') {
                                              return broker;
                                            }
                                            return broker.name;
                                          }}
                                          renderOption={(props, option: IBroker) => {
                                            return <li {...props}>{option.name}</li>;
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              required
                                              autoComplete='off'
                                              error={Boolean(touched.brokerName && errors.brokerName)}
                                              onBlur={handleBlur}
                                              label='Broker'
                                              variant='outlined'
                                            />
                                          )}
                                        />

                                        {touched.brokerName && errors.brokerName && <FormHelperText error>{errors.brokerName}</FormHelperText>}
                                      </FormControl>
                                    </Grid>
                                  )}
                                  {values.role && values.role !== 'Administrator' && values.role !== 'Broker' && (
                                    <Grid item xs={12} sm={6}>
                                      <FormControl fullWidth variant='outlined' required error={touched.businessClientNames && errors.businessClientNames ? true : false}>
                                        <Autocomplete
                                          multiple
                                          filterSelectedOptions
                                          value={values.businessClientNames}
                                          classes={{
                                            paper: classes.paperBorder
                                          }}
                                          onChange={(_, options: IBusinessClient[] | string[], reason: string) => {
                                            const optionNames = options.map(option => (option.name ? option.name : option)).filter(Boolean);

                                            if (reason === 'clear') {
                                              setFieldValue('businessClientNames', []);
                                            } else if (reason === 'removeOption') {
                                              setFieldValue(
                                                'businessClientNames',
                                                values.businessClientNames.filter((name: string) => optionNames.includes(name))
                                              );
                                            } else {
                                              setFieldValue(
                                                'businessClientNames',
                                                // remove duplicates
                                                [...values.businessClientNames, ...optionNames].filter((id, index, self) => {
                                                  return self.indexOf(id) === index;
                                                })
                                              );
                                            }
                                          }}
                                          selectOnFocus
                                          clearOnBlur
                                          handleHomeEndKeys
                                          loading={businessClientsLoading}
                                          id='businessClientNames'
                                          options={businessClients}
                                          getOptionDisabled={() => {
                                            // disable options if the selected role is business client and you have already selected one option
                                            // other roles can allow multiple business clients to select
                                            if (values.role && values.role === 'Business Client' && values.businessClientNames.length === 1) {
                                              return true;
                                            }
                                          }}
                                          getOptionLabel={(businessClient: any) => (businessClient.name ? businessClient.name : businessClient)}
                                          renderOption={(props, option: any) => {
                                            if (option && option.name) {
                                              return (
                                                <li {...props} key={`${option.businessClientId}`}>
                                                  {option.name}
                                                </li>
                                              );
                                            }
                                            return (
                                              <li {...props} key={`${option}`}>
                                                {option}
                                              </li>
                                            );
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              InputLabelProps={{
                                                required: true
                                              }}
                                              autoComplete='off'
                                              error={Boolean(touched.businessClientNames && errors.businessClientNames)}
                                              onBlur={handleBlur}
                                              label='Business Client'
                                              variant='outlined'
                                            />
                                          )}
                                        />

                                        {touched.businessClientNames && errors.businessClientNames && <FormHelperText error>{errors.businessClientNames}</FormHelperText>}
                                      </FormControl>
                                    </Grid>
                                  )}
                                </Grid>
                              </div>
                            </Paper>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                  <CardActions>
                    <Button
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        !isValid ||
                        (values.role && values.role !== 'Administrator' && values.role !== 'Broker' && values.businessClientNames.length === 0 ? true : false)
                      }
                      type='submit'
                      startIcon={currentUser ? <Save /> : <Add />}
                      variant='contained'
                      color='primary'
                    >
                      {currentUser ? 'Save' : 'Add User'}
                    </Button>
                    <Button
                      type='button'
                      variant='contained'
                      color='inherit'
                      startIcon={<Close />}
                      onClick={() => {
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm('You have unsaved changes, are you sure you want to exit?');
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </CardActions>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
      <Toast
        id='user-success'
        message={successMessage || `User Added!`}
        open={isSuccess}
        onClose={() => {
          setSuccessMessage('');
          showSuccess(false);
        }}
        variant='success'
      />
      <Toast
        id='user-error'
        autoHideDuration={6000}
        message={errorMessage || 'We were unable to create the user at this time. Please try again later. Please contact Enrollment Alliance support if this issue continues.'}
        open={isError}
        onClose={() => {
          setErrorMessage('');
          showError(false);
        }}
        variant='error'
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  outlinedLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 2,
    paddingRight: 2
  },
  content: {
    marginTop: theme.spacing(1)
  },
  cardContent: {
    paddingTop: 0
  },
  paperBorder: {
    border: `1px solid ${theme.palette.grey[300]}`
  }
}));
