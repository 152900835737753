import React from 'react';
import { Grid, TextField } from '@mui/material';
import { IEnrollmentEngagementNumbers } from '../../../models';
import useGeneralDropdownsOptionsCollection from '../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import GenericDropdown from '../../../components/generic-dropdown/GenericDropdown';

interface IEnrollmentEngagementNumbersProps {
    data: IEnrollmentEngagementNumbers;
    handleBlur: any;
    setFieldValue: (field: string, value: any) => void;
    values: any;
    errors: any;
}

const EnrollmentEngagementNumbers: React.FC<IEnrollmentEngagementNumbersProps> = ({ data, setFieldValue, handleBlur, errors }) => {

    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalDropdownsOptions = generalDropdownsOptionsRequest.data;
    const generalDropdownsOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <GenericDropdown
                    handleBlur={handleBlur}
                    options={generalDropdownsOptions?.priorYearEnrollmentStyleOptions ?? []}
                    isLoading={generalDropdownsOptionsLoading}
                    setFieldValue={setFieldValue}
                    value={data?.priorYearEnrollmentStyle ?? ''}
                    error={errors.enrollmentEngagementNumbers?.priorYearEnrollmentStyle}
                    label='Prior Year Enrollment Style'
                    id={`enrollmentEngagementNumbers.priorYearEnrollmentStyle`}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Prior Year Engagement"
                    name="enrollmentEngagementNumbers.priorYearEngagement"
                    value={data?.priorYearEngagement}
                    onChange={(e) => setFieldValue('enrollmentEngagementNumbers.priorYearEngagement', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Employee Engagement"
                    name="enrollmentEngagementNumbers.employeeEngagement"
                    value={data?.employeeEngagement}
                    onChange={(e) => setFieldValue('enrollmentEngagementNumbers.employeeEngagement', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Number of Enroller Days"
                    name="enrollmentEngagementNumbers.numberOfEnrollerDays"
                    value={data?.numberOfEnrollerDays}
                    onChange={(e) => setFieldValue('enrollmentEngagementNumbers.numberOfEnrollerDays', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Engagement Percentage"
                    name="enrollmentEngagementNumbers.engagementPercentage"
                    value={data?.engagementPercentage}
                    onChange={(e) => setFieldValue('enrollmentEngagementNumbers.engagementPercentage', e.target.value)}
                />
            </Grid>
        </Grid>
    );
};

export default EnrollmentEngagementNumbers;
