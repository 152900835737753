import { FC } from 'react';
// Components
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker as KeyboardDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

export const DatePicker: FC<DatePickerProps<any, any>> = ({ onChange, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <KeyboardDatePicker
        componentsProps={{
          actionBar: {
            // The actions will be the same between desktop and mobile
            actions: ['clear']
          }
        }}
        inputFormat='MM/dd/yyyy'
        onChange={(date: any) => {
          // we allow partial dates now, don't return the date unless
          // it is actually valid. If invalid the .getDate() method
          // will return NaN
          if (date && !isNaN(date)) {
            onChange(date);
          } else {
            onChange('');
          }
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};
