import React, { FC, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { scaleLinear } from 'd3-scale';
import useMediaQuery from '@mui/material/useMediaQuery';
// Componnents
import { Button } from '@mui/material';
import clsx from 'clsx';
import { CheckCircleOutline, EventAvailable, HourglassEmpty } from '@mui/icons-material';
import { IProgressBarColors, IProgressBarItem } from '../../models';

interface IProgressBarProps {
  data?: {
    [key: string]: any;
  }[];
  totalCount: number;
  handleClick?: (val: string) => void;
  onLoad?: () => void;
  colors?: IProgressBarColors[];
  icons?: JSX.Element[];
  order?: number[];
}

export const ProgressBar: FC<IProgressBarProps> = ({
  data,
  totalCount,
  handleClick,
  onLoad,
  colors = [
    { background: '#4C4B4C', text: '#fff' },
    { background: '#7CDEF3', text: '#fff' },
    { background: '#11A5C5', text: '#fff' }
  ],
  icons = [<HourglassEmpty />, <EventAvailable />, <CheckCircleOutline />],
  order
}) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width: 600px)');

  const ticks = scaleLinear().domain([0, totalCount]).ticks(10);

  const assembleStatuses = () => {
    let statusArray = [];

    for (const [key, value] of Object.entries(data[0])) {
      const status = { name: key, count: value };
      statusArray.push(status);
    }
    // When received status order does not match what is desired to be displayed, things should be rearranged by given order
    if (order) {
      statusArray = statusArray.map((status, index) => {
        return {
          ...status,
          order: order[index]
        };
      });
      statusArray.sort((a, b) => (a.order > b.order ? 1 : -1));
    }
    // Combine colors and icons into status array
    const newStatuses = statusArray.map((status, index) => {
      return {
        ...status,
        background: colors[index].background,
        text: colors[index].text,
        icon: icons[index]
      };
    });
    setProgressBarData(newStatuses);
  };

  const calculateBarPercentage = (count: number) => {
    const value = (count / totalCount) * 100;
    const percent = value + '%';
    return percent;
  };

  const [progressBarData, setProgressBarData] = useState<IProgressBarItem[]>();

  useEffect(() => {
    assembleStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, totalCount]);

  if (progressBarData) {
    return (
      <div className={classes.progressBarContainer}>
        <div className={classes.progressBar}>
          {progressBarData.map((status, index) => {
            return (
              <div
                key={`${index}`}
                className={clsx(classes.progressBarColumn, classes.columnGrow)}
                style={{
                  backgroundColor: status.background || '#000',
                  color: status.text || '#fff',

                  maxHeight: isDesktop ? undefined : calculateBarPercentage(status.count),
                  flexBasis: isDesktop ? calculateBarPercentage(status.count) : 'auto',
                  width: isDesktop ? calculateBarPercentage(status.count) : '100%'
                }}
              >
                <Button
                  className={classes.progressBarLink}
                  onClick={
                    handleClick
                      ? () => {
                          handleClick(status.name);
                        }
                      : undefined
                  }
                  color='inherit'
                  fullWidth={true}
                  id={`status-filter-${status.name}`}
                  variant='text'
                  title={`${status.count} ${status.name}`}
                >
                  <span className={classes.progressBarLinkInner}>
                    <span className={classes.progressBarValue}>{status.count}</span>
                    {!isDesktop && <span className={classes.progressBarLabel}>{status.name}</span>}
                    <div className={classes.progressBarIcon}>{status.icon}</div>
                  </span>
                </Button>
              </div>
            );
          })}
        </div>
        <div className={classes.progressBarAxis}>
          {ticks.map(tick => {
            return (
              <div key={tick} className={classes.progressBarTick}>
                {tick}
              </div>
            );
          })}
        </div>
        <div className={classes.progressBarLegend}>
          {progressBarData.map((status, index) => {
            return (
              <div key={`${index}`} className={classes.progressBarLegendItem}>
                <div className={classes.progressBarLegendColor} style={{ backgroundColor: status.background }}></div>
                <div className={classes.progressBarLegendLabel}>{status.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else return null;
};

const useStyles = makeStyles(theme => ({
  progressBarContainer: {
    position: 'relative',
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexFlow: 'row nowrap',
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('sm')]: {
      flexFlow: 'column wrap'
    }
  },
  progressBar: {
    height: '500px',
    backgroundColor: '#f1f2f2',
    flexDirection: 'column-reverse',
    display: 'flex',
    flex: 1,
    minHeight: 1,
    minWidth: 1,
    [theme.breakpoints.up('sm')]: {
      height: '110px',
      flexFlow: 'row nowrap'
    }
  },
  progressBarColumn: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    flexGrow: 0,
    flexFlow: 'column nowrap',
    minHeight: '4rem',
    minWidth: '4.75rem',
    [theme.breakpoints.up('sm')]: {
      minHeight: '110px',
      width: 'auto',
      flexFlow: 'row nowrap',
      alignItems: 'stretch'
    }
  },
  columnGrow: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      flex: '0 1 auto'
    }
  },
  progressBarLink: {
    padding: '1rem',
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    flexFlow: 'row nowrap',
    textAlign: 'left',
    justifyContent: 'flex-start',
    textTransform: 'none',
    height: '100%',
    [theme.breakpoints.up('sm')]: {},
    '& > .MuiButton-label': {
      height: '100%'
    }
  },
  progressBarLinkInner: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    fontSize: theme.typography.fontSize * 1.14,
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      alignItems: 'flex-start',
      flexFlow: 'column wrap'
    }
  },
  progressBarValue: {
    display: 'inline-block',
    flex: '0 0 auto',
    paddingRight: '.5rem',
    fontSize: theme.typography.fontSize * 1.43,
    width: '3rem',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      order: 1,
      width: '100%',
      alignSelf: 'flex-end',
      textAlign: 'right',
      paddingRight: '.5rem',
      minWidth: 1,
      flex: 1,
      position: 'relative',
      top: '.5rem'
    }
  },
  progressBarLabel: {
    flexBasis: '100%',
    flexGrow: 10,
    minWidth: '1px',
    fontWeight: 'bold',
    paddingRight: '1.5rem'
  },
  progressBarIcon: {
    height: '20px',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: '40px',
      order: 0
    },
    '& > .MuiSvgIcon-root': {
      height: '100%',
      width: 'auto'
    }
  },
  hourGlassOffset: {
    marginLeft: '-.5rem'
  },
  progressBarAxis: {
    backgroundColor: theme.palette.common.white,
    width: '3rem',
    borderLeft: '1px solid #ccc',
    marginLeft: '.5rem',
    display: 'flex',
    flexFlow: 'column-reverse wrap',
    justifyContent: 'space-between',
    color: '#b2b1b2',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '3rem',
      borderTop: '1px solid #ccc',
      borderLeft: 'none',
      marginTop: '.5rem',
      marginLeft: 0,
      flexFlow: 'row nowrap'
    }
  },
  progressBarTick: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexFlow: 'column wrap'
    },
    '&&::before': {
      content: '""',
      display: 'block',
      position: 'relative',
      backgroundColor: '#ccc',
      width: '5px',
      height: '1px',
      marginRight: '5px',
      [theme.breakpoints.up('sm')]: { width: '1px', height: '5px', marginLeft: 'auto', marginRight: 'auto' }
    }
  },
  progressBarLegend: {
    display: 'none',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: '2rem'
    }
  },
  progressBarLegendItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginRight: '60px',
    '&:last-child': { marginRight: 0 }
  },
  progressBarLegendColor: {
    display: 'inline-block',
    height: '20px',
    width: '12px',
    marginRight: '.5rem'
  },
  progressBarLegendLabel: { display: 'inline-block' }
}));
