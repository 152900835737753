import React, { useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { IEnrollmentEngagementNumbers, IProductParticipation } from '../../../../../../../../models';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import GenericDropdown from '../../../../../../../../components/generic-dropdown/GenericDropdown';
import { useFormikContext } from 'formik';
import { IBusinessClientDetailFormValues } from '../../../../../../../../models';
import TabErrorMessage from '../../../../../../../../components/tab-error-message';
import { menusSubMenusValidationStatusMap } from '../../../../../hooks/useMenuSubMenuFormErrorsSynchronizer';
import { useSharedStyles } from '../../../../../shared/styles';

const ProductParticipation = () => {


    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: IProductParticipation = values.productParticipation;

    useEffect(() => {
        console.log('data', data)
    }, [data])

    const classes = useSharedStyles();

    const validateAction = menusSubMenusValidationStatusMap.get('product_partipation');
    const IsThereSomethingInvalidInTheTab = validateAction && validateAction(errors) === false;

    return (
        <div role='tabpanel' id='business-client-info-tab-content' aria-labelledby='business-client-info-tab' className={classes.tabContent}>
            <TabErrorMessage show={IsThereSomethingInvalidInTheTab} />
            <Grid container spacing={2}>


                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="HSA Participation (Prior Year)"
                        name="productParticipation.hsaParticipationPriorYear"
                        value={data?.hsaParticipationPriorYear}
                        onChange={(e) => setFieldValue('productParticipation.hsaParticipationPriorYear', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="HSA Participation"
                        name="productParticipation.hsaParticipation"
                        value={data?.hsaParticipation}
                        onChange={(e) => setFieldValue('productParticipation.hsaParticipation', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Field Title"
                        name="productParticipation.fieldTitle"
                        value={data?.fieldTitle}
                        onChange={(e) => setFieldValue('productParticipation.fieldTitle', e.target.value)}
                    />
                </Grid>

            </Grid>
        </div>
    );
};

export default ProductParticipation;
