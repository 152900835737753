import React from 'react';
import { IBusinessClientDetailFormValues, IFlexibleSpendingAccount } from '../../../../../../../../../models';
import { Box, Grid, InputAdornment, FormLabel as MuiLabel, FormControlLabel, Switch } from '@mui/material';
import { useSharedStyles } from '../../../../../../shared/styles';
import GenericDropdown from '../../../../../../../../../components/generic-dropdown/GenericDropdown';
import { PerformantTextField } from '../../../../../../../../../components/inputs/PerformantTextField';
import useGeneralDropdownsOptionsCollection from '../../../../../../../../../hooks/dropdown-collection/useGeneralDropdownsOptionsCollection';
import { useFormikContext } from 'formik';
import FieldErrorMessage from '../../../../../../../../../components/field-error-message/FieldErrorMessage ';
import SectionsFilesList from '../../../../../../../../../components/sections-files-list/SectionsFilesList';

const FlexibleSpendingAccount: React.FC = () => {

    const classes = useSharedStyles();

    const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IBusinessClientDetailFormValues>();

    const data: IFlexibleSpendingAccount = values.flexibleSpendingAccount;


    const generalDropdownsOptionsRequest = useGeneralDropdownsOptionsCollection();
    const generalOptions = generalDropdownsOptionsRequest.data;
    const generalOptionsLoading = generalDropdownsOptionsRequest.status === 'loading'

    return (<Grid container spacing={1}>

        <Grid container spacing={1}>
            <Grid item marginLeft={1}>
                <Box marginBottom='1rem'>
                    <MuiLabel color='primary' className={classes.formLabel} sx={{ marginRight: '1rem' }}>
                        Flexible Spending Account
                    </MuiLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                color='primary'
                                checked={data?.offerFlexibleSpendingAccount}
                                id='flexibleSpendingAccount.offerFlexibleSpendingAccount'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('flexibleSpendingAccount.offerFlexibleSpendingAccount', e.target.checked);
                                }}
                                onBlur={handleBlur}
                            />
                        }
                        label={
                            data?.offerFlexibleSpendingAccount ? 'Offered' : 'Not offered'
                        }
                    />
                </Box>
                <SectionsFilesList category='FlexibleSpendingAccount' />
            </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
            <PerformantTextField
                fullWidth
                size='small'
                variant='outlined'
                label='Employee Max Annual Account'
                name='flexibleSpendingAccount.employeeMaxAnnualAccount'
                value={data?.employeeMaxAnnualAccount}
                onBlur={handleBlur}
                onChange={e => {
                    setFieldValue('flexibleSpendingAccount.employeeMaxAnnualAccount', e.target.value);
                }}
                InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                }}
                error={Boolean(touched.flexibleSpendingAccount?.employeeMaxAnnualAccount && errors.flexibleSpendingAccount?.employeeMaxAnnualAccount)}
                helperText={touched.flexibleSpendingAccount?.employeeMaxAnnualAccount && errors.flexibleSpendingAccount?.employeeMaxAnnualAccount ? errors.flexibleSpendingAccount?.employeeMaxAnnualAccount : ''}
            />
            <FieldErrorMessage message={errors?.flexibleSpendingAccount?.employeeMaxAnnualAccount} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <PerformantTextField
                fullWidth
                variant='outlined'
                size='small'
                autoComplete='nope'
                label='ER Contribution'
                name='flexibleSpendingAccount.erContribution'
                value={data?.erContribution}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('flexibleSpendingAccount.erContribution', e.target.value)}
            />
            <FieldErrorMessage message={errors?.flexibleSpendingAccount?.erContribution} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <GenericDropdown handleBlur={handleBlur}
                options={generalOptions?.defaultPeriodOptions ?? []}
                isLoading={generalOptionsLoading}
                setFieldValue={setFieldValue}
                value={data?.coverageTerminatesOn ?? ''}
                error={errors.flexibleSpendingAccount?.coverageTerminatesOn}
                label='When an employee is terminated, coverage terminates on:' id={`flexibleSpendingAccount.coverageTerminatesOn`}
            />
        </Grid>
    </Grid>)
};

export default FlexibleSpendingAccount;
