import { Theme } from "@mui/material";
import { formatInputPhoneNumber } from "../../../../helpers";
import { IBuildStep, IBusinessClientDetail, getDefaultLinks } from "../../../../models";
import { buildSteps } from "../build-steps";

const getBuildSteps = (currentSelections: IBuildStep[]) => {
    const buildOptions: IBuildStep[] = buildSteps;
    // remove duplicates
    const filteredArray = currentSelections?.filter((obj, index, arr) => {
        return arr.map(mapObj => mapObj.buildStep).indexOf(obj.buildStep) === index;
    });
    const existingSteps = filteredArray?.map(a => a.buildStep);
    // combine the pre-existing options with the defaults to show them all to the user
    return [...(filteredArray || []), ...buildOptions.filter(option => !existingSteps?.includes(option.buildStep))].filter(Boolean);
};

export const createBusinessClientFormValues = (currentBusinessClient: IBusinessClientDetail,
    theme: Theme) => {
    return {
        name: currentBusinessClient?.name ?? '',
        brokerName: currentBusinessClient?.broker?.name ?? '',

        shouldCompanyNameUsedInCommunication: currentBusinessClient?.shouldCompanyNameUsedInCommunication ?? false,
        companyNameUsedInCommunication: currentBusinessClient?.companyNameUsedInCommunication ?? '',

        eligibilityRules: currentBusinessClient?.eligibilityRules,

        caseOverviewUrl: currentBusinessClient?.caseOverviewUrl ?? '',
        newHireScheduleUrl: currentBusinessClient?.newHireScheduleUrl ?? '',
        benefitProgramSupportDocumentationUrl: currentBusinessClient?.benefitProgramSupportDocumentationUrl ?? '',
        importantCaseNotes: currentBusinessClient?.importantCaseNotes ?? '',
        logoUrl: currentBusinessClient?.logoUrl ?? '',
        appLogoUrl: currentBusinessClient?.appLogoUrl ?? '',

        isEnrollmentAllianceProvidingBenAdminSystem: currentBusinessClient?.isEnrollmentAllianceProvidingBenAdminSystem ?? false,
        previousYearEnrollmentDetails: currentBusinessClient?.previousYearEnrollmentDetails ?? '',

        systemOfRecord: currentBusinessClient?.systemOfRecord?.name ?? '',
        systemOfRecordUrl: currentBusinessClient?.systemOfRecordUrl ?? '',
        systemOfRecordUsername: currentBusinessClient?.systemOfRecordUsername ?? '',
        systemOfRecordPassword: currentBusinessClient?.systemOfRecordPassword ?? '',
        openEnrollmentEffectiveDate: currentBusinessClient?.openEnrollmentEffectiveDate ?? '',
        classes:
            currentBusinessClient?.classes?.map(c => ({
                ...c,
                videoUrl: c.videoUrl || '',
                waitingPeriod: c.waitingPeriod || ''
            })) ?? [],
        location:
            currentBusinessClient?.location?.map(l => ({
                ...l,
                name: l.name || '',
                supervisorName: l.supervisorName || ''
            })) ?? [],
        contacts: currentBusinessClient?.contacts?.map(l => ({
            ...l,
        })) ?? [],

        supplementalProducts: currentBusinessClient?.supplementalProducts?.map(l => ({
            ...l,
        })) ?? [],
        mobileAppEnabled: currentBusinessClient?.mobileAppEnabled ?? false,
        dashboardOpenEnrollmentView: currentBusinessClient?.dashboardOpenEnrollmentView ?? 45,
        appLogoType: currentBusinessClient?.appLogoType ?? 'Broker',
        homeLogoType: currentBusinessClient?.homeLogoType ?? 'Broker',
        hexColor: currentBusinessClient?.hexColor ?? "#FFFFFFF",
        secondaryHexColor: currentBusinessClient?.secondaryHexColor ?? '#000000',
        textHexColor: currentBusinessClient?.textHexColor ?? '#000000',
        header: currentBusinessClient?.header ?? '',
        links: getDefaultLinks(currentBusinessClient?.links ?? []),
        openEnrollmentStartDate: currentBusinessClient?.openEnrollmentStartDate ?? '',
        selfServiceStartDate: currentBusinessClient?.selfServiceStartDate ?? '',
        selfServiceEndDate: currentBusinessClient?.selfServiceEndDate ?? '',
        enrollmentDeadline: currentBusinessClient?.enrollmentDeadline ?? '',
        hrManagerFirstName: currentBusinessClient?.hrManagerFirstName ?? '',
        hrManagerLastName: currentBusinessClient?.hrManagerLastName ?? '',
        hrManagerEmail: currentBusinessClient?.hrManagerEmail ?? '',
        hrManagerPhoneNumber: currentBusinessClient?.hrManagerPhoneNumber ? formatInputPhoneNumber(currentBusinessClient.hrManagerPhoneNumber) : '',
        automatedEmployeeCommunication: currentBusinessClient?.automatedEmployeeCommunication ?? true,
        qrCodes: currentBusinessClient?.qrCodes ?? [],
        // enrollment-builder fields
        projectType: !!currentBusinessClient?.projectType ? currentBusinessClient?.projectType : '',
        enrollmentStart: currentBusinessClient?.enrollmentStart ?? '',
        enrollmentEnd: currentBusinessClient?.enrollmentEnd ?? '',
        targetDate: currentBusinessClient?.targetDate ?? '',
        buildSteps: getBuildSteps(currentBusinessClient?.buildSteps) ?? buildSteps,
        // enrollment overview fields
        situsState: currentBusinessClient?.situsState || '',
        industry: currentBusinessClient?.industry || '',
        industryTier: currentBusinessClient?.industryTier || '',

        medicalInsurance: currentBusinessClient.medicalInsurance,

        healthSavingsAccount: {
            offerHealthSavingsAccount: currentBusinessClient?.healthSavingsAccount?.offerHealthSavingsAccount ?? false,
            erContributionsDistribution: currentBusinessClient?.healthSavingsAccount?.erContributionsDistribution || '',
            employeeMaxAnnualContribution: currentBusinessClient?.healthSavingsAccount?.employeeMaxAnnualContribution ?? null,
            familyMaxAnnualContribution: currentBusinessClient?.healthSavingsAccount?.familyMaxAnnualContribution ?? null,
            bankName: currentBusinessClient?.healthSavingsAccount?.bankName || '',
            contingentPlans: currentBusinessClient?.healthSavingsAccount?.contingentPlans || '',
            coverageTermination: currentBusinessClient?.healthSavingsAccount?.coverageTermination || '',
            setupRequirements: currentBusinessClient?.healthSavingsAccount?.setupRequirements || '',
            erContribution: currentBusinessClient?.healthSavingsAccount?.erContribution || '',
        },

        healthReimbursementAccount: currentBusinessClient?.healthReimbursementAccount,
        flexibleSpendingAccount: currentBusinessClient?.flexibleSpendingAccount,
        dependentCareFlexSpendingAccount: currentBusinessClient?.dependentCareFlexSpendingAccount,
        limitedPurposeFlexSpendingAccount: currentBusinessClient?.limitedPurposeFlexSpendingAccount,
        enrollmentEngagementNumbers: currentBusinessClient?.enrollmentEngagementNumbers,
        productParticipation: currentBusinessClient?.productParticipation,
        challengesAndSolutions: currentBusinessClient?.challengesAndSolutions,

        dentalInsurance: currentBusinessClient?.dentalInsurance,
        visionInsurance: currentBusinessClient?.visionInsurance,
        shortTermDisabilityCoverage: currentBusinessClient?.shortTermDisabilityCoverage,
        longTermDisabilityCoverage: currentBusinessClient?.longTermDisabilityCoverage,
        groupLifeInsurance: currentBusinessClient?.groupLifeInsurance,
        voluntaryLifeCoverage: currentBusinessClient?.voluntaryLifeCoverage,
        accidentInsurance: currentBusinessClient?.accidentInsurance,
        hospitalCoverage: currentBusinessClient?.hospitalCoverage,
        cancerInsurance: currentBusinessClient?.cancerInsurance,
        criticalIllnessInsurance: currentBusinessClient?.criticalIllnessInsurance,
        portableLifeInsurance: currentBusinessClient?.portableLifeInsurance,

        financialProductNotes: currentBusinessClient?.financialProductNotes || '',

        benAdminBuildType: currentBusinessClient?.benAdminBuildType || '',
        engagementPercentage: currentBusinessClient?.engagementPercentage || '',
        anticipatedSupport: currentBusinessClient?.anticipatedSupport || '',
        sicCode: currentBusinessClient?.sicCode ?? '',
        street: currentBusinessClient?.street ?? '',
        streetLine2: currentBusinessClient?.streetLine2 ?? '',
        city: currentBusinessClient?.city ?? '',
        state: currentBusinessClient?.state || '',
        postalCode: currentBusinessClient?.postalCode ?? '',
        taxId: currentBusinessClient?.taxId ?? '',
        totalEligibleEmployees: currentBusinessClient?.totalEligibleEmployees || '',
        totalEmployees: currentBusinessClient?.totalEmployees || '',
        engagementPackageType: currentBusinessClient?.engagementPackageType || '',
        carrierRepGoals: currentBusinessClient?.carrierRepGoals || '',
        highlightsChallenges: currentBusinessClient?.highlightsChallenges || '',
        specialAttentionBusinessClientEmployees: currentBusinessClient?.specialAttentionBusinessClientEmployees ?? [],
        hrDeadline: currentBusinessClient?.hrDeadline || '',
        provideDataFile: currentBusinessClient?.provideDataFile || false,
        dataDeliveryDate: currentBusinessClient?.dataDeliveryDate || '',
        dataFileRecipients: currentBusinessClient?.dataFileRecipients || [],
        accountManagerUserId: currentBusinessClient?.accountManagerUserId ?? '',


        projectedNumberOfBenefitMeetingsRequired: currentBusinessClient?.projectedNumberOfBenefitMeetingsRequired ?? '',
        estimatedEngagementRate: currentBusinessClient?.estimatedEngagementRate ?? '',

        proposedDatesAndTimes: currentBusinessClient?.proposedDatesAndTimes ?? '',
        timeZone: currentBusinessClient?.timeZone ?? '',
        enrollmentMethodAndSchedulingNotes: currentBusinessClient?.enrollmentMethodAndSchedulingNotes ?? '',

        businessClientLocationAndLogistic: currentBusinessClient?.businessClientLocationAndLogistic ?? null,
        faceToFaceEnrollment: currentBusinessClient?.faceToFaceEnrollment ?? null,

        businessClientFollowUpMessages: currentBusinessClient?.businessClientFollowUpMessages ?? null,


        typeOfEnrollment: currentBusinessClient?.typeOfEnrollment ?? null,
        enrollmentConditions: currentBusinessClient?.enrollmentConditions ?? null,
        primaryEnrollmentMethod: currentBusinessClient?.primaryEnrollmentMethod ?? null,
        secondaryEnrollmentMethod: currentBusinessClient?.secondaryEnrollmentMethod ?? null,
        enrollmentSchedulingTools: currentBusinessClient?.enrollmentSchedulingTools ?? [],

        sectionsDocuments: currentBusinessClient?.sectionsDocuments ?? [],
    }
}